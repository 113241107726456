import { Box } from '@mui/material';
import CustomTable from 'components/CustomTable/CustomTable';
import { useMemo } from 'react';

const AutomatedWorkflows = () => {
  const data = [
    { id: 1, name: 'Your application received mail1', type: 'Email', recipient: 'Partner', send: '25', opens: '20' },
    { id: 2, name: 'Your application received mail2', type: 'Email', recipient: 'Lender', send: '14', opens: '11' },
    { id: 3, name: 'Your application received mail3', type: 'Email', recipient: 'Partner', send: '68', opens: '60' },
  ];

  const columns = useMemo(
    () => [
      {
        width: 280,
        field: 'name',
        headerName: 'Name',
      },
      {
        width: 280,
        field: 'type',
        headerName: 'Type',
      },
      {
        width: 280,
        field: 'recipient',
        headerName: 'Recipient',
      },
      {
        width: 280,
        field: 'send',
        headerName: 'Send',
      },
      {
        width: 280,
        field: 'opens',
        headerName: 'Opens',
      },
    ],
    [],
  );
  return (
    <Box>
      <CustomTable
        columns={columns}
        rows={data || []}
        headerUpperCase={true}
        hideFooterPagination={true}
        NoDataFountMessage="No Data Found"
        disableColumnMenu={true}
      />
    </Box>
  );
};

export default AutomatedWorkflows;
