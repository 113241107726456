import { Add } from '@material-ui/icons';
import { Box, Button, Typography } from '@mui/material';
import clsx from 'clsx';
import CustomSelectBox from 'components/CustomSelect/CustomSelect';
import TextFieldBox from 'components/CustomTextInput/TextInputBox';
import { CustomRadioField } from 'components/inputs';
import { FieldArray, Formik, FormikHelpers } from 'formik';

import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import CustomInputDatePicker from 'components/inputs/CustomInputDatePicker/CustomInputDatePicker';
import TextArea from 'components/inputs/CustomTextarea/CustomTextarea';
import {
  adminOfferStatusOptions,
  lenderOfferStatusOptions,
  offerInterestTypesOptions,
  offerProductTypesOptions,
  rejectionReasonsOptions,
} from 'core/constants';
import { mapAppRegionToSupportedCurrency } from 'core/utils';
import { useMemo } from 'react';
import CustomCheckbox from 'components/CustomCheckBox/CheckBox';
import FooterButton from 'components/Modal/FooterButton';
import ErrorMeg from 'components/inputs/ErrorMessage/ErrorMessage';
import { RejectionReasonTag } from 'core/types';
import { useTranslation } from 'react-i18next';
import { useAccount } from 'store/account/hooks';
import { useAdmin } from 'store/admin/hooks';
import { MakeBasicOfferData, MakeRevenueBasedOfferData, OfferProductType } from 'store/admin/types';
import { useApplications } from 'store/applications/hooks';
import { OfferStatus } from 'store/applications/types';
import { useAuth } from 'store/auth/hooks';
import { useLenders } from 'store/lenders/hooks';
import { FormValues, getFormSchema, getInitialValues, initialRate } from './utils';
import useStyles from './MakeOfferModal.styles';
import useCommonStyles from '../../../../assets/css/Common.styles';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete_icon.svg';

const OfferModal = ({ onClose }: { onClose: (val: string) => void }) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { loading, makeAnOffer, updateOfferDetails, setError } = useAdmin();
  const { applicationDetails } = useApplications();
  const { allLenders } = useLenders();
  const { isAdmin } = useAuth();
  const { t } = useTranslation();
  const { account } = useAccount();
  const appRegion = applicationDetails?.region;
  const offerValue = null;

  const lendersOptions = useMemo(() => {
    const emptyOption = [{ label: '', value: '' }];
    if (allLenders.length > 0) {
      return emptyOption.concat(allLenders.map((lender) => ({ label: lender.displayed_name, value: lender.id })));
    }
    return emptyOption;
  }, [allLenders]);

  const initialValues: FormValues = getInitialValues(lendersOptions, offerValue, account?.id || '');

  const onSubmit = async (values: FormValues, helper: FormikHelpers<FormValues>) => {
    try {
      if (applicationDetails && values.validUntil && values.productType && values.status) {
        let offerData;
        if (values.productType === OfferProductType.REVENUE_BASED) {
          offerData = {
            application_id: applicationDetails.id,
            lender_id: values.lenderId as string,
            status: values.status,
            product_type: values.productType,
            offer_details: {
              valid_until: values.validUntil,
              revenue_repayments: values.rates.map((rate) => ({
                total_repayment: Number(rate.totalRepayableAmount),
                total_get: Number(rate.eligibleAmount),
                sweep: Number(rate.repaymentPercentage) / 100,
                sweep_terms: rate.repaymentTerms,
                currency: mapAppRegionToSupportedCurrency(applicationDetails.region),
              })),
            },
          } as MakeRevenueBasedOfferData;
        } else {
          offerData = {
            application_id: applicationDetails.id,
            lender_id: values.lenderId as string,
            status: values.status,
            product_type: values.productType,
            offer_details: {
              min_credit_offer: Number(values.minCredit),
              max_credit_offer: Number(values.maxCredit),
              duration_in_months: Number(values.loanDuration),
              currency: mapAppRegionToSupportedCurrency(applicationDetails.region),
              valid_until: values.validUntil,
              rates: values.rates.map((rate) => ({
                interest_rate: Number(rate.interestRate) / 100,
                principal: Number(rate.eligibleAmount),
                monthly_repayment: Number(rate.monthlyRepayment),
                total_repayment: Number(rate.totalRepayableAmount),
                monthly_repayment_type: rate.interestType,
              })),
            },
          } as MakeBasicOfferData;
        }
        if (values.notes) offerData.notes = values.notes;
        if (values.rejectionReason) offerData.rejection_reason = values.rejectionReason;
        if (values.rejectionReasonTags) offerData.rejection_reason_tags = values.rejectionReasonTags;

        if (!offerValue) {
          makeAnOffer(offerData);
        } else {
          const { offer_details, notes, rejection_reason, rejection_reason_tags, status } = offerData;
          updateOfferDetails({
            id: '1',
            details: {
              ...offer_details,
              notes: notes || '',
              status,
              rejection_reason: rejection_reason || '',
              rejection_reason_tags,
            },
          });
        }
      }
      if (!loading) {
        onClose('');
      }
      helper?.resetForm();
    } catch (error) {
      setError((error as Error)?.message || true);
      onClose('');
    }
  };

  const formSchema = useMemo(
    () => getFormSchema(account?.id || '', appRegion),
    // eslint-disable-next-line
    [account?.id, appRegion],
  );

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={formSchema}>
      {({ values, handleSubmit, setFieldValue }) => {
        return (
          <Box className={clsx([commonClasses.filter_cardBody])}>
            <Box className={clsx([commonClasses.formLblValue])}>
              <CustomSelectBox
                name="lenderId"
                value={values?.lenderId}
                label="Lender name"
                placeholder="Select Lander"
                onChange={(e) => {
                  setFieldValue('lenderId', e?.target?.value);
                }}
                options={lendersOptions}
              />
            </Box>
            <Box className={clsx([commonClasses.formLblValue])}>
              <CustomSelectBox
                name="status"
                value={values.status}
                label="Status"
                placeholder="Select Status"
                onChange={(e) => {
                  setFieldValue('status', e?.target?.value);
                }}
                options={isAdmin ? adminOfferStatusOptions : lenderOfferStatusOptions}
              />
            </Box>
            <Box className={clsx([commonClasses.formLblValue])}>
              <CustomSelectBox
                name="productType"
                value={values?.productType}
                label="Product type"
                placeholder="Select Product Type"
                onChange={(e) => {
                  setFieldValue('productType', e?.target?.value);
                }}
                options={offerProductTypesOptions}
              />
            </Box>
            <Box className={clsx([commonClasses.formLblValue])}>
              <TextFieldBox
                value={values?.minCredit}
                startAdornment={true}
                label="Minimum credit amount"
                name="minCredit"
                type="number"
              />
            </Box>
            <Box className={clsx([commonClasses.formLblValue])}>
              <TextFieldBox
                value={values?.maxCredit}
                startAdornment={true}
                label="Maximum credit amount"
                name="maxCredit"
                type="number"
              />
            </Box>
            <Box className={clsx([commonClasses.formLblValue])}>
              <TextFieldBox
                value={values?.loanDuration}
                label="Loan duration (in months)"
                name="loanDuration"
                type="number"
              />
            </Box>
            <Box className={clsx([commonClasses.formLblValue])}>
              <CustomInputDatePicker
                name="validUntil"
                value={values?.validUntil}
                label="Offer valid until"
                isShowIcon="true"
                onChange={(date: MaterialUiPickersDate) => {
                  setFieldValue('validUntil', date);
                }}
                collapsed
                minDate={new Date()}
                openTo="date"
                format="DD MMMM YYYY"
              />
            </Box>
            <FieldArray name="rates">
              {({ remove: removeRate, push: pushRate }) => {
                return values?.rates?.map((rate, index) => {
                  return (
                    <Box key={rate?.interestRate}>
                      <Box className={clsx([classes.formLblValueGroup])}>
                        <Box className={clsx([classes.LblValueSpace])}>
                          <Typography
                            variant="text_MD"
                            component="label"
                            className={clsx([commonClasses.fontJost, classes.fontWeight_700, commonClasses.gray_500])}
                          >
                            Offer rate {index + 1}
                          </Typography>
                          {values?.rates?.length > 1 && <DeleteIcon onClick={() => removeRate(index)} />}
                        </Box>
                        <Box className={clsx([commonClasses.formLblValue])}>
                          <TextFieldBox
                            startAdornment={true}
                            value={rate?.eligibleAmount}
                            name={`rates.${index}.eligibleAmount`}
                            label="Eligible amount"
                            type="number"
                          />
                        </Box>
                        <Box className={clsx([commonClasses.formLblValue])}>
                          <TextFieldBox
                            value={rate?.interestRate}
                            label="Interest rate (per month)"
                            name={`rates.${index}.interestRate`}
                            type="number"
                          />
                        </Box>
                        <Box className={clsx([commonClasses.formLblValue])}>
                          <TextFieldBox
                            startAdornment={true}
                            value={rate?.monthlyRepayment}
                            label="Monthly repayment amount"
                            name={`rates.${index}.monthlyRepayment`}
                            type="number"
                          />
                        </Box>
                      </Box>
                      <Box className={clsx([commonClasses.formLblValue])}>
                        <CustomRadioField
                          options={offerInterestTypesOptions}
                          name={`rates.${index}.interestType`}
                          value={rate?.interestType}
                          setFieldValue={setFieldValue}
                          title={t('pages.lead.admin.makeOffer.inputs.interestType.label')}
                        />
                      </Box>
                      <Box className={clsx([commonClasses.formLblValue])}>
                        <TextFieldBox
                          startAdornment={true}
                          value={rate.totalRepayableAmount}
                          label="Total amount to repay"
                          name={`rates.${index}.totalRepayableAmount`}
                          type="number"
                        />
                      </Box>
                      {values?.rates?.length - 1 === index && (
                        <Box
                          sx={{ pb: 8, borderBottom: 1, borderColor: 'grey.300' }}
                          className={clsx([commonClasses.formLblValue])}
                        >
                          <Button
                            disableRipple={true}
                            className={clsx([classes.addRate])}
                            endIcon={<Add />}
                            onClick={() => pushRate(initialRate)}
                          >
                            Add rate
                          </Button>
                        </Box>
                      )}
                    </Box>
                  );
                });
              }}
            </FieldArray>

            <Box className={classes.modalTextAreBox}>
              <TextArea name="notes" label="Provide comments about this offer" value={values?.notes} minRows={4} />
            </Box>
            {values.status === OfferStatus.DECLINED && (
              <>
                <Box className={classes.modalTextAreBox}>
                  <ul className={classes.ul_style}>
                    {rejectionReasonsOptions?.map((d: { value: string; label: string }) => {
                      return (
                        <li key={d?.value}>
                          <CustomCheckbox
                            formik={false}
                            value={d?.value}
                            checked={values?.rejectionReasonTags?.includes(d?.value as RejectionReasonTag)}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              if (!e?.target?.checked) {
                                setFieldValue(
                                  'rejectionReasonTags',
                                  values.rejectionReasonTags.filter((v) => v !== e?.target.value),
                                );
                              } else {
                                setFieldValue('rejectionReasonTags', [...values.rejectionReasonTags, e?.target.value]);
                              }
                            }}
                            name="rejectionReasonTags"
                            label={d?.label}
                          />
                        </li>
                      );
                    })}
                  </ul>
                  <ErrorMeg name="rejectionReasonTags" />
                </Box>
                <Box className={classes.modalTextAreBox}>
                  <TextArea
                    name="rejectionReason"
                    label={t('pages.lead.admin.makeOffer.inputs.notes.label')}
                    value={values?.rejectionReason}
                    minRows={4}
                  />
                </Box>
              </>
            )}
            <FooterButton
              handleSubmit={handleSubmit}
              onClose={() => onClose('')}
              btnName="Send offer"
              loading={loading}
            />
          </Box>
        );
      }}
    </Formik>
  );
};

export default OfferModal;
