import { FC, useState } from 'react';
import { Container, Divider, Grid, Typography, List, ListItem, ListItemText, Box, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { useAccount } from 'store/account/hooks';
import { composeFullName } from 'utils';
import { InviteUserModal } from 'components/InviteUserModal';
import { lenderRolesOptions, partnerRolesOptions } from 'core/constants';
import { AlertMessageStatus, UserRole } from 'core/types';
import { AlertMessage } from 'components/alerts';
import { useGlobalState } from 'store/global/hooks';
import { useAuth } from 'store/auth/hooks';
import { mapUserRoleToName } from 'core/utils';
import { AccountUsers } from 'components/AccountUsers';
import useStyles from './Team.styles';

const Team: FC = () => {
  const [inviteOpen, setInviteOpen] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const { account, error, success, setError, setSuccess } = useAccount();
  const { error: globalError } = useGlobalState();
  const { isPartnerAdmin, isLenderAdmin, role, firstName, lastName, email } = useAuth();

  const toggleInviteOpen = () => setInviteOpen((prevState) => !prevState);

  return (
    <Container disableGutters maxWidth="xl">
      <Grid container spacing={3} className={classes.container}>
        <Grid item xs={12}>
          <Typography variant="h1" className={classes.title}>
            {t('pages.team.title')}
          </Typography>

          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Typography className={classes.subtitle}>{t('pages.team.sections.personalInfo.title')}</Typography>

          <List>
            <ListItem className={classes.listItem}>
              <ListItemText className={classes.itemTitle}>{t('pages.team.sections.personalInfo.name')}</ListItemText>
              <ListItemText className={classes.itemText}>
                {composeFullName(firstName ?? '', lastName ?? '')}
              </ListItemText>
            </ListItem>

            <ListItem className={classes.listItem}>
              <ListItemText className={classes.itemTitle}>{t('pages.team.sections.personalInfo.email')}</ListItemText>
              <ListItemText className={classes.itemText}>{email}</ListItemText>
            </ListItem>

            <ListItem className={classes.listItem}>
              <ListItemText className={classes.itemTitle}>{t('pages.team.sections.personalInfo.role')}</ListItemText>
              <ListItemText className={classes.itemText}>{role ? mapUserRoleToName(role) : ''}</ListItemText>
            </ListItem>
          </List>

          <Box m={2} />

          <Typography className={classes.subtitle}>{t('pages.team.sections.organizationInfo.title')}</Typography>

          <List>
            <ListItem className={classes.listItem}>
              <ListItemText className={classes.itemTitle}>
                {t('pages.team.sections.organizationInfo.name')}
              </ListItemText>
              <ListItemText className={classes.itemText}>{account.name}</ListItemText>
            </ListItem>
          </List>
        </Grid>

        <Grid item xs={12}>
          <AccountUsers />
        </Grid>

        {(isPartnerAdmin || isLenderAdmin) && (
          <Grid item xs={12}>
            <Button variant="contained" color="primary" disableElevation onClick={toggleInviteOpen}>
              {t('pages.team.inviteUser.buttons.invite')}
            </Button>
          </Grid>
        )}
      </Grid>

      <InviteUserModal
        open={inviteOpen}
        toggleOpen={toggleInviteOpen}
        roleOptions={isPartnerAdmin ? partnerRolesOptions : lenderRolesOptions}
        defaultRole={isPartnerAdmin ? UserRole.PARTNER_MANAGER : UserRole.LENDER_MANAGER}
      />

      {!globalError && (
        <>
          <AlertMessage
            open={!!error}
            onClose={() => setError(false)}
            message={typeof error === 'string' ? error : undefined}
            autoHideDuration={5000}
            status={AlertMessageStatus.ERROR}
          />

          <AlertMessage
            open={!!success}
            onClose={() => setSuccess(false)}
            message={typeof success === 'string' ? success : undefined}
            autoHideDuration={5000}
            status={AlertMessageStatus.SUCCESS}
          />
        </>
      )}
    </Container>
  );
};

export default Team;
