import { FC, useState } from 'react';
import { Box } from '@material-ui/core';
import { CSVDownloader } from 'react-papaparse';
import { useLenders } from 'store/lenders/hooks';

import { East } from '@mui/icons-material';
import { Button, Grid, Stack, Typography } from '@mui/material';
import clsx from 'clsx';
import CommonModal from 'components/Modal/CommonModal';
import { CreateLenderModal } from '../CreateLenderModal';
import { UploadLendersModal } from '../UploadLendersModal';

import useStyles from './LenderAdminActions.styles';
import { ReactComponent as DocumentPen } from '../../../../assets/icons/document_pen.svg';
import { ReactComponent as DocumentUpload } from '../../../../assets/icons/document_upload.svg';
import { ReactComponent as DownloadIcon } from '../../../../assets/icons/downloadBlue.svg';
import { ReactComponent as DownloadLenders } from '../../../../assets/icons/download_lenders.svg';
import { ReactComponent as UploadIcon } from '../../../../assets/icons/upload_blue.svg';

type Lender = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

const LenderAdminActions: FC = () => {
  const [createOpen, setCreateOpen] = useState(false);
  const [uploadOpen, setUploadOpen] = useState(false);

  const { lenders } = useLenders();
  const classes = useStyles();

  const toggleCreateOpen = () => setCreateOpen((prevState) => !prevState);
  const toggleUploadOpen = () => setUploadOpen((prevState) => !prevState);

  const transformedLenders = lenders.map((lender: Lender) => {
    const transformedLender: Lender = {};
    Object.keys(lender).forEach((key) => {
      const transformedHeader = key.replace(/([A-Z])/g, ' $1');
      const formattedHeader = transformedHeader.charAt(0).toUpperCase() + transformedHeader.slice(1);
      transformedLender[formattedHeader] = lender[key as keyof Lender]; // Use type assertion here
    });
    return transformedLender;
  });

  return (
    <Box sx={{ marginTop: '30px' }}>
      <Box className={clsx([classes.welcomeStripWRap, classes.cardShadow])}>
        <Grid container spacing={4} sx={{ alignItems: 'center' }}>
          <Grid item xs={3.6}>
            <Box className={classes.boxCircleIconTextWrap}>
              <Stack sx={{ mb: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={6}>
                <Box className={classes.boxCircleIcon}>
                  <DocumentPen />
                </Box>
                <Box className={classes.boxCircleText}>
                  <Typography
                    className={clsx([classes.fontJost, classes.boxCircleTitle])}
                    component="p"
                    variant="text_MD"
                    sx={{ mb: 2 }}
                  >
                    Create a lender
                  </Typography>
                  <Button
                    variant="text"
                    onClick={toggleCreateOpen}
                    disableRipple={true}
                    className={clsx([classes.btnNoPadding, classes.btnBlueText])}
                    endIcon={<East />}
                  >
                    Create lender
                  </Button>
                </Box>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={4.8} justifyContent="center">
            <Box
              className={clsx([classes.boxBorderLR, classes.boxCircleIconTextWrap, classes.justifyCenter])}
              justifyContent="center"
            >
              <Stack sx={{ mb: 0 }} direction="row" justifyContent="center" alignItems="center" spacing={6}>
                <Box className={classes.boxCircleIcon}>
                  <DocumentUpload />
                </Box>
                <Box className={classes.boxCircleText}>
                  <Typography
                    className={clsx([classes.fontJost, classes.boxCircleTitle])}
                    component="p"
                    variant="text_MD"
                    sx={{ mb: 2 }}
                  >
                    Upload lenders
                  </Typography>
                  <Button
                    variant="text"
                    onClick={toggleUploadOpen}
                    disableRipple={true}
                    className={clsx([classes.btnNoPadding, classes.btnBlueText])}
                    endIcon={<UploadIcon />}
                  >
                    Upload lenders
                  </Button>
                </Box>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={3.6}>
            <Box className={clsx([classes.boxCircleIconTextWrap, classes.justifyEnd])} justifyContent="flex-end">
              <Stack sx={{ mb: 0 }} direction="row" justifyContent="flex-end" alignItems="center" spacing={6}>
                <Box className={classes.boxCircleIcon}>
                  <DownloadLenders />
                </Box>
                <Box className={classes.boxCircleText}>
                  <Typography
                    className={clsx([classes.fontJost, classes.boxCircleTitle])}
                    component="p"
                    variant="text_MD"
                    sx={{ mb: 2 }}
                  >
                    Download lenders
                  </Typography>
                  <Button
                    variant="text"
                    disableRipple={true}
                    className={clsx([classes.btnNoPadding, classes.btnBlueText])}
                    endIcon={<DownloadIcon />}
                  >
                    <CSVDownloader
                      filename="BScore_Lenders"
                      data={transformedLenders}
                      config={{
                        header: true,
                        delimiter: ',',
                      }}
                      bom
                    >
                      Download
                    </CSVDownloader>
                  </Button>
                </Box>
              </Stack>
            </Box>
          </Grid>
          {createOpen && (
            <CommonModal open={createOpen} title="Create a lender" onClose={toggleCreateOpen} icon={<DocumentPen />}>
              <CreateLenderModal open={createOpen} toggleOpen={toggleCreateOpen} />
            </CommonModal>
          )}
          {uploadOpen && (
            <CommonModal open={uploadOpen} title="Upload lenders" onClose={toggleUploadOpen} icon={<DocumentUpload />}>
              <UploadLendersModal open={uploadOpen} toggleOpen={toggleUploadOpen} />
            </CommonModal>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default LenderAdminActions;
