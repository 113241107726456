import { ChangeEvent, FC, useRef } from 'react';
import { Grid, Paper, Box, Chip, FormHelperText } from '@material-ui/core';
import { Formik, Form, Field, FormikProps } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import { CustomTextField, CustomSelect, CustomLabel } from 'components/inputs';
import { LoadingButton } from 'components/buttons';
import { useAuth } from 'store/auth/hooks';
import {
  accountCountryOptions,
  accountEmployeesOptions,
  accountIndustryOptions,
  accountTypeOptions,
  websiteRegExp,
} from 'core/constants';
import { AuthPageContainer } from 'components/AuthPageContainer';
import { AccountType, ApplicationRegion } from 'store/applications/types';
import useStyles from './GetStarted.styles';

interface FormValues {
  contact_first_name: string;
  contact_last_name: string;
  contact_email: string;
  phone_number: string;
  job_title: string;
  company_name: string;
  company_website: string;
  country_code: string;
  industry: string;
  employees: string;
  account_type: AccountType | null;
  supported_regions: string[];
}

const GetStarted: FC = () => {
  const formRef = useRef<FormikProps<FormValues> | null>(null);
  const classes = useStyles();
  const { t } = useTranslation();
  const { loading, error, success, setError, setSuccess, registerAccount } = useAuth(() =>
    formRef.current?.resetForm(),
  );

  const formSchema = yup.object({
    contact_first_name: yup.string().required(t('pages.getStarted.inputs.contact_first_name.required')),
    contact_last_name: yup.string().required(t('pages.getStarted.inputs.contact_last_name.required')),
    contact_email: yup
      .string()
      .required(t('pages.getStarted.inputs.contact_email.required'))
      .email(t('pages.getStarted.inputs.contact_email.error')),
    phone_number: yup.string().required(t('pages.getStarted.inputs.phone_number.required')),
    job_title: yup.string().required(t('pages.getStarted.inputs.job_title.required')),
    company_name: yup.string().required(t('pages.getStarted.inputs.company_name.required')),
    company_website: yup
      .string()
      .required(t('pages.getStarted.inputs.company_website.required'))
      .matches(websiteRegExp, t('pages.getStarted.inputs.company_website.error')),
    country_code: yup.string().required(t('pages.getStarted.inputs.country_code.required')),
    industry: yup.string().required(t('pages.getStarted.inputs.industry.required')),
    employees: yup.string().required(t('pages.getStarted.inputs.employees.required')),
    account_type: yup
      .string()
      .required(t('pages.getStarted.inputs.account_type.required'))
      .oneOf([...Object.values(AccountType), null], t('pages.getStarted.inputs.account_type.error'))
      .nullable(),
    supported_regions: yup
      .array(yup.string())
      .when('account_type', (accountType: AccountType, schema: yup.ArraySchema<yup.StringSchema>) =>
        accountType === AccountType.LENDER
          ? schema.min(1, t('pages.getStarted.inputs.supported_regions.required'))
          : schema.notRequired(),
      ),
  });

  const initialValues: FormValues = {
    contact_first_name: '',
    contact_last_name: '',
    contact_email: '',
    phone_number: '',
    job_title: '',
    company_name: '',
    company_website: '',
    country_code: '',
    industry: '',
    employees: '',
    account_type: null,
    supported_regions: [],
  };

  const onSubmit = (values: FormValues) => {
    registerAccount({ ...values, account_type: values.account_type as AccountType });
  };

  const clearSuccess = () => setSuccess(false);

  const clearError = () => setError(false);

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={formSchema} innerRef={formRef}>
      {({ values, errors, touched, handleChange, handleSubmit, setFieldValue }) => (
        <AuthPageContainer
          error={error}
          clearError={clearError}
          success={success}
          clearSuccess={clearSuccess}
          logoMdGridSize={4}
          formMdGridSize={8}
        >
          <Paper elevation={2} className={classes.formContainer}>
            <Form noValidate>
              <Grid item container direction="row" spacing={3} className={classes.inputsContainer}>
                <Grid item>
                  <Field
                    autoFocus
                    id="contact_first_name"
                    fullWidth
                    component={CustomTextField}
                    name="contact_first_name"
                    value={values.contact_first_name}
                    onChange={handleChange}
                    title={t('pages.getStarted.inputs.contact_first_name.label')}
                    placeholder={t('pages.getStarted.inputs.contact_first_name.placeholder')}
                    className={classes.textInput}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>

                <Grid item>
                  <Field
                    id="contact_last_name"
                    fullWidth
                    component={CustomTextField}
                    name="contact_last_name"
                    value={values.contact_last_name}
                    onChange={handleChange}
                    title={t('pages.getStarted.inputs.contact_last_name.label')}
                    placeholder={t('pages.getStarted.inputs.contact_last_name.placeholder')}
                    className={classes.textInput}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>

                <Grid item>
                  <Field
                    id="contact_email"
                    fullWidth
                    component={CustomTextField}
                    name="contact_email"
                    type="contact_email"
                    value={values.contact_email}
                    onChange={handleChange}
                    title={t('pages.getStarted.inputs.contact_email.label')}
                    placeholder={t('pages.getStarted.inputs.contact_email.placeholder')}
                    className={classes.textInput}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>

                <Grid item>
                  <Field
                    id="phone_number"
                    fullWidth
                    component={CustomTextField}
                    name="phone_number"
                    value={values.phone_number}
                    onChange={handleChange}
                    title={t('pages.getStarted.inputs.phone_number.label')}
                    placeholder={t('pages.getStarted.inputs.phone_number.placeholder')}
                    className={classes.textInput}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>

                <Grid item>
                  <Field
                    id="job_title"
                    fullWidth
                    component={CustomTextField}
                    name="job_title"
                    value={values.job_title}
                    onChange={handleChange}
                    title={t('pages.getStarted.inputs.job_title.label')}
                    placeholder={t('pages.getStarted.inputs.job_title.placeholder')}
                    className={classes.textInput}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>

                <Grid item>
                  <Field
                    id="company_name"
                    fullWidth
                    component={CustomTextField}
                    name="company_name"
                    value={values.company_name}
                    onChange={handleChange}
                    title={t('pages.getStarted.inputs.company_name.label')}
                    placeholder={t('pages.getStarted.inputs.company_name.placeholder')}
                    className={classes.textInput}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>

                <Grid item>
                  <Field
                    id="company_website"
                    fullWidth
                    component={CustomTextField}
                    name="company_website"
                    value={values.company_website}
                    onChange={handleChange}
                    title={t('pages.getStarted.inputs.company_website.label')}
                    placeholder={t('pages.getStarted.inputs.company_website.placeholder')}
                    className={classes.textInput}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>

                <Grid item>
                  <Field
                    id="country_code"
                    fullWidth
                    component={CustomSelect}
                    options={accountCountryOptions}
                    name="country_code"
                    value={values.country_code}
                    onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                      setFieldValue('country_code', event.target.value)
                    }
                    title={t('pages.getStarted.inputs.country_code.label')}
                    className={classes.textInput}
                  />
                </Grid>

                <Grid item>
                  <Field
                    id="industry"
                    fullWidth
                    component={CustomSelect}
                    options={accountIndustryOptions}
                    name="industry"
                    value={values.industry}
                    onChange={(event: ChangeEvent<HTMLSelectElement>) => setFieldValue('industry', event.target.value)}
                    title={t('pages.getStarted.inputs.industry.label')}
                    className={classes.textInput}
                  />
                </Grid>

                <Grid item>
                  <Field
                    id="employees"
                    fullWidth
                    component={CustomSelect}
                    options={accountEmployeesOptions}
                    name="employees"
                    value={values.employees}
                    onChange={(event: ChangeEvent<HTMLSelectElement>) => setFieldValue('employees', event.target.value)}
                    title={t('pages.getStarted.inputs.employees.label')}
                    className={classes.textInput}
                  />
                </Grid>

                <Grid item>
                  <Field
                    id="account_type"
                    fullWidth
                    component={CustomSelect}
                    options={accountTypeOptions}
                    name="account_type"
                    value={values.account_type ?? ''}
                    onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                      setFieldValue('account_type', event.target.value)
                    }
                    title={t('pages.getStarted.inputs.account_type.label')}
                    className={classes.textInput}
                  />
                </Grid>

                {values.account_type === AccountType.LENDER && (
                  <Grid item>
                    <CustomLabel title={t('pages.getStarted.inputs.supported_regions.label')} />

                    <Box className={classes.supportedRegionsContainer}>
                      {Object.values(ApplicationRegion).map((region) => {
                        const alreadySelected = values.supported_regions.find((v) => v === region);
                        return (
                          <Chip
                            id={region}
                            key={region}
                            color={alreadySelected ? 'primary' : 'default'}
                            label={region}
                            onClick={() => {
                              if (alreadySelected) {
                                setFieldValue(
                                  'supported_regions',
                                  values.supported_regions.filter((v) => v !== region),
                                );
                              } else {
                                setFieldValue('supported_regions', [...values.supported_regions, region]);
                              }
                            }}
                            className={classes.supportedRegionOption}
                          />
                        );
                      })}
                    </Box>

                    {touched.supported_regions && errors.supported_regions && (
                      <FormHelperText error>{errors.supported_regions}</FormHelperText>
                    )}
                  </Grid>
                )}
              </Grid>

              <Grid item className={classes.actionContainer}>
                <LoadingButton
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => handleSubmit()}
                  loading={loading}
                  type="submit"
                >
                  {t('pages.getStarted.buttons.submit')}
                </LoadingButton>
              </Grid>
            </Form>
          </Paper>
        </AuthPageContainer>
      )}
    </Formik>
  );
};

export default GetStarted;
