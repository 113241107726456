import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  controlLabel: {
    '& span.MuiTypography-body1': {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      fontWeight: 700,
    },
    '& svg': {
      width: 20,
      height: 20,
    },
  },
}));
