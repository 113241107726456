import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(0, 0, 0, 0.7)',
  },
  color: {
    color: '#0086C9',
  },
}));
