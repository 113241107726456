import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  container: {},
  columnHeaderItem: {
    backgroundColor: theme.palette.customGrey.main,
    height: 60,
    padding: theme.spacing(1),
    borderRadius: 0,
    cursor: 'pointer',
  },
  columnHeaderButton: {
    borderRadius: 0,
    padding: '4px 8px',
  },
  columnHeaderTitle: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(20),
    fontWeight: 'bold',
    height: 'auto',
    minHeight: 'auto',
  },
  columnHeaderIconContainer: {
    height: 30,
    width: 20,
    position: 'relative',
  },
  columnHeaderSortUpIcon: {
    width: 14,
    height: 12,
    position: 'absolute',
    right: 0,
    top: 6,
  },
  columnHeaderSortDownIcon: {
    width: 14,
    height: 12,
    position: 'absolute',
    right: 0,
    top: 13,
  },
}));
