import { Box, Typography } from '@mui/material';
import clsx from 'clsx';
import CustomFileUploader from 'components/CustomImageUpload/CustomImageUpload';
import moment from 'moment';
import { formats, modules } from 'pages/settings/Faqs/constants';
import ReactQuill from 'react-quill';
import * as yup from 'yup';

import { useMemo, useRef, useState } from 'react';
import TextFieldBox from 'components/CustomTextInput/TextInputBox';
import FooterButton from 'components/Modal/FooterButton';
import { allowedFileTypes } from 'core/constants';
import { CompanyDocumentType } from 'core/types';
import { Formik, FormikProps } from 'formik';
import { sendEmail as sendEmailApi } from 'http/admin';
import { useTranslation } from 'react-i18next';
import { useAdmin } from 'store/admin/hooks';
import ErrorMeg from 'components/inputs/ErrorMessage/ErrorMessage';
import { useApplications } from 'store/applications/hooks';
import { useAuth } from 'store/auth/hooks';
import { CC_DEFAULT_EMAIL_ADDRESS, SIGNATURE_LOGO_SRC } from './constants';
import useStyles from './SendEmailModal.styles';
import useCommonStyles from '../../../../assets/css/Common.styles';

const filesInitialState: { accepted: File[] } = { accepted: [] };
interface FormValues {
  from: string;
  email: string;
  cc?: string;
  bcc?: string;
  subject: string;
  body: string;
  send_at: Date;
}

const SendEmailsModal = ({
  onClose,
  setMessage,
}: {
  onClose: (val: string) => void;
  setMessage: (message: string, open: boolean) => void;
}) => {
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState<{ accepted: File[] }>(filesInitialState);

  const formRef = useRef<FormikProps<FormValues> | null>(null);
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { t } = useTranslation();
  const { setError, setRefreshEmails } = useAdmin();
  const { applicationDetails } = useApplications();
  const { firstName, lastName, occupation, email: adminEmail } = useAuth();

  const applicant = applicationDetails?.company.people.find((p) => p.applicant);

  const formSchema = useMemo(
    () =>
      yup.object({
        from: yup
          .string()
          .email(t('pages.lead.admin.sendEmail.inputs.from.error'))
          .required(t('pages.lead.admin.sendEmail.inputs.from.required')),
        email: yup
          .string()
          .email(t('pages.lead.admin.sendEmail.inputs.email.error'))
          .required(t('pages.lead.admin.sendEmail.inputs.email.required')),
        cc: yup.string().email(t('pages.lead.admin.sendEmail.inputs.cc.error')),
        bcc: yup.string().email(t('pages.lead.admin.sendEmail.inputs.bcc.error')),
        subject: yup.string().required(t('pages.lead.admin.sendEmail.inputs.subject.required')),
        body: yup
          .string()
          .min(12, t('pages.lead.admin.sendEmail.inputs.body.minimum'))
          .required(t('pages.lead.admin.sendEmail.inputs.body.required')),
        send_at: yup.date().required(t('pages.lead.admin.sendEmail.inputs.send_at.required')),
      }),
    [t],
  );

  const initialValues: FormValues = {
    from: adminEmail ?? '',
    email: applicant?.email ?? '',
    cc: CC_DEFAULT_EMAIL_ADDRESS,
    bcc: '',
    subject: '',
    body: `
    <br />
    <br />
    <p><strong>${firstName} ${lastName}</strong></p>
    ${occupation ? `<p>${occupation}</p>` : ''}
    <p><img src="${SIGNATURE_LOGO_SRC}" /></p>
    <p>Fair, fast and simple funding</p>
    <br/>
    <p>E: <a href="mailto:${adminEmail}">${adminEmail}</a></p>
    <p>W: <a href="${t('pages.auth.footer.links.websiteUrl')}">${t('pages.auth.footer.links.websiteUrl')}</a></p>
    <br/>
    <p>Find out what our <a href="https://uk.trustpilot.com/review/scorethebusiness.com">customers</a> have to say about us.</p>
    <br />
    `,
    send_at: moment().toDate(),
  };

  const onSubmit = async (values: FormValues) => {
    if (!applicationDetails?.id) return;
    setLoading(true);
    try {
      const formData = new FormData();

      if (files.accepted.length) {
        formData.append('type', CompanyDocumentType.EMAIL_ATTACHMENTS);

        files.accepted.forEach((file) => {
          formData.append('file', file);
        });
      }

      formData.append('email', values.email);
      formData.append('subject', values.subject);
      formData.append('body', values.body);

      if (values.cc) formData.append('cc', values.cc);
      if (values.bcc) formData.append('bcc', values.bcc);
      if (values.send_at) formData.append('send_at', moment(values.send_at).unix().toString());

      await sendEmailApi(applicationDetails.id, formData);

      setMessage(t('pages.lead.admin.sendEmail.messages.success'), true);
      setRefreshEmails(true);

      formRef.current?.resetForm();
      onClose('');
    } catch (error) {
      onClose('');
      setError((error as Error)?.message ?? true);
    }
    setLoading(false);
  };

  return (
    <Formik initialValues={initialValues} validationSchema={formSchema} onSubmit={onSubmit}>
      {({ values, setFieldValue, handleSubmit }) => {
        return (
          <Box className={clsx([commonClasses.filter_cardBody])}>
            <Box>
              <Box className={clsx([commonClasses.formLblValue])}>
                <TextFieldBox label="From:" name="from" value={values?.from} type="text" placeholder="Sender’s email" />
              </Box>
              <Box className={clsx([commonClasses.formLblValue])}>
                <TextFieldBox label="To:" name="email" value={values?.email} type="text" placeholder="Sender’s email" />
              </Box>
              <Box className={clsx([commonClasses.formLblValue])}>
                <TextFieldBox label="CC:" name="cc" value={values?.cc} type="text" placeholder="Sender’s CC" />
              </Box>
              <Box className={clsx([commonClasses.formLblValue])}>
                <TextFieldBox
                  label="Subject:"
                  name="subject"
                  value={values?.subject}
                  type="text"
                  placeholder="Sender’s Subject"
                />
              </Box>
              <Box className={clsx([commonClasses.formLblValue])}>
                <Typography
                  variant="text_MD"
                  component="label"
                  className={clsx([
                    commonClasses.fontJost,
                    commonClasses.fontWeight_500,
                    commonClasses.gray_500,
                    commonClasses.inputLbl,
                  ])}
                >
                  Body:
                </Typography>
                <Box className={clsx([classes.formInlineLblValue_value])}>
                  <ReactQuill
                    theme="snow"
                    modules={modules}
                    onChange={(value: string) => setFieldValue('body', value)}
                    formats={formats}
                    readOnly={false}
                    value={values?.body}
                    preserveWhitespace
                    className={clsx([classes.editor])}
                  />
                </Box>
                <Box>
                  <ErrorMeg name="body" />
                </Box>
              </Box>
              <Box className={clsx([commonClasses.formLblValue])}>
                <Typography
                  variant="text_MD"
                  component="label"
                  className={clsx([
                    commonClasses.fontJost,
                    commonClasses.fontWeight_500,
                    commonClasses.gray_500,
                    commonClasses.inputLbl,
                  ])}
                >
                  Attachments:
                </Typography>
                <Box className={clsx([classes.formInlineLblValue_value])}>
                  <CustomFileUploader
                    maxSize={10485760}
                    allowedFileTypes={allowedFileTypes?.map((d) => d?.mimeType)}
                    loading={false}
                    setFiles={setFiles}
                  />
                </Box>
              </Box>
            </Box>
            <FooterButton
              handleSubmit={handleSubmit}
              onClose={() => onClose('')}
              btnName="Send mail"
              loading={loading}
            />
          </Box>
        );
      }}
    </Formik>
  );
};

export default SendEmailsModal;
