import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Router, Redirect, Route, Switch } from 'react-router-dom';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { WIDGET_BUNDLE_URL, WIDGET_ELEMENT_ID, WIDGET_SCRIPT_ID } from 'core/constants';
import { useIntercom } from 'hooks';
import useScript from 'hooks/useScript';
import { useLenders } from 'store/lenders/hooks';
import { usePartners } from 'store/partners/hooks';
import { AlertDialog } from 'components/AlertDialog';
import { AlertMessage } from 'components/alerts';
import { Layout } from 'components/Layout';
import { useAccount } from 'store/account/hooks';
import { useAuth } from 'store/auth/hooks';
import { useGlobalState } from 'store/global/hooks';
import { Home } from 'pages/home';
import { Design } from 'pages/design';
import { StylesProvider, createGenerateClassName } from '@material-ui/core/styles';
import {
  Admins,
  // Dashboard,
  ForgotPassword,
  Lead,
  Lender,
  Lenders,
  Login,
  Partners,
  ResetPassword,
  Partner,
  Register,
  Leads,
  Settings,
  Developers,
  ConfirmCode,
  PreAuth,
  GetStarted,
  Metrics,
  Integrations,
  Integration,
  LenderRules,
  WorkFlow,
  CreateWorkFlow,
  LenderRulesTable,
} from '../pages';
import routes from './routes';
import ScrollToTop from './ScrollToTop';
import { history } from './service';

const PublicRoutes: FC = () => {
  const generateClassName = createGenerateClassName({
    seed: 'bs-app',
  });
  return (
    <StylesProvider generateClassName={generateClassName}>
      <Route path={routes.login} component={Login} />
      <Route path={routes.confirmCode} component={ConfirmCode} />
      <Route path={routes.register} component={Register} />
      <Route path={routes.forgotPassword} component={ForgotPassword} />
      <Route path={routes.resetPassword} component={ResetPassword} />
      <Route path={routes.preAuth} component={PreAuth} />
      <Route path={routes.getStarted} component={GetStarted} />

      {/* Redirect when user is logged out because of token expiration issue */}

      <Route exact path="/">
        <Redirect to={routes.login} />
      </Route>

      <Route path={routes.dashboard}>
        <Redirect to={routes.login} />
      </Route>
    </StylesProvider>
  );
};

const Intercom: FC = () => {
  const { account } = useAccount();

  useIntercom(process.env.REACT_APP_INTERCOM_APP_ID as string, account);

  return <></>;
};

const PrivateRoutes: FC = () => {
  const { fetchAccount, error, setError, getAccountUsers } = useAccount();
  const { error: globalError } = useGlobalState();
  const { isAdmin, isPartner, isCompanyOwner, getCurrentAuthenticatedUser } = useAuth();
  const { getAllLenders } = useLenders();
  const { getAllPartners } = usePartners();

  useEffect(() => {
    getCurrentAuthenticatedUser();

    if (isCompanyOwner) return;

    if (isAdmin) {
      getAllLenders();
      getAllPartners();
      return;
    }
    fetchAccount();
    getAccountUsers({ per_page: 10000, page: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const intercomEnabled = !!process.env.REACT_APP_INTERCOM_ENABLED as boolean;

  return (
    <>
      <Layout>
        <Route>
          <Route exact path={routes.home} component={Home} />
          <Route exact path={routes.design} component={Design} />
          <Route exact path={routes.dashboard} component={Home} />
          <Route path={`${routes.lead}/:id`} component={Lead} />
          <Route path={`${routes.lenderDetail}/:id`} component={LenderRulesTable} />
        </Route>

        {!isCompanyOwner && (
          <Route>
            <Route path={routes.leads} component={Leads} />
            <Route path={routes.settings} component={Settings} />
          </Route>
        )}

        {isAdmin && (
          <Route>
            <Route path={routes.lenders} component={Lenders} />
            <Route path={`${routes.lender}/:id`} component={Lender} />
            <Route path={routes.partners} component={Partners} />
            <Route path={`${routes.partner}/:id`} component={Partner} />
            <Route path={routes.admins} component={Admins} />
            <Route path={routes.metrics} component={Metrics} />
            <Route path={routes.integrations} component={Integrations} />
            <Route path={routes.integration} component={Integration} />
            <Route path={routes.lenderRules} component={LenderRules} />
            <Route path={routes.workFlow} component={WorkFlow} />
            <Route path={routes.createWorkFlow} component={CreateWorkFlow} />
          </Route>
        )}

        {isPartner && (
          <Route>
            {/* <Route path={routes.security} component={Security} /> */}
            <Route path={routes.developers} component={Developers} />
            <Route path={routes.metrics} component={Metrics} />

            {intercomEnabled && <Intercom />}
          </Route>
        )}

        <Route exact path="/">
          <Redirect to={routes.home} />
        </Route>

        <Route path="/auth">
          <Redirect to={routes.home} />
        </Route>

        {!globalError && (
          <AlertMessage
            open={!!error}
            onClose={() => setError(false)}
            message={typeof error === 'string' ? error : undefined}
          />
        )}
      </Layout>
    </>
  );
};

const AppRouter: FC = () => {
  const [errorOpened, setErrorOpened] = useState(false);
  const { accessToken } = useAuth();
  const { t } = useTranslation();
  const { error } = useGlobalState();
  useScript(WIDGET_SCRIPT_ID, WIDGET_SCRIPT_ID, WIDGET_BUNDLE_URL);

  useEffect(() => {
    if (error) {
      setErrorOpened(true);
    } else {
      setErrorOpened(false);
    }
  }, [error]);

  const handleConfirm = () => {
    window.open(`mailto:brokers@scorethebusiness.com?subject=${t('global.email.supportSubject')}`);
  };

  const handleCancel = () => {
    setErrorOpened(false);
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Router history={history}>
        <ScrollToTop />

        <Switch>{accessToken ? <PrivateRoutes /> : <PublicRoutes />}</Switch>

        {error && !errorOpened && (
          <AlertMessage
            open
            message={t('global.globalErrorMessage')}
            autoHideDuration={null}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          />
        )}

        <AlertDialog
          open={errorOpened}
          dialogContentTitle={t('global.globalError')}
          dialogContentText={t('global.globalErrorMessage')}
          handleConfirm={handleConfirm}
          handleCancel={handleCancel}
          confirmButtonTitle={t('global.globalErrorAction')}
        />
      </Router>

      {/* Widget is going to rendered using this as the parent element - for admin edit action */}
      <div id={WIDGET_ELEMENT_ID} />
    </MuiPickersUtilsProvider>
  );
};

export default AppRouter;
