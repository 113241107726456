import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),

    '& .MuiGrid-item': {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
  documentUploadContainer: {
    maxWidth: '100%',
    marginBottom: 8,
    '& > div:first-child': {
      marginBottom: -12,
    },
  },
  filter_cardBody: {
    padding: '0px 16px 24px 16px',
  },
  inputLbl: {
    lineHeight: '1.75rem !important',
    marginBottom: '0.5rem !important',
    display: 'block !important',
  },
  fontJost: {
    fontFamily: 'jost !important',
  },
  gray_500: {
    color: `${theme.palette.Gray[500]} !important`,
  },
  fontWeight_500: {
    fontWeight: '500!important',
  },
  modalFooter: {
    marginTop: theme.spacing(8),
  },
}));
