import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertDialog } from 'components/AlertDialog';

interface FDInfoProps {
  handleClose: () => void;
  isOpen: boolean;
  duplicates: string[];
  clearDuplicates: () => void;
  continueUpload: () => void;
}
const FileDropInfo: FC<FDInfoProps> = ({ handleClose, isOpen, duplicates, clearDuplicates, continueUpload }) => {
  const { t } = useTranslation();

  const closeDialog = (upload?: typeof continueUpload) => {
    if (upload) {
      upload();
    }
    clearDuplicates();
    handleClose();
  };

  return (
    <AlertDialog
      open={isOpen}
      handleCancel={() => closeDialog()}
      handleConfirm={() => closeDialog(continueUpload)}
      dialogContentTitle={t('global.uploadDocumentExists')}
      cancelButtonTitle={t('global.buttons.no')}
      confirmButtonTitle={t('global.buttons.yes')}
      dialogContentText={duplicates.join('\n')}
    />
  );
};

export default FileDropInfo;
