import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { InputAdornment } from '@material-ui/core';
import { Done } from '@material-ui/icons';
import { Box, Button, FormControl, ListItemText, Stack, Typography } from '@mui/material';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';

import CustomSelectBox from 'components/CustomSelect/CustomSelect';
import { AlertMessage } from 'components/alerts';
import { AlertMessageStatus, ApplicationNoteType, UserQuery, UserRole } from 'core/types';
import { mapApplicationStatusToLabel } from 'core/utils';
import { setAssignee, updateApplication } from 'http/admin';
import moment from 'moment';
import { useAccount } from 'store/account/hooks';
import { useAdmin } from 'store/admin/hooks';
import { useApplications } from 'store/applications/hooks';
import { ApplicationStatus, ApplicationDetails as IApplicationDetails } from 'store/applications/types';
import { useAuth } from 'store/auth/hooks';
import { composeFullName } from 'utils';
import { CustomInput } from 'pages/home/Home.styles';
import { ApplicationNotes } from '../ApplicationNotes';
import { AutomatedEmailsSwitch } from '../AutomatedEmailsSwitch';
import { CompanyDocuments } from '../CompanyDocuments';
import { UpdateStatusModal } from '../UpdateStatusModal';
import { WidgetMode } from '../WidgetMode';

import useStyles from '../../Lead.styles';
import { ReactComponent as DocumentIcon } from '../../../../assets/icons/document.svg';
import { ReactComponent as RadioBlueIcon } from '../../../../assets/icons/radioBlue.svg';
import { ReactComponent as RadioGrayIcon } from '../../../../assets/icons/radioGray.svg';
import { ReactComponent as TarshBlue } from '../../../../assets/icons/tarshBlue.svg';

const adminQuery: UserQuery = {
  per_page: 1000,
  page: 1,
  role: `${UserRole.ADMIN},${UserRole.SUPER_ADMIN}`,
};

export const CompanyHouse = ({ details }: { details: IApplicationDetails | null }) => {
  const { applicationDetails, updateApplicationDetails } = useApplications();
  const [fundingAmount, setFundingAmount] = useState(details?.funding_amount || '');
  const [assignee, setNewAssignee] = useState<string | undefined>(applicationDetails?.assignee?.id);
  const [saveMessage, setSaveMessage] = useState(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [assignError, setError] = useState<string | undefined>(undefined);

  const { users } = useAccount();
  const classes = useStyles();
  const { isAdmin, isCompanyOwner } = useAuth();
  const { admins, getAdminUsers, setError: setAdminError, setSuccess: setAdminSuccess } = useAdmin();
  const { id: applicationId } = useParams<{ id: string }>();

  const assigneeOptions: { id: string; fullName: string }[] = useMemo(() => {
    if (isAdmin) return admins;
    return users.map((user) => ({ id: user.id, fullName: composeFullName(user.first_name, user.last_name) }));
  }, [admins, isAdmin, users]);

  const setAppAssignee = async (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    const { value } = event.target;
    try {
      await setAssignee(applicationId, (value as string) || undefined);
      setNewAssignee(value as string);
      setSuccess(true);
    } catch (err) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const errMessage = (err as any)?.response?.message;
      setError(errMessage || (err as Error).message);
    }
  };

  const handleSubmit = async () => {
    await updateApplication(applicationDetails?.id || '', { funding_amount: Number(fundingAmount) });
    updateApplicationDetails({ fundingAmount: Number(fundingAmount) });
    setSaveMessage(true);
  };

  useEffect(() => {
    if (isAdmin) {
      getAdminUsers(adminQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdmin]);
  return (
    <>
      <Box className={classes.headingStripIconText}>
        <Box className={classes.headingStripIcon}>
          <DocumentIcon />
        </Box>
        <Box className={classes.headingStripTextWrap}>
          <Typography variant="text_MD" component="h3" className={classes.fontJost}>
            View application details, upload documents and write a note
          </Typography>
        </Box>
      </Box>
      <Box className={clsx([classes.tabInfoWrap])}>
        <Box className={clsx([classes.tabGap_144, classes.flex, classes.width_100])}>
          <Stack
            spacing={{ xs: 2, sm: 4 }}
            direction="row"
            useFlexGap
            flexWrap="nowrap"
            className={clsx([classes.stepWrap, classes.tabInfoLeft])}
          >
            <Box className={clsx([classes.stepLine])}>
              <RadioBlueIcon />
            </Box>
            <Box>
              <Typography variant="text_MD" component="h4">
                Details
              </Typography>
              <Typography variant="text_SM" component="p">
                View application details
              </Typography>
            </Box>
          </Stack>
          <Box className={clsx([classes.stepWrap, classes.tabInfoRight])}>
            <Box
              className={clsx([
                classes.tabInfoRight_box,
                classes.Gap_24,
                classes.flex,
                classes.flexCol,
                classes.tabInfoRight_space,
              ])}
            >
              <Box className={clsx([classes.formInlineLblValue])}>
                <Box className={clsx([classes.formInlineLblValue_lbl])}>
                  <Typography
                    variant="text_MD"
                    component="label"
                    className={clsx([classes.fontJost, classes.fontWeight_500, classes.gray_500])}
                  >
                    Assignee
                  </Typography>
                </Box>
                <Box className={clsx([classes.formInlineLblValue_box])}>
                  <CustomSelectBox
                    options={assigneeOptions.map(({ id, fullName }) => {
                      return { value: id, label: fullName };
                    })}
                    name="stateOverViewFilter"
                    onChange={setAppAssignee}
                    value={assignee || ''}
                    formik={false}
                  />
                </Box>
              </Box>
              <Box className={clsx([classes.formInlineLblValue])}>
                <Box className={clsx([classes.formInlineLblValue_lbl])}>
                  <Typography
                    variant="text_MD"
                    component="label"
                    className={clsx([classes.fontJost, classes.fontWeight_500, classes.gray_500])}
                  >
                    Status
                  </Typography>
                  <Typography variant="text_SM" component="p" className={clsx([classes.fontJost, classes.gray_700])}>
                    Update application status
                  </Typography>
                </Box>
                <Box className={clsx([classes.formInlineLblValue_box])}>
                  {isAdmin && applicationDetails ? (
                    <UpdateStatusModal appStatus={applicationDetails?.status} />
                  ) : (
                    <ListItemText className={classes.listItemText}>
                      {isCompanyOwner ? mapApplicationStatusToLabel(ApplicationStatus.NEW) : ApplicationStatus.NEW}
                    </ListItemText>
                  )}
                </Box>
              </Box>
              <Box className={clsx([classes.formInlineLblValue])}>
                <Box className={clsx([classes.formInlineLblValue_lbl])}>
                  <Typography
                    variant="text_MD"
                    component="label"
                    className={clsx([classes.fontJost, classes.fontWeight_500, classes.gray_500])}
                  >
                    Company name
                  </Typography>
                </Box>
                <Box className={clsx([classes.formInlineLblValue_box])}>
                  <Typography variant="text_MD" component="p" className={clsx([classes.fontJost, classes.gray_500])}>
                    {details?.company?.registered_name}
                  </Typography>
                </Box>
              </Box>
              <Box className={clsx([classes.formInlineLblValue])}>
                <Box className={clsx([classes.formInlineLblValue_lbl])}>
                  <Typography
                    variant="text_MD"
                    component="label"
                    className={clsx([classes.fontJost, classes.fontWeight_500, classes.gray_500])}
                  >
                    Company type
                  </Typography>
                </Box>
                <Box className={clsx([classes.formInlineLblValue_box])}>
                  <Typography variant="text_MD" component="p" className={clsx([classes.fontJost, classes.gray_500])}>
                    {details?.company?.type || '-'}
                  </Typography>
                </Box>
              </Box>
              <Box className={clsx([classes.formInlineLblValue])}>
                <Box className={clsx([classes.formInlineLblValue_lbl])}>
                  <Typography
                    variant="text_MD"
                    component="label"
                    className={clsx([classes.fontJost, classes.fontWeight_500, classes.gray_500])}
                  >
                    Loan amount
                  </Typography>
                </Box>
                <Box className={clsx([classes.formInlineLblValue_box])}>
                  <FormControl variant="standard">
                    <CustomInput
                      name="funding_amount"
                      type="number"
                      className={clsx([classes.iconInputBox])}
                      startAdornment={
                        <InputAdornment position="start">
                          <Typography
                            variant="text_MD"
                            component="span"
                            className={clsx([
                              classes.fontJost,
                              classes.fontWeight_500,
                              classes.black_500,
                              classes.inputIconLeft,
                            ])}
                          >
                            £
                          </Typography>
                        </InputAdornment>
                      }
                      value={fundingAmount || ''}
                      onChange={(e) => setFundingAmount(e?.target?.value)}
                    />
                  </FormControl>
                </Box>
              </Box>
              <Box className={clsx([classes.formInlineLblValue])}>
                <Box className={clsx([classes.formInlineLblValue_lbl])}>
                  <Typography
                    variant="text_MD"
                    component="label"
                    className={clsx([classes.fontJost, classes.fontWeight_500, classes.gray_500])}
                  >
                    Date submitted
                  </Typography>
                </Box>
                <Box className={clsx([classes.formInlineLblValue_box])}>
                  <Typography variant="text_MD" component="p" className={clsx([classes.fontJost, classes.gray_500])}>
                    {moment(details?.created_at).format('DD/MM/YYYY')}
                  </Typography>
                </Box>
              </Box>
              {isAdmin && applicationDetails && (
                <Box className={clsx([classes.formInlineLblValue])}>
                  <Box className={clsx([classes.formInlineLblValue_lbl])}>
                    <Typography
                      variant="text_MD"
                      component="label"
                      className={clsx([classes.fontJost, classes.fontWeight_500, classes.gray_500])}
                    >
                      Automated Emails
                    </Typography>
                  </Box>
                  <Box className={clsx([classes.formInlineLblValue_box])}>
                    <AutomatedEmailsSwitch
                      applicationId={applicationDetails.id}
                      initialActive={!!applicationDetails.active_automated_emails}
                      setError={setAdminError}
                      setSuccess={setAdminSuccess}
                    />
                  </Box>
                </Box>
              )}
              {(isAdmin || isCompanyOwner) && <WidgetMode />}
            </Box>
          </Box>
        </Box>
        <Box className={clsx([classes.tabGap_144, classes.flex, classes.width_100])}>
          <Stack
            spacing={{ xs: 2, sm: 4 }}
            direction="row"
            useFlexGap
            flexWrap="nowrap"
            className={clsx([classes.stepWrap, classes.tabInfoLeft])}
          >
            <Box className={clsx([classes.stepLine])}>
              <RadioGrayIcon />
            </Box>
            <Box>
              <Typography variant="text_MD" component="h4">
                Documents
              </Typography>
              <Typography variant="text_SM" component="p">
                Upload a document
              </Typography>
            </Box>
          </Stack>
          <Box className={clsx([classes.stepWrap, classes.tabInfoRight])}>
            <Box
              className={clsx([
                classes.tabInfoRight_box,
                classes.Gap_24,
                classes.flex,
                classes.flexCol,
                classes.tabInfoRight_space,
              ])}
            >
              <CompanyDocuments company={details?.company} appId={details?.id || ''} />
            </Box>
          </Box>
        </Box>
        <Box className={clsx([classes.tabGap_144, classes.flex, classes.width_100])}>
          <Stack
            spacing={{ xs: 2, sm: 4 }}
            direction="row"
            useFlexGap
            flexWrap="nowrap"
            className={clsx([classes.stepWrap, classes.tabInfoLeft])}
          >
            <Box>
              <RadioGrayIcon />
            </Box>
            <Box>
              <Typography variant="text_MD" component="h4">
                Notes
              </Typography>
              <Typography variant="text_SM" component="p">
                View application notes
              </Typography>
            </Box>
          </Stack>
          <Box className={clsx([classes.stepWrap, classes.tabInfoRight])}>
            <Box className={clsx([classes.tabInfoRight_space])}>
              <Box className={clsx([classes.flex, classes.flexCol, classes.gap_32])}>
                {/* TextArea Box Start */}
                <Box className={clsx([classes.textareaWrap, classes.flex, classes.flexCol])}>
                  <Box className={clsx([classes.textareaLblWrap])}>
                    <Box className={clsx([classes.textareaLblWrap_lbl])}>
                      <Typography
                        variant="text_MD"
                        component="label"
                        className={clsx([classes.fontJost, classes.fontWeight_500, classes.gray_500])}
                      >
                        Internal note
                      </Typography>
                    </Box>
                    <Box className={clsx([classes.textareaLblWrap_action])}>
                      <Box className={classes.dashboardPaletteLink}>
                        <Box className={classes.dashboardPaletteLinkItem}>
                          <Typography
                            variant="text_MD"
                            component="span"
                            className={clsx([classes.fontJost, classes.fontWeight_400, classes.primary_600])}
                          >
                            Delete
                          </Typography>
                          <TarshBlue className={classes.dashboardPaletteLinkIcon} />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box className={clsx([classes.textareaWrapInner])}>
                    <ApplicationNotes
                      type={ApplicationNoteType.INTERNAL}
                      applicationId={details?.id || ''}
                      initialNotes={details?.internal_notes}
                    />
                  </Box>
                </Box>
                {/* TextArea Box End */}
                {/* TextArea Box Start */}
                <Box className={clsx([classes.textareaWrap, classes.flex, classes.flexCol])}>
                  <Box className={clsx([classes.textareaLblWrap])}>
                    <Box className={clsx([classes.textareaLblWrap_lbl])}>
                      <Typography
                        variant="text_MD"
                        component="label"
                        className={clsx([classes.fontJost, classes.fontWeight_500, classes.gray_500])}
                      >
                        Internal note
                      </Typography>
                    </Box>
                    <Box className={clsx([classes.textareaLblWrap_action])}>
                      <Box className={classes.dashboardPaletteLink}>
                        <Box className={classes.dashboardPaletteLinkItem}>
                          <Typography
                            variant="text_MD"
                            component="span"
                            className={clsx([classes.fontJost, classes.fontWeight_400, classes.primary_600])}
                          >
                            Delete
                          </Typography>
                          <TarshBlue className={classes.dashboardPaletteLinkIcon} />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box className={clsx([classes.textareaWrapInner])}>
                    <ApplicationNotes
                      type={ApplicationNoteType.EXTERNAL}
                      applicationId={details?.id || ''}
                      initialNotes={details?.external_notes}
                    />
                  </Box>
                </Box>
              </Box>
              <Button variant="contained" onClick={handleSubmit} sx={{ width: 1, mt: 8 }} className={classes.cmn_btn}>
                Save all changes
                <Done className={classes.cmn_btn_icon} />
              </Button>
            </Box>
          </Box>
        </Box>
        {Boolean(assignError) && (
          <AlertMessage open={Boolean(assignError)} onClose={() => setError(undefined)} message={assignError} />
        )}
        {saveMessage && (
          <AlertMessage
            open={saveMessage}
            message="Successfully save all changes"
            onClose={() => setSaveMessage(false)}
            autoHideDuration={5000}
            status={AlertMessageStatus.SUCCESS}
          />
        )}

        {success && (
          <AlertMessage
            open={success}
            onClose={() => setSuccess(false)}
            autoHideDuration={5000}
            status={AlertMessageStatus.SUCCESS}
          />
        )}
      </Box>
    </>
  );
};
