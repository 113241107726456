import { FC } from 'react';
import { Box, Link, Paper, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { ApiSecret } from '../ApiSecret';
import { KeyType, Display } from '../ApiSecret/ApiSecret';
import useStyles from './ApiKeys.styles';

interface ApiKeysProps {
  publicKey: string;
  secretKey: string;
}

const ApiKeys: FC<ApiKeysProps> = ({ publicKey, secretKey }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box>
      <Typography variant="caption" className={classes.mainSubtitle} display="block">
        {t('pages.api.subtitle')} <Link href={t('pages.auth.footer.links.docsUrl')}>{t('global.learnMore')}</Link>
      </Typography>

      <Paper elevation={2} className={classes.keysContainer}>
        <Typography className={classes.subtitle} gutterBottom>
          {t('pages.api.apiKeys.title')}
        </Typography>

        <ApiSecret
          title={t('pages.api.apiKeys.public_key')}
          description={t('pages.api.apiKeys.message')}
          secret={publicKey}
          type={KeyType.PUBLIC}
          name="public_key"
        />

        <Typography className={classes.subtitle}>{t('pages.api.secrets.title')}</Typography>

        <ApiSecret
          title={t('pages.api.secrets.secretKey')}
          description={t('pages.api.secrets.message')}
          secret={secretKey}
          display={Display.MASK}
          type={KeyType.SECRET}
          name="secret_key"
        />
      </Paper>
    </Box>
  );
};

export default ApiKeys;
