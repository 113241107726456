import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  title: {
    fontSize: theme.typography.pxToRem(20),
    lineHeight: theme.typography.pxToRem(24),
    marginBottom: 8,
    fontWeight: 500,
    color: theme.palette.Gray[700],
  },
  subtitle: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(20),
    marginBottom: 32,
    fontWeight: 400,
  },
  rulesContainer: {
    '& p, & span, & li': {
      textTransform: 'capitalize',
    },
  },
  rulesSectionTitle: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20),
    fontWeight: 600,
    marginTop: theme.spacing(2),
  },
  table: {
    '& .MuiTableRow-root td:nth-child(2)': {
      textTransform: 'none',
    },
  },
  highlightedRow: {
    backgroundColor: theme.palette.action.hover,
    '& span': {
      fontWeight: 600,
    },
  },
  switchLabel: {
    marginRight: theme.spacing(0.5),
    '& > span': {
      fontSize: theme.typography.pxToRem(13),
      lineHeight: theme.typography.pxToRem(18),
    },
  },
  row: {
    cursor: 'auto',
  },
  fontSize14: {
    fontSize: '14px !important',
  },
  marginBottom40: {
    marginBottom: '40px !important',
  },
  marginBottom88: {
    marginBottom: '88px !important',
  },
  tableRowBgColor: {
    backgroundColor: theme.palette.Gray[200],
    color: theme.palette.Gray[700],
    '& .MuiDataGrid-row:hover': {
      backgroundColor: 'inherit',
    },
  },
  switchContainer: {
    display: 'flex',
    position: 'relative',
    width: 120,
  },
}));
