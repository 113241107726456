import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  container: {
    // bottom: 35,
  },
  title: {
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(24),
    fontWeight: 700,
    color: theme.palette.common.white,
  },
  message: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20),
  },
  snackbarTheme: {
    borderRight: '0px solid #8C92AC',
  },
  snackbarAlertTheme: {
    padding: `${theme.spacing(4)} !important`,
    backgroundColor: `${theme.palette.Blue[25]} !important`,
    color: theme.palette.Blue[600],
    border: `2px solid ${theme.palette.Blue[500]}`,
    borderRadius: '0.5rem !important',
    '& .bs-app-MuiAlert-icon': {
      color: `${theme.palette.Blue[700]} !important`,
    },
    '& .bs-app-MuiAlert-message, & .MuiAlert-message': {
      color: `${theme.palette.Blue[700]} !important`,
      fontFamily: 'Jost',
      fontWeight: 500,
      fontSize: `${theme.typography.pxToRem(18)} !important`,
    },
  },
  snackbarErrorTheme: {
    padding: `${theme.spacing(4)} !important`,
    backgroundColor: `${theme.palette.Red[50]} !important`,
    color: theme.palette.Blue[600],
    border: `2px solid ${theme.palette.Red[700]}`,
    borderRadius: '0.5rem !important',
    '& .bs-app-MuiAlert-icon': {
      color: `${theme.palette.Red[700]} !important`,
    },
    '& .bs-app-MuiAlert-message, & .MuiAlert-message': {
      color: `${theme.palette.Red[700]} !important`,
      fontFamily: 'Jost',
      fontWeight: 500,
      fontSize: `${theme.typography.pxToRem(18)} !important`,
    },
  },
}));
