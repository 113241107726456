import { FC, useState, useCallback, useEffect } from 'react';
import {
  Accordion as MuiAccordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Card,
  CardHeader,
  CardContent,
  CircularProgress,
  Box,
  withStyles,
  Divider,
} from '@material-ui/core';
import { ExpandMoreOutlined } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import { getEmails as getEmailsApi } from 'http/admin';
import { Email } from 'store/admin/types';
import { usePrevious } from 'hooks';
import { useAdmin } from 'store/admin/hooks';
import { EmailType } from 'core/types';
import useStyles from './ReceivedEmailList.styles';
import { DocumentList } from '../CompanyDocuments/components';

interface ReceivedEmailListProps {
  applicationId: string;
  companyId: string;
}

const Accordion = withStyles({
  root: {
    border: '0px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const ReceivedEmailList: FC<ReceivedEmailListProps> = ({ applicationId, companyId }) => {
  const [receivedEmails, setReceivedEmails] = useState<Email[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | boolean>(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const { refreshEmails, setRefreshEmails } = useAdmin();
  const prevRefreshEmails = usePrevious(refreshEmails);
  const [expanded, setExpanded] = useState<boolean>(false);

  const handleAccordionChange = (open: boolean) => () => {
    setExpanded(!open);
  };

  const getReceivedEmails = useCallback(async () => {
    setLoading(true);
    try {
      const data = await getEmailsApi(applicationId, EmailType.RECEIVED);
      setReceivedEmails(data);
    } catch (err) {
      setError((err as Error)?.message ?? true);
    }
    setRefreshEmails(false);
    setLoading(false);
  }, [applicationId, setRefreshEmails]);

  useEffect(() => {
    getReceivedEmails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!prevRefreshEmails && refreshEmails) {
      getReceivedEmails();
    }
  }, [getReceivedEmails, prevRefreshEmails, refreshEmails]);

  return (
    <Accordion expanded={expanded} onChange={handleAccordionChange(expanded)}>
      <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
        <Typography className={classes.heading}>{t('pages.lead.sections.receivedEmailList.title')}</Typography>
        <Typography className={classes.secondaryHeading}>
          {t('pages.lead.sections.receivedEmailList.subtitle')}
        </Typography>
      </AccordionSummary>

      <AccordionDetails className={classes.detailsContainer}>
        {loading && (
          <Box className={classes.centerContainer}>
            <CircularProgress size={30} />
          </Box>
        )}

        {error && (
          <Box className={classes.centerContainer}>
            <Typography className={classes.error}>
              {typeof error === 'string' ? error : t('global.globalError')}
            </Typography>
          </Box>
        )}

        {receivedEmails.map((email) => (
          <Card key={email.id} className={classes.cardContainer} elevation={1}>
            <CardHeader
              subheader={
                <>
                  <Typography className={classes.emailSubheader}>
                    {t('global.email.from')} <strong>{email.from}</strong>
                  </Typography>
                  <Typography className={classes.emailSubheader}>
                    {t('global.email.subject')} {email.subject}
                  </Typography>
                  <Typography className={classes.emailSubheader}>
                    {t('global.email.received')} <strong>{new Date(email.created_at).toLocaleDateString()}</strong>
                  </Typography>
                  <Typography className={classes.emailSubheader}>
                    {t('global.email.maskEmail')} <strong>{email.cc}</strong>
                  </Typography>
                  <Divider light />
                </>
              }
            />
            <CardContent>
              {email.attachments?.length && (
                <DocumentList
                  title={t('pages.lead.sections.emailList.attachments')}
                  companyId={companyId}
                  documents={email.attachments}
                />
              )}

              {/* eslint-disable-next-line react/no-danger */}
              <div dangerouslySetInnerHTML={{ __html: email.message }} />
            </CardContent>
          </Card>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default ReceivedEmailList;
