import { FC } from 'react';
import { Box, Card, Typography } from '@material-ui/core';
import { CardContent, Stack } from '@mui/material';
import clsx from 'clsx';
import moment from 'moment';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { getDateFromDateOnly, numberToPercentage } from 'utils';
import useStyles from './Overview.styles';

interface OverviewProps {
  totalCount: number;
  data: { date: string; amount: number }[];
  name: string;
  total: number;
}

const Overview: FC<OverviewProps> = ({ name, total, data, totalCount }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const startDate = data[0];
  const endDate = data?.slice(-1)[0];
  const dateFormat = startDate && startDate?.date?.length === 7 ? 'MMM YYYY' : 'MMM DD';

  return (
    <Card className={classes.metricsBoxTab}>
      <CardContent sx={{ p: 0 }}>
        <Stack sx={{ mb: 6 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
          <Typography className={classes.fontJost} component="p">
            {total} {name?.split('_')?.join(' ')}
          </Typography>
          <Typography className={clsx([classes.fontJost, classes.perChip])} component="span">
            {numberToPercentage(total / totalCount)}
          </Typography>
        </Stack>
        <Box className={classes.metricsBoxTabChart}>
          <ReactApexChart
            options={{
              chart: {
                height: 50,
                width: 1,
                type: 'line',
                toolbar: {
                  show: false,
                },
              },
              stroke: {
                width: 1,
                curve: 'smooth',
              },
              xaxis: {
                labels: {
                  show: false,
                },
                axisBorder: {
                  show: false,
                },
                axisTicks: {
                  show: false,
                },
              },
              yaxis: {
                show: false,
                labels: {
                  show: false,
                },
              },
              labels: data?.map((d) => d?.date),
              colors: ['#0086C9'],
            }}
            series={[
              {
                name: 'Count',
                data: data?.map((d) => d?.amount),
              },
            ]}
          />
        </Box>
        {data.length > 0 && (
          <Box className={classes.labelContainer}>
            {startDate && (
              <Typography className={classes.label}>
                {moment(getDateFromDateOnly(startDate.date)).format(dateFormat)}
              </Typography>
            )}
            {endDate && (
              <Typography className={classes.label}>
                {moment(getDateFromDateOnly(endDate.date)).isSame(moment(), 'day')
                  ? t('global.time.today')
                  : moment(getDateFromDateOnly(endDate.date)).format(dateFormat)}
              </Typography>
            )}
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default Overview;
