export interface Rate {
  rateId: string;
  selected: boolean;
  interestRate: number;
  principal: number;
  effectiveOnDay: Date | null;
  monthlyRepayment: number;
  totalRepayment: number;
  monthlyRepaymentType: string; // You might want to define a more specific type if applicable
}

export interface IApplicationOffer {
  id: string;
  productType: string;
  applicationId: string;
  type: string;
  currency: string;
  durationInMonths: number;
  externalId: string | null;
  lenderId: string;
  lenderInternalName: string;
  lenderDisplayName: string;
  lenderLogo: string;
  lenderRawResponse: string | null;
  maxCreditOffer: number;
  minCreditOffer: number;
  notes: string;
  rejectionReason: string | null;
  status: string;
  rates?: Rate[];
  approvalValidUntil: Date;
  createdAt: Date;
  updatedAt: Date;
  metadata: string | null;
  rejectionReasonTags: string[];
  declineReason: string | null;
}

export interface ApplicationState {
  loading: boolean;
  error: boolean | string;
  success: boolean | string;
  applicationOffer: IApplicationOffer[];
  total: number;
}

export interface IPartnerQuery {
  page: number;
  per_page?: number;
  search?: string;
  type?: string;
  status?: string;
}

export interface PartnerQuery {
  id: string;
  query: IOfferQuery;
}

export interface ApplicationsOfferReturnHook {
  getApplicationOffer: (query: PartnerQuery) => void;
}

export interface ApplicationsOfferListResponse {
  offers: IApplicationOffer[];
  total: number;
  page: number;
}

export interface IOfferQuery {
  page: number;
  per_page?: number;
  search?: string;
  product_type?: string;
  status?: string;
}

export enum IOfferStatus {
  PENDING = 'PENDING',
  DEFERRED = 'DEFERRED',
  DECLINED = 'DECLINED',
  ADDITIONAL_DETAILS_REQUESTED = 'ADDITIONAL_DETAILS_REQUESTED',
  OFFERED = 'OFFERED',
  ACCEPTED = 'ACCEPTED',
  CANCELLED = 'CANCELLED',
  LENDER_CALL_FAILED = 'LENDER_CALL_FAILED',
  RULES_DECLINED = 'RULES_DECLINED',
  APPLICANT_DECLINED = 'APPLICANT_DECLINED',
}

export enum ProductType {
  REVENUE_BASED = 'REVENUE_BASED',
  CREDIT_LINE = 'CREDIT_LINE',
  REVOLVING_CREDIT_FACILITY = 'REVOLVING_CREDIT_FACILITY',
  TERM_LOAN = 'TERM_LOAN',
  UNKNOWN = 'UNKNOWN',
  BUSINESS_CREDIT_CARD = 'BUSINESS_CREDIT_CARD',
}

export enum IOfferType {
  API = 'API',
  MANUAL = 'MANUAL',
}
