import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '24px',
  },
  searchInput: {
    flex: 1,
  },
  customTable: {
    marginTop: '20px',
  },
  modal_main: {
    width: '620px',
  },
  navigateArrowWrap: {
    display: 'flex',
    maxWidth: '164px',
    justifyContent: 'space-between',
  },
  navigateArrow: {
    width: '24px',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '100px',
    color: theme.palette.Gray[900],
    transition: 'all 0.3s',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.Gray[300],
    },
    '&:active': {
      scale: 0.85,
    },
  },
  navigateDisabled: {
    color: theme.palette.Gray[400],
    cursor: 'not-allowed',
    '&:active': {
      scale: 1,
    },
    '&:hover': {
      backgroundColor: theme.palette.Gray[300],
    },
  },
}));

export default useStyles;
