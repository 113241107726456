import { Theme } from '@mui/material';

import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  cardInGrid: {
    boxShadow: '0px 1px 2px 0px #1018280D',
    padding: '24px',
    backgroundColor: '#fff',
    height: '100%',
    borderRadius: '1rem',
  },
  c_card: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(4),
    },
    // backgroundColor: theme.palette.primary.contrastText,
  },
  c_cardTitle: {
    display: 'flex',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(6),
  },
  c_cardTitleText: {
    fontSize: `${theme.typography.pxToRem(16)} !important`,
    lineHeight: `${theme.typography.pxToRem(28)} !important`,
    color: `${theme.palette.Gray[600]} !important`,
    fontWeight: '500 !important',
    fontFamily: '"Jost" !important',
  },
  c_cardBody: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
  },
  datePickerContainer: {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid',
    borderColor: '#D0D5DD',
    borderRadius: '4px',
    padding: '0 0.5rem',
    [theme.breakpoints.down(494)]: {
      marginTop: theme.spacing(2),
    },
  },
  datePicker_wrap: {
    width: 'auto !important',
  },
  flex_item: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  home_datePicker: {
    '&:before': {
      display: 'none !important',
    },
    '& input': {
      width: '100px !important',
      textAlign: 'left !important',
    },
  },
  subtitle: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20),
  },
  semibold: {
    fontFamily: 'inter semibold',
  },
  selectContainer: {
    // marginRight: theme.spacing(2),
    '& .bs-app-MuiInputBase-root': {
      outline: 'none !important',
    },
    '& .bs-app-MuiSelect-root': {
      height: 36,
      minWidth: '7.5em',
      paddingTop: 0,
      paddingBottom: 0,
      paddingRight: 16,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      fontWeight: 700,
    },
  },
  select: {
    root: {
      '&:hover:not($disabled):before': {
        borderColor: '#D0D5DD',
      },
      '&.Mui-focused:before': {
        borderColor: '#D0D5DD',
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#D0D5DD',
    },
    '&:focus-within .MuiOutlinedInput-notchedOutline': {
      borderColor: '#D0D5DD',
    },
  },
  selectBoxDropdown: {
    color: `${theme.palette.Gray[700]} !important`,
    borderRadius: '8px !important',
    fontWeight: '400 !important',
    '& .bs-app-MuiSelect-root': {
      fontWeight: '400 !important',
    },
  },

  status_overview_icon_padding: {
    padding: '9.816px 7.705px 12.153px 14.263px',
  },
  statusOverViewPageNotFound: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.Gray[700],
  },
  chartGroup: {
    height: '600px',
  },
  cardGrey: {
    background: `${theme.palette.Gray[50]} !important `,
    border: '1px solid',
    borderColor: `${theme.palette.Gray[200]} !important `,
    borderRadius: '0.5rem',
    padding: '0.5rem',
    boxShadow: '0px 1px 2px 0px #1018280D',
  },
}));
