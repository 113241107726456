import { FC, useState, useEffect, useCallback } from 'react';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { confirmPreAuthSession as confirmPreAuthSessionApi } from 'http/auth';
import routes from 'router/routes';
import { useWidget } from 'hooks';
import useStyles from './PreAuth.styles';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const PreAuth: FC = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | boolean>(false);
  const query = useQuery();
  const classes = useStyles();
  const { t } = useTranslation();
  const { openWidget } = useWidget();

  const getPreAuthSession = useCallback(
    async (token: string, email: string) => {
      setLoading(true);
      try {
        const { public_key: publicKey, access_token: accessToken } = await confirmPreAuthSessionApi(token, email);

        if (!publicKey || !accessToken) return;

        openWidget(publicKey, accessToken);
      } catch (err) {
        setError((err as Error).message ?? true);
      }
      setLoading(false);
    },
    [openWidget],
  );

  useEffect(() => {
    const token = query.get('token');
    const email = query.get('email');

    if (token && email) {
      getPreAuthSession(token, email);
      // Clear url query parameters //
      window.history.replaceState({}, document.title, routes.preAuth);
      return;
    }

    setError(t('pages.preAuth.messages.error') as string);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className={classes.container}>
      {loading && (
        <Box className={classes.loaderContainer}>
          <CircularProgress size={100} thickness={2} />
        </Box>
      )}

      {error && (
        <Typography className={classes.text}>{typeof error === 'string' ? error : t('global.error')}</Typography>
      )}
    </Box>
  );
};

export default PreAuth;
