import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Container, Grid } from '@material-ui/core';
import { GridRenderCellParams, GridRowParams } from '@mui/x-data-grid';
import clsx from 'clsx';
import { CommonChip } from 'components/CommonChip/CommonChip';
import CustomFilter from 'components/CustomTable/CustomFilter/CustomFilter';
import CustomTable from 'components/CustomTable/CustomTable';
import { Header } from 'components/Header/Header';
import FilterModal from 'components/Modal/FilterModal';
import { AlertMessage } from 'components/alerts';
import { LenderFilterOption, ROWS_PER_TABLE_PAGE } from 'core/constants';
import { AlertMessageStatus, ILenderFilterData, LenderQuery, LendersTableColumnNames } from 'core/types';
import { useDebounce } from 'hooks';
import { getLenders as getAllLenders } from 'http/lenders';
import {
  SEARCH_DEBOUNCE_DELAY,
  downloadCSVFile,
  firstLatterCapital,
  getParamsValue,
  statueReplaceBooleanData,
} from 'pages/leads/components/CreateApplicationModal/utils';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import routes from 'router/routes';
import { useGlobalState } from 'store/global/hooks';
import { useLenders } from 'store/lenders/hooks';
import useCommonStyles from '../../assets/css/Common.styles';
import useStyles from './Lenders.styles';
import { LenderAdminActions } from './components';

const Lenders: FC = () => {
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>('');
  const debouncedSearchTerm = useDebounce(search, SEARCH_DEBOUNCE_DELAY);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [tabValue, setTabValue] = useState<number | string>(0);
  const [lenderFilterData, setLenderFilterData] = useState<ILenderFilterData>({
    region: [],
    status: [],
  });
  const [showLenderFilterData, setShowLenderFilterData] = useState<ILenderFilterData>({
    region: [],
    status: [],
  });
  const [queryData, setQueryData] = useState({
    active: '',
    supportedRegions: '',
    per_page: ROWS_PER_TABLE_PAGE,
  });

  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const commonClasses = useCommonStyles();
  const { error: globalError } = useGlobalState();
  const { loading, lenders, error, total, success, setError, setSuccess, getLenders } = useLenders();

  const toggleFilterModal = () => setOpenFilterModal(!openFilterModal);

  const handleFilterData = (event: ChangeEvent<HTMLInputElement>) => {
    const data: string = Object.keys(lenderFilterData)[+tabValue];

    if (event?.target?.checked) {
      setLenderFilterData({
        ...lenderFilterData,
        [data]:
          data === 'status'
            ? [event?.target?.name]
            : [...lenderFilterData[data as keyof typeof lenderFilterData], event?.target?.name],
      });
    } else {
      setLenderFilterData({
        ...lenderFilterData,
        [data]: lenderFilterData[data as keyof typeof lenderFilterData]?.filter(
          (d: string) => d !== event?.target?.name,
        ),
      });
    }
  };

  const handleRemoveFilter = (data: string, name: string) => {
    const values: ILenderFilterData = {
      ...lenderFilterData,
      [name]: lenderFilterData[name as keyof typeof lenderFilterData]?.filter((d: string) => d !== data),
    };
    setQueryData({
      ...queryData,
      supportedRegions: values?.region?.join(','),
      active: statueReplaceBooleanData(values?.status)?.join(','),
    });
    setShowLenderFilterData(values);
    setLenderFilterData(values);
  };

  const handleApplyFilter = () => {
    setQueryData({
      ...queryData,
      supportedRegions: lenderFilterData?.region?.length === 3 ? '' : lenderFilterData?.region?.join(','),
      active: statueReplaceBooleanData(lenderFilterData?.status)?.join(','),
    });
    setShowLenderFilterData(lenderFilterData);
    toggleFilterModal();
  };

  const columns = [
    {
      field: LendersTableColumnNames.LENDER_NAME,
      width: 300,
      headerName: t('pages.lenders.table.columns.lenderName'),
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Box className={classes.lender_with_image}>
            {params?.row?.logo_url && <img src={params?.row?.logo_url} alt="Lender" className={classes.lenderLogo} />}
            <Box className={classes.marginTop}>{params?.row?.name || '-'}</Box>
          </Box>
        );
      },
    },
    {
      field: LendersTableColumnNames.REGIONS,
      width: 200,
      headerName: t('pages.lenders.table.columns.regions'),
      renderCell: (params: GridRenderCellParams) => {
        return params?.row?.supported_regions?.join(',') || '-';
      },
    },
    {
      field: LendersTableColumnNames.USER_NAME,
      width: 300,
      headerName: t('pages.lenders.table.columns.userName'),
      renderCell: (params: GridRenderCellParams) => {
        return params?.row?.contact_person_name || '-';
      },
    },
    {
      field: LendersTableColumnNames.EMAIL,
      width: 250,
      headerName: t('pages.lenders.table.columns.email'),
      renderCell: (params: GridRenderCellParams) => {
        return params?.row?.contact_person_email || '-';
      },
    },
    {
      field: LendersTableColumnNames.ROLE,
      width: 200,
      headerName: t('pages.lenders.table.columns.role'),
      renderCell: (params: GridRenderCellParams) => {
        return params?.row?.role ? firstLatterCapital(params?.row?.role?.split('_')?.join(' ')) : '-';
      },
    },
    {
      field: LendersTableColumnNames.ENABLED,
      width: 200,
      headerName: t('pages.lenders.table.columns.enabled'),
      renderCell: (params: GridRenderCellParams) => {
        return (
          <span
            className={clsx([
              commonClasses.badge,
              params?.row?.active ? commonClasses.accepted : commonClasses.rejected,
            ])}
          >
            {params?.row?.active ? 'Yes' : 'No'}
          </span>
        );
      },
    },
  ];

  const lenderQueryData: LenderQuery = useMemo(
    () => ({
      page,
      ...getParamsValue({
        ...queryData,
        search: debouncedSearchTerm,
      }),
    }),
    [page, queryData, debouncedSearchTerm],
  );

  const downloadCSV = async () => {
    await getAllLenders().then((res) => {
      if (res?.lenders?.length > 0) {
        const lendersData = res?.lenders?.map((d) => {
          return {
            Lender: d?.name || '-',
            'Region(s)': d?.supported_regions?.join('') || '-',
            'Contact Name': d?.contact_person_name || '-',
            'Contact Email': d?.contact_person_email || '-',
            Role: (d?.role && firstLatterCapital(d?.role?.split('_')?.join(' '))) || '-',
            Enabled: d?.active,
          };
        });
        downloadCSVFile(lendersData, 'BScore_Lenders.csv');
      }
    });
  };

  const getAllLenderData = useCallback(() => {
    getLenders(lenderQueryData);
  }, [getLenders, lenderQueryData]);

  useEffect(() => {
    getAllLenderData();
  }, [getAllLenderData]);

  return (
    <Container disableGutters maxWidth="xl" style={{ minWidth: 'unset', overflow: 'unset' }}>
      <Header name="Lenders" />
      <Box className={commonClasses.mainWrapper}>
        <Box className={commonClasses.filterMenu}>
          <LenderAdminActions />
        </Box>
        <Box className={commonClasses.cardShadow}>
          <Grid item xs={12} className={clsx([commonClasses.tableContainer])}>
            <CustomFilter
              downloadCSV={downloadCSV}
              toggleFilterModal={toggleFilterModal}
              total={total}
              searchPlaceholder="Search by lender name"
              page={page}
              pageSize={ROWS_PER_TABLE_PAGE}
              setPage={setPage}
              setSearch={setSearch}
              search={search}
            />
            <CommonChip data={showLenderFilterData} handleRemoveFilter={handleRemoveFilter} />
            <CustomTable
              rows={lenders}
              loading={loading}
              columns={columns}
              headerUpperCase={true}
              onRowClick={(item: GridRowParams) => {
                if (item?.id) {
                  history.push(`${routes.lender}/${item?.id}`);
                }
              }}
              hideFooterPagination={true}
              NoDataFountMessage="No Data Found"
              disableColumnMenu={true}
            />
          </Grid>
          {openFilterModal && (
            <FilterModal
              setValue={setTabValue}
              value={+tabValue}
              filterTabName={['Region', 'Status']}
              open={openFilterModal}
              width="width25"
              onClose={toggleFilterModal}
              filterOption={LenderFilterOption}
              handleApplyFilter={handleApplyFilter}
              filterData={lenderFilterData}
              handleFilterData={handleFilterData}
            />
          )}
          {!globalError && (
            <>
              <AlertMessage
                open={!!error}
                onClose={() => setError(false)}
                message={typeof error === 'string' ? error : undefined}
                autoHideDuration={5000}
              />

              <AlertMessage
                open={!!success}
                onClose={() => setSuccess(false)}
                message={typeof success === 'string' ? success : undefined}
                autoHideDuration={5000}
                status={AlertMessageStatus.SUCCESS}
              />
            </>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default Lenders;
