import { useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { useActionCreator, usePrevious } from 'hooks';
import { RootState } from 'store/types';
import {
  getPartners,
  createPartner,
  setError,
  setSuccess,
  getTrustedDomains,
  saveTrustedDomains,
  getPartnerDetails,
  getAllPartners,
  setPartnerDetails,
  updatePartner,
} from './actions';
import { PartnersReturnHook } from './types';

export const usePartners = (onSuccess?: () => void): PartnersReturnHook => {
  const partnersState = useSelector((state: RootState) => state.partners, shallowEqual);
  const prevLoading = usePrevious(partnersState.loading);

  useEffect(() => {
    if (prevLoading && !partnersState.loading && !partnersState.error && onSuccess) {
      onSuccess();
    }
  }, [partnersState.error, partnersState.loading, onSuccess, prevLoading]);

  return {
    ...partnersState,
    getPartners: useActionCreator(getPartners),
    getPartnerDetails: useActionCreator(getPartnerDetails),
    createPartner: useActionCreator(createPartner),
    setError: useActionCreator(setError),
    setSuccess: useActionCreator(setSuccess),
    getTrustedDomains: useActionCreator(getTrustedDomains),
    saveTrustedDomains: useActionCreator(saveTrustedDomains),
    getAllPartners: useActionCreator(getAllPartners),
    setPartnerDetails: useActionCreator(setPartnerDetails),
    updatePartner: useActionCreator(updatePartner),
  };
};
