import { AccountType } from 'store/applications/types';
import { AccountRoles, ApiKeysQuery } from 'core/types';
import { Account, ApiKeys } from 'store/account/types';
import { apiCall } from '../index';

export const fetchAccount = async (): Promise<Account> =>
  apiCall({ method: 'get', url: '/dashboard/account?include_keys=1' });

export const fetchApiKeys = async (query: ApiKeysQuery = {}): Promise<ApiKeys> => {
  const queryString = new URLSearchParams(query as Record<string, string>).toString();
  return apiCall({ method: 'get', url: `/dashboard/api_keys?${queryString}` });
};

export const deleteAccountUser = async (accountType: AccountType, id: string): Promise<null> =>
  apiCall({ method: 'delete', url: `/${accountType}/user/${id}` });

export const updateAccountUserRole = async (accountType: AccountType, id: string, role: AccountRoles): Promise<null> =>
  apiCall({ method: 'put', url: `/${accountType}/user/${id}`, data: { role } });
