import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  documentsRequested: {
    '& .MuiFormControlLabel-root .MuiTypography-body1': {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
    },
  },
  notesContainer: {
    paddingTop: '0px !important',
  },
  textAreaError: {
    borderColor: `${theme.palette.error.main} !important`,
  },
  c_card: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(4),
    },
  },
  c_cardTitle: {
    display: 'flex',
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
  },
  c_cardTitleText: {
    fontSize: `${theme.typography.pxToRem(16)} !important`,
    lineHeight: `${theme.typography.pxToRem(28)} !important`,
    color: `${theme.palette.Gray[600]} !important`,
    fontWeight: '500 !important',
    fontFamily: '"Jost" !important',
  },
  c_cardBody: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
  },
  cardShadow: {
    boxShadow: '0px 2px 24px 0px #9292921A',
    backgroundColor: theme.palette.primary.contrastText,
    borderRadius: theme.spacing(3),
  },
  filter_cardBody: {
    padding: '0px 16px 24px 16px',
  },
  filter_cardTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px',

    '& h3': {
      fontWeight: '500',
    },
  },
  closeFilterIcon: {
    fontSize: '24px',
  },
  closeFilterIconEnd: {
    fontSize: '24px',
    width: '20px',
    height: '20px',
    marginLeft: 'auto !important',
    minWidth: '24px !important',
    minHeight: '24px !important',
    color: `${theme.palette.Gray[700]} !important`,
    '& .MuiSvgIcon-root': {
      marginRight: '0 !important',
    },
  },
  ul_style: {
    padding: '0px',
    listStyle: 'none',
    columnCount: 2,
  },
  modalIconMargin: {
    margin: '0 auto 1rem',
  },
  modalTitle: {
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(27),
    color: `${theme.palette.Gray[700]} !important`,
    fontWeight: 700,
    display: 'block',
    maxWidth: '432px',
    margin: '0 auto 20px !important',
    textAlign: 'center',
  },
  modalIconBorder: {
    width: '3.5rem',
    height: '3.5rem',
    backgroundColor: theme.palette.Blue[100],
    border: `10px solid ${theme.palette.Blue[50]}`,
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.Blue[600],
  },
  filedTitle: {
    color: `${theme.palette.Gray[500]} !important`,
    fontWeight: '500 !important',
    fontFamily: 'Jost, sans-serif !important',
  },
  modalTextAreBox: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 0,
    width: '100%',
  },
  filedSpaceBottom: {
    marginBottom: '0.5rem !important',
  },
  modalTextArea: {
    borderRadius: '0.5rem',
    borderColor: `${theme.palette.Gray[300]} !important`,
  },
  modalFooter: {
    marginTop: theme.spacing(8),
  },
  formLblValue: {
    marginBottom: '1.5rem !important',
  },
  fontWeight_500: {
    fontWeight: '500!important',
  },
  formInlineLblValue: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  formInlineLblValue_lbl: {
    position: 'relative',
  },
  fontJost: {
    fontFamily: 'jost !important',
  },
  gray_500: {
    color: `${theme.palette.Gray[500]} !important`,
  },
  inputLbl: {
    lineHeight: '1.75rem !important',
    marginBottom: '0.5rem !important',
    display: 'block !important',
  },
  formInlineLblValue_value: {
    position: 'relative',
    width: '100%',
  },
  datePicker_wrap: {
    width: 'auto !important',
  },
  formLblValueGroup: {
    borderTop: `1px solid ${theme.palette.Gray[300]}`,
  },
  home_datePicker: {
    '&:before': {
      display: 'none !important',
    },
    '& input': {
      width: '150px !important',
      textAlign: 'left !important',
    },
  },
  LblValueSpace: {
    margin: '2rem 0rem !important',
  },
  fontWeight_700: {
    fontWeight: '700 !important',
  },
  formWidthFull: {
    width: '100%',
  },
  black_500: {
    color: '#000000',
  },
  inputIconLeft: {
    width: '24px',
    height: '44px',
    display: 'flex',
    alignItems: 'center',
    borderRight: `1px solid #8C92AC`,
  },
  customInputHight: {
    '& .MuiInputBase-input': {
      padding: '12px 12px !important',
    },
  },
  ul_styleOneLine: {
    padding: '0px',
    listStyle: 'none',
    columnCount: 1,
  },
}));
