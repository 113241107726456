import { ActionReducerMapBuilder, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { ApplicationState } from './types';
import { getApplicationOffer } from './actions';

const initialState: ApplicationState = {
  loading: false,
  error: false,
  success: false,
  applicationOffer: [],
  total: -1,
};

const applicationStore = createSlice({
  name: 'applicationOffer',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<ApplicationState>) => {
    builder.addCase(getApplicationOffer.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.applicationOffer = payload.applicationOffer;
      state.total = payload.total;
    });

    builder.addMatcher(isAnyOf(getApplicationOffer.pending), (state) => {
      state.loading = true;
      state.error = false;
    });

    builder.addMatcher(isAnyOf(getApplicationOffer.rejected), (state, { error }) => {
      state.loading = false;
      state.error = error?.message || true;
    });
  },
});

export default applicationStore.reducer;
