import { ChangeEvent, FC, useRef } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import * as yup from 'yup';
import { Grid } from '@material-ui/core';
import { appStatusOptions } from 'core/constants';
import { useAdmin } from 'store/admin/hooks';
import { useApplications } from 'store/applications/hooks';
import { ApplicationStatus } from 'store/applications/types';
import CustomSelectBox from 'components/CustomSelect/CustomSelect';
import useStyles from './UpdateStatusModal.styles';

interface FormValues {
  status: string;
}

interface UpdateStatusModalProps {
  appStatus: ApplicationStatus;
}

const UpdateStatusModal: FC<UpdateStatusModalProps> = ({ appStatus }) => {
  const formRef = useRef<FormikProps<FormValues> | null>(null);
  const classes = useStyles();
  const { loading, updateApplicationStatus, rejectApplication } = useAdmin();
  const { applicationDetails } = useApplications();

  const offerSchema = yup.object({
    status: yup.string().oneOf(Object.values(ApplicationStatus)).required(),
  });

  const initialValues: FormValues = {
    status: appStatus,
  };

  const onSubmit = (values: FormValues) => {
    const updatedStatus = values.status as ApplicationStatus;
    if (applicationDetails) {
      if (updatedStatus === ApplicationStatus.REJECTED) {
        rejectApplication({ id: applicationDetails.id });
      } else {
        updateApplicationStatus({ id: applicationDetails.id, appStatus: updatedStatus });
      }
    }
  };

  const onChangeSubmit = (updatedStatus: ApplicationStatus, setFieldValue: (name: string, value: string) => void) => {
    setFieldValue('status', updatedStatus);

    if (applicationDetails) {
      if (updatedStatus === ApplicationStatus.REJECTED) {
        rejectApplication({ id: applicationDetails.id });
      } else {
        updateApplicationStatus({ id: applicationDetails.id, appStatus: updatedStatus });
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={offerSchema}
      innerRef={formRef}
      enableReinitialize
    >
      {({ values, setFieldValue }) => (
        <Form noValidate>
          <Grid container direction="column" spacing={4} className={classes.container}>
            <Grid item>
              <CustomSelectBox
                options={appStatusOptions}
                name="status"
                disabled={loading}
                onChange={(event: ChangeEvent<{ name?: string | undefined; value: unknown }>) =>
                  onChangeSubmit(event.target.value as ApplicationStatus, setFieldValue)
                }
                value={values.status}
                formik={false}
              />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default UpdateStatusModal;
