import { Box, Button, Dialog, Typography } from '@mui/material';
import clsx from 'clsx';
import { Close } from '@material-ui/icons';
import FooterButton from 'components/Modal/FooterButton';
import useStyles from './DeleteAdminModal.styles';
import useCommonClasses from '../../../../assets/css/Common.styles';
import { ReactComponent as AlertCircleRedICon } from '../../../../assets/icons/alertBox.svg';

const DeleteAdminModal = ({
  open,
  onClose,
  handleSubmit,
  loading = false,
}: {
  open: boolean;
  loading: boolean;
  onClose: () => void;
  handleSubmit: () => void;
}) => {
  const commonClasses = useCommonClasses();
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: classes.modal_480 }}
    >
      <Box className={clsx([commonClasses.c_card, commonClasses.cardShadow])}>
        <Box className={clsx([classes.modalCloseIcon])}>
          <Button className={commonClasses.closeFilterIconEnd} onClick={onClose}>
            <Close />
          </Button>
        </Box>
        <Box className={clsx([classes.modalBody])}>
          <Box className={clsx([commonClasses.modalIconMargin, classes.modalIconBorderRed])}>
            <AlertCircleRedICon />
          </Box>
          <Typography variant="text_LG" className={clsx([commonClasses.modalTitle])}>
            Are you sure you want to delete this admin?
          </Typography>
          <Box className={commonClasses.modalFooter}>
            <FooterButton btnName="Yes, Delete Admin" handleSubmit={handleSubmit} onClose={onClose} loading={loading} />
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default DeleteAdminModal;
