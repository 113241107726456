import { FC, ReactElement } from 'react';
import { MUIDataTableCustomHeadRenderer, MUISortOptions } from 'mui-datatables';

import { ColumnHeader } from '../ColumnHeader';

interface CustomHeadRenderProps {
  columnMeta: MUIDataTableCustomHeadRenderer;
  handleToggleColumn: (columnIndex: number) => void;
  sortOrder: MUISortOptions;
  Menu?: ReactElement;
}

const CustomHeadRender: FC<CustomHeadRenderProps> = ({ columnMeta, handleToggleColumn, sortOrder, Menu }) => {
  const title = columnMeta.label as string;
  const toggleSortOrder = () => handleToggleColumn(columnMeta.index);
  const order = sortOrder.name === columnMeta.name ? sortOrder.direction : undefined;
  return <ColumnHeader key={title} title={title} toggleSortOrder={toggleSortOrder} order={order} Menu={Menu} />;
};

export default CustomHeadRender;
