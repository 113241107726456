import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  headingStripIconText: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    gap: '1rem',
  },
  headingStripIcon: {
    width: '3.25rem',
    height: '3.25rem',
    backgroundColor: theme.palette.primary.contrastText,
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.Gray[700],
    boxShadow: theme.shadows[6],
  },
  headingStripTextWrap: {
    position: 'relative',
    color: theme.palette.Gray[700],
    '& h3': {
      fontWeight: '500',
    },
  },
  fontJost: {
    fontFamily: 'jost !important',
  },
  selectContainer: {
    // marginRight: theme.spacing(2),
    '& .MuiInputBase-root': {
      outline: 'none !important',
    },
    '& .MuiSelect-root': {
      height: 22,
      minWidth: '7.5em',
      paddingTop: 0,
      paddingBottom: 0,
      paddingRight: 16,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      fontWeight: 700,
    },
  },
  select: {
    root: {
      '&:hover:not($disabled):before': {
        borderColor: '#D0D5DD',
      },
      '&.Mui-focused:before': {
        borderColor: '#D0D5DD',
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#D0D5DD',
    },
    '&:focus-within .MuiOutlinedInput-notchedOutline': {
      borderColor: '#D0D5DD',
    },
    '& .MuiInputBase-input': {
      padding: '13.5px 14px !important',
    },
  },
  selectBoxDropdown: {
    color: `${theme.palette.Gray[700]} !important`,
    fontWeight: 400,
    width: '100%',
  },
  datePickerContainer: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down(494)]: {
      marginTop: theme.spacing(2),
    },
  },
  subtitle: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20),
    fontWeight: 700,
  },
  semibold: {
    fontFamily: 'Jost',
  },
  datePicker_wrap: {
    width: 'auto !important',
  },
  home_datePicker: {
    '&:before': {
      display: 'none !important',
    },
    '& input': {
      width: '150px !important',
      textAlign: 'left !important',
    },
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  selectBoxDiv: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  datePicker: {
    justifyContent: 'space-between',
  },
  width236px: {
    maxWidth: '236px !important',
    fontSize: '13px',
    width: '230px',
  },
}));

export default useStyles;
