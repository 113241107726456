import { styled } from '@mui/material/styles';
import { DataGrid, GridColDef, GridEventListener, GridRowClassNameParams, GridValidRowModel } from '@mui/x-data-grid';
import NoDataFound from 'components/NoDataFound/NoDataFound';
import { Grid } from '@mui/material';

interface ICommonTable {
  rows: GridValidRowModel[];
  columns: GridColDef[];
  checkboxSelection?: boolean;
  hideFooterPagination?: boolean;
  onRowClick?: GridEventListener<'rowClick'>;
  disableColumnMenu?: boolean;
  headerUpperCase?: boolean;
  NoDataFountMessage: string;
  linkTitle?: string;
  NoDataFoundOnclick?: () => void;
  loading?: boolean;
  getRowClassName?: (params: GridRowClassNameParams) => string;
}

const CustomTable = ({
  rows,
  columns,
  headerUpperCase = false,
  disableColumnMenu = false,
  onRowClick,
  checkboxSelection = false,
  hideFooterPagination = false,
  NoDataFountMessage,
  linkTitle,
  NoDataFoundOnclick,
  loading = false,
  getRowClassName,
}: ICommonTable) => {
  const columnData = headerUpperCase
    ? columns?.map((column: GridColDef) => ({
        ...column,
        headerName: column?.headerName?.toUpperCase(),
      }))
    : columns;

  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    fontFamily: 'Jost',
    '.MuiDataGrid-row': {
      '&.Mui-selected': {
        backgroundColor: `${theme.palette.Blue[100]} !important`,
      },
    },
    '.MuiDataGrid-cell': {
      outlineColor: 'transparent !important',
    },
    // backgroundColor: theme.palette.primary.main,
    '.MuiDataGrid-columnHeader': {
      '&:focus': {
        outlineColor: 'transparent !important',
      },
      '&Title': {
        color: theme.palette.Gray[900],
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(18),
        fontWeight: 500,
      },
    },
    '.MuiDataGrid-cellContent': {
      color: theme.palette.Gray[900],
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      fontWeight: 400,
    },
    '.MuiDataGrid-withBorderColor': {
      borderColor: theme.palette.Gray[300],
    },
  }));

  return rows?.length > 0 ? (
    <StyledDataGrid
      loading={loading}
      rows={rows || []}
      sortingOrder={['asc', 'desc']}
      columns={columnData}
      checkboxSelection={checkboxSelection}
      getRowClassName={getRowClassName}
      hideFooterPagination={hideFooterPagination}
      onRowClick={onRowClick}
      disableColumnMenu={disableColumnMenu}
      hideFooter={true}
    />
  ) : (
    <Grid style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10%' }}>
      <NoDataFound title={NoDataFountMessage} linkTitle={linkTitle} onClick={NoDataFoundOnclick} />
    </Grid>
  );
};

export default CustomTable;
