import { FC } from 'react';
import { Box, Container, Grid, GridSize, Link } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Logo } from 'assets/icons/fullLogo.svg';
import { AlertMessageStatus } from 'core/types';
import { noop } from 'utils';
import useStyles from './AuthPageContainer.styles';
import { AlertMessage } from '../alerts';

interface AuthPageContainerProps {
  error?: boolean | string;
  clearError?: () => void;
  success?: string | boolean;
  clearSuccess?: () => void;
  logoMdGridSize?: GridSize;
  formMdGridSize?: GridSize;
  children?: React.ReactNode;
}

const AuthPageContainer: FC<AuthPageContainerProps> = ({
  children,
  error,
  clearError,
  success,
  clearSuccess = noop,
  formMdGridSize,
  logoMdGridSize,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.root}>
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={2} className={classes.contentContainer}>
          <Grid item md={logoMdGridSize ?? 6} className={classes.authContainer}>
            <Logo height="80px" width="200px" />
          </Grid>

          <Grid item xs={12} md={formMdGridSize ?? 6} className={classes.authContainer}>
            {children}

            <Box className={classes.formContainer} paddingTop="20px" justifyContent="center" display="flex">
              <Link href={t('pages.auth.footer.links.websiteUrl')} className={classes.footerLink}>
                {t('pages.auth.footer.links.main')}
              </Link>
              <span>·</span>
              <Link href={t('pages.auth.footer.links.privacyUrl')} className={classes.footerLink}>
                {t('pages.auth.footer.links.privacy')}
              </Link>
              <span>·</span>
              <Link href={t('pages.auth.footer.links.termsUrl')} className={classes.footerLink}>
                {t('pages.auth.footer.links.terms')}
              </Link>
              <span>·</span>
              <Link href={t('pages.auth.footer.links.contactUrl')} className={classes.footerLink}>
                {t('pages.auth.footer.links.contact')}
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <AlertMessage open={!!error} onClose={clearError} message={typeof error === 'string' ? error : undefined} />

      <AlertMessage
        open={!!success}
        onClose={clearSuccess}
        message={typeof success === 'string' ? success : undefined}
        status={AlertMessageStatus.SUCCESS}
      />
    </Box>
  );
};

export default AuthPageContainer;
