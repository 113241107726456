import { useField } from 'formik';
import { Box, Typography } from '@mui/material';
import clsx from 'clsx';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import useStyles from './CustomTextarea.styles';
import ErrorMeg from '../ErrorMessage/ErrorMessage';

interface ITextArea {
  minRows?: number;
  maxRows?: number;
  label?: string;
  name: string;
  value?: string | null;
  disabled?: boolean;
  extraClass?: string;
  placeholder?: string;
  inputClass?: string;
  isCompulsory?: boolean;
}

const TextArea = ({ minRows, maxRows, label, name, placeholder, disabled }: ITextArea) => {
  const [field] = useField(name);
  const classes = useStyles();

  return (
    <Box className={classes.modalTextAreBox}>
      {label && (
        <Typography
          variant="text_MD"
          component="label"
          className={clsx([classes.fontJost, classes.fontWeight_500, classes.gray_500, classes.inputLbl])}
        >
          {label}
        </Typography>
      )}
      <TextareaAutosize
        minRows={minRows}
        maxRows={maxRows}
        {...field}
        placeholder={placeholder}
        className={clsx([classes.modalTextArea])}
        disabled={disabled}
      />
      <ErrorMeg name={name} />
    </Box>
  );
};

export default TextArea;
