import moment from 'moment';
import { MonthlyRepaymentType, OfferProductType } from 'store/admin/types';
import {
  AccountType,
  ApplicationRegion,
  ApplicationStatus,
  OfferStatus,
  OnlineSales,
  TimePeriod,
} from 'store/applications/types';
import { IOfferStatus, IOfferType, ProductType } from 'store/applications/applicationOffer/types';
import { IReminderStatus } from 'store/reminders/types';
import { enumDataFirstLetterCapital } from 'pages/leads/components/CreateApplicationModal/utils';
import {
  CompanyDocumentType,
  UserRole,
  DashboardConstants,
  PartnerRoles,
  LenderRoles,
  OverviewDateRange,
  RejectionReasonTag,
  ApiLender,
  StageEnv,
  FontName,
  LenderRegion,
  LenderStatus,
} from './types';
import {
  mapCompanyDocumentTypeToLabel,
  mapRejectionReasonTagToLabel,
  mapRevenuePeriodToLabel,
  mapTradingTimePeriodToLabel,
  mapUserRoleToName,
} from './utils';

export const websiteRegExp =
  /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

export const sidebarWidth = 224;

export const tableActions = [
  { label: 'Bulk Actions', value: 'bulk' },
  { label: 'Action 1', value: 'action1' },
  { label: 'Action 2', value: 'action2' },
];

export const AUTH_SESSION_KEY = 'bsCore/AUTH_SESSION_KEY';

export const APP_FILTERS_KEY = 'bsCore/APP_FILTERS_KEY';

export const companyDocumentTypeOptions = Object.values(CompanyDocumentType)
  .map((value) => ({
    label: mapCompanyDocumentTypeToLabel(value),
    value,
  }))
  .filter((docOption) => docOption.value !== CompanyDocumentType.EMAIL_ATTACHMENTS);

export const monthName: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const tableFilters = [
  ApplicationStatus.NEW,
  ApplicationStatus.PENDING,
  ApplicationStatus.PREQUALIFIED,
  ApplicationStatus.ADDITIONAL_DETAILS_REQUESTED,
  ApplicationStatus.OFFERS_REQUESTED,
  ApplicationStatus.OFFER_ACCEPTED,
  ApplicationStatus.FUNDS_DISBURSED,
  ApplicationStatus.CLOSED,
  ApplicationStatus.REJECTED,
];

export const lenderTableFilters = [
  ApplicationStatus.OFFERS_REQUESTED,
  // ApplicationStatus.OFFER_ACCEPTED,
  // ApplicationStatus.FUNDS_DISBURSED,
];

const dashboardConstants: DashboardConstants =
  typeof process.env.REACT_APP_DASHBOARD_CONSTANTS !== 'undefined'
    ? JSON.parse(process.env.REACT_APP_DASHBOARD_CONSTANTS as string)
    : {};

const {
  MIN_CREDIT_AMOUNT = 1000,
  MAX_CREDIT_AMOUNT = 2500000,
  ROWS_PER_TABLE_PAGE = 30,
  SEARCH_ROWS_PER_TABLE_PAGE = 40,
} = dashboardConstants;

export { MIN_CREDIT_AMOUNT, MAX_CREDIT_AMOUNT, ROWS_PER_TABLE_PAGE, SEARCH_ROWS_PER_TABLE_PAGE };

export const SEARCH_DEBOUNCED_DELAY = 500; // miliseconds

export const appStatusOptions = [
  { label: 'New', value: ApplicationStatus.NEW },
  { label: 'Pending', value: ApplicationStatus.PENDING },
  { label: 'Pre-qualified', value: ApplicationStatus.PREQUALIFIED },
  { label: 'Awaiting client information', value: ApplicationStatus.ADDITIONAL_DETAILS_REQUESTED },
  { label: 'Lender review', value: ApplicationStatus.OFFERS_REQUESTED },
  { label: 'Lender offer accepted', value: ApplicationStatus.OFFER_ACCEPTED },
  { label: 'Funded', value: ApplicationStatus.FUNDS_DISBURSED },
  { label: 'Closed', value: ApplicationStatus.CLOSED },
  { label: 'Rejected', value: ApplicationStatus.REJECTED },
];

export const allowedFileTypes = [
  {
    mimeType: 'application/msword',
    name: 'Microsoft Word',
    extension: '.doc',
  },
  {
    mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessing',
    name: 'Microsoft Word (OpenXML)',
    extension: '.docx',
  },
  {
    mimeType: 'image/png',
    name: 'PNG Image',
    extension: '.png',
  },
  {
    mimeType: 'image/jpeg',
    name: 'JPEG Image',
    extension: '.jpg',
  },
  {
    mimeType: 'image/svg+xml',
    name: 'SVG Image',
    extension: '.svg',
  },
  {
    mimeType: '.csv',
    name: 'Comma-separated values (CSV)',
    extension: '.csv',
  },
  {
    mimeType: 'application/pdf',
    name: 'Adobe Portable Document Format (PDF)',
    extension: ' .pdf',
  },
  {
    mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
    name: 'Microsoft Excel',
    extension: '.xlsx',
  },
  {
    mimeType: 'application/wps-office.xlsx', // .xlsx
    name: 'Microsoft Excel',
    extension: '.xlsx',
  },
  {
    mimeType: 'application/vnd.ms-excel', // .xls (older Excel format)
    name: 'Microsoft Excel',
    extension: '.xls',
  },
  {
    mimeType: 'application/wps-office.xls', // .xls (older Excel format)
    name: 'Microsoft Excel',
    extension: '.xls',
  },
];

export const offerStatusOptions = [
  { label: '', value: '' },
  { label: 'PENDING', value: OfferStatus.PENDING },
  { label: 'DEFERRED', value: OfferStatus.DEFERRED },
  { label: 'DECLINED', value: OfferStatus.DECLINED },
  { label: 'ADDITIONAL_DETAILS_REQUESTED', value: OfferStatus.ADDITIONAL_DETAILS_REQUESTED },
  { label: 'OFFERED', value: OfferStatus.OFFERED },
  { label: 'ACCEPTED', value: OfferStatus.ACCEPTED },
  { label: 'CANCELLED', value: OfferStatus.CANCELLED },
  { label: 'LENDER_CALL_FAILED', value: OfferStatus.LENDER_CALL_FAILED },
];

export const adminOfferStatusOptions = [
  ...offerStatusOptions,
  { label: 'RULES_DECLINED', value: OfferStatus.RULES_DECLINED },
  { label: 'APPLICANT_DECLINED', value: OfferStatus.APPLICANT_DECLINED },
];

export const lenderOfferStatusOptions = [
  { label: '', value: '' },
  // { label: 'PENDING', value: OfferStatus.PENDING },
  { label: 'OFFERED', value: OfferStatus.OFFERED },
];

// Minimum 8 characters, at least one letter, one number and one special character
export const strongPasswordRegexExp = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

export const countryCodeToContryName: Record<string, string> = {
  GB: 'United Kingdom',
  IE: 'Ireland',
  AE: 'United Arab Emirates',
  US: 'USA',
  OTHER: 'Other',
};

export const additionalDocumentOptions = [{ value: '', label: '' }, ...companyDocumentTypeOptions];

export const INCOMPLETED_APP_STATUS = [
  ApplicationStatus.NEW,
  ApplicationStatus.PENDING,
  ApplicationStatus.PREQUALIFIED,
];

export const LOCKED_FOR_INTRODUCTION_STATUS = [ApplicationStatus.FUNDS_DISBURSED, ApplicationStatus.OFFER_ACCEPTED];

export const offerProductTypesOptions = [
  { value: '', label: '' },
  { value: OfferProductType.CREDIT_LINE, label: 'Credit Line' },
  { value: OfferProductType.REVENUE_BASED, label: 'Revenue Based Finance' },
  { value: OfferProductType.REVOLVING_CREDIT_FACILITY, label: 'Revolving Credit Facility' },
  { value: OfferProductType.TERM_LOAN, label: 'Term Loan' },
  { value: OfferProductType.BUSINESS_CREDIT_CARD, label: 'Business credit card' },
];

export const offerInterestTypesOptions: { value: string; label: string }[] = [
  { value: MonthlyRepaymentType.INTEREST_ONLY, label: 'Interest only' },
  { value: MonthlyRepaymentType.INTEREST_AND_CAPITAL, label: 'Capital and interest' },
];

export const OFFER_FOLLOW_UP_ENABLED: Record<OfferStatus, boolean> = {
  [OfferStatus.PENDING]: false,
  [OfferStatus.DEFERRED]: true,
  [OfferStatus.DECLINED]: false,
  [OfferStatus.ADDITIONAL_DETAILS_REQUESTED]: true,
  [OfferStatus.OFFERED]: true,
  [OfferStatus.ACCEPTED]: false,
  [OfferStatus.CANCELLED]: false,
  [OfferStatus.LENDER_CALL_FAILED]: false,
  [OfferStatus.RULES_DECLINED]: false,
  [OfferStatus.APPLICANT_DECLINED]: false,
};

export const partnerRoles = [UserRole.PARTNER_MANAGER, UserRole.PARTNER_USER];

export const partnerRolesOptions: { label: string; value: PartnerRoles }[] = [
  { label: mapUserRoleToName(UserRole.PARTNER_MANAGER), value: UserRole.PARTNER_MANAGER },
  { label: mapUserRoleToName(UserRole.PARTNER_USER), value: UserRole.PARTNER_USER },
];

export const lenderRoles = [UserRole.LENDER_MANAGER, UserRole.LENDER_MAINTAINER, UserRole.LENDER_VIEWER];

export const lenderRolesOptions: { label: string; value: LenderRoles }[] = [
  { label: mapUserRoleToName(UserRole.LENDER_MANAGER), value: UserRole.LENDER_MANAGER },
  { label: mapUserRoleToName(UserRole.LENDER_MAINTAINER), value: UserRole.LENDER_MAINTAINER },
  { label: mapUserRoleToName(UserRole.LENDER_VIEWER), value: UserRole.LENDER_VIEWER },
];

export const OFFER_UPDATE_ENABLED: Record<OfferStatus, boolean> = {
  [OfferStatus.PENDING]: true,
  [OfferStatus.DEFERRED]: true,
  [OfferStatus.DECLINED]: true,
  [OfferStatus.ADDITIONAL_DETAILS_REQUESTED]: true,
  [OfferStatus.OFFERED]: true,
  [OfferStatus.ACCEPTED]: false,
  [OfferStatus.CANCELLED]: false,
  [OfferStatus.LENDER_CALL_FAILED]: true,
  [OfferStatus.RULES_DECLINED]: false,
  [OfferStatus.APPLICANT_DECLINED]: true,
};

export const OFFER_REJECT_ENABLED: Record<OfferStatus, boolean> = {
  [OfferStatus.PENDING]: false,
  [OfferStatus.DEFERRED]: true,
  [OfferStatus.DECLINED]: false,
  [OfferStatus.ADDITIONAL_DETAILS_REQUESTED]: true,
  [OfferStatus.OFFERED]: false,
  [OfferStatus.ACCEPTED]: false,
  [OfferStatus.CANCELLED]: false,
  [OfferStatus.LENDER_CALL_FAILED]: false,
  [OfferStatus.RULES_DECLINED]: false,
  [OfferStatus.APPLICANT_DECLINED]: false,
};

export const overviewFilterOptions = [
  // { value: OverviewDateRange.TODAY, label: 'Today' },
  { value: OverviewDateRange.SEVEN_DAYS, label: 'Last 7 days' },
  { value: OverviewDateRange.FOUR_WEEKS, label: 'Last 4 weeks' },
  { value: OverviewDateRange.THREE_MONTHS, label: 'Last 3 months' },
  { value: OverviewDateRange.TWELVE_MONTHS, label: 'Last 12 months' },
  { value: OverviewDateRange.MONTH_TO_DATE, label: 'Viewing month to date' },
  { value: OverviewDateRange.QUARTER_TO_DATE, label: 'Viewing quarter to date' },
  { value: OverviewDateRange.YEAR_TO_DATE, label: 'Viewing year to date' },
  { value: OverviewDateRange.ALL_TIME, label: 'View all applications' },
];

// Used as start date for "All time" filter on dashboard apps overview
// This value has to be at least a date before one year since its implementation (2021-10-13)
// That way "Year to date" filter will pass validation
export const MIN_DATE_DATA_OVERVIEW = moment('2020-10-01').startOf('day').toDate();

export const rejectionReasonsOptions = Object.values(RejectionReasonTag).map((rejectionReasonTag) => ({
  value: rejectionReasonTag,
  label: mapRejectionReasonTagToLabel(rejectionReasonTag),
}));

export const lendersApiOptions = Object.values(ApiLender).map((lender) => ({ value: lender, label: lender }));

export const OFFER_API_LENDER_ENABLED: Record<OfferStatus, boolean> = {
  [OfferStatus.PENDING]: false,
  [OfferStatus.DEFERRED]: false,
  [OfferStatus.DECLINED]: true,
  [OfferStatus.ADDITIONAL_DETAILS_REQUESTED]: false,
  [OfferStatus.OFFERED]: false,
  [OfferStatus.ACCEPTED]: false,
  [OfferStatus.CANCELLED]: true,
  [OfferStatus.LENDER_CALL_FAILED]: true,
  [OfferStatus.RULES_DECLINED]: true,
  [OfferStatus.APPLICANT_DECLINED]: false,
};

export const revenueTimePeriodOptions = Object.values(TimePeriod).map((period) => ({
  value: period,
  label: mapRevenuePeriodToLabel(period),
}));

export const tradingTimePeriodOptions = Object.values(TimePeriod).map((period) => ({
  value: period,
  label: mapTradingTimePeriodToLabel(period),
}));

export const onlineSalesOptions = [
  { value: 0, label: '' },
  { value: OnlineSales.LESS_THAN_25, label: '< 25%' },
  { value: OnlineSales.BETWEEN_25_50, label: '25% - 50%' },
  { value: OnlineSales.BETWEEN_50_75, label: '50% - 75%' },
  { value: OnlineSales.MORE_THAN_75, label: '> 75%' },
];

// 20MB -> in bytes
export const MAX_FILE_SIZE = 20000000;

export const WIDGET_SCRIPT_ID = 'bscore-widget';

export const WIDGET_ELEMENT_ID = 'bs-widget-popup';

const STAGE_ENV_WIDGET_BUNDLE_URL: Record<StageEnv, string> = {
  [StageEnv.LOCAL]: 'http://localhost:3003/widget.js',
  [StageEnv.DEV_STAGING]: 'https://bscore-widget-dev.firebaseapp.com/widget.js',
  [StageEnv.STAGING]: 'https://wdjs-s.scorethebusiness.com/widget.js',
  [StageEnv.PRODUCTION]: 'https://wdjs.scorethebusiness.com/widget.js',
};

export const CURRENT_STAGE_ENV = process.env.REACT_APP_STAGE as StageEnv;

export const WIDGET_BUNDLE_URL = STAGE_ENV_WIDGET_BUNDLE_URL[CURRENT_STAGE_ENV];

export const ruleBooleanValueOptions = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];

export const accountTypeOptions = [
  { label: '', value: '' },
  { label: 'Partner', value: AccountType.PARTNER },
  { label: 'Lender', value: AccountType.LENDER },
];

// values extracted from https://www.yapily.com/get-started/
export const accountIndustryOptions = [
  { value: '', label: '' },
  { value: 'Accounting & Bookkeeping', label: 'Accounting & Bookkeeping' },
  { value: 'Banking', label: 'Banking' },
  { value: 'Blockchain', label: 'Blockchain' },
  { value: 'Business & Technology Service', label: 'Business & Technology Service' },
  { value: 'Insurtech', label: 'Insurtech' },
  { value: 'Lending & Credit Rating', label: 'Lending & Credit Rating' },
  { value: 'Payment Service', label: 'Payment Service' },
  { value: 'PropTech', label: 'PropTech' },
  { value: 'Wealth & Asset Management', label: 'Wealth & Asset Management' },
  { value: 'Other', label: 'Other' },
];

// values extracted from https://www.yapily.com/get-started/
export const accountEmployeesOptions = [
  { value: '', label: '' },
  { value: '1-10', label: '1-10' },
  { value: '11-50', label: '11-50' },
  { value: '51-200', label: '51-200' },
  { value: '201-500', label: '201-500' },
  { value: '501-1000', label: '501-1000' },
  { value: '1001-5000', label: '1001-5000' },
  { value: '5001+', label: '5001+' },
];

// values extracted from https://www.yapily.com/get-started/
export const accountCountryOptions = [
  { label: '', value: '' },
  { label: 'United Kingdom', value: 'GB' },
  { label: 'United States', value: 'US' },
  { label: 'Canada', value: 'CA' },
  { label: 'Afghanistan', value: 'AF' },
  { label: 'Albania', value: 'AL' },
  { label: 'Algeria', value: 'DZ' },
  { label: 'American Samoa', value: 'AS' },
  { label: 'Andorra', value: 'AD' },
  { label: 'Angola', value: 'AO' },
  { label: 'Anguilla', value: 'AI' },
  { label: 'Antigua and Barbuda', value: 'AG' },
  { label: 'Argentina', value: 'AR' },
  { label: 'Armenia', value: 'AM' },
  { label: 'Aruba', value: 'AW' },
  { label: 'Australia', value: 'AU' },
  { label: 'Austria', value: 'AT' },
  { label: 'Azerbaijan', value: 'AZ' },
  { label: 'Bahamas', value: 'BS' },
  { label: 'Bahrain', value: 'BH' },
  { label: 'Bangladesh', value: 'BD' },
  { label: 'Barbados', value: 'BB' },
  { label: 'Belarus', value: 'BY' },
  { label: 'Belgium', value: 'BE' },
  { label: 'Belize', value: 'BZ' },
  { label: 'Benin', value: 'BJ' },
  { label: 'Bermuda', value: 'BM' },
  { label: 'Bhutan', value: 'BT' },
  { label: 'Bolivia', value: 'BO' },
  { label: 'Bosnia and Herzegovina', value: 'BA' },
  { label: 'Botswana', value: 'BW' },
  { label: 'Brazil', value: 'BR' },
  { label: 'British Indian Ocean Territory', value: 'IO' },
  { label: 'British Virgin Islands', value: 'VG' },
  { label: 'Brunei', value: 'BN' },
  { label: 'Bulgaria', value: 'BG' },
  { label: 'Burkina Faso', value: 'BF' },
  { label: 'Burundi', value: 'BI' },
  { label: 'Cambodia', value: 'KH' },
  { label: 'Cameroon', value: 'CM' },
  { label: 'Cape Verde', value: 'CV' },
  { label: 'Cayman Islands', value: 'KY' },
  { label: 'Central African Republic', value: 'CF' },
  { label: 'Chad', value: 'TD' },
  { label: 'Chile', value: 'CL' },
  { label: 'China', value: 'CN' },
  { label: 'Christmas Island', value: 'CX' },
  { label: 'Cocos (Keeling) Islands', value: 'CC' },
  { label: 'Colombia', value: 'CO' },
  { label: 'Comoros', value: 'KM' },
  { label: 'Congo', value: 'CG' },
  { label: 'Cook Islands', value: 'CK' },
  { label: 'Costa Rica', value: 'CR' },
  { label: 'Croatia', value: 'HR' },
  { label: 'Cuba', value: 'CU' },
  { label: 'Curaçao', value: 'CW' },
  { label: 'Cyprus', value: 'CY' },
  { label: 'Czech Republic', value: 'CZ' },
  { label: 'Cote d`Ivoire', value: 'CI' },
  { label: 'Democratic Republic of the Congo', value: 'CD' },
  { label: 'Denmark', value: 'DK' },
  { label: 'Djibouti', value: 'DJ' },
  { label: 'Dominica', value: 'DM' },
  { label: 'Dominican Republic', value: 'DO' },
  { label: 'Ecuador', value: 'EC' },
  { label: 'Egypt', value: 'EG' },
  { label: 'El Salvador', value: 'SV' },
  { label: 'Equatorial Guinea', value: 'GQ' },
  { label: 'Eritrea', value: 'ER' },
  { label: 'Estonia', value: 'EE' },
  { label: 'Ethiopia', value: 'ET' },
  { label: 'Falkland Islands', value: 'FK' },
  { label: 'Faroe Islands', value: 'FO' },
  { label: 'Fiji', value: 'FJ' },
  { label: 'Finland', value: 'FI' },
  { label: 'France', value: 'FR' },
  { label: 'French Guiana', value: 'GF' },
  { label: 'French Polynesia', value: 'PF' },
  { label: 'French Southern Territories', value: 'TF' },
  { label: 'Gabon', value: 'GA' },
  { label: 'Gambia', value: 'GM' },
  { label: 'Georgia', value: 'GE' },
  { label: 'Germany', value: 'DE' },
  { label: 'Ghana', value: 'GH' },
  { label: 'Gibraltar', value: 'GI' },
  { label: 'Greece', value: 'GR' },
  { label: 'Greenland', value: 'GL' },
  { label: 'Grenada', value: 'GD' },
  { label: 'Guadeloupe', value: 'GP' },
  { label: 'Guam', value: 'GU' },
  { label: 'Guatemala', value: 'GT' },
  { label: 'Guernsey', value: 'GG' },
  { label: 'Guinea', value: 'GN' },
  { label: 'Guinea-Bissau', value: 'GW' },
  { label: 'Guyana', value: 'GY' },
  { label: 'Haiti', value: 'HT' },
  { label: 'Honduras', value: 'HN' },
  { label: 'Hong Kong S.A.R., China', value: 'HK' },
  { label: 'Hungary', value: 'HU' },
  { label: 'Iceland', value: 'IS' },
  { label: 'India', value: 'IN' },
  { label: 'Indonesia', value: 'ID' },
  { label: 'Iran, Islamic Republic Of', value: 'IR' },
  { label: 'Iraq', value: 'IQ' },
  { label: 'Ireland', value: 'IE' },
  { label: 'Isle of Man', value: 'IM' },
  { label: 'Israel', value: 'IL' },
  { label: 'Italy', value: 'IT' },
  { label: 'Jamaica', value: 'JM' },
  { label: 'Japan', value: 'JP' },
  { label: 'Jersey', value: 'JE' },
  { label: 'Jordan', value: 'JO' },
  { label: 'Kazakhstan', value: 'KZ' },
  { label: 'Kenya', value: 'KE' },
  { label: 'Kiribati', value: 'KI' },
  { label: 'Kuwait', value: 'KW' },
  { label: 'Kyrgyzstan', value: 'KG' },
  { label: 'Laos', value: 'LA' },
  { label: 'Latvia', value: 'LV' },
  { label: 'Lebanon', value: 'LB' },
  { label: 'Lesotho', value: 'LS' },
  { label: 'Liberia', value: 'LR' },
  { label: 'Libyan', value: 'LY' },
  { label: 'Liechtenstein', value: 'LI' },
  { label: 'Lithuania', value: 'LT' },
  { label: 'Luxembourg', value: 'LU' },
  { label: 'Macao S.A.R., China', value: 'MO' },
  { label: 'Macedonia', value: 'MK' },
  { label: 'Madagascar', value: 'MG' },
  { label: 'Malawi', value: 'MW' },
  { label: 'Malaysia', value: 'MY' },
  { label: 'Maldives', value: 'MV' },
  { label: 'Mali', value: 'ML' },
  { label: 'Malta', value: 'MT' },
  { label: 'Marshall Islands', value: 'MH' },
  { label: 'Martinique', value: 'MQ' },
  { label: 'Mauritania', value: 'MR' },
  { label: 'Mauritius', value: 'MU' },
  { label: 'Mayotte', value: 'YT' },
  { label: 'Mexico', value: 'MX' },
  { label: 'Micronesia', value: 'FM' },
  { label: 'Moldova', value: 'MD' },
  { label: 'Monaco', value: 'MC' },
  { label: 'Mongolia', value: 'MN' },
  { label: 'Montenegro', value: 'ME' },
  { label: 'Montserrat', value: 'MS' },
  { label: 'Morocco', value: 'MA' },
  { label: 'Mozambique', value: 'MZ' },
  { label: 'Myanmar', value: 'MM' },
  { label: 'Namibia', value: 'NA' },
  { label: 'Nauru', value: 'NR' },
  { label: 'Nepal', value: 'NP' },
  { label: 'Netherlands', value: 'NL' },
  { label: 'New Caledonia', value: 'NC' },
  { label: 'New Zealand', value: 'NZ' },
  { label: 'Nicaragua', value: 'NI' },
  { label: 'Niger', value: 'NE' },
  { label: 'Nigeria', value: 'NG' },
  { label: 'Niue', value: 'NU' },
  { label: 'Norfolk Island', value: 'NF' },
  { label: 'North Korea', value: 'KP' },
  { label: 'Northern Mariana Islands', value: 'MP' },
  { label: 'Norway', value: 'NO' },
  { label: 'Oman', value: 'OM' },
  { label: 'Pakistan', value: 'PK' },
  { label: 'Palau', value: 'PW' },
  { label: 'Palestinian Territory', value: 'PS' },
  { label: 'Panama', value: 'PA' },
  { label: 'Papua New Guinea', value: 'PG' },
  { label: 'Paraguay', value: 'PY' },
  { label: 'Peru', value: 'PE' },
  { label: 'Philippines', value: 'PH' },
  { label: 'Pitcairn', value: 'PN' },
  { label: 'Poland', value: 'PL' },
  { label: 'Portugal', value: 'PT' },
  { label: 'Puerto Rico', value: 'PR' },
  { label: 'Qatar', value: 'QA' },
  { label: 'Romania', value: 'RO' },
  { label: 'Russian Federation', value: 'RU' },
  { label: 'Rwanda', value: 'RW' },
  { label: 'Réunion', value: 'RE' },
  { label: 'Saint Barthélemy', value: 'BL' },
  { label: 'Saint Helena', value: 'SH' },
  { label: 'Saint Kitts and Nevis', value: 'KN' },
  { label: 'Saint Lucia', value: 'LC' },
  { label: 'Saint Pierre and Miquelon', value: 'PM' },
  { label: 'Saint Vincent and the Grenadines', value: 'VC' },
  { label: 'Samoa', value: 'WS' },
  { label: 'San Marino', value: 'SM' },
  { label: 'Sao Tome and Principe', value: 'ST' },
  { label: 'Saudi Arabia', value: 'SA' },
  { label: 'Senegal', value: 'SN' },
  { label: 'Serbia', value: 'CS' },
  { label: 'Seychelles', value: 'SC' },
  { label: 'Sierra Leone', value: 'SL' },
  { label: 'Singapore', value: 'SG' },
  { label: 'Slovakia', value: 'SK' },
  { label: 'Slovenia', value: 'SI' },
  { label: 'Solomon Islands', value: 'SB' },
  { label: 'Somalia', value: 'SO' },
  { label: 'South Africa', value: 'ZA' },
  { label: 'South Korea', value: 'KR' },
  { label: 'South Sudan', value: 'SS' },
  { label: 'Spain', value: 'ES' },
  { label: 'Sri Lanka', value: 'LK' },
  { label: 'Sudan', value: 'SD' },
  { label: 'Suriname', value: 'SR' },
  { label: 'Svalbard and Jan Mayen', value: 'SJ' },
  { label: 'Swaziland', value: 'SZ' },
  { label: 'Sweden', value: 'SE' },
  { label: 'Switzerland', value: 'CH' },
  { label: 'Syria', value: 'SY' },
  { label: 'Taiwan', value: 'TW' },
  { label: 'Tajikistan', value: 'TJ' },
  { label: 'Tanzania', value: 'TZ' },
  { label: 'Thailand', value: 'TH' },
  { label: 'Timor-Leste', value: 'TL' },
  { label: 'Togo', value: 'TG' },
  { label: 'Tokelau', value: 'TK' },
  { label: 'Tonga', value: 'TO' },
  { label: 'Trinidad and Tobago', value: 'TT' },
  { label: 'Tunisia', value: 'TN' },
  { label: 'Turkey', value: 'TR' },
  { label: 'Turkmenistan', value: 'TM' },
  { label: 'Turks and Caicos Islands', value: 'TC' },
  { label: 'Tuvalu', value: 'TV' },
  { label: 'U.S. Virgin Islands', value: 'VI' },
  { label: 'Uganda', value: 'UG' },
  { label: 'Ukraine', value: 'UA' },
  { label: 'United Arab Emirates', value: 'AE' },
  { label: 'United States Minor Outlying Islands', value: 'UM' },
  { label: 'Uruguay', value: 'UY' },
  { label: 'Uzbekistan', value: 'UZ' },
  { label: 'Vanuatu', value: 'VU' },
  { label: 'Vatican', value: 'VA' },
  { label: 'Venezuela', value: 'VE' },
  { label: 'Viet Nam', value: 'VN' },
  { label: 'Wallis and Futuna', value: 'WF' },
  { label: 'Western Sahara', value: 'EH' },
  { label: 'Yemen', value: 'YE' },
  { label: 'Zambia', value: 'ZM' },
  { label: 'Zimbabwe', value: 'ZW' },
];

export const fontNameOptions = [
  { label: '', value: '' },
  ...Object.values(FontName).map((value) => ({ label: value, value })),
];

export const applicationRegionOptions = [
  { label: '', value: '' },
  ...Object.values(ApplicationRegion).map((region) => ({
    label: region,
    value: region,
  })),
];

export const VIEW_DOCUMENT = {
  ROWS_PER_PAGE: 20,
};

export const applicationDetailsTabsList: string[] = [
  'Application offers',
  'Application details',
  'Action lead',
  'Company House profile',
  'Client emails',
  'Received emails',
];

export const metricsTabsList: string[] = ['Applications status', 'Offer status', 'Most active lenders'];

export const rejectionBoxModal: string[] = [
  'Dissolved',
  'Low monthly turnover',
  'First gazette notice for compulsory strike-off',
  'Poor personal credit',
  'Overdue confirmation statements',
  'Poor business credits',
  'Overdue filed accounts',
  'Low balance in bank accounts',
  'CCJs/court judgements',
  'Others',
];

export const requestDetailsCheckboxOption: string[] = [
  'Bank statements',
  'Management accounts',
  'Filed accounts',
  'VAT return',
  'Payment data',
  'Identity document',
];

export const ApplicationFilterOption: {
  '0': string[];
  '1': string[];
  '2': string[];
  '3': string[];
} = {
  '0': ['Pending apps', 'Funded apps', 'Not funded apps'],
  '1': [
    'bank-statement',
    'management-accounts',
    'filed-accounts',
    'vat-return',
    'payment-data',
    'identity-document',
    'email-attachments',
  ],
  '2': ['<25%', '25% - 50%', '50% - 75%', '>75%'],
  '3': ['Is a home owner', 'Is not a home owner'],
};

export const filterTabName: string[] = ['App status', 'Uploaded documents', 'Online sales', 'Homeowner'];

export const pendingStatuses = [
  ApplicationStatus.PREQUALIFIED,
  ApplicationStatus.PENDING,
  ApplicationStatus.OFFERS_REQUESTED,
  ApplicationStatus.OFFER_ACCEPTED,
  ApplicationStatus.NEW,
  ApplicationStatus.ADDITIONAL_DETAILS_REQUESTED,
];
export const fundedStatuses = [ApplicationStatus.FUNDS_DISBURSED];

export const notFundedStatuses = [ApplicationStatus.CLOSED, ApplicationStatus.REJECTED];

export const applicationStatusChartColor: string[] = [
  '#32D583',
  '#FDB022',
  '#36BFFA',
  '#7A5AF8',
  '#98A2B3',
  '#6172F3',
  '#2E90FA',
  '#A53123',
];
export const OfferStatusAndActiveLenderChartColor: string[] = [
  '#0086C9',
  '#0BA5EC',
  '#36BFFA',
  '#7CD4FD',
  '#B9E6FE',
  '#E0F2FE',
  '#F0F9FF',
];

export const offerStatus = {
  PENDING: 'Pending',
  DEFERRED: 'Deferred',
  DECLINED: 'Declined',
  ADDITIONAL_DETAILS_REQUESTED: 'Additional details requested',
  OFFERED: 'Offered',
  ACCEPTED: 'Accepted',
  CANCELLED: 'Cancelled',
  LENDER_CALL_FAILED: 'Lender Call Failed',
  RULES_DECLINED: 'Rules declined',
  APPLICANT_DECLINED: 'Applicant Declined',
};

export const LenderFilterOption: {
  0: string[];
  1: string[];
} = {
  0: Object.values(LenderRegion),
  1: Object.values(LenderStatus),
};

export const PartnerFilterOption: {
  0: string[];
} = {
  0: Object.values(LenderStatus),
};

export const OfferFilterOption: {
  0: string[];
  1: string[];
  2: string[];
} = {
  0: enumDataFirstLetterCapital(IOfferType),
  1: enumDataFirstLetterCapital(IOfferStatus),
  2: enumDataFirstLetterCapital(ProductType),
};

export const ReminderFilterOption: {
  0: string[];
} = {
  0: Object.values(IReminderStatus),
};
