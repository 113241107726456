import { Avatar, Badge, Box, Divider, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useAuth } from 'store/auth/hooks';
import { ReactComponent as LogoutIcon } from '../../assets/icons/iconLogout.svg';
import { ReactComponent as UserIcon } from '../../assets/icons/admins.svg';
import { ReactComponent as ShieldIcon } from '../../assets/icons/shieldIcon.svg';
import { ReactComponent as HelpIcon } from '../../assets/icons/helpIcon.svg';
import { ReactComponent as DownArrowIcon } from '../../assets/icons/downArrow.svg';
import useStyles from './SidebarUser.styles';
import useCommonStyles from '../../assets/css/Common.styles';

const SidebarUser: FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { isAdmin, firstName, email, logout } = useAuth();
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const accountName = firstName ?? '';
  const { t } = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box className={classes.userContainer}>
        <Box display="flex">
          <Avatar style={{ backgroundColor: '#0086C9' }} className={classes.userAvatar} alt={accountName}>
            {firstName?.toUpperCase()?.slice(0, 2)}
          </Avatar>

          <Box>
            <Typography className={classes.name}>{accountName}</Typography>
            <Typography className={classes.status}>{isAdmin ? 'Admin' : 'Partner'}</Typography>
          </Box>
        </Box>
        <Box className={classes.logoutButtonContainer}>
          <Box className={clsx([commonClasses.closeFilterIconEnd, commonClasses.cursorPointer])} onClick={handleClick}>
            <DownArrowIcon width="24px" height="24px" />
          </Box>
        </Box>
      </Box>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
          style: {
            width: 300,
          },
        }}
        className={classes.menu}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem className={classes.menuHeader}>
          <Badge color="success" variant="dot" className={classes.badge}>
            <Box className={classes.userIconContainer}>
              <UserIcon color="#0065AB" width="24px" height="24px" />
            </Box>
          </Badge>
          <ListItemText className={classes.userEmail}>{email}</ListItemText>
        </MenuItem>

        <Divider variant="fullWidth" className={classes.divider} />

        <MenuItem className={classes.link}>
          <ListItemIcon className={classes.iconContainer}>
            <UserIcon color="#667085" width="20px" height="20px" />
          </ListItemIcon>
          <ListItemText>{t('global.profile')}</ListItemText>
        </MenuItem>

        <MenuItem className={classes.link}>
          <ListItemIcon className={classes.iconContainer}>
            <ShieldIcon color="#667085" width="20px" height="20px" />
          </ListItemIcon>
          <ListItemText>{t('global.settings')}</ListItemText>
        </MenuItem>

        <MenuItem className={classes.link}>
          <ListItemIcon className={classes.iconContainer}>
            <HelpIcon color="#667085" width="20px" height="20px" />
          </ListItemIcon>
          <ListItemText>{t('global.helpAndSupport')}</ListItemText>
        </MenuItem>

        <Divider variant="fullWidth" className={classes.divider} style={{ margin: '8px 0' }} />

        <MenuItem className={classes.link} onClick={logout}>
          <ListItemIcon className={classes.iconContainer}>
            <LogoutIcon color="#667085" width="20px" height="20px" />
          </ListItemIcon>
          <ListItemText>{t('global.logout')}</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default SidebarUser;
