import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  container: {
    padding: '20px 0px',
  },
  outer: {
    border: `2px dashed ${theme.palette.greyLightBorder.main}`,
    padding: '40px 40px',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    borderRadius: 12,
    height: 100,
  },
  outerError: {
    borderColor: theme.palette.error.main,
  },
  inner: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  disabled: {
    opacity: 0.33,
  },
  uploadMessage: {
    textAlign: 'center',
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
    color: theme.palette.greyText.main,
    marginTop: 2,
  },
  progress: {
    position: 'absolute',
  },
  errorMessage: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(18),
    color: theme.palette.error.main,
    marginTop: theme.spacing(1),
    marginBottom: -theme.spacing(1),
  },
  inputFileContent: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100%',
  },
  inputFileIcon: {
    marginBottom: '12px',
    width: '40px',
    height: '40px',
    borderRadius: '100px',
    display: 'block',
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    margin: 0,
    backgroundColor: '#F2F4F7',
    transitionDuration: '0.3s',
    '&:active': {
      scale: '0.85',
    },
    '& svg': {
      width: '100%',
      height: '100%',
    },
  },
  inputFileText: {
    fontSize: `${theme.typography.pxToRem(14)}`,
    lineHeight: `${theme.typography.pxToRem(20)}`,
    fontWeight: '600 !important',
    color: `${theme.palette.Blue[600]} !important`,
    '& span': {
      fontSize: `${theme.typography.pxToRem(14)}`,
      lineHeight: `${theme.typography.pxToRem(20)}`,
      fontWeight: '400',
      color: theme.palette.Gray[500],
      display: 'inline-block',
      marginLeft: '4px',
    },
  },
}));
