import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '50%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardContainer: {
    backgroundColor: 'transparent',

    marginBottom: theme.spacing(1),
    '& .MuiCardHeader-title': {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: 600,
    },
    '& .MuiCardHeader-subheader': {
      fontSize: theme.typography.pxToRem(15),
    },
    '& .MuiCardHeader-root': {
      paddingBottom: '0px',
    },
    '& .MuiCardContent-root:last-child': {
      paddingBottom: theme.spacing(2),
    },
    '& .MuiCardContent-root p': {
      fontSize: theme.typography.pxToRem(14),
    },
  },
  centerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  error: {
    fontSize: theme.typography.pxToRem(14),
  },
  emailSubheader: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.secondary,
  },
}));
