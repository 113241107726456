import { FC } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import clsx from 'clsx';

import { LoadingButton } from '../buttons';
import useStyles from './AlertDialog.styles';

interface AlertDialogProps {
  open: boolean;
  dialogContentTitle: string;
  dialogContentText?: string;
  handleCancel: () => void;
  handleConfirm?: () => void;
  confirmButtonTitle?: string;
  loading?: boolean;
  disabled?: boolean;
  confirmButtonDisabled?: boolean;
  cancelButtonTitle?: string;
  containerClassname?: string;
  HeaderIcon?: JSX.Element;
  onHeaderAction?: () => void;
  children?: React.ReactNode;
}

const AlertDialog: FC<AlertDialogProps> = ({
  open,
  dialogContentTitle,
  dialogContentText,
  handleConfirm,
  handleCancel,
  children,
  confirmButtonTitle = 'Submit',
  cancelButtonTitle = 'Cancel',
  loading = false,
  disabled = false,
  confirmButtonDisabled = false,
  containerClassname,
  HeaderIcon,
  onHeaderAction,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={loading ? undefined : handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: clsx([classes.container, containerClassname]) }}
    >
      <DialogTitle id="alert-dialog-title" className={classes.header} disableTypography>
        <h2>{dialogContentTitle}</h2>

        {HeaderIcon && onHeaderAction && (
          <IconButton className={classes.headerIcon} onClick={onHeaderAction}>
            {HeaderIcon}
          </IconButton>
        )}

        <IconButton className={classes.closeIcon} onClick={handleCancel} disabled={loading}>
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent className={classes.main}>
        {dialogContentText && (
          <DialogContentText id="alert-dialog-description" className={classes.mainTitle}>
            {dialogContentText}
          </DialogContentText>
        )}

        {children}
      </DialogContent>

      {!disabled && (
        <DialogActions className={classes.footer}>
          <Button variant="contained" onClick={handleCancel} color="default" disableElevation disabled={loading}>
            {cancelButtonTitle}
          </Button>

          {handleConfirm && (
            <LoadingButton
              variant="contained"
              onClick={handleConfirm}
              color="primary"
              disableElevation
              loading={loading}
              disabled={confirmButtonDisabled}
            >
              {confirmButtonTitle}
            </LoadingButton>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default AlertDialog;
