import { useMemo, useState, useEffect } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Box } from '@mui/material';
import clsx from 'clsx';
import CustomSelectBox from 'components/CustomSelect/CustomSelect';
import TextFieldBox from 'components/CustomTextInput/TextInputBox';
import FooterButton from 'components/Modal/FooterButton';
import { CustomTextField } from 'components/inputs';
import CustomInputDatePicker from 'components/inputs/CustomInputDatePicker/CustomInputDatePicker';
import { UserQuery, UserRole } from 'core/types';
import { Field, Formik } from 'formik';
import { useDebounce } from 'hooks';
import { ThemeProvider } from '@material-ui/core/styles';
import { getApplications } from 'http/applications';
import { createReminder as createReminderApi } from 'http/reminders';
import { SEARCH_DEBOUNCE_DELAY } from 'pages/leads/components/CreateApplicationModal/utils';
import { useTranslation } from 'react-i18next';
import { MuiAutocompleteSelectBox } from 'core/theme-extended';
import { useAdmin } from 'store/admin/hooks';
import { useApplications } from 'store/applications/hooks';
import { useAuth } from 'store/auth/hooks';
import { useReminders } from 'store/reminders/hooks';
import * as yup from 'yup';
import useStyles from './CreateReminderModal.styles';
import useCommonStyles from '../../../../assets/css/Common.styles';

interface FormValues {
  assignee_id: string;
  reminder: string;
  due_date: Date | null;
  application_ids: string[];
}

const adminQuery: UserQuery = {
  per_page: 1000,
  page: 1,
  role: `${UserRole.ADMIN},${UserRole.SUPER_ADMIN}`,
};

const CreateRemindersModal = ({ onClose }: { onClose: (val: string) => void }) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<{ label: string; value: string | number }[] | []>([]); // State to hold fetched options
  const [selectedOptions, setSelectedOptions] = useState<{ label: string; value: string | number }[] | []>([]); // State for selected options
  const [searchValue, setSearchValue] = useState('');
  const [loadingOptions, setLoadingOptions] = useState(false);
  const debouncedSearchTerm = useDebounce(searchValue, SEARCH_DEBOUNCE_DELAY);
  const [openSelectBox, setOpenSelectBox] = useState<boolean>(false);

  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { t } = useTranslation();
  const { admins, setError, setSuccess, getAdminUsers } = useAdmin();
  const { isAdmin } = useAuth();
  const { applicationDetails } = useApplications();
  const { getReminders } = useReminders();

  const formSchema = useMemo(
    () =>
      yup.object({
        assignee_id: yup.string().required(t('pages.lead.admin.createReminder.inputs.assignee_id.required')),
        reminder: yup.string().required(t('pages.lead.admin.createReminder.inputs.reminder.required')),
        due_date: yup.date().required(t('pages.lead.admin.createReminder.inputs.due_date.required')).nullable(),
        ...(!applicationDetails?.id && {
          application_ids: yup.string().required(t('pages.lead.admin.createReminder.inputs.application.required')),
        }),
      }),
    // eslint-disable-next-line
    [t],
  );

  const initialValues: FormValues = {
    assignee_id: '',
    reminder: '',
    due_date: new Date(),
    application_ids: [],
  };

  const onSubmit = async (values: FormValues) => {
    const applicationIds =
      selectedOptions?.length === 0
        ? [...(applicationDetails?.id ? [applicationDetails?.id] : [])]
        : selectedOptions?.map((d) => `${d?.value}`);
    if (!applicationIds.length || !values?.due_date) return;
    setLoading(true);
    try {
      await createReminderApi({
        ...values,
        application_ids: applicationIds,
        due_date: values?.due_date.toISOString(),
      }).then(() => {
        setSuccess('Your reminder has been set');
        getReminders({ per_page: 20, page: 1 });
        setLoading(false);
        onClose('');
      });
    } catch (err) {
      setLoading(false);
      setError((err as Error).message ?? true);
    }
  };

  const assigneeOptions = useMemo(() => {
    return [{ label: '', value: '' }].concat(admins.map((admin) => ({ label: admin.fullName, value: admin.id })));
  }, [admins]);

  const getApplicationData = async () => {
    try {
      setLoadingOptions(true);
      await getApplications({ search: debouncedSearchTerm }).then((res) => {
        if (res?.applications?.length > 0) {
          const applicationOptionData: { label: string; value: string | number }[] = res?.applications?.map((data) => ({
            label: `${data?.company?.director?.first_name} ${data?.company?.director?.last_name}`,
            value: data?.id,
          }));

          setOptions(applicationOptionData);
          setLoadingOptions(false);
        } else {
          setLoadingOptions(false);
          setOptions([]);
        }
      });
    } catch (error) {
      setLoadingOptions(false);
    }
  };

  useEffect(() => {
    if (isAdmin) {
      getAdminUsers(adminQuery);
    }
  }, [isAdmin, getAdminUsers]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      getApplicationData();
    }
    // eslint-disable-next-line
  }, [debouncedSearchTerm]);

  return (
    <Formik initialValues={initialValues} validationSchema={formSchema} onSubmit={onSubmit}>
      {({ handleSubmit, setFieldValue, values }) => {
        return (
          <Box className={clsx([commonClasses.filter_cardBody])}>
            {!applicationDetails?.id && (
              <Box className={clsx([commonClasses.formLblValue])}>
                <ThemeProvider theme={MuiAutocompleteSelectBox}>
                  <Autocomplete
                    multiple={true}
                    id="registered_name"
                    value={selectedOptions}
                    open={openSelectBox}
                    onOpen={() => setOpenSelectBox(true)}
                    onClose={() => setOpenSelectBox(false)}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    onChange={(e, value: any) => {
                      setSelectedOptions(value);
                    }}
                    onInputChange={(e, value) => {
                      if (typeof value === 'string') {
                        setSearchValue(value.toLowerCase());
                      } else if (value === null || value === undefined) {
                        setSearchValue('');
                      }
                    }}
                    options={options}
                    loading={loadingOptions}
                    renderOption={(option) => option.label}
                    getOptionLabel={(option) => option.label}
                    renderInput={(props) => (
                      <Field
                        {...props}
                        component={CustomTextField}
                        title="Application"
                        name="application_ids"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        disabled={loading}
                      />
                    )}
                    className={classes.autoCompleteInput}
                    freeSolo={debouncedSearchTerm === '' && options.length === 0}
                  />
                </ThemeProvider>
              </Box>
            )}

            <Box className={clsx([commonClasses.formLblValue])}>
              <CustomSelectBox
                name="assignee_id"
                value={values?.assignee_id}
                label="Assignee"
                placeholder="Select Assignee"
                onChange={(e) => {
                  setFieldValue('assignee_id', e?.target?.value);
                }}
                options={assigneeOptions}
              />
            </Box>
            <Box className={clsx([commonClasses.formLblValue])}>
              <TextFieldBox value={values?.reminder} boxInput={classes.width100} label="Reminder" name="reminder" />
            </Box>
            <Box className={clsx([commonClasses.formLblValue])}>
              <CustomInputDatePicker
                name="due_date"
                label="Due date"
                value={values?.due_date}
                onChange={(date: MaterialUiPickersDate) => {
                  setFieldValue('due_date', date);
                }}
                openTo="date"
                isShowIcon="true"
                minDate={new Date()}
                collapsed
                format="DD MMMM YYYY"
              />
            </Box>
            <FooterButton
              handleSubmit={handleSubmit}
              onClose={() => onClose('')}
              btnName="Save reminder"
              loading={loading}
            />
          </Box>
        );
      }}
    </Formik>
  );
};

export default CreateRemindersModal;
