import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  title: {
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(24),
    marginBottom: 12,
  },
  subtitle: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20),
    fontWeight: 700,
  },
  fontJost: {
    fontFamily: 'jost !important',
  },
  gray_700: {
    color: `${theme.palette.Gray[700]} !important`,
  },
  primary_600: {
    color: `${theme.palette.Blue[600]} !important`,
  },
  metricsBox: {
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: `${theme.spacing(2)} !important`,
  },
  metricsBoxInner: {
    padding: `${theme.spacing(6)} !important`,
  },
  metricsBoxNumber: {
    color: `${theme.palette.Gray[800]} !important`,
    fontWeight: '600',
    fontSize: `${theme.typography.pxToRem(32)} !important`,
    lineHeight: `${theme.typography.pxToRem(44)} !important`,
  },
  metricsBoxLeft: {},
  metricsBoxRight: {},
  metricsBoxChart: {
    width: '147px',
    position: 'relative',
    top: '10px',
    right: '-4px',
  },
  ValueUpDown: {},
  metricsHeader: {
    fontWeight: '500',
  },
  metricsBoxTab: {
    boxShadow: 'none !important',
  },
  perChip: {
    fontSize: `${theme.typography.pxToRem(12)} !important`,
    lineHeight: `${theme.typography.pxToRem(21)} !important`,
    backgroundColor: theme.palette.Gray[200],
    color: theme.palette.Gray[600],
    borderRadius: `${theme.typography.pxToRem(2)} !important`,
    padding: `${theme.spacing(1)} !important`,
    fontWeight: '500',
  },
  metricsBoxTabChart: {
    position: 'relative',
    '& img': {
      width: '100%',
    },
  },
  mainWrapper: {
    padding: `${theme.spacing(4)} !important`,
  },
}));
