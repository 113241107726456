import { apiCall } from 'http/index';
import { LenderRules } from 'store/lenderRules/types';

export const createLenderRules = async (lender_id: string, lenderRules: LenderRules): Promise<null> =>
  apiCall({ method: 'post', url: `admin/lenders/${lender_id}/rule_engine`, data: lenderRules });

export const updateLenderRules = async (lender_id: string, lenderRules: LenderRules): Promise<null> =>
  apiCall({ method: 'put', url: `admin/lenders/${lender_id}/rule_engine`, data: lenderRules });

export const updateAllLenderRules = async (lenderAllEnable: { active: boolean }): Promise<null> =>
  apiCall({ method: 'put', url: `admin/rule_engine/all`, data: lenderAllEnable });
