import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from '@material-ui/core';
import FooterButton from 'components/Modal/FooterButton';
import { CustomLabel } from 'components/inputs';
import { OFFER_API_LENDER_ENABLED } from 'core/constants';
import { ApiLender, SelectOption } from 'core/types';
import { gatherOffers as gatherOffersApi } from 'http/admin';
import { useAdmin } from 'store/admin/hooks';
import { LenderOffer } from 'store/applications/types';
import { useLenders } from 'store/lenders/hooks';
import { toTitleCase } from 'utils';
import useStyles from './GatherOffers.styles';

const getLendersStr = (apiLenders: ApiLender[]) => {
  const lendersLength = apiLenders.length;
  return apiLenders
    .map((lender, index) => {
      if (lendersLength > 1) {
        if (index < lendersLength - 2) {
          return `${toTitleCase(lender)}, `;
        }
        if (index < lendersLength - 1) {
          return `${toTitleCase(lender)} and  `;
        }
      }
      return toTitleCase(lender);
    })
    .join('');
};

interface GatherOffersProps {
  companyId: string;
  offers: LenderOffer[];
}

const GatherOffers: FC<GatherOffersProps> = ({ companyId, offers }) => {
  const [loading, setLoading] = useState(false);
  const [apiLenders, setApiLenders] = useState<ApiLender[]>([]);
  const classes = useStyles();
  const { t } = useTranslation();
  const { setError, setSuccess } = useAdmin();
  const { allLenders } = useLenders();

  const availableApiLenders = useMemo(() => {
    return Object.values(ApiLender).reduce(
      (acc: SelectOption<ApiLender>[], val: ApiLender): SelectOption<ApiLender>[] => {
        const apiLender = allLenders.find((e) => e.name === val);
        if (!apiLender) return acc;
        acc.push({ label: apiLender.displayed_name, value: val });
        return acc;
      },
      [],
    );
  }, [allLenders]);

  const enabledApiLenders = useMemo(() => {
    let lenders = [...availableApiLenders.map((l) => l.value)];

    offers.forEach((offer) => {
      const lenderName = offer.lender_name.toLowerCase() as ApiLender;
      if (!lenders.includes(lenderName)) return;
      if (!OFFER_API_LENDER_ENABLED[offer.status]) {
        lenders = lenders.filter((lender) => lender !== lenderName);
      }
    });

    return lenders;
  }, [availableApiLenders, offers]);

  const onChange = (value: ApiLender) => {
    setApiLenders((prevApiLenders) => {
      const alreadySelected = prevApiLenders.find((lender) => lender === value);
      if (alreadySelected) return prevApiLenders.filter((lender) => lender !== value);
      return [...prevApiLenders, value];
    });
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      await gatherOffersApi(companyId, apiLenders);
      const successMessage = t('pages.lead.admin.gatherOffers.messages.success', {
        lenders: getLendersStr(apiLenders),
      });
      setSuccess(successMessage);
    } catch (err) {
      setError((err as Error)?.message ?? true);
    }
    setLoading(false);
  };

  return (
    <Box className={classes.filter_cardBody}>
      <FormControl>
        <FormLabel component={() => <CustomLabel title={t('pages.lead.admin.gatherOffers.subtitle')} />} />

        <FormGroup className={classes.styleOneLine}>
          {availableApiLenders.map((option) => (
            <FormControlLabel
              key={`${option.value}`}
              value={option.value}
              control={
                <Checkbox
                  checked={apiLenders.includes(option.value)}
                  onChange={() => onChange(option.value)}
                  color="primary"
                  disabled={!enabledApiLenders.includes(option.value)}
                />
              }
              label={option.label}
              className={classes.controlLabel}
            />
          ))}
        </FormGroup>
      </FormControl>

      <FooterButton
        handleSubmit={onSubmit}
        btnName={t('pages.lead.admin.actions.gatherOffers')}
        loading={loading}
        isShowCancelBtn={false}
      />
    </Box>
  );
};

export default GatherOffers;
