import { FC, useEffect, useState } from 'react';
import { Card, Container, Grid } from '@material-ui/core';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import { Box, CardContent, Stack, Typography } from '@mui/material';
import clsx from 'clsx';
import { Header } from 'components/Header/Header';
import { MetricsOverview } from 'components/MetricsOverview';
import theme from 'core/theme-extended';
import { IMetricsCountData } from 'core/types';
import { getApplicationCount, getLendersCount, getOfferCount } from 'http/metrics';
import { countLastMonthReturn, sortData } from 'pages/leads/components/CreateApplicationModal/utils';
import ReactApexChart from 'react-apexcharts';
import useStyles from './Metrics.styles';
import useCommonStyles from '../../assets/css/Common.styles';
import { ReactComponent as Calender } from '../../assets/icons/metrics_calender.svg';

const initialsValue = {
  total: 0,
  percentage: 0,
  chartData: {
    counts: [],
    labels: [],
  },
};

const Metrics: FC = () => {
  const [metricCount, setMetricCount] = useState<IMetricsCountData>({
    applications: initialsValue,
    offers: initialsValue,
    lenders: initialsValue,
  });
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const metricsData = [
    {
      title: 'Total applications',
      total: metricCount?.applications?.total,
      percentage: metricCount?.applications?.percentage,
      chartData: metricCount?.applications?.chartData,
    },
    {
      title: 'Total offers',
      total: metricCount?.offers?.total,
      percentage: metricCount?.offers?.percentage,
      chartData: metricCount?.offers?.chartData,
    },
    {
      title: 'Most actives lenders',
      total: metricCount?.lenders?.total,
      percentage: metricCount?.lenders?.percentage,
      chartData: metricCount?.lenders?.chartData,
    },
  ];

  const getMetricsCount = async () => {
    const [application, offer, lenders] = await Promise.all([
      getApplicationCount(),
      getOfferCount(),
      getLendersCount(),
    ]);

    if (application?.length > 0 || offer?.length > 0 || lenders?.length > 0) {
      const applicationCount = countLastMonthReturn(application);
      const offerCount = countLastMonthReturn(offer);
      const lendersCount = countLastMonthReturn(lenders);
      setMetricCount({
        ...metricCount,
        applications: {
          ...metricCount.applications,
          total: applicationCount?.total,
          percentage: +applicationCount?.percentage,
          chartData: {
            counts: sortData(application, 'count') as number[],
            labels: sortData(application, 'month') as string[],
          },
        },
        offers: {
          ...metricCount.offers,
          total: offerCount?.total,
          percentage: +offerCount?.percentage,
          chartData: {
            counts: sortData(offer, 'count') as number[],
            labels: sortData(offer, 'month') as string[],
          },
        },
        lenders: {
          ...metricCount.lenders,
          total: lendersCount?.total,
          percentage: +lendersCount?.percentage,
          chartData: {
            counts: sortData(lenders, 'count') as number[],
            labels: sortData(lenders, 'month') as string[],
          },
        },
      });
    }
  };

  useEffect(() => {
    getMetricsCount();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Header name="Metrics" />
      <Box className={clsx([commonClasses.mainWrapper, commonClasses.marginTop24])}>
        <Container disableGutters maxWidth="xl">
          <Grid container spacing={3}>
            {metricsData?.map((d) => {
              return (
                <Grid item xs={4} key={d?.title}>
                  <Card className={classes.metricsBox}>
                    <CardContent className={classes.metricsBoxInner}>
                      <Stack
                        sx={{ mb: 6 }}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                      >
                        <Typography
                          variant="text_MD"
                          className={clsx([classes.fontJost, classes.gray_700])}
                          component="p"
                        >
                          {d?.title}
                        </Typography>
                        <Calender />
                      </Stack>
                      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={4}>
                        <Box className={classes.metricsBoxLeft}>
                          <Typography
                            variant="text_MD"
                            className={clsx([classes.fontJost, classes.metricsBoxNumber, classes.primary_600])}
                            component="h5"
                            sx={{ mb: 4 }}
                          >
                            {d?.total || 0}
                          </Typography>
                          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={4}>
                            <Box>
                              <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
                                {+d?.percentage > 0 || +d?.percentage === 0 ? (
                                  <ArrowUpward style={{ color: theme.palette.Green[700], width: '20px' }} />
                                ) : (
                                  <ArrowDownward style={{ color: theme.palette.Red[700], width: '20px' }} />
                                )}
                                <Typography
                                  variant="text_SM"
                                  className={clsx([classes.fontJost, classes.ValueUpDown])}
                                  component="h5"
                                  color={
                                    +d?.percentage > 0 || +d?.percentage === 0
                                      ? theme.palette.Green[700]
                                      : theme.palette.Red[700]
                                  }
                                >
                                  {+d?.percentage || 0}%
                                </Typography>
                              </Stack>
                            </Box>
                            <Typography
                              variant="text_SM"
                              className={clsx([classes.fontJost, classes.ValueUpDown])}
                              component="p"
                              color={theme.palette.Gray[500]}
                            >
                              vs last month
                            </Typography>
                          </Stack>
                        </Box>
                        <Box className={classes.metricsBoxRight}>
                          <Box className={classes.metricsBoxChart}>
                            <ReactApexChart
                              type="area"
                              options={{
                                chart: {
                                  height: 30,
                                  width: 30,
                                  type: 'area',
                                  toolbar: {
                                    show: false,
                                  },
                                },
                                dataLabels: {
                                  enabled: false,
                                },
                                stroke: {
                                  curve: 'smooth',
                                },
                                xaxis: {
                                  labels: {
                                    show: false,
                                  },
                                  axisBorder: {
                                    show: false,
                                  },
                                  axisTicks: {
                                    show: false,
                                  },
                                },
                                labels: d?.chartData?.labels,
                                grid: {
                                  show: false,
                                },
                                yaxis: {
                                  show: false,
                                  labels: {
                                    show: false,
                                  },
                                  axisBorder: {
                                    show: false,
                                  },
                                  axisTicks: {
                                    show: false,
                                  },
                                },
                                colors: [+d?.percentage > 0 || +d?.percentage === 0 ? '#039855' : '#D92D20'],
                              }}
                              series={[
                                {
                                  name: 'Count',
                                  data: d?.chartData?.counts,
                                },
                              ]}
                            />
                          </Box>
                        </Box>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Container>
        <MetricsOverview />
      </Box>
    </>
  );
};

export default Metrics;
