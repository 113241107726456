import { useEffect } from 'react';
import { Refresh } from '@material-ui/icons';
import { Box, Button, List, ListItem, Typography } from '@mui/material';
import clsx from 'clsx';
import moment from 'moment';
import { ApplicationDetails } from 'store/applications/types';
import { useAdmin } from 'store/admin/hooks';
import { useTranslation } from 'react-i18next';
import { updateCompanyProfile } from 'http/applications';
import { useApplications } from 'store/applications/hooks';
import useStyles from '../../Lead.styles';
import { ReactComponent as Ellipse } from '../../../../assets/icons/Ellipse 24.svg';

export const ActionLeadTab = ({ details }: { details: ApplicationDetails | null }) => {
  const { t } = useTranslation();
  const { setError, setSuccess } = useAdmin();
  const classes = useStyles();
  const { getApplicationDetails } = useApplications();

  const companyDetails = details?.company?.company_profile;
  const applicant = details?.company?.people.find((p) => p.applicant);

  const getProfile = async (showMsg: boolean) => {
    try {
      await updateCompanyProfile(details?.company?.id || '');
      getApplicationDetails(details?.id || '');
      if (showMsg) setSuccess(t('pages.lead.sections.companyProfile.success'));
    } catch (error) {
      setError((error as Error).message || true);
    }
  };

  useEffect(() => {
    getProfile(false);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Box className={classes.headingStripIconText}>
        <Box className={classes.headingStripIcon}>
          <Ellipse />
        </Box>
        <Box className={classes.headingStripTextWrap}>
          <Typography variant="text_MD" component="h3" className={classes.fontJost}>
            {applicant?.first_name}’s Company House Profile
          </Typography>
        </Box>
      </Box>
      <Box className={clsx([classes.tabInfoWrap, classes.tabGap_160])}>
        <Box className={clsx([classes.tabCol])}>
          <Box className={clsx([classes.flex, classes.flexCol, classes.gap_24])}>
            <Box>
              <Box className={clsx([classes.listKeyValueBox, classes.py_16])}>
                <Box className={clsx([classes.listKeyValueBox_key])}>
                  <Typography
                    variant="text_SM"
                    component="p"
                    className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                  >
                    Type
                  </Typography>
                </Box>
                <Box className={clsx([classes.listKeyValueBox_value])}>
                  <Typography
                    variant="text_SM"
                    component="p"
                    className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                  >
                    {companyDetails?.type}
                  </Typography>
                </Box>
              </Box>
              <List className={clsx([classes.listKeyValueTitleWrap])}>
                <ListItem className={clsx([classes.listKeyValueTitleItem])}>
                  <Box className={clsx([classes.listKeyValueBox])}>
                    <Box className={clsx([classes.listKeyValueBox_title])}>
                      <Typography
                        variant="text_SM"
                        component="p"
                        className={clsx([
                          classes.fontJost,
                          classes.fontWeight_500,
                          classes.gray_600,
                          classes.lineHeight_25,
                        ])}
                      >
                        Accounts
                      </Typography>
                    </Box>
                  </Box>
                </ListItem>
                <ListItem className={clsx([classes.listKeyValueTitleItem])}>
                  <Box className={clsx([classes.listKeyValueBox])}>
                    <Box className={clsx([classes.listKeyValueBox_key])}>
                      <Typography
                        variant="text_SM"
                        component="p"
                        className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                      >
                        Overdue
                      </Typography>
                    </Box>
                    <Box className={clsx([classes.listKeyValueBox_value])}>
                      <Typography
                        variant="text_SM"
                        component="p"
                        className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                      >
                        {companyDetails?.confirmation_statement?.overdue ? 'True' : 'False'}
                      </Typography>
                    </Box>
                  </Box>
                </ListItem>
                <ListItem className={clsx([classes.listKeyValueTitleItem])}>
                  <Box className={clsx([classes.listKeyValueBox])}>
                    <Box className={clsx([classes.listKeyValueBox_key])}>
                      <Typography
                        variant="text_SM"
                        component="p"
                        className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                      >
                        Next due
                      </Typography>
                    </Box>
                    <Box className={clsx([classes.listKeyValueBox_value])}>
                      <Typography
                        variant="text_SM"
                        component="p"
                        className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                      >
                        {companyDetails?.confirmation_statement?.next_due || '-'}
                      </Typography>
                    </Box>
                  </Box>
                </ListItem>
              </List>
              <List className={clsx([classes.listKeyValueTitleWrap])}>
                <ListItem className={clsx([classes.listKeyValueTitleItem])}>
                  <Box className={clsx([classes.listKeyValueBox])}>
                    <Box className={clsx([classes.listKeyValueBox_title])}>
                      <Typography
                        variant="text_SM"
                        component="p"
                        className={clsx([
                          classes.fontJost,
                          classes.fontWeight_500,
                          classes.gray_600,
                          classes.lineHeight_25,
                        ])}
                      >
                        Last accounts
                      </Typography>
                    </Box>
                  </Box>
                </ListItem>
                <ListItem className={clsx([classes.listKeyValueTitleItem])}>
                  <Box className={clsx([classes.listKeyValueBox])}>
                    <Box className={clsx([classes.listKeyValueBox_key])}>
                      <Typography
                        variant="text_SM"
                        component="p"
                        className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                      >
                        Type
                      </Typography>
                    </Box>
                    <Box className={clsx([classes.listKeyValueBox_value])}>
                      <Typography
                        variant="text_SM"
                        component="p"
                        className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                      >
                        {companyDetails?.accounts?.last_accounts?.type || '-'}
                      </Typography>
                    </Box>
                  </Box>
                </ListItem>
                <ListItem className={clsx([classes.listKeyValueTitleItem])}>
                  <Box className={clsx([classes.listKeyValueBox])}>
                    <Box className={clsx([classes.listKeyValueBox_key])}>
                      <Typography
                        variant="text_SM"
                        component="p"
                        className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                      >
                        Made up to
                      </Typography>
                    </Box>
                    <Box className={clsx([classes.listKeyValueBox_value])}>
                      <Typography
                        variant="text_SM"
                        component="p"
                        className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                      >
                        {companyDetails?.accounts?.last_accounts?.made_up_to || '-'}
                      </Typography>
                    </Box>
                  </Box>
                </ListItem>
                <ListItem className={clsx([classes.listKeyValueTitleItem])}>
                  <Box className={clsx([classes.listKeyValueBox])}>
                    <Box className={clsx([classes.listKeyValueBox_key])}>
                      <Typography
                        variant="text_SM"
                        component="p"
                        className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                      >
                        Period start on
                      </Typography>
                    </Box>
                    <Box className={clsx([classes.listKeyValueBox_value])}>
                      <Typography
                        variant="text_SM"
                        component="p"
                        className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                      >
                        {companyDetails?.accounts?.last_accounts?.period_start_on || '-'}
                      </Typography>
                    </Box>
                  </Box>
                </ListItem>
                <ListItem className={clsx([classes.listKeyValueTitleItem])}>
                  <Box className={clsx([classes.listKeyValueBox])}>
                    <Box className={clsx([classes.listKeyValueBox_key])}>
                      <Typography
                        variant="text_SM"
                        component="p"
                        className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                      >
                        Period end on
                      </Typography>
                    </Box>
                    <Box className={clsx([classes.listKeyValueBox_value])}>
                      <Typography
                        variant="text_SM"
                        component="p"
                        className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                      >
                        {companyDetails?.accounts?.last_accounts?.period_end_on || '-'}
                      </Typography>
                    </Box>
                  </Box>
                </ListItem>
              </List>
              <List className={clsx([classes.listKeyValueTitleWrap])}>
                <ListItem className={clsx([classes.listKeyValueTitleItem])}>
                  <Box className={clsx([classes.listKeyValueBox])}>
                    <Box className={clsx([classes.listKeyValueBox_title])}>
                      <Typography
                        variant="text_SM"
                        component="p"
                        className={clsx([
                          classes.fontJost,
                          classes.fontWeight_500,
                          classes.gray_600,
                          classes.lineHeight_25,
                        ])}
                      >
                        Accounting reference date
                      </Typography>
                    </Box>
                  </Box>
                </ListItem>
                <ListItem className={clsx([classes.listKeyValueTitleItem])}>
                  <Box className={clsx([classes.listKeyValueBox])}>
                    <Box className={clsx([classes.listKeyValueBox_key])}>
                      <Typography
                        variant="text_SM"
                        component="p"
                        className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                      >
                        Day
                      </Typography>
                    </Box>
                    <Box className={clsx([classes.listKeyValueBox_value])}>
                      <Typography
                        variant="text_SM"
                        component="p"
                        className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                      >
                        {companyDetails?.accounts?.accounting_reference_date?.day}
                      </Typography>
                    </Box>
                  </Box>
                </ListItem>
                <ListItem className={clsx([classes.listKeyValueTitleItem])}>
                  <Box className={clsx([classes.listKeyValueBox])}>
                    <Box className={clsx([classes.listKeyValueBox_key])}>
                      <Typography
                        variant="text_SM"
                        component="p"
                        className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                      >
                        Month
                      </Typography>
                    </Box>
                    <Box className={clsx([classes.listKeyValueBox_value])}>
                      <Typography
                        variant="text_SM"
                        component="p"
                        className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                      >
                        {companyDetails?.accounts?.accounting_reference_date?.month}
                      </Typography>
                    </Box>
                  </Box>
                </ListItem>
              </List>
              <List className={clsx([classes.listKeyValueTitleWrap])}>
                <ListItem className={clsx([classes.listKeyValueTitleItem])}>
                  <Box className={clsx([classes.listKeyValueBox])}>
                    <Box className={clsx([classes.listKeyValueBox_key])}>
                      <Typography
                        variant="text_SM"
                        component="p"
                        className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                      >
                        Can file
                      </Typography>
                    </Box>
                    <Box className={clsx([classes.listKeyValueBox_value])}>
                      <Typography
                        variant="text_SM"
                        component="p"
                        className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                      >
                        {companyDetails?.can_file ? 'True' : 'False'}
                      </Typography>
                    </Box>
                  </Box>
                </ListItem>
                <ListItem className={clsx([classes.listKeyValueTitleItem])}>
                  <Box className={clsx([classes.listKeyValueBox])}>
                    <Box className={clsx([classes.listKeyValueBox_key])}>
                      <Typography
                        variant="text_SM"
                        component="p"
                        className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                      >
                        Has charges
                      </Typography>
                    </Box>
                    <Box className={clsx([classes.listKeyValueBox_value])}>
                      <Typography
                        variant="text_SM"
                        component="p"
                        className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                      >
                        {companyDetails?.has_charges ? 'True' : 'False'}
                      </Typography>
                    </Box>
                  </Box>
                </ListItem>
              </List>
            </Box>
          </Box>
        </Box>
        <Box className={classes.tabCol}>
          <Box className={clsx([classes.flex, classes.flexCol, classes.gap_24])}>
            <Box>
              <List className={clsx([classes.listKeyValueTitleWrap, classes.gap_16, classes.border_0])}>
                <ListItem className={clsx([classes.listKeyValueTitleItem])}>
                  <Box className={clsx([classes.listKeyValueBox])}>
                    <Box className={clsx([classes.listKeyValueBox_key])}>
                      <Typography
                        variant="text_SM"
                        component="p"
                        className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                      >
                        Company status
                      </Typography>
                    </Box>
                    <Box className={clsx([classes.listKeyValueBox_value])}>
                      <Typography
                        variant="text_SM"
                        component="p"
                        className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                      >
                        {companyDetails?.company_status === 'active' ? 'Active' : 'Inactive'}
                      </Typography>
                    </Box>
                  </Box>
                </ListItem>
                <ListItem className={clsx([classes.listKeyValueTitleItem])}>
                  <Box className={clsx([classes.listKeyValueBox])}>
                    <Box className={clsx([classes.listKeyValueBox_key])}>
                      <Typography
                        variant="text_SM"
                        component="p"
                        className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                      >
                        Has insolvency history
                      </Typography>
                    </Box>
                    <Box className={clsx([classes.listKeyValueBox_value])}>
                      <Typography
                        variant="text_SM"
                        component="p"
                        className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                      >
                        {companyDetails?.has_insolvency_history ? 'True' : 'False'}
                      </Typography>
                    </Box>
                  </Box>
                </ListItem>
                <ListItem className={clsx([classes.listKeyValueTitleItem])}>
                  <Box className={clsx([classes.listKeyValueBox])}>
                    <Box className={clsx([classes.listKeyValueBox_key])}>
                      <Typography
                        variant="text_SM"
                        component="p"
                        className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                      >
                        Jurisdiction
                      </Typography>
                    </Box>
                    <Box className={clsx([classes.listKeyValueBox_value])}>
                      <Typography
                        variant="text_SM"
                        component="p"
                        className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                      >
                        {companyDetails?.jurisdiction || '-'}
                      </Typography>
                    </Box>
                  </Box>
                </ListItem>
                <ListItem className={clsx([classes.listKeyValueTitleItem])}>
                  <Box className={clsx([classes.listKeyValueBox])}>
                    <Box className={clsx([classes.listKeyValueBox_key])}>
                      <Typography
                        variant="text_SM"
                        component="p"
                        className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                      >
                        Creation date
                      </Typography>
                    </Box>
                    <Box className={clsx([classes.listKeyValueBox_value])}>
                      <Typography
                        variant="text_SM"
                        component="p"
                        className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                      >
                        {moment(details?.company?.formation_date).format('YYYY-MM-DD') || '-'}
                      </Typography>
                    </Box>
                  </Box>
                </ListItem>
                <ListItem className={clsx([classes.listKeyValueTitleItem])}>
                  <Box className={clsx([classes.listKeyValueBox])}>
                    <Box className={clsx([classes.listKeyValueBox_key])}>
                      <Typography
                        variant="text_SM"
                        component="p"
                        className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                      >
                        Registered office is in dispute
                      </Typography>
                    </Box>
                    <Box className={clsx([classes.listKeyValueBox_value])}>
                      <Typography
                        variant="text_SM"
                        component="p"
                        className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                      >
                        {companyDetails?.registered_office_is_in_dispute ? 'True' : 'False'}
                      </Typography>
                    </Box>
                  </Box>
                </ListItem>
                <ListItem className={clsx([classes.listKeyValueTitleItem])}>
                  <Box className={clsx([classes.listKeyValueBox])}>
                    <Box className={clsx([classes.listKeyValueBox_key])}>
                      <Typography
                        variant="text_SM"
                        component="p"
                        className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                      >
                        Company number
                      </Typography>
                    </Box>
                    <Box className={clsx([classes.listKeyValueBox_value])}>
                      <Typography
                        variant="text_SM"
                        component="p"
                        className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                      >
                        {companyDetails?.company_number || '-'}
                      </Typography>
                    </Box>
                  </Box>
                </ListItem>
              </List>
              <List className={clsx([classes.listKeyValueTitleWrap])}>
                <ListItem className={clsx([classes.listKeyValueTitleItem])}>
                  <Box className={clsx([classes.listKeyValueBox])}>
                    <Box className={clsx([classes.listKeyValueBox_title])}>
                      <Typography
                        variant="text_SM"
                        component="p"
                        className={clsx([
                          classes.fontJost,
                          classes.fontWeight_500,
                          classes.gray_600,
                          classes.lineHeight_25,
                        ])}
                      >
                        Confirmation statement
                      </Typography>
                    </Box>
                  </Box>
                </ListItem>
                <ListItem className={clsx([classes.listKeyValueTitleItem])}>
                  <Box className={clsx([classes.listKeyValueBox])}>
                    <Box className={clsx([classes.listKeyValueBox_key])}>
                      <Typography
                        variant="text_SM"
                        component="p"
                        className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                      >
                        Overdue
                      </Typography>
                    </Box>
                    <Box className={clsx([classes.listKeyValueBox_value])}>
                      <Typography
                        variant="text_SM"
                        component="p"
                        className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                      >
                        {companyDetails?.confirmation_statement?.overdue ? 'True' : 'False'}
                      </Typography>
                    </Box>
                  </Box>
                </ListItem>
                <ListItem className={clsx([classes.listKeyValueTitleItem])}>
                  <Box className={clsx([classes.listKeyValueBox])}>
                    <Box className={clsx([classes.listKeyValueBox_key])}>
                      <Typography
                        variant="text_SM"
                        component="p"
                        className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                      >
                        Next due
                      </Typography>
                    </Box>
                    <Box className={clsx([classes.listKeyValueBox_value])}>
                      <Typography
                        variant="text_SM"
                        component="p"
                        className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                      >
                        {companyDetails?.confirmation_statement?.next_due}
                      </Typography>
                    </Box>
                  </Box>
                </ListItem>
                <ListItem className={clsx([classes.listKeyValueTitleItem])}>
                  <Box className={clsx([classes.listKeyValueBox])}>
                    <Box className={clsx([classes.listKeyValueBox_key])}>
                      <Typography
                        variant="text_SM"
                        component="p"
                        className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                      >
                        Last made up to
                      </Typography>
                    </Box>
                    <Box className={clsx([classes.listKeyValueBox_value])}>
                      <Typography
                        variant="text_SM"
                        component="p"
                        className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                      >
                        {companyDetails?.confirmation_statement?.last_made_up_to}
                      </Typography>
                    </Box>
                  </Box>
                </ListItem>
                <ListItem className={clsx([classes.listKeyValueTitleItem])}>
                  <Box className={clsx([classes.listKeyValueBox])}>
                    <Box className={clsx([classes.listKeyValueBox_key])}>
                      <Typography
                        variant="text_SM"
                        component="p"
                        className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                      >
                        Next made up to
                      </Typography>
                    </Box>
                    <Box className={clsx([classes.listKeyValueBox_value])}>
                      <Typography
                        variant="text_SM"
                        component="p"
                        className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                      >
                        {companyDetails?.confirmation_statement?.next_made_up_to}
                      </Typography>
                    </Box>
                  </Box>
                </ListItem>
              </List>
              <Button
                variant="contained"
                onClick={() => getProfile(true)}
                sx={{ width: 1, mt: 4 }}
                className={classes.cmn_btn}
              >
                Refresh profile
                <Refresh className={classes.cmn_btn_icon} />
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
