import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  fontWeight_500: {
    fontWeight: '500!important',
  },
  formInlineLblValue_value: {
    position: 'relative',
  },
  fontJost: {
    fontFamily: 'jost !important',
  },
  gray_500: {
    color: `${theme.palette.Gray[500]} !important`,
  },
  black_500: {
    color: '#000000',
  },
  inputIconLeft: {
    width: '24px',
    height: '44px',
    display: 'flex',
    alignItems: 'center',
    borderRight: `1px solid #D0D5DD`,
  },
  inputBox: {
    width: '100%',
    '& .MuiInputBase-input': {
      padding: '12px !important',
      boxShadow: 'none !important',
    },
  },
  iconInputBox: {
    width: '100%',
    '& .MuiInputBase-input': {
      boxShadow: 'none !important',
    },
  },
  inputLbl: {
    lineHeight: '1.75rem !important',
    marginBottom: '0.5rem !important',
    display: 'block !important',
  },
  marginBottom24px: {
    marginBottom: '24px !important',
  },
}));

export default useStyles;
