import { FC, useEffect, useState, useCallback, ChangeEvent } from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import {
  Box,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Switch,
  TextField,
  Tooltip as MuiTooltip,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import 'assets/css/prism.css';
import { FontName, WidgetFormConfig, WidthType } from 'core/types';
import { useAccount } from 'store/account/hooks';
import { usePartners } from 'store/partners/hooks';
import { getWidgetConfig as getWidgetConfigApi, saveWidgetConfig as saveWidgetConfigApi } from 'http/partners';
import { LoadingButton } from 'components/buttons';
import { CustomNumberField } from 'components/inputs';
import { fontNameOptions } from 'core/constants';
import useWidgetStyles from '../../Widget.styles';
import useStyles from './ConfigureWidgetForm.styles';
import EmbedCode from '../EmbedCode/EmbedCode';
import { parseSavedWidgetConfig } from './utils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const NumberInput: FC = (props: any) => <CustomNumberField {...props} thousandSeparator={false} />;

const DEFAULT_WIDTH = 100;
const DEFAULT_HEIGHT = 1000;
const HEX_REGEX = /^#([0-9a-f]{3}|[0-9a-f]{6})$/i;

type FormValues = Required<Omit<WidgetFormConfig, 'fontName'>> & { fontName: string };

interface ConfigureWidgetFormProps {
  // eslint-disable-next-line react/no-unused-prop-types
  id?: string;
  publicKey: string;
}

const ConfigureWidgetForm: FC<ConfigureWidgetFormProps> = ({ publicKey }) => {
  const { t } = useTranslation();
  const widgetClasses = useWidgetStyles();
  const classes = useStyles();
  const {
    account: { name },
  } = useAccount();
  const { setError, setSuccess } = usePartners();
  const [loading, setLoading] = useState(false);
  const [widgetConfig, setWidgetConfig] = useState<WidgetFormConfig | null>(null);
  const [savedConfigMode, setSavedConfigMode] = useState(true);

  const getWidgetconfig = useCallback(async () => {
    setLoading(true);
    try {
      const savedConfig = await getWidgetConfigApi();
      if (savedConfig && savedConfig.configuration) {
        setWidgetConfig((prevState) => ({ ...prevState, ...parseSavedWidgetConfig(savedConfig.configuration) }));
      }
    } catch (err) {
      setError((err as Error).message ?? true);
    }
    setLoading(false);
  }, [setError]);

  useEffect(() => {
    getWidgetconfig();
  }, [getWidgetconfig]);

  const formSchema = yup.object({
    fullWidth: yup.boolean(),
    widthType: yup.string().oneOf(Object.values(WidthType)),
    width: yup.number().required(),
    height: yup.number().required(),
    partnerName: yup.string(),
    logoUrl: yup.string().url(t('pages.widget.sections.configure.inputs.logoUrl.error')),
    letterLogoUrl: yup.string().url(t('pages.widget.sections.configure.inputs.letterLogoUrl.error')),
    primaryColor: yup.string().matches(HEX_REGEX, t('pages.widget.sections.configure.inputs.primaryColor.error')),
    secondaryColor: yup.string().matches(HEX_REGEX, t('pages.widget.sections.configure.inputs.secondaryColor.error')),
    fontName: yup.string(),
    kycEnabled: yup.boolean(),
    exitEnabled: yup.boolean(),
    captchaEnabled: yup.boolean(),
    popupEnabled: yup.boolean(),
    creditscoreFlow: yup.boolean(),
  });

  const initialValues: FormValues = {
    fullWidth: widgetConfig?.fullWidth ?? true,
    widthType: widgetConfig?.widthType ?? WidthType.vw,
    width: widgetConfig?.width ?? 100,
    height: widgetConfig?.height ?? 1000,
    partnerName: widgetConfig?.partnerName ?? name ?? '',
    logoUrl: widgetConfig?.logoUrl ?? '',
    letterLogoUrl: widgetConfig?.letterLogoUrl ?? '',
    primaryColor: widgetConfig?.primaryColor ?? '',
    secondaryColor: widgetConfig?.secondaryColor ?? '',
    fontName: widgetConfig?.fontName ?? '',
    kycEnabled: widgetConfig?.kycEnabled ?? false,
    exitEnabled: widgetConfig?.exitEnabled ?? true,
    captchaEnabled: widgetConfig?.captchaEnabled ?? true,
    popupEnabled: widgetConfig?.popupEnabled ?? false,
    creditscoreFlow: widgetConfig?.creditscoreFlow ?? false,
  };

  const onSubmit = async (values: FormValues) => {
    setLoading(true);
    try {
      const { fullWidth, widthType, width, height, popupEnabled, ...rest } = values;
      await saveWidgetConfigApi({
        ...rest,
        width: (width || DEFAULT_WIDTH) + (width ? widthType : WidthType.vw),
        height: (height || DEFAULT_HEIGHT) + WidthType.px,
        popup: popupEnabled,
      });
      setSuccess(t('pages.widget.messages.saveSuccess') as string);
    } catch (err) {
      setError((err as Error).message ?? true);
    }
    setLoading(false);
  };

  const toggleSavedConfigMode = () => setSavedConfigMode((prevState) => !prevState);

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={formSchema} enableReinitialize>
      {({ values, touched, errors, handleChange, setFieldValue, setValues, handleSubmit }) => (
        <>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={4} lg={4}>
              <Box component="form" position="relative">
                <Grid item xs={12}>
                  <Typography gutterBottom className={widgetClasses.subtitle}>
                    {t('pages.widget.sections.configure.title')}
                  </Typography>
                </Grid>

                <Grid item>
                  <FormControlLabel
                    control={<Switch onChange={toggleSavedConfigMode} checked={savedConfigMode} />}
                    name="configMode"
                    labelPlacement="start"
                    className={classes.configModeSwitchLabel}
                    label={
                      <Box display="flex" alignItems="center">
                        <Typography variant="body1">
                          {t(`pages.widget.sections.configure.inputs.configMode.${savedConfigMode ? 'saved' : 'code'}`)}
                        </Typography>
                      </Box>
                    }
                  />
                </Grid>

                <Grid item>
                  <TextField
                    name="publicKey"
                    variant="outlined"
                    value={publicKey}
                    disabled
                    type="text"
                    className={classes.publicKeyInput}
                    label={t('pages.widget.sections.configure.inputs.publicKey.label')}
                  />
                </Grid>

                <Grid item>
                  <Typography variant="caption" className={classes.captionTitle} component="p">
                    {t('pages.widget.sections.configure.dimensionsCaption')}
                  </Typography>

                  <Grid item>
                    <FormControlLabel
                      control={
                        <Switch
                          onChange={(event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
                            setFieldValue('fullWidth', checked);
                            if (!checked) return;
                            setFieldValue('width', 100);
                            setFieldValue('widthType', WidthType.vw);
                          }}
                          checked={values.fullWidth}
                        />
                      }
                      label={t('pages.widget.sections.configure.inputs.fullWidth.label')}
                      name="fullWidth"
                      labelPlacement="start"
                      className={classes.switchLabel}
                    />
                    <FormGroup className={classes.formGroup}>
                      <TextField
                        name="width"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          const value = Number(event.target.value);
                          setFieldValue('width', values.widthType === WidthType.vw ? Math.min(value, 100) : value);
                        }}
                        variant="outlined"
                        value={values.width}
                        disabled={values.fullWidth}
                        type="tel"
                        className={classes.selectInput}
                        label={t('pages.widget.sections.configure.inputs.width.label')}
                        InputProps={{ inputComponent: NumberInput }}
                        onBlur={() => {
                          if (!values.width) {
                            setValues({ ...values, width: DEFAULT_WIDTH, widthType: WidthType.vw, fullWidth: true });
                          }
                        }}
                      />
                      <Select
                        name="widthType"
                        value={values.widthType}
                        onChange={handleChange}
                        disabled={values.fullWidth}
                        variant="standard"
                        className={classes.select}
                      >
                        <MenuItem value={WidthType.px}>{WidthType.px}</MenuItem>
                        <MenuItem value={WidthType.vw}>{WidthType.vw}</MenuItem>
                      </Select>
                    </FormGroup>
                  </Grid>

                  <Grid item>
                    <FormGroup className={classes.formGroup}>
                      <TextField
                        name="height"
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                          setFieldValue('height', Number(event.target.value))
                        }
                        variant="outlined"
                        value={values.height}
                        type="tel"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end" className={classes.padRight}>
                              {WidthType.px}
                            </InputAdornment>
                          ),
                          inputComponent: NumberInput,
                        }}
                        className={classes.input}
                        label={t('pages.widget.sections.configure.inputs.height.label')}
                        onBlur={() => {
                          if (!values.height) setFieldValue('height', DEFAULT_HEIGHT);
                        }}
                      />
                    </FormGroup>
                  </Grid>
                </Grid>

                <Grid item>
                  <Typography variant="caption" className={classes.captionTitle} component="p">
                    {t('pages.widget.sections.configure.otherTitle')}
                  </Typography>

                  <Grid item>
                    <FormControlLabel
                      control={
                        <TextField
                          name="partnerName"
                          onChange={handleChange}
                          variant="outlined"
                          value={values.partnerName}
                          type="text"
                          className={classes.input}
                        />
                      }
                      labelPlacement="start"
                      className={classes.formLabel}
                      label={
                        <Box display="flex" alignItems="center">
                          <MuiTooltip
                            title={t('pages.widget.sections.configure.inputs.partnerName.tooltip') as string}
                            arrow
                          >
                            <HelpOutline className={classes.helpIcon} />
                          </MuiTooltip>
                          <Typography variant="body1">
                            {t('pages.widget.sections.configure.inputs.partnerName.label')}
                          </Typography>
                        </Box>
                      }
                    />
                  </Grid>

                  <Grid item>
                    <FormControlLabel
                      control={
                        <TextField
                          name="logoUrl"
                          onChange={handleChange}
                          variant="outlined"
                          value={values.logoUrl}
                          type="url"
                          className={classes.input}
                          placeholder={t('pages.widget.sections.configure.inputs.logoUrl.placeholder') as string}
                          error={touched.logoUrl && !!errors.logoUrl}
                          helperText={errors.logoUrl}
                        />
                      }
                      labelPlacement="start"
                      className={classes.formLabel}
                      label={
                        <Box display="flex" alignItems="center">
                          <MuiTooltip
                            title={t('pages.widget.sections.configure.inputs.logoUrl.tooltip') as string}
                            arrow
                          >
                            <HelpOutline className={classes.helpIcon} />
                          </MuiTooltip>
                          <Typography variant="body1">
                            {t('pages.widget.sections.configure.inputs.logoUrl.label')}
                          </Typography>
                        </Box>
                      }
                    />
                  </Grid>

                  <Grid item>
                    <FormControlLabel
                      control={
                        <TextField
                          name="letterLogoUrl"
                          onChange={handleChange}
                          variant="outlined"
                          value={values.letterLogoUrl}
                          type="url"
                          className={classes.input}
                          placeholder={t('pages.widget.sections.configure.inputs.letterLogoUrl.placeholder') as string}
                          error={touched.letterLogoUrl && !!errors.letterLogoUrl}
                          helperText={errors.letterLogoUrl}
                        />
                      }
                      labelPlacement="start"
                      className={classes.formLabel}
                      label={
                        <Box display="flex" alignItems="center">
                          <MuiTooltip
                            title={t('pages.widget.sections.configure.inputs.letterLogoUrl.tooltip') as string}
                            arrow
                          >
                            <HelpOutline className={classes.helpIcon} />
                          </MuiTooltip>
                          <Typography variant="body1">
                            {t('pages.widget.sections.configure.inputs.letterLogoUrl.label')}
                          </Typography>
                        </Box>
                      }
                    />
                  </Grid>

                  <Grid item>
                    <FormControlLabel
                      control={
                        <TextField
                          name="primaryColor"
                          onChange={handleChange}
                          variant="outlined"
                          value={values.primaryColor}
                          type="text"
                          className={classes.input}
                          placeholder={t('pages.widget.sections.configure.inputs.primaryColor.placeholder') as string}
                          error={touched.primaryColor && !!errors.primaryColor}
                          helperText={errors.primaryColor}
                        />
                      }
                      labelPlacement="start"
                      className={classes.formLabel}
                      label={
                        <Box display="flex" alignItems="center">
                          <MuiTooltip
                            title={t('pages.widget.sections.configure.inputs.primaryColor.tooltip') as string}
                            arrow
                          >
                            <HelpOutline className={classes.helpIcon} />
                          </MuiTooltip>
                          <Typography variant="body1">
                            {t('pages.widget.sections.configure.inputs.primaryColor.label')}
                          </Typography>
                        </Box>
                      }
                    />
                  </Grid>

                  <Grid item>
                    <FormControlLabel
                      control={
                        <TextField
                          name="secondaryColor"
                          onChange={handleChange}
                          variant="outlined"
                          value={values.secondaryColor}
                          type="text"
                          className={classes.input}
                          placeholder={t('pages.widget.sections.configure.inputs.secondaryColor.placeholder') as string}
                          error={touched.secondaryColor && !!errors.secondaryColor}
                          helperText={errors.secondaryColor}
                        />
                      }
                      labelPlacement="start"
                      className={classes.formLabel}
                      label={
                        <Box display="flex" alignItems="center">
                          <MuiTooltip
                            title={t('pages.widget.sections.configure.inputs.secondaryColor.tooltip') as string}
                            arrow
                          >
                            <HelpOutline className={classes.helpIcon} />
                          </MuiTooltip>
                          <Typography variant="body1">
                            {t('pages.widget.sections.configure.inputs.secondaryColor.label')}
                          </Typography>
                        </Box>
                      }
                    />
                  </Grid>

                  <Grid item>
                    <FormControlLabel
                      control={
                        <Select
                          name="fontName"
                          value={values.fontName}
                          onChange={handleChange}
                          variant="standard"
                          className={classes.fontNameInput}
                        >
                          {fontNameOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      }
                      labelPlacement="start"
                      className={classes.formLabel}
                      label={
                        <Box display="flex" alignItems="center">
                          <MuiTooltip
                            title={t('pages.widget.sections.configure.inputs.fontName.tooltip') as string}
                            arrow
                          >
                            <HelpOutline className={classes.helpIcon} />
                          </MuiTooltip>
                          <Typography variant="body1">
                            {t('pages.widget.sections.configure.inputs.fontName.label')}
                          </Typography>
                        </Box>
                      }
                    />
                  </Grid>

                  <Grid item>
                    <FormControlLabel
                      control={<Switch onChange={handleChange} checked={values.kycEnabled} />}
                      name="kycEnabled"
                      labelPlacement="start"
                      className={classes.switchLabel}
                      label={
                        <Box display="flex" alignItems="center">
                          <MuiTooltip title={t('pages.widget.sections.configure.inputs.kyc.tooltip') as string} arrow>
                            <HelpOutline className={classes.helpIcon} />
                          </MuiTooltip>
                          <Typography variant="body1">
                            {t('pages.widget.sections.configure.inputs.kyc.label')}
                          </Typography>
                        </Box>
                      }
                    />
                  </Grid>

                  <Grid item>
                    <FormControlLabel
                      control={<Switch onChange={handleChange} checked={values.exitEnabled} />}
                      name="exitEnabled"
                      labelPlacement="start"
                      className={classes.switchLabel}
                      label={
                        <Box display="flex" alignItems="center">
                          <MuiTooltip title={t('pages.widget.sections.configure.inputs.exit.tooltip') as string} arrow>
                            <HelpOutline className={classes.helpIcon} />
                          </MuiTooltip>
                          <Typography variant="body1">
                            {t('pages.widget.sections.configure.inputs.exit.label')}
                          </Typography>
                        </Box>
                      }
                    />
                  </Grid>

                  <Grid item>
                    <FormControlLabel
                      control={<Switch onChange={handleChange} checked={values.captchaEnabled} />}
                      name="captchaEnabled"
                      labelPlacement="start"
                      className={classes.switchLabel}
                      label={
                        <Box display="flex" alignItems="center">
                          <MuiTooltip
                            title={t('pages.widget.sections.configure.inputs.captcha.tooltip') as string}
                            arrow
                          >
                            <HelpOutline className={classes.helpIcon} />
                          </MuiTooltip>
                          <Typography variant="body1">
                            {t('pages.widget.sections.configure.inputs.captcha.label')}
                          </Typography>
                        </Box>
                      }
                    />
                  </Grid>

                  <Grid item>
                    <FormControlLabel
                      control={<Switch onChange={handleChange} checked={values.popupEnabled} />}
                      name="popupEnabled"
                      labelPlacement="start"
                      className={classes.switchLabel}
                      label={
                        <Box display="flex" alignItems="center">
                          <MuiTooltip title={t('pages.widget.sections.configure.inputs.popup.tooltip') as string} arrow>
                            <HelpOutline className={classes.helpIcon} />
                          </MuiTooltip>
                          <Typography variant="body1">
                            {t('pages.widget.sections.configure.inputs.popup.label')}
                          </Typography>
                        </Box>
                      }
                    />
                  </Grid>

                  <Grid item>
                    <FormControlLabel
                      control={<Switch onChange={handleChange} checked={values.creditscoreFlow} />}
                      name="creditscoreFlow"
                      labelPlacement="start"
                      className={classes.switchLabel}
                      label={
                        <Box display="flex" alignItems="center">
                          <MuiTooltip
                            title={t('pages.widget.sections.configure.inputs.creditscoreFlow.tooltip') as string}
                            arrow
                          >
                            <HelpOutline className={classes.helpIcon} />
                          </MuiTooltip>
                          <Typography variant="body1">
                            {t('pages.widget.sections.configure.inputs.creditscoreFlow.label')}
                          </Typography>
                        </Box>
                      }
                    />
                  </Grid>
                </Grid>

                {savedConfigMode && (
                  <Grid item>
                    <LoadingButton variant="contained" color="primary" onClick={() => handleSubmit()} loading={loading}>
                      {t('global.buttons.save')}
                    </LoadingButton>
                  </Grid>
                )}

                {loading && (
                  <Box className={classes.loaderContainer}>
                    <CircularProgress color="primary" />
                  </Box>
                )}
              </Box>
            </Grid>

            <Grid item xs={12} sm={8} lg={8}>
              <EmbedCode
                publicKey={publicKey}
                widgetConfig={{ ...values, fontName: values.fontName as FontName }}
                savedConfigMode={savedConfigMode}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Formik>
  );
};

export default ConfigureWidgetForm;
