import { Autocomplete } from '@material-ui/lab';
import { Box, FormControlLabel, Grid, Typography } from '@mui/material';
import clsx from 'clsx';
import TextFieldBox from 'components/CustomTextInput/TextInputBox';
import FooterButton from 'components/Modal/FooterButton';
import { Field, Formik } from 'formik';
import { createLenderRules, updateLenderRules } from 'http/lenderRules';
import { IOSSwitch } from 'pages/home/Home';
import { SEARCH_DEBOUNCE_DELAY, convertSelectBoxOptionData } from 'pages/leads/components/CreateApplicationModal/utils';
import { useTranslation } from 'react-i18next';
import { IAddRuleInitialValue } from 'store/applications/types';
import * as yup from 'yup';
import ErrorMeg from 'components/inputs/ErrorMessage/ErrorMessage';
import { useAdmin } from 'store/admin/hooks';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { CustomTextField } from 'components/inputs';
import { useDebounce } from 'hooks';
import { useLenders } from 'store/lenders/hooks';
import useCommonStyles from '../../../../assets/css/Common.styles';
import useStyles from './AddRulesModal.styles';

const AddRulesModal = ({
  type = 'Add',
  onClose,
  lenderRulesDetails,
}: {
  type?: string;
  onClose: () => void;
  lenderRulesDetails?: IAddRuleInitialValue | null;
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [openSelectBox, setOpenSelectBox] = useState<boolean>(false);
  const debouncedSearchTerm = useDebounce(searchValue, SEARCH_DEBOUNCE_DELAY);
  const [btnLoading, setBtnLoading] = useState<boolean>(false);

  const { loading, lenders, getLenders } = useLenders();
  const { setError, setSuccess } = useAdmin();
  const { t } = useTranslation();
  const commonClasses = useCommonStyles();
  const classes = useStyles();
  const labelPath = 'pages.lenderRule.addRule';
  const SIC_CODES_LIST_SEPARATOR = ',';

  const lenderSchema = yup.object({
    lender_id: yup.string().required(t(`${labelPath}.lender_name.required`)),
    monthly_turnover: yup.string().required(t(`${labelPath}.monthly_turnover.required`)),
    loan_amount_greater: yup.number().required(t(`${labelPath}.loan_amount.from_required`)),
    loan_amount_less: yup
      .number()
      .moreThan(yup.ref('loan_amount_greater'), 'Should be more than from amount')
      .required(t(`${labelPath}.loan_amount.to_required`)),
    trading_time_months: yup.string().required(t(`${labelPath}.trading_time.required`)),
    company_status: yup.boolean().required(t(`${labelPath}.trading_time.required`)),
    sic_codes_blacklist: yup
      .string()
      .required(t(`${labelPath}.blacklist_sic_codes.required`))
      .optional(),
    sic_codes_whitelist: yup
      .string()
      .required(t(`${labelPath}.whitelist_sic_codes.required`))
      .optional(),
  });

  const [initialValues, setInitialValues] = useState<IAddRuleInitialValue>({
    lender_id: '',
    monthly_turnover: '',
    loan_amount_greater: '',
    loan_amount_less: '',
    trading_time_months: '',
    company_status: true,
    sic_codes_blacklist: '',
    sic_codes_whitelist: '',
  });

  const onSubmit = async (value: IAddRuleInitialValue) => {
    try {
      setBtnLoading(true);
      const ruleData = {
        rules: [
          {
            fact: 'monthly-turnover',
            operator: 'greaterThanInclusive',
            value: +value?.monthly_turnover,
          },
          {
            fact: 'loan-amount',
            operator: 'greaterThanInclusive',
            value: value?.loan_amount_greater,
          },
          {
            fact: 'loan-amount',
            operator: 'lessThanInclusive',
            value: value?.loan_amount_less,
          },
          {
            fact: 'trading-time-in-months',
            operator: 'greaterThanInclusive',
            value: value?.trading_time_months,
          },
          {
            fact: 'company-status',
            operator: 'equal',
            value: JSON.stringify(value?.company_status ? 'active' : 'inactive'),
          },
          {
            fact: 'sic-codes-blacklist',
            operator: 'isNotBlacklisted',
            value: JSON.stringify(value?.sic_codes_blacklist),
          },
          {
            fact: 'sic-codes-whitelist',
            operator: 'isInWhiteList',
            value: JSON.stringify(value?.sic_codes_whitelist),
          },
        ],
      };
      if (type === 'Add') {
        await createLenderRules(value?.lender_id, ruleData).then(() => {
          setSuccess('Create lender rules successfully!');
          setBtnLoading(false);
          onClose();
        });
      } else {
        await updateLenderRules(value?.lender_id, ruleData).then(() => {
          setSuccess('update lender rules successfully!');
          setBtnLoading(false);
          onClose();
        });
      }
    } catch (error) {
      setError((error as Error)?.message || true);
      setBtnLoading(false);
    }
  };

  const getLenderData = useCallback(() => {
    if (debouncedSearchTerm) {
      getLenders({ search: debouncedSearchTerm });
    }
  }, [debouncedSearchTerm, getLenders]);

  useEffect(() => {
    getLenderData();
  }, [getLenderData]);

  useEffect(() => {
    if (type === 'Edit' && lenderRulesDetails) {
      if (lenderRulesDetails?.lender_name) {
        setSearchValue(lenderRulesDetails?.lender_name?.split(' ')[0] as string);
      }
      setInitialValues(lenderRulesDetails);
    }
    // eslint-disable-next-line
  }, [type]);

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={lenderSchema} enableReinitialize>
      {({ values, handleSubmit, setFieldValue }) => {
        return (
          <Box className={clsx([commonClasses.filter_cardBody])}>
            <Box className={clsx([commonClasses.formLblValue])}>
              <Autocomplete
                id="registered_name"
                value={
                  convertSelectBoxOptionData(lenders, 'name', 'id')?.find((d) => d?.value === values?.lender_id) || ''
                }
                open={openSelectBox}
                onOpen={() => setOpenSelectBox(true)}
                onClose={() => setOpenSelectBox(false)}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onChange={(e, value: any) => {
                  setFieldValue('lender_id', value?.value);
                }}
                onInputChange={(e, value) => {
                  if (typeof value === 'string') {
                    setSearchValue(value.toLowerCase());
                  } else if (value === null || value === undefined) {
                    setSearchValue('');
                  }
                }}
                options={convertSelectBoxOptionData(lenders, 'name', 'id')}
                loading={loading}
                disabled={type === 'Edit'}
                renderOption={(option) => option.label}
                getOptionLabel={(option) => option.label}
                renderInput={(props) => (
                  <Field
                    {...props}
                    component={CustomTextField}
                    title="Lender Name"
                    disable={type === 'Edit'}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
                className={clsx([classes.autoCompleteInput, type === 'Edit' && commonClasses.cursorNotAllowed])}
                freeSolo={debouncedSearchTerm === '' && lenders.length === 0}
              />
              <ErrorMeg name="lender_id" />
            </Box>
            <Box className={clsx([commonClasses.formLblValue])}>
              <TextFieldBox
                name="monthly_turnover"
                type="number"
                startAdornment={true}
                boxInput={classes.inputWidth}
                value={values?.monthly_turnover}
                label={t(`${labelPath}.monthly_turnover.label`)}
                placeholder="2,500,000.00"
              />
            </Box>
            <Box className={clsx([commonClasses.formLblValue])}>
              <Typography
                variant="text_MD"
                component="label"
                className={clsx([
                  commonClasses.fontJost,
                  commonClasses.fontWeight_500,
                  commonClasses.gray_500,
                  commonClasses.inputLbl,
                ])}
              >
                {t(`${labelPath}.loan_amount.label`)}
              </Typography>
            </Box>
            <Grid className={clsx([commonClasses.formLblValue])} container spacing={5}>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={2}>
                    <Typography
                      variant="text_MD"
                      component="label"
                      className={clsx([
                        commonClasses.fontJost,
                        commonClasses.fontWeight_500,
                        commonClasses.gray_500,
                        commonClasses.inputLbl,
                      ])}
                    >
                      From
                    </Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <TextFieldBox
                      name="loan_amount_greater"
                      type="number"
                      inputWidth={classes.width191}
                      startAdornment={true}
                      value={values?.loan_amount_greater}
                      placeholder="2,500,000.00"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={2} className={clsx(classes.marginLeft8, classes.marginRight8)}>
                    <Typography
                      variant="text_MD"
                      component="label"
                      className={clsx([
                        commonClasses.fontJost,
                        commonClasses.fontWeight_500,
                        commonClasses.gray_500,
                        commonClasses.inputLbl,
                      ])}
                    >
                      To
                    </Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <TextFieldBox
                      name="loan_amount_less"
                      type="number"
                      inputWidth={classes.width191}
                      startAdornment={true}
                      value={values?.loan_amount_less}
                      placeholder="2,500,000.00"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Box className={clsx([commonClasses.formLblValue])}>
              <TextFieldBox
                name="trading_time_months"
                type="number"
                startAdornment={true}
                boxInput={classes.inputWidth}
                icon="<"
                value={values?.trading_time_months}
                label={t(`${labelPath}.trading_time.label`)}
                placeholder="Number of months"
              />
            </Box>
            <Box className={clsx([commonClasses.formLblValue, commonClasses.flexWithGap10])}>
              <Typography
                variant="text_MD"
                component="label"
                className={clsx([
                  commonClasses.fontJost,
                  commonClasses.fontWeight_500,
                  commonClasses.gray_500,
                  commonClasses.inputLbl,
                  classes.toAmount,
                ])}
              >
                {t(`${labelPath}.company_status.label`)}
              </Typography>
              <FormControlLabel
                control={
                  <IOSSwitch
                    onChange={(e) => setFieldValue('company_status', e?.target?.checked)}
                    checked={values?.company_status}
                  />
                }
                label=""
              />
            </Box>
            <Box className={clsx([commonClasses.formLblValue])}>
              <TextFieldBox
                boxInput={classes.inputWidth}
                name="sic_codes_blacklist"
                value={values?.sic_codes_blacklist}
                label={t(`${labelPath}.blacklist_sic_codes.label`)}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  const targetValue = event.target.value
                    .replace(/\s+/g, ' ')
                    .replace(/([a-zA-Z0-9])(?=(\s)+(?![a-zA-Z0-9]))/g, `$1${SIC_CODES_LIST_SEPARATOR}`);
                  setFieldValue('value', targetValue);
                }}
              />
            </Box>
            <Box className={clsx([commonClasses.formLblValue])}>
              <TextFieldBox
                boxInput={classes.inputWidth}
                name="sic_codes_whitelist"
                value={values?.sic_codes_whitelist}
                label={t(`${labelPath}.whitelist_sic_codes.label`)}
              />
            </Box>
            <FooterButton btnName="Save" handleSubmit={handleSubmit} loading={btnLoading} onClose={onClose} />
          </Box>
        );
      }}
    </Formik>
  );
};

export default AddRulesModal;
