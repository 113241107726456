import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useTabsStyles = makeStyles({
  root: {
    backgroundColor: 'transparent',
    minHeight: 'auto',
    flexGrow: 1,
    width: '100%',
  },
});

export default makeStyles((theme: Theme) => ({
  title: {
    fontSize: theme.typography.pxToRem(17),
    lineHeight: theme.typography.pxToRem(16),
    marginBottom: 12,
    color: theme.palette.Gray[700],
    display: 'flex',
    alignItems: 'center',
  },
  titleAvatar: { marginRight: 5, background: theme.palette.primary.main },
  bold: {},
  cardContainer: {
    border: `1px solid ${theme.palette.greyLightBorder.main}`,
    height: 214,
  },
  cardsContainer: {
    marginBottom: theme.spacing(2),
    minHeight: 642,
    alignContent: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      minHeight: 856,
    },
  },
  filterBox: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(7),
  },
  flexWithAlign: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
}));
