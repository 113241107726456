import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { FC, useMemo, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import routes from 'router/routes';
import { useAuth } from 'store/auth/hooks';

import { TabRoute } from 'core/types';
import { Api } from '../settings/Api';
import { Widget } from '../settings/Widget';
import { WebhooksConfig } from '../settings/WebhooksConfig';
import { Security } from '../settings/Security';
import useStyles from './Developers.styles';

const Developers: FC = () => {
  const classes = useStyles();
  const { isPartner } = useAuth();
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const tabs: TabRoute[] = useMemo(() => {
    if (isPartner) {
      return [
        { path: routes.api, label: t('global.api'), Component: Api },
        { path: routes.widget, label: t('global.widget'), Component: Widget },
        { path: routes.security, label: t('global.security'), Component: Security },
        { path: routes.webhooks, label: t('global.webhooks'), Component: WebhooksConfig },
      ];
    }
    return [];
  }, [isPartner, t]);

  const navToFirstTab = useCallback(() => {
    const initialTab = tabs[0];
    if (initialTab) {
      history.replace(initialTab.path);
    }
  }, [history, tabs]);

  useEffect(() => {
    if (location.pathname === routes.developers) {
      navToFirstTab();
    }
  }, [location.pathname, navToFirstTab]);

  return (
    <Box className={classes.root}>
      <Tabs value={location.pathname} orientation="vertical" variant="fullWidth" className={classes.tabs}>
        <Typography className={classes.subtitle}>{t('global.developer')}</Typography>
        {tabs.map((route) => (
          <Tab
            key={route.path}
            label={route.label}
            component={Link}
            value={route.path}
            to={route.path}
            className={classes.tab}
          />
        ))}
      </Tabs>

      <Box paddingLeft="20px">
        <Switch>
          {tabs.map((tab) => (
            <Route key={tab.path} path={tab.path} component={tab.Component} />
          ))}
        </Switch>
      </Box>
    </Box>
  );
};

export default Developers;
