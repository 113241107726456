import { FC, ReactElement, useState, MouseEvent } from 'react';
import { Box, TableCell, Button, Menu as MuiMenu, MenuItem } from '@material-ui/core';
import { Dehaze } from '@material-ui/icons';

import { noop } from 'utils';
import useStyles from './ColumnHeader.styles';

interface ColumnHeaderProps {
  title: string;
  // eslint-disable-next-line react/require-default-props
  toggleSortOrder?: () => void;
  // eslint-disable-next-line react/no-unused-prop-types
  order?: 'asc' | 'desc';
  empty?: boolean;
  Menu?: ReactElement;
}

const ColumnHeader: FC<ColumnHeaderProps> = ({ title, toggleSortOrder = noop, empty, Menu }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <TableCell className={classes.columnHeaderItem}>
        {!empty && (
          <Button
            className={classes.columnHeaderButton}
            onClick={Menu ? handleClick : toggleSortOrder}
            endIcon={
              Menu ? (
                <Box className={classes.columnHeaderIconContainer}>
                  <Dehaze className={classes.columnMoreIcon} />
                </Box>
              ) : null
              // TODO: Sort not working
              // <Box className={classes.columnHeaderIconContainer}>
              //   {order !== 'desc' && <ExpandLess className={classes.columnHeaderSortUpIcon} />}
              //   {order !== 'asc' && <ExpandMore className={classes.columnHeaderSortDownIcon} />}
              // </Box>
            }
          >
            {title}
          </Button>
        )}
      </TableCell>

      <MuiMenu
        id={`headMenu${title}`}
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: -50,
          horizontal: 0,
        }}
      >
        <MenuItem className={classes.menuItem}>{Menu}</MenuItem>
      </MuiMenu>
    </>
  );
};

export default ColumnHeader;
