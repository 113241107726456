import { Box, Typography } from '@mui/material';
import clsx from 'clsx';
import CustomCheckbox from 'components/CustomCheckBox/CheckBox';
import TextArea from 'components/inputs/CustomTextarea/CustomTextarea';
import { companyDocumentTypeOptions } from 'core/constants';
import { CompanyDocumentType } from 'core/types';
import { Formik, FormikProps } from 'formik';
import { followUpApplication as followUpApplicationApi } from 'http/admin';
import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAdmin } from 'store/admin/hooks';
import { useApplications } from 'store/applications/hooks';
import * as yup from 'yup';
import ErrorMeg from 'components/inputs/ErrorMessage/ErrorMessage';
import FooterButton from 'components/Modal/FooterButton';
import useStyles from './FollowUpModal.styles';
import useCommonStyles from '../../../../assets/css/Common.styles';

interface FormValues {
  documentsRequested: CompanyDocumentType[];
  notes: string;
}

const FollowUpsModal = ({
  onClose,
  setMessage,
}: {
  onClose: (val: string) => void;
  setMessage: (message: string, open: boolean) => void;
}) => {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { t } = useTranslation();
  const formRef = useRef<FormikProps<FormValues> | null>(null);
  const { setError } = useAdmin();
  const { applicationDetails, getApplicationDetails } = useApplications();

  const rejectSchema = yup.object({
    documentsRequested: yup
      .array(yup.string())
      .min(1, t('pages.lead.admin.followUp.inputs.documentsRequested.required')),
    notes: yup.string().nullable(),
  });

  const appRequestDetails = useMemo(() => {
    return applicationDetails?.details_requested?.find((req) => !req.offer_id && !req.resolved);
  }, [applicationDetails]);

  const initialValues = {
    documentsRequested: appRequestDetails?.document_types ?? [],
    notes: appRequestDetails?.notes ?? '',
  };
  const onSubmit = async (values: FormValues) => {
    if (!applicationDetails) return;
    setLoading(true);
    try {
      await followUpApplicationApi(applicationDetails.id, values.documentsRequested, values.notes);
      formRef.current?.resetForm();
      setMessage(t('pages.lead.admin.followUp.messages.success'), true);
      getApplicationDetails(applicationDetails.id);
      onClose('');
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError((error as Error).message ?? true);
    }
  };
  return (
    <Formik initialValues={initialValues} validationSchema={rejectSchema} onSubmit={onSubmit}>
      {({ setFieldValue, values, handleSubmit }) => {
        return (
          <Box className={clsx([commonClasses.filter_cardBody])}>
            <Typography component="div" className={clsx([commonClasses.filedTitle])}>
              What additional details do you require?
            </Typography>
            <ul className={classes.ul_styleOneLine}>
              {companyDocumentTypeOptions?.map((d) => {
                return (
                  <li key={d?.value}>
                    <CustomCheckbox
                      formik={false}
                      value={d?.value}
                      checked={values?.documentsRequested?.includes(d?.value)}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (!e?.target?.checked) {
                          setFieldValue(
                            'documentsRequested',
                            values.documentsRequested.filter((v) => v !== e?.target.value),
                          );
                        } else {
                          setFieldValue('documentsRequested', [...values.documentsRequested, e?.target.value]);
                        }
                      }}
                      name="documentsRequested"
                      label={d?.label}
                    />
                  </li>
                );
              })}
            </ul>
            <ErrorMeg name="documentsRequested" />
            <Box className={classes.modalTextAreBox}>
              <TextArea minRows={3} name="notes" value={values?.notes} label="Please provide additional information" />
            </Box>
            <FooterButton
              handleSubmit={handleSubmit}
              onClose={() => onClose('')}
              btnName="Request follow-up"
              loading={loading}
            />
          </Box>
        );
      }}
    </Formik>
  );
};

export default FollowUpsModal;
