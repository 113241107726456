import { FC } from 'react';
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ProductsIcon } from '../../assets/icons/products.svg';
import useStyles from './SidebarButtons.styles';

const SidebarButtons: FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <ListItem
      style={{ marginBottom: 0 }}
      className={classes.listItemContainer}
      onClick={() => {
        window.open('https://www.scorethebusiness.com/resources/knowledge-hub', '_blank');
        return null;
      }}
    >
      <ListItemButton className={classes.linkContainer}>
        <ListItemIcon className={classes.linkButtonIcon}>
          <ProductsIcon width="20px" height="20px" />
        </ListItemIcon>
        <ListItemText className={classes.blueText}>{t('global.learn')}</ListItemText>
      </ListItemButton>
    </ListItem>
  );
};

export default SidebarButtons;
