import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  title: {
    fontSize: theme.typography.pxToRem(20),
    lineHeight: theme.typography.pxToRem(30),
    marginBottom: 8,
    color: theme.palette.Gray[700],
    fontWeight: 500,
  },
  subtitle: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
    color: theme.palette.Gray[700],
    fontWeight: 400,
  },
  tableContainer: {
    marginTop: theme.spacing(3),
    '& .MuiTableRow-root': {
      cursor: 'auto',
    },
  },
  disabled: {
    opacity: 0.5,
  },
  createBtn: {
    width: '153px',
    height: '40px',
  },
  companyColor: {
    color: theme.palette.Blue[600],
    fontFamily: 'Jost',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
  },
}));
