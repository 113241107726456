import { PartnerQuery, PartnerRoles, PartnersListResponse, UserQuery, UsersListResponse } from 'core/types';
import { apiCall } from 'http/index';
import { ApplicationStatus, OfferStatus, WebhookNotificationTypes } from 'store/applications/types';
import {
  CreatePartner,
  WebhookUrl,
  TrustedDomain,
  ShortPartner,
  PartnerDetails,
  WidgetConfig,
} from 'store/partners/types';

export const getAllPartners = async (): Promise<ShortPartner[]> =>
  apiCall({ method: 'get', url: '/admin/partners/all' });

export const getPartners = async (query: PartnerQuery = {}): Promise<PartnersListResponse> => {
  const queryString = new URLSearchParams(query as Record<string, string>).toString();
  return apiCall({ method: 'get', url: `/admin/partners?${queryString}` });
};

export const getPartnerDetails = async (partnerId: string): Promise<PartnerDetails> =>
  apiCall({ method: 'get', url: `/admin/partners/${partnerId}/details` });

export const createPartner = async (partner: CreatePartner): Promise<null> =>
  apiCall({ method: 'post', url: '/admin/partners', data: partner });

export const saveTrustedDomains = async (domain: string): Promise<null> =>
  apiCall({ method: 'post', url: '/partner/domains', data: { domain } });

export const getTrustedDomains = async (): Promise<TrustedDomain[]> =>
  apiCall({ method: 'get', url: '/partner/domains' });

export const deleteTrustedDomain = async (id: string): Promise<null> =>
  apiCall({ method: 'delete', url: `/partner/domains/${id}` });

export const updatePartner = async (id: string, partner: Partial<CreatePartner>): Promise<null> =>
  apiCall({ method: 'put', url: `/admin/partners/${id}`, data: partner });

export const getPartnerUsers = async (query: UserQuery = {}): Promise<UsersListResponse> => {
  const queryString = new URLSearchParams(query as Record<string, string>).toString();
  return apiCall({ method: 'get', url: `/partner/users?${queryString}` });
};

export const deletePartnerUser = async (id: string): Promise<null> =>
  apiCall({ method: 'delete', url: `/partner/user/${id}` });

export const updatePartnerUserRole = async (id: string, role: PartnerRoles): Promise<null> =>
  apiCall({ method: 'put', url: `/partner/user/${id}`, data: { role } });

export const getWebhookUrls = async (): Promise<WebhookUrl[]> => apiCall({ method: 'get', url: '/partner/webhooks' });

export const saveWebhookUrl = async (
  url: string,
  offer_status: OfferStatus[],
  application_status: ApplicationStatus[],
): Promise<WebhookUrl> =>
  apiCall({ method: 'post', url: '/partner/webhooks', data: { url, offer_status, application_status } });

export const updateWebhookUrl = async (id: string, active: boolean): Promise<null> =>
  apiCall({ method: 'put', url: `/partner/webhooks/${id}`, data: { active } });

export const deleteWebhookUrl = async (id: string): Promise<null> =>
  apiCall({ method: 'delete', url: `/partner/webhooks/${id}` });

export const getWebhookUrlSigningSecret = async (id: string): Promise<{ signing_secret: string }> =>
  apiCall({ method: 'get', url: `/partner/webhooks/${id}/signing-secret` });

// TODO Add type to this, as it can be a test application or test offer
export const sendTestWebhookEvent = async (
  offer_status: OfferStatus,
  application_status: ApplicationStatus,
  type: WebhookNotificationTypes,
): Promise<null> => {
  return apiCall({
    method: 'post',
    url: '/partner/webhooks/test',
    data: {
      application_status,
      offer_status,
      type,
    },
  });
};

export const getWidgetConfig = async (): Promise<WidgetConfig> =>
  apiCall({ method: 'get', url: '/partner/widget_config' });

export const saveWidgetConfig = async (configuration: Record<string, unknown>): Promise<null> =>
  apiCall({ method: 'put', url: '/partner/widget_config', data: { configuration } });
