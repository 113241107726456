import { ChangeEvent } from 'react';
import { RadioGroup, Box, FormControlLabel, Radio, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import { FormControl, Typography } from '@mui/material';
import theme from 'core/theme-extended';
import ErrorMeg from '../ErrorMessage/ErrorMessage';
import useStyles from './CustomRadioField.styles';

interface CustomRadioFieldProps {
  title: string;
  options: { label: string; value: string }[];
  name: string;
  value: string | number | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  formik?: boolean;
}

export const GreenRadio = withStyles({
  root: {
    color: theme?.palette?.grey?.[400],
    '&$checked': {
      color: theme?.palette?.Blue?.[600],
    },
  },
  checked: {},
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
})((props: any) => <Radio color="default" {...props} />);

const CustomRadioField = ({
  formik = false,
  title,
  name,
  options,
  value,
  setFieldValue,
  onChange,
}: CustomRadioFieldProps) => {
  const classes = useStyles();

  return (
    <Box className={clsx([classes.formLblValue])}>
      <Typography
        variant="text_MD"
        component="label"
        className={clsx([classes.fontJost, classes.fontWeight_500, classes.gray_500])}
      >
        {title}
        <FormControl>
          <RadioGroup
            onChange={onChange || ((e: ChangeEvent<HTMLInputElement>) => setFieldValue?.(name, e?.target?.value))}
            value={value}
          >
            {options?.map((option) => (
              <FormControlLabel
                key={`${option.value}`}
                value={option.value}
                checked={option?.value === value}
                control={<GreenRadio />}
                label={option.label}
                name={name}
                className={classes.controlLabel}
              />
            ))}
          </RadioGroup>
          {formik && <ErrorMeg name={name} />}
        </FormControl>
      </Typography>
    </Box>
  );
};

export default CustomRadioField;
