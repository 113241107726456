import { FC, useState, useMemo, ChangeEvent, useRef, useCallback, useEffect } from 'react';
import { Grid, CircularProgress, InputAdornment } from '@material-ui/core';
import { Formik, Form, Field, FormikProps } from 'formik';
import { Close, ExpandMore } from '@material-ui/icons';
import moment, { Moment } from 'moment';
import * as yup from 'yup';
import { searchCompanies as searchCompaniesApi, searchDirectors as searchDirectorsApi } from 'http/companies';
import { createPreAuthLink as createPreAuthLinkApi, createApplication as createApplicationApi } from 'http/admin';
import { CustomRadioField, CustomTextField, CustomTextarea } from 'components/inputs';
import { composeDirectorName, parseObject } from 'utils';
import {
  AdminCreateApplication,
  AreaPhoneCode,
  CompanyRegion,
  CompanySearchResult,
  CreateApplicant,
  CreateCompany,
} from 'core/types';
import { usePartners } from 'store/partners/hooks';
import { useAdmin } from 'store/admin/hooks';
import { useDebounce, useWidget } from 'hooks';
import { ApplicationRegion } from 'store/applications/types';
import { applicationRegionOptions } from 'core/constants';
import { mapAppRegionToCompanyRegion } from 'core/utils';
import { useApplications } from 'store/applications/hooks';
import { useTranslation } from 'react-i18next';
import TextFieldBox from 'components/CustomTextInput/TextInputBox';
import { MuiAutocompleteSelectBox } from 'core/theme-extended';
import CustomSelectBox from 'components/CustomSelect/CustomSelect';
import { Autocomplete } from '@material-ui/lab';
import { Box, Button, Dialog, Typography } from '@mui/material';
import { ThemeProvider } from '@material-ui/core/styles';
import CustomInputDatePicker from 'components/inputs/CustomInputDatePicker/CustomInputDatePicker';
import FooterButton from 'components/Modal/FooterButton';
import clsx from 'clsx';
import {
  SEARCH_DEBOUNCE_DELAY,
  PhoneNumberInput,
  EINNumberInput,
  FormValidation,
  validateCompanyNumber,
  USA_PHONE_NUMBER_REGEX,
  IRL_PHONE_NUMBER_REGEX,
} from './utils';
import useStyles from './CreateApplicationModal.styles';
import useCommonStyles from '../../../../assets/css/Common.styles';

interface FormValues {
  partner_id: string;
  email: string;
  registered_name: string;
  director_id: string;
  first_name: string;
  last_name: string;
  formation_date: string;
  registered_number: string;
  company_status: string;
  region: string;
  phone_number: string;
  ein: string;
}

interface CreateApplicationModalProps {
  open: boolean;
  toggleOpen: () => void;
  sendLink?: boolean;
  partnerId?: string;
  showMessage?: (message: string, open: boolean) => void;
}

const CreateApplicationModal: FC<CreateApplicationModalProps> = ({
  open,
  toggleOpen,
  sendLink = false,
  partnerId,
  showMessage,
}) => {
  const formRef = useRef<FormikProps<FormValues> | null>(null);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [autoCompleteOpen, setAutoCompleteOpen] = useState(false);
  const [loadingOptions, setLoadingOptions] = useState(false);
  const [options, setOptions] = useState<CompanySearchResult[]>([]);
  const [selectedOption, setSelectedOption] = useState<CompanySearchResult | null>(null);
  const [searchingDirectors, setSearchingDirectors] = useState(false);
  const [directors, setDirectors] = useState<CreateApplicant[]>([]);

  const debouncedSearchTerm = useDebounce(searchTerm, SEARCH_DEBOUNCE_DELAY);
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { t } = useTranslation();
  const { setError } = useAdmin();
  const { allPartners } = usePartners();
  const { openWidget } = useWidget();
  const { getApplications } = useApplications();

  const formSchema = yup.object({
    partner_id: yup.string().required(t('pages.dashboard.addAdminActions.inputs.partner_id.required')),
    email: yup
      .string()
      .email(t('pages.dashboard.addAdminActions.inputs.email.error'))
      .required(t('pages.dashboard.addAdminActions.inputs.email.required')),
    registered_name: yup.string().required(t('pages.dashboard.addAdminActions.inputs.registered_name.required')),
    director_id: yup
      .string()
      .when('region', (region: ApplicationRegion, schema: yup.StringSchema) =>
        region === ApplicationRegion.UK
          ? schema.required(t('pages.dashboard.addAdminActions.inputs.director.required'))
          : schema.notRequired(),
      ),
    first_name: yup.string().required(t('pages.dashboard.addAdminActions.inputs.first_name.required')),
    last_name: yup.string().required(t('pages.dashboard.addAdminActions.inputs.last_name.required')),
    formation_date: yup.string().required(t('pages.dashboard.addAdminActions.inputs.formation_date.required')),
    registered_number: yup
      .string()
      .when('region', (region: ApplicationRegion, schema: yup.StringSchema) =>
        region !== ApplicationRegion.USA
          ? schema
              .required(t('pages.dashboard.addAdminActions.inputs.registered_number.required'))
              .test(
                'isCompanyAvailable',
                t('pages.dashboard.addAdminActions.inputs.registered_number.error'),
                validateCompanyNumber,
              )
          : schema.notRequired(),
      ),
    company_status: yup.string().equals(['active'], t('pages.dashboard.addAdminActions.inputs.company_status.error')),
    region: yup
      .string()
      .oneOf(Object.values(ApplicationRegion))
      .required(t('pages.dashboard.addAdminActions.inputs.region.required')),
    phone_number: yup
      .string()
      .when('region', (region: ApplicationRegion, schema: yup.StringSchema) =>
        region === ApplicationRegion.UK
          ? schema.notRequired()
          : schema
              .matches(
                region === ApplicationRegion.USA ? USA_PHONE_NUMBER_REGEX : IRL_PHONE_NUMBER_REGEX,
                t('pages.dashboard.addAdminActions.inputs.phone_number.error'),
              )
              .required(t('pages.dashboard.addAdminActions.inputs.phone_number.required')),
      ),
    ein: yup
      .string()
      .when('region', (region: ApplicationRegion, schema: yup.StringSchema) =>
        region !== ApplicationRegion.USA
          ? schema.notRequired()
          : schema
              .min(9, t('pages.dashboard.addAdminActions.inputs.ein.error'))
              .required(t('pages.dashboard.addAdminActions.inputs.ein.required')),
      ),
  });

  const searchCompanies = useCallback(async () => {
    setLoadingOptions(true);
    try {
      const data = await searchCompaniesApi(debouncedSearchTerm);
      setOptions(data);
    } catch (err) {
      /** */
    }
    setLoadingOptions(false);
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (debouncedSearchTerm !== '') {
      searchCompanies();
    } else {
      setOptions([]);
    }
  }, [debouncedSearchTerm, searchCompanies]);

  useEffect(() => {
    if (directors?.length === 1) {
      const currentValues = formRef.current?.values;
      if (currentValues) {
        const { id, first_name, last_name } = directors[0];
        formRef.current?.setValues({
          ...currentValues,
          director_id: id ?? '',
          first_name: first_name ?? '',
          last_name: last_name ?? '',
        });
      }
    }
  }, [directors]);

  const initialValues: FormValues = {
    partner_id: partnerId ?? '',
    email: '',
    registered_name: '',
    director_id: '',
    first_name: '',
    last_name: '',
    formation_date: moment().toDate().toISOString() || '',
    registered_number: '',
    company_status: '',
    region: '',
    phone_number: '',
    ein: '',
  };

  const searchDirectors = async (companyNumber: string) => {
    setSearchingDirectors(true);
    try {
      const data = await searchDirectorsApi(companyNumber);
      setDirectors(data.map((dir, idx) => ({ ...dir, id: `${Math.random().toString(36).slice(2, 9)}-${idx}` })));
    } catch (err) {
      /** */
    }
    setSearchingDirectors(false);
  };

  const onCancel = () => {
    formRef.current?.resetForm();
    toggleOpen();
    setSelectedOption(null);
    setDirectors([]);
    setOptions([]);
  };

  const onSubmit = async (values: FormValues) => {
    setLoading(true);
    try {
      const region = values?.region as ApplicationRegion;

      const company: Partial<CreateCompany> = {
        registered_name: values?.registered_name,
        formation_date: values?.formation_date,
        region: mapAppRegionToCompanyRegion(region),
      };

      if (selectedOption?.companyAddress) {
        company.address = {
          ...selectedOption?.companyAddress,
          country_code: selectedOption?.companyAddress?.country_code
            ? selectedOption?.companyAddress?.country_code
            : CompanyRegion.GB,
        };
      }

      let applicant: Partial<CreateApplicant> = {
        first_name: values?.first_name,
        last_name: values?.last_name,
        email: values?.email,
        addresses: [],
        applicant: true,
      };

      if (region === ApplicationRegion.UK) {
        company.registered_number = values?.registered_number;
        company.type = selectedOption?.companyType;
      } else if (region === ApplicationRegion.USA) {
        company.ein = values?.ein;
        applicant.phone_number = `${AreaPhoneCode.US}-${values?.phone_number}`;
      } else if (region === ApplicationRegion.IRL) {
        company.registered_number = values?.registered_number;
        applicant.phone_number = `${AreaPhoneCode.IE}-${values?.phone_number}`;
      }

      const selectedDirector = directors?.find((dir) => dir?.id === values?.director_id);

      if (selectedDirector) {
        const props = {
          nationality: 1,
          country_of_residence: 1,
          role: 1,
          occupation: 1,
          appointed_on: 1,
          resigned_on: 1,
          addresses: 1,
        };

        applicant = { ...parseObject(selectedDirector, props), ...applicant };
      }

      const directorsData =
        directors.reduce((dirs: Partial<CreateApplicant>[], { id, ...rest }) => {
          if (id !== values?.director_id) {
            const addresses = rest?.addresses.map((addr) => ({
              ...addr,
              country_code: addr?.country_code ?? CompanyRegion.GB,
            }));
            return [...dirs, { ...rest, addresses }];
          }
          return dirs;
        }, []) ?? [];

      const payload: AdminCreateApplication = {
        partner_id: values?.partner_id,
        company,
        applicant,
        directors: directorsData,
        region,
      };

      if (sendLink) {
        await createPreAuthLinkApi(payload).then(() => {
          showMessage?.('Invite link sent', true);
        });
      } else {
        const data = await createApplicationApi(payload);
        if (data?.access_token) {
          showMessage?.('Application sent', true);
        }
        openWidget(data.public_key, data.access_token, getApplications);
      }
      onCancel();
    } catch (err) {
      setError((err as Error).message ?? true);
    }
    setLoading(false);
  };

  const handleDirectorChange = (value: string, values: FormValues, setValues: (values: FormValues) => void) => {
    const selectedDirector = directors.find((dir) => dir.id === value);
    if (selectedDirector) {
      const updatedValues = {
        ...values,
        director_id: selectedDirector?.id ?? '',
        first_name: selectedDirector?.first_name ?? '',
        last_name: selectedDirector?.last_name ?? '',
      };
      setValues(updatedValues);
    }
  };

  const partnersOptions = useMemo(() => {
    const emptyOption = [{ label: '', value: '' }];
    if (allPartners.length > 0) {
      return emptyOption.concat(allPartners.map((partner) => ({ label: partner?.name, value: partner?.id })));
    }
    return emptyOption;
  }, [allPartners]);

  const companyDirectorsOptions = useMemo(() => {
    return directors?.map((d) => ({
      value: d?.id,
      label: composeDirectorName(d?.last_name || '', d?.first_name || ''),
    }));
  }, [directors]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={formSchema}
      innerRef={formRef}
      validateOnChange={false}
    >
      {({ values, errors, touched, submitCount, handleChange, handleSubmit, setFieldValue, setValues }) => {
        const requiredName = Boolean(errors.registered_name);
        const alreadyActiveApplication = /active application/i.test(errors.registered_number ?? '');
        const notActive = Boolean(errors.company_status);
        const isUKCompany = values?.region === ApplicationRegion.UK;
        const isUSACompany = values?.region === ApplicationRegion.USA;
        const isIrishCompany = values?.region === ApplicationRegion.IRL;

        return (
          <Dialog
            open={open}
            onClose={onCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            classes={{ paper: clsx([classes.modalContainer]) }}
          >
            <Box className={clsx([classes.c_card, classes.cardShadow])}>
              <Box className={clsx([classes.c_cardTitle, classes.filter_cardTitle])}>
                <Typography variant="text_LG" component="h3">
                  {t(`pages.dashboard.addAdminActions.title.${sendLink ? 'send' : 'create'}`)}
                </Typography>
                <Button className={classes?.closeFilterIcon} onClick={onCancel}>
                  <Close />
                </Button>
              </Box>
              <Box className={clsx([classes.filter_cardBody])}>
                <Form>
                  <FormValidation />
                  <CustomSelectBox
                    options={applicationRegionOptions}
                    name="region"
                    value={values?.region}
                    onChange={(event: ChangeEvent<{ value: unknown }>) => {
                      setFieldValue('region', event.target.value);
                    }}
                    label={t('pages.dashboard.addAdminActions.inputs.region.label')}
                    disabled={loading}
                  />

                  {!partnerId && (
                    <CustomSelectBox
                      options={
                        partnersOptions[0]?.value === '' && partnersOptions.length === 1
                          ? [{ label: 'No active partners found', value: 'No active partners found' }]
                          : partnersOptions
                      }
                      name="partner_id"
                      className={
                        partnersOptions[0]?.value === '' && partnersOptions.length === 1
                          ? clsx([classes.selectDisable])
                          : ''
                      }
                      value={values?.partner_id}
                      onChange={(event: ChangeEvent<{ value: unknown }>) => {
                        setFieldValue('partner_id', event.target.value);
                      }}
                      label={t('pages.dashboard.addAdminActions.inputs.partner_id.label')}
                      disabled={loading}
                    />
                  )}

                  <TextFieldBox
                    name="email"
                    value={values?.email}
                    label={t('pages.dashboard.addAdminActions.inputs.email.label')}
                    placeholder={t('pages.dashboard.addAdminActions.inputs.email.placeholder')}
                    mainBoxClassName={commonClasses.marginBottom24px}
                  />

                  {isUKCompany ? (
                    <Box className={commonClasses.marginBottom24px}>
                      <ThemeProvider theme={MuiAutocompleteSelectBox}>
                        <Autocomplete
                          id="registered_name"
                          value={selectedOption}
                          open={autoCompleteOpen}
                          onOpen={() => setAutoCompleteOpen(true)}
                          onClose={() => setAutoCompleteOpen(false)}
                          onChange={(e, value) => {
                            if (typeof value === 'string') return;
                            setSelectedOption(value);
                            if (value?.companyNumber) searchDirectors(value.companyNumber);
                            else setDirectors([]);
                            setValues({
                              ...values,
                              director_id: '',
                              first_name: '',
                              last_name: '',
                              registered_name: value?.title ?? '',
                              registered_number: value?.companyNumber ?? '',
                              formation_date: value?.incorporatedOn ?? '',
                              company_status: value?.companyStatus ?? '',
                            });
                          }}
                          onInputChange={(e, value) => setSearchTerm(value)}
                          options={options}
                          loading={loadingOptions}
                          getOptionSelected={(option, value) => option.title === value.title}
                          getOptionLabel={(option) => option.title}
                          renderInput={(props) => (
                            <Field
                              {...props}
                              name="registered_name"
                              component={CustomTextField}
                              title={t('pages.dashboard.addAdminActions.inputs.registered_name.label')}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder={t('pages.dashboard.addAdminActions.inputs.registered_name.placeholder')}
                              error={alreadyActiveApplication || notActive || (touched.registered_name && requiredName)}
                              helperText={
                                (touched.registered_name && errors.registered_name) ?? alreadyActiveApplication
                                  ? errors.registered_number
                                  : errors.company_status
                              }
                              disabled={loading}
                            />
                          )}
                          className={classes.autoCompleteInput}
                          popupIcon={<ExpandMore className={classes.popupIcon} />}
                          freeSolo={debouncedSearchTerm === '' && options.length === 0}
                        />
                      </ThemeProvider>
                    </Box>
                  ) : (
                    <TextFieldBox
                      name="registered_name"
                      value={values?.registered_name}
                      mainBoxClassName={commonClasses.marginBottom24px}
                      label={t('pages.dashboard.addAdminActions.inputs.registered_name.label')}
                      placeholder={t('pages.dashboard.addAdminActions.inputs.registered_name.placeholder')}
                      disabled={loading}
                    />
                  )}

                  {isUKCompany && (
                    <>
                      {searchingDirectors && (
                        <Grid item className={classes.loadingContainer}>
                          <CircularProgress size={25} />
                        </Grid>
                      )}

                      {values?.registered_name && companyDirectorsOptions?.length > 0 && (
                        <Grid item>
                          <Field
                            id="director_id"
                            aria-label="directors-choice"
                            component={CustomRadioField}
                            options={companyDirectorsOptions}
                            name="director_id"
                            value={values?.director_id || ''}
                            onChange={(event: ChangeEvent<HTMLInputElement>) =>
                              handleDirectorChange(event?.target?.value, values, setValues)
                            }
                            title={t('pages.dashboard.addAdminActions.inputs.director.label')}
                            className={classes.textInput}
                            disabled={loading}
                          />
                        </Grid>
                      )}
                    </>
                  )}

                  {isIrishCompany && (
                    <TextFieldBox
                      name="registered_number"
                      mainBoxClassName={commonClasses.marginBottom24px}
                      value={values?.registered_number || ''}
                      label={t('pages.dashboard.addAdminActions.inputs.registered_number.label')}
                      placeholder={t('pages.dashboard.addAdminActions.inputs.registered_number.placeholder')}
                      onChange={handleChange}
                    />
                  )}

                  <TextFieldBox
                    name="first_name"
                    value={values?.first_name || ''}
                    label={t('pages.dashboard.addAdminActions.inputs.first_name.label')}
                    placeholder={t('pages.dashboard.addAdminActions.inputs.first_name.placeholder')}
                    disabled={loading}
                    onChange={handleChange}
                    mainBoxClassName={commonClasses.marginBottom24px}
                  />

                  <TextFieldBox
                    name="last_name"
                    value={values?.last_name || ''}
                    label={t('pages.dashboard.addAdminActions.inputs.last_name.label')}
                    placeholder={t('pages.dashboard.addAdminActions.inputs.last_name.placeholder')}
                    disabled={loading}
                    onChange={handleChange}
                    mainBoxClassName={commonClasses.marginBottom24px}
                  />

                  {!isUKCompany && (
                    <>
                      <Box className={commonClasses.marginBottom24px}>
                        <CustomInputDatePicker
                          name="formation_date"
                          label={t('pages.dashboard.addAdminActions.inputs.formation_date.label')}
                          value={values?.formation_date ? moment(values?.formation_date).toDate() : null}
                          onChange={(date: Moment | null) => {
                            setFieldValue('formation_date', date?.toDate().toISOString() ?? null);
                          }}
                          collapsed
                          isShowIcon="true"
                          error={submitCount > 0 ? errors.formation_date : undefined}
                          openTo="date"
                          format="DD/MM/YYYY"
                          disabled={loading}
                          className={classes.textInput}
                        />
                      </Box>
                      <Box className={commonClasses.marginBottom24px}>
                        <Field
                          id="phone_number"
                          fullWidth
                          component={CustomTextField}
                          name="phone_number"
                          value={values?.phone_number}
                          onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            setFieldValue('phone_number', event.target.value?.trim())
                          }
                          title={t('pages.dashboard.addAdminActions.inputs.phone_number.label')}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                {isUSACompany ? AreaPhoneCode.US : AreaPhoneCode.IE}
                              </InputAdornment>
                            ),
                            inputComponent: PhoneNumberInput,
                          }}
                          className={classes.textInput}
                          disabled={loading}
                          mainBoxClassName={commonClasses.marginBottom24px}
                        />
                      </Box>
                    </>
                  )}

                  {isUSACompany && (
                    <Grid item>
                      <Field
                        id="ein"
                        fullWidth
                        component={CustomTextField}
                        name="ein"
                        value={values?.ein}
                        onChange={handleChange}
                        title={t('pages.dashboard.addAdminActions.inputs.ein.label')}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          inputComponent: EINNumberInput,
                        }}
                        className={classes.textInput}
                        disabled={loading}
                      />
                    </Grid>
                  )}
                  {!sendLink && false && (
                    <Box>
                      <CustomTextarea name="message" label="Drop a message" minRows={3} />
                    </Box>
                  )}
                </Form>
                <FooterButton btnName="Submit application" handleSubmit={handleSubmit} isShowCancelBtn={false} />
              </Box>
            </Box>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default CreateApplicationModal;
