import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Paper, Typography } from '@mui/material';
import { allowedFileTypes as Files } from 'core/constants';
import useStyles from './CustomImageUpload.styles';

interface CustomFileUploaderProps {
  maxSize: number;
  allowedFileTypes: string[];
  loading: boolean;
  multiple?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFiles?: any;
}

const dropzoneStyle: React.CSSProperties = {
  textAlign: 'center',
  padding: '20px',
  border: '2px dashed #ccc',
  borderRadius: '4px',
  cursor: 'pointer',
};

const CustomFileUploader: React.FC<CustomFileUploaderProps> = ({ maxSize, multiple = false, setFiles }) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();

  const handleFileUpload = (acceptedFiles: File[]) => {
    const previewImages: File[] = [];

    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target && event.target.result) {
          previewImages.push(file);
          setSelectedFiles([...previewImages]);
        }
      };
      reader.readAsDataURL(file);
    });

    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
      setSelectedFiles(acceptedFiles);
      setFiles?.({ accepted: acceptedFiles });
    }, 2000);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => handleFileUpload(acceptedFiles),
    maxSize,
    accept: Files.map((fileType) => fileType.mimeType),
    disabled: isLoading,
    multiple,
  });

  return (
    <div>
      <Paper elevation={3} {...getRootProps()} style={dropzoneStyle}>
        <input {...getInputProps()} />
        <Box className={classes.inputFileContent}>
          <span className={classes.inputFileIcon}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_1698_3041)">
                <path
                  d="M13.3334 13.3334L10 10M10 10L6.66669 13.3334M10 10V17.5M16.9917 15.325C17.8045 14.8819 18.4466 14.1808 18.8166 13.3322C19.1866 12.4837 19.2635 11.5361 19.0352 10.6389C18.8069 9.74182 18.2863 8.94629 17.5556 8.3779C16.8249 7.80951 15.9257 7.50064 15 7.50003H13.95C13.6978 6.5244 13.2277 5.61864 12.575 4.85085C11.9223 4.08307 11.1041 3.47324 10.1818 3.0672C9.25949 2.66116 8.25715 2.46949 7.25013 2.5066C6.2431 2.5437 5.25758 2.80861 4.36768 3.28142C3.47777 3.75422 2.70662 4.42261 2.11221 5.23635C1.5178 6.05008 1.1156 6.98797 0.935844 7.97952C0.756086 8.97107 0.803449 9.99047 1.07437 10.9611C1.3453 11.9317 1.83273 12.8282 2.50003 13.5834"
                  stroke="#475467"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_1698_3041">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </span>
          <Typography component="p" className={classes.inputFileText}>
            Click to upload
            <Typography component="span">or drag and drop</Typography>
          </Typography>
        </Box>
      </Paper>
      <ul className="file-list">
        {selectedFiles.map((file) => (
          <li key={file?.name} className={classes.li_image}>
            <span className="file-name">{file?.name}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CustomFileUploader;
