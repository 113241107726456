import { ChangeEvent, FC, useRef } from 'react';
import { Box, Grid } from '@material-ui/core';
import { Form, Formik, FormikProps } from 'formik';
import pickBy from 'lodash.pickby';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import clsx from 'clsx';
import CustomSelectBox from 'components/CustomSelect/CustomSelect';
import TextFieldBox from 'components/CustomTextInput/TextInputBox';
import FooterButton from 'components/Modal/FooterButton';
import { AlertMessage } from 'components/alerts';
import { accountCountryOptions, websiteRegExp } from 'core/constants';
import { AlertMessageStatus } from 'core/types';
import { usePartners } from 'store/partners/hooks';
import { CreatePartner, PartnerDetails } from 'store/partners/types';
import useCommonStyles from '../../../../assets/css/Common.styles';

interface FormValues {
  name: string;
  displayed_name: string;
  contact_first_name: string;
  contact_last_name: string;
  contact_email: string;
  active: boolean;
  address: string;
  logo_url: string;
  website: string;
  phone_number: string;
  job_title: string;
  country_code: string;
  industry: string;
  employees: string;
}

interface CreatePartnerModalProps {
  open: boolean;
  toggleOpen: () => void;
  partner?: PartnerDetails;
}

const CreatePartnerModal: FC<CreatePartnerModalProps> = ({ open, toggleOpen, partner }) => {
  const formRef = useRef<FormikProps<FormValues> | null>(null);
  const commonClasses = useCommonStyles();
  const { t } = useTranslation();
  const { loading, error, setError, createPartner, updatePartner } = usePartners(() => {
    if (open) {
      formRef.current?.resetForm();
      toggleOpen();
    }
  });

  const lenderSchema = yup.object({
    name: yup.string().required(t('pages.partners.admin.createPartner.inputs.name.required')),
    displayed_name: yup.string().required(t('pages.partners.admin.createPartner.inputs.displayed_name.required')),
    contact_first_name: yup
      .string()
      .required(t('pages.partners.admin.createPartner.inputs.contact_first_name.required')),
    contact_last_name: yup.string().required(t('pages.partners.admin.createPartner.inputs.contact_last_name.required')),
    contact_email: yup
      .string()
      .email(t('pages.partners.admin.createPartner.inputs.contact_email.error'))
      .required(t('pages.partners.admin.createPartner.inputs.contact_email.required')),
    active: yup.boolean(),
    logo_url: yup.string().url(t('pages.partners.admin.createPartner.inputs.logo_url.error')),
    website: yup.string().matches(websiteRegExp, t('pages.partners.admin.createPartner.inputs.website.error')),
    address: yup.string(),
    phone_number: yup.string(),
    job_title: yup.string(),
    country_code: yup.string().required(t('pages.partners.admin.createPartner.inputs.country_code.required')),
    industry: yup.string(),
    employees: yup.string(),
  });

  const initialValues: FormValues = {
    name: partner?.name ?? '',
    displayed_name: partner?.displayed_name ?? '',
    contact_first_name: partner?.contact_person_name?.split(' ')[0] ?? '',
    contact_last_name: partner?.contact_person_name?.split(' ')[1] ?? '',
    contact_email: partner?.contact_person_email ?? '',
    active: partner?.active ?? false,
    address: partner?.address ?? '',
    logo_url: partner?.logo_url ?? '',
    website: partner?.website ?? '',
    phone_number: partner?.phone_number ?? '',
    job_title: partner?.job_title ?? '',
    country_code: partner?.country_code ?? '',
    industry: partner?.industry ?? '',
    employees: partner?.employees ?? '',
  };

  const onSubmit = (values: FormValues) => {
    const partnerData = { ...pickBy(values), active: values.active } as CreatePartner;

    if (partner?.id) {
      updatePartner({ id: partner.id, partner: partnerData });
    } else {
      createPartner(partnerData);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={lenderSchema}
        innerRef={formRef}
        enableReinitialize
      >
        {({ values, handleSubmit, setFieldValue }) => {
          const fieldData = [
            { name: 'name', value: values?.name, label: t('pages.partners.admin.createPartner.inputs.name.label') },
            {
              name: 'displayed_name',
              value: values?.displayed_name,
              label: t('pages.partners.admin.createPartner.inputs.displayed_name.label'),
            },
            {
              name: 'contact_first_name',
              value: values?.contact_first_name,
              label: t('pages.partners.admin.createPartner.inputs.contact_first_name.label'),
            },
            {
              name: 'contact_last_name',
              value: values?.contact_last_name,
              label: t('pages.partners.admin.createPartner.inputs.contact_last_name.label'),
            },
            {
              name: 'contact_email',
              value: values?.contact_email,
              label: t('pages.partners.admin.createPartner.inputs.contact_email.label'),
            },
            {
              name: 'logo_url',
              value: values?.logo_url,
              label: t('pages.partners.admin.createPartner.inputs.logo_url.label'),
            },
            {
              name: 'website',
              value: values?.website,
              label: t('pages.partners.admin.createPartner.inputs.website.label'),
            },
          ];
          return (
            <Box className={clsx([commonClasses.filter_cardBody])}>
              <Form noValidate>
                <Grid container direction="column">
                  {(fieldData || [])?.slice(0, 5)?.map((data) => {
                    return (
                      <Grid item key={data?.name}>
                        <TextFieldBox name={data?.name} value={data?.value} label={data?.label} />
                      </Grid>
                    );
                  })}

                  <Grid item>
                    <CustomSelectBox
                      name="country_code"
                      options={accountCountryOptions}
                      value={values.country_code}
                      onChange={(event: ChangeEvent<{ value: unknown }>) =>
                        setFieldValue('country_code', event.target.value)
                      }
                      label={t('pages.partners.admin.createPartner.inputs.country_code.label')}
                    />
                  </Grid>

                  {(fieldData || [])?.slice(5)?.map((data) => {
                    return (
                      <Grid item key={data?.label}>
                        <TextFieldBox name={data?.name} value={data?.value} label={data?.label} />
                      </Grid>
                    );
                  })}
                </Grid>
                <FooterButton
                  btnName={partner ? 'Update' : 'Create'}
                  handleSubmit={handleSubmit}
                  onClose={toggleOpen}
                  loading={loading}
                />
              </Form>
            </Box>
          );
        }}
      </Formik>

      {error && (
        <AlertMessage
          open={!!error}
          onClose={() => setError(false)}
          message={typeof error === 'string' ? error : undefined}
          autoHideDuration={5000}
          status={AlertMessageStatus.ERROR}
        />
      )}
    </>
  );
};

export default CreatePartnerModal;
