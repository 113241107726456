import { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from 'store/types';

import { useActionCreator, usePrevious } from 'hooks';
import { AdminReturnHook } from './types';
import {
  makeAnOffer,
  clearError,
  setError,
  updateApplicationStatus,
  updateOfferDetails,
  updateOfferStatus,
  rejectApplication,
  uploadLenders,
  introduceLender,
  setSuccess,
  getAdminUsers,
  getRulesEngine,
  setRefreshEmails,
  updateRulesEngine,
  updateAdminUsersAction,
} from './actions';

export const useAdmin = (onSuccess?: () => void): AdminReturnHook => {
  const adminState = useSelector((state: RootState) => state.admin, shallowEqual);
  const prevLoading = usePrevious(adminState.loading);

  useEffect(() => {
    if (prevLoading && !adminState.loading && !adminState.error && onSuccess) {
      onSuccess();
    }
  }, [adminState.error, adminState.loading, onSuccess, prevLoading]);

  return {
    ...adminState,
    makeAnOffer: useActionCreator(makeAnOffer),
    clearError: useActionCreator(clearError),
    setError: useActionCreator(setError),
    updateApplicationStatus: useActionCreator(updateApplicationStatus),
    updateOfferDetails: useActionCreator(updateOfferDetails),
    updateOfferStatus: useActionCreator(updateOfferStatus),
    rejectApplication: useActionCreator(rejectApplication),
    uploadLenders: useActionCreator(uploadLenders),
    introduceLender: useActionCreator(introduceLender),
    setSuccess: useActionCreator(setSuccess),
    getAdminUsers: useActionCreator(getAdminUsers),
    getRulesEngine: useActionCreator(getRulesEngine),
    setRefreshEmails: useActionCreator(setRefreshEmails),
    updateRulesEngine: useActionCreator(updateRulesEngine),
    updateAdminUsers: useActionCreator(updateAdminUsersAction),
  };
};
