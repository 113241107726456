import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Container, Grid, Link, Paper, Typography } from '@material-ui/core';
import { Description, Mail } from '@material-ui/icons';
import { AlertMessage } from 'components/alerts';
import { Loader } from 'components/Loader';
import { TrustedDomains } from 'components/TrustedDomains';
import { AlertMessageStatus } from 'core/types';
import { useAccount } from 'store/account/hooks';
import { useGlobalState } from 'store/global/hooks';
import { usePartners } from 'store/partners/hooks';
import { ApiSecret } from '../Api/components';
import { KeyType } from '../Api/components/ApiSecret/ApiSecret';
import { ConfigureWidgetForm } from './components/ConfigureWidgetForm';
import useStyles from './Widget.styles';

const Security: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { error: globalError } = useGlobalState();
  const { error, setError, success, setSuccess, loading } = usePartners();

  // const production = (process.env.REACT_APP_STAGE as string) === 'production';

  const {
    account: {
      api_keys: { public_key },
    },
  } = useAccount();

  const publicKey = useMemo(() => {
    return public_key || '';
  }, [public_key]);

  return (
    <Container disableGutters maxWidth="xl">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid item sm={8} lg={6}>
            <Typography className={classes.title} gutterBottom>
              {t('pages.widget.title')}
            </Typography>
            <Typography className={classes.subtitle} gutterBottom>
              {t('pages.widget.subtitle')}
            </Typography>
          </Grid>
          <Grid item xs={6} className={classes.sectionHeader}>
            <Typography className={classes.subtitle} gutterBottom>
              {t('pages.widget.sections.gettingStarted.title')}
            </Typography>
            <Typography className={classes.text} gutterBottom>
              {t('pages.widget.sections.gettingStarted.subtitle')}
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={8} lg={6}>
          <Paper className={classes.keysContainer}>
            <Typography className={classes.subtitle} gutterBottom>
              {t('pages.api.apiKeys.title')}
            </Typography>

            <ApiSecret
              title={t('pages.api.apiKeys.public_key')}
              description={t('pages.api.apiKeys.message')}
              secret={publicKey}
              type={KeyType.PUBLIC}
              name="public_key"
            />
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Grid item xs={12} sm={8} lg={6}>
            <Typography className={classes.subtitle} gutterBottom>
              {t('pages.widget.sections.steps.title')}
            </Typography>
            <Typography className={classes.text} gutterBottom>
              {t('pages.widget.sections.steps.subtitle')}
            </Typography>

            <ul>
              <li className={classes.gutterBottom}>
                <Typography variant="body2" gutterBottom>
                  {t('pages.widget.sections.steps.list.domains1')} {t('pages.widget.sections.steps.list.domains2')}{' '}
                  {t('pages.security.sections.trustedDomains.subtitle')}{' '}
                  {t('pages.widget.sections.steps.list.domains3')}{' '}
                  <Link href="https://docs.scorethebusiness.com/reference/configure-trusted-domains" target="_blank">
                    {t('pages.widget.sections.steps.list.domains4')}
                  </Link>
                </Typography>
                <TrustedDomains />
              </li>
              <li>
                <Typography variant="body2">
                  {t('pages.widget.sections.steps.list.config1')}{' '}
                  <Link href="https://docs.scorethebusiness.com/reference/displaying-in-an-ios-webview">
                    {t('pages.widget.sections.steps.list.configiOS')}
                  </Link>{' '}
                  {t('pages.widget.sections.steps.list.configAndroid')}.
                </Typography>
              </li>
            </ul>
          </Grid>

          <ConfigureWidgetForm publicKey={publicKey} />
        </Grid>

        <Grid item xs={12}>
          <Typography className={classes.subtitle} gutterBottom>
            {t('pages.api.resources.title')}
          </Typography>
        </Grid>

        <Grid item xs={12} className={classes.actionsContainer}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Description />}
            onClick={() => window.open(t('pages.auth.footer.links.widgetUrl'))}
          >
            {t('pages.widget.resources.widgetjs')}
          </Button>
          <Button variant="contained" color="primary" startIcon={<Mail />} href="mailto:olu@scorethebusiness.com">
            {t('pages.api.resources.support')}
          </Button>
        </Grid>
      </Grid>

      {!globalError && (
        <>
          <AlertMessage
            open={!!error}
            onClose={() => setError(false)}
            message={typeof error === 'string' ? error : undefined}
            autoHideDuration={5000}
          />

          <AlertMessage
            open={!!success}
            onClose={() => setSuccess(false)}
            message={typeof success === 'string' ? success : undefined}
            autoHideDuration={5000}
            status={AlertMessageStatus.SUCCESS}
          />
        </>
      )}

      <Loader visible={loading} />
    </Container>
  );
};

export default Security;
