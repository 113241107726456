import { AxiosRequestConfig } from 'axios';
import { apiCall } from 'http/index';
import { UserResponse, UserQuery, UserRole, UsersListResponse } from 'core/types';

export const getUsers = async (query: UserQuery = {}): Promise<UsersListResponse> => {
  const queryString = new URLSearchParams(query as Record<string, string>).toString();
  return apiCall({ method: 'get', url: `/users?${queryString}` });
};

export const inviteUser = async (email: string, role: UserRole, account_id?: string): Promise<null> =>
  apiCall({ method: 'post', url: '/dashboard/invitations', data: { email, role, account_id } });

export const getCurrentAuthenticatedUser = async (authToken?: string): Promise<UserResponse> => {
  const axiosConfig: AxiosRequestConfig = { method: 'get', url: '/users/current' };
  if (authToken) {
    axiosConfig.headers = {
      Authorization: `Bearer ${authToken}`,
    };
  }
  return apiCall(axiosConfig);
};
