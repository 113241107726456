import { Box, List, ListItem, Typography } from '@mui/material';
import clsx from 'clsx';
import {
  CompanyDetails,
  IApplicationData,
  ApplicationDetails as IApplicationDetails,
  ICompanyInformation,
} from 'store/applications/types';
import moment from 'moment';
import { isHomeowner } from 'utils';
import useStyles from '../../Lead.styles';

import { ReactComponent as BookIcon } from '../../../../assets/icons/book.svg';
import { ReactComponent as CompanyInformation } from '../../../../assets/icons/companyInformation.svg';
import { ReactComponent as FundingRequest } from '../../../../assets/icons/funding_request.svg';
import { ReactComponent as UserIcon } from '../../../../assets/icons/userIcon.svg';
import { ReactComponent as WalletIcon } from '../../../../assets/icons/walletIcon.svg';

export const ApplicationDetails = ({ details }: { details: IApplicationDetails | null }) => {
  const applicant = details?.company?.people.find((p) => p?.applicant);
  const activeAddress = applicant?.addresses?.find((addr) => addr?.current);
  const company: CompanyDetails | undefined = details?.company;
  const addressDetails = applicant?.addresses[0];
  const classes = useStyles();

  let is_homeowner = 'No';
  if (activeAddress?.status && isHomeowner(activeAddress?.status)) {
    is_homeowner = 'Yes';
  }

  const applicationData: IApplicationData = {
    'First name': applicant?.first_name,
    'Last name': applicant?.last_name,
    'Email address': applicant?.email,
    'Mobile number': applicant?.phone_number,
    'Date of birth': applicant?.date_of_birth,
    Country: applicant?.country_of_residence,
    'Is a homeowner?': is_homeowner.toString(),
    'Address line 1': addressDetails?.line_1 || '-',
    City: addressDetails?.city || '-',
    Postcode: addressDetails?.postcode,
    State: '-',
    Region: addressDetails?.region || '-',
    'Country (Code)': `${addressDetails?.county || ''} ${
      addressDetails?.country_code ? `(${addressDetails?.country_code})` : ''
    } `,
    'Current address?': addressDetails?.current ? 'True' : 'False',
    To:
      moment().diff(moment(details?.updated_at), 'days') === 0
        ? 'Present'
        : moment(details?.updated_at).format('DD/MM/YYYY'),
  };

  const companyInformation: ICompanyInformation = {
    'Registered Name': company?.registered_name,
    'Registration number': company?.registered_number,
    Type: company?.type,
    Region: company?.region,
    'Incorporated On': company?.formation_date,
    'Business age': company?.formation_date
      ? `${moment().diff(moment(company?.formation_date), 'months').toString()} months`
      : '-',
    'Trading since': company?.trading_from_date,
    'Vat registered': company?.vat_registered ? 'Yes' : 'No',
    'Address line 1': company?.address?.line_1 as string,
    'Country (Code)': `${applicant?.country_of_residence}  (${company?.region})`,
    Current: company?.address?.current ? 'True' : 'False',
    'CCJS active': company?.ccjs && company?.ccjs?.length > 0 ? 'True' : 'False',
    'CCJS historic': company?.ccjs?.map((d) => d?.type)?.join(',') || '-',
  };

  const funding_request_information = {
    'Requested loan amount': `£${details?.funding_amount || 0}`,
    'Funding reasons': `${details?.funding_reasons ? details?.funding_reasons?.join(',') : '-'}`,
    'Credit limit': details?.credit_limit || 0,
    'Credit score consent': '-',
  };

  const financialInformation = {
    'Average revenue per month': ` £${details?.company?.financial_information?.monthly_revenue || 0}`,
    'Annual revenue for the last 12 months': `£${details?.company?.financial_information?.year_to_date_revenue || 0}`,
  };

  const listData = [
    {
      title: 'Applicant information',
      icon: <UserIcon className={classes.icon18} />,
      data: applicationData,
    },
    {
      title: 'Financial information',
      icon: <WalletIcon className={classes.icon18} />,
      data: financialInformation,
    },
    {
      title: 'Company information',
      icon: <CompanyInformation className={classes.icon18} />,
      data: companyInformation,
    },
    {
      title: 'Funding request information',
      icon: <FundingRequest className={classes.icon18} />,
      data: funding_request_information,
    },
  ];

  return (
    <>
      <Box className={classes.headingStripIconText}>
        <Box className={classes.headingStripIcon}>
          <BookIcon />
        </Box>
        <Box className={classes.headingStripTextWrap}>
          <Typography variant="text_MD" component="h3" className={classes.fontJost}>
            All information relating to {applicant?.first_name} applications
          </Typography>
        </Box>
      </Box>
      <Box className={clsx([classes.tabInfoWrap, classes.tabGap_160])}>
        {[0, 1]?.map((d) => {
          return (
            <Box className={clsx([classes.tabCol, d !== 1 && classes.tabColLine])}>
              {listData?.slice(d === 1 ? 2 : 0, d === 1 ? 4 : 2)?.map((data) => {
                return (
                  <Box className={clsx([classes.flex, classes.flexCol, classes.gap_24])}>
                    <Box className={classes.headingSubStripIconText}>
                      <Box className={classes.headingSubStripIcon}>{data?.icon}</Box>
                      <Box className={classes.headingSubStripTextWrap}>
                        <Typography variant="text_SM" component="h4" className={classes.fontJost}>
                          {data?.title}
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <List className={clsx([classes.listKeyValueWrap])}>
                        {Object.keys(data?.data)?.length > 0 &&
                          Object.entries(data?.data)?.map((e) => {
                            return (
                              <ListItem key={e[0]} className={clsx([classes.listKeyValueItem])}>
                                <Box className={clsx([classes.listKeyValueBox])}>
                                  <Box className={clsx([classes.listKeyValueBox_key])}>
                                    <Typography
                                      variant="text_SM"
                                      component="p"
                                      className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                                    >
                                      {e[0]}
                                    </Typography>
                                  </Box>
                                  <Box className={clsx([classes.listKeyValueBox_value])}>
                                    <Typography
                                      variant="text_SM"
                                      component="p"
                                      className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                                    >
                                      {e[1] || '-'}
                                    </Typography>
                                  </Box>
                                </Box>
                              </ListItem>
                            );
                          })}
                      </List>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          );
        })}
      </Box>
    </>
  );
};
