import { FC, useRef, useState } from 'react';
import { Grid } from '@material-ui/core';
import { Formik, Form, FormikProps } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import { createAdmin as createAdminApi } from 'http/admin';
import { useAdmin } from 'store/admin/hooks';
import { UserRole } from 'core/types';
import { composeFullName } from 'utils';
import { Box } from '@mui/material';
import TextFieldBox from 'components/CustomTextInput/TextInputBox';
import FooterButton from 'components/Modal/FooterButton';
import useCommonStyles from '../../../../assets/css/Common.styles';

interface FormValues {
  first_name: string;
  last_name: string;
  occupation?: string;
  email: string;
}

interface CreateAdminModalProps {
  toggleOpen: () => void;
}

const CreateAdminModal: FC<CreateAdminModalProps> = ({ toggleOpen }) => {
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormikProps<FormValues> | null>(null);
  const commonClasses = useCommonStyles();
  const { t } = useTranslation();
  const { setSuccess, setError, updateAdminUsers } = useAdmin();

  const initialValues: FormValues = {
    first_name: '',
    last_name: '',
    occupation: '',
    email: '',
  };

  const lenderSchema = yup.object({
    first_name: yup.string().required(t('pages.admins.admin.createAdmin.inputs.first_name.required')),
    last_name: yup.string().required(t('pages.admins.admin.createAdmin.inputs.last_name.required')),
    occupation: yup.string().notRequired(),
    email: yup
      .string()
      .email(t('pages.admins.admin.createAdmin.inputs.email.error'))
      .required(t('pages.admins.admin.createAdmin.inputs.email.required')),
  });

  const onCancel = () => {
    formRef.current?.resetForm();
    toggleOpen();
  };

  const onSubmit = async (values: FormValues) => {
    setLoading(true);
    try {
      const user = await createAdminApi(values);
      const createdAt = new Date().toISOString();
      updateAdminUsers({
        ...values,
        ...user,
        role: UserRole.ADMIN,
        fullName: composeFullName(values.first_name, values.last_name),
        created_at: createdAt,
        updated_at: createdAt,
        can_delete: true,
      });
      setSuccess(t('pages.admins.admin.success.create') as string);
      onCancel();
    } catch (err) {
      setError((err as Error).message ?? true);
    }
    setLoading(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={lenderSchema}
      innerRef={formRef}
      enableReinitialize
    >
      {({ values, handleSubmit }) => {
        const fieldData = [
          {
            value: values?.first_name,
            name: 'first_name',
            label: t('pages.admins.admin.createAdmin.inputs.first_name.label'),
          },
          {
            value: values?.last_name,
            name: 'last_name',
            label: t('pages.admins.admin.createAdmin.inputs.last_name.label'),
          },
          {
            value: values?.occupation,
            name: 'occupation',
            label: t('pages.admins.admin.createAdmin.inputs.occupation.label'),
          },
          { value: values?.email, name: 'email', label: t('pages.admins.admin.createAdmin.inputs.email.label') },
        ];
        return (
          <Box className={commonClasses?.filter_cardBody}>
            <Form noValidate>
              <Grid container direction="column">
                {fieldData?.map((d) => {
                  return (
                    <Grid item key={d?.name}>
                      <TextFieldBox name={d?.name} value={d?.value} label={d?.label} />
                    </Grid>
                  );
                })}
              </Grid>
              <FooterButton btnName="Create Admin" handleSubmit={handleSubmit} loading={loading} onClose={toggleOpen} />
            </Form>
          </Box>
        );
      }}
    </Formik>
  );
};

export default CreateAdminModal;
