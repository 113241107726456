import { ActionReducerMapBuilder, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { logout } from 'store/auth/actions';
import { AccountState } from './types';
import {
  fetchAccount,
  fetchApiKeys,
  inviteUser,
  setError,
  setSuccess,
  getAccountUsers,
  deleteAccountUser,
  updateAccountUserRole,
} from './actions';

const initialState: AccountState = {
  loading: false,
  error: false,
  success: false,
  account: {
    id: null,
    name: null,
    owner: {
      id: null,
      first_name: null,
      last_name: null,
      email: null,
      role: null,
    },
    api_keys: {
      public_key: null,
      secret_key: null,
      test_public_key: null,
      test_secret_key: null,
    },
  },
  total: -1,
  users: [],
};

const accountStore = createSlice({
  name: 'account',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<AccountState>) => {
    builder.addCase(fetchAccount.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.account = { ...state.account, ...payload };
    });

    builder.addCase(fetchApiKeys.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.account.api_keys = payload;
    });

    builder.addCase(logout, (state) => {
      state.account = initialState.account;
    });

    builder.addCase(inviteUser.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(setError, (state, { payload }) => {
      state.error = payload;
    });

    builder.addCase(setSuccess, (state, { payload }) => {
      state.success = payload;
    });

    builder.addCase(getAccountUsers.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.users = payload.users;
      state.total = payload.total;
    });

    builder.addCase(deleteAccountUser.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(updateAccountUserRole.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload && payload.index >= 0) state.users[payload.index].role = payload.role;
    });

    builder.addMatcher(
      isAnyOf(
        fetchAccount.pending,
        fetchApiKeys.pending,
        inviteUser.pending,
        getAccountUsers.pending,
        deleteAccountUser.pending,
        updateAccountUserRole.pending,
      ),
      (state) => {
        state.loading = true;
        state.error = false;
      },
    );

    builder.addMatcher(
      isAnyOf(
        fetchAccount.rejected,
        fetchApiKeys.rejected,
        inviteUser.rejected,
        getAccountUsers.rejected,
        deleteAccountUser.rejected,
        updateAccountUserRole.rejected,
      ),
      (state, { error }) => {
        state.loading = false;
        state.error = error.message ? error.message : true;
      },
    );
  },
});

export default accountStore.reducer;
