import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  container: {
    maxWidth: 800,
    width: '100%',
    borderRadius: 0,
    '& .bs-app-MuiDialogContent-root': {
      overflowY: 'initial',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    boxShadow: '0px 4px 4px rgba(204, 207, 209, 0.2)',
    position: 'relative',
    padding: 20,

    '& h2': {
      fontSize: theme.typography.pxToRem(18),
      lineHeight: theme.typography.pxToRem(24),
      fontWeight: 700,
      margin: 0,
      marginRight: 64,
    },
  },
  closeIcon: {
    position: 'absolute',
    top: 15,
    right: 10,
    height: 20,
    width: 20,
    padding: 16,
    '& svg': {
      height: 20,
      width: 20,
      color: theme.palette.common.black,
    },
  },
  headerIcon: {
    position: 'absolute',
    top: 16,
    right: 50,
    height: 20,
    width: 20,
    padding: 16,

    '& svg': {
      height: 20,
      width: 20,
      color: theme.palette.common.black,
    },
  },
  main: {
    padding: 20,
    paddingBottom: 0,

    '& textarea': {
      width: '100%',
      borderColor: theme.palette.greyText.main,
      borderRadius: 4,
    },
  },
  mainTitle: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20),
    color: theme.palette.greyText.main,
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 20,
  },
}));
