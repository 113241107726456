import { withStyles, Theme, createStyles, TabProps, Tab } from '@material-ui/core';

const StyledTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      fontWeight: 400,
      fontSize: theme.typography.pxToRem(12),
      '&:focus': {
        opacity: 1,
      },
      display: 'flex',
      flex: 1,
      minWidth: 100,
      lineHeight: '16px',
      whiteSpace: 'pre-line',
      paddingBottom: '9px',
    },
    wrapper: {
      '& > svg': {
        fontSize: '26px',
      },
    },
    selected: {
      background: theme.palette.common.white,
      color: theme.palette.common.black,
    },
  }),
)((props: TabProps) => <Tab disableRipple {...props} />);

export default StyledTab;
