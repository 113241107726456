import { FC, useState, ChangeEvent } from 'react';
import { Box, FormControlLabel, CircularProgress } from '@material-ui/core';

import { updateActiveAutomatedEmails as updateActiveAutomatedEmailsApi } from 'http/admin';
import { IOSSwitch } from 'pages/home/Home';
import useStyles from './AutomatedEmailsSwitch.styles';

interface AutomatedEmailsSwitchProps {
  applicationId: string;
  initialActive: boolean;
  setSuccess: (success: string | boolean) => void;
  setError: (error: string | boolean) => void;
  successMessage?: string;
}

const AutomatedEmailsSwitch: FC<AutomatedEmailsSwitchProps> = ({
  applicationId,
  initialActive,
  setSuccess,
  setError,
  successMessage,
}) => {
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(initialActive);
  const classes = useStyles();

  const toggleActive = async (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setLoading(true);
    try {
      await updateActiveAutomatedEmailsApi(applicationId, checked);
      setActive(checked);
      setSuccess(successMessage ?? true);
    } catch (err) {
      setError((err as Error).message ?? true);
    }
    setLoading(false);
  };

  return (
    <Box className={classes.switchContainer}>
      <FormControlLabel control={<IOSSwitch onChange={toggleActive} checked={active} disabled={loading} />} label="" />
      {active ? ' Enabled' : ' Disabled'}
      {loading && <CircularProgress size={24} className={classes.loader} />}
    </Box>
  );
};

export default AutomatedEmailsSwitch;
