import { createAsyncThunk, createAction, Dispatch } from '@reduxjs/toolkit';
import i18n from 'i18next';

import {
  makeAnOffer as makeAnOfferApi,
  updateApplicationStatus as updateApplicationStatusApi,
  updateOfferDetails as updateOfferDetailsApi,
  updateOfferStatus as updateOfferStatusApi,
  rejectApplication as rejectApplicationApi,
  introduceLender as introduceLenderApi,
  uploadLenders as uploadLendersApi,
  getRulesEngine as getRulesEngineApi,
} from 'http/admin';
import { getUsers as getUsersApi } from 'http/users';
import {
  ApplicationDetails,
  ApplicationsState,
  ApplicationStatus,
  LenderOffer,
  OfferStatus,
} from 'store/applications/types';
import { getApplicationDetails } from 'store/applications/actions';
import { getLenders } from 'store/lenders/actions';
import { UserQuery, UserRole } from 'core/types';
import { composeFullName } from 'utils';
import { ROWS_PER_TABLE_PAGE } from 'core/constants';
import { AuthState } from 'store/auth/types';
import { history } from 'router/service';
import routes from 'router/routes';
import {
  Admin,
  AdminState,
  LenderUpload,
  MakeOfferData,
  OfferProductType,
  RejectAppData,
  RuleEnginQuery,
  RuleEngineRule,
  UpdateAppStatusData,
  UpdateAppStatusPayload,
  UpdateOfferData,
  UpdateOfferStatusData,
} from './types';

export const MAKE_AN_OFFER = 'admin/MAKE_AN_OFFER';
export const CLEAR_ERROR = 'admin/CLEAR_ERROR';
export const UPDATE_APPLICATION_STATUS = 'admin/UPDATE_APPLICATION_STATUS';
export const UPDATE_OFFER_DETAILS = 'admin/UPDATE_OFFER_DETAILS';
export const UPDATE_OFFER_STATUS = 'admin/UPDATE_OFFER_STATUS';
export const REJECT_APPLICATION = 'admin/REJECT_APPLICATION';
export const INTRODUCE_LENDER = 'admin/INTRODUCE_LENDER';
export const SET_ERROR = 'admin/SET_ERROR';
export const SET_SUCCESS = 'admin/SET_SUCCESS';
export const UPLOAD_LENDERS = 'admin/UPLOAD_LENDERS';
export const GET_ADMIN_USERS = 'admin/GET_ADMIN_USERS';
export const GET_RULES_ENGINE = 'admin/GET_RULES_ENGINE';
export const SET_REFRESH_EMAILS = 'admin/SET_REFRESH_EMAILS';
export const UPDATE_RULES_ENGINE = 'admin/UPDATE_RULES_ENGINE';
export const UPDATE_ADMIN_USERS = 'admin/UPDATE_ADMIN_USERS';

export const clearError = createAction(CLEAR_ERROR);

export const setError = createAction<string | boolean>(SET_ERROR);

export const setSuccess = createAction<string | boolean>(SET_SUCCESS);

export const makeAnOffer = createAsyncThunk<
  void,
  MakeOfferData,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  { dispatch: any; state: { auth: AuthState; applications: ApplicationsState } }
>(MAKE_AN_OFFER, async (offer, { dispatch, getState }) => {
  await makeAnOfferApi(offer)
    .then(() => {
      const {
        auth: { role },
        applications: { applicationDetails },
      } = getState();

      if (
        role &&
        [UserRole.LENDER_MANAGER, UserRole.LENDER_MAINTAINER].includes(role) &&
        offer.status === OfferStatus.DECLINED
      ) {
        // Lender has "rejected" this application
        history.push(routes.leads);
        return;
      }
      if (applicationDetails?.id === offer.application_id) {
        dispatch(getApplicationDetails(offer.application_id));
      }
      dispatch(setSuccess('Offer sent'));
    })
    .catch((error) => {
      dispatch(setError((error as Error).message ?? true));
    });
});

export const updateApplicationStatus = createAsyncThunk<
  UpdateAppStatusPayload,
  UpdateAppStatusData,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  { state: { applications: ApplicationsState }; dispatch: any }
>(UPDATE_APPLICATION_STATUS, async ({ id, appStatus }, { getState, dispatch }) => {
  await updateApplicationStatusApi(id, appStatus);

  if (appStatus === ApplicationStatus.REJECTED) {
    const successMessage = i18n.t('pages.lead.admin.reject.messages.success.application');
    dispatch(setSuccess(successMessage));
  } else {
    dispatch(setSuccess(true));
  }

  const {
    applications: { applications, applicationDetails },
  } = getState();
  const payload: UpdateAppStatusPayload = {};

  if (applicationDetails) {
    const updatedAppDetails: ApplicationDetails = {
      ...applicationDetails,
      status: appStatus,
    };
    payload.applicationDetails = updatedAppDetails;
  }

  const appIdx = applications.findIndex((app) => app.id === id);
  if (appIdx >= 0) {
    const updatedApps = [...applications];
    updatedApps[appIdx] = { ...applications[appIdx], status: appStatus };
    payload.applications = updatedApps;
  }

  return payload;
});

export const updateOfferDetails = createAsyncThunk<
  void,
  { id: string; details: UpdateOfferData },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  { state: { applications: ApplicationsState }; dispatch: any }
>(UPDATE_OFFER_DETAILS, async ({ id, details }, { getState, dispatch }) => {
  await updateOfferDetailsApi(id, details);

  const {
    applications: { applicationDetails },
  } = getState();
  if (applicationDetails?.id) {
    dispatch(getApplicationDetails(applicationDetails.id));
  }
});

export const updateOfferStatus = createAsyncThunk<
  { offerIndex: number; updatedOffer: LenderOffer } | null,
  UpdateOfferStatusData,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  { state: { applications: ApplicationsState }; dispatch: any }
>(UPDATE_OFFER_STATUS, async ({ id, status, rejectionReason, rejectionReasonTags }, { getState, dispatch }) => {
  await updateOfferStatusApi(id, status, rejectionReason, rejectionReasonTags);

  if (status === OfferStatus.DECLINED) {
    const successMessage = i18n.t('pages.lead.admin.reject.messages.success.offer');
    dispatch(setSuccess(successMessage));
  }

  const {
    applications: { applicationDetails },
  } = getState();
  if (applicationDetails) {
    const offerIndex = applicationDetails.offers.findIndex((off) => off.id === id);
    if (offerIndex >= 0) {
      const updatedOffer = { ...applicationDetails.offers[offerIndex], status };
      return { offerIndex, updatedOffer };
    }
    return null;
  }
  return null;
});

export const rejectApplication = createAsyncThunk<
  ApplicationDetails | null,
  RejectAppData,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  { state: { applications: ApplicationsState }; dispatch: any }
>(REJECT_APPLICATION, async ({ id, rejectionReason, rejectionReasonTags }, { getState, dispatch }) => {
  await rejectApplicationApi(id, rejectionReason, rejectionReasonTags);

  const successMessage = i18n.t('pages.lead.admin.reject.messages.success.application');
  dispatch(setSuccess(successMessage));

  const {
    applications: { applicationDetails },
  } = getState();

  if (!applicationDetails || applicationDetails?.id !== id) return null;

  return {
    ...applicationDetails,
    status: ApplicationStatus.REJECTED,
    application_rejection: applicationDetails.application_rejection
      ? {
          ...applicationDetails.application_rejection,
          rejection_reason: rejectionReason,
          rejection_reason_tags: rejectionReasonTags,
        }
      : {
          rejected_at: new Date().toISOString(),
          rejection_reason: rejectionReason,
          rejection_reason_tags: rejectionReasonTags,
        },
  };
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const uploadLenders = createAsyncThunk<void, LenderUpload[], { dispatch: any }>(
  UPLOAD_LENDERS,
  async (lenders, { dispatch }) => {
    await uploadLendersApi(lenders);
    dispatch(getLenders({ per_page: ROWS_PER_TABLE_PAGE, page: 1 }));
    const successMessage = i18n.t('pages.lenders.admin.uploadLender.messages.success');
    dispatch(setSuccess(successMessage));
  },
);

export const introduceLender = createAsyncThunk<
  void,
  { id: string; lenderId: string; productType: OfferProductType },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  { dispatch: any; state: { applications: ApplicationsState } }
>(INTRODUCE_LENDER, async ({ id, lenderId, productType }, { dispatch, getState }) => {
  await introduceLenderApi(id, lenderId, productType).then(() => {
    const {
      applications: { applicationDetails },
    } = getState();
    if (applicationDetails?.id) {
      dispatch(getApplicationDetails(applicationDetails.id));
    }

    const successMessage = i18n.t('pages.lead.admin.lenderIntroduction.messages.success');
    dispatch(setSuccess(successMessage));
    return true;
  });
});

export const getAdminUsers = createAsyncThunk<{ admins: Admin[]; total: number }, UserQuery>(
  GET_ADMIN_USERS,
  async (query) => {
    const data = await getUsersApi(query);
    const admins = data.users.map(
      (user) => ({ ...user, fullName: composeFullName(user.first_name, user.last_name) } as Admin),
    );
    return { admins, total: data.total };
  },
);

export const getRulesEngine = createAsyncThunk<RuleEngineRule[], RuleEnginQuery>(GET_RULES_ENGINE, async (query) => {
  const data = await getRulesEngineApi(query as RuleEnginQuery);
  return data;
});

export const setRefreshEmails = createAction<boolean>(SET_REFRESH_EMAILS);

export const updateRulesEngine = createAction<RuleEngineRule[]>(UPDATE_RULES_ENGINE);

export const updateAdminUsers = createAction<Admin[]>(UPDATE_ADMIN_USERS);

export const updateAdminUsersAction =
  (payload: string | Admin) =>
  (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dispatch: Dispatch<any>,
    getState: () => { admin: AdminState },
  ): void => {
    const {
      admin: { admins },
    } = getState();
    if (typeof payload === 'string') {
      dispatch(updateAdminUsers(admins.filter((admin) => admin.id !== payload)));
      return;
    }
    dispatch(updateAdminUsers([payload, ...admins]));
  };
