/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-no-undef */
// eslint-disable-next-line import/no-duplicates
import { FC } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputBase,
  InputLabel,
  Link,
  Switch,
  Tab,
  Tabs,
  Typography,
  SwitchProps,
  InputAdornment,
  CardContent,
  Card,
  Grid,
  Avatar,
  List,
  ListItem,
  Stack,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
// eslint-disable-next-line import/no-duplicates
import React from 'react';
// import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import {
  CheckBoxOutlined,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  FilterList,
  Download,
  Search,
  ChevronLeft,
  ChevronRight,
  CropFreeOutlined,
  Add,
  PersonOutlineOutlined,
  Refresh,
  OpenInNew,
  Done,
  Close,
} from '@mui/icons-material';
// import { CheckBoxOutlined } from '@material-ui/icons';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import useStyles from './Design.styles';
import { ReactComponent as EuroFile } from '../../assets/icons/euro_file.svg';
import { ReactComponent as ArrowRightBlue } from '../../assets/icons/arrow_right_blue.svg';
import { ReactComponent as ArrowRightWhite } from '../../assets/icons/arrow_right_white.svg';
import { ReactComponent as NoApplication } from '../../assets/icons/nodata.svg';
import { ReactComponent as RadioBlueIcon } from '../../assets/icons/radioBlue.svg';
import { ReactComponent as RadioGrayIcon } from '../../assets/icons/radioGray.svg';
import { ReactComponent as DownloadBlue } from '../../assets/icons/downloadBlue.svg';
import { ReactComponent as TarshBlue } from '../../assets/icons/tarshBlue.svg';
import { ReactComponent as EditBlue } from '../../assets/icons/editBlue.svg';

const Design: FC = () => {
  const classes = useStyles();
  // const label = { inputProps: { 'aria-label': 'Size switch demo' } };
  const MaterialUISwitch = styled(Switch)(() => ({
    width: 22,
    height: 14,
    padding: 0,
    margin: '0 0 0 8px',
    '& .MuiSwitch-switchBase': {
      margin: '0',
      padding: 0,
      transform: 'translateX(0px)',
      '&.Mui-checked': {
        color: '#fff',
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: '#98A2B3',
      width: 13,
      height: 13,
    },
    '& .MuiSwitch-track': {
      width: '100%',
      height: '9px',
      border: '2px solid #98A2B3',
      borderRadius: '100px',
      backgroundColor: 'transparent !important',
      top: '50%',
      position: 'absolute',
      transform: 'translateY(-50%)',
      left: 0,
    },
  }));
  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const CustomInput = styled(InputBase)(() => ({
    // fontSize: '0.875rem',
    // lineHeight: '1.225rem',
    // color: '#667085',
    // 'label + &': {
    //   marginTop: theme.spacing(3),
    // },
    '& .MuiInputBase-input': {},
  }));

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const IOSSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 26,
    height: 18,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 0,
      top: 5,
      left: 5,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(9px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: '#FF867E',
          // backgroundColor: theme.palette.mode === 'dark' ? '#FF867E' : '#65C466',
          opacity: 1,
          border: 0,
        },
      },
      '&.Mui-disabled .MuiSwitch-thumb': {},
      '&.Mui-disabled + .MuiSwitch-track': {},
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 8,
      height: 8,
      top: 2,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.Gray[300],
      opacity: 1,
      transitionDuration: '300ms',
    },
  }));

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'firstName', headerName: 'First name', width: 130 },
    { field: 'lastName', headerName: 'Last name', width: 130 },
    {
      field: 'age',
      headerName: 'Age',
      type: 'number',
      width: 90,
    },
    {
      field: 'fullName',
      headerName: 'Full name',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 160,
      valueGetter: (params: GridValueGetterParams) => `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    },
  ];

  const rows = [
    { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
    { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
    { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
    { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
    { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
    { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
    { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
    { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
    { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
  ];

  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    fontFamily: 'Jost',
    '.MuiDataGrid-row': {
      '&.Mui-selected': {
        backgroundColor: `${theme.palette.Blue[100]} !important`,
      },
    },
    '.MuiDataGrid-cell': {
      outlineColor: 'transparent !important',
    },
    // backgroundColor: theme.palette.primary.main,
    '.MuiDataGrid-columnHeader': {
      '&:focus': {
        outlineColor: 'transparent !important',
      },
      '&Title': {
        color: theme.palette.Gray[900],
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(18),
        fontWeight: 500,
      },
    },
    '.MuiDataGrid-cellContent': {
      color: theme.palette.Gray[900],
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      fontWeight: 400,
    },
    '.MuiDataGrid-withBorderColor': {
      borderColor: theme.palette.Gray[300],
    },
  }));

  return (
    <>
      {/* HEADER */}
      <Box className={classes.site_header}>
        <Box className={classes.site_header_left}>
          <h4 className={classes.site_header_title}>Home</h4>
          <Link href="#!" className={classes.site_header_back_link}>
            <ArrowRightBlue className={classes.site_header_back_icon} />
            Back to home
          </Link>
        </Box>
        <Box className={classes.site_header_right}>
          <Box className={classes.site_header_toggle_btn}>
            <Box className={classes.site_header_toggle_btn_text}>Test Mode</Box>
            {/* <Switch {...label} size="small" /> */}
            <FormControlLabel control={<MaterialUISwitch defaultChecked={false} />} label="" />
          </Box>
        </Box>
      </Box>
      {/* / HEADER */}

      {/* BACK PAGE */}
      <Box className={classes.pageBackWrap}>
        <Link href="#!" className={classes.pageBackLink}>
          <ChevronLeft className={classes.pageBackIcon} />
          Back to applications
        </Link>
      </Box>
      {/* BACK PAGE */}

      {/* CONTENT */}
      <Box className={classes.mainWrapper}>
        {/*  */}

        <Box className="">
          <Typography variant="head_2XL">Hello</Typography>
          <Typography variant="head_XL">Hello</Typography>
          <Typography variant="head_LG">Hello</Typography>
          <Typography variant="head_MD">Hello</Typography>
          <Typography variant="head_SM">Hello</Typography>
          <Typography variant="head_XS">Hello</Typography>

          <Typography variant="text_XL">Hello</Typography>
          <Typography variant="text_LG">Hello</Typography>
          <Typography variant="text_MD">Hello</Typography>
          <Typography variant="text_SM">Hello</Typography>
          <Typography variant="text_XS">Hello</Typography>
        </Box>

        <Box className="" sx={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}>
          <div className={classes.box1} />
          <div className={classes.box2} />
          <div className={classes.box3} />
          <div className={classes.box4} />
          <div className={classes.box5} />
          <div className={classes.box6} />
          <div className={classes.box7} />
        </Box>

        <FormControl variant="standard">
          <InputLabel shrink htmlFor="bootstrap-input">
            Bootstrap
          </InputLabel>
          <CustomInput defaultValue="react-bootstrap" id="bootstrap-input" />
        </FormControl>

        <FormControl variant="standard">
          <InputLabel shrink htmlFor="bootstrap-input">
            Bootstrap
          </InputLabel>
          <CustomInput multiline rows={2} maxRows={4} defaultValue="react-bootstrap" id="bootstrap-input" />
        </FormControl>

        <Button variant="contained" className="">
          Start a new application
        </Button>

        <Button variant="contained" className="white">
          Start a new application
        </Button>

        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Invite team member" />
            <Tab label="Team members" />
            <Tab label="Roles & Permissions" />
          </Tabs>
        </Box>
        <p>BADGE LIST</p>
        <div className="">
          <span className={clsx(classes.badge, classes.accepted)}>Accepted</span>
          <span className={clsx(classes.badge, classes.rejected)}>Rejected</span>
          <span className={clsx(classes.badge, classes.new)}>New</span>
          <span className={clsx(classes.badge, classes.pending)}>Pending</span>
          <span className={clsx(classes.badge, classes.preQualified)}>Pre-qualified</span>
          <span className={clsx(classes.badge, classes.accepted)}>Funded</span>
          <span className={clsx(classes.badge, classes.closed)}>Closed</span>
          <span className={clsx(classes.badge, classes.lenderReview)}>Lender review</span>
          <span className={clsx(classes.badge, classes.awaitingClientInfo)}>Awaiting client info</span>
        </div>

        <Checkbox checkedIcon={<CheckBoxOutlined />} />

        <div className="">
          <Box className={classes.navigateArrowWrap}>
            <Box className={clsx([classes.navigateArrow, classes.navigateArrowLeft, classes.navigateDisabled])}>
              <KeyboardArrowLeft />
            </Box>
            <Box className={clsx([classes.navigateArrow, classes.navigateArrowRight])}>
              <KeyboardArrowRight />
            </Box>
          </Box>
        </div>

        <FormControlLabel control={<IOSSwitch defaultChecked />} label="Disabled" />

        <Box className={classes.inputFileWrapper}>
          <label className={classes.inputFileLabel} htmlFor="filInput">
            <input type="file" name="" id="filInput" />
            <Box className={classes.inputFileContent}>
              <span className={classes.inputFileIcon}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_1698_3041)">
                    <path
                      d="M13.3334 13.3334L10 10M10 10L6.66669 13.3334M10 10V17.5M16.9917 15.325C17.8045 14.8819 18.4466 14.1808 18.8166 13.3322C19.1866 12.4837 19.2635 11.5361 19.0352 10.6389C18.8069 9.74182 18.2863 8.94629 17.5556 8.3779C16.8249 7.80951 15.9257 7.50064 15 7.50003H13.95C13.6978 6.5244 13.2277 5.61864 12.575 4.85085C11.9223 4.08307 11.1041 3.47324 10.1818 3.0672C9.25949 2.66116 8.25715 2.46949 7.25013 2.5066C6.2431 2.5437 5.25758 2.80861 4.36768 3.28142C3.47777 3.75422 2.70662 4.42261 2.11221 5.23635C1.5178 6.05008 1.1156 6.98797 0.935844 7.97952C0.756086 8.97107 0.803449 9.99047 1.07437 10.9611C1.3453 11.9317 1.83273 12.8282 2.50003 13.5834"
                      stroke="#475467"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1698_3041">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
              <Typography component="p" className={classes.inputFileText}>
                Click to upload
                <Typography component="span">or drag and drop</Typography>
              </Typography>
            </Box>
          </label>
          <Box className={classes.uploadedItemList}>
            <Box className={classes.uploadedItem}>
              <span className={classes.docIcon}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M3.41271 0.5H15.8L20.7079 5.40787C20.7083 5.4115 20.7087 5.41544 20.7091 5.41969C20.7121 5.45199 20.7143 5.49774 20.7143 5.562V23.4536C20.6717 23.4843 20.6243 23.5 20.5873 23.5H3.41271C3.37571 23.5 3.32832 23.4843 3.28571 23.4536V0.825429C3.28571 0.635243 3.33454 0.551618 3.35587 0.525628C3.37105 0.507127 3.38398 0.5 3.41271 0.5Z"
                    fill="white"
                    stroke="#CC4B4C"
                  />
                  <path
                    d="M8.36312 14.2817C8.21398 14.2817 8.07084 14.2333 7.94869 14.142C7.50255 13.8073 7.44255 13.4349 7.47084 13.1811C7.54884 12.4834 8.41155 11.7531 10.0358 11.0091C10.6804 9.59657 11.2937 7.85614 11.6593 6.402C11.2316 5.47114 10.8158 4.26343 11.1188 3.555C11.2251 3.30686 11.3576 3.11657 11.6048 3.03429C11.7026 3.00171 11.9494 2.96057 12.0403 2.96057C12.2563 2.96057 12.4461 3.23871 12.5807 3.41014C12.7071 3.57129 12.9938 3.91286 12.4208 6.32529C12.9986 7.51843 13.8171 8.73386 14.6014 9.56614C15.1633 9.46457 15.6467 9.41271 16.0406 9.41271C16.7117 9.41271 17.1184 9.56914 17.2843 9.89143C17.4214 10.158 17.3653 10.4696 17.1171 10.8171C16.8784 11.151 16.5493 11.3276 16.1657 11.3276C15.6446 11.3276 15.0377 10.9984 14.361 10.3483C13.1451 10.6024 11.7253 11.0559 10.5776 11.5577C10.2193 12.318 9.87598 12.9304 9.55627 13.3796C9.11698 13.9946 8.73812 14.2817 8.36312 14.2817ZM9.50398 12.0849C8.58812 12.5996 8.21484 13.0226 8.18784 13.2609C8.18355 13.3003 8.17198 13.404 8.37255 13.5574C8.43641 13.5373 8.80927 13.3671 9.50398 12.0849ZM15.3484 10.1811C15.6977 10.4499 15.783 10.5857 16.0114 10.5857C16.1117 10.5857 16.3976 10.5814 16.53 10.3967C16.5938 10.3071 16.6187 10.2497 16.6286 10.2189C16.5758 10.191 16.506 10.1344 16.125 10.1344C15.9086 10.1349 15.6364 10.1443 15.3484 10.1811ZM12.147 7.36029C11.8406 8.42057 11.436 9.56529 11.001 10.602C11.8967 10.2544 12.8704 9.951 13.785 9.73629C13.2064 9.06429 12.6283 8.22514 12.147 7.36029ZM11.8868 3.73371C11.8448 3.74786 11.3168 4.48671 11.928 5.112C12.3347 4.20557 11.9053 3.72771 11.8868 3.73371Z"
                    fill="#CC4B4C"
                  />
                  <path
                    d="M8.68119 21.7341H8.14285V18.4286H9.09355C9.23396 18.4286 9.37305 18.4509 9.51051 18.4958C9.64796 18.5408 9.77131 18.608 9.88055 18.6976C9.98979 18.7872 10.078 18.8957 10.1453 19.0227C10.2125 19.1497 10.2463 19.2924 10.2463 19.4511C10.2463 19.6188 10.2178 19.7703 10.161 19.9065C10.1043 20.0426 10.0249 20.1571 9.9232 20.2496C9.8215 20.3421 9.69881 20.414 9.55545 20.4648C9.41209 20.5157 9.25331 20.5409 9.0801 20.5409H8.68086V21.7341H8.68119ZM8.68119 18.8367V20.1463H9.17458C9.24019 20.1463 9.30515 20.1351 9.36977 20.1125C9.43407 20.0902 9.49312 20.0534 9.54692 20.0026C9.60072 19.9517 9.64402 19.8809 9.67683 19.7897C9.70964 19.6985 9.72604 19.5856 9.72604 19.4511C9.72604 19.3973 9.71849 19.335 9.70373 19.2651C9.68864 19.1949 9.65813 19.1277 9.61188 19.0634C9.56529 18.9991 9.50034 18.9453 9.41668 18.902C9.33303 18.8587 9.22215 18.837 9.08469 18.837H8.68119V18.8367Z"
                    fill="black"
                  />
                  <path
                    d="M13.5475 19.9895C13.5475 20.2614 13.5183 20.494 13.4599 20.6869C13.4015 20.8798 13.3277 21.0412 13.2378 21.1714C13.1479 21.3017 13.0472 21.404 12.935 21.4788C12.8228 21.5536 12.7145 21.6094 12.6099 21.6471C12.5052 21.6845 12.4095 21.7085 12.3228 21.719C12.2362 21.7288 12.1719 21.7341 12.1299 21.7341H10.8788V18.4286H11.8744C12.1526 18.4286 12.397 18.4729 12.6076 18.5608C12.8182 18.6487 12.9934 18.7665 13.1325 18.9128C13.2716 19.0591 13.3752 19.2261 13.4441 19.4127C13.513 19.5997 13.5475 19.792 13.5475 19.9895ZM11.9508 21.3394C12.3156 21.3394 12.5787 21.2229 12.7401 20.9897C12.9015 20.7565 12.9822 20.4186 12.9822 19.976C12.9822 19.8386 12.9658 19.7024 12.933 19.5679C12.8999 19.4334 12.8366 19.3117 12.7424 19.2025C12.6483 19.0932 12.5203 19.005 12.3589 18.9377C12.1975 18.8705 11.9882 18.8367 11.731 18.8367H11.4171V21.3394H11.9508Z"
                    fill="black"
                  />
                  <path
                    d="M14.875 18.8367V19.8773H16.2564V20.245H14.875V21.7341H14.3278V18.4286H16.3952V18.8367H14.875Z"
                    fill="black"
                  />
                </svg>
              </span>
              <span className={classes.listItemText}>Bank statement/9fbfe60d797-0aa0-4ddf.pdf</span>
              <Box className={classes.listItemAction}>
                <span className={clsx(classes.actionIcon, classes.downloadIcon)}>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_560_42164)">
                      <path
                        d="M11.667 2.5V5.83333C11.667 6.05435 11.7548 6.26631 11.9111 6.42259C12.0674 6.57887 12.2793 6.66667 12.5003 6.66667H15.8337"
                        stroke="#0086C9"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M14.167 17.5H5.83366C5.39163 17.5 4.96771 17.3244 4.65515 17.0118C4.34259 16.6993 4.16699 16.2754 4.16699 15.8333V4.16667C4.16699 3.72464 4.34259 3.30072 4.65515 2.98816C4.96771 2.67559 5.39163 2.5 5.83366 2.5H11.667L15.8337 6.66667V15.8333C15.8337 16.2754 15.6581 16.6993 15.3455 17.0118C15.0329 17.3244 14.609 17.5 14.167 17.5Z"
                        stroke="#0086C9"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M10 14.1667V9.16669"
                        stroke="#0086C9"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M7.91699 12.0833L10.0003 14.1666L12.0837 12.0833"
                        stroke="#0086C9"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_560_42164">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
                <span className={clsx(classes.actionIcon, classes.deleteIcon)}>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_560_42170)">
                      <path
                        d="M3.33301 5.83325H16.6663"
                        stroke="#0086C9"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8.33301 9.16675V14.1667"
                        stroke="#0086C9"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11.667 9.16675V14.1667"
                        stroke="#0086C9"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M4.16699 5.83325L5.00033 15.8333C5.00033 16.2753 5.17592 16.6992 5.48848 17.0118C5.80104 17.3243 6.22496 17.4999 6.66699 17.4999H13.3337C13.7757 17.4999 14.1996 17.3243 14.5122 17.0118C14.8247 16.6992 15.0003 16.2753 15.0003 15.8333L15.8337 5.83325"
                        stroke="#0086C9"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M7.5 5.83333V3.33333C7.5 3.11232 7.5878 2.90036 7.74408 2.74408C7.90036 2.5878 8.11232 2.5 8.33333 2.5H11.6667C11.8877 2.5 12.0996 2.5878 12.2559 2.74408C12.4122 2.90036 12.5 3.11232 12.5 3.33333V5.83333"
                        stroke="#0086C9"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_560_42170">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className={classes.tableActionWrap}>
          <Box className={classes.tableActionButtonWrap}>
            <Button variant="outlined" className="white">
              <FilterList />
              Filters
            </Button>
            <Button variant="outlined" className="white">
              <Download />
              Export
            </Button>
          </Box>
          <Box className={classes.tableActionSearchWrap}>
            <FormControl variant="standard">
              <CustomInput
                rows={2}
                maxRows={4}
                defaultValue="react-bootstrap"
                id="bootstrap-input"
                startAdornment={
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Box>
          <Box className={classes.tableActionPaginationWrap}>
            <Typography component="p" className={classes.tableActionPaginationCount}>
              1-20 of 1,000
            </Typography>
          </Box>
        </Box>

        <Box className={classes.selectedFilter}>
          <ul className={classes.selectedFilterList}>
            <li className={classes.selectedFilterItem}>
              <Typography variant="text_XS" component="span" className={classes.selectedFilterItemText}>
                Lorem
              </Typography>
              <span className={classes.selectedFilterRemoveIcon}>
                <Close />
              </span>
            </li>
          </ul>
        </Box>

        <Box className={classes.home_page_content}>
          <Box className={classes.home_page_titleWrap}>
            <h1 className={classes.home_page_titleName}>Welcome back, Donald</h1>
          </Box>
          <Box className={classes.app_list_wrap}>
            <Box className={classes.app_list_inner}>
              <Box className={classes.app_list_item}>
                <Box className={classes.app_list_item_text}>
                  <Box className={classes.app_list_item_icon}>
                    <EuroFile />
                  </Box>
                  <span className={classes.app_list_item_desc}>Start a new application</span>
                </Box>
                <Box className={classes.app_list_item_link}>
                  <Link href="#!" className={classes.app_list_item_link_item}>
                    Start application <ArrowRightBlue className={classes.app_list_item_link_icon} />
                  </Link>
                </Box>
              </Box>
              <Box className={classes.app_list_item}>
                <Box className={classes.app_list_item_text}>
                  <Box className={classes.app_list_item_icon}>
                    <EuroFile />
                  </Box>
                  <span className={classes.app_list_item_desc}>Start a new application</span>
                </Box>
                <Box className={classes.app_list_item_link}>
                  <Link href="#!" className={classes.app_list_item_link_item}>
                    Start application <ArrowRightBlue className={classes.app_list_item_link_icon} />
                  </Link>
                </Box>
              </Box>
              <Box className={classes.app_list_item}>
                <Box className={classes.app_list_item_text}>
                  <Box className={classes.app_list_item_icon}>
                    <EuroFile />
                  </Box>
                  <span className={classes.app_list_item_desc}>Start a new application</span>
                </Box>
                <Box className={classes.app_list_item_link}>
                  <Link href="#!" className={classes.app_list_item_link_item}>
                    Start application <ArrowRightBlue className={classes.app_list_item_link_icon} />
                  </Link>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className={classes.box_title_wrap}>
            <Box className={classes.app_list_wrap}>
              <h3 className={classes.box_title}>New Application</h3>
            </Box>
          </Box>
          <Box className={classes.app_list_wrap}>
            {/*  */}
            <Box className={classes.no_data_wrap}>
              <NoApplication />
              <p className={classes.no_data_text}>No applications yet</p>
              <Button variant="contained" className={classes.cmn_btn}>
                Start a new application
                <ArrowRightWhite className={classes.cmn_btn_icon} />
              </Button>
            </Box>
            {/*  */}
            <Box className={classes.app_list_inner}>
              <Box className={classes.app_list_item}>
                <Box className={classes.app_list_item_text_wrap}>
                  <h4 className={classes.app_list_item_title}>Bankkit</h4>
                  <span className={classes.app_list_item_subtitle}>9/12/2022, 09:22 am</span>
                </Box>
                <Box className={classes.app_list_item_link}>
                  <span className={classes.app_list_item_cost}>£1,102,803.00</span>
                </Box>
              </Box>
              <Box className={classes.app_list_item}>
                <Box className={classes.app_list_item_text_wrap}>
                  <h4 className={classes.app_list_item_title}>Bankkit</h4>
                  <span className={classes.app_list_item_subtitle}>9/12/2022, 09:22 am</span>
                </Box>
                <Box className={classes.app_list_item_link}>
                  <span className={classes.app_list_item_cost}>£1,102,803.00</span>
                </Box>
              </Box>
              <Box className={classes.app_list_item}>
                <Box className={classes.app_list_item_text_wrap}>
                  <h4 className={classes.app_list_item_title}>Bankkit</h4>
                  <span className={classes.app_list_item_subtitle}>9/12/2022, 09:22 am</span>
                </Box>
                <Box className={classes.app_list_item_link}>
                  <span className={classes.app_list_item_cost}>£1,102,803.00</span>
                </Box>
              </Box>
              <Box className={classes.app_list_item}>
                <Box className={classes.app_list_item_text_wrap}>
                  <h4 className={classes.app_list_item_title}>Bankkit</h4>
                  <span className={classes.app_list_item_subtitle}>9/12/2022, 09:22 am</span>
                </Box>
                <Box className={classes.app_list_item_link}>
                  <span className={classes.app_list_item_cost}>£1,102,803.00</span>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* / CONTENT */}

      {/* CARD */}
      <Box className={classes.mainWrapper}>
        <Grid container spacing={4}>
          <Grid item xs={3}>
            <Card className={classes.dashboardPaletteCard}>
              <CardContent className={classes.dashboardPalette}>
                <Box className={classes.dashboardPaletteIconText}>
                  <Box className={classes.dashboardPaletteIcon}>
                    <CropFreeOutlined />
                  </Box>
                  <Box className={classes.dashboardPaletteTextWrap}>
                    <Typography className={classes.dashboardPaletteDesc} component="p">
                      Application offers
                    </Typography>
                    <Typography className={classes.dashboardPaletteDescCount} component="span">
                      5
                    </Typography>
                  </Box>
                </Box>
                <Box className={classes.dashboardPaletteLink}>
                  <Link href="#!" className={classes.dashboardPaletteLinkItem}>
                    Create an offer
                    <ChevronRight className={classes.dashboardPaletteLinkIcon} />
                  </Link>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card className={classes.dashboardPaletteCard}>
              <CardContent className={classes.dashboardPalette}>
                <Box className={classes.dashboardPaletteIconText}>
                  <Box className={classes.dashboardPaletteIcon}>
                    <CropFreeOutlined />
                  </Box>
                  <Box className={classes.dashboardPaletteTextWrap}>
                    <Typography className={classes.dashboardPaletteDesc} component="p">
                      Application offers
                    </Typography>
                    <Typography className={classes.dashboardPaletteDescCount} component="span">
                      5
                    </Typography>
                  </Box>
                </Box>
                <Box className={classes.dashboardPaletteLink}>
                  <Link href="#!" className={classes.dashboardPaletteLinkItem}>
                    Create an offer
                    <ChevronRight className={classes.dashboardPaletteLinkIcon} />
                  </Link>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card className={classes.dashboardPaletteCard}>
              <CardContent className={classes.dashboardPalette}>
                <Box className={classes.dashboardPaletteIconText}>
                  <Box className={classes.dashboardPaletteIcon}>
                    <CropFreeOutlined />
                  </Box>
                  <Box className={classes.dashboardPaletteTextWrap}>
                    <Typography className={classes.dashboardPaletteDesc} component="p">
                      Application offers
                    </Typography>
                    <Typography className={classes.dashboardPaletteDescCount} component="span">
                      5
                    </Typography>
                  </Box>
                </Box>
                <Box className={classes.dashboardPaletteLink}>
                  <Link href="#!" className={classes.dashboardPaletteLinkItem}>
                    Create an offer
                    <ChevronRight className={classes.dashboardPaletteLinkIcon} />
                  </Link>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card className={classes.dashboardPaletteCard}>
              <CardContent className={classes.dashboardPalette}>
                <Box className={classes.dashboardPaletteIconText}>
                  <Box className={classes.dashboardPaletteIcon}>
                    <CropFreeOutlined />
                  </Box>
                  <Box className={classes.dashboardPaletteTextWrap}>
                    <Typography className={classes.dashboardPaletteDesc} component="p">
                      Application offers
                    </Typography>
                    <Typography className={classes.dashboardPaletteDescCount} component="span">
                      5
                    </Typography>
                  </Box>
                </Box>
                <Box className={classes.dashboardPaletteLink}>
                  <Link href="#!" className={classes.dashboardPaletteLinkItem}>
                    Create an offer
                    <ChevronRight className={classes.dashboardPaletteLinkIcon} />
                  </Link>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card className={classes.dashboardPaletteCard}>
              <CardContent className={classes.dashboardPalette}>
                <Box className={classes.dashboardPaletteIconText}>
                  <Box className={classes.dashboardPaletteIcon}>
                    <CropFreeOutlined />
                  </Box>
                  <Box className={classes.dashboardPaletteTextWrap}>
                    <Typography className={classes.dashboardPaletteDesc} component="p">
                      Application offers
                    </Typography>
                    <Typography className={classes.dashboardPaletteDescCount} component="span">
                      5
                    </Typography>
                  </Box>
                </Box>
                <Box className={classes.dashboardPaletteLink}>
                  <Link href="#!" className={classes.dashboardPaletteLinkItem}>
                    Create an offer
                    <ChevronRight className={classes.dashboardPaletteLinkIcon} />
                  </Link>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card className={classes.dashboardPaletteCard}>
              <CardContent className={classes.dashboardPalette}>
                <Box className={classes.dashboardPaletteIconText}>
                  <Box className={classes.dashboardPaletteIcon}>
                    <CropFreeOutlined />
                  </Box>
                  <Box className={classes.dashboardPaletteTextWrap}>
                    <Typography className={classes.dashboardPaletteDesc} component="p">
                      Application offers
                    </Typography>
                    <Typography className={classes.dashboardPaletteDescCount} component="span">
                      5
                    </Typography>
                  </Box>
                </Box>
                <Box className={classes.dashboardPaletteLink}>
                  <Link href="#!" className={classes.dashboardPaletteLinkItem}>
                    Create an offer
                    <ChevronRight className={classes.dashboardPaletteLinkIcon} />
                  </Link>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card className={classes.dashboardPaletteCard}>
              <CardContent className={classes.dashboardPalette}>
                <Box className={classes.dashboardPaletteIconText}>
                  <Box className={classes.dashboardPaletteIcon}>
                    <CropFreeOutlined />
                  </Box>
                  <Box className={classes.dashboardPaletteTextWrap}>
                    <Typography className={classes.dashboardPaletteDesc} component="p">
                      Application offers
                    </Typography>
                    <Typography className={classes.dashboardPaletteDescCount} component="span">
                      5
                    </Typography>
                  </Box>
                </Box>
                <Box className={classes.dashboardPaletteLink}>
                  <Link href="#!" className={classes.dashboardPaletteLinkItem}>
                    Create an offer
                    <ChevronRight className={classes.dashboardPaletteLinkIcon} />
                  </Link>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>

      {/* HOME PAGE CODE START HERE */}
      <Box className={classes.mainWrapper} sx={{ marginTop: '30px' }}>
        <Box className={clsx([classes.welcomeStripWRap, classes.cardShadow])}>
          <Grid container spacing={4} sx={{ alignItems: 'center' }}>
            <Grid item xs={3.5}>
              <Box className={classes.welcomeStripTitleWrap}>
                <Typography component="p" className={classes.welcomeStriptitle}>
                  Welcome, Donald
                </Typography>
                <Link href="#!" className={clsx([classes.welcomeStriptitleItem, classes.commonLinkStyle])}>
                  Review business loan applications
                  <ChevronRight className={clsx([classes.welcomeStriptitleIcon, classes.commonLinkIconStyle])} />
                </Link>
              </Box>
            </Grid>
            <Grid item xs={2.8}>
              <Box className={classes.welcomeStripIconText}>
                <Box className={classes.welcomeStripIcon}>
                  <CropFreeOutlined />
                </Box>
                <Box className={classes.welcomeStripTextWrap}>
                  <Typography className={classes.welcomeStripDescCount} component="span">
                    5
                  </Typography>
                  <Typography className={classes.welcomeStripDesc} component="p">
                    Loan applications
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={2.8}>
              <Box className={classes.welcomeStripIconText}>
                <Box className={classes.welcomeStripIcon}>
                  <CropFreeOutlined />
                </Box>
                <Box className={classes.welcomeStripTextWrap}>
                  <Typography className={classes.welcomeStripDescCount} component="span">
                    5
                  </Typography>
                  <Typography className={classes.welcomeStripDesc} component="p">
                    Lenders
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={2.8}>
              <Box className={classes.welcomeStripIconText}>
                <Box className={classes.welcomeStripIcon}>
                  <CropFreeOutlined />
                </Box>
                <Box className={classes.welcomeStripTextWrap}>
                  <Typography className={classes.welcomeStripDescCount} component="span">
                    5
                  </Typography>
                  <Typography className={classes.welcomeStripDesc} component="p">
                    Partners
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ marginTop: '30px' }}>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={6} xl={3}>
              <Box className={clsx([classes.c_card, classes.cardShadow, classes.teamCard])}>
                <Box className={clsx([classes.c_cardTitle])}>
                  <Typography className={clsx([classes.c_cardTitleText])} variant="text_MD" component="div">
                    Your team
                  </Typography>
                </Box>
                <Box className={clsx([classes.c_cardBody])}>
                  <Box className={classes.profileListWrapper}>
                    <Avatar
                      className={classes.profileListItem}
                      sx={{ bgcolor: '#EFF8FF', color: '#0065AB' }}
                      variant="rounded"
                    >
                      <PersonOutlineOutlined />
                    </Avatar>
                    <Avatar className={classes.profileListItem} sx={{ bgcolor: '#5243AA' }}>
                      OR
                    </Avatar>
                    <Avatar className={classes.profileListItem} sx={{ bgcolor: '#603415' }}>
                      IO
                    </Avatar>
                    <Avatar className={classes.profileListItem} sx={{ bgcolor: '#9E1AA9' }}>
                      DL
                    </Avatar>
                  </Box>
                  <Button variant="outlined" className="blue">
                    <Add /> Add team member
                  </Button>
                </Box>
              </Box>
              <Box className={clsx([classes.c_card, classes.cardShadow, classes.NewApplicationCard])}>
                <Box className={clsx([classes.c_cardTitle])}>
                  <Typography className={clsx([classes.c_cardTitleText])} variant="text_MD" component="div">
                    New Applications
                  </Typography>
                </Box>
                <Box className={clsx([classes.c_cardBody])}>
                  <Box className={clsx([classes.NewApplicationList, classes.NewApplicationListEmpty])}>
                    {/* EMPTY CONTENT */}
                    <Box className={clsx([classes.emptyDataWrap])}>
                      <Box className={clsx([classes.emptyDataIamge])}>
                        <img
                          src="/images/no-data.png"
                          srcSet="/images/no-data.png&dpr=2 2x"
                          alt="no-data.png"
                          loading="lazy"
                          width={90.69}
                          height={90.69}
                        />
                      </Box>
                      <Typography className={clsx([classes.emptyDataText])} variant="body1" component="p">
                        No new application
                      </Typography>
                    </Box>
                    {/* EMPTY CONTENT */}
                    {/* CONTENT */}
                    {/* <Box className={clsx([classes.NewApplicationItem])}>
                      <Typography className={classes.NewApplicationlabel} component="label" variant="text_XS">
                        NEW
                      </Typography>
                      <Typography className={classes.NewApplicationTitle} component="p" variant="text_SM">
                        Bankkit
                      </Typography>
                      <Typography className={classes.NewApplicationAmount} component="p" variant="text_MD">
                        $1,102,803.00
                      </Typography>
                      <Typography className={classes.NewApplicationTime} component="p" variant="text_XS">
                        Time stamp:&nbsp;9/12/2022, 09:22 am
                      </Typography>
                    </Box>
                    <Box className={clsx([classes.NewApplicationItem])}>
                      <Typography className={classes.NewApplicationlabel} component="label" variant="text_XS">
                        NEW
                      </Typography>
                      <Typography className={classes.NewApplicationTitle} component="p" variant="text_SM">
                        Bankkit
                      </Typography>
                      <Typography className={classes.NewApplicationAmount} component="p" variant="text_MD">
                        $1,102,803.00
                      </Typography>
                      <Typography className={classes.NewApplicationTime} component="p" variant="text_XS">
                        Time stamp:&nbsp;9/12/2022, 09:22 am
                      </Typography>
                    </Box> */}
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} lg={6} xl={9}>
              <Box className={clsx([classes.cardShadow, classes.cardShadow, classes.statusOverviewCard])}>
                <Box className={clsx([classes.c_cardTitle])}>
                  <Typography className={clsx([classes.c_cardTitleText])} variant="text_MD" component="div">
                    Status overview
                  </Typography>
                </Box>
                <Box className={clsx([classes.c_cardBody])}>
                  <Box className={clsx([classes.emptyDataWrap])}>
                    <Box className={clsx([classes.emptyDataIamge])}>
                      <img
                        src="/images/no-data.png"
                        srcSet="/images/no-data.png&dpr=2 2x"
                        alt="no-data.png"
                        loading="lazy"
                        width={90.69}
                        height={90.69}
                      />
                    </Box>
                    <Typography className={clsx([classes.emptyDataText])} variant="body1" component="p">
                      No new application
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ marginTop: '30px' }}>
          <Box>
            <StyledDataGrid
              rows={rows}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
              pageSizeOptions={[5, 10]}
              checkboxSelection={false}
            />
          </Box>
        </Box>
      </Box>

      <Box className={classes.mainWrapper}>
        <Box className={clsx([classes.c_card, classes.cardShadow])} sx={{ marginTop: '30px' }}>
          <Box className={clsx([classes.c_cardTitle, classes.filter_cardTitle])}>
            <Typography variant="text_LG" component="h3">
              Filters
            </Typography>
            <span className={classes.closeFilterIcon}>
              <Close />
            </span>
          </Box>
          <Box className={clsx([classes.filter_cardBody])}>
            <Box className={clsx([classes.filter_tabingBox])}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" scrollButtons="auto">
                <Tab label="App status" />
                <Tab label="Uploaded documents" />
                <Tab label="Online sales" />
                <Tab label="Homeowner" />
              </Tabs>
            </Box>
            <Box className={clsx([classes.filter_checkBoxGrp])}>
              <FormControlLabel
                label={
                  <Typography variant="text_SM" component="div">
                    Pending apps
                  </Typography>
                }
                control={<Checkbox checkedIcon={<CheckBoxOutlined />} />}
              />
              <FormControlLabel
                label={
                  <Typography variant="text_SM" component="div">
                    Pending apps
                  </Typography>
                }
                control={<Checkbox checkedIcon={<CheckBoxOutlined />} />}
              />
              <FormControlLabel
                label={
                  <Typography variant="text_SM" component="div">
                    Not funded apps
                  </Typography>
                }
                control={<Checkbox checkedIcon={<CheckBoxOutlined />} />}
              />
            </Box>
          </Box>
          <Box className={clsx([classes.filter_cardFooter])}>
            <Button variant="contained" className="blue" sx={{ width: '100%' }}>
              Apply filter
            </Button>
          </Box>
        </Box>

        <Box className={clsx([classes.c_card, classes.cardShadow])} sx={{ marginTop: '30px' }}>
          <Box className={clsx([classes.filter_cardBody])}>
            <Box className={clsx([classes.filter_tabingBox])}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" scrollButtons="auto">
                <Tab label="Application offers" {...a11yProps(0)} />
                <Tab label="Application details" {...a11yProps(1)} />
                <Tab label="Action lead" {...a11yProps(2)} />
                <Tab label="Company House profile" {...a11yProps(3)} />
                <Tab label="Company House profile" {...a11yProps(4)} />
                <Tab label="Client emails" {...a11yProps(5)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <Box className={classes.headingStripIconText}>
                <Box className={classes.headingStripIcon}>
                  <CropFreeOutlined />
                </Box>
                <Box className={classes.headingStripTextWrap}>
                  <Typography variant="text_MD" component="h3" className={classes.fontJost}>
                    All information relating to Bankkit applications
                  </Typography>
                </Box>
              </Box>
              <Box className={clsx([classes.tabInfoWrap, classes.tabGap_160])}>
                <Box className={clsx([classes.tabCol, classes.tabColLine])}>
                  <Box className={clsx([classes.flex, classes.flexCol, classes.gap_24])}>
                    <Box className={classes.headingSubStripIconText}>
                      <Box className={classes.headingSubStripIcon}>
                        <CropFreeOutlined className={classes.icon18} />
                      </Box>
                      <Box className={classes.headingSubStripTextWrap}>
                        <Typography variant="text_SM" component="h4" className={classes.fontJost}>
                          Applicant information
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <List className={clsx([classes.listKeyValueWrap])}>
                        <ListItem className={clsx([classes.listKeyValueItem])}>
                          <Box className={clsx([classes.listKeyValueBox])}>
                            <Box className={clsx([classes.listKeyValueBox_key])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                First name
                              </Typography>
                            </Box>
                            <Box className={clsx([classes.listKeyValueBox_value])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                Nithin
                              </Typography>
                            </Box>
                          </Box>
                        </ListItem>
                        <ListItem className={clsx([classes.listKeyValueItem])}>
                          <Box className={clsx([classes.listKeyValueBox])}>
                            <Box className={clsx([classes.listKeyValueBox_key])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                Last name
                              </Typography>
                            </Box>
                            <Box className={clsx([classes.listKeyValueBox_value])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                B
                              </Typography>
                            </Box>
                          </Box>
                        </ListItem>
                        <ListItem className={clsx([classes.listKeyValueItem])}>
                          <Box className={clsx([classes.listKeyValueBox])}>
                            <Box className={clsx([classes.listKeyValueBox_key])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                Email address
                              </Typography>
                            </Box>
                            <Box className={clsx([classes.listKeyValueBox_value])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                nithu321.nb@gmail.com
                              </Typography>
                            </Box>
                          </Box>
                        </ListItem>
                        <ListItem className={clsx([classes.listKeyValueItem])}>
                          <Box className={clsx([classes.listKeyValueBox])}>
                            <Box className={clsx([classes.listKeyValueBox_key])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                Mobile number
                              </Typography>
                            </Box>
                            <Box className={clsx([classes.listKeyValueBox_value])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                nithu321.nb@gmail.com
                              </Typography>
                            </Box>
                          </Box>
                        </ListItem>
                        <ListItem className={clsx([classes.listKeyValueItem])}>
                          <Box className={clsx([classes.listKeyValueBox])}>
                            <Box className={clsx([classes.listKeyValueBox_key])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                Date of birth
                              </Typography>
                            </Box>
                            <Box className={clsx([classes.listKeyValueBox_value])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                20/11/1998
                              </Typography>
                            </Box>
                          </Box>
                        </ListItem>
                        <ListItem className={clsx([classes.listKeyValueItem])}>
                          <Box className={clsx([classes.listKeyValueBox])}>
                            <Box className={clsx([classes.listKeyValueBox_key])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                Country
                              </Typography>
                            </Box>
                            <Box className={clsx([classes.listKeyValueBox_value])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                United Kingdom
                              </Typography>
                            </Box>
                          </Box>
                        </ListItem>
                        <ListItem className={clsx([classes.listKeyValueItem])}>
                          <Box className={clsx([classes.listKeyValueBox])}>
                            <Box className={clsx([classes.listKeyValueBox_key])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                Is a homeowner?
                              </Typography>
                            </Box>
                            <Box className={clsx([classes.listKeyValueBox_value])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                No
                              </Typography>
                            </Box>
                          </Box>
                        </ListItem>
                        <ListItem className={clsx([classes.listKeyValueItem])}>
                          <Box className={clsx([classes.listKeyValueBox])}>
                            <Box className={clsx([classes.listKeyValueBox_key])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                Address line 1
                              </Typography>
                            </Box>
                            <Box className={clsx([classes.listKeyValueBox_value])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                Prime Minister & First Lord of the Treasury, 10 Downing Street
                              </Typography>
                            </Box>
                          </Box>
                        </ListItem>
                        <ListItem className={clsx([classes.listKeyValueItem])}>
                          <Box className={clsx([classes.listKeyValueBox])}>
                            <Box className={clsx([classes.listKeyValueBox_key])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                City
                              </Typography>
                            </Box>
                            <Box className={clsx([classes.listKeyValueBox_value])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                London
                              </Typography>
                            </Box>
                          </Box>
                        </ListItem>
                        <ListItem className={clsx([classes.listKeyValueItem])}>
                          <Box className={clsx([classes.listKeyValueBox])}>
                            <Box className={clsx([classes.listKeyValueBox_key])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                Postcode
                              </Typography>
                            </Box>
                            <Box className={clsx([classes.listKeyValueBox_value])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                sw1a2aa
                              </Typography>
                            </Box>
                          </Box>
                        </ListItem>
                        <ListItem className={clsx([classes.listKeyValueItem])}>
                          <Box className={clsx([classes.listKeyValueBox])}>
                            <Box className={clsx([classes.listKeyValueBox_key])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                County
                              </Typography>
                            </Box>
                            <Box className={clsx([classes.listKeyValueBox_value])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                London
                              </Typography>
                            </Box>
                          </Box>
                        </ListItem>
                        <ListItem className={clsx([classes.listKeyValueItem])}>
                          <Box className={clsx([classes.listKeyValueBox])}>
                            <Box className={clsx([classes.listKeyValueBox_key])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                Region
                              </Typography>
                            </Box>
                            <Box className={clsx([classes.listKeyValueBox_value])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                UK
                              </Typography>
                            </Box>
                          </Box>
                        </ListItem>
                        <ListItem className={clsx([classes.listKeyValueItem])}>
                          <Box className={clsx([classes.listKeyValueBox])}>
                            <Box className={clsx([classes.listKeyValueBox_key])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                Country (Code)
                              </Typography>
                            </Box>
                            <Box className={clsx([classes.listKeyValueBox_value])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                (UK)
                              </Typography>
                            </Box>
                          </Box>
                        </ListItem>
                        <ListItem className={clsx([classes.listKeyValueItem])}>
                          <Box className={clsx([classes.listKeyValueBox])}>
                            <Box className={clsx([classes.listKeyValueBox_key])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                Current address?
                              </Typography>
                            </Box>
                            <Box className={clsx([classes.listKeyValueBox_value])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                Yes
                              </Typography>
                            </Box>
                          </Box>
                        </ListItem>
                        <ListItem className={clsx([classes.listKeyValueItem])}>
                          <Box className={clsx([classes.listKeyValueBox])}>
                            <Box className={clsx([classes.listKeyValueBox_key])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                To
                              </Typography>
                            </Box>
                            <Box className={clsx([classes.listKeyValueBox_value])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                Present
                              </Typography>
                            </Box>
                          </Box>
                        </ListItem>
                      </List>
                    </Box>
                  </Box>
                  <Box className={clsx([classes.flex, classes.flexCol, classes.gap_24])}>
                    <Box className={classes.headingSubStripIconText}>
                      <Box className={classes.headingSubStripIcon}>
                        <CropFreeOutlined className={classes.icon18} />
                      </Box>
                      <Box className={classes.headingSubStripTextWrap}>
                        <Typography variant="text_SM" component="h4" className={classes.fontJost}>
                          Financial information
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <List className={clsx([classes.listKeyValueWrap])}>
                        <ListItem className={clsx([classes.listKeyValueItem])}>
                          <Box className={clsx([classes.listKeyValueBox])}>
                            <Box className={clsx([classes.listKeyValueBox_key])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                Average revenue per month
                              </Typography>
                            </Box>
                            <Box className={clsx([classes.listKeyValueBox_value])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                £12,000.00
                              </Typography>
                            </Box>
                          </Box>
                        </ListItem>
                        <ListItem className={clsx([classes.listKeyValueItem])}>
                          <Box className={clsx([classes.listKeyValueBox])}>
                            <Box className={clsx([classes.listKeyValueBox_key])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                Annual revenue for the last 12 months
                              </Typography>
                            </Box>
                            <Box className={clsx([classes.listKeyValueBox_value])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                £144,000.00
                              </Typography>
                            </Box>
                          </Box>
                        </ListItem>
                      </List>
                    </Box>
                  </Box>
                </Box>
                <Box className={classes.tabCol}>
                  <Box className={clsx([classes.flex, classes.flexCol, classes.gap_24])}>
                    <Box className={classes.headingSubStripIconText}>
                      <Box className={classes.headingSubStripIcon}>
                        <CropFreeOutlined className={classes.icon18} />
                      </Box>
                      <Box className={classes.headingSubStripTextWrap}>
                        <Typography variant="text_SM" component="h4" className={classes.fontJost}>
                          Company information
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <List className={clsx([classes.listKeyValueWrap])}>
                        <ListItem className={clsx([classes.listKeyValueItem])}>
                          <Box className={clsx([classes.listKeyValueBox])}>
                            <Box className={clsx([classes.listKeyValueBox_key])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                Registered Name
                              </Typography>
                            </Box>
                            <Box className={clsx([classes.listKeyValueBox_value])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                Bankkit
                              </Typography>
                            </Box>
                          </Box>
                        </ListItem>
                        <ListItem className={clsx([classes.listKeyValueItem])}>
                          <Box className={clsx([classes.listKeyValueBox])}>
                            <Box className={clsx([classes.listKeyValueBox_key])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                Registration number
                              </Typography>
                            </Box>
                            <Box className={clsx([classes.listKeyValueBox_value])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                09521644
                              </Typography>
                            </Box>
                          </Box>
                        </ListItem>
                        <ListItem className={clsx([classes.listKeyValueItem])}>
                          <Box className={clsx([classes.listKeyValueBox])}>
                            <Box className={clsx([classes.listKeyValueBox_key])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                Type
                              </Typography>
                            </Box>
                            <Box className={clsx([classes.listKeyValueBox_value])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                Private limited with Share Capital
                              </Typography>
                            </Box>
                          </Box>
                        </ListItem>
                        <ListItem className={clsx([classes.listKeyValueItem])}>
                          <Box className={clsx([classes.listKeyValueBox])}>
                            <Box className={clsx([classes.listKeyValueBox_key])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                Region
                              </Typography>
                            </Box>
                            <Box className={clsx([classes.listKeyValueBox_value])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                GB
                              </Typography>
                            </Box>
                          </Box>
                        </ListItem>
                        <ListItem className={clsx([classes.listKeyValueItem])}>
                          <Box className={clsx([classes.listKeyValueBox])}>
                            <Box className={clsx([classes.listKeyValueBox_key])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                Incorporated On
                              </Typography>
                            </Box>
                            <Box className={clsx([classes.listKeyValueBox_value])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                26/11/1998
                              </Typography>
                            </Box>
                          </Box>
                        </ListItem>
                        <ListItem className={clsx([classes.listKeyValueItem])}>
                          <Box className={clsx([classes.listKeyValueBox])}>
                            <Box className={clsx([classes.listKeyValueBox_key])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                Business age
                              </Typography>
                            </Box>
                            <Box className={clsx([classes.listKeyValueBox_value])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                288 months
                              </Typography>
                            </Box>
                          </Box>
                        </ListItem>
                        <ListItem className={clsx([classes.listKeyValueItem])}>
                          <Box className={clsx([classes.listKeyValueBox])}>
                            <Box className={clsx([classes.listKeyValueBox_key])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                Trading since
                              </Typography>
                            </Box>
                            <Box className={clsx([classes.listKeyValueBox_value])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                27/11/1998
                              </Typography>
                            </Box>
                          </Box>
                        </ListItem>
                        <ListItem className={clsx([classes.listKeyValueItem])}>
                          <Box className={clsx([classes.listKeyValueBox])}>
                            <Box className={clsx([classes.listKeyValueBox_key])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                Vat registered
                              </Typography>
                            </Box>
                            <Box className={clsx([classes.listKeyValueBox_value])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                No
                              </Typography>
                            </Box>
                          </Box>
                        </ListItem>
                        <ListItem className={clsx([classes.listKeyValueItem])}>
                          <Box className={clsx([classes.listKeyValueBox])}>
                            <Box className={clsx([classes.listKeyValueBox_key])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                Address line 1
                              </Typography>
                            </Box>
                            <Box className={clsx([classes.listKeyValueBox_value])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                Silverstone Innovation Centre, Silverstone Circuit, Towecster, NN12 8GX
                              </Typography>
                            </Box>
                          </Box>
                        </ListItem>
                        <ListItem className={clsx([classes.listKeyValueItem])}>
                          <Box className={clsx([classes.listKeyValueBox])}>
                            <Box className={clsx([classes.listKeyValueBox_key])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                Country (Code)
                              </Typography>
                            </Box>
                            <Box className={clsx([classes.listKeyValueBox_value])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                United Kingdom (GB)
                              </Typography>
                            </Box>
                          </Box>
                        </ListItem>
                        <ListItem className={clsx([classes.listKeyValueItem])}>
                          <Box className={clsx([classes.listKeyValueBox])}>
                            <Box className={clsx([classes.listKeyValueBox_key])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                Current
                              </Typography>
                            </Box>
                            <Box className={clsx([classes.listKeyValueBox_value])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                No
                              </Typography>
                            </Box>
                          </Box>
                        </ListItem>
                        <ListItem className={clsx([classes.listKeyValueItem])}>
                          <Box className={clsx([classes.listKeyValueBox])}>
                            <Box className={clsx([classes.listKeyValueBox_key])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                CCJS active
                              </Typography>
                            </Box>
                            <Box className={clsx([classes.listKeyValueBox_value])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                test
                              </Typography>
                            </Box>
                          </Box>
                        </ListItem>
                        <ListItem className={clsx([classes.listKeyValueItem])}>
                          <Box className={clsx([classes.listKeyValueBox])}>
                            <Box className={clsx([classes.listKeyValueBox_key])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                CCJS historic
                              </Typography>
                            </Box>
                            <Box className={clsx([classes.listKeyValueBox_value])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                test
                              </Typography>
                            </Box>
                          </Box>
                        </ListItem>
                      </List>
                    </Box>
                  </Box>
                  <Box className={clsx([classes.flex, classes.flexCol, classes.gap_24])}>
                    <Box className={classes.headingSubStripIconText}>
                      <Box className={classes.headingSubStripIcon}>
                        <CropFreeOutlined className={classes.icon18} />
                      </Box>
                      <Box className={classes.headingSubStripTextWrap}>
                        <Typography variant="text_SM" component="h4" className={classes.fontJost}>
                          Funding request information
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <List className={clsx([classes.listKeyValueWrap])}>
                        <ListItem className={clsx([classes.listKeyValueItem])}>
                          <Box className={clsx([classes.listKeyValueBox])}>
                            <Box className={clsx([classes.listKeyValueBox_key])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                Requested loan amount
                              </Typography>
                            </Box>
                            <Box className={clsx([classes.listKeyValueBox_value])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                £1,234,560.00
                              </Typography>
                            </Box>
                          </Box>
                        </ListItem>
                        <ListItem className={clsx([classes.listKeyValueItem])}>
                          <Box className={clsx([classes.listKeyValueBox])}>
                            <Box className={clsx([classes.listKeyValueBox_key])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                Funding reasons
                              </Typography>
                            </Box>
                            <Box className={clsx([classes.listKeyValueBox_value])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                Loan refinance
                              </Typography>
                            </Box>
                          </Box>
                        </ListItem>
                        <ListItem className={clsx([classes.listKeyValueItem])}>
                          <Box className={clsx([classes.listKeyValueBox])}>
                            <Box className={clsx([classes.listKeyValueBox_key])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                Credit limit
                              </Typography>
                            </Box>
                            <Box className={clsx([classes.listKeyValueBox_value])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                £18,000.00
                              </Typography>
                            </Box>
                          </Box>
                        </ListItem>
                        <ListItem className={clsx([classes.listKeyValueItem])}>
                          <Box className={clsx([classes.listKeyValueBox])}>
                            <Box className={clsx([classes.listKeyValueBox_key])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                Credit score consent
                              </Typography>
                            </Box>
                            <Box className={clsx([classes.listKeyValueBox_value])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                01/11/2022
                              </Typography>
                            </Box>
                          </Box>
                        </ListItem>
                      </List>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Box className={classes.headingStripIconText}>
                <Box className={classes.headingStripIcon}>
                  <CropFreeOutlined />
                </Box>
                <Box className={classes.headingStripTextWrap}>
                  <Typography variant="text_MD" component="h3" className={classes.fontJost}>
                    Bankkit’s Company House Profile
                  </Typography>
                </Box>
              </Box>
              <Box className={clsx([classes.tabInfoWrap, classes.tabGap_160])}>
                <Box className={clsx([classes.tabCol])}>
                  <Box className={clsx([classes.flex, classes.flexCol, classes.gap_24])}>
                    <Box>
                      <Box className={clsx([classes.listKeyValueBox, classes.py_16])}>
                        <Box className={clsx([classes.listKeyValueBox_key])}>
                          <Typography
                            variant="text_SM"
                            component="p"
                            className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                          >
                            Type
                          </Typography>
                        </Box>
                        <Box className={clsx([classes.listKeyValueBox_value])}>
                          <Typography
                            variant="text_SM"
                            component="p"
                            className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                          >
                            Ltd
                          </Typography>
                        </Box>
                      </Box>
                      <List className={clsx([classes.listKeyValueTitleWrap])}>
                        <ListItem className={clsx([classes.listKeyValueTitleItem])}>
                          <Box className={clsx([classes.listKeyValueBox])}>
                            <Box className={clsx([classes.listKeyValueBox_title])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([
                                  classes.fontJost,
                                  classes.fontWeight_500,
                                  classes.gray_600,
                                  classes.lineHeight_25,
                                ])}
                              >
                                Accounts
                              </Typography>
                            </Box>
                          </Box>
                        </ListItem>
                        <ListItem className={clsx([classes.listKeyValueTitleItem])}>
                          <Box className={clsx([classes.listKeyValueBox])}>
                            <Box className={clsx([classes.listKeyValueBox_key])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                Overdue
                              </Typography>
                            </Box>
                            <Box className={clsx([classes.listKeyValueBox_value])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                False
                              </Typography>
                            </Box>
                          </Box>
                        </ListItem>
                        <ListItem className={clsx([classes.listKeyValueTitleItem])}>
                          <Box className={clsx([classes.listKeyValueBox])}>
                            <Box className={clsx([classes.listKeyValueBox_key])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                Next due
                              </Typography>
                            </Box>
                            <Box className={clsx([classes.listKeyValueBox_value])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                2023-09-30
                              </Typography>
                            </Box>
                          </Box>
                        </ListItem>
                      </List>
                      <List className={clsx([classes.listKeyValueTitleWrap])}>
                        <ListItem className={clsx([classes.listKeyValueTitleItem])}>
                          <Box className={clsx([classes.listKeyValueBox])}>
                            <Box className={clsx([classes.listKeyValueBox_title])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([
                                  classes.fontJost,
                                  classes.fontWeight_500,
                                  classes.gray_600,
                                  classes.lineHeight_25,
                                ])}
                              >
                                Last accounts
                              </Typography>
                            </Box>
                          </Box>
                        </ListItem>
                        <ListItem className={clsx([classes.listKeyValueTitleItem])}>
                          <Box className={clsx([classes.listKeyValueBox])}>
                            <Box className={clsx([classes.listKeyValueBox_key])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                Type
                              </Typography>
                            </Box>
                            <Box className={clsx([classes.listKeyValueBox_value])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                Full
                              </Typography>
                            </Box>
                          </Box>
                        </ListItem>
                        <ListItem className={clsx([classes.listKeyValueTitleItem])}>
                          <Box className={clsx([classes.listKeyValueBox])}>
                            <Box className={clsx([classes.listKeyValueBox_key])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                Made up to
                              </Typography>
                            </Box>
                            <Box className={clsx([classes.listKeyValueBox_value])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                2022-01-02
                              </Typography>
                            </Box>
                          </Box>
                        </ListItem>
                        <ListItem className={clsx([classes.listKeyValueTitleItem])}>
                          <Box className={clsx([classes.listKeyValueBox])}>
                            <Box className={clsx([classes.listKeyValueBox_key])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                Period start on
                              </Typography>
                            </Box>
                            <Box className={clsx([classes.listKeyValueBox_value])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                2021-01-01
                              </Typography>
                            </Box>
                          </Box>
                        </ListItem>
                        <ListItem className={clsx([classes.listKeyValueTitleItem])}>
                          <Box className={clsx([classes.listKeyValueBox])}>
                            <Box className={clsx([classes.listKeyValueBox_key])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                Period end on
                              </Typography>
                            </Box>
                            <Box className={clsx([classes.listKeyValueBox_value])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                2022-01-02
                              </Typography>
                            </Box>
                          </Box>
                        </ListItem>
                      </List>
                      <List className={clsx([classes.listKeyValueTitleWrap])}>
                        <ListItem className={clsx([classes.listKeyValueTitleItem])}>
                          <Box className={clsx([classes.listKeyValueBox])}>
                            <Box className={clsx([classes.listKeyValueBox_title])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([
                                  classes.fontJost,
                                  classes.fontWeight_500,
                                  classes.gray_600,
                                  classes.lineHeight_25,
                                ])}
                              >
                                Accounting reference date
                              </Typography>
                            </Box>
                          </Box>
                        </ListItem>
                        <ListItem className={clsx([classes.listKeyValueTitleItem])}>
                          <Box className={clsx([classes.listKeyValueBox])}>
                            <Box className={clsx([classes.listKeyValueBox_key])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                Day
                              </Typography>
                            </Box>
                            <Box className={clsx([classes.listKeyValueBox_value])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                31
                              </Typography>
                            </Box>
                          </Box>
                        </ListItem>
                        <ListItem className={clsx([classes.listKeyValueTitleItem])}>
                          <Box className={clsx([classes.listKeyValueBox])}>
                            <Box className={clsx([classes.listKeyValueBox_key])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                Month
                              </Typography>
                            </Box>
                            <Box className={clsx([classes.listKeyValueBox_value])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                12
                              </Typography>
                            </Box>
                          </Box>
                        </ListItem>
                      </List>
                      <List className={clsx([classes.listKeyValueTitleWrap])}>
                        <ListItem className={clsx([classes.listKeyValueTitleItem])}>
                          <Box className={clsx([classes.listKeyValueBox])}>
                            <Box className={clsx([classes.listKeyValueBox_key])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                Can file
                              </Typography>
                            </Box>
                            <Box className={clsx([classes.listKeyValueBox_value])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                True
                              </Typography>
                            </Box>
                          </Box>
                        </ListItem>
                        <ListItem className={clsx([classes.listKeyValueTitleItem])}>
                          <Box className={clsx([classes.listKeyValueBox])}>
                            <Box className={clsx([classes.listKeyValueBox_key])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                Has charges
                              </Typography>
                            </Box>
                            <Box className={clsx([classes.listKeyValueBox_value])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                True
                              </Typography>
                            </Box>
                          </Box>
                        </ListItem>
                      </List>
                    </Box>
                  </Box>
                </Box>
                <Box className={classes.tabCol}>
                  <Box className={clsx([classes.flex, classes.flexCol, classes.gap_24])}>
                    <Box>
                      <List className={clsx([classes.listKeyValueTitleWrap, classes.gap_16, classes.border_0])}>
                        <ListItem className={clsx([classes.listKeyValueTitleItem])}>
                          <Box className={clsx([classes.listKeyValueBox])}>
                            <Box className={clsx([classes.listKeyValueBox_key])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                Company status
                              </Typography>
                            </Box>
                            <Box className={clsx([classes.listKeyValueBox_value])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                Active
                              </Typography>
                            </Box>
                          </Box>
                        </ListItem>
                        <ListItem className={clsx([classes.listKeyValueTitleItem])}>
                          <Box className={clsx([classes.listKeyValueBox])}>
                            <Box className={clsx([classes.listKeyValueBox_key])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                Has insolvency history
                              </Typography>
                            </Box>
                            <Box className={clsx([classes.listKeyValueBox_value])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                False
                              </Typography>
                            </Box>
                          </Box>
                        </ListItem>
                        <ListItem className={clsx([classes.listKeyValueTitleItem])}>
                          <Box className={clsx([classes.listKeyValueBox])}>
                            <Box className={clsx([classes.listKeyValueBox_key])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                Jurisdiction
                              </Typography>
                            </Box>
                            <Box className={clsx([classes.listKeyValueBox_value])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                England-Wales
                              </Typography>
                            </Box>
                          </Box>
                        </ListItem>
                        <ListItem className={clsx([classes.listKeyValueTitleItem])}>
                          <Box className={clsx([classes.listKeyValueBox])}>
                            <Box className={clsx([classes.listKeyValueBox_key])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                Creation date
                              </Typography>
                            </Box>
                            <Box className={clsx([classes.listKeyValueBox_value])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                2015-04-01
                              </Typography>
                            </Box>
                          </Box>
                        </ListItem>
                        <ListItem className={clsx([classes.listKeyValueTitleItem])}>
                          <Box className={clsx([classes.listKeyValueBox])}>
                            <Box className={clsx([classes.listKeyValueBox_key])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                Registered office is in dispute
                              </Typography>
                            </Box>
                            <Box className={clsx([classes.listKeyValueBox_value])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                False
                              </Typography>
                            </Box>
                          </Box>
                        </ListItem>
                        <ListItem className={clsx([classes.listKeyValueTitleItem])}>
                          <Box className={clsx([classes.listKeyValueBox])}>
                            <Box className={clsx([classes.listKeyValueBox_key])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                Company number
                              </Typography>
                            </Box>
                            <Box className={clsx([classes.listKeyValueBox_value])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                09521644
                              </Typography>
                            </Box>
                          </Box>
                        </ListItem>
                      </List>
                      <List className={clsx([classes.listKeyValueTitleWrap])}>
                        <ListItem className={clsx([classes.listKeyValueTitleItem])}>
                          <Box className={clsx([classes.listKeyValueBox])}>
                            <Box className={clsx([classes.listKeyValueBox_title])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([
                                  classes.fontJost,
                                  classes.fontWeight_500,
                                  classes.gray_600,
                                  classes.lineHeight_25,
                                ])}
                              >
                                Confirmation statement
                              </Typography>
                            </Box>
                          </Box>
                        </ListItem>
                        <ListItem className={clsx([classes.listKeyValueTitleItem])}>
                          <Box className={clsx([classes.listKeyValueBox])}>
                            <Box className={clsx([classes.listKeyValueBox_key])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                Overdue
                              </Typography>
                            </Box>
                            <Box className={clsx([classes.listKeyValueBox_value])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                False
                              </Typography>
                            </Box>
                          </Box>
                        </ListItem>
                        <ListItem className={clsx([classes.listKeyValueTitleItem])}>
                          <Box className={clsx([classes.listKeyValueBox])}>
                            <Box className={clsx([classes.listKeyValueBox_key])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                Next due
                              </Typography>
                            </Box>
                            <Box className={clsx([classes.listKeyValueBox_value])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                2023-04-15
                              </Typography>
                            </Box>
                          </Box>
                        </ListItem>
                        <ListItem className={clsx([classes.listKeyValueTitleItem])}>
                          <Box className={clsx([classes.listKeyValueBox])}>
                            <Box className={clsx([classes.listKeyValueBox_key])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                Last made up to
                              </Typography>
                            </Box>
                            <Box className={clsx([classes.listKeyValueBox_value])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                2022-04-01
                              </Typography>
                            </Box>
                          </Box>
                        </ListItem>
                        <ListItem className={clsx([classes.listKeyValueTitleItem])}>
                          <Box className={clsx([classes.listKeyValueBox])}>
                            <Box className={clsx([classes.listKeyValueBox_key])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                Next made up to
                              </Typography>
                            </Box>
                            <Box className={clsx([classes.listKeyValueBox_value])}>
                              <Typography
                                variant="text_SM"
                                component="p"
                                className={clsx([classes.fontJost, classes.gray_600, classes.lineHeight_25])}
                              >
                                2023-04-01
                              </Typography>
                            </Box>
                          </Box>
                        </ListItem>
                      </List>
                      <Button variant="contained" sx={{ width: 1, mt: 4 }} className={classes.cmn_btn}>
                        Refresh profile
                        <Refresh className={classes.cmn_btn_icon} />
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <Box className={classes.headingStripIconText}>
                <Box className={classes.headingStripIcon}>
                  <CropFreeOutlined />
                </Box>
                <Box className={classes.headingStripTextWrap}>
                  <Typography variant="text_MD" component="h3" className={classes.fontJost}>
                    View application details, upload documents and write a note
                  </Typography>
                </Box>
              </Box>
              <Box className={clsx([classes.tabInfoWrap])}>
                <Box className={clsx([classes.tabGap_144, classes.flex, classes.width_100])}>
                  <Stack
                    spacing={{ xs: 2, sm: 4 }}
                    direction="row"
                    useFlexGap
                    flexWrap="nowrap"
                    className={clsx([classes.stepWrap, classes.tabInfoLeft])}
                  >
                    <Box className={clsx([classes.stepLine])}>
                      <RadioBlueIcon />
                    </Box>
                    <Box>
                      <Typography variant="text_MD" component="h4">
                        Details
                      </Typography>
                      <Typography variant="text_SM" component="p">
                        View application details
                      </Typography>
                    </Box>
                  </Stack>
                  <Box className={clsx([classes.stepWrap, classes.tabInfoRight])}>
                    <Box
                      className={clsx([
                        classes.tabInfoRight_box,
                        classes.Gap_24,
                        classes.flex,
                        classes.flexCol,
                        classes.tabInfoRight_space,
                      ])}
                    >
                      <Box className={clsx([classes.formInlineLblValue])}>
                        <Box className={clsx([classes.formInlineLblValue_lbl])}>
                          <Typography
                            variant="text_MD"
                            component="label"
                            className={clsx([classes.fontJost, classes.fontWeight_500, classes.gray_500])}
                          >
                            Assignee
                          </Typography>
                        </Box>
                        <Box className={clsx([classes.formInlineLblValue_value])}>
                          <select className={clsx([classes.formSelect])}>
                            <option>Select an assignee</option>
                          </select>
                        </Box>
                      </Box>
                      <Box className={clsx([classes.formInlineLblValue])}>
                        <Box className={clsx([classes.formInlineLblValue_lbl])}>
                          <Typography
                            variant="text_MD"
                            component="label"
                            className={clsx([classes.fontJost, classes.fontWeight_500, classes.gray_500])}
                          >
                            Status
                          </Typography>
                          <Typography
                            variant="text_SM"
                            component="p"
                            className={clsx([classes.fontJost, classes.gray_700])}
                          >
                            Update application status
                          </Typography>
                        </Box>
                        <Box className={clsx([classes.formInlineLblValue_value])}>
                          <select className={clsx([classes.formSelect])}>
                            <option>Select an assignee</option>
                          </select>
                        </Box>
                      </Box>
                      <Box className={clsx([classes.formInlineLblValue])}>
                        <Box className={clsx([classes.formInlineLblValue_lbl])}>
                          <Typography
                            variant="text_MD"
                            component="label"
                            className={clsx([classes.fontJost, classes.fontWeight_500, classes.gray_500])}
                          >
                            Company name
                          </Typography>
                        </Box>
                        <Box className={clsx([classes.formInlineLblValue_value])}>
                          <Typography
                            variant="text_MD"
                            component="p"
                            className={clsx([classes.fontJost, classes.gray_500])}
                          >
                            Bankkit
                          </Typography>
                        </Box>
                      </Box>
                      <Box className={clsx([classes.formInlineLblValue])}>
                        <Box className={clsx([classes.formInlineLblValue_lbl])}>
                          <Typography
                            variant="text_MD"
                            component="label"
                            className={clsx([classes.fontJost, classes.fontWeight_500, classes.gray_500])}
                          >
                            Company type
                          </Typography>
                        </Box>
                        <Box className={clsx([classes.formInlineLblValue_value])}>
                          <Typography
                            variant="text_MD"
                            component="p"
                            className={clsx([classes.fontJost, classes.gray_500])}
                          >
                            Private limited with Share Capital
                          </Typography>
                        </Box>
                      </Box>
                      <Box className={clsx([classes.formInlineLblValue])}>
                        <Box className={clsx([classes.formInlineLblValue_lbl])}>
                          <Typography
                            variant="text_MD"
                            component="label"
                            className={clsx([classes.fontJost, classes.fontWeight_500, classes.gray_500])}
                          >
                            Loan amount
                          </Typography>
                        </Box>
                        <Box className={clsx([classes.formInlineLblValue_value])}>
                          <FormControl variant="standard">
                            <CustomInput
                              defaultValue="1,234,560.00"
                              id="bootstrap-input"
                              startAdornment={
                                <InputAdornment position="start">
                                  <Typography
                                    variant="text_MD"
                                    component="span"
                                    className={clsx([
                                      classes.fontJost,
                                      classes.fontWeight_500,
                                      classes.black_500,
                                      classes.inputIconLeft,
                                    ])}
                                  >
                                    £
                                  </Typography>
                                </InputAdornment>
                              }
                            />
                          </FormControl>
                        </Box>
                      </Box>
                      <Box className={clsx([classes.formInlineLblValue])}>
                        <Box className={clsx([classes.formInlineLblValue_lbl])}>
                          <Typography
                            variant="text_MD"
                            component="label"
                            className={clsx([classes.fontJost, classes.fontWeight_500, classes.gray_500])}
                          >
                            Date submitted
                          </Typography>
                        </Box>
                        <Box className={clsx([classes.formInlineLblValue_value])}>
                          <Typography
                            variant="text_MD"
                            component="p"
                            className={clsx([classes.fontJost, classes.gray_500])}
                          >
                            01/11/2022
                          </Typography>
                        </Box>
                      </Box>
                      <Box className={clsx([classes.formInlineLblValue])}>
                        <Box className={clsx([classes.formInlineLblValue_lbl])}>
                          <Typography
                            variant="text_MD"
                            component="label"
                            className={clsx([classes.fontJost, classes.fontWeight_500, classes.gray_500])}
                          >
                            Automated Emails
                          </Typography>
                        </Box>
                        <Box className={clsx([classes.formInlineLblValue_value])}>
                          <FormControlLabel control={<IOSSwitch defaultChecked />} label="Disabled" />
                        </Box>
                      </Box>
                      <Button variant="contained" sx={{ width: 1, mt: 4 }} className={classes.cmn_btn}>
                        Launch application
                        <OpenInNew className={classes.cmn_btn_icon} />
                      </Button>
                    </Box>
                  </Box>
                </Box>
                <Box className={clsx([classes.tabGap_144, classes.flex, classes.width_100])}>
                  <Stack
                    spacing={{ xs: 2, sm: 4 }}
                    direction="row"
                    useFlexGap
                    flexWrap="nowrap"
                    className={clsx([classes.stepWrap, classes.tabInfoLeft])}
                  >
                    <Box className={clsx([classes.stepLine])}>
                      <RadioGrayIcon />
                    </Box>
                    <Box>
                      <Typography variant="text_MD" component="h4">
                        Documents
                      </Typography>
                      <Typography variant="text_SM" component="p">
                        Upload a document
                      </Typography>
                    </Box>
                  </Stack>
                  <Box className={clsx([classes.stepWrap, classes.tabInfoRight])}>
                    <Box
                      className={clsx([
                        classes.tabInfoRight_box,
                        classes.Gap_24,
                        classes.flex,
                        classes.flexCol,
                        classes.tabInfoRight_space,
                      ])}
                    >
                      <Box className={clsx([classes.formInlineLblValue])}>
                        <Box className={clsx([classes.formInlineLblValue_lbl])}>
                          <Typography
                            variant="text_MD"
                            component="label"
                            className={clsx([classes.fontJost, classes.fontWeight_500, classes.gray_500])}
                          >
                            Document type
                          </Typography>
                        </Box>
                        <Box className={clsx([classes.formInlineLblValue_value])}>
                          <select className={clsx([classes.formSelect])}>
                            <option>Select an assignee</option>
                          </select>
                        </Box>
                      </Box>
                      <Box>
                        <Box className={classes.inputFileWrapper}>
                          <label className={clsx([classes.inputFileLabel, classes.width_100])} htmlFor="filInput">
                            <input type="file" name="" id="filInput" />
                            <Box className={classes.inputFileContent}>
                              <span className={classes.inputFileIcon}>
                                <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g clipPath="url(#clip0_1698_3041)">
                                    <path
                                      d="M13.3334 13.3334L10 10M10 10L6.66669 13.3334M10 10V17.5M16.9917 15.325C17.8045 14.8819 18.4466 14.1808 18.8166 13.3322C19.1866 12.4837 19.2635 11.5361 19.0352 10.6389C18.8069 9.74182 18.2863 8.94629 17.5556 8.3779C16.8249 7.80951 15.9257 7.50064 15 7.50003H13.95C13.6978 6.5244 13.2277 5.61864 12.575 4.85085C11.9223 4.08307 11.1041 3.47324 10.1818 3.0672C9.25949 2.66116 8.25715 2.46949 7.25013 2.5066C6.2431 2.5437 5.25758 2.80861 4.36768 3.28142C3.47777 3.75422 2.70662 4.42261 2.11221 5.23635C1.5178 6.05008 1.1156 6.98797 0.935844 7.97952C0.756086 8.97107 0.803449 9.99047 1.07437 10.9611C1.3453 11.9317 1.83273 12.8282 2.50003 13.5834"
                                      stroke="#475467"
                                      strokeWidth="1.66667"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_1698_3041">
                                      <rect width="20" height="20" fill="white" />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </span>
                              <Typography component="p" className={classes.inputFileText}>
                                Click to upload
                                <Typography component="span">or drag and drop</Typography>
                              </Typography>
                            </Box>
                          </label>
                          <Box className={classes.uploadedItemList}>
                            <Box className={classes.uploadedItem}>
                              <span className={classes.docIcon}>
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M3.41271 0.5H15.8L20.7079 5.40787C20.7083 5.4115 20.7087 5.41544 20.7091 5.41969C20.7121 5.45199 20.7143 5.49774 20.7143 5.562V23.4536C20.6717 23.4843 20.6243 23.5 20.5873 23.5H3.41271C3.37571 23.5 3.32832 23.4843 3.28571 23.4536V0.825429C3.28571 0.635243 3.33454 0.551618 3.35587 0.525628C3.37105 0.507127 3.38398 0.5 3.41271 0.5Z"
                                    fill="white"
                                    stroke="#CC4B4C"
                                  />
                                  <path
                                    d="M8.36312 14.2817C8.21398 14.2817 8.07084 14.2333 7.94869 14.142C7.50255 13.8073 7.44255 13.4349 7.47084 13.1811C7.54884 12.4834 8.41155 11.7531 10.0358 11.0091C10.6804 9.59657 11.2937 7.85614 11.6593 6.402C11.2316 5.47114 10.8158 4.26343 11.1188 3.555C11.2251 3.30686 11.3576 3.11657 11.6048 3.03429C11.7026 3.00171 11.9494 2.96057 12.0403 2.96057C12.2563 2.96057 12.4461 3.23871 12.5807 3.41014C12.7071 3.57129 12.9938 3.91286 12.4208 6.32529C12.9986 7.51843 13.8171 8.73386 14.6014 9.56614C15.1633 9.46457 15.6467 9.41271 16.0406 9.41271C16.7117 9.41271 17.1184 9.56914 17.2843 9.89143C17.4214 10.158 17.3653 10.4696 17.1171 10.8171C16.8784 11.151 16.5493 11.3276 16.1657 11.3276C15.6446 11.3276 15.0377 10.9984 14.361 10.3483C13.1451 10.6024 11.7253 11.0559 10.5776 11.5577C10.2193 12.318 9.87598 12.9304 9.55627 13.3796C9.11698 13.9946 8.73812 14.2817 8.36312 14.2817ZM9.50398 12.0849C8.58812 12.5996 8.21484 13.0226 8.18784 13.2609C8.18355 13.3003 8.17198 13.404 8.37255 13.5574C8.43641 13.5373 8.80927 13.3671 9.50398 12.0849ZM15.3484 10.1811C15.6977 10.4499 15.783 10.5857 16.0114 10.5857C16.1117 10.5857 16.3976 10.5814 16.53 10.3967C16.5938 10.3071 16.6187 10.2497 16.6286 10.2189C16.5758 10.191 16.506 10.1344 16.125 10.1344C15.9086 10.1349 15.6364 10.1443 15.3484 10.1811ZM12.147 7.36029C11.8406 8.42057 11.436 9.56529 11.001 10.602C11.8967 10.2544 12.8704 9.951 13.785 9.73629C13.2064 9.06429 12.6283 8.22514 12.147 7.36029ZM11.8868 3.73371C11.8448 3.74786 11.3168 4.48671 11.928 5.112C12.3347 4.20557 11.9053 3.72771 11.8868 3.73371Z"
                                    fill="#CC4B4C"
                                  />
                                  <path
                                    d="M8.68119 21.7341H8.14285V18.4286H9.09355C9.23396 18.4286 9.37305 18.4509 9.51051 18.4958C9.64796 18.5408 9.77131 18.608 9.88055 18.6976C9.98979 18.7872 10.078 18.8957 10.1453 19.0227C10.2125 19.1497 10.2463 19.2924 10.2463 19.4511C10.2463 19.6188 10.2178 19.7703 10.161 19.9065C10.1043 20.0426 10.0249 20.1571 9.9232 20.2496C9.8215 20.3421 9.69881 20.414 9.55545 20.4648C9.41209 20.5157 9.25331 20.5409 9.0801 20.5409H8.68086V21.7341H8.68119ZM8.68119 18.8367V20.1463H9.17458C9.24019 20.1463 9.30515 20.1351 9.36977 20.1125C9.43407 20.0902 9.49312 20.0534 9.54692 20.0026C9.60072 19.9517 9.64402 19.8809 9.67683 19.7897C9.70964 19.6985 9.72604 19.5856 9.72604 19.4511C9.72604 19.3973 9.71849 19.335 9.70373 19.2651C9.68864 19.1949 9.65813 19.1277 9.61188 19.0634C9.56529 18.9991 9.50034 18.9453 9.41668 18.902C9.33303 18.8587 9.22215 18.837 9.08469 18.837H8.68119V18.8367Z"
                                    fill="black"
                                  />
                                  <path
                                    d="M13.5475 19.9895C13.5475 20.2614 13.5183 20.494 13.4599 20.6869C13.4015 20.8798 13.3277 21.0412 13.2378 21.1714C13.1479 21.3017 13.0472 21.404 12.935 21.4788C12.8228 21.5536 12.7145 21.6094 12.6099 21.6471C12.5052 21.6845 12.4095 21.7085 12.3228 21.719C12.2362 21.7288 12.1719 21.7341 12.1299 21.7341H10.8788V18.4286H11.8744C12.1526 18.4286 12.397 18.4729 12.6076 18.5608C12.8182 18.6487 12.9934 18.7665 13.1325 18.9128C13.2716 19.0591 13.3752 19.2261 13.4441 19.4127C13.513 19.5997 13.5475 19.792 13.5475 19.9895ZM11.9508 21.3394C12.3156 21.3394 12.5787 21.2229 12.7401 20.9897C12.9015 20.7565 12.9822 20.4186 12.9822 19.976C12.9822 19.8386 12.9658 19.7024 12.933 19.5679C12.8999 19.4334 12.8366 19.3117 12.7424 19.2025C12.6483 19.0932 12.5203 19.005 12.3589 18.9377C12.1975 18.8705 11.9882 18.8367 11.731 18.8367H11.4171V21.3394H11.9508Z"
                                    fill="black"
                                  />
                                  <path
                                    d="M14.875 18.8367V19.8773H16.2564V20.245H14.875V21.7341H14.3278V18.4286H16.3952V18.8367H14.875Z"
                                    fill="black"
                                  />
                                </svg>
                              </span>
                              <span className={classes.listItemText}>Bank statement/9fbfe60d797-0aa0-4ddf.pdf</span>
                              <Box className={classes.listItemAction}>
                                <span className={clsx(classes.actionIcon, classes.downloadIcon)}>
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g clipPath="url(#clip0_560_42164)">
                                      <path
                                        d="M11.667 2.5V5.83333C11.667 6.05435 11.7548 6.26631 11.9111 6.42259C12.0674 6.57887 12.2793 6.66667 12.5003 6.66667H15.8337"
                                        stroke="#0086C9"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M14.167 17.5H5.83366C5.39163 17.5 4.96771 17.3244 4.65515 17.0118C4.34259 16.6993 4.16699 16.2754 4.16699 15.8333V4.16667C4.16699 3.72464 4.34259 3.30072 4.65515 2.98816C4.96771 2.67559 5.39163 2.5 5.83366 2.5H11.667L15.8337 6.66667V15.8333C15.8337 16.2754 15.6581 16.6993 15.3455 17.0118C15.0329 17.3244 14.609 17.5 14.167 17.5Z"
                                        stroke="#0086C9"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M10 14.1667V9.16669"
                                        stroke="#0086C9"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M7.91699 12.0833L10.0003 14.1666L12.0837 12.0833"
                                        stroke="#0086C9"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_560_42164">
                                        <rect width="20" height="20" fill="white" />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </span>
                                <span className={clsx(classes.actionIcon, classes.deleteIcon)}>
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g clipPath="url(#clip0_560_42170)">
                                      <path
                                        d="M3.33301 5.83325H16.6663"
                                        stroke="#0086C9"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M8.33301 9.16675V14.1667"
                                        stroke="#0086C9"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M11.667 9.16675V14.1667"
                                        stroke="#0086C9"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M4.16699 5.83325L5.00033 15.8333C5.00033 16.2753 5.17592 16.6992 5.48848 17.0118C5.80104 17.3243 6.22496 17.4999 6.66699 17.4999H13.3337C13.7757 17.4999 14.1996 17.3243 14.5122 17.0118C14.8247 16.6992 15.0003 16.2753 15.0003 15.8333L15.8337 5.83325"
                                        stroke="#0086C9"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M7.5 5.83333V3.33333C7.5 3.11232 7.5878 2.90036 7.74408 2.74408C7.90036 2.5878 8.11232 2.5 8.33333 2.5H11.6667C11.8877 2.5 12.0996 2.5878 12.2559 2.74408C12.4122 2.90036 12.5 3.11232 12.5 3.33333V5.83333"
                                        stroke="#0086C9"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_560_42170">
                                        <rect width="20" height="20" fill="white" />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </span>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <Box className={clsx([classes.formInlineLblValue])}>
                        <Box className={clsx([classes.formInlineLblValue_lbl])}>
                          <Typography
                            variant="text_MD"
                            component="label"
                            className={clsx([classes.fontJost, classes.fontWeight_500, classes.gray_500])}
                          >
                            Merged documents
                          </Typography>
                        </Box>
                        <Box className={clsx([classes.formInlineLblValue_value])}>
                          <Box className={classes.dashboardPaletteLink}>
                            <Link href="#!" className={classes.dashboardPaletteLinkItem}>
                              <Typography
                                variant="text_MD"
                                component="span"
                                className={clsx([classes.fontJost, classes.fontWeight_400, classes.primary_600])}
                              >
                                Download document
                              </Typography>
                              <DownloadBlue className={classes.dashboardPaletteLinkIcon} />
                            </Link>
                          </Box>
                        </Box>
                      </Box>
                      <Box className={clsx([classes.formInlineLblValue])}>
                        <Box className={clsx([classes.formInlineLblValue_lbl])}>
                          <Typography
                            variant="text_MD"
                            component="label"
                            className={clsx([classes.fontJost, classes.fontWeight_500, classes.gray_500])}
                          >
                            Email attachments
                          </Typography>
                        </Box>
                        <Box className={clsx([classes.formInlineLblValue_value])}>
                          <Box className={classes.dashboardPaletteLink}>
                            <Link href="#!" className={classes.dashboardPaletteLinkItem}>
                              <Typography
                                variant="text_MD"
                                component="span"
                                className={clsx([classes.fontJost, classes.fontWeight_400, classes.primary_600])}
                              >
                                Download attachments
                              </Typography>
                              <DownloadBlue className={classes.dashboardPaletteLinkIcon} />
                            </Link>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box className={clsx([classes.tabGap_144, classes.flex, classes.width_100])}>
                  <Stack
                    spacing={{ xs: 2, sm: 4 }}
                    direction="row"
                    useFlexGap
                    flexWrap="nowrap"
                    className={clsx([classes.stepWrap, classes.tabInfoLeft])}
                  >
                    <Box>
                      <RadioGrayIcon />
                    </Box>
                    <Box>
                      <Typography variant="text_MD" component="h4">
                        Notes
                      </Typography>
                      <Typography variant="text_SM" component="p">
                        View application notes
                      </Typography>
                    </Box>
                  </Stack>
                  <Box className={clsx([classes.stepWrap, classes.tabInfoRight])}>
                    <Box className={clsx([classes.tabInfoRight_space])}>
                      <Box className={clsx([classes.flex, classes.flexCol, classes.gap_32])}>
                        {/* TextArea Box Start */}
                        <Box className={clsx([classes.textareaWrap, classes.flex, classes.flexCol])}>
                          <Box className={clsx([classes.textareaLblWrap])}>
                            <Box className={clsx([classes.textareaLblWrap_lbl])}>
                              <Typography
                                variant="text_MD"
                                component="label"
                                className={clsx([classes.fontJost, classes.fontWeight_500, classes.gray_500])}
                              >
                                Internal note
                              </Typography>
                            </Box>
                            <Box className={clsx([classes.textareaLblWrap_action])}>
                              <Box className={classes.dashboardPaletteLink}>
                                <Link href="#!" className={classes.dashboardPaletteLinkItem}>
                                  <Typography
                                    variant="text_MD"
                                    component="span"
                                    className={clsx([classes.fontJost, classes.fontWeight_400, classes.primary_600])}
                                  >
                                    Delete
                                  </Typography>
                                  <TarshBlue className={classes.dashboardPaletteLinkIcon} />
                                </Link>
                              </Box>
                            </Box>
                          </Box>
                          <Box className={clsx([classes.textareaWrapInner])}>
                            <CustomInput
                              multiline
                              rows={4}
                              maxRows={4}
                              defaultValue=""
                              id="bootstrap-input"
                              className={clsx([classes.textareaCustom, classes.fontWeight_500, classes.gray_500])}
                            />
                          </Box>
                          <Box className={clsx([classes.textareaLblWrap_action, classes.width_100])}>
                            <Box className={classes.dashboardPaletteLink}>
                              <Link href="#!" className={classes.dashboardPaletteLinkItem}>
                                <Typography
                                  variant="text_MD"
                                  component="span"
                                  className={clsx([classes.fontJost, classes.fontWeight_400, classes.primary_600])}
                                >
                                  Add new
                                </Typography>
                                <EditBlue className={classes.dashboardPaletteLinkIcon} />
                              </Link>
                            </Box>
                          </Box>
                        </Box>
                        {/* TextArea Box End */}
                        {/* TextArea Box Start */}
                        <Box className={clsx([classes.textareaWrap, classes.flex, classes.flexCol])}>
                          <Box className={clsx([classes.textareaLblWrap])}>
                            <Box className={clsx([classes.textareaLblWrap_lbl])}>
                              <Typography
                                variant="text_MD"
                                component="label"
                                className={clsx([classes.fontJost, classes.fontWeight_500, classes.gray_500])}
                              >
                                Internal note
                              </Typography>
                            </Box>
                            <Box className={clsx([classes.textareaLblWrap_action])}>
                              <Box className={classes.dashboardPaletteLink}>
                                <Link href="#!" className={classes.dashboardPaletteLinkItem}>
                                  <Typography
                                    variant="text_MD"
                                    component="span"
                                    className={clsx([classes.fontJost, classes.fontWeight_400, classes.primary_600])}
                                  >
                                    Delete
                                  </Typography>
                                  <TarshBlue className={classes.dashboardPaletteLinkIcon} />
                                </Link>
                              </Box>
                            </Box>
                          </Box>
                          <Box className={clsx([classes.textareaWrapInner])}>
                            <CustomInput
                              multiline
                              rows={4}
                              maxRows={4}
                              defaultValue=""
                              id="bootstrap-input"
                              className={clsx([classes.textareaCustom, classes.fontWeight_500, classes.gray_500])}
                            />
                          </Box>
                          <Box className={clsx([classes.textareaLblWrap_action, classes.width_100])}>
                            <Box className={classes.dashboardPaletteLink}>
                              <Link href="#!" className={classes.dashboardPaletteLinkItem}>
                                <Typography
                                  variant="text_MD"
                                  component="span"
                                  className={clsx([classes.fontJost, classes.fontWeight_400, classes.primary_600])}
                                >
                                  Add new
                                </Typography>
                                <EditBlue className={classes.dashboardPaletteLinkIcon} />
                              </Link>
                            </Box>
                          </Box>
                        </Box>
                        {/* TextArea Box End */}
                      </Box>
                      <Button variant="contained" sx={{ width: 1, mt: 8 }} className={classes.cmn_btn}>
                        Save all changes
                        <Done className={classes.cmn_btn_icon} />
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              testsdsds
            </CustomTabPanel>
          </Box>
        </Box>
      </Box>

      {/* <Box></Box> */}
      {/* HOME PAGE CODE END HERE */}
    </>
  );
};

export default Design;
