import { FC } from 'react';
import { ThemeProvider } from '@mui/material';

import './i18n/config';
import theme from './core/theme-extended';
import { AppRouter } from './router';

const App: FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <AppRouter />
    </ThemeProvider>
  );
};

export default App;
