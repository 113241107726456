import { ChevronRight, Search } from '@material-ui/icons';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  FormControlLabel,
  Grid,
  InputAdornment,
  Pagination,
  Stack,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import { Header } from 'components/Header/Header';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CustomInput } from 'pages/home/Home.styles';
import { MaterialUISwitchLg } from 'pages/integrations/Integrations';
import {
  SEARCH_DEBOUNCE_DELAY,
  firstLatterCapital,
  getParamsValue,
  isValidArray,
} from 'pages/leads/components/CreateApplicationModal/utils';
import { useAdmin } from 'store/admin/hooks';
import routes from 'router/routes';
import { useHistory } from 'react-router-dom';
import { updateAllLenderRules, updateLenderRules } from 'http/lenderRules';
import { RuleEngineRule } from 'store/admin/types';
import { IAddRuleInitialValue } from 'store/applications/types';
import { IEditLenderRulesData } from 'store/lenderRules/types';
import CustomButton from 'components/CustomButton/Button';
import { useGlobalState } from 'store/global/hooks';
import { AlertMessage } from 'components/alerts';
import { AlertMessageStatus } from 'core/types';
import CommonModal from 'components/Modal/CommonModal';
import { useDebounce } from 'hooks';
import NoDataFound from 'components/NoDataFound/NoDataFound';
import AddRulesModal from './components/AddRules/AddRulesModal';
import useStyles from './LenderRules.styles';
import useCommonStyle from '../../assets/css/Common.styles';
import { ReactComponent as ApplicationLeads } from '../../assets/icons/application_offers.svg';
import { ReactComponent as LayersIcon } from '../../assets/icons/accounts_overdue.svg';
import { ReactComponent as BriefcaseIcon } from '../../assets/icons/bag.svg';
import { ReactComponent as ActivityIcon } from '../../assets/icons/company_status.svg';
import { ReactComponent as CoinStackIcon } from '../../assets/icons/loan_amount.svg';
import { ReactComponent as DollarSignIcon } from '../../assets/icons/monthly_turnover.svg';
import { ReactComponent as HashIcon } from '../../assets/icons/sic_codes.svg';
import { ReactComponent as WatchIcon } from '../../assets/icons/trading_time.svg';
import { ReactComponent as AddRuleIcon } from '../../assets/icons/puleCircleIcon.svg';

const lenderRuleIcon = [
  { icon: <BriefcaseIcon />, title: 'Business age', sub_title: 'business-age-in-months' },
  { icon: <LayersIcon />, title: 'Accounts overdue', sub_title: 'accounts-overdue' },
  { icon: <DollarSignIcon />, title: 'Monthly turnover', sub_title: 'monthly-turnover' },
  { icon: <ActivityIcon />, title: 'Company status', sub_title: 'company-status' },
  { icon: <CoinStackIcon />, title: 'Loan amount', sub_title: 'loan-amount' },
  { icon: <WatchIcon />, title: 'Trading time', sub_title: 'trading-time-in-months' },
  { icon: <HashIcon />, title: 'Sic codes', sub_title: 'sic-codes-blacklist' },
  { icon: <HashIcon />, title: 'Company type', sub_title: 'company-type' },
  { icon: <HashIcon />, title: 'Company region', sub_title: 'company-region' },
  { icon: <HashIcon />, title: 'Company annual revenue', sub_title: 'company-annual-revenue' },
  { icon: <HashIcon />, title: 'Is homeowner', sub_title: 'is-homeowner' },
];

const LenderRules = () => {
  const [search, setSearch] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [openRuleModal, setOpenRuleModal] = useState<string>('');
  const [lenderRulesDetails, setLenderRulesDetails] = useState<IAddRuleInitialValue | null>(null);
  const [enabledLenderRules, setEnabledLenderRules] = useState(true);
  const debouncedSearchTerm = useDebounce(search, SEARCH_DEBOUNCE_DELAY);
  const history = useHistory();
  const classes = useStyles();
  const pageSize: number = 9;
  const commonClasses = useCommonStyle();
  const { rulesEngine, getRulesEngine, success, error, setSuccess, clearError } = useAdmin();
  const { error: globalError } = useGlobalState();

  const toggleModal = (value: string) => setOpenRuleModal(value);

  const handleEditData = (rulesData: IEditLenderRulesData[]) => {
    history.push(`${routes?.lenderDetail}/${rulesData[0]?.lender_id}`);
  };

  const parsedRules = useMemo(() => {
    return rulesEngine.reduce((acc: Record<string, RuleEngineRule[]>, val: RuleEngineRule) => {
      const lenderName = val.lender_name?.toLowerCase();
      const ruleCriteria = val.criteria;
      if (lenderName) {
        if (acc[lenderName]) acc[lenderName].push(val);
        else acc[lenderName] = [val];
      }

      if (ruleCriteria) {
        if (acc[ruleCriteria]) acc[ruleCriteria].push(val);
        else acc[ruleCriteria] = [val];
      }

      return acc;
    }, {} as Record<string, RuleEngineRule[]>);
  }, [rulesEngine]);

  const handleRulesData = () => {
    const ruleData: { title: string; fact: string[]; rulesData: IEditLenderRulesData[] }[] = [];
    Object.entries(parsedRules).forEach((data) => {
      ruleData.push({ title: data[0], fact: data[1]?.map((d) => d?.fact), rulesData: data[1] });
    });
    return ruleData;
  };

  const handleUpdateLenderRulesStatus = async (value: boolean, lender_id: string) => {
    await updateLenderRules(lender_id, { active: value }).then(() => {
      getRulesEngine({});
    });
  };

  const startIndex = (page - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, handleRulesData()?.length);
  const lenderRuleData = handleRulesData()?.slice(startIndex, endIndex);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const getLenderRuleData = useCallback(() => {
    if (!openRuleModal || debouncedSearchTerm) {
      const queryString = getParamsValue({ search });
      getRulesEngine(queryString);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openRuleModal, debouncedSearchTerm]);

  const updateAllLenderRulesData = async (value: boolean) => {
    await updateAllLenderRules({ active: value }).then(() => {
      setEnabledLenderRules(value);
      getLenderRuleData();
    });
  };

  useEffect(() => {
    getLenderRuleData();
  }, [getLenderRuleData]);

  useEffect(() => {
    return () => {
      clearError();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Header name="Lender rules" />
      <Box className={commonClasses.mainWrapper}>
        <Box sx={{ mb: 6 }} className={clsx([commonClasses.justifyBetween, commonClasses.marginTop24])}>
          <Box className={clsx([classes.flexWithGap20, commonClasses.mainWrapper])}>
            <Box>
              <CustomButton
                onClick={() => {
                  toggleModal('Add');
                  setLenderRulesDetails(null);
                }}
                className="white"
              >
                Add rules <AddRuleIcon />
              </CustomButton>
            </Box>
            <Box className={clsx([commonClasses.mainWrapper, classes.marginTop10])}>
              <Typography
                className={clsx([commonClasses.fontJost, commonClasses.boxCircleTitle])}
                component="p"
                variant="text_MD"
                sx={{ mb: 2 }}
              >
                Enable all{' '}
                <FormControlLabel
                  sx={{ m: 0 }}
                  control={
                    <MaterialUISwitchLg
                      checked={enabledLenderRules}
                      onChange={(e) => {
                        updateAllLenderRulesData(e?.target?.checked);
                      }}
                    />
                  }
                  label=""
                />
              </Typography>
            </Box>
          </Box>
          <Box className={classes.marginRight16}>
            <CustomInput
              rows={2}
              maxRows={4}
              value={search}
              placeholder="Search by name"
              onChange={(e) => setSearch(e?.target?.value)}
              startAdornment={
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              }
            />
          </Box>
        </Box>
        <Box sx={{ mt: 6, px: 4 }}>
          <Grid container>
            <Grid item xs={12} lg={12} xl={12}>
              <Box>
                <Grid container spacing={2} className={commonClasses.flexSpaceCenter}>
                  {isValidArray(lenderRuleData) ? (
                    lenderRuleData?.map((data) => {
                      return (
                        <Grid item xs={12} md={4}>
                          <Card className={clsx([classes.integrateToolBox])}>
                            <CardContent sx={{ p: 0 }} className={classes.integrateToolBoxInner}>
                              <Box sx={{ px: 6, pt: 4, mb: 6 }}>
                                <Stack
                                  sx={{ mb: 2 }}
                                  direction="row"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  <Box>
                                    <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={4}>
                                      <Avatar
                                        variant="rounded"
                                        alt={data?.title?.toUpperCase()}
                                        className={clsx([commonClasses.avtarRound])}
                                        src={
                                          data?.rulesData[0]?.lender_logo
                                            ? data?.rulesData[0]?.lender_logo
                                            : `https://placehold.co/800?text=${data?.title}&font=roboto`
                                        }
                                        sx={{ width: 52, height: 52 }}
                                      />
                                      <Box className={commonClasses.boxCircleText}>
                                        <Typography
                                          className={clsx([commonClasses.fontJost, commonClasses.boxCircleTitle])}
                                          component="p"
                                          variant="text_MD"
                                          sx={{ mb: 0 }}
                                        >
                                          {firstLatterCapital(data?.title) || '-'}
                                        </Typography>
                                      </Box>
                                    </Stack>
                                  </Box>
                                  <Box>
                                    <FormControlLabel
                                      sx={{ m: 0 }}
                                      control={
                                        <MaterialUISwitchLg
                                          onChange={(e) =>
                                            handleUpdateLenderRulesStatus(
                                              e?.target?.checked,
                                              data?.rulesData[0]?.lender_id || '',
                                            )
                                          }
                                          checked={data?.rulesData[0]?.active}
                                        />
                                      }
                                      label=""
                                    />
                                  </Box>
                                </Stack>
                                <Stack direction="row" flexWrap="wrap">
                                  {lenderRuleIcon
                                    ?.filter((d) => data?.fact?.includes(d?.sub_title))
                                    ?.map((e) => {
                                      return (
                                        <Chip
                                          className={clsx([commonClasses.fontJost, classes.chipNoBorder])}
                                          label={e?.title}
                                          icon={e?.icon}
                                          variant="outlined"
                                        />
                                      );
                                    })}
                                </Stack>
                              </Box>
                              <Box sx={{ px: 6, py: 4, borderTop: 1, borderColor: '#EAECF0', mt: 'auto' }}>
                                <Stack direction="row" justifyContent="end" alignItems="center" spacing={4}>
                                  <Box>
                                    <Button
                                      variant="text"
                                      disableRipple={true}
                                      onClick={() => handleEditData(data?.rulesData)}
                                      className={clsx([
                                        commonClasses.btnNoPadding,
                                        classes.btnGray,
                                        commonClasses.btnTextNoEffect,
                                      ])}
                                      endIcon={<ChevronRight />}
                                    >
                                      Edit
                                    </Button>
                                  </Box>
                                </Stack>
                              </Box>
                            </CardContent>
                          </Card>
                        </Grid>
                      );
                    })
                  ) : (
                    <Box>
                      <NoDataFound title="No data found!" />
                    </Box>
                  )}
                  {!!openRuleModal && (
                    <CommonModal
                      open={!!openRuleModal}
                      onClose={() => toggleModal('')}
                      icon={<ApplicationLeads />}
                      title={`${openRuleModal} Rule`}
                    >
                      <AddRulesModal
                        type={openRuleModal}
                        lenderRulesDetails={lenderRulesDetails}
                        onClose={() => toggleModal('')}
                      />
                    </CommonModal>
                  )}
                </Grid>
                {lenderRuleData?.length > 0 && (
                  <Box className={clsx([classes.paginationCustom, classes.paginationStyle])}>
                    <Pagination
                      onChange={handleChange}
                      color="primary"
                      count={Math.ceil(handleRulesData()?.length / pageSize)}
                      shape="rounded"
                    />
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
          {!globalError && (
            <>
              <AlertMessage
                open={!!error}
                onClose={clearError}
                message={typeof error === 'string' ? error : undefined}
                autoHideDuration={5000}
              />

              <AlertMessage
                open={!!success}
                onClose={() => setSuccess(false)}
                message={typeof success === 'string' ? success : undefined}
                status={AlertMessageStatus.SUCCESS}
              />
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default LenderRules;
