export interface Reminder {
  id: string;
  application_id: string;
  assignee: {
    id: string;
    name: string;
  };
  reminder: string;
  due_date: string;
  done: boolean;
  company_name: string;
}

export interface RemindersState {
  loading: boolean;
  error: string | boolean;
  success: string | boolean;
  reminders: Reminder[];
  total: number;
}

export interface RemindersReturnHook extends RemindersState {
  setError: (error: boolean | string) => void;
  setSuccess: (success: string | boolean) => void;
  getReminders: (query: RemindersQuery) => void;
  updateReminder: (payload: { id: string; done: boolean }) => void;
}

export interface RemindersQuery {
  per_page?: number;
  page?: number;
  start?: string; // date
  end?: string; // date
  assignee_id?: string;
  application_id?: string;
}

export interface RemindersListResponse {
  reminders: Reminder[];
  total: number;
  page: number;
  per_page: number;
}

export interface CreateReminder {
  application_ids: string[];
  assignee_id: string;
  reminder: string;
  due_date: string;
}

export enum IReminderStatus {
  DONE = 'DONE',
  FAILED = 'FAILED',
}
