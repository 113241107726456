import { FC, forwardRef, SyntheticEvent, useEffect } from 'react';
import { Snackbar, SnackbarOrigin } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useTranslation } from 'react-i18next';
import { useAdmin } from 'store/admin/hooks';

import clsx from 'clsx';
import useStyles from './AlertMessage.styles';
import { AlertMessageStatus } from '../../../core/types';
import { ReactComponent as RightIcon } from '../../../assets/icons/right_toast.svg';

const Alert = forwardRef((props: AlertProps, ref) => <MuiAlert ref={ref} elevation={6} variant="filled" {...props} />);

interface AlertMessageProps {
  open: boolean;
  onClose?: () => void;
  title?: string;
  message?: string;
  status?: AlertMessageStatus;
  ignoreClickAway?: boolean;
  autoHideDuration?: number | null;
  anchorOrigin?: SnackbarOrigin;
}

const AlertMessage: FC<AlertMessageProps> = ({
  open,
  title,
  message,
  onClose,
  anchorOrigin = { vertical: 'top', horizontal: 'center' },
  status = AlertMessageStatus.ERROR,
  ignoreClickAway = true,
  autoHideDuration = 6000,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { setSuccess } = useAdmin();

  const handleClose = (event?: SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway' && ignoreClickAway) return;
    if (onClose) {
      onClose();
      setSuccess(false);
    }
  };

  const getAlertMessage = () => {
    if (message) {
      return message;
    }
    if (status === AlertMessageStatus.SUCCESS) {
      return t('global.success');
    }
    return '';
  };

  useEffect(() => {
    setTimeout(() => {
      handleClose();
      setSuccess(false);
    }, 4000);
    // eslint-disable-next-line
  }, []);

  return (
    getAlertMessage() && (
      <Snackbar
        open={open}
        autoHideDuration={autoHideDuration}
        onClose={onClose && handleClose}
        className={clsx([classes.snackbarTheme])}
        anchorOrigin={anchorOrigin}
      >
        <Alert
          icon={status === AlertMessageStatus.SUCCESS ? <RightIcon /> : <ErrorOutlineIcon color="error" />}
          onClose={onClose && handleClose}
          severity={status}
          className={status === AlertMessageStatus.ERROR ? classes.snackbarErrorTheme : classes.snackbarAlertTheme}
        >
          {title && <AlertTitle className={classes.title}>{title}</AlertTitle>}
          <span className={classes.message}>{getAlertMessage()}</span>
        </Alert>
      </Snackbar>
    )
  );
};

export default AlertMessage;
