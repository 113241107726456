import { ApplicationsOfferListResponse, IOfferQuery } from 'store/applications/applicationOffer/types';
import {
  ApplicationDetails,
  ApplicationOverviewItem,
  ApplicationQuery,
  ApplicationsListResponse,
  BasicOffer,
  IApplicationListData,
  OfferOverviewItem,
  OverviewQuery,
} from 'store/applications/types';
import { apiCall } from '../index';

export const getApplications = async (query: ApplicationQuery = {}): Promise<ApplicationsListResponse> => {
  let apiPath = '/applications';
  const queryString = new URLSearchParams(query as Record<string, string>).toString();
  if (queryString) {
    apiPath += `?${queryString}`;
  }
  return apiCall({ method: 'get', url: apiPath });
};

export const getApplicationDetails = async (id: string): Promise<ApplicationDetails> =>
  apiCall({ method: 'get', url: `/applications/${id}` });

export const updateCompanyProfile = async (id: string): Promise<null> =>
  apiCall({ method: 'post', url: `/admin/companies/${id}/company_profile` });

export const getOffersOverview = async (params: OverviewQuery): Promise<OfferOverviewItem[]> =>
  apiCall({ method: 'get', url: '/offers/all', params });

export const getApplicationsOverview = async (params: OverviewQuery): Promise<ApplicationOverviewItem[]> =>
  apiCall({ method: 'get', url: '/applications/all', params });

export const applicationAuth = async (partner_id: string, company_id: string, email: string): Promise<string> => {
  const data = await apiCall<{ access_token: string }>({
    method: 'post',
    url: '/applications/auth',
    data: { partner_id, company_id, email },
  });
  return data.access_token;
};

export const checkForActiveApplication = async ({
  partner_id,
  registered_number,
  email,
}: {
  partner_id: string;
  registered_number?: string;
  email?: string;
}): Promise<null> =>
  apiCall({ method: 'get', url: 'admin/applications/check', params: { partner_id, registered_number, email } });

export const getApplicationDetailsCount = async (applicationId: string) =>
  apiCall({ method: 'get', url: `/applications/${applicationId}/overview` });

export const getAllApplicationOffer = async (
  applicationId: string,
): Promise<{ offers: BasicOffer[]; total: number; page: number; per_page: number }> =>
  apiCall({ method: 'get', url: `/admin/applications/${applicationId}/offers` });

export const getApplicationOfferData = async (
  applicationId: string,
  params: IOfferQuery,
): Promise<ApplicationsOfferListResponse> => {
  return apiCall({ method: 'get', url: `/admin/applications/${applicationId}/offers`, params });
};

export const getAllApplicationData = async (): Promise<IApplicationListData> =>
  apiCall({ method: 'get', url: `/applications` });
