import { ActionReducerMapBuilder, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { AdminState } from './types';
import {
  makeAnOffer,
  clearError,
  setError,
  updateApplicationStatus,
  updateOfferDetails,
  updateOfferStatus,
  rejectApplication,
  uploadLenders,
  introduceLender,
  setSuccess,
  getAdminUsers,
  getRulesEngine,
  setRefreshEmails,
  updateRulesEngine,
  updateAdminUsers,
} from './actions';

const initialState: AdminState = {
  loading: false,
  error: false,
  downloading: false,
  success: false,
  admins: [],
  total: -1,
  rulesEngine: [],
  refreshEmails: false,
};

const adminStore = createSlice({
  name: 'admin',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<AdminState>) => {
    builder.addCase(clearError, (state) => {
      state.error = false;
    });

    builder.addCase(setError, (state, { payload }) => {
      state.error = payload;
    });

    builder.addCase(setSuccess, (state, { payload }) => {
      state.success = payload;
    });

    builder.addCase(getAdminUsers.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.admins = payload.admins;
      state.total = payload.total;
    });

    builder.addCase(getRulesEngine.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.rulesEngine = payload;
    });

    builder.addCase(setRefreshEmails, (state, { payload }) => {
      state.refreshEmails = payload;
    });

    builder.addCase(updateRulesEngine, (state, { payload }) => {
      state.rulesEngine = payload;
    });

    builder.addCase(updateAdminUsers, (state, { payload }) => {
      state.admins = payload;
      state.total = payload.length;
    });

    builder.addMatcher(
      isAnyOf(
        makeAnOffer.pending,
        updateApplicationStatus.pending,
        updateOfferDetails.pending,
        updateOfferStatus.pending,
        rejectApplication.pending,
        uploadLenders.pending,
        introduceLender.pending,
        getAdminUsers.pending,
        getRulesEngine.pending,
      ),
      (state) => {
        state.loading = true;
        state.error = false;
      },
    );

    builder.addMatcher(
      isAnyOf(
        makeAnOffer.rejected,
        updateApplicationStatus.rejected,
        updateOfferDetails.rejected,
        updateOfferStatus.rejected,
        rejectApplication.rejected,
        uploadLenders.rejected,
        introduceLender.rejected,
        getAdminUsers.rejected,
        getRulesEngine.rejected,
      ),
      (state, { error }) => {
        state.loading = false;
        state.error = error?.message || true;
      },
    );

    builder.addMatcher(
      isAnyOf(
        makeAnOffer.fulfilled,
        updateApplicationStatus.fulfilled,
        updateOfferDetails.fulfilled,
        updateOfferStatus.fulfilled,
        rejectApplication.fulfilled,
        uploadLenders.fulfilled,
        introduceLender.fulfilled,
      ),
      (state) => {
        state.loading = false;
      },
    );
  },
});

export default adminStore.reducer;
