import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  inputBox: {
    '& .MuiInputBase-root': {
      height: '46px !important',
    },
  },
  fontWeight_500: {
    fontWeight: '500!important',
  },
  formInlineLblValue_value: {
    position: 'relative',
    width: '35.5rem',
  },
  fontJost: {
    fontFamily: 'jost !important',
  },
  gray_500: {
    color: `${theme.palette.Gray[500]} !important`,
  },
  black_500: {
    color: '#000000',
  },
  inputIconLeft: {
    width: '24px',
    height: '44px',
    display: 'flex',
    alignItems: 'center',
    borderRight: `1px solid #8C92AC`,
  },
  inputLbl: {
    lineHeight: '1.75rem !important',
    marginBottom: '0.5rem !important',
    display: 'block !important',
  },
  errorText: {
    color: '#d32f2f',
    margin: '-3px 0px 0px -13px',
    fontSize: 12,
  },
}));
