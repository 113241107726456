import { ChangeEvent, FC, useState, useEffect } from 'react';
import { FormHelperText, TextareaAutosize } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { LoadingButton } from 'components/buttons';
import { usePartners } from 'store/partners/hooks';
import useStyles from './TrustedDomains.styles';

const DOMAIN_LIST_SEPARATOR = ',';

const getDomainsList = (value: string): string[] => {
  return value.split(DOMAIN_LIST_SEPARATOR).reduce((acc: string[], val: string) => {
    const trimmedVal = val.trim();
    if (trimmedVal) return [...acc, trimmedVal];
    return acc;
  }, []);
};

const getInvalidDomains = (domains: string[]): string[] => {
  const invalidDomains: string[] = [];
  for (let index = 0; index < domains.length; index += 1) {
    const element = domains[index];
    const isValid = /(^((?!-)[A-Za-z0-9-*]{1,63}(?<!-)\.)+[A-Za-z]{2,6}$)|(localhost)/.test(element);
    if (!isValid) {
      invalidDomains.push(element);
    }
  }
  return invalidDomains;
};

const parseRawDomainsValue = (value: string): string => {
  return value.replace(/  +/g, ' ').replace(/([a-zA-Z0-9])(?=(\s)+(?![a-zA-Z0-9]))/g, `$1${DOMAIN_LIST_SEPARATOR}`);
};

interface TrustedDomainsProps {
  initialDomains?: string;
}

const TrustedDomains: FC<TrustedDomainsProps> = ({ initialDomains = '' }) => {
  const [inputError, setInputError] = useState('');
  const [trustedDomains, setTrustedDomains] = useState(initialDomains);
  const classes = useStyles();
  const { t } = useTranslation();
  const { submitLoading, getTrustedDomains, trustedDomains: partnerDomains, saveTrustedDomains } = usePartners();

  useEffect(() => {
    getTrustedDomains();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTrustedDomains(partnerDomains.map((d) => d.domain).join(', '));
  }, [partnerDomains]);

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setTrustedDomains(parseRawDomainsValue(event.target.value));
  };

  const onSubmit = async () => {
    const domains = getDomainsList(trustedDomains);
    setTrustedDomains(domains.join(', '));
    const invalidDomains = getInvalidDomains(domains);

    if (invalidDomains.length) {
      setInputError(t('pages.security.inputs.trustedDomains.error', { domain: invalidDomains.join(', ') }));
      return;
    }

    setInputError('');
    saveTrustedDomains(domains);
  };

  return (
    <form noValidate className={classes.container}>
      <TextareaAutosize
        id="trustedDomains"
        value={trustedDomains}
        aria-label="trusted domains"
        rowsMin={1}
        onChange={handleChange}
        className={clsx([classes.input, inputError && classes.inputError])}
        placeholder={t('pages.security.inputs.trustedDomains.placeholder')}
      />

      {inputError && <FormHelperText error>{inputError}</FormHelperText>}

      <LoadingButton
        variant="contained"
        onClick={onSubmit}
        color="primary"
        disableElevation
        loading={submitLoading}
        containerClassName={classes.submitButton}
      >
        {t('global.buttons.save')}
      </LoadingButton>
    </form>
  );
};

export default TrustedDomains;
