import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import i18n from 'i18next';
import omitBy from 'lodash.omitby';
import isNil from 'lodash.isnil';

import {
  getPartners as getParnersApi,
  createPartner as createPartnerApi,
  getTrustedDomains as getTrustedDomainsApi,
  saveTrustedDomains as saveTrustedDomainsApi,
  deleteTrustedDomain as deleteTrustedDomainApi,
  getPartnerDetails as getPartnerDetailsApi,
  getAllPartners as getAllPartnersApi,
  updatePartner as updatePartnerApi,
} from 'http/partners';
import { ROWS_PER_TABLE_PAGE } from 'core/constants';
import { UserQuery } from 'core/types';
import {
  CreatePartner,
  Partner,
  TrustedDomain,
  PartnersState,
  PartnerDetails,
  UpdatePartner,
  ShortPartner,
} from './types';

export const GET_PARTNERS = 'partners/GET_PARTNERS';
export const GET_PARTNER_DETAILS = 'partners/GET_PARTNER_DETAILS';
export const CREATE_PARTNER = 'partners/CREATE_PARTNER';
export const SET_ERROR = 'partners/SET_ERROR';
export const SET_SUCCESS = 'partners/SET_SUCCESS';
export const GET_TRUSTED_DOMAINS = 'partners/GET_TRUSTED_DOMAINS';
export const SAVE_TRUSTED_DOMAINS = 'partners/SAVE_TRUSTED_DOMAINS';
export const GET_ALL_PARTNERS = 'partners/GET_ALL_PARTNERS';
export const SET_PARTNER_DETAILS = 'partners/SET_PARTNER_DETAILS';
export const UPDATE_PARTNER = 'partners/UPDATE_PARTNER';

export const getAllPartners = createAsyncThunk<ShortPartner[]>(GET_ALL_PARTNERS, async () => {
  const partners = await getAllPartnersApi();
  return partners;
});

export const getPartners = createAsyncThunk<{ partners: Partner[]; total: number }, UserQuery>(
  GET_PARTNERS,
  async (query) => {
    const data = await getParnersApi(omitBy(query, isNil));
    return { partners: data.partners, total: data.total };
  },
);

export const getPartnerDetails = createAsyncThunk<{ partnerDetails: PartnerDetails }, string>(
  GET_PARTNER_DETAILS,
  async (partnerId) => {
    const data = await getPartnerDetailsApi(partnerId);
    return { partnerDetails: data };
  },
);

export const setSuccess = createAction<string | boolean>(SET_SUCCESS);

export const createPartner = createAsyncThunk<void, CreatePartner>(CREATE_PARTNER, async (partner, { dispatch }) => {
  await createPartnerApi(partner);
  dispatch(getPartners({ per_page: ROWS_PER_TABLE_PAGE, page: 1 }));
  const successMesage = i18n.t('pages.partners.admin.success.create');
  dispatch(setSuccess(successMesage));
});

export const setError = createAction<boolean | string>(SET_ERROR);

export const getTrustedDomains = createAsyncThunk<TrustedDomain[]>(GET_TRUSTED_DOMAINS, async () => {
  const domains = await getTrustedDomainsApi();
  return domains;
});

export const saveTrustedDomains = createAsyncThunk<void, string[], { state: { partners: PartnersState } }>(
  SAVE_TRUSTED_DOMAINS,
  async (domains, { dispatch, getState }) => {
    const {
      partners: { trustedDomains },
    } = getState();

    const parsedTrustedDomains = trustedDomains.map((tD) => tD.domain);
    const domainsToSave = domains.filter((d) => !parsedTrustedDomains.includes(d));
    const domainsToDelete = trustedDomains.filter((tD) => !domains.includes(tD.domain));

    if (!domainsToSave.length && !domainsToDelete.length) return;

    await Promise.all([
      ...domainsToSave.map(saveTrustedDomainsApi),
      ...domainsToDelete.map(({ id }) => deleteTrustedDomainApi(id)),
    ]);

    dispatch(getTrustedDomains());

    const successMesage = i18n.t('pages.security.inputs.trustedDomains.success');
    dispatch(setSuccess(successMesage));
  },
);

export const setPartnerDetails = createAction<PartnerDetails | null>(SET_PARTNER_DETAILS);

export const updatePartner = createAsyncThunk<void, UpdatePartner>(
  UPDATE_PARTNER,
  async ({ id, partner }, { dispatch }) => {
    await updatePartnerApi(id, partner);
    dispatch(getPartnerDetails(id));
    const successMesage = i18n.t('pages.partners.admin.success.update');
    dispatch(setSuccess(successMesage));
  },
);
