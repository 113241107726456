import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  site_header: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.customGrey2.main}`,
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(3),
  },
  site_header_left: {
    fontSize: theme.typography.pxToRem(28),
  },
  site_header_right: {
    fontSize: theme.typography.pxToRem(28),
  },
  site_right_header: {
    display: 'flex',
    gap: theme.spacing(4),
  },
  site_header_title: {
    fontSize: theme.typography.pxToRem(28),
    lineHeight: theme.typography.pxToRem(38),
    fontFamily: theme.typography.fontFamily,
    fontWeight: '500',
    margin: '0',
  },
  site_header_toggle_btn: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(4),
    border: `1px solid ${theme.palette.customGrey2.main}`,
    borderRadius: '100px',
  },
  site_header_toggle_btn_text: {
    textTransform: 'uppercase',
    color: theme.palette.darkRed.main,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(21.6),
    marginRight: theme.spacing(2),
    fontFamily: theme.typography.fontFamily,
  },
  border_blue: {
    border: '1px solid #0086C9 !important',
  },
}));

export default useStyles;
