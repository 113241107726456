import { useState, FC } from 'react';
import { Grid } from '@material-ui/core';
import { Box, Typography } from '@mui/material';
import { DocumentUploadCSV } from 'components/DocumentUpload';
import { readLendersCSV } from 'http/admin/lenders.etl.csv';
import { useTranslation } from 'react-i18next';

import clsx from 'clsx';
import FooterButton from 'components/Modal/FooterButton';
import { AlertMessage } from 'components/alerts';
import { AlertMessageStatus } from 'core/types';
import { useAdmin } from 'store/admin/hooks';
import { LendersAction } from 'store/admin/types';
import useStyles from './UploadLendersModal.styles';
import useCommonClasses from '../../../../assets/css/Common.styles';

interface UploadLendersModalProps {
  open: boolean;
  toggleOpen: () => void;
}

const UploadLendersModal: FC<UploadLendersModalProps> = ({ open, toggleOpen }) => {
  const classes = useStyles();
  const commonClasses = useCommonClasses();
  const { t } = useTranslation();
  const { loading, uploadLenders, error, setError, success, setSuccess } = useAdmin(() => {
    if (open) toggleOpen();
  });

  const [csvFile, setCSVFile] = useState<string>('');

  const setCSV = async (csv: string) => {
    setCSVFile(csv);
    return null;
  };

  const uploadDocument = async () => {
    const parsedLenders: LendersAction[] = await readLendersCSV(csvFile);

    if (parsedLenders.length > 0) uploadLenders(parsedLenders);
  };

  return (
    <Box className={clsx([commonClasses.filter_cardBody])}>
      <form noValidate>
        <Grid container direction="column">
          <Grid item className={classes.documentUploadContainer}>
            <Typography
              component="label"
              className={clsx([
                commonClasses.fontJost,
                commonClasses.fontWeight_500,
                commonClasses.gray_500,
                commonClasses.inputLbl,
              ])}
            >
              Bulk upload
            </Typography>

            <DocumentUploadCSV uploadDocument={setCSV} />
            <FooterButton handleSubmit={uploadDocument} loading={loading} btnName="Submit" onClose={toggleOpen} />
          </Grid>
        </Grid>
      </form>

      <AlertMessage
        open={error as boolean}
        onClose={() => setError(false)}
        message={error as string}
        status={AlertMessageStatus.ERROR}
      />

      <AlertMessage
        open={success as boolean}
        onClose={() => setSuccess(false)}
        message={(success as string) || t('global.successMessage')}
        status={AlertMessageStatus.SUCCESS}
        autoHideDuration={5000}
      />
    </Box>
  );
};

export default UploadLendersModal;
