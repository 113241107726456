import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Container, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Box } from '@mui/material';
import { GridRenderCellParams, GridRowParams } from '@mui/x-data-grid';
import clsx from 'clsx';
import { CommonChip } from 'components/CommonChip/CommonChip';
import CustomFilter from 'components/CustomTable/CustomFilter/CustomFilter';
import CustomTable from 'components/CustomTable/CustomTable';
import { Header } from 'components/Header/Header';
import FilterModal from 'components/Modal/FilterModal';
import { AlertMessage } from 'components/alerts';
import { customHeadRender } from 'components/table';
import { PartnerFilterOption, ROWS_PER_TABLE_PAGE } from 'core/constants';
import { AlertMessageStatus, IPartnerFilterData, PartnerQuery, PartnersTableColumnNames } from 'core/types';
import { useDebounce } from 'hooks';
import { getPartners as getPartnersAll } from 'http/partners';
import {
  SEARCH_DEBOUNCE_DELAY,
  downloadCSVFile,
  getParamsValue,
  statueReplaceBooleanData,
} from 'pages/leads/components/CreateApplicationModal/utils';
import routes from 'router/routes';
import { useGlobalState } from 'store/global/hooks';
import { usePartners } from 'store/partners/hooks';
import useCommonStyles from '../../assets/css/Common.styles';
import { PartnerAdminActions } from './components';

const Partners: FC = () => {
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>('');
  const debouncedSearchTerm = useDebounce(search, SEARCH_DEBOUNCE_DELAY);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [tabValue, setTabValue] = useState<number | string>(0);
  const [partnerFilterData, setPartnerFilterData] = useState<IPartnerFilterData>({
    status: [],
  });
  const [showPartnerFilterData, setShowPartnerFilterData] = useState<IPartnerFilterData>({
    status: [],
  });
  const [queryData, setQueryData] = useState({
    active: '',
    per_page: ROWS_PER_TABLE_PAGE,
  });

  const history = useHistory();
  const { t } = useTranslation();
  const commonClasses = useCommonStyles();
  const { error: globalError } = useGlobalState();
  const { loading, partners, total, error, success, setSuccess, setError, getPartners } = usePartners();

  const toggleFilterModal = () => setOpenFilterModal(!openFilterModal);

  const handleFilterData = (event: ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.checked) {
      setPartnerFilterData({
        ...partnerFilterData,
        status: [event?.target?.name],
      });
    } else {
      setPartnerFilterData({
        ...partnerFilterData,
        status: partnerFilterData?.status?.filter((d: string) => d !== event?.target?.name),
      });
    }
  };

  const handleRemoveFilter = (data: string, name: string) => {
    const values: IPartnerFilterData = {
      ...partnerFilterData,
      [name]: partnerFilterData[name as keyof typeof partnerFilterData]?.filter((d: string) => d !== data),
    };
    setQueryData({
      ...queryData,
      active: statueReplaceBooleanData(values?.status)?.join(','),
    });
    setShowPartnerFilterData(values);
    setPartnerFilterData(values);
  };

  const handleApplyFilter = () => {
    setQueryData({
      ...queryData,
      active: statueReplaceBooleanData(partnerFilterData?.status)?.join(','),
    });
    setShowPartnerFilterData(partnerFilterData);
    toggleFilterModal();
  };

  const columns = useMemo(
    () => [
      {
        width: 300,
        field: PartnersTableColumnNames.PARTNER_NAME,
        headerName: t('pages.partners.table.columns.partnerName'),
        options: {
          customHeadRender,
        },
      },
      {
        width: 300,
        field: PartnersTableColumnNames.USER_NAME,
        headerName: t('pages.partners.table.columns.userName'),
        options: {
          customHeadRender,
        },
      },
      {
        width: 300,
        field: PartnersTableColumnNames.EMAIL,
        headerName: t('pages.partners.table.columns.email'),
        options: {
          customHeadRender,
        },
      },
      {
        width: 300,
        field: PartnersTableColumnNames.ROLE,
        headerName: t('pages.partners.table.columns.role'),
      },
      {
        width: 300,
        field: PartnersTableColumnNames.ENABLED,
        headerName: t('pages.partners.table.columns.enabled'),
        renderCell: (params: GridRenderCellParams) => {
          return (
            <span
              className={clsx([
                commonClasses.badge,
                params?.row?.active ? commonClasses.accepted : commonClasses.rejected,
              ])}
            >
              {params?.row?.active ? 'Yes' : 'No'}
            </span>
          );
        },
      },
    ],
    [t, commonClasses],
  );

  const partnerQuery: PartnerQuery = useMemo(
    () => ({
      page,
      ...getParamsValue({ ...queryData, search: debouncedSearchTerm }),
    }),
    [page, queryData, debouncedSearchTerm],
  );

  const downloadCSV = async () => {
    await getPartnersAll({}).then((data) => {
      const partnerData = data?.partners?.map((d) => ({
        'Partner Name': d?.name,
        'Contact Name': d?.contact_person_name,
        'Contact Email': d?.contact_person_email,
        Role: d?.role || '-',
        Enabled: d?.active ? 'Yes' : 'No',
      }));
      downloadCSVFile(partnerData, 'BScore_partner.csv');
    });
  };

  const getAllPartnerData = useCallback(() => {
    getPartners(partnerQuery);
  }, [getPartners, partnerQuery]);

  useEffect(() => {
    getAllPartnerData();
  }, [getAllPartnerData]);

  return (
    <Container disableGutters maxWidth="xl">
      <Header name="Partners" />
      <Box className={commonClasses.mainWrapper}>
        <Box className={commonClasses.filterMenu}>
          <PartnerAdminActions downloadCSV={downloadCSV} />
        </Box>
        <Box className={commonClasses.cardShadow}>
          <Grid item xs={12} className={clsx([commonClasses.tableContainer])}>
            <CustomFilter
              downloadCSV={downloadCSV}
              toggleFilterModal={toggleFilterModal}
              total={total}
              page={page}
              searchPlaceholder="Search by partner name"
              pageSize={ROWS_PER_TABLE_PAGE}
              setPage={setPage}
              setSearch={setSearch}
              search={search}
            />
            <CommonChip data={showPartnerFilterData} handleRemoveFilter={handleRemoveFilter} />
            <CustomTable
              rows={partners}
              loading={loading}
              columns={columns}
              onRowClick={(item: GridRowParams) => {
                if (item?.id) {
                  history.push(`${routes.partner}/${item?.id}`);
                }
              }}
              headerUpperCase={true}
              hideFooterPagination={true}
              NoDataFountMessage="No Data Found"
              disableColumnMenu={true}
            />
          </Grid>
          {openFilterModal && (
            <FilterModal
              setValue={setTabValue}
              value={+tabValue}
              filterTabName={['Status']}
              open={openFilterModal}
              onClose={toggleFilterModal}
              filterOption={PartnerFilterOption}
              width="width25"
              handleApplyFilter={handleApplyFilter}
              filterData={partnerFilterData}
              handleFilterData={handleFilterData}
            />
          )}

          {!globalError && (
            <>
              <AlertMessage
                open={!!error}
                onClose={() => setError(false)}
                message={typeof error === 'string' ? error : undefined}
                autoHideDuration={5000}
              />

              <AlertMessage
                open={!!success}
                onClose={() => setSuccess(false)}
                message={typeof success === 'string' ? success : undefined}
                autoHideDuration={5000}
                status={AlertMessageStatus.SUCCESS}
              />
            </>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default Partners;
