import { useMemo, useCallback } from 'react';
import { Box, TableFooter } from '@material-ui/core';
import MUIDataTable, {
  SelectableRows,
  FilterType,
  MUIDataTableColumnDef,
  MUIDataTableState,
  MUIDataTableProps,
  MUIDataTableTextLabels,
} from 'mui-datatables';

import { ROWS_PER_TABLE_PAGE } from 'core/constants';
import useStyles from './Table.styles';
import { PaginationFooter } from '../PaginationFooter';

const customFooter = (
  rowCount: number,
  page: number,
  rowsPerPage: number,
  changeRowsPerPage: (page: string | number) => void,
  changePage: (newPage: number) => void,
  textLabels: Partial<MUIDataTableTextLabels>,
  pagination: boolean,
) => {
  if (!pagination) return <TableFooter />;
  return (
    <PaginationFooter
      rowCount={rowCount}
      page={page}
      rowsPerPage={rowsPerPage}
      changeRowsPerPage={changeRowsPerPage}
      changePage={changePage}
    />
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface DataItem extends Record<string, any> {
  id: string;
}

interface TableProps<T extends DataItem> {
  columns: MUIDataTableColumnDef[];
  data: T[];
  onItemClick?: (itemId: string, colIndex: number) => void;
  rowsPerPage?: number;
  onTableChange?: (action: string, tableState: MUIDataTableState) => void;
  page?: number;
  count?: number;
  pagination?: boolean;
}

const Table = <T extends DataItem>({
  columns,
  data,
  onItemClick,
  rowsPerPage,
  onTableChange,
  page,
  count,
  pagination,
}: TableProps<T>): JSX.Element => {
  const classes = useStyles();

  const onCellClick = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (colData: any, cellMeta: any) => {
      const rowData = data[cellMeta.dataIndex];
      if (rowData && onItemClick) {
        onItemClick(rowData.id, cellMeta.colIndex);
      }
    },
    [data, onItemClick],
  );

  const options = useMemo(() => {
    const tableOptions: MUIDataTableProps['options'] = {
      rowsPerPage,
      selectableRows: 'none' as SelectableRows,
      download: false,
      print: false,
      filterType: 'dropdown' as FilterType,
      filter: false,
      viewColumns: false,
      search: false,
      elevation: 0,
      responsive: 'simple',
      page,
      pagination,
      customToolbarSelect: () => null,
      fixedSelectColumn: false,
    };
    tableOptions.customFooter = (...args) => customFooter(...args, !!pagination);
    if (onCellClick) tableOptions.onCellClick = onCellClick;
    if (onTableChange) {
      tableOptions.onTableChange = onTableChange;
      tableOptions.serverSide = true;
      if (typeof count !== 'number') tableOptions.count = -1;
      else tableOptions.count = count;
    }
    return tableOptions;
  }, [count, onCellClick, onTableChange, page, pagination, rowsPerPage]);

  return (
    <Box className={classes.container}>
      <MUIDataTable columns={columns} data={data} options={options} title="" />
    </Box>
  );
};

Table.defaultProps = {
  onItemClick: undefined,
  rowsPerPage: ROWS_PER_TABLE_PAGE,
  onTableChange: undefined,
  page: 0,
  count: undefined,
  pagination: true,
};

export default Table;
