import { Box, Grid } from '@mui/material';
import CustomButton from 'components/CustomButton/Button';
import useStyles from './CommonModal.styles';

const FooterButton = ({
  onClose,
  handleSubmit,
  loading = false,
  btnName,
  isShowCancelBtn = true,
  isShowFilterBtn = false,
}: {
  loading?: boolean;
  btnName: string;
  onClose?: () => void;
  handleSubmit?: () => void;
  isShowCancelBtn?: boolean;
  isShowFilterBtn?: boolean;
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.modalFooter}>
      <Grid className={classes.flexWithGap}>
        {isShowCancelBtn && (
          <Grid item xs={12} md={6} width={isShowFilterBtn ? '50%' : '100%'}>
            <CustomButton onClick={onClose || (() => {})} className="white">
              Cancel
            </CustomButton>
          </Grid>
        )}
        <Grid item xs={12} md={isShowCancelBtn ? 6 : 12} width={isShowFilterBtn ? '50%' : '100%'}>
          <CustomButton onClick={handleSubmit || (() => {})} loading={loading} disabled={loading}>
            {btnName}
          </CustomButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FooterButton;
