import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  snackbarTheme: {
    borderRight: '0px solid #8C92AC',
  },
  snackbarAlertTheme: {
    padding: `${theme.spacing(4)} !important`,
    backgroundColor: `${theme.palette.Blue[25]} !important`,
    color: theme.palette.Blue[600],
    border: `2px solid ${theme.palette.Blue[500]}`,
    borderRadius: '0.5rem !important',
    '& .MuiAlert-icon': {
      color: `${theme.palette.Blue[700]} !important`,
    },
    '& .MuiAlert-message': {
      color: `${theme.palette.Blue[700]} !important`,
      fontFamily: 'Jost',
      fontWeight: 500,
      fontSize: `${theme.typography.pxToRem(18)} !important`,
    },
  },
}));
