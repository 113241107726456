import { Grid } from '@mui/material';
import ReactApexChart from 'react-apexcharts';
import useStyles from './ReactChart.styles';

interface IReactChart {
  series?: number[];
  colors?: string[];
  labels?: string[];
  title?: string;
  position?: 'right' | 'left' | 'top' | 'bottom';
  type?: string;
  xaxisCategories?: number[] | string[];
  yaxisTitle?: string;
  isShowTooltip?: boolean;
}

const ReactChart = ({
  series,
  colors,
  labels,
  title,
  position,
  type = 'dount',
  xaxisCategories = [],
  yaxisTitle,
  isShowTooltip = false,
}: IReactChart) => {
  const classes = useStyles();
  return (
    <Grid>
      {type === 'dount' ? (
        <ReactApexChart
          options={{
            chart: {
              type: 'donut',
            },
            dataLabels: {
              enabled: false,
            },
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200,
                  },
                  legend: {
                    show: false,
                  },
                },
              },
            ],
            legend: {
              position,
              fontSize: '15',
            },
            plotOptions: {
              pie: {
                expandOnClick: false,
                donut: {
                  size: '60%',
                },
              },
            },
            title: {
              text: title,
              style: {
                color: '#475467',
              },
              align: 'left',
            },
            labels,
            colors,
          }}
          series={series}
          height={600}
          className={classes.customLegend}
          type="donut"
        />
      ) : (
        <ReactApexChart
          options={{
            chart: {
              height: 50,
              width: 1,
              type: 'line',
              toolbar: {
                show: false,
              },
            },
            ...(isShowTooltip && {
              tooltip: {
                custom: ({ series: seriesData, seriesIndex, dataPointIndex, w }) => {
                  return `<div className="tooltip_div">
                  <div>Date : ${w.globals.categoryLabels[dataPointIndex]}</div>
                  <div>Amount :  ${seriesData[seriesIndex][dataPointIndex]}</div>
                  </div>`;
                },
              },
            }),
            dataLabels: {
              enabled: false,
            },
            stroke: {
              width: 2,
              curve: 'smooth',
            },
            title: {
              align: 'left',
              text: title,
            },
            grid: {
              row: {
                opacity: 0.5,
              },
            },
            xaxis: {
              categories: xaxisCategories,
            },
            yaxis: {
              title: {
                text: yaxisTitle,
                style: {
                  color: '#667085',
                  fontSize: '12px',
                  fontFamily: 'Inter',
                  fontWeight: 500,
                },
              },
            },
            colors: colors as string[],
          }}
          series={[{ data: series || [], name: 'count' }]}
          type="area"
          height={350}
        />
      )}
    </Grid>
  );
};

export default ReactChart;
