import { Box, Button, FormControlLabel, Grid } from '@mui/material';
import { MaterialUISwitch } from 'pages/home/Home.styles';
import { useState } from 'react';
import { CreateApplicationModal } from 'pages/leads/components/CreateApplicationModal';
import { AlertMessage } from 'components/alerts';
import { AlertMessageStatus } from 'core/types';
import useStyles from './Header.styles';

export const Header = ({
  name,
  isShowTestMode = true,
  isShowSendBtn = true,
}: {
  name?: string;
  isShowTestMode?: boolean;
  isShowSendBtn?: boolean;
}) => {
  const [showMessage, setShowMessage] = useState<{ message: string; open: boolean }>({ message: '', open: false });
  const [typeOpenModal, setTypeOpenModal] = useState('');
  const classes = useStyles();
  const toggleApplicationModal = () => setTypeOpenModal(typeOpenModal ? '' : typeOpenModal);
  const production = (process.env.REACT_APP_STAGE as string) !== 'production';

  const updateShowMessage = (newMessage: string, newOpen: boolean) => {
    setShowMessage({ message: newMessage, open: newOpen });
    setTimeout(() => {
      setShowMessage({ message: '', open: false });
    }, 4000);
  };

  return (
    <Box className={classes.site_header}>
      <Box className={classes.site_header_left}>
        <h4 className={classes.site_header_title}>{name}</h4>
      </Box>

      {isShowSendBtn && (
        <Grid className={classes.site_right_header}>
          {production && isShowTestMode && (
            <Box className={classes.site_header_right}>
              <Box className={classes.site_header_toggle_btn}>
                <Box className={classes.site_header_toggle_btn_text}>Test Mode</Box>
                <FormControlLabel control={<MaterialUISwitch defaultChecked={false} />} label="" />
              </Box>
            </Box>
          )}
          <Button
            variant="outlined"
            size="medium"
            onClick={() => setTypeOpenModal('send')}
            className="white"
            style={{ marginRight: '10px' }}
          >
            Send an invite link
          </Button>
          <Button
            variant="contained"
            size="medium"
            onClick={() => setTypeOpenModal('create')}
            className={`${classes.border_blue} blue`}
          >
            Start a new application
          </Button>
        </Grid>
      )}
      {showMessage?.open && (
        <AlertMessage open={!!showMessage?.open} status={AlertMessageStatus?.SUCCESS} message={showMessage?.message} />
      )}
      {!!typeOpenModal && (
        <CreateApplicationModal
          open={!!typeOpenModal}
          sendLink={typeOpenModal === 'send'}
          toggleOpen={toggleApplicationModal}
          showMessage={updateShowMessage}
        />
      )}
    </Box>
  );
};
