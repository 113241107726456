import { useLenders } from 'store/lenders/hooks';
import { ChangeEvent, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import clsx from 'clsx';
import * as yup from 'yup';
import CustomSelectBox from 'components/CustomSelect/CustomSelect';
import { useTranslation } from 'react-i18next';
import { useApplications } from 'store/applications/hooks';
import { OfferProductType } from 'store/admin/types';
import FooterButton from 'components/Modal/FooterButton';
import { offerProductTypesOptions } from 'core/constants';
import { useAdmin } from 'store/admin/hooks';
import { Formik } from 'formik';
import useStyles from './SendApplications.styles';
import useCommonStyles from '../../../../assets/css/Common.styles';

interface FormValues {
  lenderId: string | undefined;
  productType: string | undefined;
}

const SendApplications = ({ onClose }: { onClose: (val: string) => void }) => {
  const commonClasses = useCommonStyles();
  const classes = useStyles();
  const { t } = useTranslation();

  const initialValues: FormValues = {
    lenderId: '',
    productType: '',
  };

  const { loading, introduceLender, setError } = useAdmin();
  const { allLenders } = useLenders();
  const { applicationDetails } = useApplications();

  const applicationLeadsSchema = yup.object({
    lenderId: yup.string().required(t('pages.lead.admin.makeOffer.inputs.lenderId.required')).nullable(),
    productType: yup.string().required(t('pages.lead.admin.applicationLeads.inputs.productTypeId.required')).nullable(),
  });

  const onSubmit = async (values: FormValues) => {
    try {
      if (applicationDetails?.id && values.lenderId && values.productType) {
        await introduceLender({
          id: applicationDetails.id,
          lenderId: values.lenderId,
          productType: values.productType as OfferProductType,
        });
        onClose('');
      }
    } catch (error) {
      setError((error as Error)?.message || true);
      onClose('');
    }
  };

  const lendersOptions = useMemo(() => {
    const emptyOption = [{ label: '', value: '' }];
    if (allLenders.length > 0) {
      return emptyOption.concat(allLenders.map((lender) => ({ label: lender.displayed_name, value: lender.id })));
    }
    return emptyOption;
  }, [allLenders]);

  return (
    <Formik initialValues={initialValues} validationSchema={applicationLeadsSchema} onSubmit={onSubmit}>
      {({ handleSubmit, values, setFieldValue }) => {
        return (
          <Box className={clsx([commonClasses.filter_cardBody])}>
            <Typography
              variant="text_SM"
              className={clsx([commonClasses.fontJost, classes.modalTitleSub, commonClasses.gray_500])}
            >
              {t('pages.lead.admin.applicationLeads.subtitle')}
            </Typography>
            <Box className={clsx([commonClasses.formLblValue])}>
              <CustomSelectBox
                name="lenderId"
                onChange={(event: ChangeEvent<{ value: unknown }>) => {
                  setFieldValue('lenderId', event.target.value);
                }}
                placeholder="Select Lander"
                label={t('pages.lead.admin.applicationLeads.inputs.lenderId.label')}
                options={lendersOptions || []}
                value={values?.lenderId || ''}
              />
            </Box>
            <Box className={clsx([commonClasses.formLblValue])}>
              <CustomSelectBox
                name="productType"
                placeholder="Select Product type"
                onChange={(event: ChangeEvent<{ value: unknown }>) => {
                  setFieldValue('productType', event.target.value);
                }}
                value={values?.productType || ''}
                label={t('pages.lead.admin.applicationLeads.inputs.productTypeId.label')}
                options={offerProductTypesOptions}
              />
            </Box>
            <FooterButton
              handleSubmit={handleSubmit}
              onClose={() => onClose('')}
              loading={loading}
              btnName={t('pages.lead.admin.applicationLeads.buttons.confirm')}
            />
          </Box>
        );
      }}
    </Formik>
  );
};

export default SendApplications;
