import { WidgetFormConfig, WidthType } from 'core/types';

export const parseSavedWidgetConfig = (configuration: Record<string, unknown>): WidgetFormConfig => {
  const { width, height, popup, ...rest } = configuration;

  let parsedWidth = 100;
  let widthType = WidthType.vw;
  let parsedHeight = 1000;
  let fullWidth = true;

  if (width) {
    if (width !== '100vw') fullWidth = false;
    const widthMatchResult = (width as string).match(/(\d+)([^\d]+)/);
    if (widthMatchResult) {
      parsedWidth = Number(widthMatchResult[1]);
      widthType = widthMatchResult[2] as WidthType.px;
    }
  }

  if (height) {
    const heightMatchResult = (height as string).match(/(\d+)([^\d]+)/);
    if (heightMatchResult) {
      parsedHeight = Number(heightMatchResult[1]);
    }
  }

  return {
    ...rest,
    width: parsedWidth,
    widthType,
    height: parsedHeight,
    fullWidth,
    popupEnabled: !!popup,
  } as WidgetFormConfig;
};
