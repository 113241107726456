import { createSlice, ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { RemindersState } from './types';
import { setError, setSuccess, getReminders, updateReminders } from './actions';

const initialState: RemindersState = {
  loading: false,
  error: false,
  success: false,
  reminders: [],
  total: -1,
};

const remindersStore = createSlice({
  name: 'reminders',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<RemindersState>) => {
    builder.addCase(setError, (state, { payload }) => {
      state.error = payload;
    });

    builder.addCase(setSuccess, (state, { payload }) => {
      state.success = payload;
    });

    builder.addCase(updateReminders, (state, { payload }) => {
      state.reminders = payload;
    });

    builder.addCase(getReminders.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.reminders = payload.reminders;
      state.total = payload.total;
    });

    builder.addCase(getReminders.pending, (state) => {
      state.loading = true;
      state.error = false;
    });

    builder.addCase(getReminders.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error?.message || true;
    });
  },
});

export default remindersStore.reducer;
