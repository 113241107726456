import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, CircularProgress, List, ListItem, ListItemIcon, Typography } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import { CompanyDocumentType } from 'core/types';
import { mapCompanyDocumentTypeToLabel } from 'core/utils';
import { useAdmin } from 'store/admin/hooks';
import { CompanyDocument } from 'store/applications/types';
import { useAuth } from 'store/auth/hooks';
import { downloadMergedDocument as downloadMergedDocumentApi } from 'http/admin';
import useStyles from '../../../LeadDetails/LeadDetails.styles';
import { ReactComponent as NoFoundData } from '../../../../../../assets/icons/no_data_found.svg';

interface MergedDocumentsProps {
  title?: string;
  appId: string;
  documents: CompanyDocument[];
}

const MergedDocumentList: FC<MergedDocumentsProps> = ({ title, appId, documents }) => {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const { isAdmin, isLenderAdmin } = useAuth();
  const { setError } = useAdmin();

  const downloadMergedDocument = useCallback(
    async (docType: CompanyDocumentType, name: string) => {
      setLoading(true);
      try {
        const blob = await downloadMergedDocumentApi(appId, docType);
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = name;
        link.click();
      } catch (err) {
        setError((err as Error)?.message || true);
      }
      setLoading(false);
    },
    [appId, setError],
  );

  const mergedDocuments = useMemo(() => {
    const mergeDocsTypes: CompanyDocumentType[] = [];
    documents.forEach((cD) => {
      const cDType = cD.type as CompanyDocumentType;
      if (!mergeDocsTypes.includes(cDType)) mergeDocsTypes.push(cDType);
    });
    return mergeDocsTypes.map((type) => ({ type, label: mapCompanyDocumentTypeToLabel(type) }));
  }, [documents]);

  const isAdminUser = isAdmin || isLenderAdmin;

  return (
    <>
      {title && <Typography className={classes.uploadDocTitle}>{title}</Typography>}
      <Box className={classes.documentsContainer}>
        {documents.length >= 1 ? (
          <List>
            {mergedDocuments.map((mergedDoc, idx) => (
              // eslint-disable-next-line react/no-array-index-key
              <ListItem className={classes.horizontalItem} key={`${mergedDoc.type}-${idx}`}>
                <ListItemIcon className={classes.horizontalItemValue}>
                  <Button
                    className={classes.actionButton}
                    variant="outlined"
                    color="default"
                    onClick={() => downloadMergedDocument(mergedDoc.type, mergedDoc.label)}
                    startIcon={isAdminUser ? <GetApp /> : undefined}
                    disabled={!isAdminUser}
                  >
                    {mergedDoc.label}
                  </Button>
                </ListItemIcon>
              </ListItem>
            ))}
          </List>
        ) : (
          <Box>
            <Box className={classes.flexSpaceCenter}>
              <NoFoundData />
            </Box>
            <Box style={{ textAlign: 'center' }}>{t('pages.lead.sections.documents.notAvailable')}</Box>
          </Box>
        )}

        {loading && (
          <Box className={classes.documentLoaderContainer}>
            <CircularProgress color="primary" />
          </Box>
        )}
      </Box>
    </>
  );
};

export default MergedDocumentList;
