import { Box, FormControlLabel, Grid } from '@mui/material';
import { Header } from 'components/Header/Header';
import CustomTable from 'components/CustomTable/CustomTable';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { useAuth } from 'store/auth/hooks';
import { useTranslation } from 'react-i18next';
import { getRuleParsedValue } from 'utils';
import { mapRuleFactToLabel, mapRuleOperatorToLabel } from 'core/utils';
import { IOSSwitch } from 'pages/home/Home';
import { updateRule as updateRuleApi } from 'http/admin';
import { RuleEngineRule } from 'store/admin/types';
import { useAdmin } from 'store/admin/hooks';
import { useParams } from 'react-router-dom';
import { AlertMessage } from 'components/alerts';
import { useGlobalState } from 'store/global/hooks';
import { AlertMessageStatus } from 'core/types';
import CommonModal from 'components/Modal/CommonModal';
import { ActionRuleModal } from 'pages/rules-engine/components/ActionRuleModal';

import { FC, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import useCommonClasses from '../../../../assets/css/Common.styles';
import useStyles from './AddRulesModal.styles';
import { ReactComponent as EditIcon } from '../../../../assets/icons/editBlue.svg';
import { ReactComponent as RuleBoxIcon } from '../../../../assets/icons/application_offers.svg';

const LenderRulesTable: FC = () => {
  /* hooks */
  const { error: globalError } = useGlobalState();
  const { isAdmin } = useAuth();
  const lender = useParams<{ id: string }>();
  const classes = useStyles();
  const { rulesEngine, getRulesEngine, success, error, setSuccess, setError } = useAdmin();
  const { t } = useTranslation();
  const commonClasses = useCommonClasses();
  /* states */
  const [selectedRule, setSelectedRule] = useState<RuleEngineRule | null>(null);

  const parsedRules = useMemo(() => {
    let rules = rulesEngine.filter((val) => val.lender_id === lender.id);

    rules = rules
      .map((data) => {
        if (data.lender_name === 'Fleximize' && Array.isArray(data.rules)) {
          const flattenedData = [];

          flattenedData.push({
            ...data,
            isParent: !!data?.rules.length,
          });

          data.rules.forEach((child) => {
            flattenedData.push({
              ...child,
              isParent: false,
            });
          });

          return flattenedData;
        }
        return data;
      })
      .flat();

    return rules;
  }, [rulesEngine, lender.id]);

  const toggleActiveRule = async (checked: boolean, row: RuleEngineRule) => {
    try {
      const findRule = rulesEngine?.find((d) => d?.id === row?.id);
      await updateRuleApi(row?.id, { active: checked });
      if (findRule?.id) {
        const updatePromises = [];
        for (let i = 0; i < (findRule?.rules?.length || 0); i += 1) {
          if (findRule && findRule?.rules?.length && findRule?.rules[i]?.id) {
            updatePromises.push(updateRuleApi(findRule?.rules[i]?.id, { active: checked }));
          }
        }
        await Promise.all(updatePromises);
      }
      getRulesEngine({});
      setSuccess(t('pages.rulesEngine.messages.updateSuccess') as string);
    } catch (errors) {
      setError((errors as Error).message ?? true);
    }
  };
  const column = [
    {
      width: 300,
      field: 'fact',
      headerName: t('pages.rulesEngine.rules.table.fact'),
      renderCell: (params: GridRenderCellParams) => {
        return mapRuleFactToLabel(params?.row?.fact, params?.row?.criteria) || '-';
      },
    },
    {
      width: 300,
      field: 'operator',
      headerName: t('pages.rulesEngine.rules.table.operator'),
      renderCell: (params: GridRenderCellParams) => {
        return mapRuleOperatorToLabel(params?.row?.operator) || '-';
      },
    },
    {
      width: 300,
      field: 'value',
      headerName: t('pages.rulesEngine.rules.table.value'),
      renderCell: (params: GridRenderCellParams) => {
        return getRuleParsedValue(params?.row?.operator, params?.row?.value, params?.row?.criteria) || '-';
      },
    },
    {
      width: 300,
      field: 'active',
      headerName: t('pages.rulesEngine.rules.table.status'),
      renderCell: (params: GridRenderCellParams) => {
        const disable = false;
        return (
          <Box className={classes.switchContainer}>
            <FormControlLabel
              control={
                <IOSSwitch
                  disabled={disable}
                  checked={params?.row?.active}
                  onChange={(e) => toggleActiveRule(e?.target?.checked, params?.row)}
                />
              }
              label=""
            />
            {params?.row?.active ? t('global.states.enabled') : t('global.states.disabled')}
          </Box>
        );
      },
    },
    {
      width: 300,
      field: 'actions',
      headerName: t('pages.rulesEngine.rules.table.action'),
      renderCell: (params: GridRenderCellParams) => {
        const onClick = () => {
          if (params?.row?.id) setSelectedRule(params?.row);
        };
        return (
          <Box
            onClick={onClick}
            className={clsx([commonClasses.primary_600, commonClasses.flexWithGap04, commonClasses.cursorPointer])}
          >
            Edit value <EditIcon />
          </Box>
        );
      },
    },
  ];
  useEffect(() => {
    getRulesEngine({});
  }, [getRulesEngine]);

  return (
    <>
      <Header name="Rules Details" />
      <Box sx={{ p: 6 }}>
        <Box sx={{ mt: 4 }} className={classes.tableContainer}>
          <Grid item xs={12} md={8}>
            <CustomTable
              rows={parsedRules ?? []}
              headerUpperCase={true}
              columns={isAdmin ? column : column?.slice(0, 4)}
              hideFooterPagination={true}
              NoDataFountMessage="You don’t have any Rules defined"
              disableColumnMenu={true}
            />
          </Grid>
        </Box>
      </Box>
      {selectedRule && (
        <CommonModal
          title={t('pages.rulesEngine.modal.title')}
          open={!!selectedRule}
          onClose={() => setSelectedRule(null)}
          icon={<RuleBoxIcon />}
        >
          <ActionRuleModal toggleOpen={() => setSelectedRule(null)} rule={selectedRule} />
        </CommonModal>
      )}

      {!globalError && (
        <>
          <AlertMessage
            open={!!error}
            onClose={() => setError(false)}
            message={typeof error === 'string' ? error : undefined}
            autoHideDuration={5000}
          />

          <AlertMessage
            open={!!success}
            onClose={() => setSuccess(false)}
            message={typeof success === 'string' ? success : undefined}
            autoHideDuration={5000}
            status={AlertMessageStatus.SUCCESS}
          />
        </>
      )}
    </>
  );
};

export default LenderRulesTable;
