import { Box } from '@mui/material';
import { ErrorMessage } from 'formik';
import useStyle from './ErrorMessage.styles';

const ErrorMeg = ({ name }: { name: string }) => {
  const classes = useStyle();
  return <ErrorMessage name={name}>{(msg) => <Box className={classes.errorText}>{msg}</Box>}</ErrorMessage>;
};

export default ErrorMeg;
