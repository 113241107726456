import { createAsyncThunk, createAction, Dispatch } from '@reduxjs/toolkit';

import {
  getApplications as getApplicationsApi,
  getApplicationDetails as getApplicationDetailsApi,
  getOffersOverview as getOffersOverviewApi,
  getApplicationsOverview as getApplicationsOverviewApi,
} from 'http/applications';
import { setAppFilters } from 'utils';
import { RequestError } from 'http/types';
import { getErrorMessage } from 'http/utils';
import { OverviewDateRange, UserRole } from 'core/types';
import { AuthState } from 'store/auth/types';
import { OfferProductType } from 'store/admin/types';
import {
  ApplicationDetails,
  ApplicationsListResponse,
  ApplicationsState,
  ApplicationsQueryFilters,
  OverviewQuery,
  OfferOverviewItem,
  ApplicationOverviewItem,
  LenderOffer,
} from './types';
import { getApplicationsQuery } from './utils';

export const GET_APPLICATIONS = 'applications/GET_APPLICATIONS';
export const GET_APPLICATION_DETAILS = 'applications/GET_APPLICATION_DETAILS';
export const CLEAR_APPLICATION_DETAILS = 'applications/CLEAR_APPLICATION_DETAILS';
export const UPDATE_APPLICATIONS_QUERY = 'applications/UPDATE_APPLICATIONS_QUERY';
export const SET_APPLICATIONS_SEARCH_TERM = 'applications/SET_APPLICATIONS_SEARCH_TERM';
export const GET_APPLICATIONS_OVERVIEW = 'applications/GET_APPLICATIONS_OVERVIEW';
export const UPDATE_APPLICATION_DETAILS = 'applications/UPDATE_APPLICATION_DETAILS';
export const CLEAR_APPLICATIONS_FILTERS = 'applications/CLEAR_APPLICATIONS_FILTERS';
export const SET_DATE_RANGE = 'applications/SET_DATE_RANGE';
export const GET_OFFERS_OVERVIEW = 'applications/GET_OFFERS_OVERVIEW';

export const getApplications = createAsyncThunk<
  ApplicationsListResponse,
  undefined,
  { state: { applications: ApplicationsState } }
>(GET_APPLICATIONS, async (_, { getState }) => {
  const { applications } = getState();
  const appQuery = getApplicationsQuery(applications.filters);
  setAppFilters(applications.filters);
  const data = await getApplicationsApi(appQuery);
  return data;
});

export const getApplicationDetails = createAsyncThunk<ApplicationDetails, string, { state: { auth: AuthState } }>(
  GET_APPLICATION_DETAILS,
  async (id, { getState }) => {
    try {
      const applicationDetails = await getApplicationDetailsApi(id);
      const {
        auth: { role },
      } = getState();

      if (role !== UserRole.COMPANY_OWNER) return applicationDetails;

      const offers = applicationDetails.offers.reduce((acc: LenderOffer[], offer: LenderOffer): LenderOffer[] => {
        if (offer.product_type !== OfferProductType.REVENUE_BASED) {
          const ratesOffers =
            offer.rates.map((rate) => ({
              ...offer,
              rate_id: rate.rate_id,
              loan_amount: rate.principal,
              interest_rate: rate.interest_rate,
              selected: rate.selected,
              unique_id: rate.rate_id,
              monthly_repayment: rate.monthly_repayment,
              monthly_repayment_type: rate.monthly_repayment_type,
              total_repayment: rate.total_repayment,
            })) ?? [];
          return [...acc, ...ratesOffers];
        }
        const revenueRepaymentOffers =
          offer.revenueRepayments.map((revenueRepayment) => ({
            ...offer,
            repayment_id: revenueRepayment.revenue_repayment_id,
            loan_amount: revenueRepayment.total_get,
            selected: revenueRepayment.selected,
            sweep: revenueRepayment.sweep,
            sweep_terms: revenueRepayment.sweep_terms,
            total_repayment: revenueRepayment.total_repayment,
            unique_id: revenueRepayment.revenue_repayment_id,
          })) ?? [];
        return [...acc, ...revenueRepaymentOffers];
      }, [] as LenderOffer[]);

      return { ...applicationDetails, offers };
    } catch (error) {
      const message = getErrorMessage(error as RequestError);
      throw new Error(message);
    }
  },
);

export const clearApplicationDetails = createAction(CLEAR_APPLICATION_DETAILS);

export const updateApplicationsQuery = createAction<Partial<ApplicationsQueryFilters>>(UPDATE_APPLICATIONS_QUERY);

export const updateApplicationsQueryAction =
  (queryOptions: Partial<ApplicationsQueryFilters>) =>
  (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dispatch: Dispatch<any>,
  ): void => {
    dispatch(updateApplicationsQuery(queryOptions));
    dispatch(getApplications());
  };

export const setApplicationsSearchTerm = createAction<string>(SET_APPLICATIONS_SEARCH_TERM);

export const getApplicationsOverview = createAsyncThunk<
  { total: number; data: ApplicationOverviewItem[] },
  OverviewQuery
>(GET_APPLICATIONS_OVERVIEW, async (query) => {
  const applications = await getApplicationsOverviewApi(query);
  return { total: applications.length, data: applications };
});

export const updateApplicationDetails = createAction<{ fundingAmount: number }>(UPDATE_APPLICATION_DETAILS);

export const clearApplicationsFilters = createAction(CLEAR_APPLICATIONS_FILTERS);

export const clearApplicationsFiltersAction =
  () =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (dispatch: Dispatch<any>): void => {
    dispatch(clearApplicationsFilters());
    dispatch(getApplications());
  };

export const setDateRange = createAction<OverviewDateRange | undefined>(SET_DATE_RANGE);

export const getOffersOverview = createAsyncThunk<{ total: number; data: OfferOverviewItem[] }, OverviewQuery>(
  GET_OFFERS_OVERVIEW,
  async (query) => {
    const offers = await getOffersOverviewApi(query);
    return { total: offers.length, data: offers };
  },
);
