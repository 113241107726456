import { FilterList, KeyboardArrowLeft, KeyboardArrowRight, Search } from '@material-ui/icons';
import { Download } from '@mui/icons-material';
import { Box, Button, FormControl, Grid, InputAdornment } from '@mui/material';
import clsx from 'clsx';
import { CustomInput } from 'pages/home/Home.styles';
import useStyles from './CustomFilter.styles';

const CustomFilter = ({
  page,
  total,
  search,
  pageSize,
  setPage,
  setSearch,
  downloadCSV,
  toggleFilterModal,
  searchPlaceholder = 'Search by business name or app ID',
  isShowSearch = true,
  isShowFilter = true,
}: {
  page: number;
  total: number;
  search?: string;
  pageSize: number;
  isShowSearch?: boolean;
  isShowFilter?: boolean;
  searchPlaceholder?: string;
  downloadCSV: () => void;
  toggleFilterModal?: () => void;
  setPage: (val: number) => void;
  setSearch?: (val: string) => void;
}) => {
  const classes = useStyles();

  const startIndex = (page - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, total);

  const handlePreviousPage = () => {
    setPage(Math.max(page - 1, 1));
  };

  const handleNextPage = () => {
    setPage(Math.min(page + 1, Math.ceil(total / pageSize)));
  };

  return (
    <Box className={classes.formControl} sx={{ mb: 6 }}>
      {isShowFilter && (
        <Button variant="outlined" className="white" onClick={toggleFilterModal || (() => {})}>
          <FilterList />
          Filters
        </Button>
      )}
      <Button variant="outlined" className="white" onClick={downloadCSV}>
        <Download />
        Export
      </Button>
      <FormControl variant="standard" className={classes.searchInput}>
        {isShowSearch && (
          <CustomInput
            rows={2}
            maxRows={4}
            value={search}
            placeholder={searchPlaceholder}
            id="bootstrap-input"
            onChange={(e) => setSearch?.(e?.target?.value)}
            startAdornment={
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            }
          />
        )}
      </FormControl>
      <Box className={classes.navigateArrowWrap}>
        <Grid>
          {total ? startIndex + 1 : 0}-{endIndex} of {total}
        </Grid>
        <Grid
          onClick={handlePreviousPage}
          className={clsx([classes.navigateArrow, page <= 1 && classes.navigateDisabled])}
        >
          <KeyboardArrowLeft />
        </Grid>
        <Grid
          onClick={handleNextPage}
          className={clsx([classes.navigateArrow, page >= Math.ceil(total / pageSize) && classes.navigateDisabled])}
        >
          <KeyboardArrowRight />
        </Grid>
      </Box>
    </Box>
  );
};

export default CustomFilter;
