/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form, Formik, FormikProps } from 'formik';
import { useHistory, useLocation } from 'react-router-dom';
import { Typography } from '@mui/material';
import * as yup from 'yup';
import { FormControlLabel, Grid, Paper, Checkbox } from '@material-ui/core';
import { LoadingButton } from 'components/buttons';
import { CustomTextField } from 'components/inputs';
import { useAuth } from 'store/auth/hooks';
import { AuthPageContainer } from 'components/AuthPageContainer';
import routes from 'router/routes';
import useStyles from './Login.styles';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

interface FormValues {
  email: string;
  rememberMe: boolean;
}

const Login: FC = () => {
  const [emailDisabled, setEmailDisabled] = useState(false);
  const formRef = useRef<FormikProps<FormValues> | null>(null);
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { loading, initAuth, error, setError } = useAuth(() => history.push(routes.confirmCode));
  const query = useQuery();

  useEffect(() => {
    const email = query.get('email');
    if (email) {
      formRef.current?.setFieldValue('email', email);
      setEmailDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loginSchema = yup.object({
    email: yup.string().required(t('pages.login.inputs.email.required')).email(t('pages.login.inputs.email.error')),
    rememberMe: yup.boolean(),
  });

  const initialValues = {
    email: '',
    rememberMe: false,
  };

  const onSubmit = (values: FormValues) => {
    initAuth(values);
  };

  const clearError = () => setError(false);

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={loginSchema} innerRef={formRef}>
      {({ handleChange, values, handleSubmit }) => (
        <AuthPageContainer error={error} clearError={clearError}>
          <Paper elevation={2} className={classes.formContainer}>
            <Typography variant="h6" gutterBottom>
              {t('pages.login.title')}
            </Typography>
            <Form noValidate>
              <Grid container direction="column" className={classes.gridContainer}>
                <Grid item>
                  <Field
                    autoFocus
                    id="email"
                    fullWidth
                    component={CustomTextField}
                    name="email"
                    type="email"
                    value={values.email.toLocaleLowerCase()}
                    onChange={handleChange}
                    title={t('pages.login.inputs.email.label')}
                    placeholder={t('pages.login.inputs.email.placeholder')}
                    className={classes.textInput}
                    InputLabelProps={{ shrink: true }}
                    disabled={emailDisabled}
                  />
                </Grid>

                <Grid item className={classes.rememberMeContainer}>
                  <FormControlLabel
                    className={classes.controlLabel}
                    control={
                      <Checkbox
                        checked={values.rememberMe}
                        onChange={handleChange}
                        id="rememberMe"
                        name="rememberMe"
                        color="primary"
                        className={classes.checkbox}
                      />
                    }
                    label={<Typography>{t('pages.login.inputs.rememberMe.label')}</Typography>}
                  />
                </Grid>

                <Grid item className={classes.actionContainer}>
                  <LoadingButton
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => handleSubmit()}
                    loading={loading}
                    type="submit"
                  >
                    {t('pages.login.buttons.submit')}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Form>
          </Paper>
        </AuthPageContainer>
      )}
    </Formik>
  );
};

export default Login;
