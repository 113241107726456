import { Box, Fade } from '@mui/material';
import { FC } from 'react';
// import { Alert, Box, Fade } from '@mui/material';
// import { useTranslation } from 'react-i18next';
// import { Navbar } from 'components/Navbar';
import { StylesProvider, createGenerateClassName } from '@material-ui/core/styles';
import { Sidebar } from 'components/Sidebar';
import useStyles from './Layout.styles';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: FC<LayoutProps> = ({ children }) => {
  const classes = useStyles();
  const generateClassName = createGenerateClassName({
    seed: 'bs-app',
  });
  // const { t } = useTranslation();

  // const production = (process.env.REACT_APP_STAGE as string) === 'production';

  return (
    <StylesProvider generateClassName={generateClassName}>
      <Box className={classes.container}>
        {/* <Navbar /> */}
        <Sidebar />
        <Fade in>
          <Box className={classes.contentContainer}>
            {/* {!production && (
            <Box className={classes.testmodeContainer}>
              <Alert severity="info">{t('global.testMode')}</Alert>
            </Box>
          )} */}
            <Box className={classes.dashboardContent}>{children}</Box>
          </Box>
        </Fade>
      </Box>
    </StylesProvider>
  );
};

export default Layout;
