import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  urlContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 720,
    marginTop: theme.spacing(3),
  },
  saveButton: {
    width: 75,
  },
  textInput: {
    minWidth: '100%',

    '& .MuiInputBase-root': {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      fontWeight: 700,
      height: 36,
    },

    '& .Mui-disabled': {
      backgroundColor: theme.palette.grey[200],
      color: theme.palette.common.black,
    },

    '& .Mui-error': {
      color: theme.palette.error.main,
      marginLeft: 0,
      marginRight: 0,
    },

    [theme.breakpoints.down('xs')]: {
      minWidth: 'auto',
    },
  },
  offerStatusContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& > *': {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
      marginRight: theme.spacing(1.5),
    },
    '& .MuiFormHelperText-root.Mui-error': {
      marginTop: theme.spacing(1),
    },
  },
  offerStatusOption: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(18),
  },
  actionsContainer: {
    marginTop: 20,
    display: 'flex',
    alignItems: 'center',
    '& > button:first-child': {
      marginRight: theme.spacing(1),
    },
  },
}));
