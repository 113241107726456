import React from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import clsx from 'clsx';
import useStyles from './Button.styles';

interface CustomButtonProps {
  loading?: boolean;
  disabled?: boolean;
  onClick: () => void;
  children: React.ReactNode;
  className?: string;
}

const CustomButton: React.FC<CustomButtonProps> = ({
  loading = false,
  disabled = false,
  onClick,
  children,
  className,
}) => {
  const classes = useStyles();
  return (
    <Button
      variant="contained"
      color="primary"
      className={clsx([classes?.customButton, className])}
      disabled={disabled || loading}
      onClick={onClick}
      fullWidth
    >
      {loading && <CircularProgress size={20} color="inherit" />} {children}
    </Button>
  );
};

export default CustomButton;
