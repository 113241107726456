import { FC, useState, useMemo, useEffect, ChangeEvent, useCallback } from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Box } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import clsx from 'clsx';
import { CommonChip } from 'components/CommonChip/CommonChip';
import CustomButton from 'components/CustomButton/Button';
import CustomFilter from 'components/CustomTable/CustomFilter/CustomFilter';
import CustomTable from 'components/CustomTable/CustomTable';
import CommonModal from 'components/Modal/CommonModal';
import FilterModal from 'components/Modal/FilterModal';
import { AlertMessage } from 'components/alerts';
import { ROWS_PER_TABLE_PAGE, ReminderFilterOption } from 'core/constants';
import { AlertMessageStatus, IReminderFilterData, RemindersTableColumnNames } from 'core/types';
import { useDebounce } from 'hooks';
import CreateRemindersModal from 'pages/lead/components/CreateReminderModal/CreateRemindersModal';
import {
  SEARCH_DEBOUNCE_DELAY,
  downloadCSVFile,
  getParamsValue,
  statueReplaceBooleanData,
} from 'pages/leads/components/CreateApplicationModal/utils';
import routes from 'router/routes';
import { useAuth } from 'store/auth/hooks';
import { useAdmin } from 'store/admin/hooks';
import { useGlobalState } from 'store/global/hooks';
import { useReminders } from 'store/reminders/hooks';
import { RemindersQuery } from 'store/reminders/types';
import useCommonStyles from '../../assets/css/Common.styles';
import { ReactComponent as RemindersIcon } from '../../assets/icons/reminders.svg';
import { getReminders as getRemindersApi } from '../../http/reminders/index';
import useStyles from './Reminders.styles';

const DATE_FORMAT = 'YYYY-MM-DD';

const today = moment().format(DATE_FORMAT);

const Reminders: FC = () => {
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>('');
  const [openReminderModal, setOpenReminderModal] = useState<string>('');
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [tabValue, setTabValue] = useState<number | string>(0);
  const debouncedSearchTerm = useDebounce(search, SEARCH_DEBOUNCE_DELAY);
  const [reminderFilterData, setReminderFilterData] = useState<IReminderFilterData>({
    status: [],
  });
  const [showReminderFilterData, setShowReminderFilterData] = useState<IReminderFilterData>({
    status: [],
  });
  const [queryData, setQueryData] = useState({
    done: '',
    per_page: ROWS_PER_TABLE_PAGE,
  });

  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { t } = useTranslation();
  const { uid: currentUserId } = useAuth();
  const { loading, reminders, total, getReminders } = useReminders();
  const { error: globalError } = useGlobalState();
  const { error, clearError, setError, setSuccess, success } = useAdmin();

  const handleColor = (value: boolean) => {
    if (value) {
      return { color: commonClasses.accepted, value: 'Done' };
    }
    if (value === false) {
      return { color: commonClasses.pending, value: 'Pending' };
    }
    return null;
  };

  const disableRow = (data: GridRenderCellParams) => {
    return currentUserId !== data?.row.assignee.id;
  };

  const toggleFilterModal = () => setOpenFilterModal(!openFilterModal);

  const handleFilterData = (event: ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.checked) {
      setReminderFilterData({
        ...reminderFilterData,
        status: [event?.target?.name],
      });
    } else {
      setReminderFilterData({
        ...reminderFilterData,
        status: reminderFilterData?.status?.filter((d: string) => d !== event?.target?.name),
      });
    }
  };

  const handleRemoveFilter = (data: string, name: string) => {
    const values: IReminderFilterData = {
      ...reminderFilterData,
      [name]: reminderFilterData[name as keyof typeof reminderFilterData]?.filter((d: string) => d !== data),
    };
    setQueryData({
      ...queryData,
      done: statueReplaceBooleanData(values?.status)?.join(','),
    });
    setShowReminderFilterData(values);
    setReminderFilterData(values);
  };

  const handleApplyFilter = () => {
    setQueryData({
      ...queryData,
      done: statueReplaceBooleanData(reminderFilterData?.status)?.join(','),
    });
    setShowReminderFilterData(reminderFilterData);
    toggleFilterModal();
  };

  const columns = useMemo(
    () => [
      {
        width: 200,
        field: RemindersTableColumnNames.REMINDER,
        headerName: t('pages.reminders.table.columns.reminder'),
        renderCell: (params: GridRenderCellParams) => {
          return (
            <span className={disableRow(params) ? classes.disabled : undefined}>{params?.row?.reminder || '-'}</span>
          );
        },
      },
      {
        width: 300,
        field: RemindersTableColumnNames.DUE_DATE,
        headerName: t('pages.reminders.table.columns.dueDate'),
        renderCell: (params: GridRenderCellParams) => {
          if (moment(today).isSame(moment(params?.row?.due_date).format(DATE_FORMAT))) {
            return (
              <span className={disableRow(params) ? classes.disabled : ''}>
                {t('components.adminReminders.dueToday')}
              </span>
            );
          }
          return (
            <span className={disableRow(params) ? classes.disabled : ''}>
              {moment(params?.row?.due_date).format('DD/MM/YYYY')}
            </span>
          );
        },
      },
      {
        width: 300,
        field: RemindersTableColumnNames.COMPANY,
        headerName: t('pages.reminders.table.columns.company'),
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Link
              to={`${routes.lead}/${params?.row?.application_id}`}
              className={clsx([disableRow(params) && classes.disabled, classes.companyColor])}
            >
              {params?.row?.company_name || '-'}
            </Link>
          );
        },
      },
      {
        width: 300,
        field: RemindersTableColumnNames.ASSIGNEE,
        headerName: t('pages.reminders.table.columns.assignee'),
        renderCell: (params: GridRenderCellParams) => {
          return params?.row?.assignee?.name || '-';
        },
      },
      {
        width: 300,
        field: RemindersTableColumnNames.STATUS,
        headerName: t('pages.reminders.table.columns.status'),
        renderCell: (params: GridRenderCellParams) => {
          return (
            <span className={clsx([commonClasses.badge, handleColor(params?.row?.done)?.color])}>
              {handleColor(params?.row?.done)?.value || '-'}
            </span>
          );
        },
      },
    ],
    // eslint-disable-next-line
    [classes.disabled, currentUserId, reminders, t],
  );

  const downloadCSV = async () => {
    await getRemindersApi({}).then((data) => {
      const reminderData = data?.reminders?.map((d) => ({
        Reminder: d?.reminder,
        'Create At': moment(d?.due_date).format('DD/MM/YYYY'),
        Company: d?.company_name,
        Status: handleColor(d?.done)?.value || '-',
      }));
      downloadCSVFile(reminderData, 'BScore_reminder.csv');
    });
  };

  const remindersQuery: RemindersQuery = useMemo(
    () => ({
      page,
      ...getParamsValue({ ...queryData, search: debouncedSearchTerm }),
    }),
    [page, queryData, debouncedSearchTerm],
  );

  const getAllReminderData = useCallback(() => {
    getReminders(remindersQuery);
  }, [getReminders, remindersQuery]);

  useEffect(() => {
    getAllReminderData();
  }, [getAllReminderData]);

  useEffect(() => {
    return () => {
      clearError();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container disableGutters maxWidth="xl">
      <Grid item xs={12} className={commonClasses.flexSpaceBetween}>
        <Box>
          <Typography className={classes.title}>{t('pages.reminders.title')}</Typography>
          <Typography className={clsx([classes.subtitle, commonClasses.marginBottom24])}>
            {t('pages.reminders.subtitle')}
          </Typography>
        </Box>
        <Box className={classes.createBtn}>
          <CustomButton onClick={() => setOpenReminderModal('1')}>Create reminder</CustomButton>
        </Box>
      </Grid>

      <Grid>
        <CustomFilter
          downloadCSV={downloadCSV}
          toggleFilterModal={toggleFilterModal}
          total={total}
          search={search}
          searchPlaceholder="Search by reminder name"
          setSearch={setSearch}
          page={page}
          pageSize={ROWS_PER_TABLE_PAGE}
          setPage={setPage}
        />
        <CommonChip data={showReminderFilterData} handleRemoveFilter={handleRemoveFilter} />
        <CustomTable
          rows={reminders}
          loading={loading}
          columns={columns}
          headerUpperCase={true}
          hideFooterPagination={true}
          NoDataFountMessage="No Data Found"
          disableColumnMenu={true}
        />
      </Grid>

      {openFilterModal && (
        <FilterModal
          setValue={setTabValue}
          value={+tabValue}
          filterTabName={['Status']}
          open={openFilterModal}
          width="width25"
          onClose={toggleFilterModal}
          filterOption={ReminderFilterOption}
          handleApplyFilter={handleApplyFilter}
          filterData={reminderFilterData}
          handleFilterData={handleFilterData}
        />
      )}

      {openReminderModal && (
        <CommonModal
          open={!!openReminderModal}
          onClose={() => setOpenReminderModal('')}
          title="Create reminder"
          icon={<RemindersIcon />}
        >
          <CreateRemindersModal onClose={setOpenReminderModal} />
        </CommonModal>
      )}

      {!globalError && (
        <>
          <AlertMessage
            open={!!error}
            onClose={() => setError(false)}
            message={typeof error === 'string' ? error : undefined}
            autoHideDuration={5000}
          />

          <AlertMessage
            open={!!success}
            onClose={() => setSuccess(false)}
            message={typeof success === 'string' ? success : undefined}
            autoHideDuration={5000}
            status={AlertMessageStatus.SUCCESS}
          />
        </>
      )}
    </Container>
  );
};

export default Reminders;
