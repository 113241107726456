import isNil from 'lodash.isnil';
import omitBy from 'lodash.omitby';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';

import { SEARCH_ROWS_PER_TABLE_PAGE, ROWS_PER_TABLE_PAGE } from 'core/constants';
import { getMinDateStringForTimeWithPeriod, getOnlineSalesInterval } from 'utils';
import {
  ApplicationsQueryFilters,
  ApplicationQuery,
  OfferOverviewItem,
  OfferStatus,
  ApplicationOverview,
  ApplicationStatus,
  OffersOverview,
  ApplicationOverviewItem,
} from './types';

const moment = extendMoment(Moment);

export const getApplicationsQuery = (filters: ApplicationsQueryFilters): ApplicationQuery => {
  const min_trading_date =
    filters.minTradingTime && filters.minTradingTimePeriod
      ? getMinDateStringForTimeWithPeriod(filters.minTradingTime, filters.minTradingTimePeriod)
      : undefined;
  const [minOnlineSales, maxOnlineSales] = getOnlineSalesInterval(filters.onlineSales ?? 0);

  const query: ApplicationQuery = {
    per_page: filters.searchTerm ? SEARCH_ROWS_PER_TABLE_PAGE : ROWS_PER_TABLE_PAGE,
    page: filters.activePage + 1,
    status: filters.activeFilters.join(',') || undefined,
    search: filters?.searchTerm || undefined,
    assigneeId: filters.assigneeId || undefined,
    min_trading_date,
    min_revenue: filters.minRevenue,
    revenue_period: filters.minRevenue ? filters.revenuePeriod : undefined,
    min_loan_amount: filters.minLoanAmount,
    max_loan_amount: filters.maxLoanAmount,
    is_homeowner: filters.isHomeOwner,
    min_online_sales: filters.onlineSales ? minOnlineSales : undefined,
    max_online_sales: filters.onlineSales ? maxOnlineSales : undefined,
    document_types: filters.documentTypes.join(',') || undefined,
    region: filters.region || undefined,
    partnerId: filters.partnerId || undefined,
  };
  return omitBy(query, isNil);
};

const getDateRangeAndFormat = (
  startDate: string,
  endDate: string,
): { dateFormat: string; dateRange: Record<string, number> } => {
  const start = moment(startDate).startOf('day');
  const end = moment(endDate).startOf('day');
  const monthsDiff = Math.abs(end.diff(start, 'months'));
  let dateFormat = 'YYYY-MM-DD';

  if (monthsDiff > 3) dateFormat = 'YYYY-MM';

  const startToEndRange = moment.range(start, end);

  const dateRange = Array.from(startToEndRange.by('day')).reduce((acc: Record<string, number>, val: Moment.Moment) => {
    acc[val.format(dateFormat)] = 0;
    return acc;
  }, {});

  return { dateFormat, dateRange };
};

const groupItemsOverviewByDate = (
  items: { updated_at: string }[],
  dateFormat: string,
  dateRange: Record<string, number>,
) => {
  return items.reduce(
    (acc: Record<string, number>, val: { updated_at: string }) => {
      const updatedDate = moment(val.updated_at).format(dateFormat);
      if (acc[updatedDate]) acc[updatedDate] += 1;
      else acc[updatedDate] = 1;
      return acc;
    },
    { ...dateRange },
  );
};

export const getApplicationsByStatusOverview = (
  applications: ApplicationOverviewItem[],
  startDate: string,
  endDate: string,
): { total: number; data: ApplicationOverview[] } => {
  const appsByStatus = applications.reduce(
    (acc: Record<ApplicationStatus, ApplicationOverviewItem[]>, val: ApplicationOverviewItem) => {
      if (acc[val.status]) acc[val.status].push(val);
      else acc[val.status] = [val];
      return acc;
    },
    {} as Record<ApplicationStatus, ApplicationOverviewItem[]>,
  );

  const { dateFormat, dateRange } = getDateRangeAndFormat(startDate, endDate);

  const data = Object.values(ApplicationStatus).map((status): ApplicationOverview => {
    const apps = appsByStatus[status] ?? [];
    const appsByDate = groupItemsOverviewByDate(apps, dateFormat, dateRange);
    return { status, total: apps.length, data: appsByDate };
  });
  return { total: applications.length, data };
};

export const getOffersByStatusOverview = (
  offers: OfferOverviewItem[],
  startDate: string,
  endDate: string,
): { total: number; data: OffersOverview[] } => {
  const offersByStatus = offers.reduce((acc: Record<OfferStatus, OfferOverviewItem[]>, val: OfferOverviewItem) => {
    if (acc[val.status]) acc[val.status].push(val);
    else acc[val.status] = [val];
    return acc;
  }, {} as Record<OfferStatus, OfferOverviewItem[]>);

  const { dateFormat, dateRange } = getDateRangeAndFormat(startDate, endDate);

  const data = Object.values(OfferStatus).map((status): OffersOverview => {
    const offersOverview = offersByStatus[status] ?? [];
    const offersByDate = groupItemsOverviewByDate(offersOverview, dateFormat, dateRange);
    return { name: status, total: offersOverview.length, data: offersByDate };
  });
  return { total: offers.length, data };
};

export const getOffersByLenderOverview = (
  offers: OfferOverviewItem[],
  startDate: string,
  endDate: string,
): { total: number; data: OffersOverview[] } => {
  const offersByLender = offers
    // ONLY SHOW LENDERS THAT HAVE ACTIVELY OFFERED
    .filter(
      (acc) =>
        acc.status === OfferStatus.ACCEPTED ||
        acc.status === OfferStatus.OFFERED ||
        acc.status === OfferStatus.APPLICANT_DECLINED,
    )
    .reduce((acc: Record<string, OfferOverviewItem[]>, val: OfferOverviewItem) => {
      if (acc[val.lender_name]) acc[val.lender_name].push(val);
      else acc[val.lender_name] = [val];
      return acc;
    }, {} as Record<string, OfferOverviewItem[]>);

  const { dateFormat, dateRange } = getDateRangeAndFormat(startDate, endDate);

  const data = Object.keys(offersByLender).map((lenderName: string): OffersOverview => {
    const offersByLenderOverview = offersByLender[lenderName];
    const offersByDate = groupItemsOverviewByDate(offersByLenderOverview, dateFormat, dateRange);
    return {
      name: lenderName,
      total: offersByLenderOverview.length,
      data: offersByDate,
      logo: offersByLenderOverview[0]?.lender_logo,
    };
  });
  return { total: offers.length, data };
};

export const getClearBitLogo = (companyWebsite: string | null | undefined) =>
  companyWebsite
    ? `logo.clearbit.com/${companyWebsite}?size=300`
    : `https://assets.website-files.com/5f4676eeed9fa2ffc2ecf715/5f4e69b20d173de2713eb774_favicon-32x32.png`;
