import { ActionReducerMapBuilder, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { PartnersState } from './types';
import {
  getPartners,
  getPartnerDetails,
  createPartner,
  setError,
  setSuccess,
  getTrustedDomains,
  saveTrustedDomains,
  getAllPartners,
  setPartnerDetails,
  updatePartner,
} from './actions';

const initialState: PartnersState = {
  loading: false,
  error: false,
  partners: [],
  success: false,
  trustedDomains: [],
  submitLoading: false,
  total: -1,
  allPartners: [],
  partnerDetails: null,
};

const partnersStore = createSlice({
  name: 'partners',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<PartnersState>) => {
    builder.addCase(getPartners.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.partners = payload.partners;
      state.total = payload.total;
    });

    builder.addCase(getPartnerDetails.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.partnerDetails = payload.partnerDetails;
    });

    builder.addCase(setError, (state, { payload }) => {
      state.error = payload;
    });

    builder.addCase(setSuccess, (state, { payload }) => {
      state.success = payload;
    });

    builder.addCase(getTrustedDomains.fulfilled, (state, { payload }) => {
      state.trustedDomains = payload;
      state.loading = false;
    });

    builder.addCase(saveTrustedDomains.fulfilled, (state) => {
      state.submitLoading = false;
    });
    builder.addCase(saveTrustedDomains.pending, (state) => {
      state.submitLoading = true;
    });

    builder.addCase(saveTrustedDomains.rejected, (state, { error }) => {
      state.submitLoading = false;
      state.error = error?.message || true;
    });

    builder.addCase(setPartnerDetails, (state, { payload }) => {
      if (!payload) state.partnerDetails = initialState.partnerDetails;
      else state.partnerDetails = payload;
    });

    builder.addCase(getAllPartners.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.allPartners = payload;
    });

    builder.addMatcher(
      isAnyOf(
        getPartners.pending,
        createPartner.pending,
        getPartnerDetails.pending,
        getTrustedDomains.pending,
        updatePartner.pending,
        getAllPartners.pending,
      ),
      (state) => {
        state.loading = true;
        state.error = false;
      },
    );

    builder.addMatcher(
      isAnyOf(
        getPartners.rejected,
        createPartner.rejected,
        getPartnerDetails.rejected,
        getTrustedDomains.rejected,
        updatePartner.rejected,
        getAllPartners.rejected,
      ),
      (state, { error }) => {
        state.loading = false;
        state.error = error?.message || true;
      },
    );

    builder.addMatcher(isAnyOf(createPartner.fulfilled, updatePartner.fulfilled), (state) => {
      state.loading = false;
    });
  },
});

export default partnersStore.reducer;
