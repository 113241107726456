import { Container, Grid, Paper } from '@material-ui/core';
import { Avatar, Box, List, ListItem, Stack, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { ChevronLeft } from '@material-ui/icons';
import clsx from 'clsx';
import { AccountUsers } from 'components/AccountUsers';
import { CreateAccountOwnerModal } from 'components/CreateAccountOwnerModal';
import CustomButton from 'components/CustomButton/Button';
import { Header } from 'components/Header/Header';
import { Loader } from 'components/Loader';
import CommonModal from 'components/Modal/CommonModal';
import { UserSwitchEnabled } from 'components/UserSwitchEnabled';
import { AlertMessage } from 'components/alerts';
import { countryCodeLabel } from 'core/countries';
import { AlertMessageStatus } from 'core/types';
import { CreatePartnerModal } from 'pages/partners/components';
import useApiStyles from 'pages/settings/Api/Api.styles';
import { ApiSecret } from 'pages/settings/Api/components';
import { Display, KeyType } from 'pages/settings/Api/components/ApiSecret/ApiSecret';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import routes from 'router/routes';
import { useAccount } from 'store/account/hooks';
import { AccountType } from 'store/applications/types';
import { useGlobalState } from 'store/global/hooks';
import { usePartners } from 'store/partners/hooks';
import useCommonStyles from '../../assets/css/Common.styles';
import useStyles from './Partner.styles';
import { ReactComponent as DocumentPen } from '../../assets/icons/document_pen.svg';

const PartnerDetails: FC = () => {
  const [updateOpen, setUpdateOpen] = useState(false);
  const [createOwnerOpen, setCreateOwnerOpen] = useState(false);

  const commonClasses = useCommonStyles();
  const classes = useStyles();
  const apiClasses = useApiStyles();
  const { t } = useTranslation();
  const data = useParams<{ id: string }>();
  const { error: globalError } = useGlobalState();
  const { users } = useAccount();
  const { loading, error, setError, partnerDetails, getPartnerDetails, success, setSuccess, setPartnerDetails } =
    usePartners();

  const toggleUpdateOpen = () => setUpdateOpen((prevState) => !prevState);

  const toggleCreateOwnerOpen = () => setCreateOwnerOpen((prevState) => !prevState);

  const partnerData = [
    { title: t('pages.partner.sections.header.accountName'), value: partnerDetails?.name },
    { title: t('pages.partner.sections.header.displayedName'), value: partnerDetails?.displayed_name },
    { title: t('pages.partner.sections.header.contactName'), value: partnerDetails?.contact_person_name },
    { title: t('pages.partner.sections.header.contactEmail'), value: partnerDetails?.contact_person_email },
    { title: t('pages.partner.sections.header.website'), value: partnerDetails?.website },
    {
      title: t('pages.partner.sections.header.country'),
      value: `${countryCodeLabel[partnerDetails?.country_code || '']} (${partnerDetails?.country_code})`,
    },
    { title: t('pages.partner.sections.header.logoUrl'), value: partnerDetails?.logo_url },
    { title: 'Enable lender', value: '' },
  ];

  useEffect(() => {
    return () => setPartnerDetails(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data?.id) {
      getPartnerDetails(data?.id);
    }
    // eslint-disable-next-line
  }, [data?.id, getPartnerDetails]);

  return (
    <Container disableGutters maxWidth="xl">
      <Header name={partnerDetails?.name} />
      <Box className={clsx([commonClasses.pageBackWrap, commonClasses.mainWrapper])}>
        <Link to={routes?.partners} className={commonClasses.pageBackLink}>
          <ChevronLeft className={commonClasses.pageBackIcon} />
          Back to Partners
        </Link>
      </Box>
      <Box sx={{ mt: 6, px: 4 }} className={commonClasses.mainWrapper}>
        <Grid container spacing={4} className={commonClasses.marginBottom24px}>
          <Grid item xs={12} lg={4} xl={4}>
            <Box sx={{ px: 8, py: 6, height: '100%' }} className={clsx([commonClasses.cardShadow])}>
              <Box>
                <Stack sx={{ mb: 6 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={4}>
                  <Avatar alt={partnerDetails?.name} src={partnerDetails?.logo_url} sx={{ width: 56, height: 56 }} />
                  <Box className={commonClasses.boxCircleText}>
                    <Typography className={clsx([commonClasses.fontJost, commonClasses.boxCircleTitle])} component="p">
                      {partnerDetails?.name}
                    </Typography>
                    <Typography className={clsx([commonClasses.fontJost])} component="p">
                      All information relating to this Partner
                    </Typography>
                  </Box>
                </Stack>
                <Box sx={{ mb: 6 }}>
                  <List sx={{ mb: 0, p: 0 }}>
                    {partnerData?.map((d) => {
                      return (
                        <ListItem sx={{ px: 0 }}>
                          <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                            <Box className={commonClasses.fixedWidth136}>
                              <Typography
                                component="p"
                                className={clsx([
                                  commonClasses.fontJost,
                                  commonClasses.gray_600,
                                  commonClasses.lineHeight_25,
                                ])}
                              >
                                {d?.title}
                              </Typography>
                            </Box>
                            <Box>
                              {d?.title === 'Enable lender' ? (
                                partnerDetails && (
                                  <UserSwitchEnabled
                                    id={partnerDetails.id}
                                    initialActive={partnerDetails.active}
                                    accountType={AccountType.PARTNER}
                                    setSuccess={setSuccess}
                                    setError={setError}
                                    successMessage={t('pages.partner.messages.updateSuccess') as string}
                                  />
                                )
                              ) : (
                                <Typography
                                  component="p"
                                  className={clsx([
                                    commonClasses.fontJost,
                                    commonClasses.gray_600,
                                    commonClasses.lineHeight_25,
                                  ])}
                                >
                                  {d?.value || '-'}
                                </Typography>
                              )}
                            </Box>
                          </Stack>
                        </ListItem>
                      );
                    })}
                  </List>
                </Box>
                <Box className={commonClasses.flexWithGap10}>
                  <CustomButton onClick={toggleUpdateOpen}>{t('pages.partners.admin.actions.update')}</CustomButton>
                  {users?.length === 0 && <CustomButton onClick={toggleCreateOwnerOpen}>Create Account</CustomButton>}
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={8} xl={8}>
            <Paper
              elevation={2}
              className={clsx([apiClasses.keysContainer, commonClasses.cardShadow, commonClasses.padding24px])}
            >
              <Typography className={classes.subtitle} gutterBottom>
                {t('pages.partner.keysTitle')}
              </Typography>

              {partnerDetails &&
                Object.entries(partnerDetails.api_keys).reduce((acc: JSX.Element[], [key, value]) => {
                  if (key !== 'id') {
                    const isSecretKey = /secret/i.test(key);
                    acc.push(
                      <ApiSecret
                        display={isSecretKey ? Display.MASK : Display.RAW}
                        key={`${key}${value}`}
                        title={t(`pages.api.apiKeys.${key}`)}
                        secret={value || ''}
                        type={isSecretKey ? KeyType.SECRET : KeyType.PUBLIC}
                        accountId={partnerDetails.id}
                        accountType={AccountType.PARTNER}
                        name={key}
                      />,
                    );
                  }
                  return acc;
                }, [])}
            </Paper>
          </Grid>
        </Grid>
        <Box sx={{ px: 8, py: 6, height: '100%' }} className={clsx([commonClasses.cardShadow])}>
          <AccountUsers partnerId={data?.id} width={350} toggle={createOwnerOpen} />
        </Box>
      </Box>

      {updateOpen && partnerDetails && (
        <CommonModal open={updateOpen} title="Update this partner" onClose={toggleUpdateOpen} icon={<DocumentPen />}>
          <CreatePartnerModal open={updateOpen} toggleOpen={toggleUpdateOpen} partner={partnerDetails} />
        </CommonModal>
      )}

      {createOwnerOpen && (
        <CommonModal
          open={createOwnerOpen}
          title="Create account owner"
          onClose={toggleCreateOwnerOpen}
          icon={<DocumentPen />}
        >
          <CreateAccountOwnerModal toggleOpen={toggleCreateOwnerOpen} accountType={AccountType.PARTNER} />
        </CommonModal>
      )}

      <Loader visible={loading} />

      {!globalError && (
        <>
          <AlertMessage
            open={!!error}
            onClose={() => setError(false)}
            message={typeof error === 'string' ? error : undefined}
            autoHideDuration={5000}
          />

          <AlertMessage
            open={!!success}
            onClose={() => setSuccess(false)}
            message={typeof success === 'string' ? success : undefined}
            autoHideDuration={5000}
            status={AlertMessageStatus.SUCCESS}
          />
        </>
      )}
    </Container>
  );
};

export default PartnerDetails;
