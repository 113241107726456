import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  container: {
    '& .bs-app-MuiFormControl-root': {
      marginTop: 0,
      marginBottom: 0,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      backgroundColor: '#fff',
      borderRadius: '4px !important',
    },

    '& .bs-app-MuiInput-underline:before, .MuiInput-underline:after, .bs-app-MuiInput-underline:hover:before': {
      border: 'none',
    },

    '& .bs-app-MuiInputBase-input': {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      fontWeight: 400,
      width: 100,
      height: 36,
      textAlign: 'center',
      color: theme.palette.Gray[700],
      backgroundColor: '#fff',
      borderRadius: 4,
      paddingTop: 0,
      paddingBottom: 0,
      cursor: 'pointer',
    },
  },
  dateContainer: {
    position: 'relative',
    '& .bs-app-MuiFormControl-marginNormal': {
      marginTop: 0,
      marginBottom: 0,
    },
    '& .bs-app-MuiInputBase-input': {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      fontWeight: 700,
      borderRadius: 4,
      paddingLeft: 13,
      cursor: 'pointer',
      border: `1px solid ${theme.palette.greyLightBorder.main}`,
      height: 34,
    },
    '& .bs-app-MuiInput-underline:before, .MuiInput-underline:after, .bs-app-MuiInput-underline:hover:before': {
      border: 'none',
    },
  },
  icon: {
    width: 14,
    height: 14,
    color: '#687C8D',
    position: 'absolute',
    top: 40,
    right: 10,
  },
  inputError: {
    '& .bs-app-MuiInputBase-input': {
      borderColor: theme.palette.error.main,
    },
  },
  iconStyle: { marginLeft: '10px' },
}));
