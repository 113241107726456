import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  keysContainer: {
    padding: theme.spacing(3),
  },
  mainSubtitle: {
    marginBottom: theme.spacing(3),
  },
  subtitle: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(20),
    fontWeight: 700,
  },
}));
