import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Grid } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { Box, CardContent, Tab, Tabs, Typography } from '@mui/material';
import clsx from 'clsx';
import { Header } from 'components/Header/Header';
import { AlertMessage } from 'components/alerts';
import { INCOMPLETED_APP_STATUS, applicationDetailsTabsList } from 'core/constants';
import { AlertMessageStatus } from 'core/types';
import { usePrevious, useScrollToTop } from 'hooks';
import { getApplicationDetailsCount } from 'http/applications';
import { Link, RouteComponentProps, useParams } from 'react-router-dom';
import routes from 'router/routes';
import { useAdmin } from 'store/admin/hooks';
import { useApplications } from 'store/applications/hooks';
import { useGlobalState } from 'store/global/hooks';
import CommonModal from 'components/Modal/CommonModal';
import CustomToast from 'components/ToastMessage/CustomToast';
import { useAuth } from 'store/auth/hooks';
import { ApplicationStatus, LenderOffer } from 'store/applications/types';
import ApplicationOffer from './components/ApplicationOffer/ApplicationOffer';
import ClientEmailsList from './components/ClientEmails/ClientEmailsList';
import { CompanyHouse } from './components/CompanyHouseProfile/CompanyHouse';
import CreateRemindersModal from './components/CreateReminderModal/CreateRemindersModal';
import FollowUpsModal from './components/FollowUpModal/FollowUpsModal';
import OfferModal from './components/MakeOfferModal/OfferModal';
import RejectsModal from './components/RejectModal/RejectsModal';
import SendApplications from './components/SendApplications/SendApplications';
import SendEmailsModal from './components/SendEmailModal/SendEmailsModal';
import { ActionLeadTab } from './components/ActionLeadTab/ActionLeadTab';
import { ApplicationDetails } from './components/ApplicationDetails/ApplicationDetails';
import useStyles from './Lead.styles';
import useCommonStyles from '../../assets/css/Common.styles';

import { ReactComponent as ApplicationLeads } from '../../assets/icons/application_leads.svg';
import { ReactComponent as ApplicationOffers } from '../../assets/icons/application_offers.svg';
import { ReactComponent as Emails } from '../../assets/icons/emails.svg';
import { ReactComponent as RejectedApplications } from '../../assets/icons/rejected_applications.svg';
import { ReactComponent as Reminders } from '../../assets/icons/reminders.svg';
import { ReactComponent as RequestedDetails } from '../../assets/icons/requested_details.svg';
import { GatherOffers } from './components';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface IAplicationDetailsCount {
  offerCount: number;
  rejectionCount: number;
  requestedDetailCount: number;
  sentEmailCount: number;
  receivedEmailCount: number;
  reminderCount: number;
}

export function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Lead: FC<RouteComponentProps> = () => {
  const [tabValue, setTabValue] = useState<number>(0);
  const [showMessage, setShowMessage] = useState<{ message: string; open: boolean }>({ message: '', open: false });
  const [applicationDetailsOverviewCount, setApplicationDetailsOverViewCount] = useState<IAplicationDetailsCount>({
    offerCount: 0,
    rejectionCount: 0,
    requestedDetailCount: 0,
    sentEmailCount: 0,
    receivedEmailCount: 0,
    reminderCount: 0,
  });
  const [toggle, setToggle] = useState<string>('');
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const data = useParams<{ id: string }>();
  const { t } = useTranslation();
  const { isAdmin, isLenderAdmin } = useAuth();
  const { error: globalError } = useGlobalState();
  const { error, clearError, success, setSuccess } = useAdmin();
  const { getApplicationDetails, applicationDetails, clearApplicationDetails, loading } = useApplications();
  const prevLoading = usePrevious(loading);
  const prevApplicationDetails = usePrevious(applicationDetails);

  const applicant = applicationDetails?.company?.people.find((p) => p.applicant);

  const isAppNotCompleted = !!applicationDetails && INCOMPLETED_APP_STATUS.includes(applicationDetails.status);
  const canMakeOffersOrFollowup =
    !!applicationDetails && applicationDetails.status === ApplicationStatus.OFFERS_REQUESTED;

  const isShowApplicationLeads = applicationDetails?.status !== ApplicationStatus.OFFERS_REQUESTED;

  useScrollToTop(!prevApplicationDetails && !!prevLoading && !loading);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const updateShowMessage = (newMessage: string, newOpen: boolean) => {
    setShowMessage({ message: newMessage, open: newOpen });
    setTimeout(() => {
      setShowMessage({ message: '', open: false });
    }, 4000);
  };

  const dashboardBoxData = [
    {
      icon: <ApplicationOffers />,
      title: 'Application offers',
      value: applicationDetailsOverviewCount?.offerCount || 0,
      subTitle: 'Create an offer',
      disabled: isAppNotCompleted || !canMakeOffersOrFollowup,
      show: true,
    },
    {
      icon: <RejectedApplications />,
      title: 'Rejected applications',
      value: applicationDetailsOverviewCount?.rejectionCount || 0,
      subTitle: 'Reject application',
      show: isAdmin || isLenderAdmin,
    },
    {
      icon: <RequestedDetails />,
      title: 'Requested details',
      value: applicationDetailsOverviewCount?.requestedDetailCount,
      subTitle: 'Request additional details',
      disabled: !canMakeOffersOrFollowup,
      show: isAdmin,
    },
    {
      icon: <Emails />,
      title: 'Emails / Received Emails',
      value: applicationDetailsOverviewCount?.sentEmailCount || 0,
      subTitle: 'Send email',
      disabled: !applicant,
      show: isAdmin,
    },
    {
      icon: <Reminders />,
      title: 'Reminders',
      value: applicationDetailsOverviewCount?.reminderCount,
      subTitle: 'Create reminder',
      disabled: !applicant,
      show: isAdmin,
    },
    {
      icon: <ApplicationLeads />,
      title: 'Application leads',
      value: 0,
      subTitle: 'Send a lender introduction email',
      disabled: isShowApplicationLeads,
      show: isAdmin,
    },
    {
      icon: <ApplicationLeads />,
      title: 'Application leads',
      value: 0,
      subTitle: `${t('pages.lead.admin.gatherOffers.title')}`,
      disabled: isShowApplicationLeads,
      show: isAdmin,
    },
  ];

  const getApplicationDetailsCounts = async () => {
    await getApplicationDetailsCount(data?.id).then((res) => {
      setApplicationDetailsOverViewCount(res as IAplicationDetailsCount);
    });
  };

  const getApplicationDetailsApi = useCallback(() => {
    if (data?.id || !toggle) {
      getApplicationDetails(data?.id);
      getApplicationDetailsCounts();
    }
    // eslint-disable-next-line
  }, [data, toggle]);

  useEffect(() => {
    getApplicationDetailsApi();
  }, [getApplicationDetailsApi]);

  useEffect(() => {
    return () => {
      clearApplicationDetails();
      clearError();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Grid>
        <Header name={`${applicant?.first_name || ''} application`} isShowTestMode={false} isShowSendBtn={true} />
      </Grid>
      <Box className={commonClasses.padding24px}>
        <Box className={commonClasses.marginBottom24px}>
          <Link to={routes?.leads} className={classes.pageBackLink}>
            <ChevronLeft className={classes.pageBackIcon} />
            Back to applications
          </Link>
        </Box>
        <Box>
          <Grid container spacing={4}>
            {dashboardBoxData?.map((e, index) => {
              return (
                e?.show && (
                  <Grid item xs={3} key={e?.title}>
                    <Card className={classes.dashboardPaletteCard}>
                      <CardContent className={classes.dashboardPalette}>
                        <Box className={classes.dashboardPaletteIconText}>
                          <Box className={classes.dashboardPaletteIcon}>{e?.icon}</Box>
                          <Box className={classes.dashboardPaletteTextWrap}>
                            <Typography className={classes.dashboardPaletteDesc} component="p">
                              {e?.title}
                            </Typography>
                            <Typography className={classes.dashboardPaletteDescCount} component="span">
                              {e?.value}{' '}
                              {e?.subTitle === 'Send email' && (
                                <span style={{ marginRight: 4 }} className={classes.dashboardPaletteDesc}>
                                  /
                                </span>
                              )}
                              {e?.subTitle === 'Send email' &&
                                (applicationDetailsOverviewCount?.receivedEmailCount || 0)}
                            </Typography>
                          </Box>
                        </Box>
                        <Box className={classes.dashboardPaletteLink}>
                          <Box
                            onClick={() => !e?.disabled && setToggle(`${index + 1}`)}
                            className={clsx([classes.dashboardPaletteLinkItem, e?.disabled && classes.disabled])}
                          >
                            <span style={{ fontWeight: 700 }}>{e?.subTitle}</span>
                            <ChevronRight className={classes.dashboardPaletteLinkIcon} />
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                )
              );
            })}
          </Grid>
        </Box>
        <Box className={clsx([classes.c_card, classes.cardShadow])} sx={{ marginTop: '30px' }}>
          <Box className={clsx([commonClasses.padding24px])}>
            <Box className={clsx([classes.filter_tabingBox])}>
              <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example" scrollButtons="auto">
                {applicationDetailsTabsList?.map((d, index) => {
                  return <Tab label={d} key={d} {...a11yProps(index)} />;
                })}
              </Tabs>
            </Box>
            <CustomTabPanel value={tabValue} index={0}>
              <ApplicationOffer activeTab={toggle} />
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={1}>
              <ApplicationDetails details={applicationDetails} />
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={3}>
              <ActionLeadTab details={applicationDetails} />
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={2}>
              <CompanyHouse details={applicationDetails} />
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={4}>
              <ClientEmailsList tabValue={tabValue} details={applicationDetails} />
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={5}>
              <ClientEmailsList tabValue={tabValue} details={applicationDetails} />
            </CustomTabPanel>
          </Box>
        </Box>
      </Box>

      {toggle === '1' && (
        <CommonModal open={!!toggle} onClose={() => setToggle('')} title="Offer box" icon={<ApplicationOffers />}>
          <OfferModal onClose={setToggle} />
        </CommonModal>
      )}

      {toggle === '2' && (
        <CommonModal
          open={!!toggle}
          onClose={() => setToggle('')}
          title="Rejection box"
          icon={<RejectedApplications />}
        >
          <RejectsModal onClose={setToggle} details={applicationDetails} />
        </CommonModal>
      )}

      {toggle === '3' && (
        <CommonModal
          open={!!toggle}
          onClose={() => setToggle('')}
          title="Request additional details required for this application"
          icon={<RequestedDetails />}
        >
          <FollowUpsModal setMessage={updateShowMessage} onClose={setToggle} />
        </CommonModal>
      )}

      {toggle === '4' && (
        <CommonModal
          open={!!toggle}
          onClose={() => setToggle('')}
          title={`Send a mail to ${applicant?.first_name}`}
          icon={<Emails />}
        >
          <SendEmailsModal setMessage={updateShowMessage} onClose={setToggle} />
        </CommonModal>
      )}

      {toggle === '5' && (
        <CommonModal open={!!toggle} onClose={() => setToggle('')} title="Create reminder" icon={<Reminders />}>
          <CreateRemindersModal onClose={setToggle} />
        </CommonModal>
      )}

      {toggle === '6' && (
        <CommonModal
          open={!!toggle}
          onClose={() => setToggle('')}
          title="Send a lender introduction email"
          icon={<ApplicationLeads />}
        >
          <SendApplications onClose={setToggle} />
        </CommonModal>
      )}
      {toggle === '7' && (
        <CommonModal
          open={!!toggle}
          onClose={() => setToggle('')}
          title={t('pages.lead.admin.gatherOffers.title')}
          icon={<ApplicationLeads />}
        >
          <GatherOffers
            companyId={applicationDetails?.company.id as string}
            offers={applicationDetails?.offers as LenderOffer[]}
          />
        </CommonModal>
      )}
      {showMessage?.open && <CustomToast open={showMessage?.open} message={showMessage?.message} />}

      {!globalError && (
        <>
          <AlertMessage
            open={!!error}
            onClose={clearError}
            message={typeof error === 'string' ? error : undefined}
            autoHideDuration={5000}
          />

          <AlertMessage
            open={!!success}
            onClose={() => setSuccess(false)}
            message={typeof success === 'string' ? success : undefined}
            status={AlertMessageStatus.SUCCESS}
          />
        </>
      )}
    </Box>
  );
};

export default Lead;
