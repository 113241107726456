import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  inputFileContent: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100%',
  },
  inputFileIcon: {
    marginBottom: '12px',
    width: '40px',
    height: '40px',
    borderRadius: '100px',
    display: 'block',
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    margin: 0,
    backgroundColor: '#F2F4F7',
    boxShadow: '0px 0px 0px 6px #F9FAFB',
    transitionDuration: '0.3s',
    '&:active': {
      scale: '0.85',
    },
    '& svg': {
      width: '100%',
      height: '100%',
    },
  },
  inputFileText: {
    fontSize: `${theme.typography.pxToRem(14)}`,
    lineHeight: `${theme.typography.pxToRem(20)}`,
    fontWeight: '600 !important',
    color: `${theme.palette.Blue[600]} !important`,
    '& span': {
      fontSize: `${theme.typography.pxToRem(14)}`,
      lineHeight: `${theme.typography.pxToRem(20)}`,
      fontWeight: '400',
      color: theme.palette.Gray[500],
      display: 'inline-block',
      marginLeft: '4px',
    },
  },
  icon: {
    width: '25px',
    marginRight: '5px',
  },
  li_image: {
    display: 'flex',
  },
}));
