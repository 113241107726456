import { FC } from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';

import { CustomTextField } from 'components/inputs';
import { useAuth } from 'store/auth/hooks';
import { LoadingButton } from 'components/buttons';
import routes from 'router/routes';
import { AuthPageContainer } from 'components/AuthPageContainer';
import useStyles from './ForgotPassword.styles';

interface FormValues {
  email: string;
}

const ForgotPassword: FC<RouteComponentProps> = ({ history }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { loading, error, success, setError, initPasswordReset, setSuccess } = useAuth();

  const loginSchema = yup.object({
    email: yup
      .string()
      .required(t('pages.forgotPassword.inputs.email.required'))
      .email(t('pages.forgotPassword.inputs.email.error')),
  });

  const initialValues = { email: '' };

  const onSubmit = (values: FormValues) => {
    initPasswordReset(values.email);
  };

  const clearSuccess = () => setSuccess(false);

  const goToLogin = () => history.push(routes.login);

  const clearError = () => setError(false);

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={loginSchema}>
      {({ handleChange, values, handleSubmit }) => (
        <AuthPageContainer error={error} clearError={clearError} success={success} clearSuccess={clearSuccess}>
          <Paper elevation={2} className={classes.formContainer}>
            <Typography variant="h6" gutterBottom>
              {t('pages.forgotPassword.message.title')}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {t('pages.forgotPassword.message.description')}
            </Typography>
            <Form noValidate>
              <Grid container direction="column" className={classes.gridContainer}>
                <Grid item>
                  <Field
                    autoFocus
                    id="email"
                    fullWidth
                    component={CustomTextField}
                    name="email"
                    type="email"
                    value={values.email}
                    onChange={handleChange}
                    title={t('pages.forgotPassword.inputs.email.label')}
                    placeholder={t('pages.forgotPassword.inputs.email.placeholder')}
                    className={classes.textInput}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>

                <Grid item className={classes.actionContainer}>
                  <LoadingButton
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => handleSubmit()}
                    loading={loading}
                    type="submit"
                  >
                    {t('pages.forgotPassword.buttons.submit')}
                  </LoadingButton>
                  <LoadingButton
                    fullWidth
                    variant="text"
                    color="secondary"
                    onClick={() => goToLogin()}
                    loading={loading}
                    type="submit"
                  >
                    {t('pages.forgotPassword.buttons.goLogin')}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Form>
          </Paper>
        </AuthPageContainer>
      )}
    </Formik>
  );
};

export default ForgotPassword;
