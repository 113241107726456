import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'flex-end',
    marginTop: theme.spacing(2),
    flexDirection: 'column',
  },
  syncText: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(16),
    marginTop: theme.spacing(0.5),
    textAlign: 'right',
  },
  errorText: {
    color: theme.palette.error.main,
  },
  providerName: {
    fontSize: theme.typography.pxToRem(15),
    lineHeight: theme.typography.pxToRem(20),
    fontWeight: 700,
  },
  providerActionContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > p': {
      marginRight: theme.spacing(2),
    },
  },
}));
