import { useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

import { useActionCreator, usePrevious } from 'hooks';
import { clearAppFilters, clearAuthSession } from 'utils';
import { UserRole } from 'core/types';
import { RootState } from '../types';
import {
  initAuth,
  confirmAuth,
  logout as logoutAction,
  setError,
  register,
  confirmPasswordReset,
  initPasswordReset,
  getCurrentAuthenticatedUser,
  setSuccess,
  registerAccount,
} from './actions';
import { AuthReturnHook } from './types';

export const useAuth = (onSuccess?: () => void): AuthReturnHook => {
  const authState = useSelector((state: RootState) => state.auth, shallowEqual);
  const dispatch = useDispatch();
  const prevLoading = usePrevious(authState.loading);

  useEffect(() => {
    if (prevLoading && !authState.loading && !authState.error && onSuccess) {
      onSuccess();
    }
  }, [authState.error, authState.loading, onSuccess, prevLoading]);

  const roles = useMemo(() => {
    const userRole = {
      isSuperAdmin: false,
      isAdmin: false,
      isPartner: false,
      isPartnerAdmin: false,
      isLender: false,
      isLenderAdmin: false,
      isCompanyOwner: false,
    };
    if (!userRole) return userRole;
    switch (authState.role) {
      case UserRole.SUPER_ADMIN:
        userRole.isSuperAdmin = true;
        userRole.isAdmin = true;
        break;
      case UserRole.ADMIN:
        userRole.isAdmin = true;
        break;
      case UserRole.PARTNER_MANAGER:
        userRole.isPartner = true;
        userRole.isPartnerAdmin = true;
        break;
      case UserRole.PARTNER_USER:
        userRole.isPartner = true;
        break;
      case UserRole.LENDER_MANAGER:
      case UserRole.LENDER_MAINTAINER:
        userRole.isLender = true;
        userRole.isLenderAdmin = true;
        break;
      case UserRole.LENDER_VIEWER:
        userRole.isLender = true;
        break;
      case UserRole.COMPANY_OWNER:
        userRole.isCompanyOwner = true;
        break;
      default:
        break;
    }

    return userRole;
  }, [authState.role]);

  const logout = useCallback(() => {
    clearAuthSession();
    clearAppFilters();
    dispatch(logoutAction());
  }, [dispatch]);

  return {
    ...authState,
    ...roles,
    initAuth: useActionCreator(initAuth),
    confirmAuth: useActionCreator(confirmAuth),
    logout,
    setError: useActionCreator(setError),
    register: useActionCreator(register),
    confirmPasswordReset: useActionCreator(confirmPasswordReset),
    initPasswordReset: useActionCreator(initPasswordReset),
    getCurrentAuthenticatedUser: useActionCreator(getCurrentAuthenticatedUser),
    setSuccess: useActionCreator(setSuccess),
    registerAccount: useActionCreator(registerAccount),
  };
};
