import { useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { useActionCreator, usePrevious } from 'hooks';
import { RootState } from 'store/types';
import {
  getLenders,
  createLender,
  updateLender,
  setLenderLogo,
  getAllLenders,
  getLenderDetails,
  setLenderDetails,
  setError,
  setSuccess,
} from './actions';
import { LendersReturnHook } from './types';

export const useLenders = (onSuccess?: () => void): LendersReturnHook => {
  const lendersState = useSelector((state: RootState) => state.lenders, shallowEqual);
  const prevLoading = usePrevious(lendersState.loading);

  useEffect(() => {
    if (prevLoading && !lendersState.loading && !lendersState.error && onSuccess) {
      onSuccess();
    }
  }, [lendersState.error, lendersState.loading, onSuccess, prevLoading]);

  return {
    ...lendersState,
    getLenders: useActionCreator(getLenders),
    createLender: useActionCreator(createLender),
    updateLender: useActionCreator(updateLender),
    setLenderLogo: useActionCreator(setLenderLogo),
    getAllLenders: useActionCreator(getAllLenders),
    getLenderDetails: useActionCreator(getLenderDetails),
    setLenderDetails: useActionCreator(setLenderDetails),
    setError: useActionCreator(setError),
    setSuccess: useActionCreator(setSuccess),
  };
};
