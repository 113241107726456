import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  container: {
    width: 350,
    position: 'relative',
    '& .MuiInputBase-root': {
      height: 40,
    },
    '& .MuiInputBase-input': {
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(2),
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
    },
  },
  searchIcon: {
    position: 'absolute',
    top: 18,
    left: 17,
    height: 18,
    width: 18,
  },
  closeIcon: {
    position: 'absolute',
    top: 18,
    right: 5,
    height: 18,
    width: 18,
    cursor: 'pointer',
  },
}));
