import { FC, useMemo, useEffect, useCallback, useState } from 'react';
import Box from '@material-ui/core/Box';
import { TabRoute } from 'core/types';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { Tab, Tabs } from '@mui/material';
import clsx from 'clsx';
import { Header } from 'components/Header/Header';
import { Account } from 'pages/account';
import { CustomTabPanel } from 'pages/lead/Lead';
import { Reminders } from 'pages/reminders';
import routes from 'router/routes';
import { useAuth } from 'store/auth/hooks';
import { RulesEngine } from '../rules-engine';
import { Faqs } from './Faqs';
import { Team } from './Team';
import useCommonStyles from '../../assets/css/Common.styles';

const Settings: FC = () => {
  const [tabValue, setTabValue] = useState<number>(0);

  const commonClasses = useCommonStyles();
  const { isAdmin, isPartner, isLender } = useAuth();
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const tabs: TabRoute[] = useMemo(() => {
    if (isAdmin) {
      return [
        // { path: routes.rulesEngine, label: t('global.rulesEngine'), Component: RulesEngine },
        { path: routes.faqs, label: t('global.faqs'), Component: Faqs },
        { path: routes.reminders, label: t('global.reminders'), Component: Reminders },
      ];
    }
    if (isPartner) return [{ path: routes.team, label: t('global.team'), Component: Team }];
    if (isLender)
      return [
        { path: routes.team, label: t('global.team'), Component: Team },
        { path: routes.account, label: t('global.account'), Component: Account },
      ];
    return [];
  }, [isAdmin, isPartner, isLender, t]);

  const settingTabs = ['FAQs', 'Reminders'];

  const navToFirstTab = useCallback(() => {
    const initialTab = tabs[0];
    if (initialTab) {
      history.replace(initialTab.path);
    }
  }, [history, tabs]);

  useEffect(() => {
    if (location.pathname === routes.settings) {
      navToFirstTab();
    }
    if (location?.state === 'reminder') {
      setTabValue(1);
    }
    // eslint-disable-next-line
  }, [location.pathname, navToFirstTab]);

  return (
    <Box>
      <Header name="Settings" />
      <Box className={clsx([commonClasses.mainWrapper, commonClasses.marginTop24])}>
        <Box className={clsx([commonClasses.c_card, commonClasses.cardShadow])}>
          <Box className={commonClasses.padding24px}>
            <Box className={clsx([commonClasses.filter_tabingBox])}>
              <Tabs
                value={+tabValue}
                onChange={(event: React.SyntheticEvent, newValue: string) => {
                  setTabValue(+newValue);
                }}
                aria-label="basic tabs example"
                scrollButtons="auto"
              >
                {settingTabs?.map((data) => {
                  return <Tab label={data} key={data} />;
                })}
              </Tabs>
            </Box>
            {false && (
              <CustomTabPanel index={0} value={tabValue}>
                <RulesEngine />
              </CustomTabPanel>
            )}
            <CustomTabPanel index={0} value={tabValue}>
              <Faqs />
            </CustomTabPanel>
            <CustomTabPanel index={1} value={tabValue}>
              <Reminders />
            </CustomTabPanel>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Settings;
