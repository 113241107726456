import { InputBase, Theme, Switch } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) => ({
  // typoH1: {

  // },
  // HEADER STYLE
  titleName: {
    fontSize: theme.typography.pxToRem(28),
    lineHeight: theme.typography.pxToRem(38),
    fontFamily: theme.typography.fontFamily,
    fontWeight: '500',
  },
  site_header: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.customGrey2.main}`,
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  site_header_left: {
    fontSize: theme.typography.pxToRem(28),
  },
  site_header_right: {
    fontSize: theme.typography.pxToRem(28),
  },
  site_header_title: {
    fontSize: theme.typography.pxToRem(28),
    lineHeight: theme.typography.pxToRem(38),
    fontFamily: theme.typography.fontFamily,
    fontWeight: '500',
    margin: '0',
  },
  datePicker_wrap: {
    width: 'auto !important',
  },
  selectContainer: {
    // marginRight: theme.spacing(2),
    '& .bs-app-MuiInputBase-root': {
      outline: 'none !important',
    },
    '& .bs-app-MuiSelect-root': {
      height: 36,
      minWidth: '7.5em',
      paddingTop: 0,
      paddingBottom: 0,
      paddingRight: 16,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      fontWeight: 700,
    },
  },
  site_header_back_link: {
    fontSize: '1rem',
    fontWeight: '600',
    color: theme.palette.Blue[600],
    display: 'flex',
    alignItems: 'center',
  },
  site_header_back_icon: {
    display: 'inline-block',
    rotate: '180deg',
    marginLeft: '4px',
  },
  site_header_toggle_btn: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    // paddingRight: theme.spacing(2),
    border: `1px solid ${theme.palette.customGrey2.main}`,
    borderRadius: '100px',
  },
  site_header_toggle_btn_text: {
    textTransform: 'uppercase',
    color: theme.palette.darkRed.main,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(21.6),
    marginRight: theme.spacing(2),
    fontFamily: theme.typography.fontFamily,
  },

  // BACK TO PAGE
  pageBackWrap: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(3),
  },
  pageBackLink: {
    display: 'inline-flex !important',
    alignItems: 'center !important',
    fontSize: '1rem !important',
    lineHeight: '1.8rem !important',
    color: `${theme.palette.Blue[600]} !important`,
    fontWight: '600 !important',
    textDecoration: 'none !important',
  },
  pageBackIcon: {
    marginRight: '0.5rem',
    // marginLeft: '0.5rem',
  },
  // BACK TO PAGE END

  // CONTENT STYLE
  mainWrapper: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  home_page_content: {
    marginTop: '24px',
  },
  home_page_titleWrap: {
    marginBottom: '48px',
  },
  home_page_titleName: {
    fontSize: theme.typography.pxToRem(32),
    lineHeight: theme.typography.pxToRem(57.6),
    fontWeight: 500,
    fontFamily: theme.typography.fontFamily,
    textAlign: 'center',
    margin: '0',
  },
  app_list_wrap: {
    maxWidth: '808px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  app_list_inner: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    border: `1px solid ${theme.palette.customGrey2.main}`,
    borderRadius: '10px',
  },
  app_list_item: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.customGrey2.main}`,
    },

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  app_list_item_icon: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.pending.main,
    borderRadius: '100px',
    top: '50%',
    transform: 'translateY(-50%)',
    position: 'absolute',
    left: '0',
  },
  app_list_item_text: {
    position: 'relative',
    paddingLeft: theme.spacing(12),
    minHeight: '40px',
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.down('md')]: {
      marginBottom: '10px',
    },
  },
  app_list_item_desc: {
    display: 'inline-block',
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(32.4),
    fontWeight: `500`,
  },
  app_list_item_link: {},
  app_list_item_link_item: {
    fontSize: `${theme.typography.pxToRem(16)} !important`,
    lineHeight: `${theme.typography.pxToRem(28.8)} !important`,
    cursor: 'pointer',
    color: `${theme.palette.Blue[600]} !important`,
    textDecoration: 'none !important',
    fontWeight: `500 !important`,
    display: 'flex',
    alignItems: 'center',
    transition: 'all 0.3s',
    '&:hover': {
      opacity: '0.7',
    },
    '&:active': {
      scale: '0.958',
    },
  },
  app_list_item_link_icon: {
    marginLeft: '2px',
  },
  app_list_item_text_wrap: {},
  app_list_item_title: {
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(32.4),
    fontWeight: `500`,
    margin: '0',
  },
  app_list_item_subtitle: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(25.2),
    marginTop: '4px',
  },
  app_list_item_cost: {
    fontSize: theme.typography.pxToRem(20),
    lineHeight: theme.typography.pxToRem(36),
    fontWeight: `500`,
  },
  box_title_wrap: {
    '&:not(:first-child)': {
      marginTop: theme.spacing(7),
    },
    marginBottom: theme.spacing(2),
  },
  box_title: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(28.8),
    fontWeight: `500`,
    textTransform: 'uppercase',
    margin: '0',
  },
  no_data_wrap: {
    padding: theme.spacing(4),
    border: `1px solid ${theme.palette.customGrey2.main}`,
    borderRadius: '10px',
    textAlign: 'center',
  },
  no_data_text: {
    marginTop: theme.spacing(2),
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(28.8),
    color: theme.palette.common.black,
    fontWeight: `500`,
    // marginBottom: 0,
    marginBottom: theme.spacing(2),
  },
  cmn_btn: {
    fontWeight: `400 !important`,
    textTransform: `capitalize`,
  },
  cmn_btn_icon: {
    marginLeft: '8px',
  },
  box1: {
    boxShadow: theme.shadows[1],
    width: theme.spacing(24),
    height: theme.spacing(24),
  },
  box2: {
    boxShadow: theme.shadows[2],
    width: theme.spacing(24),
    height: theme.spacing(24),
  },
  box3: {
    boxShadow: theme.shadows[3],
    width: theme.spacing(24),
    height: theme.spacing(24),
  },
  box4: {
    boxShadow: theme.shadows[4],
    width: theme.spacing(24),
    height: theme.spacing(24),
  },
  box5: {
    boxShadow: theme.shadows[5],
    width: theme.spacing(24),
    height: theme.spacing(24),
  },
  box6: {
    boxShadow: theme.shadows[6],
    width: theme.spacing(24),
    height: theme.spacing(24),
  },
  box7: {
    boxShadow: theme.shadows[7],
    width: theme.spacing(24),
    height: theme.spacing(24),
  },
  badge: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(18),
    textAlign: 'center',
    paddingBottom: theme.spacing(0.5),
    paddingTop: theme.spacing(0.5),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    color: theme.palette.common.black,
    backgroundColor: theme.palette.customGrey.main,
    fontWeight: '500',
    fontFamily: 'jost',
    borderRadius: '100px',
  },
  accepted: {
    color: theme.palette.Green[700],
    backgroundColor: theme.palette.Green[50],
  },
  rejected: {
    color: theme.palette.Red[700],
    backgroundColor: theme.palette.Red[50],
  },
  new: {
    color: theme.palette.BlueLight[700],
    backgroundColor: theme.palette.BlueLight[50],
  },
  pending: {
    color: theme.palette.Warning[700],
    backgroundColor: theme.palette.Warning[50],
  },
  preQualified: {
    color: theme.palette.Purple[700],
    backgroundColor: theme.palette.Purple[50],
  },
  closed: {
    color: theme.palette.Gray[700],
    backgroundColor: theme.palette.Gray[50],
  },
  lenderReview: {
    color: theme.palette.Indigo[700],
    backgroundColor: theme.palette.Indigo[50],
  },
  awaitingClientInfo: {
    color: theme.palette.Blue[700],
    backgroundColor: theme.palette.Blue[50],
  },
  navigateArrowWrap: {
    display: 'flex',
    maxWidth: '64px',
    justifyContent: 'space-between',
  },
  navigateArrow: {
    width: '24px',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '100px',
    color: theme.palette.Gray[900],
    transition: 'all 0.3s',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.Gray[300],
    },
    '&:active': {
      scale: 0.85,
    },
  },
  navigateDisabled: {
    color: theme.palette.Gray[400],
    cursor: 'not-allowed',
    '&:active': {
      scale: 1,
    },
    '&:hover': {
      backgroundColor: theme.palette.Gray[300],
    },
  },
  navigateArrowLeft: {},
  navigateArrowRight: {},
  inputFileWrapper: {},
  inputFileLabel: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    width: '300px',
    height: '105px',
    border: '1px dashed #e8e8e8',
    borderRadius: '10px',
    padding: theme.spacing(2.5),
    cursor: 'pointer',
    '& input': {
      display: 'none',
    },
  },
  inputFileContent: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100%',
  },
  inputFileIcon: {
    marginBottom: '12px',
    width: '40px',
    height: '40px',
    borderRadius: '100px',
    display: 'block',
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    margin: 0,
    backgroundColor: '#F2F4F7',
    boxShadow: '0px 0px 0px 6px #F9FAFB',
    transitionDuration: '0.3s',
    '&:active': {
      scale: '0.85',
    },
    '& svg': {
      width: '100%',
      height: '100%',
    },
  },
  inputFileText: {
    fontSize: `${theme.typography.pxToRem(14)}`,
    lineHeight: `${theme.typography.pxToRem(20)}`,
    fontWeight: '600 !important',
    color: `${theme.palette.Blue[600]} !important`,
    '& span': {
      fontSize: `${theme.typography.pxToRem(14)}`,
      lineHeight: `${theme.typography.pxToRem(20)}`,
      fontWeight: '400',
      color: theme.palette.Gray[500],
      display: 'inline-block',
      marginLeft: '4px',
    },
  },

  uploadedItemList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    marginTop: '1rem',
  },
  uploadedItem: {
    display: 'flex',
    alignItems: 'center',
  },
  docIcon: {
    width: '1.5rem',
    height: '1.5rem',
    display: 'flex',
    '& svg': {
      width: '100%',
      height: '100%',
    },
  },
  listItemText: {
    fontSize: `${theme.typography.pxToRem(16)}`,
    fontWeight: '400',
    lineHeight: `${theme.typography.pxToRem(24)}`,
    color: theme.palette.Gray[500],
    width: 'calc(100% - 65px)',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: '1',
    WebkitBoxOrient: 'vertical',
    // padding: '0 8px',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  listItemAction: {
    display: 'flex',
    gap: 8,
  },
  actionIcon: {
    width: '1.25rem',
    height: '1.25rem',
    display: 'flex',
    '& svg': {
      width: '100%',
      height: '100%',
    },
  },
  downloadIcon: {},
  deleteIcon: {},
  tableActionWrap: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  tableActionPaginationCount: {
    fontFamily: 'Jost !important',
    fontSize: `${theme.typography.pxToRem(16)} !important`,
    fontWeight: '400 !important',
    lineHeight: `${theme.typography.pxToRem(29)} !important`,
    letterSpacing: '0em !important',
  },
  tableActionButtonWrap: {
    display: 'inline-flex',
    flexWrap: 'wrap',
    gap: '1rem',
  },
  tableActionSearchWrap: {
    maxWidth: '561px',
    width: '100%',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  tableActionPaginationWrap: {},
  selectedFilter: {},
  selectedFilterList: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(3.1),
    margin: theme.spacing(0),
    padding: theme.spacing(0),
    listStyleType: 'none',
  },
  selectedFilterItem: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.Gray[200],
    paddingLeft: theme.spacing(3.5),
    paddingRight: theme.spacing(3.5),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderRadius: theme.spacing(2),
  },
  selectedFilterItemText: {
    color: `${theme.palette.Gray[700]} !important`,
    marginRight: `${theme.spacing(2)} !important`,
  },
  selectedFilterRemoveIcon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    display: 'inline-block',
    cursor: 'pointer',
    '& svg': {
      width: '100%',
      height: '100%',
    },
  },

  // DASHBOARD PALETTE CARD
  dashboardPaletteCard: {},
  dashboardPalette: {},
  dashboardPaletteIconText: {
    marginBottom: '20px',
    display: 'flex',
  },
  dashboardPaletteIcon: {
    width: '3.125rem',
    height: '3.125rem',
    backgroundColor: theme.palette.Blue[50],
    borderRadius: '0.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.Blue[600],
  },
  dashboardPaletteTextWrap: {
    color: theme.palette.common.black,
    paddingLeft: theme.spacing(3.2),
    maxWidth: 'calc(100% - 3.125rem)',
    width: '100%',
  },
  dashboardPaletteDesc: {
    marginBottom: '0.25rem !important',
    fontSize: `${theme.typography.pxToRem(14)} !important`,
    lineHeight: `${theme.typography.pxToRem(25.2)} !important`,
    fontWeight: '400',
    color: theme.palette.Gray[900],
    // `${theme.typography.pxToRem(20)} !important`,
  },
  dashboardPaletteDescCount: {
    fontSize: `${theme.typography.pxToRem(18)} !important`,
    lineHeight: `${theme.typography.pxToRem(28)} !important`,
    fontWeight: '500 !important',
    color: `${theme.palette.Gray[900]} !important`,
    display: 'block !important',
  },
  dashboardPaletteLink: {},
  dashboardPaletteLinkItem: {
    display: 'inline-flex !important',
    alignItems: 'center !important',
    fontSize: '1rem !important',
    lineHeight: '1.8rem !important',
    color: `${theme.palette.Blue[600]} !important`,
    fontWight: '600 !important',
    textDecoration: 'none !important',
  },
  dashboardPaletteLinkIcon: {
    marginLeft: '0.5rem',
  },
  // HOME PAGE STYLE START HERE
  commonLinkStyle: {
    display: 'inline-flex !important',
    alignItems: 'center !important',
    fontSize: '14px !important',
    lineHeight: '1.8rem !important',
    color: `${theme.palette.Blue[600]} !important`,
    fontWight: '600 !important',
    textDecoration: 'none !important',
  },
  commonLinkIconStyle: {
    marginLeft: '0.5rem',
  },
  welcomeStripTitleIcon: {},
  welcomeStripTitleItem: {},
  welcomeStripWRap: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
  cardShadow: {
    boxShadow: '0px 2px 24px 0px #9292921A',
    backgroundColor: theme.palette.primary.contrastText,
    borderRadius: theme.spacing(3),
  },
  welcomeStripTitleWrap: {
    paddingLeft: theme.spacing(4),
  },
  welcomeStripTitle: {
    marginBottom: `${theme.spacing(2)} !important`,
    fontSize: `${theme.typography.pxToRem(14)} !important`,
    lineHeight: `${theme.typography.pxToRem(32.4)} !important`,
    fontWeight: '600 !important',
    color: `${theme.palette.Gray[700]} !important`,
  },
  welcomeStripIconText: {
    display: 'flex',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    position: 'relative',
    '&::before': {
      position: 'absolute',
      content: '""',
      backgroundColor: theme.palette.Gray[900],
      opacity: '0.2',
      width: '1px',
      height: '50%',
      left: '-0.9375rem',
      top: '50%',
      transform: 'translateY(-50%)',
    },
  },
  welcomeStripIcon: {
    color: theme.palette.Blue[600],
    backgroundColor: theme.palette.Blue[50],
    width: theme.spacing(12.5),
    height: theme.spacing(12.5),
    display: 'flex',
    alignItems: 'center',
    borderRadius: '10rem',
    justifyContent: 'center',
  },
  welcomeStripTextWrap: {
    color: `${theme.palette.Gray[900]}`,
    width: '100%',
    maxWidth: 'calc(100% - 3.125rem)',
    paddingLeft: theme.spacing(6),
  },
  welcomeStripDesc: {
    color: `${theme.palette.Gray[900]} !important`,
    fontWeight: '400',
    fontSize: `${theme.typography.pxToRem(14)} !important`,
    lineHeight: `${theme.typography.pxToRem(25.2)} !important`,
  },
  welcomeStripDescCount: {
    marginBottom: `${theme.spacing(1)} !important`,
    color: `${theme.palette.Gray[900]} !important`,
    display: 'block !important',
    fontSize: `${theme.typography.pxToRem(18)} !important`,
    lineHeight: `${theme.typography.pxToRem(32.4)} !important`,
    fontWeight: '500 !important',
  },
  teamCard: {},
  c_card: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(4),
    },
    // backgroundColor: theme.palette.primary.contrastText,
  },
  c_cardTitle: {
    display: 'flex',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(6),
  },
  c_cardTitleText: {
    fontSize: `${theme.typography.pxToRem(16)} !important`,
    lineHeight: `${theme.typography.pxToRem(28)} !important`,
    color: `${theme.palette.Gray[600]} !important`,
    fontWeight: '500 !important',
    fontFamily: '"Jost" !important',
  },
  c_cardBody: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
  },
  profileListWrapper: {
    display: 'flex',
    marginBottom: theme.spacing(8),
  },
  profileListItem: {
    width: `${theme.spacing(12)} !important`,
    height: `${theme.spacing(12)} !important`,
    fontSize: `${theme.typography.pxToRem(18)} !important`,
    lineHeight: `${theme.typography.pxToRem(28)} !important`,
    border: `1px solid ${theme.palette.Gray[200]}`,
    borderRadius: `${theme.spacing(16)} !important`,
    position: 'relative',
    '& + *': {
      zIndex: '9',
      marginLeft: theme.spacing(-2.75),
    },
    cursor: 'pointer',
  },
  NewApplicationCard: {},
  NewApplicationList: {},
  NewApplicationListEmpty: {
    minHeight: '135px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'left',
  },
  NewApplicationItem: {
    position: 'relative',
    width: '100%',
    paddingRight: theme.spacing(12),
    borderBottom: `1px solid #e8e8e8`,
    paddingBottom: theme.spacing(6),
    '&:not(:last-child)': {
      marginBottom: theme.spacing(5),
      '& > label': {
        top: theme.spacing(5),
      },
    },
  },
  reminderItem: {
    position: 'relative',
    width: '100%',
    lineHeight: '1.4 !important',
    borderBottom: `1px solid #e8e8e8`,
    '&:not(:last-child)': {
      marginBottom: theme.spacing(5),
      '& > label': {
        top: theme.spacing(5),
      },
    },
  },
  NewApplicationLabel: {
    color: `${theme.palette.Blue[600]} !important`,
    fontWeight: 500,
    position: 'absolute',
    top: '0',
    right: '0',
    paddingLeft: theme.spacing(3.5),
    '&::before': {
      position: 'absolute',
      content: '""',
      width: theme.spacing(1.5),
      height: theme.spacing(1.5),
      backgroundColor: theme.palette.Blue[600],
      left: theme.spacing(0.5),
      top: '50%',
      transform: 'translateY(-50%)',
      borderRadius: theme.spacing(6),
    },
  },
  NewApplicationTitle: {
    color: `${theme.palette.Gray[700]} !important`,
    marginBottom: `${theme.spacing(2)} !important`,
    fontWeight: 500,
  },
  NewApplicationAmount: {
    fontWeight: 700,
    color: `${theme.palette.Gray[700]} !important`,
    marginBottom: `${theme.spacing(2)} !important`,
  },
  NewApplicationTime: {
    fontWeight: 400,
    color: `${theme.palette.Gray[500]} !important`,
  },

  emptyDataWrap: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  emptyDataImage: {},
  emptyDataText: {
    color: `${theme.palette.Gray[600]} !important`,
    marginTop: `${theme.spacing(2)} !important`,
  },
  statusOverviewCard: {
    height: '100%',
    padding: '24px 32px',
    '& .c_cardBody': {
      height: 'calc(100% - 52px)',
    },
  },
  flex_item: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  filter_cardTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px',

    '& h3': {
      fontWeight: '500',
    },
  },
  closeFilterIcon: {
    fontSize: '24px',
  },
  filter_cardBody: {
    padding: '0px 16px 24px 16px',
  },
  filter_tabingBox: {
    borderBottom: `1px solid ${theme.palette.grey[50]}`,
    marginBottom: '24px',
  },
  filter_checkBoxGrp: {
    display: 'flex',
    flexDirection: 'column',
  },
  filter_cardFooter: {
    padding: '24px 16px',
    borderTop: `1px solid ${theme.palette.grey[50]}`,
  },
  headingStripIconText: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    gap: '1rem',
  },
  headingStripIcon: {
    width: '3.25rem',
    height: '3.25rem',
    backgroundColor: theme.palette.primary.contrastText,
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.Gray[900],
    boxShadow: theme.shadows[6],
  },
  headingStripTextWrap: {
    position: 'relative',
    color: theme.palette.Gray[700],
    '& h3': {
      fontWeight: '500',
    },
  },
  fontJost: {
    fontFamily: 'jost !important',
  },
  tabInfoWrap: {
    padding: '1.5rem 4.25rem',
    display: 'flex',
    flexWrap: 'wrap',
  },
  tabCol: {
    position: 'relative',
  },
  //  headingStripIconText
  headingSubStripIconText: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    gap: '0.5rem',
  },
  headingSubStripIcon: {
    width: '2rem',
    height: '2rem',
    backgroundColor: theme.palette.Blue[50],
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.Blue[600],
  },
  headingSubStripTextWrap: {
    position: 'relative',
    color: theme.palette.Gray[700],
    '& h4': {
      fontWeight: '500',
    },
  },
  icon18: {
    width: '1.125rem !important',
    height: '1.125rem !important',
  },
  tabGap_160: {
    gap: '10rem',
  },
  //  headingStripIconText End
  // KeyValue
  listKeyValueWrap: {
    position: 'relative',
    padding: `${theme.spacing(0)} !important`,
    marginBottom: `${theme.spacing(10)} !important`,
  },
  listKeyValueItem: {
    position: 'relative',
    paddingLeft: `${theme.spacing(0)} !important`,
    paddingRight: `${theme.spacing(0)} !important`,
    '&:first-child': {
      paddingTop: `${theme.spacing(0)} !important`,
    },
  },
  listKeyValueBox: {
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '0.5rem',
  },
  listKeyValueBox_key: {
    position: 'relative',
    width: '8.5rem',
  },
  listKeyValueBox_value: {
    position: 'relative',
    width: '13rem',
  },
  flex: {
    display: 'flex',
  },
  flexCol: {
    flexDirection: 'column',
  },
  gap_24: {
    gap: '1.5rem',
  },
  gray_500: {
    color: `${theme.palette.Gray[500]} !important`,
  },
  gray_600: {
    color: `${theme.palette.Gray[600]} !important`,
  },
  primary_600: {
    color: `${theme.palette.Blue[600]} !important`,
  },
  gray_700: {
    color: `${theme.palette.Gray[700]} !important`,
  },
  lineHeight_25: {
    lineHeight: `${theme.typography.pxToRem(25)} !important`,
  },
  tabColLine: {
    position: 'relative',
    '&::before': {
      position: 'absolute',
      content: '""',
      backgroundColor: theme.palette.Gray[900],
      opacity: '0.2',
      width: '1px',
      height: '100%',
      right: '-3rem',
      top: '0%',
    },
  },
  py_16: {
    paddingTop: `${theme.spacing(4)} !important`,
    paddingBottom: `${theme.spacing(4)} !important`,
  },
  borderBottomGray: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  listKeyValueTitleWrap: {
    paddingTop: `${theme.spacing(4)} !important`,
    paddingBottom: `${theme.spacing(4)} !important`,
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    gap: '0.5rem',
    flexDirection: 'column',
    display: 'flex',
  },
  listKeyValueTitleItem: {
    position: 'relative',
    padding: `${theme.spacing(0)} !important`,
  },
  fontWeight_500: {
    fontWeight: '500!important',
  },
  fontWeight_400: {
    fontWeight: '400!important',
  },
  listKeyValueBox_title: {
    width: '100%',
  },
  width_100: {
    width: '100% !important',
  },
  gap_16: {
    gap: '1rem !important',
  },
  gap_32: {
    gap: '2rem !important',
  },
  border_0: {
    border: `0px solid ${theme.palette.grey[200]} !important`,
  },
  stepWrap: {
    position: 'relative',
  },
  tabGap_144: {
    gap: '9rem !important',
  },
  Gap_24: {
    gap: '1.5rem !important',
  },
  tabInfoLeft: {
    width: '13.5rem',
  },
  tabInfoRight: {
    position: 'relative',
    maxWidth: '32.75rem',
    width: '100%',
  },
  tabInfoRight_box: {
    position: 'relative',
  },
  formInlineLblValue: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  formInlineLblValue_lbl: {
    position: 'relative',
  },
  formInlineLblValue_value: {
    position: 'relative',
    width: '21.5rem',
  },
  formSelect: {
    borderRadius: '8px',
    border: `1px solid #8C92AC`,
    backgroundColor: theme.palette.primary.contrastText,
    padding: '12px 14px',
    width: '100%',
  },
  tabInfoRight_space: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    paddingBottom: '3rem',
    marginBottom: '4rem',
  },
  textareaWrap: {
    position: 'relative',
  },
  textareaLblWrap: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '0.5rem',
  },
  textareaLblWrap_lbl: {
    position: 'relative',
  },
  textareaLblWrap_action: {
    position: 'relative',
  },
  textareaCustom: {
    width: '100%',
    padding: '0rem !important',
  },
  textareaWrapInner: {
    marginBottom: '1rem',
  },
  stepLine: {
    position: 'relative',
    '&::before': {
      position: 'absolute',
      content: '""',
      backgroundColor: theme.palette.Gray[200],
      width: '1px',
      margin: '0 auto',
      height: 'calc(100% - 2rem)',
      left: '0',
      right: '0',
      top: '2rem',
    },
  },
  black_500: {
    color: '#000000',
  },
  inputIconLeft: {
    width: '24px',
    height: '44px',
    display: 'flex',
    alignItems: 'center',
    borderRight: `1px solid #8C92AC`,
  },
  teamAvatar: {
    backgroundColor: '#FFFFFF!important',
    border: `1px solid ${theme.palette.primary.dark}`,
    color: theme.palette.primary.dark,
  },
  datePickerContainer: {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid',
    borderColor: '#D0D5DD',
    borderRadius: '4px',
    padding: '0 0.5rem',
    [theme.breakpoints.down(494)]: {
      marginTop: theme.spacing(2),
    },
  },
  home_datePicker: {
    '&:before': {
      display: 'none !important',
    },
    '& input': {
      width: '100px !important',
      textAlign: 'left !important',
    },
  },
  subtitle: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20),
  },
  semibold: {
    fontFamily: 'inter semibold',
  },
  select: {
    root: {
      '&:hover:not($disabled):before': {
        borderColor: '#D0D5DD',
      },
      '&.Mui-focused:before': {
        borderColor: '#D0D5DD',
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#D0D5DD',
    },
    '&:focus-within .MuiOutlinedInput-notchedOutline': {
      borderColor: '#D0D5DD',
    },
  },
  rightBorder: {
    position: 'relative',
    '&::before': {
      position: 'absolute',
      content: '""',
      backgroundColor: theme.palette.Gray[900],
      opacity: '0.2',
      width: '1px',
      height: '100%',
      right: '0',
      top: '50%',
      transform: 'translateY(-50%)',
    },
  },
  chartGroup: {
    height: '600px',
  },
  statusOverViewPageNotFound: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.Gray[700],
  },
  selectBoxDropdown: {
    color: `${theme.palette.Gray[700]} !important`,
    borderRadius: '8px !important',
    fontWeight: '400 !important',
    '& .bs-app-MuiSelect-root': {
      fontWeight: '400 !important',
    },
  },
  label_style: {
    fontFamily: 'Cerebri Sans Pro !important',
    fontSize: '14px !important',
    fontStyle: 'normal !important',
    fontWeight: '400 !important',
    lineHeight: '28px !important',
    color: `#475467 !important`,
    textAlign: 'left',
    paddingLeft: '15px',
  },
  reminderSubtitle: {
    color: `${theme.palette.Gray[700]} !important`,
    fontFamily: 'Jost !important',
    fontSize: '14px !important',
    fontStyle: 'normal !important',
    maxWidth: '260px',
    fontWeight: '700 !important',
    lineHeight: '28px !important',
  },
  checkbox_div: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'space-between',
    width: '100%',
  },
  marginTop0px: {
    paddingTop: '0px !important',
    paddingLeft: '23px !important',
  },
  paddingLeft23px: {
    paddingLeft: '23px !important',
  },
  reminder_cardBody: {
    padding: '18px 23px',
  },
  status_overview_icon_padding: {
    padding: '9.816px 7.705px 12.153px 14.263px',
  },

  mainWrapperColor: {
    backgroundColor: '#FCFCFD',
  },
  dateDesc: {
    color: '#000',
    fontWeight: '500',
    fontSize: '16px',
  },
  welcomeHelloTitle: {
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '32px',
  },

  cardInGrid: {
    boxShadow: '0px 1px 2px 0px #1018280D',
    padding: '24px',
    backgroundColor: '#fff',
    height: '100%',
    borderRadius: '1rem',
  },
  gridPadding: {
    padding: '1.5rem 2rem 0 2rem',
  },
  MAinTitle: {
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '24px',
    textAlign: 'left',
  },
  MAinBadge: {
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '18px',
    textAlign: 'center',
    background: theme.palette.Gray[100],
    padding: ' 2px 7px ',
  },
  TitleWrap: {
    paddingBottom: '0.5rem',
  },
  primeList: {
    marginBlockStart: '0em',
    marginBlockEnd: '0em',
    fontSize: '1rem !important',
    fontWeight: '500 !important',
    lineHeight: '24px !important',
  },
  prime2List: {
    marginBlockStart: '0em',
    marginBlockEnd: '0em',
    fontSize: '0.875rem !important',
    fontWeight: '400 !important',
    lineHeight: '20px !important',
  },
  BtnTitleWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '1rem',
    alignItems: 'center',
    width: '100%',
  },

  heightScroll: {
    width: '100%',
    maxHeight: '230px',
    overflowY: 'auto',
    marginBottom: '0.5rem',
    marginTop: '0.5rem',
    '&::-webkit-scrollbar': {
      width: '3px',
      height: '3px',
    },

    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 5px rgb(255, 251, 251)',
      borderRadius: '10px',
    },

    '&::-webkit-scrollbar-thumb': {
      background: `${theme.palette.Gray[300]} !important `,
      borderRadius: '10px',
    },
  },
  appBox: {
    borderBottom: '1px solid',
    width: '100%',
    borderColor: `${theme.palette.Gray[200]} !important `,
    margin: '0.5rem 0 1.5rem 0',
    paddingBottom: '1rem',
    cursor: 'pointer',
  },
  btnBox: {
    width: '100%',
    margin: '0rem',
    paddingBottom: '0rem',
  },
  appAmount: {
    padding: '0.5rem 0 0.75rem 0',
    fontSize: '1rem !important',
    fontWeight: '700 !important',
    lineHeight: '24px !important',
  },
  appTitle: {
    fontSize: '14px !important',
    fontWeight: '500 !important',
    lineHeight: '20px !important',
  },
  appStatus: {
    fontSize: '12px !important',
    fontWeight: '500 !important',
    lineHeight: '18px !important',
    color: '#027A48',
  },
  appTime: {
    fontSize: '12px !important',
    fontWeight: '500 !important',
    lineHeight: '18px !important',
    color: '#667085  !important',
  },
  offerList: {
    fontSize: '14px !important',
    fontWeight: '400 !important',
    lineHeight: '14px !important',
  },
  offer2List: {
    fontSize: '12px !important',
    fontWeight: '400 !important',
    lineHeight: '18px !important',
    color: '#667085  !important',
    paddingTop: '4px !important',
  },
  dueHeadTitle: {
    fontSize: '16px !important',
    fontWeight: '500 !important',
    lineHeight: '24px !important',
    marginBottom: '1.5rem !important',
  },
  actionBox: {
    border: '1px solid !important',
    borderColor: `${theme.palette.Gray[300]} !important `,
    padding: '1rem 14px  !important',
    marginBottom: '12px  !important',
    borderRadius: '0.5rem  !important',
  },
  actionTitle: {
    fontSize: '16px !important',
    fontWeight: '400 !important',
    lineHeight: '24px !important',
  },
  action2Title: {
    fontSize: '12px !important',
    fontWeight: '400 !important',
    lineHeight: '18px !important',
  },
  checkTop: {
    top: '0% !important',
  },
  heightLEftBottom: {
    width: '100%',
    maxHeight: '1080px',
    overflowY: 'auto',
    marginBottom: '0.5rem',
    marginTop: '0.5rem',
    '&::-webkit-scrollbar': {
      width: '3px',
      height: '3px',
    },

    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 5px rgb(255, 251, 251)',
      borderRadius: '10px',
    },

    '&::-webkit-scrollbar-thumb': {
      background: `${theme.palette.Gray[300]} !important `,
      borderRadius: '10px',
    },
  },
  bgChart: {
    background: `${theme.palette.Gray[300]} !important `,
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  cardGrey: {
    background: `${theme.palette.Gray[50]} !important `,
    border: '1px solid',
    borderColor: `${theme.palette.Gray[200]} !important `,
    borderRadius: '0.5rem',
    padding: '0.5rem',
    boxShadow: '0px 1px 2px 0px #1018280D',
  },
}));

export const MaterialUISwitch = styled(Switch)(() => ({
  width: 22,
  height: 14,
  padding: 0,
  margin: '0 0 0 8px',
  '& .MuiSwitch-switchBase': {
    margin: '0',
    padding: 0,
    transform: 'translateX(0px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#98A2B3',
    width: 13,
    height: 13,
  },
  '& .MuiSwitch-track': {
    width: '100%',
    height: '9px',
    border: '2px solid #98A2B3',
    borderRadius: '100px',
    backgroundColor: 'transparent !important',
    top: '50%',
    position: 'absolute',
    transform: 'translateY(-50%)',
    left: 0,
  },
}));

export const CustomInput = styled(InputBase)(() => ({
  '& .MuiInputBase-input': {},
}));

export default useStyles;
