import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  title: {
    fontSize: theme.typography.pxToRem(17),
    lineHeight: theme.typography.pxToRem(16),
    marginBottom: 0,
    color: theme.palette.grey[700],
    display: 'flex',
    alignItems: 'center',
  },
  titleAvatar: { marginRight: 5, background: theme.palette.primary.main },
  bold: {},
  cardContainer: {
    border: `1px solid grey`,
    height: 214,
  },
  cardsContainer: {
    marginBottom: theme.spacing(2),
  },
  filterBox: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(7),
  },
  select: {
    root: {
      '&:hover:not($disabled):before': {
        borderColor: '#D0D5DD',
      },
      '&.Mui-focused:before': {
        borderColor: '#D0D5DD',
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#D0D5DD',
    },
    '&:focus-within .MuiOutlinedInput-notchedOutline': {
      borderColor: '#D0D5DD',
      backgroundColor: 'transparent',
    },
  },
  flexWithAlign: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
}));
