import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  controlLabel: {
    height: 30,
    '& .MuiButtonBase-root .MuiSvgIcon-root': {
      fontSize: theme.typography.pxToRem(18),
    },
    '& .MuiFormControlLabel-label': {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      fontWeight: 'bold',
      color: theme.palette.common.black,
      marginLeft: theme.spacing(0.5),
    },
  },
  formLblValue: {
    marginBottom: '1.5rem !important',
  },
  fontWeight_500: {
    fontWeight: '500!important',
  },
  gray_500: {
    color: `${theme.palette.Gray[500]} !important`,
  },
  fontJost: {
    fontFamily: 'jost !important',
  },
}));
