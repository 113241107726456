import Papa from 'papaparse';
import { LendersAction } from 'store/admin/types';

export const readLendersCSV = async (file: string): Promise<LendersAction[]> => {
  const parsedCSV = Papa.parse(file, {
    delimiter: ',',
    header: false,
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getFieldIndex = (name: string) => {
    return ((parsedCSV?.data?.at(0) as string[]) || [])?.findIndex((d) => {
      return d === name;
    });
  };
  const transformedData: LendersAction[] = parsedCSV.data
    ?.slice(1)
    ?.filter((d) => (d as object[])?.length > 2)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .map((row: any) => {
      const transformedRow: LendersAction = {
        name: row[getFieldIndex('Name') as number] || '', // Provide default value if necessary
        displayed_name: row[getFieldIndex('Contact_person_name') as number] || '',
        contact_first_name: row[getFieldIndex('Contact_person_name') as number]?.split(' ')[0] || '',
        contact_last_name: row[getFieldIndex('Contact_person_name') as number]?.split(' ')[1] || '',
        contact_email: row[getFieldIndex('Contact_person_email') as number] || '',
        active: ['true', 'yes']?.includes((row[getFieldIndex('Active') as number] as string)?.toLowerCase()),
        address: row?.address || '',
        logo_url: row[getFieldIndex('Logo_url') as number] || '',
        website: row?.website || '',
        supported_regions: (row[getFieldIndex('Supported_regions')] as string)?.split(','),
      };
      return transformedRow;
    });
  return transformedData;
};
