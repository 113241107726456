import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  modal_main: {
    width: '620px',
  },
  c_card: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(4),
    },
  },
  c_cardTitle: {
    display: 'flex',
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
  },
  c_cardTitleText: {
    fontSize: `${theme.typography.pxToRem(16)} !important`,
    lineHeight: `${theme.typography.pxToRem(28)} !important`,
    color: `${theme.palette.Gray[600]} !important`,
    fontWeight: '500 !important',
    fontFamily: 'Jost !important',
  },
  c_cardBody: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
  },
  cardShadow: {
    boxShadow: '0px 2px 24px 0px #9292921A',
    backgroundColor: theme.palette.primary.contrastText,
    borderRadius: theme.spacing(3),
    overflowX: 'hidden',
  },
  filter_cardBody: {
    padding: '0px 16px 0px 16px',
  },
  filter_cardTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px',

    '& h3': {
      fontWeight: '500',
    },
  },
  closeFilterIcon: {
    fontSize: '24px',
  },
  closeFilterIconEnd: {
    fontSize: '24px',
    width: '20px',
    height: '20px',
    marginLeft: 'auto !important',
    minWidth: '24px !important',
    minHeight: '24px !important',
    color: `${theme.palette.Gray[700]} !important`,
    '& .MuiSvgIcon-root': {
      marginRight: '0 !important',
    },
  },
  ul_style: {
    padding: '0px',
    listStyle: 'none',
    columnCount: 2,
  },
  modalIconMargin: {
    margin: '0 auto 1rem',
  },
  modalTitle: {
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(27),
    color: `${theme.palette.Gray[700]} !important`,
    fontWeight: 700,
    display: 'block',
    maxWidth: '432px',
    margin: '0 auto 8px !important',
    textAlign: 'center',
    fontFamily: 'Jost !important',
  },
  modalIconBorder: {
    width: '3.5rem',
    height: '3.5rem',
    backgroundColor: theme.palette.Blue[100],
    border: `10px solid ${theme.palette.Blue[50]}`,
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.Blue[600],
  },
  modalFooter: {
    marginTop: theme.spacing(8),
  },
  flexWithGap: {
    display: 'flex',
    gap: '10px !important',
  },
}));
