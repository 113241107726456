import { PreAuthSession } from 'core/types';
import { RegisterData, RegisterAccountData } from '../../store/auth/types';

import { apiCall } from '../index';

export const initAuth = async (email: string): Promise<null> =>
  apiCall({ method: 'post', url: '/auth/init', data: { email: email.toLowerCase() } });

export const confirmAuth = async (email: string, auth_code: string): Promise<{ accessToken: string }> => {
  const data = await apiCall<{ access_token: string }>({
    method: 'post',
    url: '/auth/confirm',
    data: { email, auth_code },
  });
  return { accessToken: data.access_token };
};

export const register = async (registerData: RegisterData): Promise<null> =>
  apiCall({ method: 'post', url: '/auth/register/invitation', data: registerData });

export const confirmPasswordReset = async (token: string, password: string): Promise<null> =>
  apiCall({ method: 'post', url: '/auth/password_reset/confirm', data: { token, password } });

export const initPasswordReset = async (email: string): Promise<null> =>
  apiCall({ method: 'post', url: '/auth/password_reset/init', data: { email } });

export const confirmPreAuthSession = async (token: string, email: string): Promise<PreAuthSession> =>
  apiCall({ method: 'post', url: '/auth/pre_auth/confirm', data: { token, email } });

export const registerAccount = async (accountData: RegisterAccountData): Promise<null> =>
  apiCall({ method: 'post', url: '/auth/register/account', data: accountData });
