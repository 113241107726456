import { Grid, Box, Typography } from '@mui/material';
import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';

import ReactChart from 'components/Chart/ReactChart';
import CustomSelectBox from 'components/CustomSelect/CustomSelect';
import { Header } from 'components/Header/Header';
import { overviewFilterOptions } from 'core/constants';
import { OverviewDateRange, UserQuery, UserRole } from 'core/types';
import { getApplicationsCount, getLendersCount, getOffersCount } from 'http/dashboard';
import moment from 'moment';
import { isValidArray, isValidObject } from 'pages/leads/components/CreateApplicationModal/utils';
import ReactApexChart from 'react-apexcharts';
import { useAdmin } from 'store/admin/hooks';
import { useApplications } from 'store/applications/hooks';
import { IDashboardData } from 'store/applications/types';
import { useAuth } from 'store/auth/hooks';
import { useLenders } from 'store/lenders/hooks';
import { usePartners } from 'store/partners/hooks';
import { getDateRangeByFilter } from 'utils';
import useCommonStyles from '../../assets/css/Common.styles';
import { ReactComponent as Lenders } from '../../assets/icons/LendersIcon.svg';
import { ReactComponent as LoanApplicationIcon } from '../../assets/icons/loanApplicationHome.svg';
import { ReactComponent as NoFoundData } from '../../assets/icons/no_data_found.svg';
import { ReactComponent as Partners } from '../../assets/icons/partner.svg';
import { ReactComponent as Users } from '../../assets/icons/partnersIcon.svg';
import useStyles from './Dashboard.styles';

const adminQuery: UserQuery = {
  per_page: 1000,
  page: 1,
  role: `${UserRole.ADMIN},${UserRole.SUPER_ADMIN}`,
};

interface ITextColor {
  color: string[];
  text: string[];
}

interface IDashboardChartColorAndText {
  application_status: ITextColor;
  offer_status: ITextColor;
  active_lenders: ITextColor;
}

const dashboardChartColorAndText: IDashboardChartColorAndText = {
  application_status: {
    color: ['#165DFF', '#14C9C9', '#F7BA1E', '#722ED1', '#3491FA', '#D91AD9', '#FF7D00'],
    text: ['New', 'Pending', 'Pre-qualified', 'Lender review', 'Lender offer accepted', 'Closed', 'Rejected'],
  },
  offer_status: {
    color: ['#165DFF', '#14C9C9', '#F7BA1E', '#722ED1', '#3491FA', '#9FDB1D'],
    text: ['Offered', 'Declined', 'Additional details requested', 'Accepted', 'Lender called failed', 'Rule declined'],
  },
  active_lenders: {
    color: ['#306FFF', '#F7C034', '#E51723'],
    text: ['Iwoca', 'Youlend', 'Fleximize'],
  },
};

const Dashboard: FC = () => {
  const [statusOverViewFilter, setStatusOvertViewFilter] = useState<{
    fromDate: Date;
    toDate: Date;
    monthData: string;
  }>({
    fromDate: moment().subtract(1, 'weeks').subtract(1, 'days').toDate(),
    toDate: moment().endOf('day').toDate(),
    monthData: '',
  });
  const [statusOverViewData, setStatusOverviewData] = useState<{
    application_status: number[];
    offer_status: number[];
    active_lenders: number[];
  }>({
    application_status: [0, 0, 0, 0, 0, 0, 0],
    offer_status: [0, 0, 0, 0, 0, 0],
    active_lenders: [0, 0, 0, 0],
  });

  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { isAdmin } = useAuth();
  const { getAdminUsers, total: totalTeamMembers } = useAdmin();
  const { getAllPartners, allPartners } = usePartners();
  const { getAllLenders, allLenders } = useLenders();
  const { getApplications, total: totalApplication } = useApplications();

  const dashboardCount = [
    {
      title: 'Team members',
      icon: <Users />,
      count: totalTeamMembers || 0,
    },
    {
      title: 'Loan applications',
      icon: <LoanApplicationIcon />,
      count: totalApplication || 0,
    },
    {
      title: 'Lenders',
      icon: <Lenders />,
      count: allLenders?.length || 0,
    },
    {
      title: 'Partners',
      icon: <Partners />,
      count: allPartners?.length || 0,
    },
  ];

  const handleDateRangeChange = (
    event: ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => {
    const dateRangeFilter = event.target.value as OverviewDateRange;
    const { start, end } = getDateRangeByFilter(dateRangeFilter);
    setStatusOvertViewFilter({ ...statusOverViewFilter, monthData: dateRangeFilter, fromDate: start, toDate: end });
  };

  const checkNullValue = (data: object) => !Object.values(data)?.some((d) => d === null);

  const checkChartData = (data: number[]) => data?.every((d) => d === 0);

  const getValue = (data: { count: number; lender: { displayedName: string } }[], value: string): number => {
    return data?.find((d) => d?.lender?.displayedName === value)?.count || 0;
  };

  const fetchDashboardData = useCallback(async () => {
    const [applicationsCount, offersCount, lendersCount] = await Promise.all([
      getApplicationsCount({ start: statusOverViewFilter?.fromDate, end: statusOverViewFilter?.toDate }),
      getOffersCount({ start: statusOverViewFilter?.fromDate, end: statusOverViewFilter?.toDate }),
      getLendersCount({ start: statusOverViewFilter?.fromDate, end: statusOverViewFilter?.toDate }),
    ]);

    const dashboardData: IDashboardData = {
      applicationsCount,
      offersCount,
      lendersCount,
    };

    const application = dashboardData?.applicationsCount;
    const offer = dashboardData?.offersCount;
    const lenders = dashboardData?.lendersCount;

    setStatusOverviewData({
      ...statusOverViewData,
      ...(checkNullValue(offer) &&
        isValidObject(offer) && {
          offer_status: [
            offer?.OFFERED,
            offer?.DECLINED,
            offer?.ADDITIONAL_DETAILS_REQUESTED,
            offer?.ACCEPTED,
            offer?.LENDER_CALL_FAILED,
            offer?.RULES_DECLINED,
          ],
        }),
      ...(checkNullValue(application) &&
        isValidObject(application) && {
          application_status: [
            application?.NEW,
            application?.PENDING,
            application?.PREQUALIFIED,
            0,
            application?.OFFER_ACCEPTED,
            application?.CLOSED,
            application?.REJECTED,
          ],
        }),
      ...(isValidArray(lenders) && {
        active_lenders: [getValue(lenders, 'Iwoca'), getValue(lenders, 'Youlend'), getValue(lenders, 'Fleximize')],
      }),
    });
    // eslint-disable-next-line
  }, [statusOverViewFilter]);

  useEffect(() => {
    if (isAdmin) {
      getAllPartners();
      getAllLenders();
      getApplications();
      getAdminUsers(adminQuery);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isAdmin) return;
    getAdminUsers(adminQuery);
  }, [getAdminUsers, isAdmin]);

  useEffect(() => {
    fetchDashboardData();
  }, [fetchDashboardData]);

  return (
    <Box>
      <Header name="Dashboard" />
      <Box className={commonClasses.mainWrapper} sx={{ mt: 6 }}>
        <Typography className={commonClasses.welcomeStripDesc} component="p" fontWeight="500" sx={{ mb: 3 }}>
          OVERVIEW
        </Typography>
        <Box className={clsx([commonClasses.welcomeStripWRap, commonClasses.cardShadow, commonClasses.borderGray])}>
          <Grid container spacing={4} sx={{ alignItems: 'center' }}>
            {dashboardCount?.map((d, ind) => {
              return (
                <Grid item xs={3}>
                  <Box
                    sx={{ py: 2 }}
                    className={clsx([
                      ind === 0 && commonClasses.welcomeStripIconTextRemove,
                      commonClasses.welcomeStripIconText,
                    ])}
                  >
                    <Box className={commonClasses.welcomeStripIconGray}>{d?.icon}</Box>
                    <Box className={commonClasses.welcomeStripTextWrap}>
                      <Typography className={commonClasses.welcomeStripDescCount_24} component="span">
                        {d?.count}
                      </Typography>
                      <Typography className={commonClasses.welcomeStripDesc} component="p">
                        {d?.title}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>
        <Box className={clsx([classes.marginTop56px, commonClasses.flexSpaceBetween])} sx={{ alignItems: 'center' }}>
          <Box>
            <Typography sx={{ mb: 5 }} fontWeight="500" className={clsx([classes.chart_label_style])}>
              CHART
            </Typography>
          </Box>
          <Box className={clsx([commonClasses.flexWithGap16])} sx={{ alignItems: 'center' }}>
            <Box>
              <Typography variant="text_SM" className={clsx([classes.select_label_style])}>
                Showing:
              </Typography>{' '}
            </Box>
            <Box sx={{ justifyContent: 'flex-end' }}>
              <CustomSelectBox
                name="week"
                onChange={handleDateRangeChange}
                value={statusOverViewFilter?.monthData}
                options={overviewFilterOptions}
                className={classes.width236px}
                formik={false}
                mainDivClassShow={false}
              />
            </Box>
          </Box>
        </Box>
        <Box>
          <Grid container spacing={4} sx={{ alignItems: 'start' }}>
            <Grid item xs={6} className={clsx([classes.border_RightGray])}>
              <Box sx={{ py: 5, pr: 6 }}>
                <Typography sx={{ mb: 5 }} fontWeight="500" className={clsx([classes.label_style])}>
                  Application trend
                </Typography>
                <ReactChart
                  xaxisCategories={[1, 2, 3, 4, 5, 6, 7]}
                  colors={['#165DFF']}
                  type="area"
                  series={[100, 50, 200, 150, 300, 600]}
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ py: 5, pl: 6 }}>
                {!checkChartData(statusOverViewData?.application_status) ? (
                  <>
                    {' '}
                    <Typography sx={{ mb: 5 }} fontWeight="500" className={clsx([classes.label_style])}>
                      Application status
                    </Typography>
                    <ReactApexChart
                      options={{
                        labels: dashboardChartColorAndText?.application_status?.text,
                        colors: dashboardChartColorAndText?.application_status?.color,
                        dataLabels: {
                          enabled: false,
                        },
                      }}
                      series={statusOverViewData?.application_status}
                      type="pie"
                      width="500px"
                      height="500px"
                    />
                  </>
                ) : (
                  <Box className={commonClasses.flexSpaceCenter}>
                    <Box>
                      <NoFoundData />
                      <Typography className={clsx([classes.label_style])}>No data to display</Typography>
                    </Box>
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <hr className={clsx([classes.opacity_line])} />
              </Box>
            </Grid>
          </Grid>
          <Grid sx={{ mb: 10, alignItems: 'start' }} container spacing={4}>
            <Grid item xs={6} className={clsx([classes.border_RightGray])}>
              <Box sx={{ py: 5, pr: 6 }} className={clsx([classes.reactApexCSS])}>
                {!checkChartData(statusOverViewData?.offer_status) ? (
                  <>
                    <Typography sx={{ mb: 5 }} fontWeight="500" className={clsx([classes.label_style])}>
                      Offer status
                    </Typography>
                    <ReactApexChart
                      options={{
                        chart: {
                          width: 380,
                          type: 'polarArea',
                        },
                        labels: dashboardChartColorAndText?.offer_status?.text,
                        fill: {
                          opacity: 1,
                        },
                        stroke: {
                          width: 1,
                          colors: undefined,
                        },
                        yaxis: {
                          show: false,
                        },
                        plotOptions: {
                          polarArea: {
                            rings: {
                              strokeWidth: 0,
                            },
                            spokes: {
                              strokeWidth: 0,
                            },
                          },
                        },
                        colors: dashboardChartColorAndText?.offer_status?.color,
                      }}
                      series={statusOverViewData?.offer_status}
                      type="polarArea"
                      width="100%"
                      height="300px"
                    />
                  </>
                ) : (
                  <Box className={commonClasses.flexSpaceCenter}>
                    <Box>
                      <NoFoundData />
                      <Typography className={clsx([classes.label_style])}>No data to display</Typography>
                    </Box>
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ py: 5, pl: 6 }} className={clsx([classes.reactApexCSS])}>
                {!checkChartData(statusOverViewData?.active_lenders) ? (
                  <>
                    <Typography sx={{ mb: 5 }} fontWeight="500" className={clsx([classes.label_style])}>
                      Most active lenders
                    </Typography>
                    <ReactApexChart
                      options={{
                        labels: dashboardChartColorAndText?.active_lenders?.text,
                        colors: dashboardChartColorAndText?.active_lenders?.color,
                        chart: {
                          width: 380,
                        },
                        dataLabels: {
                          enabled: false,
                        },
                        responsive: [
                          {
                            breakpoint: 480,
                            options: {
                              chart: {
                                width: 200,
                              },
                              legend: {
                                show: false,
                              },
                            },
                          },
                        ],
                        legend: {
                          position: 'right',
                          offsetY: 0,
                          height: 230,
                        },
                        plotOptions: {
                          pie: {
                            donut: {
                              labels: {
                                show: true,
                                total: {
                                  showAlways: true,
                                  show: true,
                                  label: 'Most active lenders',
                                  color: '#36454F',
                                  fontFamily: 'Cerebri Sans Pro',
                                  fontSize: '11px',
                                  fontWeight: '400',
                                  formatter(w) {
                                    return w.globals.seriesTotals.reduce((a: number, b: number) => a + b, 0);
                                  },
                                },
                              },
                            },
                          },
                        },
                        annotations: {
                          points: [
                            {
                              x: '50%',
                              marker: {
                                size: 0,
                              },
                              label: {
                                text: '100',
                                style: {
                                  fontSize: '20px',
                                  color: '#000',
                                },
                              },
                            },
                          ],
                        },
                      }}
                      series={statusOverViewData?.active_lenders}
                      type="donut"
                      width="100%"
                      height="300px"
                    />
                  </>
                ) : (
                  <Box className={commonClasses.flexSpaceCenter}>
                    <Box>
                      <NoFoundData />
                      <Typography className={clsx([classes.label_style])}>No data to display</Typography>
                    </Box>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
