/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState, useEffect, useRef } from 'react';
import { Box, Container, Grid, Paper, Link } from '@material-ui/core';
import { Formik, Form, Field, FormikProps } from 'formik';
import * as yup from 'yup';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { CustomTextField } from 'components/inputs';
import { AlertMessage } from 'components/alerts';
import { LoadingButton } from 'components/buttons';
import { ReactComponent as Logo } from 'assets/icons/fullLogo.svg';
import routes from 'router/routes';
import { strongPasswordRegexExp } from 'core/constants';
import { useAuth } from 'store/auth/hooks';
import useStyles from './Register.styles';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

interface FormValues {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  confirm_password: string;
}

const Register: FC = () => {
  const [token, setToken] = useState('');
  const formRef = useRef<FormikProps<FormValues> | null>(null);
  const classes = useStyles();
  const history = useHistory();
  const { loading, error, setError, register } = useAuth(() => history.push(routes.confirmCode));
  const { t } = useTranslation();
  const query = useQuery();

  useEffect(() => {
    setToken(query.get('token') ?? '');
    formRef.current?.setFieldValue('email', query.get('email') ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loginSchema = yup.object({
    first_name: yup.string().required(t('pages.register.inputs.first_name.required')),
    last_name: yup.string().required(t('pages.register.inputs.last_name.required')),
    email: yup
      .string()
      .required(t('pages.register.inputs.email.required'))
      .email(t('pages.register.inputs.email.error')),
    password: yup
      .string()
      .required(t('pages.register.inputs.password.required'))
      .matches(strongPasswordRegexExp, t('pages.register.inputs.password.error')),
    confirm_password: yup
      .string()
      .required(t('pages.register.inputs.confirm_password.required'))
      .oneOf([yup.ref('password'), null], t('pages.register.inputs.confirm_password.error')),
  });

  const initialValues: FormValues = {
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    confirm_password: '',
  };

  const goToLogin = () => history.push(routes.login);

  const onSubmit = (values: typeof initialValues) => {
    const { confirm_password, ...rest } = values;
    register({ ...rest, token });
  };

  const clearError = () => setError(false);

  return (
    <Box className={classes.root}>
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={2}>
          <Grid item md={6} className={classes.loginContainer}>
            <Logo height="80px" width="200px" />
          </Grid>

          <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={loginSchema} innerRef={formRef}>
            {({ handleChange, values, handleSubmit }) => (
              <Grid item xs={12} md={6} className={classes.loginContainer}>
                <Paper elevation={2} className={classes.formContainer}>
                  <Form noValidate>
                    <Grid container direction="column" spacing={3}>
                      <Grid item>
                        <Field
                          autoFocus
                          id="first_name"
                          fullWidth
                          component={CustomTextField}
                          name="first_name"
                          value={values.first_name}
                          onChange={handleChange}
                          title={t('pages.register.inputs.first_name.label')}
                          placeholder={t('pages.register.inputs.first_name.placeholder')}
                          className={classes.textInput}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>

                      <Grid item>
                        <Field
                          id="last_name"
                          fullWidth
                          component={CustomTextField}
                          name="last_name"
                          value={values.last_name}
                          onChange={handleChange}
                          title={t('pages.register.inputs.last_name.label')}
                          placeholder={t('pages.register.inputs.last_name.placeholder')}
                          className={classes.textInput}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>

                      <Grid item>
                        <Field
                          id="email"
                          fullWidth
                          component={CustomTextField}
                          name="email"
                          type="email"
                          value={values.email}
                          onChange={handleChange}
                          title={t('pages.register.inputs.email.label')}
                          placeholder={t('pages.register.inputs.email.placeholder')}
                          className={classes.textInput}
                          InputLabelProps={{ shrink: true }}
                          disabled
                        />
                      </Grid>

                      <Grid item>
                        <Field
                          id="password"
                          fullWidth
                          component={CustomTextField}
                          name="password"
                          type="password"
                          value={values.password}
                          onChange={handleChange}
                          title={t('pages.register.inputs.password.label')}
                          placeholder={t('pages.register.inputs.password.placeholder')}
                          className={classes.textInput}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>

                      <Grid item>
                        <Field
                          id="confirm_password"
                          fullWidth
                          component={CustomTextField}
                          name="confirm_password"
                          type="password"
                          value={values.confirm_password}
                          onChange={handleChange}
                          title={t('pages.register.inputs.confirm_password.label')}
                          placeholder={t('pages.register.inputs.confirm_password.placeholder')}
                          className={classes.textInput}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>

                      <Grid item className={classes.actionContainer}>
                        <LoadingButton
                          fullWidth
                          variant="contained"
                          color="primary"
                          onClick={() => handleSubmit()}
                          loading={loading}
                          type="submit"
                        >
                          {t('pages.register.buttons.submit')}
                        </LoadingButton>
                      </Grid>

                      <Grid item className={classes.registerContainer}>
                        <Link href="#" className={classes.link} onClick={goToLogin}>
                          {t('pages.register.links.alreadyRegistered')}
                        </Link>
                      </Grid>
                    </Grid>
                  </Form>
                </Paper>
              </Grid>
            )}
          </Formik>
        </Grid>
      </Container>

      <AlertMessage open={!!error} onClose={clearError} message={typeof error === 'string' ? error : undefined} />
    </Box>
  );
};

export default Register;
