import {
  ApplicationQuery,
  ApplicationsListResponse,
  BasicOffer,
  IApplicationCounts,
  ILenderCount,
  IOffersCounts,
} from 'store/applications/types';
import { LenderQuery, LendersListResponse } from 'core/types';
import { apiCall } from '../index';

export const getApplicationsCount = async (filter: { start: Date; end: Date }): Promise<IApplicationCounts> =>
  apiCall({ method: 'get', url: '/applications/count', params: { ...filter } });

export const getOffersCount = async (filter: { start: Date; end: Date }): Promise<IOffersCounts> =>
  apiCall({ method: 'get', url: '/offers/count', params: { ...filter } });

export const getLendersCount = async (filter: { start: Date; end: Date }): Promise<ILenderCount[]> =>
  apiCall({ method: 'get', url: 'admin/lenders/count', params: { ...filter } });

export const getOffer = async (): Promise<{ offers: BasicOffer[]; total: number; page: number; per_page: number }> =>
  apiCall({ method: 'get', url: '/offers' });

export const getApplications = async (query: ApplicationQuery = {}): Promise<ApplicationsListResponse> => {
  let apiPath = '/applications';
  const queryString = new URLSearchParams(query as Record<string, string>).toString();
  if (queryString) {
    apiPath += `?${queryString}`;
  }
  return apiCall({ method: 'get', url: apiPath });
};

export const getLenders = async (query: LenderQuery = {}): Promise<LendersListResponse> => {
  const queryString = new URLSearchParams(query as Record<string, string>).toString();
  return apiCall({ method: 'get', url: `/admin/lenders?${queryString}` });
};
