import { Box, FormHelperText, FormLabel } from '@material-ui/core';
import { DatePicker, DatePickerViewsProps } from '@material-ui/pickers';
import InputAdornment from '@mui/material/InputAdornment';
import { FC } from 'react';
import { CustomLabel } from '../CustomLabel';
import useStyles from './CustomDatePicker.styles';
import { ReactComponent as CalendarTodayIcon } from '../../../assets/icons/calender_client_email.svg';

interface CustomDatePickerProps {
  name: string;
  label?: string;
  value?: Date | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (date: any) => void;
  format?: string;
  openTo?: DatePickerViewsProps['openTo'];
  tooltipMessage?: string;
  error?: string;
  clearable?: boolean;
  disabled?: boolean;
  isShowIcon?: string;
  disableFuture?: boolean;
  collapsed?: boolean;
  maxDate?: Date;
  minDate?: Date;
  height?: string;
  className?: string;
  inputClassName?: string;
}

const CustomDatePicker: FC<CustomDatePickerProps> = ({
  name,
  label,
  value,
  onChange,
  format = 'MM/YYYY',
  openTo = 'month',
  tooltipMessage,
  error,
  clearable = false,
  disabled = false,
  disableFuture = false,
  collapsed = false,
  maxDate,
  minDate,
  height,
  className,
  inputClassName,
  isShowIcon = 'false',
}) => {
  const classes = useStyles();
  return (
    <Box className={openTo === 'date' && !collapsed ? classes.dateContainer : classes.container} height={height}>
      {label && <FormLabel component={() => <CustomLabel title={label} tooltipMessage={tooltipMessage} />} />}

      <DatePicker
        id={name}
        fullWidth
        format={format}
        margin="normal"
        name={name}
        value={value || new Date()}
        onChange={onChange || (() => {})}
        clearable={clearable}
        openTo={openTo}
        views={[openTo]}
        disabled={disabled}
        disableFuture={disableFuture}
        maxDate={maxDate}
        InputProps={{
          className: `${inputClassName}`,
          ...(isShowIcon === 'true' && {
            startAdornment: (
              <InputAdornment className={classes?.iconStyle} position="start">
                <CalendarTodayIcon />
              </InputAdornment>
            ),
          }),
        }}
        minDate={minDate}
        className={`${className} ${error ? classes.inputError : undefined}`}
      />

      {error && <FormHelperText error>{error}</FormHelperText>}
    </Box>
  );
};

export default CustomDatePicker;
