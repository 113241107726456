import { ChangeEvent, useState } from 'react';
import { useAuth } from 'store/auth/hooks';
import moment from 'moment';
import { Box, Grid, Typography } from '@mui/material';
import { ApplicationDetails } from 'store/applications/types';
import clsx from 'clsx';
import { overviewFilterOptions } from 'core/constants';
import { CustomDatePicker } from 'components/inputs';
import CustomSelectBox from 'components/CustomSelect/CustomSelect';
import { getDateRangeByFilter } from 'utils';
import { OverviewDateRange } from 'core/types';
import { EmailList } from '../EmailList';
import useStyles from './ClientEmails.styles';
import { ReactComponent as OpenMailBox } from '../../../../assets/icons/openMailBox.svg';

const ClientEmailsList = ({ details, tabValue }: { details: ApplicationDetails | null; tabValue: number }) => {
  const { isAdmin, isPartner } = useAuth();
  const applicant = details?.company?.people.find((p) => p.applicant);
  const [statusOverViewFilter, setStatusOvertViewFilter] = useState<{
    fromDate: Date;
    toDate: Date;
    monthData: string;
  }>({
    fromDate: moment().subtract(1, 'weeks').subtract(1, 'days').toDate(),
    toDate: moment().endOf('day').toDate(),
    monthData: '',
  });

  const handleDateRangeChange = (
    event: ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => {
    const dateRangeFilter = event.target.value as OverviewDateRange;
    const { start, end } = getDateRangeByFilter(dateRangeFilter);
    setStatusOvertViewFilter({ ...statusOverViewFilter, monthData: dateRangeFilter, fromDate: start, toDate: end });
  };

  const classes = useStyles();

  return (
    (isAdmin || isPartner) && (
      <Grid item xs={12}>
        <Box sx={{ mb: '16px' }} className={classes.headingStripIconText}>
          <Grid className={classes.flex}>
            <Grid className={classes.selectBoxDiv}>
              <Box className={classes.headingStripIcon}>
                <OpenMailBox />
              </Box>
              <Box className={classes.headingStripTextWrap}>
                <Typography variant="text_MD" component="h3" className={classes.fontJost}>
                  All email conversations with {applicant?.first_name}
                </Typography>
              </Box>
            </Grid>
            <Grid className={clsx([classes.selectBoxDiv, classes.datePicker])}>
              <Box className={classes.selectContainer}>
                <CustomSelectBox
                  options={overviewFilterOptions}
                  name="stateOverViewFilter"
                  mainDivClassShow={false}
                  className={classes.width236px}
                  value={statusOverViewFilter?.monthData}
                  onChange={handleDateRangeChange}
                  formik={false}
                />
              </Box>
              <Box className={clsx([classes.datePickerContainer])}>
                <Typography className={clsx([classes.subtitle, classes.semibold])}>From</Typography>
                <CustomDatePicker
                  className={classes.datePicker_wrap}
                  inputClassName={classes.home_datePicker}
                  name="fromDate"
                  value={statusOverViewFilter?.fromDate || null}
                  onChange={(date: Date) => {
                    setStatusOvertViewFilter({
                      ...statusOverViewFilter,
                      fromDate: moment(date).startOf('day').toDate(),
                    });
                  }}
                  isShowIcon="true"
                  collapsed
                  openTo="date"
                  format="MMM DD, yyyy"
                />

                <Typography className={clsx([classes.subtitle, classes.semibold])}>to</Typography>
                <CustomDatePicker
                  name="toDate"
                  isShowIcon="true"
                  className={classes.datePicker_wrap}
                  inputClassName={classes.home_datePicker}
                  value={statusOverViewFilter?.toDate || null}
                  onChange={(date: Date) => {
                    setStatusOvertViewFilter({
                      ...statusOverViewFilter,
                      toDate: moment(date).startOf('day').toDate(),
                    });
                  }}
                  openTo="date"
                  collapsed
                  format="MMM DD, yyyy"
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <EmailList
          applicationId={details?.id || ''}
          companyId={details?.company?.id || ''}
          tabValue={tabValue}
          statusOverViewFilter={statusOverViewFilter}
        />
      </Grid>
    )
  );
};

export default ClientEmailsList;
