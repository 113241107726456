import { ReactElement } from 'react';
import { MUIDataTableCustomHeadRenderer, MUISortOptions } from 'mui-datatables';

import { CustomHeadRender } from './CustomHeadRender';

export const customHeadRender = (
  columnMeta: MUIDataTableCustomHeadRenderer,
  handleToggleColumn: (columnIndex: number) => void,
  sortOrder: MUISortOptions,
  Menu?: ReactElement,
): JSX.Element => (
  <CustomHeadRender
    key={`${columnMeta.name}-${columnMeta.index}`}
    columnMeta={columnMeta}
    handleToggleColumn={handleToggleColumn}
    sortOrder={sortOrder}
    Menu={Menu}
  />
);
