import { createTheme } from '@mui/material/styles';
import { createTheme as MuiSelectCreateTheme } from '@material-ui/core/styles';

declare module '@mui/material/styles' {
  interface customPalette {
    '25'?: string;
    '50'?: string;
    '100'?: string;
    '200'?: string;
    '300'?: string;
    '400'?: string;
    '500'?: string;
    '600'?: string;
    '700'?: string;
    '800'?: string;
    '900'?: string;
  }
  interface Palette {
    customGrey: Palette['primary'];
    greyText: Palette['primary'];
    pending: Palette['primary'];
    approved: Palette['primary'];
    denied: Palette['primary'];
    greyLightBorder: Palette['primary'];
    chipGreyBackground: Palette['primary'];
    greyLabel: Palette['primary'];
    darkRed: Palette['primary'];
    customGrey2: Palette['primary'];
    // primaryBlue: Palette['primary'];
    Gray: Palette['primary'] & customPalette;
    Blue: Palette['primary'] & customPalette;
    Green: Palette['primary'] & customPalette;
    Red: Palette['primary'] & customPalette;
    Warning: Palette['primary'] & customPalette;
    Purple: Palette['primary'] & customPalette;
    Indigo: Palette['primary'] & customPalette;
    BlueLight: Palette['primary'] & customPalette;
  }
  interface PaletteOptions {
    customGrey: PaletteOptions['primary'];
    greyText: PaletteOptions['primary'];
    pending: PaletteOptions['primary'];
    approved: PaletteOptions['primary'];
    denied: PaletteOptions['primary'];
    greyLightBorder: PaletteOptions['primary'];
    chipGreyBackground: PaletteOptions['primary'];
    greyLabel: PaletteOptions['primary'];
    darkRed: PaletteOptions['primary'];
    customGrey2: PaletteOptions['primary'];
    // primaryBlue: PaletteOptions['primary'];
    Gray: PaletteOptions['primary'] & customPalette;
    Blue: PaletteOptions['primary'] & customPalette;
    Red: PaletteOptions['primary'] & customPalette;
    Green: PaletteOptions['primary'] & customPalette;
    Warning: PaletteOptions['primary'] & customPalette;
    Purple: PaletteOptions['primary'] & customPalette;
    Indigo: PaletteOptions['primary'] & customPalette;
    BlueLight: PaletteOptions['primary'] & customPalette;
  }

  interface TypographyVariants {
    head_2XL: React.CSSProperties;
    head_XL: React.CSSProperties;
    head_LG: React.CSSProperties;
    head_MD: React.CSSProperties;
    head_SM: React.CSSProperties;
    head_XS: React.CSSProperties;
    text_XL: React.CSSProperties;
    text_LG: React.CSSProperties;
    text_MD: React.CSSProperties;
    text_SM: React.CSSProperties;
    text_XS: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    head_2XL?: React.CSSProperties;
    head_XL?: React.CSSProperties;
    head_LG?: React.CSSProperties;
    head_MD?: React.CSSProperties;
    head_SM?: React.CSSProperties;
    head_XS?: React.CSSProperties;
    text_XL?: React.CSSProperties;
    text_LG?: React.CSSProperties;
    text_MD?: React.CSSProperties;
    text_SM?: React.CSSProperties;
    text_XS?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    head_2XL: true;
    head_XL: true;
    head_LG: true;
    head_MD: true;
    head_SM: true;
    head_XS: true;
    text_XL: true;
    text_LG: true;
    text_MD: true;
    text_SM: true;
    text_XS: true;
  }
}

const greyLightBorderColor = '#D4D7D9';

export const MuiAutocompleteSelectBox = MuiSelectCreateTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        '&$focused $notchedOutline': {
          borderColor: '#D0D5DD',
          borderWidth: '1px !important',
        },
        '&:hover:not($disabled):before': {
          borderColor: '#D0D5DD !important',
          borderWidth: '1px !important',
        },
        '&.Mui-focused:before': {
          borderColor: '#D0D5DD !important',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#D0D5DD !important',
          borderWidth: '1px !important',
        },
        '&:focus-within .MuiOutlinedInput-notchedOutline': {
          borderColor: '#D0D5DD !important',
          borderWidth: '1px !important',
        },
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
});

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  },
  shadows: [
    'none',
    '0px 1px 2px 0px #1018280D',
    '0px 1px 2px 0px #1018280F, 0px 1px 3px 0px #1018281A',
    '0px 2px 4px -2px #1018280F, 0px 4px 8px -2px #1018281A',
    '0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814',
    '0px 8px 8px -4px #10182808, 0px 20px 24px -4px #10182814',
    '0px 24px 48px -12px #1018282E',
    '0px 32px 64px -12px #10182824',
    '0px 1px 2px 0px #1018280D',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ],
  spacing: 4,
  palette: {
    primary: {
      light: 'red',
      main: '#3f50b5',
      dark: '#002884',
      contrastText: '#fff',
    },
    Green: {
      '50': '#ECFDF3',
      '700': '#027A48',
    },
    Red: {
      '50': '#FEF3F2',
      '700': '#B42318',
    },
    Warning: {
      '50': '#FFFAEB',
      '700': '#B54708',
    },
    Purple: {
      '50': '#F4F3FF',
      '700': '#5925DC',
    },
    Indigo: {
      '50': '#EEF4FF',
      '700': '#3538CD',
    },
    BlueLight: {
      '50': '#F0F9FF',
      '700': '#026AA2',
    },
    Gray: {
      '25': '#FCFCFD',
      '50': '#F9FAFB',
      '100': '#F2F4F7',
      '200': '#EAECF0',
      '300': '#D0D5DD',
      '400': '#98A2B3',
      '500': '#667085',
      '600': '#475467',
      '700': '#344054',
      '800': '#1D2939',
      '900': '#101828',
    },
    Blue: {
      '25': '#F5FBFF',
      '50': '#F0F9FF',
      '100': '#E0F2FE',
      '200': '#B9E6FE',
      '300': '#7CD4FD',
      '400': '#36BFFA',
      '500': '#0BA5EC',
      '600': '#0086C9',
      '700': '#026AA2',
      '800': '#065986',
      '900': '#0B4A6F',
    },
    common: {
      black: '#29373F',
    },
    // primaryBlue: {
    //   main: '#0086C9',
    // },
    darkRed: {
      main: '#D92D20',
    },
    customGrey: {
      main: '#EEEFF0',
    },
    customGrey2: {
      main: '#D0D5DD',
    },
    greyText: {
      main: '#ABAFB2',
    },
    divider: '#CCCFD1',
    pending: {
      main: '#FF9900',
    },
    approved: {
      main: '#4AB270',
    },
    denied: {
      main: '#E74258',
    },
    greyLightBorder: {
      main: greyLightBorderColor,
    },
    chipGreyBackground: {
      main: '#e3e8ee',
    },
    greyLabel: {
      main: '#697386',
    },
  },
  typography: {
    // fontFamily: 'Jost, sans-serif', // Default font family for all variants
    fontFamily: 'Cerebri Sans Pro', // Default font family for all variants
    body1: {
      fontSize: '1rem',
      lineHeight: '1.5rem',
    },
    body2: {
      // fontSize: '0.8125rem',
    },
    h1: {
      fontSize: '2.5rem',
      fontWeight: 'bold',
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 'bold',
    },
    head_2XL: {
      fontSize: '4.5rem',
      lineHeight: '5.625rem',
      letterSpacing: '-2%',
      fontFamily: 'Cerebri Sans Pro',
    },
    head_XL: {
      fontSize: '3.75rem',
      lineHeight: '4.5rem',
      letterSpacing: '-2%',
      fontFamily: 'Cerebri Sans Pro',
    },
    head_LG: {
      fontSize: '3rem',
      lineHeight: '3.75rem',
      letterSpacing: '-2%',
      fontFamily: 'Cerebri Sans Pro',
    },
    head_MD: {
      fontSize: '2.25rem',
      lineHeight: '2.75rem',
      letterSpacing: '-2%',
      fontFamily: 'Cerebri Sans Pro',
    },
    head_SM: {
      fontSize: '1.875rem',
      lineHeight: '2.375rem',
      letterSpacing: '',
      fontFamily: 'Cerebri Sans Pro',
    },
    head_XS: {
      fontSize: '1.5rem',
      lineHeight: '2rem',
      letterSpacing: '',
      fontFamily: 'Cerebri Sans Pro',
    },
    text_XL: {
      fontSize: '1.25rem',
      lineHeight: '1.875rem',
      letterSpacing: '',
      fontFamily: 'Cerebri Sans Pro',
    },
    text_LG: {
      fontSize: '1.125rem',
      lineHeight: '1.75rem',
      letterSpacing: '',
      fontFamily: 'Cerebri Sans Pro',
    },
    text_MD: {
      fontSize: '1rem',
      lineHeight: '1.5rem',
      letterSpacing: '',
      fontFamily: 'Cerebri Sans Pro',
    },
    text_SM: {
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      letterSpacing: '',
      fontFamily: 'Cerebri Sans Pro',
    },
    text_XS: {
      fontSize: '0.75rem',
      lineHeight: '1.125rem',
      letterSpacing: '',
      fontFamily: 'Cerebri Sans Pro',
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#29373F',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#0086C9',
          fontWeight: 600,
          fontSize: '1rem',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.MuiInputBase-adornedStart': {
            '.MuiInputAdornment-positionStart': {
              position: 'absolute',
              left: '0.8rem',
              top: '50%',
              transform: 'translate(0, -50%)',
            },
            '.MuiInputBase-inputAdornedStart': {
              padding: '0.6875rem 0.6875rem 0.6875rem 2.75rem',
            },
          },
          '.MuiOutlinedInput-notchedOutline': {
            border: '1px solid #d0d5dd',
          },
          '& .MuiInputBase-input': {
            color: '#667085',
            fontSize: '0.875rem',
            backgroundColor: '#ffffff',
            padding: '0.9375rem 0.75rem',
            lineHeight: '1.225rem',
            border: '1px solid #d0d5dd',
            borderRadius: '8px',
            transition: 'all 0.3s',
            '&:focus': {
              boxShadow: '0 0 0 1px #0086C9',
            },
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '100%',
          '& > label': {
            fontSize: '1rem',
            lineHeight: '1.375rem',
            transform: 'unset',
            position: 'unset',
            color: '#010B13',
            marginBottom: '0.75rem',
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: '0',
          '.MuiFormControlLabel-label': {
            fontSize: '0.8125rem',
            lineHeight: '1.2188rem',
            marginLeft: '0.5rem',
            color: '#667085',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '0.625rem 0.9375rem',
          textTransform: 'capitalize',
          borderRadius: '0.5rem',
          letterSpacing: '0.05rem',
          transition: 'all 0.3s',
          fontWeight: '600',
          lineHeight: '1.25rem',
          '& svg': {
            width: '1.25rem',
            height: '1.25rem',
            marginRight: '0.375rem',
          },
          '&.MuiButton-outlined': {
            border: '1px solid #D0D5DD',
            color: '#344054',
            boxShadow: '0px 1px 2px #1018280d',
            '&:active': {
              scale: '0.98',
            },
            '&:focus': {
              boxShadow: '0 0 0 2px #ffffff, 0 0 0 4px #D0D5DD',
            },
            '&.blue': {
              borderColor: '#0086C9',
              color: '#0086C9',
              '&:focus': {
                boxShadow: '0 0 0 2px #ffffff, 0 0 0 4px #0086C9',
              },
            },
            '&.blueSolid': {
              backgroundColor: '#F0F9FF',
              borderColor: '#F0F9FF',
              color: '#0086C9',
              '&:focus': {
                boxShadow: '0 0 0 2px #ffffff, 0 0 0 4px #0086C9',
              },
            },
          },
          '&.MuiButton-contained': {
            backgroundColor: '#0086C9',
            fontSize: '0.8125rem',
            lineHeight: '1.1375rem',
            color: '#FCFCFD',
            fontWeight: '600',
            padding: '0.625rem 1.125rem',
            textTransform: 'capitalize',
            borderRadius: '0.5rem',
            letterSpacing: '0.05rem',
            transition: 'all 0.3s',
            '&:hover': {
              backgroundColor: '#3c99c7',
            },
            '&.MuiButton-sizeLarge': {
              fontSize: '0.875rem',
              lineHeight: '1.225rem',
              padding: '1.125rem',
            },
            '&.white': {
              backgroundColor: '#FFFFFF',
              color: '#1D2939',
              boxShadow: '0px 1px 2px 0px #10182873',
              '&:focus': {
                boxShadow: '0 0 0 2px #ffffff, 0 0 0 4px #e8e8e8',
              },
            },
          },
          '&:active': {
            scale: '0.98',
          },
          '&:focus': {
            boxShadow: '0 0 0 2px #ffffff, 0 0 0 4px #0086c9',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow:
            'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          height: 2,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          border: '2px solid',
          borderColor: '#CCCFD1',
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          borderWidth: 2,
          borderColor: '#CCCFD1',
          '& tr:nth-child(odd)': {
            backgroundColor: 'rgb(0, 89, 178, 0.02)',
          },
          '& tr:nth-child(even)': {
            backgroundColor: 'rgb(0, 89, 178, 0.05)',
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
          '&$hover:hover, &$selected': {
            backgroundColor: 'rgba(0, 89, 178, 0.6) !important',
            color: '#FFFFFF !important',
          },
        },
      },
    },
    MuiTableFooter: {
      styleOverrides: {
        root: {
          '& tr td': {
            borderBottom: 'none',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          minWidth: '1.25rem',
        },
        body: {
          color: 'inherit',
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          paddingLeft: '0',
          paddingRight: '0',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          '.MuiButtonBase-root': {
            paddingLeft: '0',
            paddingRight: '0',
            fontSize: '0.875rem',
            lineHeight: '1.225rem',
            textTransform: 'capitalize',
            '&:not(:last-child)': {
              marginRight: '15px',
            },
            '&.Mui-selected': {
              color: '#0086C9',
            },
          },
          '.MuiTabs-indicator': {
            backgroundColor: '#0086C9',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#D0D5DD',
          '&.Mui-checked': {
            color: '#0086C9',
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: '#D0D5DD',
          '&.Mui-checked': {
            color: '#0086C9',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '&.makeStyles-select-229.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#D0D5DD',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#D0D5DD',
          },
        },
      },
    },
  },
});

export default theme;
