import { Theme } from '@mui/material';

import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  lenderLogo: {
    width: 'auto',
    maxHeight: 40,
    maxWidth: 100,
  },
  tableContainer: {
    marginTop: theme.spacing(3),
  },
  lender_with_image: {
    display: 'flex',
  },
  marginTop: {
    marginTop: 10,
    marginLeft: 10,
  },
  mainWrapper: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  cardShadow: {
    boxShadow: '0px 2px 24px 0px #9292921A',
    backgroundColor: theme.palette.primary.contrastText,
    borderRadius: theme.spacing(3),
    padding: '0px 16px',
  },
  filterModalWidth: {
    width: '20% !important',
  },
}));
