import { FC, useState, useEffect } from 'react';
import { Box, CircularProgress, FormHelperText, Grid, Paper, Typography, Button } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { ErrorOutline } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import ReactCodeInput from 'react-code-input';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import clsx from 'clsx';

import { useAuth } from 'store/auth/hooks';
import { AuthPageContainer } from 'components/AuthPageContainer';
import routes from 'router/routes';
import useStyles from './ConfirmCode.styles';

const ConfirmCode: FC = () => {
  const [codeSentSuccess, setCodeSentSuccess] = useState(false);
  const [resend, setResend] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const { loading, error, email, rememberMe, success, initAuth, confirmAuth, setSuccess } = useAuth(() => {
    if (resend) {
      setCodeSentSuccess(true);
    }
  });
  const history = useHistory();

  useEffect(() => {
    if (!email) history.push(routes.login);
  }, [email, history]);

  const loginSchema = yup.object({
    code: yup
      .string()
      .matches(/^\d{6}$/, t('pages.confirmCode.inputs.code.error'))
      .length(6, t('pages.confirmCode.inputs.code.error'))
      .required(t('pages.confirmCode.inputs.code.required'))
      .nullable(),
  });

  const initialValues = {
    code: '',
  };

  const onSubmit = (values: { code: string }) => {
    if (codeSentSuccess) setCodeSentSuccess(false);
    confirmAuth(values.code);
  };

  const resendCode = () => {
    setResend(true);
    if (email) {
      initAuth({ email, rememberMe });
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={loginSchema}>
      {({ values, errors, handleSubmit, setFieldValue, resetForm }) => {
        const withError = error || (values.code.length === 6 && errors.code);
        return (
          <AuthPageContainer success={success} clearSuccess={() => setSuccess(false)}>
            <Paper elevation={2} className={classes.formContainer}>
              <Typography variant="h6" gutterBottom>
                {t('pages.confirmCode.title')}
              </Typography>

              <Typography className={classes.subtitle}>
                {t('pages.confirmCode.inputs.code.label.line1')}{' '}
                <strong className={classes.primaryText}>{t('pages.confirmCode.inputs.code.label.line2')}</strong>{' '}
                {t('pages.confirmCode.inputs.code.label.line3')}
              </Typography>

              <Form noValidate>
                <Grid container direction="column" className={classes.gridContainer}>
                  <Grid item className={classes.codeInputContainer}>
                    <ReactCodeInput
                      key={String(codeSentSuccess)}
                      name="code"
                      type="tel"
                      fields={6}
                      inputMode="tel"
                      className={clsx([classes.codeInput, withError && classes.codeInputError])}
                      onChange={(value) => {
                        setFieldValue('code', value);
                        if (/^\d{6}$/.test(value)) {
                          setTimeout(() => handleSubmit(), 350);
                        }
                      }}
                      value={values.code}
                      disabled={loading}
                    />

                    {withError && (
                      <FormHelperText className={classes.codeError} error>
                        <ErrorOutline /> {errors.code || t('pages.confirmCode.inputs.code.error')}
                      </FormHelperText>
                    )}

                    {loading && (
                      <Box className={classes.loaderContainer}>
                        <CircularProgress />
                      </Box>
                    )}

                    {error && (
                      <Button
                        id="resendCodeButton"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={() => {
                          resetForm();
                          resendCode();
                        }}
                      >
                        {t('pages.confirmCode.buttons.resend')}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Form>
            </Paper>
          </AuthPageContainer>
        );
      }}
    </Formik>
  );
};

export default ConfirmCode;
