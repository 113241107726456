import { FC, useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import clsx from 'clsx';
import { metricsTabsList } from 'core/constants';
import { CustomTabPanel, a11yProps } from 'pages/lead/Lead';
import { AppsOverview, OffersOverview, OffersOverviewByLenders } from './components';
import useCommonStyles from '../../assets/css/Common.styles';

const MetricsOverview: FC<{ preview?: boolean }> = ({ preview }) => {
  const [tabValue, setTabValue] = useState<number>(0);
  const commonClasses = useCommonStyles();

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Box className={clsx([commonClasses.c_card, commonClasses.cardShadow])} sx={{ marginTop: '30px' }}>
      <Box className={clsx([commonClasses.padding24px])}>
        <Box className={clsx([commonClasses.filter_tabingBox])}>
          <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example" scrollButtons="auto">
            {metricsTabsList?.map((d, index) => {
              return <Tab label={d} key={d} {...a11yProps(index)} />;
            })}
          </Tabs>
        </Box>
        <CustomTabPanel value={tabValue} index={0}>
          <AppsOverview preview={preview} />
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={1}>
          <OffersOverview preview={preview} />
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={2}>
          <OffersOverviewByLenders preview={preview} />
        </CustomTabPanel>
      </Box>
    </Box>
  );
};

export default MetricsOverview;
