import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  c_card: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(4),
    },
  },
  c_cardTitle: {
    display: 'flex',
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
  },
  c_cardTitleText: {
    fontSize: `${theme.typography.pxToRem(16)} !important`,
    lineHeight: `${theme.typography.pxToRem(28)} !important`,
    color: `${theme.palette.Gray[600]} !important`,
    fontWeight: '500 !important',
    fontFamily: '"Jost" !important',
  },
  c_cardBody: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
  },
  closeFilterIconEnd: {
    fontSize: '24px',
    width: '20px',
    height: '20px',
    marginLeft: 'auto !important',
    minWidth: '24px !important',
    minHeight: '24px !important',
    color: `${theme.palette.Gray[700]} !important`,
    '& .MuiSvgIcon-root': {
      marginRight: '0 !important',
    },
  },
  cardShadow: {
    boxShadow: '0px 2px 24px 0px #9292921A',
    backgroundColor: theme.palette.primary.contrastText,
    borderRadius: theme.spacing(3),
  },
  filter_cardBody: {
    padding: '0px 32px 24px 32px',
  },
  filter_tabingBox: {
    borderBottom: `1px solid ${theme.palette.grey[50]}`,
    marginBottom: '24px',
  },
  fontJost: {
    fontFamily: 'jost !important',
  },
  gap_24: {
    gap: '1.5rem',
  },
  gray_500: {
    color: `${theme.palette.Gray[500]} !important`,
  },
  gray_600: {
    color: `${theme.palette.Gray[600]} !important`,
  },
  primary_600: {
    color: `${theme.palette.Blue[600]} !important`,
  },
  gray_700: {
    color: `${theme.palette.Gray[700]} !important`,
  },
  lineHeight_25: {
    lineHeight: `${theme.typography.pxToRem(25)} !important`,
  },
  mainWrapper: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  boxCircleText: {
    color: `${theme.palette.Gray[900]}`,
    width: '100%',
    maxWidth: 'calc(100% - 3.125rem)',
  },
  boxCircleTitle: {
    color: `${theme.palette.Gray[700]} !important`,
    fontWeight: '500',
  },
  tableActionWrap: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  tableActionButtonWrap: {
    display: 'inline-flex',
    flexWrap: 'wrap',
    gap: '1rem',
  },
  py_16: {
    paddingTop: `${theme.spacing(4)} !important`,
    paddingBottom: `${theme.spacing(4)} !important`,
  },
  fixedWidth136: {
    width: '136px',
  },
  flexWithGap10: {
    display: 'flex',
    gap: '10px',
  },
  flexWithGap16: {
    display: 'flex',
    gap: '16px',
  },
  flexWithGap04: {
    display: 'flex',
    gap: '4px',
  },
  pageBackWrap: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(3),
  },
  padding24px: {
    padding: theme.spacing(6),
  },
  pageBackLink: {
    display: 'inline-flex !important',
    alignItems: 'center !important',
    fontSize: '1rem !important',
    lineHeight: '1.8rem !important',
    color: `${theme.palette.Blue[600]} !important`,
    fontWight: '600 !important',
    textDecoration: 'none !important',
  },
  pageBackIcon: {
    marginRight: '0.5rem',
  },
  tableContainer: {
    padding: '24px !important',
    marginBottom: theme.spacing(5),
    '& .MuiTableRow-root': {
      cursor: 'auto',
    },

    '& th:nth-child(6), td:nth-child(6)': {
      padding: 0,
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      minWidth: 40,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(2),
        paddingBottom: theme.spacing(2),
      },
    },
  },
  filterMenu: {
    marginTop: 24,
    marginBottom: 24,
  },
  fontWeight_500: {
    fontWeight: '500!important',
  },
  inputLbl: {
    lineHeight: '1.75rem !important',
    marginBottom: '0.5rem !important',
    display: 'block !important',
  },
  boxInput: {
    backgroundColor: '#ffffff',
    borderRadius: `${theme.typography.pxToRem(8)} !important`,
    border: `1px solid ${theme.palette.grey[300]}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px 14px',
  },
  boxInputText: {
    width: 'calc(100% - 30px)',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  btnTextNoEffect: {
    boxShadow: 'none !important',
    minWidth: '20px !important',
    '&:active': {
      outline: 'none',
      scale: '1 !important',
    },
    '&:hover': {
      outline: 'none',
      boxShadow: 'none !important',
      backgroundColor: 'transparent !important',
    },
    '&.Mui-selected': {
      outline: 'none',
    },
  },
  marginNone: {
    margin: '0 !important',
  },
  marginBottom24px: {
    marginBottom: '24px !important',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  cursorNotAllowed: {
    cursor: 'not-allowed',
  },
  closeFilterIcon: {
    fontSize: '24px',
  },
  modalTitle: {
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(27),
    color: `${theme.palette.Gray[700]} !important`,
    fontWeight: 700,
    display: 'block',
    maxWidth: '432px',
    margin: '0 auto 20px !important',
    textAlign: 'center',
  },
  modalIconBorder: {
    width: '3.5rem',
    height: '3.5rem',
    backgroundColor: theme.palette.Blue[100],
    border: `10px solid ${theme.palette.Blue[50]}`,
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.Blue[600],
  },
  modalFooter: {
    marginTop: theme.spacing(8),
  },
  modalIconMargin: {
    margin: '0 auto 1rem',
  },
  fontWidth500: {
    fontWeight: '500px !important',
  },
  flexSpaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  flexSpaceCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  filedTitle: {
    color: `${theme.palette.Gray[500]} !important`,
    fontWeight: '500 !important',
    fontFamily: 'Jost, sans-serif !important',
  },
  marginBottom24: { marginBottom: 24 },
  marginTop24: {
    marginTop: 24,
  },
  marginRight24: {
    marginRight: 24,
  },
  justifyBetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  btnNoPadding: {
    padding: `${theme.spacing(0)} !important`,
  },
  formLblValue: {
    marginBottom: '1.5rem !important',
  },
  filter_cardTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px',

    '& h3': {
      fontWeight: '500',
    },
  },
  filter_checkBoxGrp: {
    display: 'flex',
    flexDirection: 'column',
  },
  selectedFilter: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  selectedFilterList: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(3.1),
    margin: theme.spacing(0),
    padding: theme.spacing(0),
    listStyleType: 'none',
  },
  chip_style: {
    color: `${theme.palette.Gray[700]} !important`,
  },
  chip_main: {
    borderRadius: '8px !important',
    fontWeight: '500 !important',
    color: `${theme.palette.Gray[700]} !important`,
    backgroundColor: `${theme.palette.Gray[200]} !important`,
    height: '29px !important',
  },
  timeSpent: {
    fontSize: theme.typography.pxToRem(10),
  },
  new: {
    color: `${theme.palette.BlueLight[700]} !important`,
    backgroundColor: `${theme.palette.BlueLight[50]} !important`,
  },
  preQualified: {
    color: `${theme.palette.Purple[700]} !important`,
    backgroundColor: `${theme.palette.Purple[50]} !important`,
  },
  closed: {
    color: `${theme.palette.Gray[700]} !important`,
    backgroundColor: `${theme.palette.Gray[50]} !important`,
  },
  lenderReview: {
    color: `${theme.palette.Indigo[700]} !important`,
    backgroundColor: `${theme.palette.Indigo[50]} !important`,
  },
  awaitingClientInfo: {
    color: `${theme.palette.Blue[700]} !important`,
    backgroundColor: `${theme.palette.Blue[50]} !important`,
  },
  badge: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(18),
    textAlign: 'center',
    paddingBottom: theme.spacing(0.5),
    paddingTop: theme.spacing(0.5),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    color: theme.palette.common.black,
    backgroundColor: theme.palette.customGrey.main,
    fontWeight: '500',
    fontFamily: 'jost',
    borderRadius: '100px',
  },
  rejected: {
    color: `${theme.palette.Red[700]} !important`,
    backgroundColor: `${theme.palette.Red[50]} !important`,
  },
  accepted: {
    color: `${theme.palette.Green[700]} !important`,
    backgroundColor: `${theme.palette.Green[50]} !important`,
  },
  pending: {
    color: `${theme.palette.Warning[700]} !important`,
    backgroundColor: `${theme.palette.Warning[50]} !important`,
  },
  welcomeStripWRap: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
  welcomeStripTitleWrap: {
    paddingLeft: theme.spacing(4),
  },
  welcomeStripTitle: {
    marginBottom: `${theme.spacing(2)} !important`,
    fontSize: `${theme.typography.pxToRem(18)} !important`,
    lineHeight: `${theme.typography.pxToRem(32.4)} !important`,
    fontWeight: '600 !important',
    color: `${theme.palette.Gray[700]} !important`,
  },
  welcomeStripIconText: {
    display: 'flex',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    position: 'relative',
    '&::before': {
      position: 'absolute',
      content: '""',
      backgroundColor: theme.palette.Gray[900],
      opacity: '0.2',
      width: '1px',
      height: '50%',
      left: '-0.9375rem',
      top: '50%',
      transform: 'translateY(-50%)',
    },
  },
  welcomeStripIcon: {
    color: theme.palette.Blue[600],
    backgroundColor: theme.palette.Blue[50],
    width: theme.spacing(12.5),
    height: theme.spacing(12.5),
    display: 'flex',
    alignItems: 'center',
    borderRadius: '10rem',
    justifyContent: 'center',
  },
  welcomeStripTextWrap: {
    color: `${theme.palette.Gray[900]}`,
    width: '100%',
    maxWidth: 'calc(100% - 3.125rem)',
    paddingLeft: theme.spacing(6),
  },
  welcomeStripDesc: {
    color: `${theme.palette.Gray[900]} !important`,
    fontWeight: '400',
    fontSize: `${theme.typography.pxToRem(14)} !important`,
    lineHeight: `${theme.typography.pxToRem(25.2)} !important`,
  },
  welcomeStripDescCount: {
    marginBottom: `${theme.spacing(1)} !important`,
    color: `${theme.palette.Gray[900]} !important`,
    display: 'block !important',
    fontSize: `${theme.typography.pxToRem(18)} !important`,
    lineHeight: `${theme.typography.pxToRem(32.4)} !important`,
    fontWeight: '500 !important',
  },
  welcomeStripDescCount_24: {
    marginBottom: `${theme.spacing(0)} !important`,
    color: `${theme.palette.Gray[900]} !important`,
    display: 'block !important',
    fontSize: `${theme.typography.pxToRem(24)} !important`,
    lineHeight: `${theme.typography.pxToRem(32.4)} !important`,
    fontWeight: '500 !important',
  },
  welcomeStripIconGray: {
    color: theme.palette.Blue[600],
    backgroundColor: theme.palette.Gray[200],
    width: theme.spacing(12.5),
    height: theme.spacing(12.5),
    display: 'flex',
    alignItems: 'center',
    borderRadius: '10rem',
    justifyContent: 'center',
  },
  itemCenterWithGrid: {
    display: 'grid',
    // placeItems: 'center',
  },
  borderGray: {
    border: `1px solid ${theme.palette.grey[200]}`,
  },
  welcomeStripIconTextRemove: {
    '&::before': {
      display: 'none',
    },
  },
  width25: {
    width: '25% !important',
  },
  width28: {
    width: '28% !important',
  },
  marginBottom10px: {
    marginBottom: '6px !important',
    display: 'block',
  },
  avtarRound: {
    borderRadius: '100% !important',
    border: `1px solid ${theme.palette.grey[200]}`,
    '& .MuiAvatar-img': {
      objectFit: 'contain !important',
    },
  },
}));
