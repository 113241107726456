import React, { useState, FC } from 'react';
import { Box, Grid, Tab, Tabs } from '@mui/material';
import clsx from 'clsx';
import { Header } from 'components/Header/Header';
import { CustomTabPanel } from 'pages/lead/Lead';
import AutomatedWorkflows from './components/AutomatedWorkflows';
import useStyles from './WorkFlow.styles';
import WorkFlowTab from './components/WorkFlowTab';

const WorkFlow: FC = () => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const tabData = ['Create workflow', 'Automated workflows'];

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Header name="Workflow" />
      <Box sx={{ mt: 6, px: 4 }}>
        <Grid container>
          <Grid item xs={12} lg={12} xl={12}>
            <Box sx={{ px: 5, pb: 6, pt: 3, height: '100%' }} className={clsx([classes.cardShadow])}>
              <Box className={clsx([classes.filter_tabingBox])} sx={{ px: 3 }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" scrollButtons="auto">
                  {tabData?.map((d) => {
                    return <Tab label={d} key={d} />;
                  })}
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <WorkFlowTab />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <AutomatedWorkflows />
              </CustomTabPanel>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default WorkFlow;
