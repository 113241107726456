const routes = {
  // Public routes
  login: '/auth/login',
  confirmCode: '/auth/confirm-code',
  register: '/auth/register',
  resetPassword: '/auth/reset-password',
  forgotPassword: '/auth/forgot-password',
  preAuth: '/start',
  getStarted: '/auth/get-started',
  // Protected routes
  home: '/d/home',
  design: '/d/design',
  dashboard: '/d',
  leads: '/d/leads',
  lead: '/d/lead',
  // developers
  developers: '/d/developers',
  api: '/d/developers/api',
  security: '/d/developers/security',
  widget: '/d/developers/widget',
  webhooks: '/d/developers/webhooks',
  lenders: '/d/lenders',
  lenderDetail: '/d/lender-rule',
  lender: '/d/lender',
  partners: '/d/partners',
  partner: '/d/partner',
  admins: '/d/admins',
  lenderRules: '/d/lender-rules',
  // settings
  settings: '/d/settings',
  team: '/d/settings/team',
  rulesEngine: '/d/settings/rules-engine',
  faqs: '/d/settings/faqs',
  reminders: '/d/settings/reminders',
  account: '/d/settings/account',
  selectLenders: '/d/settings/select-lenders',
  // metrics
  metrics: '/d/metrics',
  // integrations
  integrations: '/d/integrations',
  integration: '/d/integration',
  // work-flow
  workFlow: '/d/work-flow',
  createWorkFlow: '/d/create-workflow',
};

export default routes;
