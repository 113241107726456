import { CheckBoxOutlined } from '@material-ui/icons';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import ErrorMeg from 'components/inputs/ErrorMessage/ErrorMessage';
import useStyles from './CheckBox.styles';

interface ICheckbox {
  label?: string;
  name: string;
  Margin?: string;
  link?: string;
  linkText?: string;
  disabled?: boolean;
  value?: string | boolean;
  extra?: { [key: string]: string };
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  ClassName?: string;
  checked?: boolean;
  key?: string | number;
  formik?: boolean;
  icon?: string;
}

const CustomCheckbox = ({
  formik = true,
  name,
  disabled = false,
  value,
  extra,
  onChange,
  label,
  checked = false,
  key,
  icon,
}: ICheckbox) => {
  const classes = useStyles();
  return (
    <>
      <FormControlLabel
        key={key}
        label={
          label && (
            <Typography className={icon && classes.labelWithIcon} variant="text_SM" component="div">
              {label} {icon && <img src={icon} alt={icon} />}
            </Typography>
          )
        }
        control={
          <Checkbox
            name={name}
            value={value}
            checked={checked}
            onChange={onChange}
            disabled={disabled}
            {...extra}
            checkedIcon={<CheckBoxOutlined />}
          />
        }
      />
      {formik && <ErrorMeg name={name} />}
    </>
  );
};

export default CustomCheckbox;
