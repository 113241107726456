import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useTooltipStyles = makeStyles(() => ({
  tooltip: {
    marginTop: 10,
  },
}));

export default makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: theme.spacing(1),
  },
  inputLabel: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20),
    color: 'grey',
  },
  tooltipIcon: {
    fontSize: theme.typography.pxToRem(17),
    color: 'grey',
  },
}));
