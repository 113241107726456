import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  titleName: {
    fontSize: theme.typography.pxToRem(28),
    lineHeight: theme.typography.pxToRem(38),
    fontFamily: theme.typography.fontFamily,
    fontWeight: '500',
  },
  site_header: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.customGrey2.main}`,
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  site_header_left: {
    fontSize: theme.typography.pxToRem(28),
  },
  site_header_right: {
    fontSize: theme.typography.pxToRem(28),
  },
  site_header_title: {
    fontSize: theme.typography.pxToRem(28),
    lineHeight: theme.typography.pxToRem(38),
    fontFamily: theme.typography.fontFamily,
    fontWeight: '500',
    margin: '0',
  },
  site_header_back_link: {
    fontSize: '1rem',
    fontWeight: '600',
    color: theme.palette.Blue[600],
    display: 'flex',
    alignItems: 'center',
  },
  site_header_back_icon: {
    display: 'inline-block',
    rotate: '180deg',
    marginLeft: '4px',
  },
  site_header_toggle_btn: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    // paddingRight: theme.spacing(2),
    border: `1px solid ${theme.palette.customGrey2.main}`,
    borderRadius: '100px',
  },
  site_header_toggle_btn_text: {
    textTransform: 'uppercase',
    color: theme.palette.darkRed.main,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(21.6),
    marginRight: theme.spacing(2),
    fontFamily: theme.typography.fontFamily,
  },

  // BACK TO PAGE
  pageBackWrap: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(3),
  },
  pageBackLink: {
    display: 'inline-flex !important',
    alignItems: 'center !important',
    fontSize: '1rem !important',
    lineHeight: '1.8rem !important',
    color: `${theme.palette.Blue[600]} !important`,
    fontWight: '600 !important',
    textDecoration: 'none !important',
  },
  pageBackIcon: {
    marginRight: '0.5rem',
    // marginLeft: '0.5rem',
  },
  // BACK TO PAGE END
  cardShadow: {
    boxShadow: '0px 2px 24px 0px #9292921A',
    backgroundColor: theme.palette.primary.contrastText,
    borderRadius: theme.spacing(3),
  },
  // CONTENT STYLE
  mainWrapper: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  filter_tabingBox: {
    borderBottom: `1px solid ${theme.palette.grey[50]}`,
    marginBottom: '24px',
  },
  fontJost: {
    fontFamily: 'jost !important',
  },
  //  headingStripIconText End
  gray_500: {
    color: `${theme.palette.Gray[500]} !important`,
  },
  gray_600: {
    color: `${theme.palette.Gray[600]} !important`,
  },
  primary_600: {
    color: `${theme.palette.Blue[600]} !important`,
  },
  gray_700: {
    color: `${theme.palette.Gray[700]} !important`,
  },
  lineHeight_25: {
    lineHeight: `${theme.typography.pxToRem(25)} !important`,
  },

  fontWeight_500: {
    fontWeight: '500!important',
  },
  fontWeight_400: {
    fontWeight: '400!important',
  },

  // integrateToolBox
  integrateToolBox: {
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: `${theme.spacing(2)} !important`,
    backgroundColor: '#FEFEFF !important',
    boxShadow: `${theme.shadows[1]} !important`,
    height: '100%',
  },
  integrateToolBoxInner: {
    padding: `${theme.spacing(0)} !important`,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  // WorkFlow
  mailBoxBlack: {
    width: '2.75rem',
    height: '2.75rem',
    backgroundColor: theme.palette.Gray[900],
    border: `4px solid ${theme.palette.Gray[300]}`,
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.Blue[600],
  },
  linkBtn: {
    color: `${theme.palette.Gray[700]} !important`,
    border: `1px solid ${theme.palette.Gray[300]}`,
    boxShadow: theme.shadows[1],
    borderRadius: '8px',
    cursor: 'pointer',
    fontSize: `${theme.typography.pxToRem(14)} !important`,
    backgroundColor: '#ffffff',
    padding: '7px 16px',
    display: 'inline-block',
  },
  fontWeight_700: {
    fontWeight: '700!important',
  },
  neWorkFlowBox: {
    backgroundColor: theme.palette.Gray[100],
    borderRadius: `${theme.spacing(3)} !important`,
    border: `2px dashed ${theme.palette.Gray[200]}`,
    maxWidth: '100%',
    padding: '40px 16px 20px !important',
  },
  workflowProfileBox: {
    border: `1px solid #C9C9C9`,
    borderRadius: `${theme.spacing(32)} !important`,
    backgroundColor: '#FEFEFF !important',
    boxShadow: `${theme.shadows[1]} !important`,
    height: '100%',
  },
  gray_800: {
    color: `${theme.palette.Gray[800]} !important`,
  },
  saveTimeDetail: {
    maxWidth: '405px',
    textAlign: 'center',
  },
}));
export default useStyles;
