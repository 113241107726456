import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  inputWidth: { width: '100% !important' },
  width191: { width: '234px !important' },
  marginLeft8: {
    marginLeft: '8px',
  },
  marginRight8: {
    marginRight: '8px',
  },
  toAmount: {
    marginTop: '8px',
    textAlign: 'center',
  },
  autoCompleteInput: {
    minWidth: '100%',
    '& .MuiInputBase-root': {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      fontWeight: 700,
      minHeight: '44.13px',
      padding: '4px !important',
    },
    '& .Mui-disabled': {
      backgroundColor: theme.palette.grey[200],
      color: theme.palette.common.black,
    },
    '& .Mui-error': {
      color: theme.palette.error.main,
      marginLeft: 0,
      marginRight: 0,
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: 'auto',
    },
  },
  lender_name_select_box: {},
  '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
    padding: '4px !important',
  },
  tableContainer: {
    marginTop: theme.spacing(3),
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '175px',
  },
  removeButton: {
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  switchContainer: {
    display: 'flex',
    position: 'relative',
    width: 120,
  },
}));
