import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  integrateToolBox: {
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: `${theme.spacing(2)} !important`,
    backgroundColor: '#FEFEFF !important',
    boxShadow: `${theme.shadows[1]} !important`,
    height: '100%',
  },
  integrateToolBoxInner: {
    padding: `${theme.spacing(0)} !important`,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  // integrateToolKey
  integrateKeyBox: {
    backgroundColor: theme.palette.Gray[100],
    borderRadius: `${theme.spacing(3)} !important`,
    maxWidth: '416px',
    height: '416px',
    paddingTop: '122px !important',
    marginLeft: 'auto',
  },
  integrateKeyLeft: {
    maxWidth: '584px',
  },
  KeyBoxSmall: {},
  integrateLine: {
    width: '100%',
    borderTop: `1px dashed ${theme.palette.Blue[600]}`,
    position: 'relative',
    '&::before': {
      position: 'absolute',
      content: '""',
      backgroundColor: theme.palette.Blue[600],
      width: '6px',
      height: '6px',
      right: '',
      top: '-3px',
      left: '-2px',
      display: 'inline-block',
      borderRadius: '100%',
    },
    '&::after': {
      position: 'absolute',
      content: '""',
      backgroundColor: theme.palette.Blue[600],
      width: '6px',
      height: '6px',
      top: '-3px',
      right: '-2px',
      display: 'inline-block',
      borderRadius: '100%',
    },
  },
  boxInputText_140: {
    width: 'calc(100% - 140px) !important',
    padding: '12px 14px',
  },
  boxInputKey: {
    backgroundColor: '#ffffff',
    borderRadius: `${theme.typography.pxToRem(8)} !important`,
    border: `1px solid ${theme.palette.grey[300]}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '10px',
  },
  boxInputKeyBtn: {
    padding: '12px 14px !important',
    borderLeft: '1px solid #e0e0e0 !important',
    borderRadius: '0 !important',
  },
  btnBlueText: {
    color: `${theme.palette.Blue[600]} !important`,
    boxShadow: 'none !important',
    '&:active': {
      outline: 'none',
      scale: '1 !important',
    },
    '&:hover': {
      outline: 'none',
      boxShadow: 'none !important',
      backgroundColor: 'transparent !important',
    },
    '&.Mui-selected': {
      outline: 'none',
    },
  },
}));
