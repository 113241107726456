import React, { ChangeEvent } from 'react';
import { Close } from '@material-ui/icons';
import { Box, Button, Dialog, Tab, Tabs, Typography } from '@mui/material';
import clsx from 'clsx';
import { firstLatterCapital } from 'pages/leads/components/CreateApplicationModal/utils';
import CustomCheckbox from 'components/CustomCheckBox/CheckBox';
import FooterButton from './FooterButton';
import useCommonStyle from '../../assets/css/Common.styles';

const FilterModal = ({
  open,
  onClose,
  setValue,
  value,
  width = 'width28',
  filterTabName,
  filterOption = {},
  filterData = {},
  handleFilterData,
  handleApplyFilter,
}: {
  open: boolean;
  value: number;
  width?: 'width25' | 'width28';
  filterTabName: string[];
  onClose: () => void;
  setValue: (value: number | string) => void;
  filterOption: object;
  filterData: object;
  handleApplyFilter: () => void;
  handleFilterData: (event: ChangeEvent<HTMLInputElement>) => void;
}) => {
  const commonClasses = useCommonStyle();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: commonClasses[`${width}`] }}
    >
      <Box className={clsx([commonClasses.c_card, commonClasses.cardShadow])}>
        <Box className={clsx([commonClasses.c_cardTitle, commonClasses.filter_cardTitle])}>
          <Typography variant="text_LG" component="h3">
            Filters
          </Typography>
          <Button className={commonClasses?.closeFilterIconEnd} onClick={onClose}>
            <Close />
          </Button>
        </Box>
        <Box className={clsx([commonClasses.filter_cardBody])}>
          <Box className={clsx([commonClasses.filter_tabingBox])}>
            <Tabs
              value={+value}
              onChange={(event: React.SyntheticEvent, newValue: number | string) => {
                setValue(newValue);
              }}
              aria-label="basic tabs example"
              scrollButtons="auto"
            >
              {filterTabName?.map((data) => {
                return <Tab label={data} key={data} />;
              })}
            </Tabs>
          </Box>
          <Box className={clsx([commonClasses.filter_checkBoxGrp])}>
            {(filterOption[value as keyof typeof filterOption] as string[])?.map((data: string) => {
              return (
                <CustomCheckbox
                  key={data}
                  formik={false}
                  label={firstLatterCapital(data)}
                  name={data}
                  value={data}
                  checked={(
                    filterData[Object.keys(filterData)[+value] as keyof typeof filterData] as string[]
                  ).includes(data)}
                  onChange={handleFilterData}
                />
              );
            })}
          </Box>
          <Box>
            <FooterButton onClose={onClose} isShowFilterBtn={true} handleSubmit={handleApplyFilter} btnName="Apply" />
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default FilterModal;
