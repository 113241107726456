import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  lenderActionButton: {
    marginLeft: theme.spacing(2),
    maxWidth: 'auto',
  },
  boxCircleIcon: {
    color: theme.palette.Blue[600],
    backgroundColor: theme.palette.Blue[50],
    width: theme.spacing(12.5),
    height: theme.spacing(12.5),
    minWidth: theme.spacing(12.5),
    display: 'flex',
    alignItems: 'center',
    borderRadius: '10rem',
    justifyContent: 'center',
  },
  boxCircleText: {
    color: `${theme.palette.Gray[900]}`,
    textTransform: 'capitalize',
    width: '100%',
    maxWidth: 'calc(100% - 3.125rem)',
  },
  boxCircleIconTextWrap: {
    display: 'flex',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  },
  boxCircleTitle: {
    color: `${theme.palette.Gray[700]} !important`,
    fontWeight: '500',
  },
  btnNoPadding: {
    padding: `${theme.spacing(0)} !important`,
  },
  boxBorderLR: {
    borderLeft: `1px solid #CFDADC`,
    borderRight: `1px solid #CFDADC`,
  },
  btnBlueText: {
    color: `${theme.palette.Blue[600]} !important`,
    textTransform: 'capitalize',
    boxShadow: 'none !important',
    '&:active': {
      outline: 'none',
      scale: '1 !important',
    },
    '&:hover': {
      outline: 'none',
      boxShadow: 'none !important',
      backgroundColor: 'transparent !important',
    },
    '&.Mui-selected': {
      outline: 'none',
    },
  },
  fontJost: {
    fontFamily: 'jost !important',
  },
  cardShadow: {
    boxShadow: '0px 2px 24px 0px #9292921A',
    backgroundColor: theme.palette.primary.contrastText,
    borderRadius: theme.spacing(3),
  },
  welcomeStripWRap: {},
  modal_main: {
    width: '620px',
  },
  mainWrapper: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  justifyCenter: {
    justifyContent: 'center',
  },
  justifyEnd: {
    justifyContent: 'end',
  },
}));
