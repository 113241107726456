import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  modalBody: {
    padding: theme.spacing(6),
  },
  modalCloseIcon: {
    position: 'absolute',
    right: '10px',
    top: '15px',
  },
  modal_480: {
    width: '480px',
    borderRadius: '12px',
  },
  modalIconBorderRed: {
    width: '3.5rem',
    height: '3.5rem',
    backgroundColor: '#FEE4E2',
    border: `10px solid ${theme.palette.Red[50]}`,
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.darkRed.main,
  },
}));
