import { FC, useEffect, useState } from 'react';
import { Container, Grid, Typography, Box, Chip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { updateLenderSupportedRegions as updateLenderSupportedRegionsApi } from 'http/lenders';
import { Loader } from 'components/Loader';
import { AlertMessage } from 'components/alerts';
import { AlertMessageStatus } from 'core/types';
import { useLenders } from 'store/lenders/hooks';
import { useGlobalState } from 'store/global/hooks';
import { ApplicationRegion } from 'store/applications/types';
import { LoadingButton } from 'components/buttons';
import { useAccount } from 'store/account/hooks';
import useStyles from './Account.styles';

const Account: FC = () => {
  const [loading, setLoading] = useState(false);
  const [supportedRegions, setSupportedRegions] = useState<ApplicationRegion[]>([]);
  const classes = useStyles();
  const { t } = useTranslation();
  const { error, success, setError, setSuccess } = useLenders();
  const { error: globalError } = useGlobalState();
  const {
    account: { supported_regions },
    fetchAccount,
  } = useAccount();

  useEffect(() => {
    fetchAccount();
  }, [fetchAccount]);

  useEffect(() => {
    if (supported_regions) setSupportedRegions(supported_regions);
  }, [supported_regions]);

  const handleRegionsUpdate = async () => {
    setLoading(true);
    try {
      await updateLenderSupportedRegionsApi(supportedRegions);
      setSuccess(t('pages.account.messages.updateRegionsSuccess') as string);
    } catch (err) {
      setError((err as Error).message ?? true);
    }
    setLoading(false);
  };

  return (
    <Container disableGutters maxWidth="xl">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography className={classes.title}>{t('pages.account.title')}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography className={classes.subtitle}>{t('pages.account.sections.supportedRegions.title')}</Typography>

          <Box className={classes.supportedRegionsContainer}>
            {Object.values(ApplicationRegion).map((region) => {
              const alreadySelected = supportedRegions.includes(region);
              return (
                <Chip
                  id={region}
                  key={region}
                  color={alreadySelected ? 'primary' : 'default'}
                  label={region}
                  onClick={() => {
                    if (alreadySelected) {
                      setSupportedRegions((prevState) => prevState.filter((sR) => sR !== region));
                    } else {
                      setSupportedRegions((prevState) => [...prevState, region]);
                    }
                  }}
                  className={classes.supportedRegionOption}
                />
              );
            })}
          </Box>

          <LoadingButton
            variant="contained"
            onClick={handleRegionsUpdate}
            color="primary"
            disableElevation
            loading={loading}
          >
            {t('global.buttons.save')}
          </LoadingButton>
        </Grid>
      </Grid>

      <Loader visible={loading} />

      {!globalError && (
        <>
          <AlertMessage
            open={!!error}
            onClose={() => setError(false)}
            message={typeof error === 'string' ? error : undefined}
            autoHideDuration={5000}
          />

          <AlertMessage
            open={!!success}
            onClose={() => setSuccess(false)}
            message={typeof success === 'string' ? success : undefined}
            autoHideDuration={5000}
            status={AlertMessageStatus.SUCCESS}
          />
        </>
      )}
    </Container>
  );
};

export default Account;
