import { FC, useCallback, useState } from 'react';
import { Grid, Typography, CircularProgress, Box } from '@material-ui/core';
import clsx from 'clsx';
import Dropzone, { FileRejection } from 'react-dropzone';

import { CompanyDocumentType, DocUploadResponse, FileErrors } from 'core/types';
import { CompanyDocument } from 'store/applications/types';
import useStyles from './FileDrop.styles';
import FileDropInfo from './FileDropInfo';
import useLeadStyle from '../../pages/lead/Lead.styles';

// 20MB -> in bytes
// 20MB -> in bytes
const maxFileSize = 20000000;

interface AllowedFiletype {
  name: string;
  mimeType: string;
  extension: string;
}

interface FileDropProps {
  allowedFileTypes: Array<AllowedFiletype>;
  onUpload(documents: CompanyDocument[]): void;
  onReject(rejections: FileRejection[]): void;
  uploadMessage: string;
  uploadDocument(data: FormData): Promise<DocUploadResponse | null>;
  documentType: CompanyDocumentType;
  error?: boolean | string;
  multiple?: boolean;
  uploadedDocs: CompanyDocument[];
}

interface FileState {
  duplicates: string[];
  acceptedFiles: File[];
  fileRejections: FileRejection[];
}

const FileDrop: FC<FileDropProps> = ({
  allowedFileTypes,
  onUpload,
  onReject,
  uploadDocument,
  documentType,
  error,
  multiple,
  uploadedDocs,
}) => {
  const [fileState, setFileState] = useState<FileState>({ duplicates: [], acceptedFiles: [], fileRejections: [] });
  const [loading, setLoading] = useState<boolean>(false);
  const classes = useStyles();
  const homeClasses = useLeadStyle();

  const closeDuplicateModal = () => setFileState((prev) => ({ ...prev, duplicates: [] }));

  const continueUpload = useCallback(
    async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      const Data = new FormData();

      Data.append('type', documentType);

      const files: { [key: string]: File } = {};
      acceptedFiles.forEach((file) => {
        Data.append('file', file);
        files[file.name] = file;
      });
      if (!fileRejections.length) {
        setLoading(true);
        try {
          const res: DocUploadResponse | null = await uploadDocument(Data);
          if (res) {
            const uploadedDocuments = res.companyDocs.map(({ name, id, type }) => ({ name, id, type }));
            onUpload(uploadedDocuments);
          }
          if (res?.errors.length) throw new Error(JSON.stringify(res.errors));
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
          const errors = JSON.parse(err.message) as FileErrors;
          errors.forEach(({ file, error: fileError }) => {
            fileRejections.push({
              file: files[file],
              errors: [
                {
                  code: 'too-many-files', // TODO: use actual error code
                  message: fileError,
                },
              ],
            });
          });
        }
        setLoading(false);
      }

      // Don't check if array has any element, we want to reset rejections on every upload op
      onReject(fileRejections);
    },
    [documentType, onReject, uploadDocument, onUpload],
  );

  const onDrop = useCallback(
    async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      const duplicates = uploadedDocs.reduce((acc: string[], doc) => {
        acceptedFiles.forEach((file) => {
          if (doc.name?.replace(/^.+\//, '') === file.name) {
            acc.push(doc.name);
          }
        });
        return acc;
      }, []);
      if (duplicates.length) {
        return setFileState((prev) => ({ ...prev, duplicates, acceptedFiles, fileRejections }));
      }
      return continueUpload(acceptedFiles, fileRejections);
    },
    [continueUpload, uploadedDocs],
  );

  const { duplicates, acceptedFiles: accFiles, fileRejections: rejFiles } = fileState;
  return (
    <>
      <Dropzone
        onDrop={(acceptedFiles, fileRejections) => onDrop(acceptedFiles, fileRejections)}
        maxSize={maxFileSize}
        accept={allowedFileTypes.map((fileType) => fileType.mimeType)}
        disabled={loading}
        multiple={multiple}
      >
        {({ getRootProps, getInputProps }) => (
          <section className={classes.container}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Box {...getRootProps()} className={clsx([classes.outer, error && classes.outerError])}>
                  <Box className={loading ? clsx([classes.inner, classes.disabled]) : classes.inner}>
                    <input {...getInputProps()} id={`dropDocumentInput-${documentType}`} />
                    <Box className={homeClasses.inputFileContent}>
                      <span className={homeClasses.inputFileIcon}>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clipPath="url(#clip0_1698_3041)">
                            <path
                              d="M13.3334 13.3334L10 10M10 10L6.66669 13.3334M10 10V17.5M16.9917 15.325C17.8045 14.8819 18.4466 14.1808 18.8166 13.3322C19.1866 12.4837 19.2635 11.5361 19.0352 10.6389C18.8069 9.74182 18.2863 8.94629 17.5556 8.3779C16.8249 7.80951 15.9257 7.50064 15 7.50003H13.95C13.6978 6.5244 13.2277 5.61864 12.575 4.85085C11.9223 4.08307 11.1041 3.47324 10.1818 3.0672C9.25949 2.66116 8.25715 2.46949 7.25013 2.5066C6.2431 2.5437 5.25758 2.80861 4.36768 3.28142C3.47777 3.75422 2.70662 4.42261 2.11221 5.23635C1.5178 6.05008 1.1156 6.98797 0.935844 7.97952C0.756086 8.97107 0.803449 9.99047 1.07437 10.9611C1.3453 11.9317 1.83273 12.8282 2.50003 13.5834"
                              stroke="#475467"
                              strokeWidth="1.66667"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_1698_3041">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      <Typography component="p" className={homeClasses.inputFileText}>
                        Click to upload
                        <Typography component="span">or drag and drop</Typography>
                      </Typography>
                    </Box>
                  </Box>
                  {loading && <CircularProgress className={classes.progress} />}
                </Box>
              </Grid>
            </Grid>
          </section>
        )}
      </Dropzone>
      <FileDropInfo
        clearDuplicates={() => setFileState((prev) => ({ ...prev, duplicates: [] }))}
        duplicates={duplicates}
        handleClose={closeDuplicateModal}
        isOpen={Boolean(duplicates.length)}
        continueUpload={() => continueUpload(accFiles, rejFiles)}
      />
    </>
  );
};

export default FileDrop;
