import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  title: {
    fontSize: theme.typography.pxToRem(15),
    lineHeight: theme.typography.pxToRem(20),
    fontWeight: 700,
  },
  controlLabel: {
    '& span.MuiTypography-body1': {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      fontWeight: 700,
      textTransform: 'capitalize',
    },
    '& svg': {
      width: 20,
      height: 20,
    },
  },
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  filter_cardBody: {
    padding: '0px 16px 24px 16px',
  },
  styleOneLine: {
    paddingLeft: '5px',
    columnCount: 1,
  },
}));
