import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  customLegend: {
    '& .apexcharts-legend': {
      display: 'block',
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
    '& .apexcharts-legend-item': {
      display: 'flex',
      alignItems: 'center',
      marginRight: '20px',
    },
    '& .apexcharts-legend-marker': {
      width: '12px',
      height: '12px',
      marginRight: '5px',
    },
    '& .apexcharts-legend-text': {
      color: '#333',
      fontSize: '14px',
    },
    '& .apexcharts-pie': {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      height: '300px',
      marginLeft: '20px',
    },
    '& .apexcharts-slices': {
      width: '12px',
      height: '12px',
    },
    tooltipStyle: {
      pending: '10px',
    },
  },
}));

export default useStyles;
