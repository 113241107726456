import omitBy from 'lodash.omitby';
import isNil from 'lodash.isnil';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getApplicationOfferData } from 'http/applications';
import { IApplicationOffer, IOfferQuery, PartnerQuery } from './types';

export const GET_LENDERS = 'admin/APPLICATION_OFFER';

export const getApplicationOffer = createAsyncThunk<
  { applicationOffer: IApplicationOffer[]; total: number },
  PartnerQuery
>(GET_LENDERS, async ({ id, query }) => {
  const data = await getApplicationOfferData(id, omitBy(query, isNil) as IOfferQuery);
  return { applicationOffer: data?.offers, total: data?.total };
});
