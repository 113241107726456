import { apiCall } from 'http/index';

export const getApplicationCount = async (
  params?: object,
): Promise<
  {
    month: string;
    count: string;
  }[]
> => apiCall({ method: 'get', url: 'applications/matrix', params });

export const getOfferCount = async (): Promise<
  {
    month: string;
    count: string;
  }[]
> => apiCall({ method: 'get', url: 'offers/matrix' });

export const getLendersCount = async (): Promise<
  {
    month: string;
    count: string;
  }[]
> => apiCall({ method: 'get', url: 'admin/lenders/matrix' });
