import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  container: {},
  headerContainer: {
    display: 'flex',
    alignItems: 'center',

    '& .MuiSwitch-root': {
      marginLeft: '-12px',
    },
  },
  title: {
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(24),
  },
  subtitle: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(20),
    fontWeight: 700,
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',

    '& button': {
      marginRight: theme.spacing(1),
    },

    '& button:last-child': {
      marginRight: 0,
    },
  },
  keysContainer: {
    padding: theme.spacing(3),
  },
}));
