import { ActionReducerMapBuilder, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { LendersState } from './types';
import {
  getLenders,
  createLender,
  updateLender,
  setLenderLogo,
  getAllLenders,
  getLenderDetails,
  setLenderDetails,
  setError,
  setSuccess,
} from './actions';

const initialState: LendersState = {
  loading: false,
  error: false,
  success: false,
  allLenders: [],
  lenders: [],
  total: -1,
  lenderDetails: null,
};

const lendersStore = createSlice({
  name: 'lenders',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<LendersState>) => {
    builder.addCase(getLenders.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.lenders = payload.lenders;
      state.total = payload.total;
    });

    builder.addCase(createLender.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(updateLender.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(setLenderLogo, (state, { payload }) => {
      const lenderIdx = state.lenders.findIndex((lender) => lender.id === payload.lenderId);
      if (lenderIdx >= 0) {
        state.lenders[lenderIdx].logo_url = payload.logoUrl;
      }
    });

    builder.addCase(getAllLenders.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.allLenders = payload;
    });

    builder.addCase(getLenderDetails.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.lenderDetails = payload;
    });

    builder.addCase(setLenderDetails, (state, { payload }) => {
      state.lenderDetails = payload;
    });

    builder.addCase(setError, (state, { payload }) => {
      state.error = payload;
    });

    builder.addCase(setSuccess, (state, { payload }) => {
      state.success = payload;
    });

    builder.addMatcher(
      isAnyOf(
        getLenders.pending,
        createLender.pending,
        updateLender.pending,
        getAllLenders.pending,
        getLenderDetails.pending,
      ),
      (state) => {
        state.loading = true;
        state.error = false;
      },
    );

    builder.addMatcher(
      isAnyOf(
        getLenders.rejected,
        createLender.rejected,
        updateLender.rejected,
        getAllLenders.rejected,
        getLenderDetails.rejected,
      ),
      (state, { error }) => {
        state.loading = false;
        state.error = error?.message || true;
      },
    );
  },
});

export default lendersStore.reducer;
