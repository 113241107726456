import { ChangeEvent, ReactNode } from 'react';
import { Box, InputBaseComponentProps, Typography } from '@mui/material';
import clsx from 'clsx';
import { useField } from 'formik';
import InputAdornment from '@mui/material/InputAdornment';
import ErrorMeg from 'components/inputs/ErrorMessage/ErrorMessage';
import { CustomInput } from 'pages/home/Home.styles';
import useStyles from './TextInputBox.styles';

interface ITextField {
  type?: string;
  extraElement?: ReactNode;
  label?: string;
  name: string;
  disabled?: boolean;
  placeholder?: string;
  icon?: string;
  className?: string;
  isCompulsory?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  startAdornment?: boolean;
  value: string | number | boolean | null | undefined;
  onInput?: (e: ChangeEvent<HTMLInputElement>) => void;
  formik?: boolean;
  inputProps?: InputBaseComponentProps;
  boxInput?: string;
  inputWidth?: string;
  mainBoxClassName?: string;
}

const TextFieldBox = ({
  startAdornment = false,
  type = 'text',
  label,
  name,
  disabled,
  placeholder,
  value,
  onInput,
  boxInput,
  formik = true,
  inputProps,
  icon = '£',
  inputWidth,
  mainBoxClassName,
}: ITextField) => {
  const [field] = useField(name);
  const classes = useStyles();
  return (
    <Box className={clsx([mainBoxClassName, classes.marginBottom24px])}>
      {' '}
      {label && (
        <Typography
          variant="text_MD"
          component="label"
          className={clsx([classes.fontJost, classes.fontWeight_500, classes.gray_500, classes.inputLbl])}
        >
          {label}
        </Typography>
      )}
      <Box className={clsx([classes.formInlineLblValue_value, boxInput])}>
        {type === 'number' ? (
          <CustomInput
            id="input-with-icon-adornment"
            placeholder={placeholder || ''}
            type="text"
            {...field}
            name={name}
            value={value}
            onInput={
              onInput ||
              ((event: ChangeEvent<HTMLInputElement>) => {
                const inputValue = event.target.value.replace(/[^0-9]/g, '');
                event.target.value = inputValue;
              })
            }
            autoComplete="new-password"
            className={clsx([startAdornment ? classes.iconInputBox : classes.inputBox, inputWidth])}
            inputProps={inputProps}
            startAdornment={
              startAdornment && (
                <InputAdornment position="start">
                  <Typography
                    variant="text_MD"
                    component="span"
                    className={clsx([
                      classes.fontJost,
                      classes.fontWeight_500,
                      classes.black_500,
                      classes.inputIconLeft,
                    ])}
                  >
                    {icon}
                  </Typography>
                </InputAdornment>
              )
            }
            disabled={disabled !== undefined ? disabled : false}
          />
        ) : (
          <CustomInput
            id="input-with-icon-adornment"
            placeholder={placeholder || ''}
            type={type}
            {...field}
            name={name}
            value={value}
            autoComplete="new-password"
            className={clsx([classes.inputBox, inputWidth])}
            startAdornment={
              startAdornment && (
                <InputAdornment position="start">
                  <Typography
                    variant="text_MD"
                    component="span"
                    className={clsx([
                      classes.fontJost,
                      classes.fontWeight_500,
                      classes.black_500,
                      classes.inputIconLeft,
                    ])}
                  >
                    {icon}
                  </Typography>
                </InputAdornment>
              )
            }
            disabled={disabled !== undefined ? disabled : false}
          />
        )}
      </Box>
      {formik && <ErrorMeg name={name} />}
    </Box>
  );
};

export default TextFieldBox;
