import axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';
import * as rax from 'retry-axios';
import { Store } from '@reduxjs/toolkit';
import jwtDecode, { JwtPayload } from 'jwt-decode';

import { isNetWorkError, accept5xx } from 'http/utils';
import { setGlobalError } from 'store/global/actions';
import http from 'http/index';
import { logout } from 'store/auth/actions';
import { clearAuthSession, getAutSession } from 'utils';

// retry axios setup
http.defaults.raxConfig = {
  instance: http,
  retry: 1,
};
rax.attach(http);

export default {
  setup: (store: Store): void => {
    const addAuthorizationToken = async (config: AxiosRequestConfig) => {
      const authSession = getAutSession();

      if (authSession && authSession.accessToken) {
        // check for expired token
        const expirationTime = jwtDecode<JwtPayload>(authSession.accessToken).exp as number;
        if (expirationTime < Date.now() / 1000) {
          // token has expired
          clearAuthSession();
          store.dispatch(logout());
          // eslint-disable-next-line @typescript-eslint/no-throw-literal
          throw new axios.CancelToken((cancel) => cancel('Session has expired, please login again!'));
        }
        config.headers.Authorization = `Bearer ${authSession.accessToken}`;
      }

      return config;
    };

    const clearGlobalErrors = (response: AxiosResponse) => {
      const globalError = store.getState().global.error;
      if (globalError) store.dispatch(setGlobalError(false));
      return response;
    };

    const checkForGlobalError = (error: AxiosError) => {
      const currentRetryAttempt = error.config.raxConfig?.currentRetryAttempt;
      const responseStatusCode = error.response?.status;

      if (isNetWorkError(error) || (responseStatusCode && accept5xx(responseStatusCode) && currentRetryAttempt === 1)) {
        store.dispatch(setGlobalError(true));
      }

      return Promise.reject(error);
    };

    http.interceptors.request.use(addAuthorizationToken);
    http.interceptors.response.use(clearGlobalErrors, checkForGlobalError);
  },
};
