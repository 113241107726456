import { FC, useRef, useState } from 'react';
import { Grid } from '@material-ui/core';
import { Form, Formik, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { Box } from '@mui/material';
import TextFieldBox from 'components/CustomTextInput/TextInputBox';
import FooterButton from 'components/Modal/FooterButton';
import { createAccountOwner as createAccountOwnerApi } from 'http/admin';
import { AccountType } from 'store/applications/types';
import { useLenders } from 'store/lenders/hooks';
import { usePartners } from 'store/partners/hooks';
import useCommonStyles from '../../assets/css/Common.styles';

interface FormValues {
  first_name: string;
  last_name: string;
  email: string;
}

interface CreateAccountOwnerModalProps {
  toggleOpen: () => void;
  accountType: AccountType;
}

const CreateAccountOwnerModal: FC<CreateAccountOwnerModalProps> = ({ toggleOpen, accountType }) => {
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormikProps<FormValues> | null>(null);
  const commonClasses = useCommonStyles();
  const { t } = useTranslation();
  const {
    partnerDetails,
    getPartnerDetails,
    setError: setPartnersError,
    setSuccess: setPartnersSuccess,
  } = usePartners();
  const { lenderDetails, getLenderDetails, setError: setLendersError, setSuccess: setLendersSuccess } = useLenders();

  const accountDetails = accountType === AccountType.LENDER ? lenderDetails : partnerDetails;

  const formSchema = yup.object({
    first_name: yup.string().required(t('components.createAccountOwnerModal.inputs.first_name.required')),
    last_name: yup.string().required(t('components.createAccountOwnerModal.inputs.last_name.required')),
    email: yup
      .string()
      .email(t('components.createAccountOwnerModal.inputs.email.error'))
      .required(t('components.createAccountOwnerModal.inputs.email.required')),
  });

  const initialValues: FormValues = {
    first_name: accountDetails?.contact_person_name.split(' ')[0] ?? '',
    last_name: accountDetails?.contact_person_name.split(' ')[1] ?? '',
    email: accountDetails?.contact_person_email ?? '',
  };

  const onCancel = () => {
    formRef.current?.resetForm();
    toggleOpen();
  };

  const onSubmit = async (values: FormValues) => {
    if (!accountDetails) return;

    setLoading(true);
    try {
      await createAccountOwnerApi(accountDetails.id, accountType, values);
      const successMessage = t('components.createAccountOwnerModal.messages.createSuccess');

      if (accountType === AccountType.LENDER) {
        getLenderDetails(accountDetails.id);
        setLendersSuccess(successMessage);
      } else {
        getPartnerDetails(accountDetails.id);
        setPartnersSuccess(successMessage);
      }

      onCancel();
    } catch (err) {
      const error = (err as Error)?.message ?? true;
      if (accountType === AccountType.LENDER) setLendersError(error);
      else setPartnersError(error);
    }
    setLoading(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={formSchema}
      innerRef={formRef}
      enableReinitialize
    >
      {({ handleChange, values, handleSubmit }) => (
        <Box className={commonClasses.filter_cardBody}>
          <Form noValidate>
            <Grid container direction="column">
              <Grid item>
                <TextFieldBox
                  name="first_name"
                  value={values?.first_name}
                  onChange={handleChange}
                  label={t('components.createAccountOwnerModal.inputs.first_name.label')}
                />
              </Grid>

              <Grid item>
                <TextFieldBox
                  name="last_name"
                  value={values?.last_name}
                  label={t('components.createAccountOwnerModal.inputs.last_name.label')}
                />
              </Grid>

              <Grid item>
                <TextFieldBox
                  name="email"
                  value={values?.email}
                  label={t('components.createAccountOwnerModal.inputs.email.label')}
                />
              </Grid>
            </Grid>
            <Box>
              <FooterButton
                handleSubmit={handleSubmit}
                onClose={toggleOpen}
                btnName="Create account owner"
                loading={loading}
              />
            </Box>
          </Form>
        </Box>
      )}
    </Formik>
  );
};

export default CreateAccountOwnerModal;
