import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  logoutButtonContainer: {
    width: 44,
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '6px',
  },
  logoutButton: {
    '& .MuiButton-root': {
      minWidth: 44,
    },
    '& .MuiButton-text': {
      padding: 0,
    },
  },
  userContainer: {
    display: 'flex',
    marginBottom: theme.spacing(5),
    justifyContent: 'space-between',
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  userAvatar: {
    border: '1px solid #EAECF0',
    width: 40,
    marginRight: theme.spacing(2),
    backgroundColor: 'red',
  },
  name: {
    '&.MuiTypography-body1': {
      color: '#FCFCFD',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.spacing(6),
    },
  },
  status: {
    '&.MuiTypography-body1': {
      color: '#FCFCFD',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.spacing(2),
    },
  },
  menu: {
    marginTop: theme.spacing(1.5),
    'ul.MuiMenu-list': {
      paddingTop: 0,
      paddingBottom: 0,
      border: '1px solid #F2F4F7',
      boxShadow: '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
    },
    borderRadius: theme.spacing(1),
  },
  menuHeader: {
    width: '100%',
    '&.MuiButtonBase-root': {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
    },
  },
  userEmail: {
    marginLeft: theme.spacing(1),
    fontSize: theme.typography.pxToRem(14),
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: theme.spacing(2.5),
    color: '#344054',
    wordBreak: 'break-all',
    whiteSpace: `initial`,
  },
  link: {
    width: '100%',
    '&.MuiButtonBase-root': {
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
    },
  },
  divider: {
    border: '1px solid #F2F4F7',
    '&.MuiDivider-root': {},
  },
  iconContainer: {
    '&.MuiListItemIcon-root': {
      minWidth: theme.spacing(4),
    },
  },
  userIconContainer: {
    width: 40,
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#EFF8FF',
    borderRadius: 40,
  },
  badge: {
    '& .MuiBadge-badge': {
      right: 8,
      top: 36,
      padding: '5px 5px',
    },
  },
}));
export default useStyles;
