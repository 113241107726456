import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Container, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import clsx from 'clsx';
import moment from 'moment';
import CustomFilter from 'components/CustomTable/CustomFilter/CustomFilter';
import CustomTable from 'components/CustomTable/CustomTable';
import { Header } from 'components/Header/Header';
import { AlertMessage } from 'components/alerts';
import { customHeadRender } from 'components/table';
import { ROWS_PER_TABLE_PAGE } from 'core/constants';
import { AdminsTableColumnNames, AlertMessageStatus, UserQuery, UserRole } from 'core/types';
import { useDebounce } from 'hooks';
import { deleteAdmin as deleteAdminApi } from 'http/admin';
import { SEARCH_DEBOUNCE_DELAY, downloadCSVFile } from 'pages/leads/components/CreateApplicationModal/utils';
import { useAdmin } from 'store/admin/hooks';
import { useAuth } from 'store/auth/hooks';
import { useGlobalState } from 'store/global/hooks';
import CommonModal from 'components/Modal/CommonModal';
import { getUsers } from 'http/users';
import { getUserType } from 'core/utils';
import { CreateAdminModal } from './components';
import DeleteAdminModal from './components/DeleteAdminModal/DeleteAdminModal';
import useStyles from './Admins.styles';
import useCommonStyles from '../../assets/css/Common.styles';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete_icon.svg';
import { ReactComponent as DocumentPen } from '../../assets/icons/document_pen.svg';
import AdminActions from './components/AdminActions/AdminActions';

const Admins: FC = () => {
  const [createOpen, setCreateOpen] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState<null | { id: string; fullName: string }>(null);
  const [deleting, setDeleting] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>('');
  const debouncedSearchTerm = useDebounce(search, SEARCH_DEBOUNCE_DELAY);

  const commonClasses = useCommonStyles();
  const classes = useStyles();
  const { t } = useTranslation();
  const { loading, admins, total, error, success, setSuccess, setError, getAdminUsers, updateAdminUsers } = useAdmin();
  const { error: globalError } = useGlobalState();
  const { isSuperAdmin } = useAuth();

  const adminQuery: UserQuery = useMemo(
    () => ({
      per_page: ROWS_PER_TABLE_PAGE,
      page,
      role: `${UserRole.ADMIN},${UserRole.SUPER_ADMIN}`,
      search: debouncedSearchTerm,
    }),
    [page, debouncedSearchTerm],
  );

  const columns = useMemo(
    () => [
      {
        width: 300,
        field: AdminsTableColumnNames.ADMIN_NAME,
        headerName: t('pages.admins.table.columns.name'),
        options: {
          customHeadRender,
        },
      },
      {
        width: 300,
        field: AdminsTableColumnNames.EMAIL,
        headerName: t('pages.admins.table.columns.email'),
        options: {
          customHeadRender,
        },
      },
      {
        width: 300,
        field: AdminsTableColumnNames.OCCUPATION,
        headerName: t('pages.admins.table.columns.occupation'),
        renderCell: (params: GridRenderCellParams) => {
          return getUserType(params?.row?.role);
        },
      },
      {
        width: 300,
        field: AdminsTableColumnNames.CREATED_AT,
        headerName: t('pages.admins.table.columns.createdAt'),
        renderCell: (params: GridRenderCellParams) => {
          return params?.row?.created_at ? moment(params?.row?.created_at).format('DD/MM/YYYY') : '-';
        },
      },
      ...(isSuperAdmin
        ? [
            {
              width: 300,
              field: AdminsTableColumnNames.REMOVE,
              sortable: false,
              headerName: t('pages.admins.table.columns.action'),
              renderCell: (params: GridRenderCellParams) => {
                const onClick = () => {
                  if (params?.row?.id) setSelectedAdmin({ id: params?.row?.id, fullName: params?.row?.fullName });
                };
                return (
                  <Box
                    onClick={onClick}
                    className={clsx([
                      commonClasses.primary_600,
                      commonClasses.flexWithGap04,
                      commonClasses.cursorPointer,
                    ])}
                  >
                    Delete admin <DeleteIcon />
                  </Box>
                );
              },
            },
          ]
        : []),
    ],
    // eslint-disable-next-line
    [admins, classes.removeButton, isSuperAdmin, t],
  );

  const toggleCreateOpen = () => setCreateOpen((prevState) => !prevState);

  const handleDeleteCancel = () => setSelectedAdmin(null);

  const downloadCSV = async () => {
    await getUsers({ role: `${UserRole.ADMIN},${UserRole.SUPER_ADMIN}` }).then((data) => {
      const userData = data?.users?.map((d) => ({
        first_name: d?.first_name || '-',
        last_name: d?.last_name || '-',
        email: d?.email || '-',
        role: d?.role || '-',
        created_at: moment(d?.created_at).format('DD/MM/YYYY hh:mm a'),
      }));
      downloadCSVFile(userData, 'BScore_admin.csv');
    });
  };

  const handleDeleteConfirm = async () => {
    if (!selectedAdmin) return;
    setDeleting(true);
    try {
      await deleteAdminApi(selectedAdmin.id);
      updateAdminUsers(selectedAdmin.id);
      setSuccess(t('pages.admins.admin.success.delete') as string);
      setSelectedAdmin(null);
    } catch (err) {
      setError((err as Error).message ?? true);
    }
    setDeleting(false);
  };

  const getAdminUsersData = useCallback(() => {
    getAdminUsers(adminQuery);
    // eslint-disable-next-line
  }, [page, adminQuery, getAdminUsers, debouncedSearchTerm]);

  useEffect(() => {
    getAdminUsersData();
  }, [getAdminUsersData]);

  return (
    <Container disableGutters maxWidth="xl">
      <Header name={t('pages.admins.title') || ''} />
      <Box className={commonClasses.mainWrapper}>
        <Box className={commonClasses.filterMenu}>{isSuperAdmin && <AdminActions downloadCSV={downloadCSV} />}</Box>
        <Box className={commonClasses.cardShadow}>
          <Grid item xs={12} className={clsx([commonClasses.tableContainer])}>
            <CustomFilter
              downloadCSV={downloadCSV}
              total={total}
              page={page}
              isShowFilter={false}
              pageSize={ROWS_PER_TABLE_PAGE}
              searchPlaceholder="Search by admin name"
              setPage={setPage}
              setSearch={setSearch}
              search={search}
            />
            <CustomTable
              rows={admins}
              loading={loading}
              columns={columns}
              headerUpperCase={true}
              hideFooterPagination={true}
              NoDataFountMessage="No Data Found"
              disableColumnMenu={true}
            />
          </Grid>
        </Box>
      </Box>
      {createOpen && (
        <CommonModal
          open={createOpen}
          title={t('pages.admins.admin.createAdmin.title')}
          onClose={toggleCreateOpen}
          icon={<DocumentPen />}
        >
          {' '}
          <CreateAdminModal toggleOpen={toggleCreateOpen} />
        </CommonModal>
      )}

      {selectedAdmin?.id && (
        <DeleteAdminModal
          handleSubmit={handleDeleteConfirm}
          open={!!selectedAdmin?.id}
          loading={deleting}
          onClose={handleDeleteCancel}
        />
      )}

      {!globalError && (
        <>
          <AlertMessage
            open={!!error}
            onClose={() => setError(false)}
            message={typeof error === 'string' ? error : undefined}
            autoHideDuration={5000}
          />

          <AlertMessage
            open={!!success}
            onClose={() => setSuccess(false)}
            message={typeof success === 'string' ? success : undefined}
            autoHideDuration={5000}
            status={AlertMessageStatus.SUCCESS}
          />
        </>
      )}
    </Container>
  );
};

export default Admins;
