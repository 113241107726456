import { Box, createTheme, Grid, ThemeProvider, Typography } from '@mui/material';
import { Select, Card } from '@material-ui/core';
import clsx from 'clsx';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { CustomDatePicker } from 'components/inputs';
import ReactChart from 'components/Chart/ReactChart';
import { OverviewDateRange } from 'core/types';
import { getDateRangeByFilter } from 'utils';
import { getApplicationCount } from 'http/metrics';
import {
  applicationStatusChartColor,
  monthName,
  OfferStatusAndActiveLenderChartColor,
  overviewFilterOptions,
} from 'core/constants';
import { getApplicationsCount, getLendersCount, getOffersCount } from 'http/dashboard';
import { IDashboardData, ILenderCount } from 'store/applications/types';
import { useAuth } from 'store/auth/hooks';
import { isValidArray, isValidObject } from 'pages/leads/components/CreateApplicationModal/utils';
import { ReactComponent as StatusOverNotFound } from '../../../../assets/icons/statusOverviewPageNotFound.svg';
import { ReactComponent as NoFoundData } from '../../../../assets/icons/no_data_found.svg';
import useCommonStyles from '../../../../assets/css/Common.styles';
import { useStyles } from './chart.style';

export const chartLabelData: {
  offer: string[];
  lenders: string[];
} = {
  offer: ['Offered', 'Declined', 'Additional details requested', 'Accepted', 'Lender call failed', 'Rule declined'],
  lenders: ['Iwoca', 'Youlend', 'Fleximize', 'Capital on Tap'],
};
const themeColor = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#D0D5DD',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#D0D5DD',
          },
        },
      },
    },
  },
});
const Charts: FC = () => {
  const { isAdmin } = useAuth();

  const [statusOverViewFilter, setStatusOvertViewFilter] = useState<{
    fromDate: Date;
    toDate: Date;
    monthData: string;
  }>({
    fromDate: moment().subtract(1, 'weeks').subtract(1, 'days').toDate(),
    toDate: moment().endOf('day').toDate(),
    monthData: '',
  });
  const initialStatus = {
    application_status: [0, 0, 0, 0, 0, 0, 0],
    offer_status: [0, 0, 0, 0, 0, 0],
    active_lenders_values: [],
    active_lenders_labels: [],
  };
  const [statusOverViewData, setStatusOverviewData] = useState<{
    application_status: number[];
    offer_status: number[];
    active_lenders_values: number[];
    active_lenders_labels: string[];
  }>(initialStatus);
  const [applicationStateCount, setApplicationStateCount] = useState<{ count: string; month: string }[]>([]);

  const { t } = useTranslation();
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  /* Methods */
  const checkChartData = (data: number[]) => data?.every((d) => d === 0);
  const checkNullValue = (data: object) => !Object.values(data)?.some((d) => d === null);

  const handleDateRangeChange = (
    event: ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => {
    const dateRangeFilter = event.target.value as OverviewDateRange;
    const { start, end } = getDateRangeByFilter(dateRangeFilter);
    setStatusOvertViewFilter({ ...statusOverViewFilter, monthData: dateRangeFilter, fromDate: start, toDate: end });
  };

  const fetchDashboardData = async () => {
    const defaultLenders: ILenderCount = {
      count: 0,
      lender: {
        displayedName: '',
      },
    };
    const [applicationsCount, offersCount, lendersCount] = await Promise.all([
      getApplicationsCount({ start: statusOverViewFilter?.fromDate, end: statusOverViewFilter?.toDate }),
      getOffersCount({ start: statusOverViewFilter?.fromDate, end: statusOverViewFilter?.toDate }),
      isAdmin
        ? getLendersCount({ start: statusOverViewFilter?.fromDate, end: statusOverViewFilter?.toDate })
        : Promise.resolve([defaultLenders]),
    ]);

    const dashboardCountData: IDashboardData = {
      applicationsCount,
      offersCount,
      lendersCount,
    };
    const applicationCount = dashboardCountData?.applicationsCount;
    const offer = dashboardCountData?.offersCount;
    const lenders = dashboardCountData?.lendersCount;

    const lendersData = lenders.reduce(
      (acc: { labels: string[]; values: number[] }, curr: ILenderCount) => {
        acc.labels.push(curr?.lender.displayedName);
        acc.values.push(curr.count);
        return acc;
      },
      { labels: [], values: [] },
    );

    setStatusOverviewData({
      ...statusOverViewData,
      ...(checkNullValue(offer)
        ? isValidObject(offer) && {
            offer_status: [
              offer?.OFFERED,
              offer?.DECLINED,
              offer?.ADDITIONAL_DETAILS_REQUESTED,
              offer?.ACCEPTED,
              offer?.LENDER_CALL_FAILED,
              offer?.RULES_DECLINED,
            ],
          }
        : { offer_status: initialStatus.offer_status }),
      ...(checkNullValue(applicationCount)
        ? isValidObject(applicationCount) && {
            application_status: [
              applicationCount?.OFFER_ACCEPTED,
              applicationCount?.PENDING,
              applicationCount?.NEW,
              applicationCount?.PREQUALIFIED,
              applicationCount?.CLOSED,
              0,
              0,
              applicationCount?.REJECTED,
            ],
          }
        : { application_status: initialStatus.application_status }),
      ...(isValidArray(lenders) && {
        active_lenders_values: [...lendersData.values],
      }),
      ...(isValidArray(lenders) && {
        active_lenders_labels: [...lendersData.labels],
      }),
    });
  };

  const getValueUsingMonth = () => {
    const chartData: number[] = [];
    monthName?.forEach((d) => {
      const value = applicationStateCount?.find((e) => moment(e?.month).format('MMM') === d);
      if (isValidObject(value as object) && value) {
        chartData.push(+value?.count || 0);
      } else {
        chartData.push(0);
      }
    });
    return chartData;
  };

  /* Api */
  useEffect(() => {
    fetchDashboardData();
    // eslint-disable-next-line
  }, [statusOverViewFilter]);

  const getApplicationStatusData = async () => {
    await getApplicationCount().then((res) => {
      setApplicationStateCount(res);
    });
  };
  useEffect(() => {
    getApplicationStatusData();
    // eslint-disable-next-line
  }, []);

  return (
    <Grid item xs={12} lg={6} xl={9}>
      <Box className={clsx([classes.cardInGrid])}>
        <Box sx={{ alignItems: 'center' }} className={clsx([classes.c_cardTitle, classes.flex_item])}>
          <Typography className={clsx([classes.c_cardTitleText])} variant="text_MD" component="div">
            Status overview
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', gap: '1rem' }}>
            <Box className={classes.datePickerContainer}>
              <Typography className={clsx([classes.subtitle, classes.semibold])}>
                {t('pages.dashboard.filters.date.from')}:
              </Typography>
              <CustomDatePicker
                className={classes.datePicker_wrap}
                inputClassName={classes.home_datePicker}
                name="fromDate"
                value={statusOverViewFilter?.fromDate || null}
                onChange={(date: Date) => {
                  setStatusOvertViewFilter({
                    ...statusOverViewFilter,
                    fromDate: moment(date).startOf('day').toDate(),
                  });
                }}
                isShowIcon="true"
                collapsed
                openTo="date"
                format="DD MMMM YYYY"
              />

              <Typography className={clsx([classes.subtitle, classes.semibold])}>
                {t('pages.dashboard.filters.date.to')}:
              </Typography>
              <CustomDatePicker
                name="toDate"
                isShowIcon="false"
                className={classes.datePicker_wrap}
                inputClassName={classes.home_datePicker}
                value={statusOverViewFilter?.toDate || null}
                onChange={(date: Date) => {
                  setStatusOvertViewFilter({
                    ...statusOverViewFilter,
                    toDate: moment(date).startOf('day').toDate(),
                  });
                }}
                openTo="date"
                minDate={new Date(statusOverViewFilter?.fromDate)}
                collapsed
                format="DD MMMM YYYY"
              />
            </Box>{' '}
            <Box className={classes.selectContainer}>
              <ThemeProvider theme={themeColor}>
                <Select
                  native
                  variant="outlined"
                  name="stateOverViewFilter"
                  className={clsx([classes.select, classes.selectBoxDropdown])}
                  onChange={handleDateRangeChange}
                >
                  {overviewFilterOptions?.map((data) => {
                    return (
                      <option key={data?.value} className={clsx([classes.selectBoxDropdown])} value={data?.value}>
                        {data?.label}
                      </option>
                    );
                  })}
                </Select>
              </ThemeProvider>
            </Box>
          </Box>
        </Box>
        {checkChartData(statusOverViewData?.application_status) &&
        !checkChartData(statusOverViewData?.offer_status) &&
        checkChartData(statusOverViewData?.active_lenders_values) ? (
          <Grid className={clsx([classes.chartGroup, classes.statusOverViewPageNotFound])}>
            <Grid>
              <Box className={classes.status_overview_icon_padding}>
                {' '}
                <StatusOverNotFound />
              </Box>
              <Grid className={clsx([classes.statusOverViewPageNotFound])}>No activity for this period</Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid container className={clsx([classes.chartGroup])} spacing={4}>
            <Grid item xs={12} lg={6} xl={4} className={clsx([commonClasses.itemCenterWithGrid])}>
              {' '}
              <Card className={classes.cardGrey}>
                <Typography className={clsx([classes.c_cardTitleText])}>Application status</Typography>
                {!checkChartData(statusOverViewData?.application_status) ? (
                  <ReactChart
                    position="bottom"
                    series={statusOverViewData?.application_status}
                    labels={[
                      `Accepted (${statusOverViewData?.application_status[0]})`,
                      `Pending (${statusOverViewData?.application_status[1]})`,
                      `New (${statusOverViewData?.application_status[2]})`,
                      `Pre-qualified (${statusOverViewData?.application_status[3]})`,
                      `Closed (${statusOverViewData?.application_status[4]})`,
                      `Lender review (${statusOverViewData?.application_status[5]})`,
                      `Awaiting client info (${statusOverViewData?.application_status[6]})`,
                      `Rejected (${statusOverViewData?.application_status[7]})`,
                    ]}
                    colors={applicationStatusChartColor}
                  />
                ) : (
                  <Box className={commonClasses.flexSpaceCenter}>
                    <Box>
                      <NoFoundData />
                      <Typography className={clsx([classes.c_cardTitleText])}>No Data Found!</Typography>
                    </Box>
                  </Box>
                )}
              </Card>
            </Grid>
            <Grid item xs={12} lg={6} xl={4} className={clsx([commonClasses.itemCenterWithGrid])}>
              <Card className={classes.cardGrey}>
                <Typography className={clsx([classes.c_cardTitleText])}>Offer status</Typography>
                {!checkChartData(statusOverViewData?.offer_status) ? (
                  <ReactChart
                    position="bottom"
                    series={statusOverViewData?.offer_status}
                    labels={chartLabelData?.offer}
                    colors={OfferStatusAndActiveLenderChartColor?.slice(0, 6)}
                  />
                ) : (
                  <Box className={commonClasses.flexSpaceCenter}>
                    <Box>
                      <NoFoundData />
                      <Typography className={clsx([classes.c_cardTitleText])}>No Data Found!</Typography>
                    </Box>
                  </Box>
                )}
              </Card>
            </Grid>
            <Grid item xs={12} lg={6} xl={4} className={commonClasses.itemCenterWithGrid}>
              <Card className={classes.cardGrey}>
                <Typography className={clsx([classes.c_cardTitleText])}>Most active lenders</Typography>
                {!checkChartData(statusOverViewData?.active_lenders_values) ? (
                  <ReactChart
                    position="bottom"
                    series={statusOverViewData?.active_lenders_values}
                    labels={statusOverViewData?.active_lenders_labels}
                    colors={OfferStatusAndActiveLenderChartColor?.slice(0, 4)}
                  />
                ) : (
                  <Box className={commonClasses.flexSpaceCenter}>
                    <Box>
                      <NoFoundData />
                      <Typography className={clsx([classes.c_cardTitleText])}>No Data Found!</Typography>
                    </Box>
                  </Box>
                )}
              </Card>
            </Grid>
          </Grid>
        )}
        <Grid sx={{ paddingTop: '1rem' }}>
          <Card className={classes.cardGrey}>
            <Box className={clsx([classes.c_cardTitle, classes.flex_item])}>
              <Typography className={clsx([classes.c_cardTitleText])} variant="text_MD" component="div">
                Application status
              </Typography>
            </Box>
            <ReactChart
              yaxisTitle="Number of applications"
              xaxisCategories={monthName}
              colors={['#0086C9']}
              type="area"
              isShowTooltip={true}
              series={getValueUsingMonth() || []}
            />
          </Card>
        </Grid>
      </Box>
    </Grid>
  );
};

export default Charts;
