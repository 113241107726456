import { useState } from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CommonModal from 'components/Modal/CommonModal';
import { East } from '@mui/icons-material';
import clsx from 'clsx';
import { CreatePartnerModal } from '../CreatePartnerModal';
import useStyles from '../../../lenders/components/LenderAdminActions/LenderAdminActions.styles';
import { ReactComponent as DownloadLenders } from '../../../../assets/icons/download_lenders.svg';
import { ReactComponent as DocumentPen } from '../../../../assets/icons/document_pen.svg';

const PartnerAdminActions = ({ downloadCSV }: { downloadCSV: () => void }) => {
  const [createOpen, setCreateOpen] = useState(false);
  const { t } = useTranslation();
  const classes = useStyles();

  const toggleCreateOpen = () => setCreateOpen((prevState) => !prevState);

  return (
    <Box sx={{ marginTop: '30px' }}>
      <Box className={clsx([classes.welcomeStripWRap, classes.cardShadow])}>
        <Grid container spacing={4} sx={{ alignItems: 'center' }}>
          <Grid item xs={3.6}>
            <Box className={classes.boxCircleIconTextWrap}>
              <Stack sx={{ mb: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={6}>
                <Box className={classes.boxCircleIcon}>
                  <DocumentPen />
                </Box>
                <Box className={classes.boxCircleText}>
                  <Typography
                    className={clsx([classes.fontJost, classes.boxCircleTitle])}
                    component="p"
                    variant="text_MD"
                    sx={{ mb: 2 }}
                  >
                    {t('pages.partners.admin.actions.create')}
                  </Typography>
                  <Button
                    onClick={toggleCreateOpen}
                    disableRipple={true}
                    className={clsx([classes.btnNoPadding, classes.btnBlueText])}
                    endIcon={<East />}
                  >
                    {t('pages.partners.admin.actions.create')}
                  </Button>
                </Box>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={4.8} justifyContent="center">
            <Box
              className={clsx([classes.boxBorderLR, classes.boxCircleIconTextWrap, classes.justifyCenter])}
              justifyContent="center"
            >
              <Stack sx={{ mb: 0 }} direction="row" justifyContent="center" alignItems="center" spacing={6}>
                <Box className={classes.boxCircleIcon}>
                  {' '}
                  <DownloadLenders />
                </Box>
                <Box className={classes.boxCircleText}>
                  <Typography
                    className={clsx([classes.fontJost, classes.boxCircleTitle])}
                    component="p"
                    variant="text_MD"
                    sx={{ mb: 2 }}
                  >
                    Download partner
                  </Typography>
                  <Button
                    variant="text"
                    onClick={downloadCSV}
                    disableRipple={true}
                    className={clsx([classes.btnNoPadding, classes.btnBlueText])}
                    endIcon={<DownloadLenders />}
                  >
                    Download partners
                  </Button>
                </Box>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={3.6}>
            <Box className={clsx([classes.boxCircleIconTextWrap, classes.justifyEnd])} justifyContent="flex-end">
              {false && (
                <Stack sx={{ mb: 0 }} direction="row" justifyContent="flex-end" alignItems="center" spacing={6}>
                  <Box className={classes.boxCircleIcon}>
                    <DownloadLenders />
                  </Box>
                  <Box className={classes.boxCircleText}>
                    <Typography
                      className={clsx([classes.fontJost, classes.boxCircleTitle])}
                      component="p"
                      variant="text_MD"
                      sx={{ mb: 2 }}
                    >
                      Download partner
                    </Typography>
                    <Button
                      variant="text"
                      disableRipple={true}
                      onClick={downloadCSV}
                      className={clsx([classes.btnNoPadding, classes.btnBlueText])}
                      endIcon={<DownloadLenders />}
                    >
                      Download
                    </Button>
                  </Box>
                </Stack>
              )}
            </Box>
          </Grid>
          {createOpen && (
            <CommonModal open={createOpen} title="Create a partner" onClose={toggleCreateOpen} icon={<DocumentPen />}>
              <CreatePartnerModal open={createOpen} toggleOpen={toggleCreateOpen} />
            </CommonModal>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default PartnerAdminActions;
