import { FC } from 'react';
import {
  TableFooter,
  TableRow,
  TablePagination,
  LabelDisplayedRowsArgs,
  Box,
  IconButton,
  Button,
} from '@material-ui/core';
import { MUIDataTableTextLabels } from 'mui-datatables';
import { TablePaginationActionsProps } from '@material-ui/core/TablePagination/TablePaginationActions';
import { ArrowLeft, ArrowRight } from '@material-ui/icons';
import times from 'lodash.times';
import { useTranslation } from 'react-i18next';

import clsx from 'clsx';
import useStyles, { useActionsStyles } from './PaginationFooter.styles';

const prevButtonsNumber = 2;
const nextButtonsNumber = 2;

const ActionsComponent: FC<TablePaginationActionsProps> = ({ count, page, onPageChange, rowsPerPage }) => {
  const classes = useActionsStyles();

  const currentPage = page + 1;
  const canNavToPrevPage = currentPage - 1 > 0;
  const canNavToNextPage = currentPage * rowsPerPage < count || count < 0;

  const renderPrevButtons = () => {
    return times(prevButtonsNumber, (index: number) => {
      const prevPage = currentPage - index - 1;
      if (prevPage > 0) {
        return (
          <Button
            key={`page-${prevPage}`}
            className={classes.pageButton}
            // minus one because page is actually zero based index
            onClick={(e) => onPageChange(e, prevPage - 1)}
          >
            {prevPage}
          </Button>
        );
      }
      return null;
    }).reverse();
  };

  const renderNextButtons = () => {
    return times(nextButtonsNumber, (index: number) => {
      const nextPage = currentPage + index + 1;
      if ((currentPage + index) * rowsPerPage < count || count < 0) {
        return (
          <Button
            key={`page-${nextPage}`}
            className={classes.pageButton}
            // minus one because page is actually zero based index
            onClick={(e) => onPageChange(e, nextPage - 1)}
          >
            {nextPage}
          </Button>
        );
      }
      return null;
    });
  };

  return (
    <Box className={classes.container}>
      <IconButton
        className={classes.iconButton}
        disabled={!canNavToPrevPage}
        onClick={(e) => onPageChange(e, page - 1)}
      >
        <ArrowLeft className={classes.icon} />
      </IconButton>

      {renderPrevButtons()}

      <Button className={clsx([classes.pageButton, classes.currentPage])}>{currentPage}</Button>

      {renderNextButtons()}

      <IconButton
        className={classes.iconButton}
        disabled={!canNavToNextPage}
        onClick={(e) => onPageChange(e, page + 1)}
      >
        <ArrowRight className={classes.icon} />
      </IconButton>
    </Box>
  );
};

interface PaginationFooterProps {
  rowCount: number;
  page: number;
  rowsPerPage: number;
  changeRowsPerPage: (page: string | number) => void;
  changePage: (newPage: number) => void;
  textLabels?: Partial<MUIDataTableTextLabels>;
}

const PaginationFooter: FC<PaginationFooterProps> = ({
  rowCount,
  page,
  rowsPerPage,
  changeRowsPerPage,
  changePage,
  // eslint-disable-next-line  @typescript-eslint/no-unused-vars
  textLabels,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  // eslint-disable-next-line
  const labelDisplayedRows = (args: LabelDisplayedRowsArgs) => {
    const { from, to, count } = args;
    if (count < 0) return <span>{`${t('pages.dashboard.table.pagination.viewing')} ${from} - ${to}`}</span>;
    return <span>{`${t('pages.dashboard.table.pagination.viewing')} ${from} - ${to} of ${count}`}</span>;
  };

  return (
    <TableFooter>
      <TableRow>
        <TablePagination
          count={rowCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(_, pageNumber) => changePage(pageNumber)}
          onRowsPerPageChange={(event) => changeRowsPerPage(event.target.value)}
          rowsPerPageOptions={[]}
          labelDisplayedRows={labelDisplayedRows}
          labelRowsPerPage={null}
          classes={classes}
          ActionsComponent={ActionsComponent}
        />
      </TableRow>
    </TableFooter>
  );
};

export default PaginationFooter;
