import { FC, useState, useCallback, useEffect } from 'react';
import { Box, Container, FormHelperText, Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';

import CustomButton from 'components/CustomButton/Button';
import { AlertMessage } from 'components/alerts';
import { AlertMessageStatus, SettingName } from 'core/types';
import {
  createSetting as createSettingApi,
  getSettingByName as getSettingByNameApi,
  updateSetting as updateSettingApi,
} from 'http/admin';
import { useAdmin } from 'store/admin/hooks';
import { useGlobalState } from 'store/global/hooks';
import useStyles from './Faqs.styles';
import { formats, modules } from './constants';

const Faqs: FC = () => {
  const [editMode, setEditMode] = useState(false);
  const [editorError, setEditorError] = useState<string | boolean>(false);
  const [editorContent, setEditorContent] = useState('');
  const [loading, setLoading] = useState(false);
  const [savedFaqs, setSavedFaqs] = useState<{ content: string; id?: string; faqName?: SettingName.FAQS }>({
    content: '',
  });
  const classes = useStyles();
  const { t } = useTranslation();
  const { error: globalError } = useGlobalState();
  const { error, success, setError, setSuccess } = useAdmin();

  const getFaqs = useCallback(async () => {
    setLoading(true);
    try {
      const setting = await getSettingByNameApi(SettingName.FAQS);

      if (setting) {
        setSavedFaqs({ id: setting.id, faqName: setting.name, content: setting.data.content });
      }
    } catch (err) {
      setError((err as Error).message ?? true);
    }
    setLoading(false);
  }, [setError]);

  useEffect(() => {
    getFaqs();
  }, [getFaqs]);

  const toggleEditMode = () => {
    if (error) setEditorError(false);
    setEditMode((prevState) => !prevState);
  };

  const onSave = async () => {
    if (editorContent.length < 10) {
      setEditorError(true);
      return;
    }

    setEditorError(false);
    setLoading(true);

    try {
      const content = editorContent.replaceAll('<p><br></p>', '<br />');

      if (savedFaqs.id) {
        await updateSettingApi(savedFaqs.id, { content });
        setSavedFaqs((prevState) => ({ ...prevState, content }));
      } else {
        const savedSetting = await createSettingApi(SettingName.FAQS, { content });
        setSavedFaqs({ id: savedSetting.id, faqName: savedSetting.name, content: savedSetting.data.content });
      }

      toggleEditMode();
      setEditorContent('');
      setSuccess(t('pages.faqs.messages.success') as string);
    } catch (err) {
      setError((err as Error).message ?? true);
    }

    setLoading(false);
  };

  return (
    <Container disableGutters maxWidth="xl">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography className={classes.title}>{t('pages.faqs.title')}</Typography>
        </Grid>

        {editMode ? (
          <Grid item xs={12}>
            <ReactQuill
              theme="snow"
              value={editorContent === '' ? savedFaqs.content : editorContent}
              onChange={setEditorContent}
              modules={modules}
              formats={formats}
              readOnly={false}
              preserveWhitespace
              className={clsx([classes.editor, editorError && classes.editorError])}
            />

            {editorError && <FormHelperText error>{t('pages.faqs.messages.error')}</FormHelperText>}
          </Grid>
        ) : (
          <Grid item xs={12}>
            {/* eslint-disable-next-line react/no-danger */}
            <div dangerouslySetInnerHTML={{ __html: savedFaqs.content }} className={classes.faqsContent} />
          </Grid>
        )}

        <Grid item xs={12} className={editMode ? classes.displayGap10 : ''}>
          <Box className={classes.width144}>
            {editMode && (
              <CustomButton onClick={toggleEditMode} className="white">
                {t('global.buttons.cancel')}
              </CustomButton>
            )}
          </Box>

          <Box className={classes.width144}>
            <CustomButton loading={loading} onClick={editMode ? onSave : toggleEditMode}>
              {t(`pages.faqs.buttons.${editMode ? 'save' : 'edit'}`)}
            </CustomButton>
          </Box>
        </Grid>
      </Grid>

      {!globalError && (
        <>
          <AlertMessage
            open={!!error}
            onClose={() => setError(false)}
            message={typeof error === 'string' ? error : undefined}
            autoHideDuration={5000}
          />

          <AlertMessage
            open={!!success}
            onClose={() => setSuccess(false)}
            message={typeof success === 'string' ? success : undefined}
            autoHideDuration={5000}
            status={AlertMessageStatus.SUCCESS}
          />
        </>
      )}
    </Container>
  );
};

export default Faqs;
