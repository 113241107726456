import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useTabsStyles = makeStyles({
  root: {
    backgroundColor: 'transparent',
    minHeight: 'auto',
    flexGrow: 1,
    width: '100%',
  },
});

export default makeStyles((theme: Theme) => ({
  dataContainer: {
    marginTop: theme.spacing(1),
  },
  tooltipContainer: {
    border: `1px solid ${theme.palette.greyLightBorder.main}`,
    padding: theme.spacing(1),
    '& > p': {
      textTransform: 'capitalize',
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
    },
  },
}));
