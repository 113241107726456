import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

// const production = (process.env.REACT_APP_STAGE as string) === 'production';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    minHeight: '100vh',
    overflowX: 'hidden',
  },
  contentContainer: {
    marginLeft: '272px',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  testmodeContainer: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    margin: '10px 20px',
  },
  dashboardContent: {
    // flex: 1,
    // display: 'flex',
    // flexWrap: 'wrap',
    // justifyContent: 'center',
    maxWidth: '100vw',
    // padding: theme.spacing(2),

    [theme.breakpoints.up('lg')]: {
      // paddingLeft: theme.spacing(4),
      // marginTop: !production ? theme.spacing(5) : theme.spacing(0),
    },
    [theme.breakpoints.down('xs')]: {
      // padding: theme.spacing(1),
    },
  },
}));

export default useStyles;
