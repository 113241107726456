import { FC } from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { AlertMessage } from 'components/alerts';
import { useGlobalState } from 'store/global/hooks';
import { usePartners } from 'store/partners/hooks';
import { AlertMessageStatus } from 'core/types';
import { Loader } from 'components/Loader';
import { TrustedDomains } from 'components/TrustedDomains';
import useStyles from './Security.styles';

const Security: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { error: globalError } = useGlobalState();
  const { error, setError, success, setSuccess, loading } = usePartners();

  return (
    <Container disableGutters maxWidth="xl">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography className={classes.title} gutterBottom>
            {t('pages.security.title')}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {t('pages.security.subtitle')}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TrustedDomains />
        </Grid>
      </Grid>

      {!globalError && (
        <>
          <AlertMessage
            open={!!error}
            onClose={() => setError(false)}
            message={typeof error === 'string' ? error : undefined}
            autoHideDuration={5000}
          />

          <AlertMessage
            open={!!success}
            onClose={() => setSuccess(false)}
            message={typeof success === 'string' ? success : undefined}
            autoHideDuration={5000}
            status={AlertMessageStatus.SUCCESS}
          />
        </>
      )}

      <Loader visible={loading} />
    </Container>
  );
};

export default Security;
