import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  leadActionButton: {
    marginLeft: theme.spacing(2),
  },
  cmn_btn_icon: {
    marginLeft: '8px',
  },
  cmn_btn: {
    fontWeight: `400 !important`,
    textTransform: `capitalize`,
  },
}));
