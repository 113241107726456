import { useCallback, useEffect } from 'react';
import { WIDGET_ELEMENT_ID } from 'core/constants';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let bsWidget: any;

interface WidgetReturnHook {
  openWidget: (publicKey: string, accessToken: string, onClose?: () => void) => void;
}

const useWidget = (): WidgetReturnHook => {
  useEffect(() => {
    return () => bsWidget?.hide(false);
  }, []);

  const openWidget = useCallback((publicKey: string, accessToken: string, onClose?: () => void) => {
    // @ts-expect-error - this is going to be available if widget bundle has been successfully loaded
    const bsWidgetInstance = window.BusinessScoreWidget;

    if (!bsWidgetInstance) return;

    if (bsWidget) {
      bsWidget.update({
        publicKey,
        accessToken,
        onClose,
      });
    } else {
      bsWidget = bsWidgetInstance({
        elementId: WIDGET_ELEMENT_ID,
        publicKey,
        height: window.innerHeight,
        width: '100vw',
        popup: true,
        kycEnabled: false,
        captchaEnabled: false,
        accessToken,
        onClose,
      });
    }

    bsWidget.show();
  }, []);

  return { openWidget };
};

export default useWidget;
