import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),

    '& .MuiGrid-item': {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
  text: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20),
    color: theme.palette.greyText.main,
    paddingBottom: 20,
  },
  textInput: {
    minWidth: '100%',

    '& .MuiInputBase-root': {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      fontWeight: 700,
      height: 36,
    },

    '& .Mui-disabled': {
      backgroundColor: theme.palette.grey[200],
      color: theme.palette.common.black,
    },

    '& .Mui-error': {
      color: theme.palette.error.main,
      marginLeft: 0,
      marginRight: 0,
    },

    [theme.breakpoints.down('xs')]: {
      minWidth: 'auto',
    },
  },
  totalContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(6),
  },
  totalText: {
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(20),
    color: theme.palette.greyText.main,
  },
  totalValue: {
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(20),
    fontWeight: 700,
  },
  textAreaError: {
    borderColor: `${theme.palette.error.main} !important`,
  },
  inputLabel: {
    '& p': {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      color: theme.palette.common.black,
    },
    '& > .MuiButtonBase-root': {
      marginTop: -theme.spacing(2),
    },
  },
  dividerContainer: {
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(1),

    '& svg': {
      width: '100%',
    },
  },
  addRateContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(-2),
    marginTop: theme.spacing(1),
  },
  rateNumberTitle: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
    fontWeight: 700,
    width: '100%',

    color: theme.palette.primary.main,
  },
  deleteRateButton: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(18),
    fontWeight: 400,
    marginTop: -6,
    minHeight: 24,
    width: 61,
    height: 24,
    padding: 0,
    borderRadius: 6,
  },
  addRateButton: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  rejectionReasonsContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& > *': {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
      marginRight: theme.spacing(1.5),
    },
    '& .MuiFormHelperText-root.Mui-error': {
      marginTop: theme.spacing(1),
    },
  },
  rejectionReasonOption: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
  },
  declineResonLabel: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20),
    color: theme.palette.greyText.main,
  },
  filter_cardBody: {
    padding: '0px 16px 24px 16px',
  },
  filter_cardTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px',

    '& h3': {
      fontWeight: '500',
    },
  },
  closeFilterIcon: {
    fontSize: '24px',
  },
  closeFilterIconEnd: {
    fontSize: '24px',
    width: '20px',
    height: '20px',
    marginLeft: 'auto !important',
    minWidth: '24px !important',
    minHeight: '24px !important',
    color: `${theme.palette.Gray[700]} !important`,
    '& .MuiSvgIcon-root': {
      marginRight: '0 !important',
    },
  },
  ul_style: {
    padding: '0px',
    listStyle: 'none',
    columnCount: 2,
  },
  modalIconMargin: {
    margin: '0 auto 1rem',
  },
  modalTitle: {
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(27),
    color: `${theme.palette.Gray[700]} !important`,
    fontWeight: 700,
    display: 'block',
    maxWidth: '432px',
    margin: '0 auto 20px !important',
    textAlign: 'center',
  },
  modalIconBorder: {
    width: '3.5rem',
    height: '3.5rem',
    backgroundColor: theme.palette.Blue[100],
    border: `10px solid ${theme.palette.Blue[50]}`,
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.Blue[600],
  },
  filedTitle: {
    color: `${theme.palette.Gray[500]} !important`,
    fontWeight: '500 !important',
    fontFamily: 'Jost, sans-serif !important',
  },
  modalTextAreBox: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 0,
    width: '100%',
  },
  filedSpaceBottom: {
    marginBottom: '0.5rem !important',
  },
  modalTextArea: {
    borderRadius: '0.5rem',
    borderColor: `${theme.palette.Gray[300]} !important`,
  },
  modalFooter: {
    marginTop: theme.spacing(8),
  },
  formLblValue: {
    marginBottom: '1.5rem !important',
  },
  fontWeight_500: {
    fontWeight: '500!important',
  },
  formInlineLblValue: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  formInlineLblValue_lbl: {
    position: 'relative',
  },
  fontJost: {
    fontFamily: 'jost !important',
  },
  gray_500: {
    color: `${theme.palette.Gray[500]} !important`,
  },
  inputLbl: {
    lineHeight: '1.75rem !important',
    marginBottom: '0.5rem !important',
    display: 'block !important',
  },
  formInlineLblValue_value: {
    position: 'relative',
    width: '100%',
  },
  datePicker_wrap: {
    width: 'auto !important',
  },
  formLblValueGroup: {
    borderTop: `1px solid ${theme.palette.Gray[300]}`,
  },
  home_datePicker: {
    '&:before': {
      display: 'none !important',
    },
    '& input': {
      width: '150px !important',
      textAlign: 'left !important',
    },
  },
  LblValueSpace: {
    margin: '2rem 0rem !important',
    display: 'flex',
    justifyContent: 'space-between',
  },
  fontWeight_700: {
    fontWeight: '700 !important',
  },
  formWidthFull: {
    width: '100%',
  },
  black_500: {
    color: '#000000',
  },
  inputIconLeft: {
    width: '24px',
    height: '44px',
    display: 'flex',
    alignItems: 'center',
    borderRight: `1px solid #8C92AC`,
  },
  customInputHight: {
    '& .MuiInputBase-input': {
      padding: '12px 12px !important',
    },
  },
  addRate: {
    color: `${theme?.palette?.Blue?.[600]} !important`,
    boxShadow: 'none !important',
    '&:active': {
      outline: 'none',
      scale: '1 !important',
    },
    '&:hover': {
      outline: 'none',
      boxShadow: 'none !important',
      backgroundColor: 'transparent !important',
    },
    '&.Mui-selected': {
      outline: 'none',
    },
  },
}));
