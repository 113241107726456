import { FC } from 'react';
import { Box, Typography, Tooltip, IconButton } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import * as CSS from 'csstype';
import clsx from 'clsx';

import useStyles, { useTooltipStyles } from './CustomLabel.styles';

interface CustomLabelProps {
  title: string;
  containerStyle?: CSS.Properties;
  labelStyle?: CSS.Properties;
  labelClassName?: string;
  tooltipMessage?: string;
}

const CustomLabel: FC<CustomLabelProps> = ({
  title,
  labelClassName,
  tooltipMessage,
  containerStyle = {},
  labelStyle = {},
}) => {
  const classes = useStyles();
  const tooltipClasses = useTooltipStyles();

  return (
    <Box className={classes.container} style={containerStyle}>
      <Typography className={clsx([classes.inputLabel, labelClassName])} style={labelStyle}>
        {title}
      </Typography>

      {tooltipMessage && (
        <Tooltip
          title={tooltipMessage}
          arrow
          classes={tooltipClasses}
          enterTouchDelay={0}
          leaveDelay={3000}
          style={{ marginLeft: 2 }}
        >
          <IconButton aria-label="tooltip-icon" size="small">
            <InfoOutlined fontSize="small" className={classes.tooltipIcon} />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};

export default CustomLabel;
