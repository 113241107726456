import { createAction, createAsyncThunk, Dispatch } from '@reduxjs/toolkit';

import { getReminders as getRemindersApi } from 'http/reminders';
import { replaceAtIndex } from 'utils';
import { Reminder, RemindersListResponse, RemindersQuery, RemindersState } from './types';

export const SET_ERROR = 'reminders/SET_ERROR';
export const SET_SUCCESS = 'reminders/SET_SUCCESS';
export const GET_REMINDERS = 'reminders/GET_REMINDERS';
export const UPDATE_REMINDERS = 'reminders/UPDATE_REMINDERS';

export const setError = createAction<string | boolean>(SET_ERROR);

export const setSuccess = createAction<string | boolean>(SET_SUCCESS);

export const getReminders = createAsyncThunk<RemindersListResponse, RemindersQuery>(GET_REMINDERS, async (query) => {
  const data = await getRemindersApi(query);
  return data;
});

export const updateReminders = createAction<Reminder[]>(UPDATE_REMINDERS);

export const updateReminderAction =
  (payload: { id: string; done: boolean }) =>
  (dispatch: Dispatch, getState: () => { reminders: RemindersState }): void => {
    const {
      reminders: { reminders },
    } = getState();

    const reminderIndex = reminders.findIndex((reminder) => reminder.id === payload.id);
    if (reminderIndex < 0) return;

    const updatedReminder = { ...reminders[reminderIndex], done: payload.done };
    const updatedReminders = replaceAtIndex(reminders, reminderIndex, [updatedReminder]);

    dispatch(updateReminders(updatedReminders));
  };
