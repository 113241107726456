import { FC, useMemo, useEffect } from 'react';
import memoize from 'lodash.memoize';
import debounce from 'lodash.debounce';
import Papa from 'papaparse';
import * as yup from 'yup';
import i18n from 'i18next';
import { useFormikContext } from 'formik';
import { checkForActiveApplication as checkForActiveApplicationApi } from 'http/applications';
import { ApplicationRegion } from 'store/applications/types';
import CustomNumberFormat from 'components/inputs/CustomNumberField/CustomNumberField';
import moment from 'moment';

import { ReactComponent as PDFIcon } from '../../../../assets/icons/pdf-icon.svg';
import { ReactComponent as PngIcon } from '../../../../assets/icons/png-icon.svg';
import { ReactComponent as JpgIcon } from '../../../../assets/icons/jpg-icon.svg';
import { ReactComponent as DocIcon } from '../../../../assets/icons/docx-file-icon.svg';
import { ReactComponent as XlsIcon } from '../../../../assets/icons/icons8-excel.svg';

export const SEARCH_DEBOUNCE_DELAY = 500; // miliseconds

export const VALIDATION_DEBOUNCE_DELAY = 500; // miliseconds

export const USA_PHONE_NUMBER_REGEX = /^(\d{3})-(\d{3})-(\d{4})$/;

export const IRL_PHONE_NUMBER_REGEX = /^(\d{3})-(\d{3})-(\d{2,4})$/;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PhoneNumberInput: FC = (props: any) => (
  <CustomNumberFormat {...props} thousandSeparator={false} format="###-###-####" type="tel" useFormattedValue />
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const EINNumberInput: FC = (props: any) => (
  <CustomNumberFormat {...props} thousandSeparator={false} format="##-#######" type="tel" useFormattedValue />
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const memoizeResolver = (...args: any[]) => {
  return `${args[0]}-${args[1]?.parent?.partner_id}`;
};

export const validateCompanyNumber = memoize(async (value: string | undefined, ctx: yup.TestContext) => {
  const partnerId = ctx.parent?.partner_id;
  if (!value || !partnerId) return true;
  try {
    await checkForActiveApplicationApi({ registered_number: value, partner_id: partnerId });
    return true;
  } catch (error) {
    return false;
  }
}, memoizeResolver);

export const formSchema = yup.object({
  partner_id: yup.string().required(i18n.t('pages.dashboard.addAdminActions.inputs.partner_id.required')),
  email: yup
    .string()
    .email(i18n.t('pages.dashboard.addAdminActions.inputs.email.error'))
    .required(i18n.t('pages.dashboard.addAdminActions.inputs.email.required')),
  registered_name: yup.string().required(i18n.t('pages.dashboard.addAdminActions.inputs.registered_name.required')),
  director_id: yup
    .string()
    .when('region', (region: ApplicationRegion, schema: yup.StringSchema) =>
      region === ApplicationRegion.UK
        ? schema.required(i18n.t('pages.dashboard.addAdminActions.inputs.director.required'))
        : schema.notRequired(),
    ),
  first_name: yup.string().required(i18n.t('pages.dashboard.addAdminActions.inputs.first_name.required')),
  last_name: yup.string().required(i18n.t('pages.dashboard.addAdminActions.inputs.last_name.required')),
  formation_date: yup.string().required(i18n.t('pages.dashboard.addAdminActions.inputs.formation_date.required')),
  registered_number: yup
    .string()
    .when('region', (region: ApplicationRegion, schema: yup.StringSchema) =>
      region !== ApplicationRegion.USA
        ? schema
            .required(i18n.t('pages.dashboard.addAdminActions.inputs.registered_number.required'))
            .test(
              'isCompanyAvailable',
              i18n.t('pages.dashboard.addAdminActions.inputs.registered_number.error'),
              validateCompanyNumber,
            )
        : schema.notRequired(),
    ),
  company_status: yup
    .string()
    .equals(['active'], i18n.t('pages.dashboard.addAdminActions.inputs.company_status.error')),
  region: yup
    .string()
    .oneOf(Object.values(ApplicationRegion))
    .required(i18n.t('pages.dashboard.addAdminActions.inputs.region.required')),
  phone_number: yup
    .string()
    .when('region', (region: ApplicationRegion, schema: yup.StringSchema) =>
      region === ApplicationRegion.UK
        ? schema.notRequired()
        : schema
            .matches(
              region === ApplicationRegion.USA ? USA_PHONE_NUMBER_REGEX : IRL_PHONE_NUMBER_REGEX,
              i18n.t('pages.dashboard.addAdminActions.inputs.phone_number.error'),
            )
            .required(i18n.t('pages.dashboard.addAdminActions.inputs.phone_number.required')),
    ),
  ein: yup
    .string()
    .when('region', (region: ApplicationRegion, schema: yup.StringSchema) =>
      region !== ApplicationRegion.USA
        ? schema.notRequired()
        : schema
            .min(9, i18n.t('pages.dashboard.addAdminActions.inputs.ein.error'))
            .required(i18n.t('pages.dashboard.addAdminActions.inputs.ein.required')),
    ),
});

export const FormValidation: FC = () => {
  const { values, validateForm } = useFormikContext();

  const debouncedValidate = useMemo(() => debounce(validateForm, VALIDATION_DEBOUNCE_DELAY), [validateForm]);

  useEffect(() => {
    debouncedValidate();
  }, [values, debouncedValidate]);

  return null;
};

export const firstLatterCapital = (data: string) => {
  return `${data?.charAt(0)?.toLocaleUpperCase()}${data?.split('')?.slice(1)?.join('')}`;
};

export const isValidArray = (data: object[]): boolean => data && Array.isArray(data) && data.length > 0;

export const isValidObject = (data: object) =>
  typeof data === 'object' && data ? Object.keys(data).length > 0 : false;

export const getParamsValue = (data: Record<string, string | number>): Record<string, string | number> => {
  const result: Record<string, string | number> = {};
  Object.keys(data).map((key) => {
    if (!!data[key] || key === 'page') {
      result[key] = data[key];
    }
    return key;
  });
  return result;
};

export const getFileTypeIcon = (file: File) => {
  const fileName: string = file?.name?.toLowerCase();
  const fileExtension = fileName?.split('.')?.pop();
  if (fileExtension === 'pdf') {
    return <PDFIcon />;
  }
  if (fileExtension === 'doc') {
    return <DocIcon />;
  }
  if (fileExtension === 'xls') {
    return <XlsIcon />;
  }
  if (fileExtension === 'png') {
    return <PngIcon />;
  }
  if (fileExtension === 'jpg' || fileExtension === 'jpeg') {
    return <JpgIcon />;
  }
  return <PngIcon />;
};

export const checkDateInCurrentMonth = (date: Date | string) => {
  const curr = new Date();
  const startDate = new Date(new Date(curr.getFullYear(), curr.getMonth(), 1).setHours(0, 0, 0, 0));
  const endDate = new Date(new Date(curr.getFullYear(), curr.getMonth() + 1, 0).setHours(23, 59, 59, 0));

  return (
    new Date(new Date(date).setFullYear(new Date().getFullYear())).getTime() <= endDate.getTime() &&
    new Date(new Date(date).setFullYear(new Date().getFullYear())).getTime() >= startDate.getTime()
  );
};

export const checkDateInLastMonth = (date: Date | string) => {
  const curr = new Date();
  const startDate = new Date(new Date(curr.getFullYear(), curr.getMonth() - 1, 1).setHours(0, 0, 0, 0));
  const endDate = new Date(new Date(curr.getFullYear(), curr.getMonth(), 0).setHours(23, 59, 59, 0));

  return (
    new Date(new Date(date).setFullYear(new Date().getFullYear())).getTime() <= endDate.getTime() &&
    new Date(new Date(date).setFullYear(new Date().getFullYear())).getTime() >= startDate.getTime()
  );
};

export const countLastMonthReturn = (data: { month: string; count: string }[]) => {
  const currentMonth = data?.find((d) => checkDateInCurrentMonth(d?.month));
  const lastMonth = data?.find((d) => checkDateInLastMonth(d?.month));
  let percentage = 0;
  percentage = Number(currentMonth?.count) - Number(lastMonth?.count);
  percentage = (percentage * 100) / Number(currentMonth?.count);

  return { percentage: percentage.toFixed(0) || 0, total: Number(currentMonth?.count) || 0 };
};

export const sortData = (data: { month: string; count: string }[], type: string) => {
  return type === 'count'
    ? data?.sort((a, b) => new Date(a.month).getTime() - new Date(b.month).getTime())?.map((d) => +d?.count)
    : data
        ?.sort((a, b) => new Date(a.month).getTime() - new Date(b.month).getTime())
        ?.map((d) => moment(d?.month).format('DD/MM/YYYY'));
};

export const downloadCSVFile = (data: object[], fileName: string) => {
  if (data?.length > 0) {
    const csvData = Papa.unparse(data);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }
};

export const convertSelectBoxOptionData = (data: object[], labelKey: string, valueKey: string) => {
  return data?.map((e) => ({ label: e[labelKey as keyof object], value: e[valueKey as keyof object] }));
};

export const checkDataIsExit = (data: object) => {
  let isExitValue = false;
  Object.values(data).forEach((d) => {
    if ((d as string[])?.length > 0) {
      isExitValue = true;
    }
  });
  return isExitValue;
};

export const statueReplaceBooleanData = (data: string[]) => {
  return data?.map((value) => {
    return value === 'Yes' || value === 'DONE';
  });
};

export const enumDataFirstLetterCapital = (enumData: { [key: string]: string }) => {
  return Object.keys(enumData)?.map((data) => {
    const value = data?.split('_');
    if (value?.length === 1) {
      const d = value[0]?.split('');
      return d[0]?.toUpperCase() + d?.slice(1)?.join('')?.toLocaleLowerCase();
    }
    const e = value[0]?.split('');
    return `${e[0]?.toUpperCase()}${e?.slice(1).join('').toLocaleLowerCase()} ${value
      ?.slice(1)
      ?.join(' ')
      ?.toLocaleLowerCase()}`;
  });
};

export const findDataFromObject = (enumData: object | { [key: string]: string }, data: string[]): string[] => {
  const newData: string[] = [];
  data.forEach((e) => {
    const value = e?.split(' ')?.join('_')?.toLocaleUpperCase();
    if (Object.values(enumData)?.findIndex((d) => d === value) !== -1) {
      newData.push(Object.keys(enumData)[Object.values(enumData)?.findIndex((d) => d === value)]);
    }
  });
  return newData;
};
