import { ActionReducerMapBuilder, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { getAutSession } from 'utils';
import { AuthState } from './types';
import {
  initAuth,
  confirmAuth,
  logout,
  setError,
  register,
  confirmPasswordReset,
  initPasswordReset,
  getCurrentAuthenticatedUser,
  setSuccess,
  registerAccount,
} from './actions';

const getInitialState = (): AuthState => {
  const initialAuthSession = getAutSession();
  return {
    loading: false,
    error: false,
    success: false,
    accessToken: initialAuthSession?.accessToken ?? null,
    role: initialAuthSession?.role ?? null,
    uid: initialAuthSession?.uid ?? null,
    firstName: initialAuthSession?.firstName ?? null,
    lastName: initialAuthSession?.lastName ?? null,
    email: initialAuthSession?.email ?? null,
    occupation: initialAuthSession?.occupation ?? null,
    rememberMe: initialAuthSession?.rememberMe ?? false,
  };
};

const authStore = createSlice({
  name: 'auth',
  initialState: getInitialState(),
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<AuthState>) => {
    builder.addCase(initAuth.fulfilled, (state, { payload }) => {
      state.error = false;
      state.loading = false;
      state.email = payload.email;
      state.rememberMe = payload.rememberMe;
    });

    builder.addCase(confirmAuth.fulfilled, (state, { payload }) => {
      state.error = false;
      state.loading = false;
      if (payload) {
        state.accessToken = payload.accessToken;
        state.role = payload.role;
        state.uid = payload.uid;
        state.email = payload.email;
        state.firstName = payload.firstName ?? null;
        state.lastName = payload.lastName ?? null;
        state.occupation = payload.occupation ?? null;
      }
    });

    builder.addCase(logout, () => getInitialState());

    builder.addCase(setError, (state, { payload }) => {
      state.error = payload;
    });

    // Note: If register succeeded, login action follows, no need for this, left for explanation purposes
    // builder.addCase(register.fulfilled, (state) => {
    //   state.error = false;
    //   state.loading = false;
    // });

    builder.addCase(getCurrentAuthenticatedUser.fulfilled, (state, { payload }) => {
      if (payload) {
        state.firstName = payload.first_name;
        state.lastName = payload.last_name;
        state.occupation = payload.occupation ?? null;
      }
    });

    builder.addCase(setSuccess, (state, { payload }) => {
      state.success = payload;
    });

    builder.addMatcher(
      isAnyOf(confirmPasswordReset.fulfilled, initPasswordReset.fulfilled, registerAccount.fulfilled),
      (state) => {
        state.loading = false;
      },
    );

    builder.addMatcher(
      isAnyOf(
        initAuth.pending,
        confirmAuth.pending,
        register.pending,
        confirmPasswordReset.pending,
        initPasswordReset.pending,
        getCurrentAuthenticatedUser.pending,
        registerAccount.pending,
      ),
      (state) => {
        state.loading = true;
        state.error = false;
      },
    );

    builder.addMatcher(
      isAnyOf(
        initAuth.rejected,
        confirmAuth.rejected,
        register.rejected,
        confirmPasswordReset.rejected,
        initPasswordReset.rejected,
        getCurrentAuthenticatedUser.rejected,
        registerAccount.rejected,
      ),
      (state, { error }) => {
        state.loading = false;
        state.error = error.message ? error.message : true;
      },
    );
  },
});

export default authStore.reducer;
