import React from 'react';
import NumberFormat from 'react-number-format';

interface CustomNumberFormatProps {
  inputRef: (instance: NumberFormat<number> | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  prefix?: string;
  decimalScale?: number;
  fixedDecimalScale?: boolean;
  useFormattedValue?: boolean;
  thousandSeparator?: boolean;
}

interface Values {
  value: string;
  formattedValue: string;
}

const CustomNumberFormat: React.FC<CustomNumberFormatProps> = ({
  name,
  inputRef,
  onChange,
  useFormattedValue,
  ...other
}) => {
  const onValueChange = (values: Values) => {
    const value = useFormattedValue ? values.formattedValue : values.value;
    onChange({ target: { name, value } });
  };

  return (
    <NumberFormat
      getInputRef={inputRef}
      displayType="input"
      onValueChange={onValueChange}
      thousandSeparator
      isNumericString={false}
      {...other}
    />
  );
};

export default CustomNumberFormat;
