import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  actionLeadContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  actionSubtitle: {
    fontSize: theme.typography.pxToRem(17),
    lineHeight: theme.typography.pxToRem(20),
    fontWeight: 700,
  },
  leadActionButton: {
    marginLeft: theme.spacing(2),
  },
  appIncompletedText: {
    fontSize: theme.typography.pxToRem(11),
    lineHeight: theme.typography.pxToRem(20),
    marginBottom: theme.spacing(1.5),
    fontWeight: 700,
    color: theme.palette.info.main,
    textAlign: 'right',
    '& svg': {
      fontSize: theme.typography.pxToRem(14),
      marginBottom: -3,
      marginRight: 6,
      color: theme.palette.info.main,
    },
  },
}));
