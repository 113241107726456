import { FC, useEffect, useState } from 'react';
import { OpenInNew } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

import { useWidget } from 'hooks';
import { applicationAuth as applicationAuthApi } from 'http/applications';
import routes from 'router/routes';
import { useAdmin } from 'store/admin/hooks';
import { useApplications } from 'store/applications/hooks';
import { useAuth } from 'store/auth/hooks';

import { Button } from '@mui/material';
import useStyles from './WidgetMode.styles';

const WidgetMode: FC = () => {
  const [loading, setLoading] = useState(false);
  // const leadClasses = useLeadStyles();
  const classes = useStyles();
  const { applicationDetails, getApplicationDetails } = useApplications();
  const { setError, setSuccess } = useAdmin();
  const { isAdmin, isCompanyOwner } = useAuth();
  const { openWidget } = useWidget();
  const history = useHistory();

  const onClose = () => {
    if (isCompanyOwner) history.push(routes.dashboard);

    if (applicationDetails?.id) getApplicationDetails(applicationDetails.id);
  };

  const openWidgetAsAdmin = async () => {
    const partner = applicationDetails?.partner;
    const applicant = applicationDetails?.company.people.find((p) => p.applicant);

    if (!applicationDetails || !partner?.api_keys.public_key || !applicant) return;

    setLoading(true);
    try {
      const accessToken = await applicationAuthApi(partner.id, applicationDetails?.company.id, applicant.email);
      if (accessToken) {
        setSuccess('User has been successfully authenticated');
      }

      openWidget(partner.api_keys.public_key, accessToken, onClose);
    } catch (error) {
      setError((error as Error).message ?? true);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (isCompanyOwner) {
      openWidgetAsAdmin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Button
      variant="contained"
      disabled={loading}
      onClick={openWidgetAsAdmin}
      sx={{ width: 1, mt: 4 }}
      className={classes.cmn_btn}
    >
      {isAdmin ? 'Launch' : 'Edit'} application
      <OpenInNew className={classes.cmn_btn_icon} />
    </Button>
  );
};

export default WidgetMode;
