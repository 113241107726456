/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useMemo, useEffect, useState, useRef } from 'react';
import { Grid, Paper, Link } from '@material-ui/core';
import { Formik, Form, Field, FormikProps } from 'formik';
import * as yup from 'yup';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { strongPasswordRegexExp } from 'core/constants';
import routes from 'router/routes';
import { CustomTextField } from 'components/inputs';
import { useAuth } from 'store/auth/hooks';
import { LoadingButton } from 'components/buttons';
import { AuthPageContainer } from 'components/AuthPageContainer';
import useStyles from './ResetPassword.styles';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

interface FormValues {
  password: string;
  confirm_password: string;
}

const ResetPassword: FC<RouteComponentProps> = ({ history }) => {
  const [token, setToken] = useState<string | null>(null);
  const formRef = useRef<FormikProps<FormValues> | null>(null);
  const classes = useStyles();
  const { t } = useTranslation();
  const { loading, error, success, setError, confirmPasswordReset, setSuccess } = useAuth(() => {
    formRef.current?.resetForm();
  });
  const query = useQuery();

  useEffect(() => {
    setToken(query.get('token'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loginSchema = useMemo(
    () =>
      yup.object({
        password: yup
          .string()
          .required(t('pages.resetPassword.inputs.password.required'))
          .matches(strongPasswordRegexExp, t('pages.resetPassword.inputs.password.error')),
        confirm_password: yup
          .string()
          .required(t('pages.resetPassword.inputs.confirm_password.required'))
          .oneOf([yup.ref('password'), null], t('pages.resetPassword.inputs.confirm_password.error')),
      }),
    [t],
  );

  const initialValues = {
    password: '',
    confirm_password: '',
  };

  const onSubmit = (values: FormValues) => {
    confirmPasswordReset({ token: token || '', password: values.password });
  };

  const goToLogin = () => history.push(routes.login);

  const clearSuccess = () => setSuccess(false);

  const clearError = () => setError(false);

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={loginSchema} innerRef={formRef}>
      {({ handleChange, values, handleSubmit }) => (
        <AuthPageContainer error={error} clearError={clearError} success={success} clearSuccess={clearSuccess}>
          <Paper elevation={2} className={classes.formContainer}>
            <Form noValidate>
              <Grid container direction="column" spacing={4}>
                <Grid item>
                  <Field
                    autoFocus
                    id="password"
                    fullWidth
                    component={CustomTextField}
                    name="password"
                    type="password"
                    value={values.password}
                    onChange={handleChange}
                    title={t('pages.resetPassword.inputs.password.label')}
                    placeholder={t('pages.resetPassword.inputs.password.placeholder')}
                    className={classes.textInput}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>

                <Grid item>
                  <Field
                    id="confirm_password"
                    fullWidth
                    component={CustomTextField}
                    name="confirm_password"
                    type="password"
                    value={values.confirm_password}
                    onChange={handleChange}
                    title={t('pages.resetPassword.inputs.confirm_password.label')}
                    placeholder={t('pages.resetPassword.inputs.confirm_password.placeholder')}
                    className={classes.textInput}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>

                <Grid item className={classes.actionContainer}>
                  <LoadingButton
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => handleSubmit()}
                    loading={loading}
                    type="submit"
                  >
                    {t('pages.resetPassword.buttons.submit')}
                  </LoadingButton>
                </Grid>

                <Grid item className={classes.linksContainer}>
                  <Link href="" className={classes.link} onClick={goToLogin}>
                    {t('pages.resetPassword.links.login')}
                  </Link>
                </Grid>
              </Grid>
            </Form>
          </Paper>
        </AuthPageContainer>
      )}
    </Formik>
  );
};

export default ResetPassword;
