import moment from 'moment';
import i18n from 'i18next';

import { APP_FILTERS_KEY, AUTH_SESSION_KEY, countryCodeToContryName, MIN_DATE_DATA_OVERVIEW } from 'core/constants';
import {
  GlobalFundingCriteria,
  OverviewDateRange,
  RuleEngineCriteria,
  SupportedCurrencies,
  SupportedLocales,
} from 'core/types';
import {
  Address,
  AddressStatus,
  ApplicationDetailsRequested,
  ApplicationsQueryFilters,
  OnlineSales,
  TimePeriod,
} from 'store/applications/types';
import { AuthSessionDetails } from 'store/auth/types';

/**
 * No operation function
 */
export const noop = (): void => {};

/**
 *
 * @param currency - SupportedCurrencies
 * @returns SupportedLocale
 */
const getLocaleForCurrency = (currency: SupportedCurrencies) => {
  switch (currency) {
    case SupportedCurrencies.GBP:
      return SupportedLocales.GB;
    case SupportedCurrencies.USD:
      return SupportedLocales.US;
    default:
      return SupportedLocales.GB;
  }
};

/**
 *  Returns properly formatted money string
 * @param value
 * @param currency
 * @returns
 */
export const parseMoney = (value?: number, currency = SupportedCurrencies.GBP, noStyle = false): string => {
  return new Intl.NumberFormat(getLocaleForCurrency(currency), {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: noStyle ? undefined : 'currency',
    currency: noStyle ? undefined : currency,
  }).format(value || 0);
};

/**
 * Returns random date between two dates
 * @param start
 * @param end
 * @returns
 */
export const randomDate = (start: Date, end: Date): Date => {
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
};

/**
 *
 * @param timeMs delay in miliseconds
 */
export const waitFor = (timeMs: number): Promise<void> =>
  new Promise<void>((resolve) => setTimeout(() => resolve(), timeMs));

/**
 *
 * @param data
 * @param rememberMe
 */
export const setAuthSession = (data: AuthSessionDetails, rememberMe = false): void => {
  if (rememberMe) {
    localStorage?.setItem(AUTH_SESSION_KEY, JSON.stringify(data));
  } else {
    sessionStorage?.setItem(AUTH_SESSION_KEY, JSON.stringify(data));
  }
};

/**
 *
 * @returns
 */
export const getAutSession = (): AuthSessionDetails | null => {
  try {
    const savedSession = sessionStorage?.getItem(AUTH_SESSION_KEY) || localStorage?.getItem(AUTH_SESSION_KEY);
    if (savedSession) {
      return JSON.parse(savedSession);
    }
    return null;
  } catch (error) {
    return null;
  }
};

/**
 *
 */
export const clearAuthSession = (): void => {
  localStorage?.removeItem(AUTH_SESSION_KEY);
  sessionStorage?.removeItem(AUTH_SESSION_KEY);
};

/**
 *
 * @param to
 * @param cc
 * @param subject
 * @param body
 */
export const sendEmailViaClient = (to: string, cc: string, subject: string, body: string): void => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.location.target = '_blank';
  window.location.href = `mailto:${to}?cc=${cc}&subject=${subject}&body=${body}`;
};

/**
 *
 * @param object
 * @param key - key of the prop which value we want
 * @returns value (string)
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getKeyStringValue = (object: Record<string, any>, key: string): string => {
  return object[key] || '';
};

/**
 *
 * @param addressObj
 * @returns
 */
export const composeAddress = (addressObj: Address): string => {
  let composedAddress = '';
  const keys = ['house_number', 'line_1', 'line_2', 'county', 'country_code', 'postcode'];
  keys.forEach((key, index) => {
    const lastValue = index === keys.length - 1;
    if (key === 'country_code') {
      const parsedValue = countryCodeToContryName[getKeyStringValue(addressObj, key)];
      composedAddress += ` ${parsedValue}${parsedValue && !lastValue ? ',' : ''}`;
    } else {
      const value = getKeyStringValue(addressObj, key);
      composedAddress += ` ${value}${value && !lastValue ? ',' : ''}`;
    }
    composedAddress = composedAddress.trim();
  });
  return composedAddress;
};

/**
 *
 * @param text
 * @returns
 */
export const toTitleCase = (text: string): string => {
  return text.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase());
};

/**
 *
 * @param detailsRequested
 * @returns
 */
export const getFollowUpKey = (detailsRequested: ApplicationDetailsRequested[]): string => {
  return detailsRequested.reduce((acc: string, val: ApplicationDetailsRequested) => {
    acc += val.notes;
    acc += val.document_types.join(',');
    return acc;
  }, '');
};

/**
 *
 * @param firstName
 * @param lastName
 * @returns fullName
 */
export const composeFullName = (firstName?: string, lastName?: string): string => {
  let fullName = '';
  if (firstName) fullName += firstName.trim();
  if (lastName) fullName += ` ${lastName.trim()}`;
  return fullName;
};

/**
 *
 * @param firstName
 * @param lastName
 * @returns initials
 */
export const composeInitials = (firstName?: string, lastName?: string): string => {
  let fullName = '';
  if (firstName) fullName += firstName.trim();
  if (lastName) fullName += ` ${lastName.trim()}`;
  return fullName
    .split(' ')
    .map((n) => n[0])
    .join('');
};

/**
 *
 * @param number
 * @returns
 */
export const numberToPercentage = (number?: number): string => {
  return `${((number || 0) * 100).toFixed(0)}%`;
};

/**
 *
 * @param dateOnly - 'YYYY-MM-DD' format
 * @returns date object
 */
export const getDateFromDateOnly = (dateOnly: string): Date => {
  const [year, month, day] = dateOnly.split('-');
  // month range is from 0 to 11
  return new Date(Number(year), Math.max(Number(month) - 1, 0), Number(day ?? 1));
};

/**
 *
 * @param dateRange - OverviewDateRange
 * @returns Object { start: Date, end: Date }
 */
export const getDateRangeByFilter = (dateRange: OverviewDateRange): { start: Date; end: Date } => {
  let start = moment().toDate();
  const end = moment().endOf('day').toDate();
  switch (dateRange) {
    // case OverviewDateRange.TODAY:
    //   start = moment().startOf('day').toDate();
    //   break;
    case OverviewDateRange.SEVEN_DAYS:
      start = moment().subtract(1, 'weeks').startOf('day').toDate();
      break;
    case OverviewDateRange.FOUR_WEEKS:
      start = moment().subtract(4, 'weeks').startOf('day').toDate();
      break;
    case OverviewDateRange.THREE_MONTHS:
    case OverviewDateRange.QUARTER_TO_DATE:
      start = moment().subtract(3, 'months').startOf('day').toDate();
      break;
    case OverviewDateRange.TWELVE_MONTHS:
    case OverviewDateRange.YEAR_TO_DATE:
      start = moment().subtract(12, 'months').startOf('day').toDate();
      break;
    case OverviewDateRange.MONTH_TO_DATE:
      start = moment().subtract(1, 'months').startOf('day').toDate();
      break;
    case OverviewDateRange.ALL_TIME:
      start = MIN_DATE_DATA_OVERVIEW;
      break;
    default:
      start = MIN_DATE_DATA_OVERVIEW;
      break;
  }
  return { start, end };
};

/**
 *
 * @param targetObject
 * @param options
 * @returns
 */
export const excludePropValues = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  targetObject: Record<string, any>,
  options: Record<string, number>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Record<string, any> =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Object.entries(targetObject).reduce((result: Record<string, any>, [key, value]) => {
    if (options[key]) return result;
    result[key] = value;
    return result;
  }, {});

/**
 *
 * @param decimal
 * @returns
 */
export const decimalToPercentage = (decimal?: number): string => {
  return `${((decimal || 0) * 100).toFixed(1)}%`;
};

/**
 *
 * @param text
 * @returns
 */
export const camelCaseToSentenceCaseText = (text: string): string => {
  return toTitleCase(text.replace(/([A-Z])/g, ' $1'));
};

/**
 *
 * @param value
 * @returns
 */
export const getOnlineSalesInterval = (value: number | string): [number, number] => {
  switch (value) {
    case OnlineSales.LESS_THAN_25:
      return [0, 0.24];
    case OnlineSales.BETWEEN_25_50:
      return [0.25, 0.5];
    case OnlineSales.BETWEEN_50_75:
      return [0.51, 0.75];
    case OnlineSales.MORE_THAN_75:
      return [0.76, 1];
    default:
      return [0, 1];
  }
};

/**
 *
 * @param timeNumber
 * @param timePeriod
 * @returns
 */
export const getMinDateStringForTimeWithPeriod = (timeNumber: number, timePeriod: TimePeriod): string => {
  return moment()
    .subtract(timeNumber, timePeriod === TimePeriod.MONTHLY ? 'months' : 'years')
    .toDate()
    .toISOString();
};

/**
 *
 * @param numHours
 * @returns
 */
export const parseHoursToTimeSpentLabel = (numHours: number): string => {
  const days = Math.floor(numHours / 24);
  const remainder = numHours % 24;
  const hours = Math.floor(remainder);
  const minutes = Math.floor(60 * (remainder - hours));

  if (!days && !hours && !minutes) return '1m';

  return `${days ? `${days}d` : ''} ${hours ? `${hours}h` : ''} ${minutes ? `${minutes}m` : ''}`;
};

/**
 *
 * @param filters
 */
export const setAppFilters = (filters: ApplicationsQueryFilters): void => {
  sessionStorage?.setItem(APP_FILTERS_KEY, JSON.stringify(filters));
};

/**
 *
 * @returns
 */
export const getAppFilters = (): ApplicationsQueryFilters | null => {
  try {
    const savedFilters = sessionStorage?.getItem(APP_FILTERS_KEY);
    if (!savedFilters) return null;
    return JSON.parse(savedFilters);
  } catch (error) {
    return null;
  }
};

/**
 *
 */
export const clearAppFilters = (): void => {
  sessionStorage?.removeItem(APP_FILTERS_KEY);
};

/**
 *
 * @param status
 * @returns
 */
export const isHomeowner = (status: AddressStatus): boolean => {
  return status === AddressStatus.OWNER_NO_MORTGAGE || status === AddressStatus.OWNER_WITH_MORTGAGE;
};

/**
 *
 * @param ruleFact
 * @param ruleValue
 * @returns
 */
export const getRuleParsedValue = (
  ruleFact: string,
  ruleValue: string | number | boolean,
  criteria?: RuleEngineCriteria,
): string | number => {
  let parsedValue = ruleValue;
  if (typeof parsedValue === 'number' && (ruleFact.includes('amount') || ruleFact.includes('turnover'))) {
    parsedValue = parseMoney(parsedValue, undefined, criteria === GlobalFundingCriteria.GLOBAL);
  } else if (typeof parsedValue === 'boolean') {
    if (parsedValue) parsedValue = i18n.t('global.yes') as string;
    else parsedValue = i18n.t('global.no') as string;
  }
  return parsedValue;
};

/**
 *
 * @param array
 * @param index
 * @param items
 * @returns
 */
export const replaceAtIndex = <T>(array: T[], index: number, items: T[]): T[] => [
  ...array.slice(0, index),
  ...items,
  ...array.slice(index + 1),
];

/**
 *
 * @param date
 * @returns
 */
export const getTime = (date?: Date): number => {
  return date != null ? date.getTime() : 0;
};

/**
 *
 * @param a
 * @param b
 * @returns
 */
export const sortByDate = (a: { date?: string }, b: { date?: string }): number => {
  if (!a.date || !b.date) return 0;
  return getTime(new Date(b.date)) - getTime(new Date(a.date));
};

/**
 *
 * @param lastName
 * @param firstName
 * @returns director name
 */
export const composeDirectorName = (lastName?: string, firstName?: string): string => {
  let directorName = '';
  if (lastName) directorName += lastName.trim();
  if (firstName) directorName += `, ${firstName.trim()}`;
  return directorName;
};

/**
 *
 * @param targetObject object we want to parse
 * @param options object with properties we want as keys
 * @returns parsed object
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseObject = (targetObject: Record<string, any>, options: Record<string, number>): Record<string, any> =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Object.entries(targetObject).reduce((result: Record<string, any>, [key, value]) => {
    if (options[key] && value) result[key] = value;
    return result;
  }, {});

/**
 *
 * @param text
 * @returns extension name
 */
export const getFileExtension = (fileName: string): string => {
  const lastDotIndex = fileName.lastIndexOf('.');
  if (lastDotIndex === -1 || lastDotIndex === fileName.length - 1) {
    return '';
  }
  const extension = fileName.slice(lastDotIndex + 1);
  return extension;
};

/**
 *
 * @param text
 * @returns boolean
 */

export const isImageType = (extension: string): boolean => {
  const imageTypes = ['png', 'jpeg', 'jpg', 'svg', 'SVG', 'JPG', 'JPEG', 'PNG'];
  return imageTypes.includes(extension);
};

/**
 *
 * @param text
 * @returns boolean
 */

export const isExcelType = (extension: string): boolean => {
  const excelTypes = ['xls', 'xlsx', 'XLS', 'XLSX'];
  return excelTypes.includes(extension);
};

/**
 *
 * @param text
 * @returns boolean
 */

export const isCsvType = (extension: string): boolean => {
  const csvTypes = ['csv', 'CSV'];
  return csvTypes.includes(extension);
};

/**
 *
 * @param text
 * @returns boolean
 */

export const isPDFType = (extension: string | null): boolean => {
  const pdfTypes = ['pdf', 'PDF'];
  return pdfTypes.includes(extension || '');
};

export const createAddress = (data: string[]): string => {
  const value = data?.filter((part: string) => part);
  return value?.join(', ');
};
