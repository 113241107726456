import { ChangeEvent, FC, useRef } from 'react';
import { Grid } from '@material-ui/core';
import { Formik, Form, Field, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { AccountRoles } from 'core/types';
import { AlertDialog } from 'components/AlertDialog';
import { CustomSelect } from 'components/inputs';
import { useAccount } from 'store/account/hooks';
import useStyles from './UpdateUserRoleModal.styles';

interface FormValues {
  role: AccountRoles | null;
}

interface UpdateUserRoleModalProps {
  open: boolean;
  toggleOpen: () => void;
  roleOptions: { label: string; value: AccountRoles }[];
  userRole: { id: string; role: AccountRoles };
}

const UpdateUserRoleModal: FC<UpdateUserRoleModalProps> = ({ open, toggleOpen, roleOptions, userRole }) => {
  const formRef = useRef<FormikProps<FormValues> | null>(null);
  const classes = useStyles();
  const { t } = useTranslation();
  const { loading, updateAccountUserRole } = useAccount(() => {
    if (open) {
      formRef.current?.resetForm();
      toggleOpen();
    }
  });

  const formSchema = yup.object({
    role: yup.string().required(t('components.updateUserRoleModal.inputs.role.required')).nullable(),
  });

  const initialValues: FormValues = {
    role: userRole.role,
  };

  const onSubmit = (values: FormValues) => {
    if (values.role) {
      updateAccountUserRole({ id: userRole.id, role: values.role });
    }
  };

  const onCancel = () => {
    formRef.current?.resetForm();
    toggleOpen();
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={formSchema} innerRef={formRef}>
      {({ values, handleSubmit, setFieldValue }) => (
        <AlertDialog
          open={open}
          dialogContentTitle={t('components.updateUserRoleModal.title')}
          handleCancel={onCancel}
          handleConfirm={handleSubmit}
          confirmButtonTitle={t('components.updateUserRoleModal.buttons.update')}
          loading={loading}
        >
          <Form noValidate>
            <Grid container direction="column" spacing={4} className={classes.container}>
              <Grid item>
                <Field
                  id="role"
                  fullWidth
                  component={CustomSelect}
                  options={roleOptions}
                  name="role"
                  value={values.role}
                  onChange={(event: ChangeEvent<HTMLSelectElement>) => setFieldValue('role', event.target.value)}
                  title={t('components.updateUserRoleModal.inputs.role.label')}
                  className={classes.textInput}
                />
              </Grid>
            </Grid>
          </Form>
        </AlertDialog>
      )}
    </Formik>
  );
};

export default UpdateUserRoleModal;
