import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  integrateToolBox: {
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: `${theme.spacing(2)} !important`,
    backgroundColor: '#FEFEFF !important',
    boxShadow: `${theme.shadows[1]} !important`,
    height: '100%',
  },
  integrateToolBoxInner: {
    padding: `${theme.spacing(0)} !important`,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
}));

export default useStyles;
