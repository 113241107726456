import { Box, Typography } from '@mui/material';
import clsx from 'clsx';
import { rejectionReasonsOptions } from 'core/constants';
import { RejectionReasonTag } from 'core/types';
import { ErrorMessage, Formik, FormikHelpers, FormikProps } from 'formik';
import { ApplicationDetails, OfferStatus } from 'store/applications/types';
import * as yup from 'yup';
import { useRef } from 'react';
import CustomCheckbox from 'components/CustomCheckBox/CheckBox';
import FooterButton from 'components/Modal/FooterButton';
import { CustomTextarea } from 'components/inputs';
import { useTranslation } from 'react-i18next';
import { useAccount } from 'store/account/hooks';
import { useAdmin } from 'store/admin/hooks';
import { OfferProductType } from 'store/admin/types';
import { useApplications } from 'store/applications/hooks';
import { useAuth } from 'store/auth/hooks';
import useStyles from './RejectModal.styles';
import useCommonStyles from '../../../../assets/css/Common.styles';

interface FormValues {
  rejectionReason: string | null;
  rejectionReasonTags: RejectionReasonTag[];
}

const RejectsModal = ({
  details,
  onClose,
}: {
  details: ApplicationDetails | null;
  onClose: (value: string) => void;
}) => {
  const { application_rejection } = details as ApplicationDetails;
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { t } = useTranslation();
  const formRef = useRef<FormikProps<FormValues> | null>(null);

  const { loading, rejectApplication, makeAnOffer, setError } = useAdmin(() => {
    formRef.current?.resetForm();
  });

  const initialValues: FormValues = {
    rejectionReason: application_rejection?.rejection_reason ?? null,
    rejectionReasonTags: application_rejection?.rejection_reason_tags ?? [],
  };

  const rejectSchema = yup.object({
    rejectionReason: yup.string().required(t('pages.lead.admin.reject.inputs.rejectionReason.required')).nullable(),
    rejectionReasonTags: yup
      .array(yup.string())
      .min(1, t('pages.lead.admin.reject.inputs.rejectionReasonTags.error'))
      .required(t('pages.lead.admin.reject.inputs.rejectionReasonTags.required'))
      .nullable(),
  });

  const { applicationDetails } = useApplications();
  const { isLenderAdmin } = useAuth();
  const { account } = useAccount();

  const onSubmit = (values: FormValues, helper: FormikHelpers<FormValues>) => {
    try {
      if (isLenderAdmin && applicationDetails && account?.id) {
        makeAnOffer({
          application_id: applicationDetails.id,
          lender_id: account.id,
          status: OfferStatus.DECLINED,
          product_type: OfferProductType.REVENUE_BASED,
          offer_details: {
            valid_until: new Date(),
            revenue_repayments: [],
          },
          rejection_reason: values.rejectionReason ?? undefined,
          rejection_reason_tags: values.rejectionReasonTags,
        });

        return;
      }
      if (applicationDetails) {
        rejectApplication({
          id: applicationDetails.id,
          rejectionReason: values.rejectionReason ?? undefined,
          rejectionReasonTags: values.rejectionReasonTags,
        });
      }
      if (!loading) {
        onClose('');
      }
      helper.resetForm();
    } catch (error) {
      setError((error as Error)?.message ?? true);
      onClose('');
    }
  };

  return (
    <Formik initialValues={initialValues} validationSchema={rejectSchema} onSubmit={onSubmit}>
      {({ handleSubmit, values, setFieldValue }) => {
        return (
          <Box className={clsx([commonClasses.filter_cardBody])}>
            <Typography component="div" className={clsx([commonClasses.filedTitle])}>
              Select reasons for rejection
            </Typography>
            <ul className={classes.ul_style}>
              {rejectionReasonsOptions?.map((d: { value: string; label: string }) => {
                return (
                  <li key={d?.value}>
                    <CustomCheckbox
                      formik={false}
                      value={d?.value}
                      checked={values?.rejectionReasonTags?.includes(d?.value as RejectionReasonTag)}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (!e?.target?.checked) {
                          setFieldValue(
                            'rejectionReasonTags',
                            values.rejectionReasonTags.filter((v) => v !== e?.target.value),
                          );
                        } else {
                          setFieldValue('rejectionReasonTags', [...values.rejectionReasonTags, e?.target.value]);
                        }
                      }}
                      name="rejectionReasonTags"
                      label={d?.label}
                    />
                  </li>
                );
              })}
            </ul>
            <ErrorMessage name="rejectionReasonTags">
              {(msg) => <Box className={clsx([classes.errorText])}>{msg}</Box>}
            </ErrorMessage>
            <Box className={classes.modalTextAreBox}>
              <CustomTextarea name="rejectionReason" label="Please provide reasons for rejection" minRows={3} />
            </Box>
            <FooterButton
              handleSubmit={handleSubmit}
              onClose={() => onClose('')}
              btnName="Reject application"
              loading={loading}
            />
          </Box>
        );
      }}
    </Formik>
  );
};

export default RejectsModal;
