import { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { useActionCreator, usePrevious } from 'hooks';
import { RootState } from '../types';
import {
  fetchAccount,
  fetchApiKeys,
  inviteUser,
  setError,
  setSuccess,
  getAccountUsers,
  deleteAccountUser,
  updateAccountUserRole,
} from './actions';
import { AccountReturnHook } from './types';

export const useAccount = (onSuccess?: () => void): AccountReturnHook => {
  const accountState = useSelector((state: RootState) => state.account, shallowEqual);
  const prevLoading = usePrevious(accountState.loading);

  useEffect(() => {
    if (prevLoading && !accountState.loading && !accountState.error && onSuccess) {
      onSuccess();
    }
  }, [accountState.error, accountState.loading, onSuccess, prevLoading]);

  return {
    ...accountState,
    fetchAccount: useActionCreator(fetchAccount),
    fetchApiKeys: useActionCreator(fetchApiKeys),
    inviteUser: useActionCreator(inviteUser),
    setError: useActionCreator(setError),
    setSuccess: useActionCreator(setSuccess),
    getAccountUsers: useActionCreator(getAccountUsers),
    deleteAccountUser: useActionCreator(deleteAccountUser),
    updateAccountUserRole: useActionCreator(updateAccountUserRole),
  };
};
