import { Box, Grid, Typography } from '@mui/material';
import clsx from 'clsx';
import useStyle from './NoDataFound.styles';
import { ReactComponent as NoDataFoundIcon } from '../../assets/icons/noDataFound.svg';
import { ReactComponent as RightArrow } from '../../assets/icons/rightArrow.svg';
import { ReactComponent as NoDataFoundIcon127 } from '../../assets/icons/no_data_found_127.688.svg';

const NoDataFound = ({
  title,
  linkTitle,
  onClick,
  width = '100%',
}: {
  title: string;
  linkTitle?: string;
  onClick?: () => void;
  width?: string;
}) => {
  const classes = useStyle();
  return (
    <Grid>
      <Box className={clsx([classes.emptyDataWrap])} width={width}>
        <Box className={clsx([classes.emptyDataIamge])}>
          {width === '127.688px' ? <NoDataFoundIcon127 /> : <NoDataFoundIcon />}
        </Box>
        <Typography className={clsx([classes.emptyDataText])} variant="body1" component="p">
          {title}
        </Typography>
        {linkTitle && (
          <Typography
            onClick={onClick}
            className={clsx([classes.emptyDataText, classes.primary_color])}
            variant="body1"
            component="p"
          >
            {linkTitle} <RightArrow className={clsx([classes.rightArrowIcon])} />
          </Typography>
        )}
      </Box>
    </Grid>
  );
};

export default NoDataFound;
