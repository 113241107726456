import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Box, List, ListItem, ListItemIcon, ListItemText, Select } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { AlertMessage } from 'components/alerts';
import { EditableTextField } from 'components/inputs';
import { Loader } from 'components/Loader';
import { AlertMessageStatus, UserQuery, UserRole } from 'core/types';
import { mapApplicationStatusToLabel } from 'core/utils';
import { setAssignee } from 'http/admin';
import { useAccount } from 'store/account/hooks';
import { useAdmin } from 'store/admin/hooks';
import { useApplications } from 'store/applications/hooks';
import { ApplicationStatus, CompanyDetails } from 'store/applications/types';
import { useAuth } from 'store/auth/hooks';
import { composeFullName } from 'utils';
import { AutomatedEmailsSwitch } from '../AutomatedEmailsSwitch';
import useStyles from '../LeadDetails/LeadDetails.styles';
import { WidgetMode } from '../WidgetMode';
import { UpdateStatusModal } from '../UpdateStatusModal';

interface ApplicationHeaderProps {
  company?: CompanyDetails;
  fundingAmount?: number;
  status: ApplicationStatus;
  createdAt?: string;
  partnerName?: string;
}

const adminQuery: UserQuery = {
  per_page: 1000,
  page: 1,
  role: `${UserRole.ADMIN},${UserRole.SUPER_ADMIN}`,
};

const ApplicationHeader: FC<ApplicationHeaderProps> = ({ company, fundingAmount, status, createdAt, partnerName }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { isAdmin, isCompanyOwner, role } = useAuth();
  const { admins, loading, getAdminUsers, setError: setAdminError, setSuccess: setAdminSuccess } = useAdmin();
  const { applicationDetails } = useApplications();
  const { users } = useAccount();

  const [assignee, setNewAssignee] = useState<string | undefined>(applicationDetails?.assignee?.id);
  const [assignError, setError] = useState<string | undefined>(undefined);
  const [success, setSuccess] = useState<boolean>(false);

  const { id: applicationId } = useParams<{ id: string }>();

  useEffect(() => {
    if (isAdmin) getAdminUsers(adminQuery);
  }, [getAdminUsers, isAdmin]);

  const setAppAssignee = async (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    const { value } = event.target;
    try {
      await setAssignee(applicationId, (value as string) || undefined);
      setNewAssignee(value as string);
      setSuccess(true);
    } catch (err) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const errMessage = (err as any)?.response?.message;
      setError(errMessage || (err as Error).message);
    }
  };

  const assigneeOptions: { id: string; fullName: string }[] = useMemo(() => {
    if (isAdmin) return admins;
    return users.map((user) => ({ id: user.id, fullName: composeFullName(user.first_name, user.last_name) }));
  }, [admins, isAdmin, users]);

  return (
    <>
      <List>
        {(isAdmin || role === UserRole.LENDER_MANAGER) && (
          <ListItem className={classes.listItem}>
            <ListItemText className={classes.listItemTitle}>
              {t('pages.lead.sections.header.assignee.title')}
            </ListItemText>

            {loading ? (
              <Loader visible={loading} />
            ) : (
              <Box className={classes.selectContainer}>
                <Select
                  native
                  IconComponent={() => <ExpandMore className={classes.expandIcon} />}
                  variant="outlined"
                  onChange={setAppAssignee}
                  value={assignee}
                  color="primary"
                  className={classes.select}
                >
                  <option value="">None</option>
                  {assigneeOptions.map(({ id, fullName }) => {
                    return (
                      <option key={id} value={id}>
                        {fullName}
                      </option>
                    );
                  })}
                </Select>
              </Box>
            )}
          </ListItem>
        )}

        <ListItem className={classes.listItem}>
          <ListItemText
            className={classes.listItemTitle}
            secondary={t('pages.lead.admin.updateStatus.inputs.status.label')}
          >
            {t('pages.lead.sections.header.status')}
          </ListItemText>
          <ListItemIcon className={classes.listItemText}>
            {isAdmin && applicationDetails ? (
              <UpdateStatusModal appStatus={applicationDetails?.status} />
            ) : (
              <ListItemText className={classes.listItemText}>
                {isCompanyOwner ? mapApplicationStatusToLabel(status) : status}
              </ListItemText>
            )}
          </ListItemIcon>
        </ListItem>

        <ListItem className={classes.listItem}>
          <ListItemText className={classes.listItemTitle}>{t('pages.lead.sections.header.companyName')}</ListItemText>
          <ListItemText className={classes.listItemText}>{company?.registered_name}</ListItemText>
        </ListItem>

        <ListItem className={classes.listItem}>
          <ListItemText className={classes.listItemTitle}>{t('pages.lead.sections.header.companyType')}</ListItemText>
          <ListItemText className={classes.listItemText}>{company?.type}</ListItemText>
        </ListItem>

        <ListItem className={classes.listItem}>
          <ListItemText className={classes.listItemTitle}>{t('pages.lead.sections.header.loanAmount')}</ListItemText>
          <ListItemText className={classes.listItemText}>
            <EditableTextField initialValue={String(fundingAmount)} type="currency" propKey="funding_amount" />
          </ListItemText>
        </ListItem>

        <ListItem className={classes.listItem}>
          <ListItemText className={classes.listItemTitle}>{t('pages.lead.sections.header.dateSubmitted')}</ListItemText>
          <ListItemText className={classes.listItemText}>{moment(createdAt).format('DD/MM/YYYY')}</ListItemText>
        </ListItem>

        {(isAdmin || isCompanyOwner) && (
          <>
            <ListItem className={classes.listItem}>
              <ListItemText className={classes.listItemTitle}>
                {t('pages.lead.sections.header.partnerName')}
              </ListItemText>
              <ListItemText className={classes.listItemText}>{partnerName}</ListItemText>
            </ListItem>

            <WidgetMode />
          </>
        )}

        {isAdmin && applicationDetails && (
          <ListItem className={classes.listItem}>
            <ListItemText className={classes.listItemTitle}>
              {t('pages.lead.sections.header.automatedEmails')}
            </ListItemText>

            <AutomatedEmailsSwitch
              applicationId={applicationDetails.id}
              initialActive={!!applicationDetails.active_automated_emails}
              setError={setAdminError}
              setSuccess={setAdminSuccess}
            />
          </ListItem>
        )}
      </List>

      <AlertMessage open={Boolean(assignError)} onClose={() => setError(undefined)} message={assignError} />

      <AlertMessage
        open={success}
        onClose={() => setSuccess(false)}
        autoHideDuration={5000}
        status={AlertMessageStatus.SUCCESS}
      />
    </>
  );
};

export default ApplicationHeader;
