import { Box, Button, Card, CardContent, Grid, Link, Stack, Typography } from '@mui/material';
import clsx from 'clsx';
import theme from 'core/theme-extended';
import routes from 'router/routes';
import { useHistory } from 'react-router-dom';
import useStyles from '../WorkFlow.styles';
import { ReactComponent as AssetsIcon } from '../../../assets/icons/assets_icon.svg';
import { ReactComponent as MailReceivedIcon } from '../../../assets/icons/mail.svg';

const WorkFlowTab = () => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Box>
      <Box sx={{ p: 8, mb: 10 }} className={classes.neWorkFlowBox}>
        <Stack direction="row" justifyContent="center" alignItems="center">
          <Box>
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={4}>
              <Box sx={{ width: 190, height: 190 }}>
                <Card
                  className={clsx([classes.workflowProfileBox])}
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                  {/* <Avatar alt="Remy Sharp" src="/images/avatarIcon.png" sx={{ width: 192, height: 192 }} /> */}
                </Card>
              </Box>
            </Stack>
          </Box>
        </Stack>
        <Box sx={{ pt: 4, mx: 'auto' }} className={clsx([classes.saveTimeDetail])}>
          <Typography
            className={clsx([classes.fontJost, classes.gray_800, classes.fontWeight_500])}
            component="p"
            variant="text_XL"
            sx={{ mb: 1 }}
          >
            Save time with workflows
          </Typography>
          <Typography
            className={clsx([classes.fontJost, classes.gray_700])}
            component="p"
            variant="text_SM"
            sx={{ mb: 4 }}
          >
            Create automatically scheduled emails and reminders to be sent at designated intervals
          </Typography>
          <Button
            variant="contained"
            className=""
            onClick={() => {
              history.push(routes.createWorkFlow);
            }}
          >
            Create new workflow
          </Button>

          <Box sx={{ pt: 4 }}>
            Or
            <Link sx={{ ml: 1 }} href={routes?.createWorkFlow} component="text" color={theme.palette.Blue[600]}>
              learn more about workflows
              <AssetsIcon />
            </Link>
          </Box>
        </Box>
      </Box>
      <Box sx={{ mb: 6 }}>
        <Typography
          className={clsx([classes.fontJost, classes.gray_700, classes.fontWeight_500])}
          component="p"
          variant="text_LG"
          sx={{ mb: 2 }}
        >
          Start with a predefined template
        </Typography>
      </Box>
      <Grid container spacing={6}>
        <Grid item xs={12} md={6} lg={4}>
          <Card className={clsx([classes.integrateToolBox])}>
            <CardContent sx={{ p: 0 }} className={classes.integrateToolBoxInner}>
              <Box sx={{ px: 6, pt: 4, mb: 2 }}>
                <Stack sx={{ mb: 2 }} direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                  <Box>
                    <Typography
                      className={clsx([classes.fontJost, classes.gray_700, classes.fontWeight_700])}
                      component="p"
                      variant="text_MD"
                      sx={{ mb: 0 }}
                    >
                      Application received mail
                    </Typography>
                  </Box>
                  <Box>
                    <Box className={clsx([classes.mailBoxBlack])}>
                      <MailReceivedIcon />
                    </Box>
                  </Box>
                </Stack>
                <Typography className={clsx([classes.fontJost])} component="p" variant="text_SM" sx={{ mb: 0 }}>
                  Send mail to partners and lenders when they apply to Business score.
                </Typography>
              </Box>
              <Box sx={{ px: 6, py: 4, mt: 'auto' }}>
                <Stack direction="row" justifyContent="start" alignItems="center" spacing={4}>
                  <Box>
                    <Link href="/" className={classes.linkBtn} underline="none" component="text">
                      Use workflow
                    </Link>
                  </Box>
                </Stack>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Card className={clsx([classes.integrateToolBox])}>
            <CardContent sx={{ p: 0 }} className={classes.integrateToolBoxInner}>
              <Box sx={{ px: 6, pt: 4, mb: 2 }}>
                <Stack sx={{ mb: 2 }} direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                  <Box>
                    <Typography
                      className={clsx([classes.fontJost, classes.gray_700, classes.fontWeight_700])}
                      component="p"
                      variant="text_MD"
                      sx={{ mb: 0 }}
                    >
                      Application received mail
                    </Typography>
                  </Box>
                  <Box>
                    <Box className={clsx([classes.mailBoxBlack])}>
                      <MailReceivedIcon />
                    </Box>
                  </Box>
                </Stack>
                <Typography className={clsx([classes.fontJost])} component="p" variant="text_SM" sx={{ mb: 0 }}>
                  Send mail to partners and lenders when they apply to Business score.
                </Typography>
              </Box>
              <Box sx={{ px: 6, py: 4, mt: 'auto' }}>
                <Stack direction="row" justifyContent="start" alignItems="center" spacing={4}>
                  <Box>
                    <Link href="/" className={classes.linkBtn} underline="none" component="text">
                      Use workflow
                    </Link>
                  </Box>
                </Stack>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Card className={clsx([classes.integrateToolBox])}>
            <CardContent sx={{ p: 0 }} className={classes.integrateToolBoxInner}>
              <Box sx={{ px: 6, pt: 4, mb: 2 }}>
                <Stack sx={{ mb: 2 }} direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                  <Box>
                    <Typography
                      className={clsx([classes.fontJost, classes.gray_700, classes.fontWeight_700])}
                      component="p"
                      variant="text_MD"
                      sx={{ mb: 0 }}
                    >
                      Application received mail
                    </Typography>
                  </Box>
                  <Box>
                    <Box className={clsx([classes.mailBoxBlack])}>
                      <MailReceivedIcon />
                    </Box>
                  </Box>
                </Stack>
                <Typography className={clsx([classes.fontJost])} component="p" variant="text_SM" sx={{ mb: 0 }}>
                  Send mail to partners and lenders when they apply to Business score.
                </Typography>
              </Box>
              <Box sx={{ px: 6, py: 4, mt: 'auto' }}>
                <Stack direction="row" justifyContent="start" alignItems="center" spacing={4}>
                  <Box>
                    <Link href="/" className={classes.linkBtn} underline="none" component="text">
                      Use workflow
                    </Link>
                  </Box>
                </Stack>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Card className={clsx([classes.integrateToolBox])}>
            <CardContent sx={{ p: 0 }} className={classes.integrateToolBoxInner}>
              <Box sx={{ px: 6, pt: 4, mb: 2 }}>
                <Stack sx={{ mb: 2 }} direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                  <Box>
                    <Typography
                      className={clsx([classes.fontJost, classes.gray_700, classes.fontWeight_700])}
                      component="p"
                      variant="text_MD"
                      sx={{ mb: 0 }}
                    >
                      Application received mail
                    </Typography>
                  </Box>
                  <Box>
                    <Box className={clsx([classes.mailBoxBlack])}>
                      <MailReceivedIcon />
                    </Box>
                  </Box>
                </Stack>
                <Typography className={clsx([classes.fontJost])} component="p" variant="text_SM" sx={{ mb: 0 }}>
                  Send mail to partners and lenders when they apply to Business score.
                </Typography>
              </Box>
              <Box sx={{ px: 6, py: 4, mt: 'auto' }}>
                <Stack direction="row" justifyContent="start" alignItems="center" spacing={4}>
                  <Box>
                    <Link href="/" className={classes.linkBtn} underline="none" component="text">
                      Use workflow
                    </Link>
                  </Box>
                </Stack>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WorkFlowTab;
