import { FC, useState, useEffect } from 'react';
import { Card, Divider, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import NoDataFound from 'components/NoDataFound/NoDataFound';
import { EmailType } from 'core/types';
import { Box } from '@mui/material';
import { usePrevious } from 'hooks';
import clsx from 'clsx';
import { getEmails as getEmailsApi } from 'http/admin';
import { useAdmin } from 'store/admin/hooks';
import { Email } from 'store/admin/types';
import { DocumentList } from '../CompanyDocuments/components';
import useStyles from './EmailList.styles';

interface EmailListProps {
  applicationId: string;
  companyId: string;
  tabValue?: number;
  statusOverViewFilter?: {
    fromDate: Date;
    toDate: Date;
    monthData: string;
  };
}

const EmailList: FC<EmailListProps> = ({ applicationId, companyId, tabValue, statusOverViewFilter }) => {
  const [emails, setEmails] = useState<Email[]>([]);
  const classes = useStyles();
  const { t } = useTranslation();
  const { refreshEmails, setRefreshEmails } = useAdmin();
  const prevRefreshEmails = usePrevious(refreshEmails);

  const getEmails = async () => {
    try {
      const data = await getEmailsApi(applicationId, tabValue === 4 ? EmailType.SENT : EmailType?.RECEIVED, {
        start: statusOverViewFilter?.fromDate || new Date(),
        end: statusOverViewFilter?.toDate || new Date(),
      });
      setEmails(data);
    } catch (err) {
      setRefreshEmails(false);
    }
  };

  useEffect(() => {
    getEmails();
    // eslint-disable-next-line
  }, [tabValue, statusOverViewFilter, applicationId, setRefreshEmails, statusOverViewFilter]);

  useEffect(() => {
    if (!prevRefreshEmails && refreshEmails) {
      getEmails();
    }
    // eslint-disable-next-line
  }, [prevRefreshEmails, refreshEmails, tabValue]);

  return (
    <Box style={{ marginBottom: '48px' }}>
      {emails?.length > 0 ? (
        emails?.map((email) => (
          <Card key={email.id} className={classes.cardContainer} elevation={1}>
            <Box>
              <Typography className={classes.emailSubheader}>
                {t('global.email.to')} <strong>{email.to}</strong>
              </Typography>
              <Typography className={classes.emailSubheader}>
                {t('global.email.subject')} {email.subject}
              </Typography>
              <Typography className={classes.emailSubheader}>
                {t('global.email.date')} <strong>{new Date(email.created_at).toLocaleDateString()}</strong>
              </Typography>
              <Typography className={classes.emailSubheader}>
                {t('global.email.maskEmail')} <strong>{email.cc}</strong>
              </Typography>
              <Divider light />
            </Box>
            <Box className={classes.margin24px}>
              {email.attachments?.length && (
                <DocumentList
                  title={t('pages.lead.sections.emailList.attachments')}
                  companyId={companyId}
                  documents={email.attachments}
                />
              )}

              <div
                className={clsx([tabValue === 4 && classes.lineHeight])}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: email?.message }}
              />
            </Box>
          </Card>
        ))
      ) : (
        <NoDataFound title="No data Found" />
      )}
    </Box>
  );
};

export default EmailList;
