import { ChangeEvent, FC, useRef, useState } from 'react';
import { Grid } from '@material-ui/core';
import { Formik, Form, Field, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { AlertDialog } from 'components/AlertDialog';
import { CustomSelect } from 'components/inputs';
import { OfferStatus, ApplicationStatus, WebhookNotificationTypes } from 'store/applications/types';
import { usePartners } from 'store/partners/hooks';
import { sendTestWebhookEvent as sendTestWebhookEventApi } from 'http/partners';
import useStyles from './OfferEventModal.styles';

const offerStatusOptions = Object.values(OfferStatus).map((status) => ({ label: status, value: status }));
const applicationStatusOptions = Object.values(ApplicationStatus).map((status) => ({ label: status, value: status }));
const webhookNotificationTypeOptions = Object.values(WebhookNotificationTypes).map((status) => ({
  label: status,
  value: status,
}));
interface FormValues {
  status: OfferStatus;
  applicationStatus: ApplicationStatus;
  webhookNotificationType: WebhookNotificationTypes;
}

interface OfferEventModalProps {
  open: boolean;
  toggleOpen: () => void;
}

const OfferEventModal: FC<OfferEventModalProps> = ({ open, toggleOpen }) => {
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormikProps<FormValues> | null>(null);
  const classes = useStyles();
  const { t } = useTranslation();
  const { setError, setSuccess } = usePartners();

  const formSchema = yup.object({
    status: yup.string().oneOf(Object.values(OfferStatus)).required(),
    // applicationStatus: yup.string().oneOf(Object.values(ApplicationStatus)).required(),
  });

  const initialValues = {
    status: OfferStatus.PENDING,
    applicationStatus: ApplicationStatus.PENDING,
    webhookNotificationType: WebhookNotificationTypes.OFFER_UPDATE,
  };

  const onSubmit = async (values: FormValues) => {
    setLoading(true);
    try {
      await sendTestWebhookEventApi(values.status, values.applicationStatus, values.webhookNotificationType);
      formRef.current?.resetForm();
      toggleOpen();
      setSuccess(t('pages.api.webhooks.messages.testEventSuccess') as string);
    } catch (err) {
      setError((err as Error).message ?? true);
    }
    setLoading(false);
  };

  const onCancel = () => {
    if (loading) return;
    formRef.current?.resetForm();
    toggleOpen();
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={formSchema} innerRef={formRef}>
      {({ values, handleSubmit, setFieldValue }) => (
        <AlertDialog
          open={open}
          dialogContentTitle={t('pages.api.webhooks.offerEventModal.title')}
          handleCancel={onCancel}
          handleConfirm={handleSubmit}
          confirmButtonTitle={t('pages.api.webhooks.buttons.testEndpoint')}
          loading={loading}
        >
          <Form noValidate>
            <Grid container direction="column" spacing={4} className={classes.container}>
              <Grid item>
                <Field
                  autoFocus
                  id="status"
                  fullWidth
                  component={CustomSelect}
                  options={offerStatusOptions}
                  name="status"
                  value={values.status}
                  onChange={(event: ChangeEvent<HTMLSelectElement>) => setFieldValue('status', event.target.value)}
                  title={t('pages.api.webhooks.offerEventModal.inputs.status.label')}
                  className={classes.textInput}
                />
              </Grid>
            </Grid>

            <Grid container direction="column" spacing={4} className={classes.container}>
              <Grid item>
                <Field
                  autoFocus
                  id="applicationStatus"
                  fullWidth
                  component={CustomSelect}
                  options={applicationStatusOptions}
                  name="applicationStatus"
                  value={values.applicationStatus}
                  onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                    setFieldValue('applicationStatus', event.target.value)
                  }
                  title={t('pages.api.webhooks.offerEventModal.inputs.applicationStatus.label')}
                  className={classes.textInput}
                />
              </Grid>
            </Grid>

            <Grid container direction="column" spacing={4} className={classes.container}>
              <Grid item>
                <Field
                  autoFocus
                  id="webhookNotificationType"
                  fullWidth
                  component={CustomSelect}
                  options={webhookNotificationTypeOptions}
                  name="webhookNotificationType"
                  value={values.webhookNotificationType}
                  onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                    setFieldValue('webhookNotificationType', event.target.value)
                  }
                  title={t('pages.api.webhooks.offerEventModal.inputs.webhookNotificationType.label')}
                  className={classes.textInput}
                />
              </Grid>
            </Grid>
          </Form>
        </AlertDialog>
      )}
    </Formik>
  );
};

export default OfferEventModal;
