import { ChangeEvent, FC, useMemo, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { Typography } from '@mui/material';
import clsx from 'clsx';
import { AlertDialog } from 'components/AlertDialog';
import { DocumentUploadMulti } from 'components/DocumentUpload';
import { AlertMessage } from 'components/alerts';
import { additionalDocumentOptions } from 'core/constants';
import { AlertMessageStatus, CompanyDocumentType, DocUploadResponse } from 'core/types';
import { deleteDocument as deleteDocumentApi, uploadCompanyDocument as uploadCompanyDocumentApi } from 'http/admin';
import { useTranslation } from 'react-i18next';
import { useAdmin } from 'store/admin/hooks';
import { CompanyDetails, CompanyDocument } from 'store/applications/types';
import { useAuth } from 'store/auth/hooks';
import CustomSelectBox from 'components/CustomSelect/CustomSelect';
import { DocumentList } from './components';
import MergedDocumentList from './components/MergedDocumentList/MergeDocumentList';
import useLeadStyles from '../../Lead.styles';

interface CompanyDocumentsProps {
  company?: CompanyDetails;
  appId: string;
}

const CompanyDocuments: FC<CompanyDocumentsProps> = ({ company, appId }) => {
  const [loading, setLoading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [documentType, setDocumentType] = useState<CompanyDocumentType>(CompanyDocumentType.BANK_STATEMENT);
  const [uploadedDocs, setUploadedDocs] = useState<CompanyDocument[]>(company?.documents ?? []);
  const [selectedDoc, setSelectedDoc] = useState<CompanyDocument | null>(null);

  const { t } = useTranslation();
  const { isAdmin, isLenderAdmin } = useAuth();
  const { setError } = useAdmin();
  const homeClasses = useLeadStyles();

  const [companyDocs] = useMemo(() => {
    return uploadedDocs.reduce(
      (acc, val): [CompanyDocument[], CompanyDocument[]] => {
        if (val.type !== CompanyDocumentType.EMAIL_ATTACHMENTS) {
          acc[0].push(val);
        } else {
          acc[1].push(val);
        }
        return acc;
      },
      [[], []] as [CompanyDocument[], CompanyDocument[]],
    );
  }, [uploadedDocs]);

  const afterUpdate = (documents: CompanyDocument[]) => {
    setUploadedDocs((prevState) => {
      return [
        ...prevState.filter((doc) => documents.every((document) => document.name !== doc.name)),
        ...(documents as CompanyDocument[]),
      ];
    });
    setUploadSuccess(true);
  };

  const uploadDocument = async (data: FormData): Promise<DocUploadResponse | null> => {
    if (company?.id) {
      return uploadCompanyDocumentApi(company.id, data);
    }
    return null;
  };

  const onUploadSuccess = (documents: CompanyDocument[]) => {
    afterUpdate(documents);
  };

  const clearUploadSuccess = () => setUploadSuccess(false);

  const handleCancel = () => setSelectedDoc(null);

  const handleConfirmDelete = async () => {
    if (!(selectedDoc && company?.id)) return;
    setLoading(true);
    try {
      await deleteDocumentApi(company.id, selectedDoc.id as string);
      setUploadedDocs((prevState) => prevState.filter((doc) => doc.id !== selectedDoc.id));
      setSelectedDoc(null);
    } catch (err) {
      setError((err as Error).message ?? true);
    }
    setLoading(false);
  };

  const isAdminUser = isAdmin || isLenderAdmin;

  return (
    <>
      <Box className={clsx([homeClasses.formInlineLblValue])}>
        <Box className={clsx([homeClasses.formInlineLblValue_lbl])}>
          <Typography
            variant="text_MD"
            component="label"
            className={clsx([homeClasses.fontJost, homeClasses.fontWeight_500, homeClasses.gray_500])}
          >
            Document type
          </Typography>
        </Box>
        <Box className={clsx([homeClasses.formInlineLblValue_box])}>
          <CustomSelectBox
            options={additionalDocumentOptions}
            name="document"
            onChange={(
              event: ChangeEvent<{
                name?: string | undefined;
                value: unknown;
              }>,
            ) => setDocumentType((event.target.value as CompanyDocumentType) || undefined)}
            value={documentType}
            formik={false}
          />
        </Box>
      </Box>
      <Box>
        <Box className={homeClasses.inputFileWrapper}>
          <DocumentUploadMulti
            uploadDocument={uploadDocument}
            onUploadSuccess={onUploadSuccess}
            documentType={documentType}
            companyDocs={companyDocs}
            afterUpdate={afterUpdate}
          />
        </Box>
      </Box>
      <Box className={clsx([homeClasses.formInlineLblValue])}>
        <Box className={clsx([homeClasses.formInlineLblValue_lbl])}>
          <Typography
            variant="text_MD"
            component="label"
            className={clsx([homeClasses.fontJost, homeClasses.fontWeight_500, homeClasses.gray_500])}
          >
            Merged documents
          </Typography>
        </Box>
        <Box className={clsx([homeClasses.formInlineLblValue_box])}>
          {company?.id && (
            <Grid item xs={12}>
              <DocumentList companyId={company.id} documents={companyDocs} setDocuments={setUploadedDocs} />
            </Grid>
          )}
        </Box>
      </Box>
      <Box className={clsx([homeClasses.formInlineLblValue])}>
        <Box className={clsx([homeClasses.formInlineLblValue_lbl])}>
          <Typography
            variant="text_MD"
            component="label"
            className={clsx([homeClasses.fontJost, homeClasses.fontWeight_500, homeClasses.gray_500])}
          >
            Email attachments
          </Typography>
        </Box>
        <Box className={clsx([homeClasses.formInlineLblValue_box])}>
          {isAdminUser && (
            <Grid item xs={12}>
              <MergedDocumentList appId={appId} documents={companyDocs} />
            </Grid>
          )}
        </Box>
      </Box>

      <AlertDialog
        open={!!selectedDoc}
        handleCancel={handleCancel}
        handleConfirm={handleConfirmDelete}
        dialogContentTitle={selectedDoc?.name ?? t('pages.lead.admin.deleteDocument.title')}
        dialogContentText={t('pages.lead.admin.deleteDocument.description')}
        confirmButtonTitle={t('pages.lead.admin.deleteDocument.buttons.confirm')}
        loading={loading}
      />

      <AlertMessage
        open={uploadSuccess}
        onClose={clearUploadSuccess}
        message={t('global.uploadDocumentSuccess')}
        status={AlertMessageStatus.SUCCESS}
      />
    </>
  );
};

export default CompanyDocuments;
