import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3),

    '& .MuiGrid-item': {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
  textInput: {
    minWidth: '100%',

    '& .MuiInputBase-root': {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      fontWeight: 700,
      height: 36,
    },

    '& .Mui-disabled': {
      backgroundColor: theme.palette.grey[200],
      color: theme.palette.common.black,
    },

    '& .Mui-error': {
      color: theme.palette.error.main,
      marginLeft: 0,
      marginRight: 0,
    },

    [theme.breakpoints.down('xs')]: {
      minWidth: 'auto',
    },
  },
  selectBoxDropdown: {
    color: `${theme.palette.Gray[700]} !important`,
    fontWeight: 400,
    width: '100%',
  },
  select: {
    root: {
      '&:hover:not($disabled):before': {
        borderColor: '#D0D5DD',
      },
      '&.Mui-focused:before': {
        borderColor: '#D0D5DD',
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#D0D5DD',
    },
    '&:focus-within .MuiOutlinedInput-notchedOutline': {
      borderColor: '#D0D5DD',
    },
    '& .MuiInputBase-input': {
      padding: '13.5px 14px !important',
    },
  },
}));
