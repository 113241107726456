import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  emptyDataWrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: '40px 0px',
  },
  emptyDataIamge: {},
  emptyDataText: {
    color: `${theme.palette.Gray[600]} !important`,
    marginTop: `${theme.spacing(2)} !important`,
    cursor: 'pointer',
    display: 'flex',
  },
  primary_color: {
    color: `${theme.palette.Blue[600]} !important`,
  },
  rightArrowIcon: {
    marginTop: 2,
  },
}));
