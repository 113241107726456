import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { AlertDialog } from 'components/AlertDialog';
import { useLenders } from 'store/lenders/hooks';

interface DeleteLenderModalProps {
  open: boolean;
  toggleOpen: () => void;
}

const DeleteLenderModal: FC<DeleteLenderModalProps> = ({ open, toggleOpen }) => {
  const { t } = useTranslation();
  const { loading } = useLenders(() => {
    if (open) toggleOpen();
  });

  const onDelete = () => {};

  const onCancel = () => {
    toggleOpen();
  };

  return (
    <AlertDialog
      open={open}
      dialogContentTitle={t('pages.lender.admin.deleteLender.title')}
      dialogContentText={t('pages.lender.admin.deleteLender.description')}
      handleCancel={onCancel}
      handleConfirm={onDelete}
      loading={loading}
      confirmButtonTitle={t('pages.lender.admin.deleteLender.confirm')}
    />
  );
};

export default DeleteLenderModal;
