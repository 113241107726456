import { ChangeEvent } from 'react';
import { Box, Select } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import clsx from 'clsx';

import useCommonStyles from 'core/styles';
import { CustomLabel } from '../CustomLabel';
import useStyles from './SelectInput.styles';

type OptionValue = string | number;

interface Option<T extends OptionValue> {
  value: T;
  label: string;
}

interface SelectInputProps<T extends OptionValue> {
  label?: string;
  labelClassName?: string;
  selectClassName?: string;
  options: Option<T>[];
  value: T | string;
  onSelect: (value: T) => void;
}

const SelectInput = <T extends OptionValue>({
  label,
  labelClassName,
  selectClassName,
  options,
  value,
  onSelect,
}: SelectInputProps<T>): JSX.Element => {
  const commonClasses = useCommonStyles();
  const classes = useStyles();

  const onChange = (
    event: ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => {
    onSelect(event.target.value as T);
  };

  return (
    <Box>
      {label && <CustomLabel title={label} labelClassName={labelClassName} />}

      <Box className={clsx([commonClasses.selectContainer, classes.selectContainer])}>
        <Select
          native
          IconComponent={() => <ExpandMore className={commonClasses.selectExpandIcon} />}
          variant="outlined"
          value={value}
          onChange={onChange}
          className={selectClassName}
        >
          {options.map((option) => {
            return (
              <option key={`option${option.value}`} value={option.value}>
                {option.label}
              </option>
            );
          })}
        </Select>
      </Box>
    </Box>
  );
};

SelectInput.defaultProps = {
  label: '',
  labelClassName: '',
  selectClassName: '',
};

export default SelectInput;
