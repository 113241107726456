import {
  CreateAdminUser,
  Email,
  LenderUpload,
  MakeOfferData,
  OfferProductType,
  RuleEnginQuery,
  RuleEngineRule,
  UpdateOfferData,
} from 'store/admin/types';
import { apiCall } from 'http/index';
import { AccountType, ApplicationDetails, ApplicationStatus, OfferStatus } from 'store/applications/types';
import {
  ApplicationNoteType,
  CompanyDocumentType,
  DocUploadResponse,
  RejectionReasonTag,
  ApiLender,
  AdminCreateApplication,
  CreateAccountOwner,
  SettingName,
  Setting,
  EmailType,
} from 'core/types';

export const makeAnOffer = async (offer: MakeOfferData): Promise<null> =>
  apiCall({ method: 'post', url: '/offers', data: offer });

export const updateApplicationStatus = async (id: string, appStatus: ApplicationStatus): Promise<null> =>
  apiCall({ method: 'put', url: `/admin/applications/${id}/status`, data: { application_status: appStatus } });

export const downloadDocument = async (id: string): Promise<Blob> =>
  apiCall({ method: 'get', url: `/admin/documents/${id}/download`, responseType: 'blob', getRawData: true });

export const updateOfferDetails = async (id: string, details: UpdateOfferData): Promise<null> =>
  apiCall({ method: 'put', url: `/offers/${id}`, data: details });

export const updateOfferStatus = async (
  id: string,
  offer_status: OfferStatus,
  rejection_reason?: string,
  rejection_reason_tags?: RejectionReasonTag[],
): Promise<null> =>
  apiCall({
    method: 'put',
    url: `/offers/${id}/status`,
    data: { offer_status, rejection_reason, rejection_reason_tags },
  });

export const rejectApplication = async (
  id: string,
  rejection_reason?: string,
  rejection_reason_tags?: RejectionReasonTag[],
): Promise<null> =>
  apiCall({
    method: 'put',
    url: `/admin/applications/${id}/reject`,
    data: { rejection_reason, rejection_reason_tags },
  });

export const followUpApplication = async (
  id: string,
  documents_requested: CompanyDocumentType[],
  notes?: string,
  offer_id?: string,
): Promise<null> =>
  apiCall({
    method: 'post',
    url: `/applications/${id}/follow-up`,
    data: { documents_requested, notes, offer_id },
  });

export const uploadLenders = async (lenders: LenderUpload[]): Promise<null> =>
  apiCall({ method: 'post', url: '/admin/lenders/upload', data: { lenders } });

export const introduceLender = async (id: string, lender_id: string, product_type: OfferProductType): Promise<null> =>
  apiCall({ method: 'post', url: `/admin/applications/${id}/lender-introduction`, data: { lender_id, product_type } });

export const uploadCompanyDocument = async (companyId: string, formData: FormData): Promise<DocUploadResponse> =>
  apiCall({
    method: 'post',
    url: `/admin/documents/${companyId}`,
    data: formData,
    headers: { 'content-type': 'multipart/form-data' },
  });

export const deleteDocument = async (companyId: string, documentId: string): Promise<null> =>
  apiCall({ method: 'delete', url: `/admin/documents/${companyId}/${documentId}` });

export const setAssignee = async (application_id: string, user_id: string | undefined): Promise<null> =>
  apiCall({ method: 'put', url: `/applications/${application_id}/assignee`, data: { user_id } });

export const updateApplicationNotes = async (
  id: string,
  notes: string,
  note_type: ApplicationNoteType,
): Promise<null> => apiCall({ method: 'put', url: `/admin/applications/${id}/notes`, data: { notes, note_type } });

export const downloadMergedDocument = async (appId: string, doc_type: CompanyDocumentType): Promise<Blob> =>
  apiCall({
    method: 'get',
    url: `/applications/${appId}/documents`,
    params: { doc_type },
    responseType: 'blob',
    getRawData: true,
  });

export const gatherOffers = async (companyId: string, lenders: ApiLender[]): Promise<null> =>
  apiCall({ method: 'post', url: `/admin/companies/${companyId}/gather_offers`, data: { lenders } });

export const getRulesEngine = async (params: RuleEnginQuery): Promise<RuleEngineRule[]> =>
  apiCall({ method: 'get', url: '/admin/rule_engine', params });

export const updateApplication = async (id: string, update: Partial<ApplicationDetails>): Promise<null> =>
  apiCall({ method: 'put', url: `/admin/applications/${id}`, data: update });

export const sendEmail = async (applicationId: string, formData: FormData): Promise<null> =>
  apiCall({
    method: 'post',
    url: `/admin/applications/${applicationId}/email`,
    data: formData,
    headers: { 'content-type': 'multipart/form-data' },
  });

export const getEmails = async (
  applicationId: string,
  type: EmailType,
  filter?: { start: Date; end: Date },
): Promise<Email[]> =>
  apiCall({ method: 'get', url: `/admin/applications/${applicationId}/emails`, params: { type, ...filter } });

export const applicationAuth = async (partner_id: string, company_id: string, email: string): Promise<string> => {
  const data = await apiCall<{ access_token: string }>({
    method: 'post',
    url: '/admin/applications/auth',
    data: { partner_id, company_id, email },
  });
  return data.access_token;
};

export const syncCompanyTransactions = async (companyId: string, providerId: string): Promise<null> =>
  apiCall({ method: 'get', url: `/admin/companies/${companyId}/sync_transactions/${providerId}` });

export const updateRule = async (id: string, update: { active?: boolean; value?: string }): Promise<null> =>
  apiCall({ method: 'put', url: `/admin/rule_engine/${id}`, data: update });

export const createAdmin = async (payload: CreateAdminUser): Promise<{ id: string; email: string }> =>
  apiCall({ method: 'post', url: '/admin', data: payload });

export const deleteAdmin = async (id: string): Promise<null> => apiCall({ method: 'delete', url: `/admin/${id}` });

export const createPreAuthLink = async (payload: AdminCreateApplication): Promise<null> =>
  apiCall({ method: 'post', url: 'admin/pre_auth', data: payload });

export const updateUserEnabled = async (id: string, active: boolean, accountType: AccountType): Promise<null> => {
  const accountPath = accountType === AccountType.PARTNER ? 'partners' : 'lenders';
  return apiCall({ method: 'put', url: `/admin/${accountPath}/${id}/active`, data: { active } });
};

export const createAccountOwner = async (
  id: string,
  accountType: AccountType,
  ownerDetails: CreateAccountOwner,
): Promise<null> => {
  const accountPath = accountType === AccountType.PARTNER ? 'partners' : 'lenders';
  return apiCall({ method: 'post', url: `/admin/${accountPath}/${id}/owner`, data: ownerDetails });
};

export const getSettingByName = async (name: SettingName): Promise<Setting | null> =>
  apiCall({ method: 'get', url: '/admin/settings', params: { name } });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createSetting = async (name: SettingName, setting: Record<string, any>): Promise<Setting> =>
  apiCall({ method: 'post', url: '/admin/settings', data: { name, data: setting } });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateSetting = async (id: string, setting: Record<string, any>): Promise<null> =>
  apiCall({ method: 'put', url: `/admin/settings/${id}`, data: { data: setting } });

export const createApplication = async (
  payload: AdminCreateApplication,
): Promise<{ access_token: string; public_key: string }> =>
  apiCall({ method: 'post', url: 'admin/applications', data: payload });

export const updateActiveAutomatedEmails = async (id: string, active: boolean): Promise<null> => {
  return apiCall({ method: 'put', url: `/admin/applications/${id}/automated_emails`, data: { active } });
};
