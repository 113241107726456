import { FC } from 'react';
import { Box, TextField } from '@material-ui/core';
import { clsx } from 'clsx';
import { FieldProps } from 'formik';
import { Typography } from '@mui/material';
import useStyles from './CustomText.styles';

interface CustomTextFieldProps extends FieldProps {
  title: string;
  placeholder?: string;
  tooltipMessage?: string;
  InputProps?: Record<string, unknown>;
}

const CustomTextField: FC<CustomTextFieldProps> = ({ title, placeholder, form, field, tooltipMessage, ...rest }) => {
  const hasError = form.touched[field.name] && form.errors[field.name];
  const InputProps = rest.InputProps || {};
  const classes = useStyles();
  return (
    <Box>
      {title && (
        <Typography
          variant="text_MD"
          component="label"
          className={clsx([classes.fontJost, classes.fontWeight_500, classes.gray_500, classes.inputLbl])}
        >
          {title}
        </Typography>
      )}

      <TextField
        className={clsx([classes.inputBox])}
        type="text"
        placeholder={placeholder}
        // error={form.touched[field.name] && !!form.errors[field.name]}
        helperText={hasError ? <Box className={classes.errorText}>{form.errors[field.name]}</Box> : null}
        InputProps={{ ...InputProps }}
        variant="outlined"
        {...field}
        {...rest}
        value={field.value || ''}
      />
    </Box>
  );
};

export default CustomTextField;
