import { FC, useState, ChangeEvent } from 'react';
import { Box, FormControlLabel, CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { updateUserEnabled as updateUserEnabledApi } from 'http/admin';
import { AccountType } from 'store/applications/types';
import { useLenders } from 'store/lenders/hooks';
import { usePartners } from 'store/partners/hooks';
import { IOSSwitch } from 'pages/home/Home';
import useStyles from './UserSwitchEnabled.styles';

interface UserSwitchEnabledProps {
  id: string;
  initialActive: boolean;
  accountType: AccountType;
  setSuccess: (success: string | boolean) => void;
  setError: (error: string | boolean) => void;
  successMessage?: string;
}

const UserSwitchEnabled: FC<UserSwitchEnabledProps> = ({
  id,
  initialActive,
  accountType,
  setSuccess,
  setError,
  successMessage,
}) => {
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(initialActive);
  const classes = useStyles();
  const { t } = useTranslation();
  const { getAllLenders } = useLenders();
  const { getAllPartners } = usePartners();

  const toggleActive = async (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setLoading(true);
    try {
      await updateUserEnabledApi(id, checked, accountType);
      setActive(checked);
      setSuccess(successMessage ?? true);
      if (accountType === AccountType.LENDER) {
        getAllLenders();
      } else if (accountType === AccountType.PARTNER) {
        getAllPartners();
      }
    } catch (err) {
      setError((err as Error).message ?? true);
    }
    setLoading(false);
  };

  return (
    <Box className={classes.switchContainer}>
      <FormControlLabel control={<IOSSwitch checked={active} onChange={toggleActive} disabled={loading} />} label="" />
      {active ? t('global.states.enabled') : t('global.states.disabled')}

      {loading && <CircularProgress size={24} className={classes.loader} />}
    </Box>
  );
};

export default UserSwitchEnabled;
