import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loaderContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    left: 0,
  },
  text: {
    fontSize: theme.typography.pxToRem(24),
    lineHeight: theme.typography.pxToRem(36),
    fontWeight: 600,
    color: theme.palette.greyText.main,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(21.5),
      lineHeight: theme.typography.pxToRem(32),
    },
  },
}));
