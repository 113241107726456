import { Alert, Snackbar } from '@mui/material';
import { useEffect } from 'react';
import useStyles from './CustomToast.styles';
import { ReactComponent as RightIcon } from '../../assets/icons/right_toast.svg';

const CustomToast = ({
  open,
  onClose,
  message,
}: {
  open: boolean;
  onClose?: (val: boolean) => void;
  message: string;
}) => {
  const classes = useStyles();
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        onClose?.(false);
      }, 5000);
    }
    // eslint-disable-next-line
  }, []);
  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={() => onClose?.(false)}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      className={classes.snackbarTheme}
    >
      <Alert
        icon={<RightIcon />}
        onClose={() => onClose?.(false)}
        sx={{ width: '100%' }}
        className={classes.snackbarAlertTheme}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default CustomToast;
