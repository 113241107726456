import { getIntegrationData } from 'http/intigration';
import { FC, useEffect, useState } from 'react';
import { Avatar, Box, Card, CardContent, FormControlLabel, Grid, Link, Stack, Switch, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import routes from 'router/routes';
import { Header } from 'components/Header/Header';
import theme from 'core/theme-extended';
import useCommonStyles from '../../assets/css/Common.styles';
import useStyles from './Integrations.styles';
import SlackIcon from '../../assets/icons/slack_icon.svg';
import StripeKYCIcon from '../../assets/icons/stripe_kyc.svg';
import GoogleCaptchaIcon from '../../assets/icons/google_captcha.svg';
import ShopifyIcon from '../../assets/icons/shopify.svg';
import CalendlyIcon from '../../assets/icons/calendly.svg';
import OpenBankingIcon from '../../assets/icons/open_banking.svg';
import HeronDataIcon from '../../assets/icons/heron_data.svg';
import CompaniesHouseIcon from '../../assets/icons/companies_house.svg';
import YouLendIcon from '../../assets/icons/youlend.svg';
import FleximizeIcon from '../../assets/icons/fleximize.svg';
import IwocaIcon from '../../assets/icons/iwoca.svg';
import CreditSafeIcon from '../../assets/icons/credit_safe.svg';
//
export const integrationsData = [
  { title: 'Slack', icon: SlackIcon },
  { title: 'Stripe KYC', icon: StripeKYCIcon },
  { title: 'Google Captcha', icon: GoogleCaptchaIcon },
  { title: 'Shopify', icon: ShopifyIcon },
  { title: 'Calendly', icon: CalendlyIcon },
  { title: 'Open Banking', icon: OpenBankingIcon },
  { title: 'Heron Data', icon: HeronDataIcon },
  { title: 'Companies House', icon: CompaniesHouseIcon },
  { title: 'Youlend', icon: YouLendIcon },
  { title: 'Fleximize', icon: FleximizeIcon },
  { title: 'Iwoca', icon: IwocaIcon },
  { title: 'Credit Safe', icon: CreditSafeIcon },
];

export const MaterialUISwitchLg = styled(Switch)(() => ({
  width: 34,
  height: 18,
  padding: 0,
  margin: '0 0 0 8px',
  '& .MuiSwitch-switchBase': {
    margin: '2px',
    padding: 0,
    transform: 'translateX(0px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(16px)',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#039855',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#fefeff',
    width: 14,
    height: 14,
  },
  '& .MuiSwitch-track': {
    width: '100%',
    height: '100%',
    borderRadius: '100px',
    backgroundColor: '#98a2b3',
    top: '50%',
    position: 'absolute',
    transform: 'translateY(-50%)',
    left: 0,
  },
}));

interface Secret {
  displayName: string;
  name: string;
  value: string;
}

export interface IntegrationConfig {
  integration: string;
  secrets?: Secret[];
  description?: string;
  active_indicator_key?: string;
}

const Integrations: FC = () => {
  const [integrationList, setIntegrationList] = useState<IntegrationConfig[]>([]);
  const classes = useStyles();
  const history = useHistory();
  const commonClasses = useCommonStyles();

  const getIntegrationApiData = async () => {
    await getIntegrationData().then((response) => setIntegrationList(response));
  };

  useEffect(() => {
    getIntegrationApiData();
  }, []);

  return (
    <Box>
      <Header name="Integrations" />
      <Box sx={{ mt: 6, px: 4 }}>
        <Grid container>
          <Grid item xs={12} lg={12} xl={12}>
            <Box sx={{ px: 8, py: 6, height: '100%' }} className={clsx([commonClasses.cardShadow])}>
              <Box sx={{ mb: 6 }}>
                <Typography
                  className={clsx([commonClasses.fontJost, commonClasses.boxCircleTitle])}
                  component="p"
                  variant="text_MD"
                  sx={{ mb: 2 }}
                >
                  Integrate tools you use everyday with Business Score
                </Typography>
              </Box>

              <Grid container spacing={6}>
                {integrationList?.map((data) => {
                  const handleIntegration = (value?: string, values?: Secret[]) => {
                    let isValid = false;
                    values?.forEach((d: Secret) => {
                      if (d?.name === value) {
                        isValid = d?.value !== 'false';
                      }
                    });
                    return isValid;
                  };
                  return (
                    <Grid item xs={12} md={4}>
                      <Card className={clsx([classes.integrateToolBox])}>
                        <CardContent sx={{ p: 0 }} className={classes.integrateToolBoxInner}>
                          <Box sx={{ px: 6, pt: 4, mb: 10 }}>
                            <Stack
                              sx={{ mb: 2 }}
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                              spacing={2}
                            >
                              <Box>
                                <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={4}>
                                  <Avatar
                                    variant="square"
                                    alt={data?.integration}
                                    src={`https://placehold.co/800?text=${data?.integration}&font=roboto`}
                                    sx={{ width: 64, height: 64 }}
                                  />

                                  <Box className={commonClasses.boxCircleText}>
                                    <Typography
                                      className={clsx([commonClasses.fontJost, commonClasses.boxCircleTitle])}
                                      component="p"
                                      variant="text_MD"
                                      sx={{ mb: 0 }}
                                    >
                                      {data?.integration}
                                    </Typography>
                                  </Box>
                                </Stack>
                              </Box>
                              <Box>
                                <FormControlLabel
                                  sx={{ m: 0 }}
                                  control={<MaterialUISwitchLg defaultChecked />}
                                  checked={
                                    data?.active_indicator_key
                                      ? handleIntegration(data?.active_indicator_key, data?.secrets)
                                      : true
                                  }
                                  disabled={!data?.active_indicator_key}
                                  label=""
                                />
                              </Box>
                            </Stack>
                            <Typography
                              className={clsx([commonClasses.fontJost])}
                              component="p"
                              variant="text_SM"
                              sx={{ mb: 0 }}
                            >
                              Send notifications to {data?.integration?.toLocaleLowerCase()} channels
                            </Typography>
                          </Box>
                          <Box sx={{ px: 6, py: 4, borderTop: 1, borderColor: '#EAECF0', mt: 'auto' }}>
                            <Stack direction="row" justifyContent="end" alignItems="center" spacing={4}>
                              <Box onClick={() => history.push({ pathname: routes?.integration, state: { data } })}>
                                <Link
                                  href="/"
                                  underline="none"
                                  className={commonClasses.cursorPointer}
                                  component="text"
                                  color={theme.palette.Blue[600]}
                                >
                                  View Integration
                                </Link>
                              </Box>
                            </Stack>
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Integrations;
