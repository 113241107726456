import { ChangeEvent, Dispatch, FC, SetStateAction } from 'react';
import { Typography, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import clsx from 'clsx';

import { CustomDatePicker } from 'components/inputs';
import { overviewFilterOptions } from 'core/constants';
import CustomSelectBox from 'components/CustomSelect/CustomSelect';
import { OverviewDateRange } from 'core/types';
import { getDateRangeByFilter } from 'utils';
import useStyles from './OverviewFilter.styles';

interface OverviewFilterProps {
  dateRange: OverviewDateRange;
  setDateRange: Dispatch<SetStateAction<OverviewDateRange>>;
  fromDate: Date;
  setFromDate: Dispatch<SetStateAction<Date>>;
  toDate: Date;
  setToDate: Dispatch<SetStateAction<Date>>;
}

const OverviewFilter: FC<OverviewFilterProps> = ({
  dateRange,
  setDateRange,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const onFromDateChange = (date: Date) => {
    setFromDate(moment(date).startOf('day').toDate());
  };

  const onToDateChange = (date: Date) => {
    setToDate(moment(date).endOf('day').toDate());
  };

  const handleDateRangeChange = (
    event: ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => {
    const dateRangeFilter = event.target.value as OverviewDateRange;
    setDateRange(dateRangeFilter);
    const { start, end } = getDateRangeByFilter(dateRangeFilter);
    setFromDate(start);
    setToDate(end);
  };

  return (
    <Box className={classes.dateRangeContainer}>
      <Box className={classes.selectContainer}>
        <CustomSelectBox
          options={overviewFilterOptions}
          onChange={handleDateRangeChange}
          value={dateRange}
          name="date-filter"
          className={[classes?.selectBox36]}
          formik={false}
          mainDivClassShow={false}
        />
      </Box>
      <Box className={classes.datePickerContainer}>
        <Typography className={clsx([classes.subtitle, classes.semibold])}>
          {t('pages.dashboard.filters.date.from')}:
        </Typography>
        <CustomDatePicker
          className={classes.datePicker_wrap}
          inputClassName={classes.home_datePicker}
          name="fromDate"
          value={fromDate || null}
          onChange={onFromDateChange}
          isShowIcon="true"
          collapsed
          maxDate={toDate}
          openTo="date"
          format="DD MMMM YYYY"
        />

        <Typography className={clsx([classes.subtitle, classes.semibold])}>
          {t('pages.dashboard.filters.date.to')}:
        </Typography>
        <CustomDatePicker
          name="toDate"
          isShowIcon="true"
          className={classes.datePicker_wrap}
          inputClassName={classes.home_datePicker}
          value={toDate || null}
          onChange={onToDateChange}
          openTo="date"
          minDate={fromDate}
          collapsed
          format="DD MMMM YYYY"
        />
      </Box>
    </Box>
  );
};

export default OverviewFilter;
