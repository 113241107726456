import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  listItemContainer: {
    padding: 0,
    '&.MuiListItem-root': {
      padding: 0,
    },
  },
  routerLink: {
    textDecoration: 'none',
  },
  linkContainer: {
    marginBottom: theme.spacing(2),
    height: 40,
    '&:hover': {
      backgroundColor: '#344054',
    },
    '&.Mui-selected': {
      backgroundColor: '#344054',
      borderLeft: `${theme.spacing(0.5)} solid #FCFCFD`,
    },
    '&.Mui-selected:hover': {
      backgroundColor: '#344054',
    },
    '&.MuiButtonBase-root': {
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
    },
    '&.MuiButtonBase-root&.Mui-selected': {
      paddingLeft: `calc(${theme.spacing(3)} - ${theme.spacing(0.5)})`,
    },
  },
  linkButtonIcon: {
    minWidth: '25px',
    display: 'flex',
    justifyContent: 'center',
    paddingRight: theme.spacing(1.5),
  },
  linkButtonText: {
    '& .MuiTypography-root': {
      color: '#F2F4F7',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(24),
    },
  },
  notificationText: {
    '& .MuiTypography-root': {
      color: '#F2F4F7',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(24),
    },
  },
}));

export default useStyles;
