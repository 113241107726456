import { useEffect } from 'react';

type Listener = () => void;

const loadScript = (id: string, name: string, src: string, onLoad?: Listener) => {
  if (!src) return;

  const d = document;
  const existingScript = document.getElementById(id);

  if (!existingScript) {
    const script = d.createElement('script');
    script.id = id;
    script.type = 'text/javascript';
    script.async = true;
    script.src = src;
    script.onload = () => {
      onLoad?.();
    };
    d.getElementsByTagName('body')[0].appendChild(script);
  }
};

const unloadScript = (id: string, onUnload?: Listener) => () => {
  onUnload?.();
  const existingScript = document.getElementById(id);
  if (existingScript) {
    existingScript.remove();
  }
};

const useScript = (id: string, name: string, src: string, onLoad?: Listener, onUnload?: Listener): void => {
  useEffect(() => {
    loadScript(id, name, src, onLoad);
    return unloadScript(id, onUnload);
  }, [id, name, src, onLoad, onUnload]);
};

export default useScript;
