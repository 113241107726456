import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  select: {
    root: {
      borderRadius: '8px !important',
      '&:hover:not($disabled):before': {
        borderColor: '#D0D5DD !important',
      },
      '&.Mui-focused:before': {
        borderColor: '#D0D5DD !important',
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#D0D5DD !important',
      borderWidth: '1px !important',
    },
    '&:focus-within .MuiOutlinedInput-notchedOutline': {
      borderColor: '#D0D5DD !important',
      borderWidth: '1px !important',
    },
    '& .MuiInputBase-input': {
      padding: '13.5px 14px !important',
      backgroundColor: 'transparent !important',
    },
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  selectBoxDropdown: {
    color: `${theme.palette.Gray[700]} !important`,
    fontWeight: 400,
    width: '100%',
    height: '46px',
    borderRadius: '8px !important',
  },
  fontWeight_500: {
    fontWeight: '500 !important',
  },
  formInlineLblValue: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  formInlineLblValue_lbl: {
    position: 'relative',
  },
  fontJost: {
    fontFamily: 'jost !important',
  },
  gray_500: {
    color: `${theme.palette.Gray[500]} !important`,
  },
  inputLbl: {
    lineHeight: '1.75rem !important',
    marginBottom: '0.5rem !important',
    display: 'block !important',
  },
  formInlineLblValue_value: {
    position: 'relative',
    width: '100%',
  },
  formLblValue: {
    marginBottom: '1.5rem !important',
  },
}));

export default useStyles;
