import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { Box, Button, Chip, Grid, FormHelperText, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { getWebhookUrls as getWebhookUrlsApi, saveWebhookUrl as saveWebhookUrlApi } from 'http/partners';
import { CustomLabel, CustomTextField } from 'components/inputs';
import { LoadingButton } from 'components/buttons';
import { usePartners } from 'store/partners/hooks';
import { WebhookUrl } from 'store/partners/types';
import { ApplicationStatus, OfferStatus } from 'store/applications/types';
import { useAuth } from 'store/auth/hooks';
import useStyles from './Webhooks.styles';
import { Webhook } from '../Webhook';
import { OfferEventModal } from '../OfferEventModal';

// eslint-disable-next-line
const websiteRegExp = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;

interface FormValues {
  url: string | null;
  offerStatus: OfferStatus[];
  applicationStatus: ApplicationStatus[];
}

const Webhooks: FC = () => {
  const [addNewEndpoint, setAddNewEndpoint] = useState(false);
  const [loading, setLoading] = useState(false);
  const [webhooks, setWebhooks] = useState<WebhookUrl[]>([]);
  const [testEventOpen, setTestEventOpen] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const { setError, setSuccess } = usePartners();
  const { isPartnerAdmin } = useAuth();

  const getWebhookUrls = useCallback(async () => {
    setLoading(true);
    try {
      const data = await getWebhookUrlsApi();
      setWebhooks(data);
    } catch (err) {
      setError((err as Error)?.message ?? true);
    }
    setLoading(false);
  }, [setError]);

  useEffect(() => {
    getWebhookUrls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TODO APP STATUS : Save app status
  const saveWebhookUrl = async (url: string, offerStatus: OfferStatus[], applicationStatus: ApplicationStatus[]) => {
    setLoading(true);
    try {
      const data = await saveWebhookUrlApi(url, offerStatus, applicationStatus);
      setWebhooks((prevData) => [...prevData, data]);
      setAddNewEndpoint(false);
      setSuccess(t('pages.api.webhooks.messages.saveSuccess') as string);
    } catch (err) {
      setError((err as Error)?.message ?? true);
    }
    setLoading(false);
  };

  const webhookSchema = useMemo(
    () =>
      yup.object({
        url: yup
          .string()
          .matches(websiteRegExp, t('pages.api.webhooks.inputs.url.error'))
          .required(t('pages.api.webhooks.inputs.url.required'))
          .nullable(),
        offerStatus: yup.array(yup.string()).min(1, t('pages.api.webhooks.inputs.offerStatus.error')).nullable(),
        applicationStatus: yup
          .array(yup.string())
          .min(1, t('pages.api.webhooks.inputs.applicationStatus.error'))
          .nullable(),
      }),
    [t],
  );

  const toggleAddNewEndpoint = () => setAddNewEndpoint((prevState) => !prevState);

  const toggleTestEventOpen = () => setTestEventOpen((prevState) => !prevState);

  const onSubmit = async (values: FormValues) => {
    if (values.url) saveWebhookUrl(values.url, values.offerStatus, values.applicationStatus);
  };

  const initialValues: FormValues = { url: null, offerStatus: [], applicationStatus: [] };

  return (
    <Box>
      <Typography gutterBottom>{t('pages.api.webhooks.configure.title')}</Typography>

      {webhooks.length > 0 && webhooks.map((webhook) => <Webhook key={webhook.id} {...webhook} />)}

      {isPartnerAdmin && addNewEndpoint && (
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={webhookSchema}>
          {({ values, errors, touched, handleChange, handleSubmit, setFieldValue }) => {
            return (
              <Form noValidate>
                <Grid container spacing={3} className={classes.urlContainer}>
                  <Grid item>
                    <Field
                      autoFocus
                      id="url"
                      fullWidth
                      component={CustomTextField}
                      name="url"
                      value={values.url}
                      onChange={handleChange}
                      title={t('pages.api.webhooks.inputs.url.label')}
                      className={classes.textInput}
                      placeholder={t('pages.api.webhooks.inputs.url.placeholder')}
                      disabled={loading}
                      variant="standard"
                    />
                  </Grid>

                  <Grid item>
                    <CustomLabel title={t('pages.api.webhooks.inputs.offerStatus.label')} />

                    <Box className={classes.offerStatusContainer}>
                      {Object.values(OfferStatus).map((status) => {
                        const alreadySelected = values.offerStatus.find((v) => v === status);
                        return (
                          <Chip
                            key={status}
                            color={alreadySelected ? 'primary' : 'default'}
                            label={status}
                            className={classes.offerStatusOption}
                            onClick={() => {
                              if (alreadySelected) {
                                setFieldValue(
                                  'offerStatus',
                                  values.offerStatus.filter((v) => v !== status),
                                );
                              } else {
                                setFieldValue('offerStatus', [...values.offerStatus, status]);
                              }
                            }}
                          />
                        );
                      })}
                    </Box>

                    <CustomLabel title={t('pages.api.webhooks.inputs.applicationStatus.label')} />
                    <Box className={classes.offerStatusContainer}>
                      {Object.values(ApplicationStatus).map((status) => {
                        const alreadySelected = values.applicationStatus.find((v) => v === status);

                        return (
                          <Chip
                            key={status}
                            color={alreadySelected ? 'primary' : 'default'}
                            label={status}
                            className={classes.offerStatusOption}
                            onClick={() => {
                              if (alreadySelected) {
                                setFieldValue(
                                  'applicationStatus',
                                  values.applicationStatus.filter((v) => v !== status),
                                );
                              } else {
                                setFieldValue('applicationStatus', [...values.applicationStatus, status]);
                              }
                            }}
                          />
                        );
                      })}
                    </Box>

                    {touched.offerStatus && errors.offerStatus && (
                      <FormHelperText error>{errors.offerStatus}</FormHelperText>
                    )}
                    {touched.applicationStatus && errors.applicationStatus && (
                      <FormHelperText error>{errors.applicationStatus}</FormHelperText>
                    )}
                  </Grid>

                  <Grid item>
                    <Box display="flex">
                      <Button
                        variant="contained"
                        color="default"
                        onClick={toggleAddNewEndpoint}
                        className={classes.saveButton}
                        style={{ marginRight: 8 }}
                      >
                        {t('global.buttons.cancel')}
                      </Button>

                      <LoadingButton
                        variant="contained"
                        color="primary"
                        onClick={() => handleSubmit()}
                        containerClassName={classes.saveButton}
                        loading={loading}
                      >
                        {t('global.buttons.save')}
                      </LoadingButton>
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      )}

      {isPartnerAdmin && !addNewEndpoint && (
        <Box className={classes.actionsContainer}>
          <Button variant="contained" color="primary" onClick={toggleAddNewEndpoint}>
            {t('pages.api.webhooks.buttons.addEndpoint')}
          </Button>

          {webhooks.length > 0 && (
            <LoadingButton variant="contained" color="primary" onClick={toggleTestEventOpen} loading={loading}>
              {t('pages.api.webhooks.buttons.testEndpoint')}
            </LoadingButton>
          )}
        </Box>
      )}

      <OfferEventModal open={testEventOpen} toggleOpen={toggleTestEventOpen} />
    </Box>
  );
};

export default Webhooks;
