import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 0,
    width: '100%',
  },
  submitButton: {
    marginTop: theme.spacing(2),
    maxWidth: 120,
    alignSelf: 'flex-end',
  },
  savedNotesTextLabel: {
    marginLeft: 0,
    textAlign: 'left',
  },
  savedNotesTextArea: {
    width: '100%',
  },
  editNotesButton: {
    fontSize: '20px',
    transition: 'opacity 0.3s',
  },
  saveNotesButton: {
    maxWidth: 120,
    alignSelf: 'flex-end',
    marginRight: '10px',
  },
  cancelNotesButton: {
    maxWidth: 120,
    marginRight: theme.spacing(2),
    alignSelf: 'flex-end',
  },
  textareaWrap: {
    position: 'relative',
  },
  textareaLblWrap: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '0.5rem',
  },
  textareaLblWrap_lbl: {
    position: 'relative',
  },
  textareaLblWrap_action: {
    position: 'relative',
  },
  textareaCustom: {
    width: '100%',
    padding: '0rem !important',
  },
  textareaWrapInner: {
    marginBottom: '1rem',
  },
  gray_500: {
    color: `${theme.palette.Gray[500]} !important`,
  },
  fontWeight_500: {
    fontWeight: '500!important',
  },
  width_100: {
    width: '100% !important',
  },
  dashboardPaletteLinkItem: {
    display: 'inline-flex !important',
    alignItems: 'center !important',
    fontSize: '1rem !important',
    lineHeight: '1.8rem !important',
    color: `${theme.palette.Blue[600]} !important`,
    fontWight: '600 !important',
    textDecoration: 'none !important',
  },
  dashboardPaletteLinkIcon: {
    marginLeft: '0.5rem',
  },
  fontJost: {
    fontFamily: 'jost !important',
  },
  fontWeight_400: {
    fontWeight: '400!important',
  },
  primary_600: {
    color: `${theme.palette.Blue[600]} !important`,
  },
  modalTextArea: {
    borderRadius: '0.5rem',
    borderColor: `${theme.palette.Gray[300]} !important`,
    outline: 'none !important',
  },
}));
