import { useSelector, shallowEqual } from 'react-redux';
import { useActionCreator, usePrevious } from 'hooks';
import { useEffect } from 'react';

import { RootState } from 'store/types';
import { getApplicationOffer } from './actions';
import { ApplicationsOfferReturnHook } from './types';

export const useApplicationsOffer = (onSuccess?: () => void): ApplicationsOfferReturnHook => {
  const applicationsOffersState = useSelector((state: RootState) => state.applicationOffer, shallowEqual);
  const prevLoading = usePrevious(applicationsOffersState.loading);

  useEffect(() => {
    if (applicationsOffersState && !applicationsOffersState.loading && !applicationsOffersState.error && onSuccess) {
      onSuccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationsOffersState.error, applicationsOffersState.loading, onSuccess, prevLoading]);

  return {
    ...applicationsOffersState,
    getApplicationOffer: useActionCreator(getApplicationOffer),
  };
};
