import { FC, useState, useMemo, useEffect } from 'react';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';
import { AlertDialog } from 'components/AlertDialog';
import CustomFilter from 'components/CustomTable/CustomFilter/CustomFilter';
import CustomTable from 'components/CustomTable/CustomTable';
import { UpdateUserRoleModal } from 'components/UpdateUserRoleModal';
import { ColumnHeader, customHeadRender } from 'components/table';
import { ROWS_PER_TABLE_PAGE, lenderRolesOptions, partnerRolesOptions } from 'core/constants';
import { AccountRoles, UserQuery, UserRole, UsersTableColumnNames } from 'core/types';
import { mapUserRoleToName } from 'core/utils';
import { usePrevious } from 'hooks';
import { getUsers } from 'http/users';
import { MUIDataTableCustomHeadRenderer, MUIDataTableMeta } from 'mui-datatables';
import { useAccount } from 'store/account/hooks';
import { useAuth } from 'store/auth/hooks';
import moment from 'moment';
import { downloadCSVFile } from 'pages/leads/components/CreateApplicationModal/utils';
import useStyles from './AccountUsers.styles';

interface AccountUsersProps {
  partnerId?: string;
  lenderId?: string;
  toggle?: boolean;
  width?: number;
}

const AccountUsers: FC<AccountUsersProps> = ({ partnerId, lenderId, toggle, width = 0 }) => {
  const [page, setPage] = useState<number>(1);
  const [selectedUser, setSelectedUser] = useState<null | { fullName: string; id: string }>(null);
  const [selectedUserRole, setSelectedUserRole] = useState<{ id: string; role: AccountRoles } | null>(null);

  const classes = useStyles();
  const { t } = useTranslation();
  const { loading, total, users, account, getAccountUsers, deleteAccountUser } = useAccount(() =>
    setSelectedUser(null),
  );
  const { isPartnerAdmin, isLenderAdmin } = useAuth();
  const accountOwnerId = account?.owner?.id;
  const prevAccountOwnerId = usePrevious(accountOwnerId);

  const usersQuery: UserQuery = useMemo(
    () => ({
      per_page: ROWS_PER_TABLE_PAGE,
      page,
      partner_id: partnerId,
      lender_id: lenderId,
    }),
    [page, lenderId, partnerId],
  );

  const columns = useMemo(
    () => [
      {
        width: width || 220,
        field: UsersTableColumnNames.NAME,
        headerName: t('pages.team.table.columns.name'),
      },
      {
        width: width || 220,
        field: UsersTableColumnNames.EMAIL,
        headerName: t('pages.team.table.columns.email'),
        options: { customHeadRender },
      },
      {
        width: width || 220,
        field: UsersTableColumnNames.ROLE,
        headerName: t('pages.team.table.columns.role'),
        options: {
          customHeadRender,
          customBodyRender:
            isPartnerAdmin || isLenderAdmin
              ? (value: UserRole, tableMeta: MUIDataTableMeta) => {
                  const user = users[tableMeta.rowIndex];
                  const onClick = () => {
                    if (user) setSelectedUserRole({ id: user.id, role: user.role as AccountRoles });
                  };
                  return (
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={onClick}
                      disabled={!user?.can_delete}
                    >
                      {mapUserRoleToName(value)}
                    </Button>
                  );
                }
              : (value: UserRole) => mapUserRoleToName(value),
        },
      },
      {
        width: width || 220,
        field: UsersTableColumnNames.CREATED_AT,
        headerName: t('pages.team.table.columns.createdAt'),
        options: {
          customHeadRender,
          customBodyRender: (value: string) => (value ? moment(value).format('DD/MM/YYYY') : '-'),
        },
      },
      ...(isPartnerAdmin || isLenderAdmin
        ? [
            {
              width: width || 220,
              field: UsersTableColumnNames.REMOVE,
              headerName: '',
              options: {
                customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) => (
                  <ColumnHeader key={`remove-${columnMeta.index}`} title="" empty />
                ),
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                customBodyRender: (_: any, tableMeta: MUIDataTableMeta) => {
                  const user = users[tableMeta.rowIndex];
                  const onClick = () => {
                    if (user) setSelectedUser({ id: user?.id, fullName: user?.fullName });
                  };
                  return (
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={onClick}
                      className={classes.removeButton}
                      disabled={!user?.can_delete}
                    >
                      {t('pages.team.deleteUser.buttons.delete')}
                    </Button>
                  );
                },
              },
            },
          ]
        : []),
    ],
    [classes.removeButton, isLenderAdmin, isPartnerAdmin, t, users, width],
  );

  const downloadCSV = async () => {
    await getUsers(partnerId ? { partner_id: partnerId } : { lender_id: lenderId }).then((res) => {
      const userData = res?.users?.map((d) => {
        return {
          'User Name': `${d?.first_name} ${d?.last_name}`,
          Email: d?.email,
          Role: d?.role,
          CreatedAt: d?.created_at,
        };
      });
      downloadCSVFile(userData, 'BScore_users.csv');
    });
  };
  const toggleFilterModal = () => {};

  const handleDeleteCancel = () => setSelectedUser(null);

  const handleDeleteConfirm = () => {
    if (selectedUser) deleteAccountUser(selectedUser?.id);
  };

  useEffect(() => {
    getAccountUsers(usersQuery);
  }, [page, usersQuery, getAccountUsers, prevAccountOwnerId, accountOwnerId, toggle]);

  return (
    <Box>
      <CustomFilter
        downloadCSV={downloadCSV}
        toggleFilterModal={toggleFilterModal}
        total={total}
        isShowSearch={false}
        page={page}
        isShowFilter={false}
        pageSize={ROWS_PER_TABLE_PAGE}
        setPage={setPage}
      />
      <CustomTable
        columns={columns}
        rows={users}
        loading={loading}
        headerUpperCase={true}
        hideFooterPagination={true}
        NoDataFountMessage="No Data Found"
        disableColumnMenu={true}
      />

      {selectedUserRole && (
        <UpdateUserRoleModal
          open
          toggleOpen={() => setSelectedUserRole(null)}
          roleOptions={isPartnerAdmin ? partnerRolesOptions : lenderRolesOptions}
          userRole={selectedUserRole}
        />
      )}

      {selectedUser && (
        <AlertDialog
          open
          dialogContentTitle={t('pages.team.deleteUser.dialog.title')}
          dialogContentText={t('pages.team.deleteUser.dialog.description', { name: selectedUser?.fullName })}
          handleConfirm={handleDeleteConfirm}
          handleCancel={handleDeleteCancel}
          confirmButtonTitle={t('pages.team.deleteUser.buttons.delete')}
        />
      )}
    </Box>
  );
};

export default AccountUsers;
