import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  formContainer: {
    maxWidth: 500,
    width: '100%',
    padding: theme.spacing(4),
  },
  gridContainer: {
    minHeight: '160px',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  codeInputContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: 'fit-content',
    position: 'relative',
  },
  codeInput: {
    display: 'flex !important',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '100%',
    maxWidth: 280,
    '& input': {
      width: 40,
      height: 54,
      borderRadius: 8,
      borderColor: theme.palette.greyLightBorder.main,
      padding: 0,
      textAlign: 'center',
      fontSize: theme.typography.pxToRem(18),
      lineHeight: theme.typography.pxToRem(24),
      fontWeight: 700,
      marginLeft: 6,
      marginRight: 6,
      borderStyle: 'solid',
      borderWidth: 1,
      outline: 'none',
      '&:focus, &:not([value=""]):not(:focus)': {
        borderColor: theme.palette.primary.main,
        borderWidth: 2,
      },
    },
  },
  codeInputError: {
    '& input': {
      borderColor: theme.palette.error.main,
    },
  },
  codeError: {
    marginTop: 15,
    marginLeft: -14,
    alignSelf: 'flex-start',
    fontSize: theme.typography.pxToRem(11),
    lineHeight: theme.typography.pxToRem(16),
    fontWeight: 700,
    color: theme.palette.error.main,
    '& svg': {
      fontSize: theme.typography.pxToRem(14),
      marginBottom: -3,
      marginRight: 6,
      color: theme.palette.error.main,
    },
  },
  loaderContainer: {
    position: 'absolute',
  },
  button: {
    width: 285,
    height: 46,
    marginTop: theme.spacing(7),
  },
  subtitle: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
    fontWeight: 500,
  },
  primaryText: {
    color: theme.palette.primary.main,
  },
}));
