import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import i18n from 'i18next';

import { UserResponse, UserRole } from 'core/types';
import {
  initAuth as initAuthApi,
  confirmAuth as confirmAuthApi,
  register as registerApi,
  confirmPasswordReset as confirmPasswordResetApi,
  initPasswordReset as initPasswordResetApi,
  registerAccount as registerAccountApi,
} from 'http/auth';
import { getCurrentAuthenticatedUser as getCurrentAuthenticatedUserApi } from 'http/users';
import { setAuthSession } from 'utils';
import { InitAuth, RegisterData, AuthState, AuthSessionDetails, RegisterAccountData } from './types';

export const INIT_AUTH = 'auth/INIT_AUTH';
export const CONFIRM_AUTH = 'auth/CONFIRM_AUTH';
export const LOG_OUT = 'auth/LOG_OUT';
export const SET_ERROR = 'auth/SET_ERROR';
export const REGISTER = 'auth/REGISTER';
export const CONFIRM_PASSWORD_RESET = 'auth/CONFIRM_PASSWORD_RESET';
export const INIT_PASSWORD_RESET = 'auth/INIT_PASSWORD_RESET';
export const GET_AUTH_USER = 'auth/GET_AUTH_USER';
export const SET_SUCCESS = 'auth/SET_SUCCESS';
export const REGISTER_ACCOUNT = 'auth/REGISTER_ACCOUNT';

export const setSuccess = createAction<string | boolean>(SET_SUCCESS);

export const initAuth = createAsyncThunk<InitAuth, InitAuth, { state: { auth: AuthState } }>(
  INIT_AUTH,
  async (data, { getState, dispatch }) => {
    await initAuthApi(data.email);
    if (getState().auth.email) {
      dispatch(setSuccess(i18n.t('pages.confirmCode.messages.resendSuccess') as string));
    }
    return data;
  },
);

export const confirmAuth = createAsyncThunk<AuthSessionDetails | null, string, { state: { auth: AuthState } }>(
  CONFIRM_AUTH,
  async (code, { getState }) => {
    const {
      auth: { email: unAuthedEmail, rememberMe },
    } = getState();

    if (!unAuthedEmail) return null;

    const { accessToken } = await confirmAuthApi(unAuthedEmail, code);

    const { uid, role, email } = jwtDecode<JwtPayload>(accessToken) as {
      uid: string;
      email: string;
      role: UserRole;
      exp: number;
    };

    const authSession: AuthSessionDetails = {
      accessToken,
      role,
      uid,
      email,
    };

    const user = await getCurrentAuthenticatedUserApi(accessToken);
    authSession.firstName = user.first_name;
    authSession.lastName = user.last_name;
    authSession.occupation = user.occupation;

    setAuthSession(authSession, rememberMe);

    return authSession;
  },
);

export const logout = createAction(LOG_OUT);

export const setError = createAction<string | boolean>(SET_ERROR);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const register = createAsyncThunk<void, RegisterData, { dispatch: any }>(
  REGISTER,
  async (data, { dispatch }) => {
    await registerApi(data);
    dispatch(initAuth({ email: data.email, rememberMe: false }));
  },
);

export const confirmPasswordReset = createAsyncThunk<void, { token: string; password: string }>(
  CONFIRM_PASSWORD_RESET,
  async ({ token, password }, { dispatch }) => {
    await confirmPasswordResetApi(token, password);
    dispatch(setSuccess(i18n.t('pages.resetPassword.successMessage') as string));
  },
);

export const initPasswordReset = createAsyncThunk<void, string>(INIT_PASSWORD_RESET, async (email, { dispatch }) => {
  await initPasswordResetApi(email);
  dispatch(setSuccess(i18n.t('pages.forgotPassword.successMessage') as string));
});

export const getCurrentAuthenticatedUser = createAsyncThunk<
  UserResponse | null,
  undefined,
  { state: { auth: AuthState } }
>(GET_AUTH_USER, async (_, { getState }) => {
  const {
    auth: { uid },
  } = getState();

  if (!uid) return null;

  const user = await getCurrentAuthenticatedUserApi(uid);
  return user;
});

export const registerAccount = createAsyncThunk<void, RegisterAccountData>(
  REGISTER_ACCOUNT,
  async (data, { dispatch }) => {
    await registerAccountApi(data);
    dispatch(setSuccess(i18n.t('pages.getStarted.messages.success') as string));
  },
);
