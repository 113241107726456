import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  inputField: {
    '& input': {
      width: '140px',
      paddingTop: 0,
      paddingBottom: 0,
      paddingRight: 16,
      height: '42px',
      textAlign: 'right',
    },
  },
  actionButton: {
    position: 'absolute',
    height: '30px',
    width: '30px',
    top: 0,
  },
  editActionButton: {
    top: -4,
    right: -28,
  },
  actionIcon: {
    fontSize: '20px',
    color: theme.palette.common.black,
  },
  cancelButton: {
    padding: 0,
    height: 'fit-content',
    '& > span': {
      fontSize: '13px !important',
    },
  },
  progress: {
    marginRight: 10,
    marginTop: 10,
  },
  fontJost: {
    fontFamily: 'jost !important',
  },
  fontWeight_500: {
    fontWeight: '500!important',
  },
  inputIconLeft: {
    width: '24px',
    height: '44px',
    display: 'flex',
    alignItems: 'center',
    borderRight: `1px solid #8C92AC`,
  },
  black_500: {
    color: '#000000',
  },
}));
