import { Box, Chip } from '@mui/material';
import clsx from 'clsx';
import { Close } from '@material-ui/icons';
import { checkDataIsExit } from 'pages/leads/components/CreateApplicationModal/utils';
import useCommonStyle from '../../assets/css/Common.styles';

export const CommonChip = ({
  data,
  handleRemoveFilter,
}: {
  data: object;
  handleRemoveFilter: (val1: string, val2: string) => void;
}) => {
  const commonClasses = useCommonStyle();
  return (
    checkDataIsExit(data) && (
      <Box className={commonClasses.selectedFilter}>
        <ul className={commonClasses.selectedFilterList}>
          {Object.entries(data)?.map((d) => {
            if (Array.isArray(d[1]) && d[1]?.length > 0) {
              return (d[1] as string[]).map((e: string) => {
                return (
                  <Chip
                    key={e}
                    className={clsx([commonClasses.chip_main])}
                    label={e}
                    onDelete={() => handleRemoveFilter(e, d[0])}
                    deleteIcon={<Close className={clsx([commonClasses.chip_style])} />}
                  />
                );
              });
            }
            return null;
          })}
        </ul>
      </Box>
    )
  );
};
