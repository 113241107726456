import { useEffect } from 'react';

const useScrollToTop = (scroll: boolean): void => {
  useEffect(() => {
    if (scroll) {
      window.scrollTo(0, 0);
    }
  }, [scroll]);
};

export default useScrollToTop;
