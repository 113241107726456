import { Box, Chip, CircularProgress, IconButton, Switch, Typography } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import clsx from 'clsx';
import {
  deleteWebhookUrl as deleteWebhookUrlApi,
  getWebhookUrlSigningSecret as getWebhookUrlSigningSecretApi,
  updateWebhookUrl as updateWebhookUrlApi,
} from 'http/partners';
import { useAuth } from 'store/auth/hooks';
import { usePartners } from 'store/partners/hooks';
import { WebhookUrl } from 'store/partners/types';
import { LoadingButton } from 'components/buttons';
import { noop } from 'utils';
import useStyles from './Webhook.styles';

const Webhook: FC<WebhookUrl> = ({ id, url, active, offer_status, application_status }) => {
  const [isActive, setIsActive] = useState(active);
  const [loading, setLoading] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [loadingSecret, setLoadingSecret] = useState(false);
  const [signingSecret, setSigningSecret] = useState('');
  const classes = useStyles();
  const { t } = useTranslation();
  const { setError, setSuccess } = usePartners();
  const { isPartnerAdmin } = useAuth();

  const onUpdate = async (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setLoading(true);
    try {
      await updateWebhookUrlApi(id, checked);
      setIsActive(checked);
      setSuccess(t('pages.api.webhooks.messages.updateSuccess') as string);
    } catch (err) {
      setError((err as Error)?.message ?? true);
    }
    setLoading(false);
  };

  const onDelete = async () => {
    setLoading(true);
    try {
      await deleteWebhookUrlApi(id);
      setDeleted(true);
      setSuccess(t('pages.api.webhooks.messages.deleteSuccess') as string);
    } catch (err) {
      setError((err as Error)?.message ?? true);
    }
    setLoading(false);
  };

  const getSigningSecret = async () => {
    setLoadingSecret(true);
    try {
      const res = await getWebhookUrlSigningSecretApi(id);
      setSigningSecret(res.signing_secret);
    } catch (err) {
      setError((err as Error)?.message ?? true);
    }
    setLoadingSecret(false);
  };

  if (deleted) return null;

  return (
    <Box className={clsx(classes.container, !isActive && classes.inactive)}>
      <Box className={classes.webhookContainer}>
        <Box className={classes.urlContainer}>
          <Typography className={classes.url}>{url}</Typography>
        </Box>
        <Box className={classes.actionsContainer}>
          <Switch onChange={isPartnerAdmin ? onUpdate : noop} checked={isActive} disabled={loading} size="small" />

          {isPartnerAdmin && (
            <IconButton
              edge="end"
              aria-label="delete webhook url"
              onClick={onDelete}
              className={classes.deleteButton}
              disabled={loading}
            >
              <Delete className={classes.deleteIcon} />
            </IconButton>
          )}

          {/* <IconButton className={classes.collapseButton} onClick={toggleShowStatus}>
            {showStatus ? <ExpandLess /> : <ExpandMore />}
          </IconButton> */}
        </Box>

        {loading && (
          <Box className={classes.loaderContainer}>
            <CircularProgress size={22} />
          </Box>
        )}
      </Box>

      <Box>
        <Typography>{t('pages.api.webhooks.configure.signingSecret.title')}</Typography>
        {signingSecret ? (
          <Typography className={classes.signingSecret}>{signingSecret}</Typography>
        ) : (
          <LoadingButton
            variant="text"
            onClick={getSigningSecret}
            loading={loadingSecret}
            className={classes.secretButton}
          >
            {t('pages.api.webhooks.configure.signingSecret.reveal')}
          </LoadingButton>
        )}
      </Box>

      <Typography variant="caption"> {t('pages.api.webhooks.configure.offers')}</Typography>

      {offer_status && (
        <Box className={classes.offerStatusContainer}>
          {Object.values(offer_status).map((status) => (
            <Chip key={status} color="primary" label={status} className={classes.offerStatusOption} size="small" />
          ))}
        </Box>
      )}

      {application_status && (
        <>
          <Typography variant="caption"> {t('pages.api.webhooks.configure.applications')}</Typography>
          <Box className={classes.offerStatusContainer}>
            {Object.values(application_status).map((status) => (
              <Chip key={status} color="primary" label={status} className={classes.offerStatusOption} size="small" />
            ))}
          </Box>
        </>
      )}
    </Box>
  );
};

export default Webhook;
