import { FC, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

import useStyles from './SidebarLink.styles';

interface SidebarLinkProps {
  route: string;
  pathname: string;
  Icon: ReactNode;
  title: string;
  disabled?: boolean;
}

const SidebarLink: FC<SidebarLinkProps> = ({ route, pathname, Icon, title, disabled = false }) => {
  const classes = useStyles();

  let selected = route === pathname;

  if (title === 'Settings') {
    selected = pathname.includes(route);
  }

  if (disabled) {
    return (
      <ListItem className={classes.listItemContainer}>
        <ListItemButton disabled={disabled} selected={selected} className={classes.linkContainer}>
          <ListItemIcon className={classes.linkButtonIcon}>{Icon}</ListItemIcon>
          <ListItemText className={classes.linkButtonText}>{title}</ListItemText>
        </ListItemButton>
      </ListItem>
    );
  }

  return (
    <NavLink to={route} className={classes.routerLink}>
      <ListItem className={classes.listItemContainer}>
        <ListItemButton disabled={disabled} selected={selected} className={classes.linkContainer}>
          <ListItemIcon className={classes.linkButtonIcon}>{Icon}</ListItemIcon>
          <ListItemText className={classes.linkButtonText}>{title}</ListItemText>
        </ListItemButton>
      </ListItem>
    </NavLink>
  );
};

export default SidebarLink;
