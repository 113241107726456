import { ChangeEvent, FC, useRef } from 'react';
import { Grid } from '@material-ui/core';
import { Formik, Form, Field, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { UserRole } from 'core/types';
import { AlertDialog } from 'components/AlertDialog';
import { CustomTextField, CustomSelect } from 'components/inputs';
import { useAccount } from 'store/account/hooks';
import useStyles from './InviteUserModal.styles';

interface FormValues {
  email: string | null;
  role: UserRole | null;
}

interface InviteUserModalProps {
  open: boolean;
  toggleOpen: () => void;
  roleOptions: { label: string; value: UserRole }[];
  defaultRole: UserRole;
  accountId?: string;
}

const InviteUserModal: FC<InviteUserModalProps> = ({ open, toggleOpen, roleOptions, defaultRole, accountId }) => {
  const formRef = useRef<FormikProps<FormValues> | null>(null);
  const classes = useStyles();
  const { t } = useTranslation();
  const { loading, inviteUser } = useAccount(() => {
    if (open) {
      formRef.current?.resetForm();
      toggleOpen();
    }
  });

  const formSchema = yup.object({
    email: yup
      .string()
      .email(t('pages.team.inviteUser.inputs.email.error'))
      .required(t('pages.team.inviteUser.inputs.email.required'))
      .nullable(),
    role: yup.string().required(t('pages.team.inviteUser.inputs.role.required')).nullable(),
  });

  const initialValues: FormValues = {
    email: null,
    role: defaultRole,
  };

  const onSubmit = (values: FormValues) => {
    if (values.email && values.role) {
      inviteUser({ email: values.email, role: values.role, accountId });
    }
  };

  const onCancel = () => {
    formRef.current?.resetForm();
    toggleOpen();
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={formSchema} innerRef={formRef}>
      {({ handleChange, values, handleSubmit, setFieldValue }) => (
        <AlertDialog
          open={open}
          dialogContentTitle={t('pages.team.inviteUser.title')}
          handleCancel={onCancel}
          handleConfirm={handleSubmit}
          confirmButtonTitle={t('pages.team.inviteUser.buttons.send')}
          loading={loading}
        >
          <Form noValidate>
            <Grid container direction="column" spacing={4} className={classes.container}>
              <Grid item>
                <Field
                  id="email"
                  fullWidth
                  component={CustomTextField}
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  title={t('pages.team.inviteUser.inputs.email.label')}
                  className={classes.textInput}
                />
              </Grid>

              <Grid item>
                <Field
                  id="role"
                  fullWidth
                  component={CustomSelect}
                  options={roleOptions}
                  name="role"
                  value={values.role}
                  onChange={(event: ChangeEvent<HTMLSelectElement>) => setFieldValue('role', event.target.value)}
                  title={t('pages.team.inviteUser.inputs.role.label')}
                  className={classes.textInput}
                />
              </Grid>
            </Grid>
          </Form>
        </AlertDialog>
      )}
    </Formik>
  );
};

export default InviteUserModal;
