import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    fontSize: theme.typography.pxToRem(32),
    lineHeight: theme.typography.pxToRem(34),
  },
  titleContainer: {
    padding: '40px 0px',
  },
  title: {
    fontSize: theme.typography.pxToRem(20),
    lineHeight: theme.typography.pxToRem(24),
    marginBottom: 12,
  },
  subtitle: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20),
  },
  semibold: {
    fontFamily: 'inter',
    fontWeight: 550,
  },
  bold: {
    fontWeight: 700,
  },
  dateRangeContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexWrap: 'wrap',
  },
  datePickerContainer: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  cardContainer: {
    border: `1px solid ${theme.palette.greyLightBorder.main}`,
  },
  selectContainer: {
    marginRight: theme.spacing(2),
    '& .MuiSelect-root': {
      height: 36,
      minWidth: '10em',
      paddingTop: 0,
      paddingBottom: 0,
      paddingRight: 16,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      fontWeight: 700,
    },
  },
  expandIcon: {
    height: 20,
    width: 20,
    color: '#687C8D',
    pointerEvents: 'none',
    position: 'absolute',
    top: 8,
    right: 10,
  },
  body: {
    fontFamily: 'inter regular',
    fontSize: theme.typography.pxToRem(17),
  },
  card: {
    background: 'linear-gradient(to right bottom, rgb(0, 127, 255), rgb(0, 89, 178) 120%)',
    boxShadow: 'rgb(0 0 0 / 10%) 0px 20px 25px, rgb(0 0 0 / 4%) 0px 10px 10px',
    '& span': {
      color: '#ffffff !important',
      fontWeight: 500,
    },
    '& p': {
      color: '#ffffff !important',
      fontWeight: 500,
    },
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  heroImage: {
    maxHeight: '200px',
  },
  team: {},
  teamCard: {
    background: '#f7fafc',
    '& span': {
      color: '#000000!important',
    },
    '& button span': {
      color: '#FFFFFF!important',
    },
  },
  teamAvatar: {
    backgroundColor: '#FFFFFF!important',
    border: `1px solid ${theme.palette.primary.dark}`,
    color: theme.palette.primary.dark,
  },
  sideGrid: {
    background: '#e3f6f5',
  },
  paddingTop: {
    marginTop: '40px',
  },
  textIconWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  marginTop56px: {
    marginTop: '56px',
  },
  label_style: {
    fontFamily: 'Cerebri Sans Pro',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '140%',
    marginTop: '10px',
  },
  chart_label_style: {
    fontFamily: 'Cerebri Sans Pro',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '140%',
    marginTop: '10px',
    color: theme.palette.grey[700],
  },
  select_label_style: {
    fontFamily: 'Cerebri Sans Pro',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '140%',
    marginTop: '10px',
    color: theme.palette.grey[700],
  },
  width141px: {
    width: '141px',
  },
  width300px: {
    width: '300px !important',
  },
  width236px: {
    maxWidth: '236px !important',
    height: '30px !important',
    fontSize: '13px',
    width: '200px',
  },
  margin20px: {
    margin: '20px',
  },
  border_RightGray: {
    borderRight: `1px solid ${theme.palette.Gray[200]}`,
  },
  opacity_line: {
    opacity: '0.3',
    margin: '30px 0px 60px',
  },
  reactApexCSS: {
    '& .apexcharts-legend-marker': {
      borderRadius: '0 !important',
      marginRight: '6px !important',
    },
  },
}));

export default useStyles;
