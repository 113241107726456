import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    position: 'relative',
    background: theme.palette.grey[200],
    '&::before': {
      content: "''",
      position: 'absolute',
      top: '-30px',
      left: 0,
      width: ' 100%',
      height: '50%',
      background: theme.palette.grey[300],
      transformOrigin: '0 50%',
      transform: 'skewY(-5deg)',
    },
  },
  container: {
    display: 'flex',
    minHeight: '100vh',
  },
  contentContainer: {
    margin: '8px -8px 0px',
  },
  authContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    zIndex: 100,
    // [theme.breakpoints.down('sm')]: {
    //   display: 'none',
    // },
  },
  formContainer: {
    maxWidth: 500,
    width: '100%',
    padding: theme.spacing(4),
  },
  footerLink: {
    fontSize: theme.typography.pxToRem(12),
    marginLeft: theme.typography.pxToRem(8),
    marginRight: theme.typography.pxToRem(8),
    lineHeight: theme.typography.pxToRem(20),
    color: theme.palette.grey[800],
  },
}));
