import { FC, useState, useEffect } from 'react';
import { Box, Tooltip } from '@material-ui/core';
import copy from 'copy-to-clipboard';
import isNil from 'lodash.isnil';
import omitBy from 'lodash.omitby';

import { Typography } from '@mui/material';
import clsx from 'clsx';
import { fetchApiKeys } from 'http/account';
import { useTranslation } from 'react-i18next';
import { AccountType } from 'store/applications/types';
import useCommonStyles from '../../../../../assets/css/Common.styles';
import { useTooltipStyles } from './ApiSecret.styles';
import { ReactComponent as ContentCopyIcon } from '../../../../../assets/icons/copy_icon.svg';

export enum Display {
  MASK = 'mask',
  HALF_MASK = 'half_mask',
  RAW = 'raw',
}
export enum KeyType {
  SECRET = 'secret',
  PUBLIC = 'public',
}
interface ApiSecretProps {
  title: string;
  secret: string;
  name: string;
  display?: Display;
  description?: string;
  type?: KeyType;
  accountId?: string;
  accountType?: AccountType;
}

const ApiSecret: FC<ApiSecretProps> = ({
  title,
  secret,
  display = Display.RAW,
  description,
  type,
  accountId,
  accountType,
  name,
}) => {
  const [copied, setCopied] = useState(false);
  const tooltipClasses = useTooltipStyles();
  const { t } = useTranslation();
  const commonClasses = useCommonStyles();

  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 1500);
    }
  }, [copied]);

  const copyToClipboard = async () => {
    if (type === KeyType.PUBLIC) {
      copy(secret);
      setCopied(true);
      return;
    }

    try {
      const key = (await fetchApiKeys(
        omitBy(
          {
            only: KeyType.SECRET,
            account_id: accountId,
            account_type: accountType,
          },
          isNil,
        ),
      )) as unknown as Record<string, string>;
      copy(key[name]);
      setCopied(true);
    } catch (err) {
      setCopied(false);
    }
  };

  type DisplaySecret = Record<Display, string>;

  const displaySecret: DisplaySecret = {
    mask: '********************',
    half_mask: `${secret.slice(0, 5)}***********`,
    raw: secret,
  };

  return (
    <Box className={commonClasses?.marginBottom24px}>
      <Typography className={clsx([commonClasses.fontJost])} component="p" variant="text_SM" sx={{ mb: 4 }}>
        {title}
      </Typography>
      <Box className={clsx([commonClasses.boxInput])}>
        <Typography className={clsx([commonClasses.fontJost, commonClasses.boxInputText, commonClasses.gray_700])}>
          {displaySecret[display]}
        </Typography>
        <Box className={clsx([commonClasses.btnTextNoEffect])} onClick={copyToClipboard}>
          <Tooltip
            classes={tooltipClasses}
            title={(copied ? t('global.copied') : t('global.copySecret')) as string}
            placement="top-start"
            arrow
          >
            <ContentCopyIcon className={clsx([commonClasses.marginNone])} />
          </Tooltip>
          <Typography variant="caption">{description}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ApiSecret;
