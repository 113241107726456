import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import routes from 'router/routes';

import { Grid } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridRowParams, GridValueGetterParams } from '@mui/x-data-grid';
import clsx from 'clsx';
import { CommonChip } from 'components/CommonChip/CommonChip';
import CustomFilter from 'components/CustomTable/CustomFilter/CustomFilter';
import CustomTable from 'components/CustomTable/CustomTable';
import FilterModal from 'components/Modal/FilterModal';
import {
  ApplicationFilterOption,
  ROWS_PER_TABLE_PAGE,
  filterTabName,
  fundedStatuses,
  notFundedStatuses,
  onlineSalesOptions,
  pendingStatuses,
} from 'core/constants';
import { CompanyDocumentType } from 'core/types';
import {
  mapAppRegionToSupportedCurrency,
  mapApplicationStatusToBackgroundColor,
  mapApplicationStatusToLabel,
} from 'core/utils';
import { useDebounce } from 'hooks';
import { getAllApplicationData } from 'http/applications';
import { useApplications } from 'store/applications/hooks';
import { ApplicationStatus, IApplicationListData } from 'store/applications/types';
import { useAuth } from 'store/auth/hooks';
import { parseMoney } from 'utils';
import useCommonStyles from '../../../../assets/css/Common.styles';
import { SEARCH_DEBOUNCE_DELAY, downloadCSVFile } from '../CreateApplicationModal/utils';

interface IFilterData {
  appStatus: string[];
  uploadedDocuments: string[];
  onlineSales: string[];
  homeowner: string[];
}

const LeadsTable: FC = () => {
  const [page, setPage] = useState(1);
  const [tabValue, setTabValue] = useState<string | number>('0');
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [filterData, setFilterData] = useState<IFilterData>({
    appStatus: [],
    uploadedDocuments: [],
    onlineSales: [],
    homeowner: [],
  });
  const [showFilterData, setShowFilterData] = useState<IFilterData>({
    appStatus: [],
    uploadedDocuments: [],
    onlineSales: [],
    homeowner: [],
  });

  const { t } = useTranslation();
  const history = useHistory();
  const {
    filters: { documentTypes, searchTerm, isHomeOwner, onlineSales },
    updateApplicationsQuery,
    setApplicationsSearchTerm,
    clearApplicationsFilters,
    applications,
    total,
    getApplications,
  } = useApplications();
  const { isAdmin, isLender } = useAuth();
  const debouncedSearchTerm = useDebounce(searchTerm, SEARCH_DEBOUNCE_DELAY);
  const commonClasses = useCommonStyles();

  const toggleFilterModal = () => setOpenModal(!openModal);

  const handleFilterData = (event: ChangeEvent<HTMLInputElement>) => {
    const data: string = Object.keys(filterData)[+tabValue];

    if (event?.target?.checked) {
      setFilterData({
        ...filterData,
        [data]: ['appStatus', 'uploadedDocuments']?.includes(data)
          ? [...filterData[data as keyof typeof filterData], event?.target?.name]
          : [event?.target?.name],
      });
    } else
      setFilterData({
        ...filterData,
        [data]: filterData[data as keyof typeof filterData]?.filter((d: string) => d !== event?.target?.name),
      });
  };

  const handleSubmitFilter = (data: IFilterData, isHideModel: boolean = true) => {
    const onlineSalesData: string | null = onlineSalesOptions
      ?.filter((d) => data?.onlineSales?.includes(d?.label))
      ?.map((d) => d?.value)
      ?.join(',');
    const status: string[] = data?.appStatus;

    let result: string[] = [];

    status.forEach((d) => {
      if (d === 'Funded apps') {
        result = [...result, ...fundedStatuses];
      } else if (d === 'Not funded apps') {
        result = [...result, ...notFundedStatuses];
      } else if (d === 'Pending apps') {
        result = [...result, ...pendingStatuses];
      } else {
        result = [];
      }
    });

    let isHomeOwnerVal;
    if (data?.homeowner?.length > 0) {
      if (data.homeowner[0] === 'Is a home owner') {
        isHomeOwnerVal = true;
      } else {
        isHomeOwnerVal = false;
      }
    } else {
      isHomeOwnerVal = undefined;
    }
    updateApplicationsQuery({
      onlineSales: onlineSalesData || undefined,
      searchTerm,
      documentTypes:
        data?.uploadedDocuments?.length > 0
          ? (data?.uploadedDocuments as CompanyDocumentType[])
          : ([] as CompanyDocumentType[]),
      isHomeOwner: isHomeOwnerVal,
      ...(data?.appStatus?.length > 0 && {
        activeFilters: (result?.length > 0 ? result : []) as ApplicationStatus[],
      }),
    });

    let isClearFilter = true;
    Object?.entries(data)?.forEach((d) => {
      if (d[1]?.length > 0) {
        isClearFilter = false;
      }
    });
    setShowFilterData(data);
    if (isClearFilter) {
      clearApplicationsFilters();
    } else {
      getApplications();
    }
    if (isHideModel) {
      toggleFilterModal();
    }
  };

  const handleRemoveFilter = (data: string, name: string) => {
    const values: IFilterData = {
      ...filterData,
      [name]: filterData[name as keyof typeof filterData]?.filter((d: string) => d !== data),
    };
    setFilterData(values);
    handleSubmitFilter(values, false);
  };

  const columnsData: GridColDef[] = [
    {
      field: 'created_at',
      headerName: t('pages.dashboard.table.columns.timeStamp'),
      valueGetter: (params: GridValueGetterParams) => moment(params?.row?.created_at).format('DD/MM/YYYY hh:mm a'),
      width: 200,
      sortable: false,
    },
    { field: 'region', headerName: t('pages.dashboard.table.columns.region') },
    {
      field: 'registered_name',
      headerName: t('pages.dashboard.table.columns.companyName'),
      valueGetter: (params: GridValueGetterParams) => params?.row?.company?.registered_name,
      width: 200,
      sortable: false,
    },
    {
      field: 'applicantName',
      headerName: t('pages.dashboard.table.columns.applicantName'),
      valueGetter: (params: GridValueGetterParams) =>
        `${params?.row?.company?.director?.first_name || ''} ${params?.row?.company?.director?.last_name || ''}`,
      width: 200,
      sortable: false,
    },
    {
      field: 'monthlyRevenue',
      headerName: t('pages.dashboard.table.columns.monthlyRevenue'),
      width: 200,
      valueGetter: (params: GridValueGetterParams) =>
        parseMoney(
          params?.row?.company?.financial_information?.monthly_revenue,
          mapAppRegionToSupportedCurrency(params?.row?.region),
        ),
    },
    {
      field: 'applicationStatus',
      headerName: t('pages.dashboard.table.columns.applicationStatus'),
      width: 200,
      renderCell: (params: GridRenderCellParams) => {
        const data = mapApplicationStatusToBackgroundColor(params?.row?.status);
        return <span className={clsx([commonClasses.badge, commonClasses[`${data}`]])}>{params?.row?.status}</span>;
      },
    },
    {
      field: 'loanAmount',
      headerName: t('pages.dashboard.table.columns.loanAmount'),
      width: 200,
      valueGetter: (params: GridValueGetterParams) =>
        parseMoney(params?.row?.funding_amount, mapAppRegionToSupportedCurrency(params?.row?.region)),
    },
    { field: 'partner_name', headerName: t('pages.dashboard.table.columns.partnerName') },
  ];

  const downloadCSV = async () => {
    await getAllApplicationData().then((data: IApplicationListData) => {
      if (data?.applications?.length > 0) {
        const applicationData = data?.applications?.map((d) => {
          return {
            'Time Stamp': moment(d?.created_at).format('DD/MM/YYYY hh:mm a'),
            Region: d?.region,
            Company: d?.company?.registered_name,
            Applicant: `${d?.company?.director?.first_name || ''} ${d?.company?.director?.last_name || ''}`,
            'Monthly revenue': parseMoney(
              d?.company?.financial_information?.monthly_revenue,
              mapAppRegionToSupportedCurrency(d?.region),
            ),
            Status: ['REJECTED', 'FUNDS_DISBURSED']?.includes(d?.status)
              ? mapApplicationStatusToLabel(d?.status)?.replace(/[\W_]/g, '')
              : mapApplicationStatusToLabel(d?.status),
            'Loan amount': parseMoney(d?.funding_amount, mapAppRegionToSupportedCurrency(d?.region)),
            Partner: d?.partner_name,
          };
        });
        downloadCSVFile(applicationData, 'BScore_application.csv');
      }
    });
  };

  useEffect(() => {
    const onlineSalesData = onlineSalesOptions?.find((d) => d?.value === Number(onlineSales))?.label;
    setFilterData({
      ...filterData,
      uploadedDocuments: documentTypes?.length > 0 ? documentTypes : [],
      homeowner: isHomeOwner === undefined ? [] : [isHomeOwner ? 'Is a home owner' : 'Is not a home owner'],
      onlineSales: onlineSalesData ? [onlineSalesData] : [],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHomeOwner, documentTypes]);

  const getApplicationData = useCallback(() => {
    if (!isLender) {
      getApplications();
    }
    updateApplicationsQuery({ activePage: page - 1, searchTerm: debouncedSearchTerm });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLender, page, getApplications, debouncedSearchTerm]);

  useEffect(() => {
    getApplicationData();
  }, [getApplicationData]);

  useEffect(() => {
    setTabValue('0');
  }, [openModal]);

  return (
    <Grid>
      {(isAdmin || isLender) && (
        <CustomFilter
          search={searchTerm}
          pageSize={ROWS_PER_TABLE_PAGE}
          total={total}
          toggleFilterModal={toggleFilterModal}
          setSearch={setApplicationsSearchTerm}
          page={page}
          setPage={setPage}
          downloadCSV={downloadCSV}
        />
      )}
      <CommonChip data={showFilterData} handleRemoveFilter={handleRemoveFilter} />
      <CustomTable
        rows={applications}
        headerUpperCase={true}
        columns={isAdmin ? columnsData : columnsData?.slice(0, 7)}
        hideFooterPagination={true}
        onRowClick={(item: GridRowParams) => {
          if (item?.id) {
            history.push(`${routes?.lead}/${item?.id}`);
          }
        }}
        NoDataFountMessage="You don’t have any applications"
        disableColumnMenu={true}
      />
      {openModal && (
        <FilterModal
          setValue={setTabValue}
          value={+tabValue}
          filterTabName={filterTabName}
          open={openModal}
          onClose={toggleFilterModal}
          filterOption={ApplicationFilterOption}
          handleApplyFilter={() => handleSubmitFilter(filterData)}
          filterData={filterData}
          handleFilterData={handleFilterData}
        />
      )}
    </Grid>
  );
};

export default LeadsTable;
