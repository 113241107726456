import { FC } from 'react';
import { Box } from '@material-ui/core';
import { DatePicker, DatePickerViewsProps } from '@material-ui/pickers';
import InputAdornment from '@mui/material/InputAdornment';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Typography } from '@mui/material';
import clsx from 'clsx';
import useStyles from './CustomInputDatePicker.styles';
import ErrorMeg from '../ErrorMessage/ErrorMessage';

interface CustomDatePickerProps {
  name: string;
  label?: string;
  value?: Date | string | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (date: MaterialUiPickersDate) => void;
  format?: string;
  openTo?: DatePickerViewsProps['openTo'];
  error?: string;
  clearable?: boolean;
  disabled?: boolean;
  isShowIcon?: string;
  disableFuture?: boolean;
  collapsed?: boolean;
  maxDate?: Date;
  minDate?: Date;
  height?: string;
  className?: string;
  inputClassName?: string;
}

const CustomInputDatePicker: FC<CustomDatePickerProps> = ({
  name,
  label,
  value,
  onChange,
  format = 'MM/YYYY',
  openTo = 'month',
  error,
  clearable = false,
  disabled = false,
  disableFuture = false,
  collapsed = false,
  maxDate,
  minDate,
  height,
  className,
  inputClassName,
  isShowIcon = 'false',
}) => {
  const classes = useStyles();
  return (
    <Box className={openTo === 'date' && !collapsed ? classes.dateContainer : classes.container} height={height}>
      {label && (
        <Typography
          variant="text_MD"
          component="label"
          className={clsx([classes.fontJost, classes.fontWeight_500, classes.gray_500, classes.inputLbl])}
        >
          {label}
        </Typography>
      )}

      <DatePicker
        id={name}
        fullWidth
        format={format}
        margin="normal"
        name={name}
        value={value}
        onChange={onChange || (() => {})}
        clearable={clearable}
        openTo={openTo}
        views={[openTo]}
        disabled={disabled}
        disableFuture={disableFuture}
        maxDate={maxDate}
        InputProps={{
          className: `${inputClassName}`,
          ...(isShowIcon === 'true' && {
            endAdornment: (
              <InputAdornment className={classes?.iconStyle} position="start">
                <CalendarTodayIcon />
              </InputAdornment>
            ),
          }),
        }}
        minDate={minDate}
        className={`${className} ${error ? classes.inputError : undefined}`}
      />

      <ErrorMeg name={name} />
    </Box>
  );
};

export default CustomInputDatePicker;
