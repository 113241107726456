import { Box, Button, Dialog, Typography } from '@mui/material';
import clsx from 'clsx';
import { Close } from '@material-ui/icons';
import useStyles from './CommonModal.styles';

const CommonModal = ({
  open,
  onClose,
  icon,
  title,
  children,
}: {
  open: boolean;
  onClose: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: any;
  title: string;
  children: React.ReactNode;
}) => {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: classes.modal_main }}
    >
      <Box className={clsx([classes.c_card, classes.cardShadow])}>
        <Box className={clsx([classes.c_cardTitle, classes.filter_cardTitle])}>
          <Button className={classes?.closeFilterIconEnd} onClick={onClose}>
            <Close />
          </Button>
        </Box>
        <Box className={clsx([classes.filter_cardBody])}>
          <Box className={clsx([classes.modalIconMargin, classes.modalIconBorder])}>{icon}</Box>
          <Typography variant="text_LG" className={clsx([classes.modalTitle])}>
            {title}
          </Typography>
        </Box>
        {children}
      </Box>
    </Dialog>
  );
};

export default CommonModal;
