import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  newWorkflowWrap: {
    maxWidth: '35rem',
  },
  filedBoxWorkFlow: {
    backgroundColor: theme.palette.Gray[50],
    borderRadius: `${theme.spacing(3)} !important`,
    border: `1px solid ${theme.palette.Gray[200]}`,
    maxWidth: '100%',
    padding: `${theme.spacing(4)} !important`,
  },
  formLblValue: {
    marginBottom: '1.5rem !important',
  },
  gray_800: {
    color: `${theme.palette.Gray[800]} !important`,
  },
  integrateLineY_76: {
    width: '6px',
    borderLeft: `1px dashed ${theme.palette.Blue[600]}`,
    position: 'relative',
    marginLeft: 'auto',
    marginRight: 'auto',
    height: '76px',
    '&::before': {
      position: 'absolute',
      content: '""',
      backgroundColor: theme.palette.Blue[600],
      width: '6px',
      height: '6px',
      right: 'auto',
      bottom: 'auto',
      top: '-3px',
      left: '-3px',
      display: 'inline-block',
      borderRadius: '100%',
    },
    '&::after': {
      position: 'absolute',
      content: '""',
      backgroundColor: theme.palette.Blue[600],
      width: '6px',
      height: '6px',
      left: '-3px',
      top: 'auto',
      right: 'auto',
      bottom: '-3px',
      display: 'inline-block',
      borderRadius: '100%',
    },
  },
  textStyle: {
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '180%',
  },
  editor: {
    border: `1px solid ${theme.palette.greyLightBorder.main}`,
    borderRadius: 4,
    height: 250,
    '& .ql-container.ql-snow': {
      border: 'none',
      height: 207,
      '& *': {
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(20),
        fontFamily: theme.typography.fontFamily,
      },
      '& .ql-tooltip': {
        left: '5px !important',
      },
    },
    '& .ql-toolbar.ql-snow': {
      border: 'none',
      borderBottom: `1px solid ${theme.palette.greyLightBorder.main}`,
      '& .ql-formats': {
        marginRight: 5,
      },
    },
  },
  quill_editor_wrapper: {
    position: 'relative',
  },
  ql_toolbar: {
    position: 'absolute',
    bottom: '0',
    left: '0',
    background: ' #fff',
    borderTop: ' 1px solid #ccc',
    width: ' 100%',
  },
}));
