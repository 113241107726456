import { LenderQuery, LendersListResponse } from 'core/types';
import { apiCall } from 'http/index';

import { ApplicationRegion } from 'store/applications/types';
import { CreateLender, LenderDetails, ShortLender } from 'store/lenders/types';

export const getAllLenders = async (): Promise<ShortLender[]> => apiCall({ method: 'get', url: '/admin/lenders/all' });

export const getLenders = async (query: LenderQuery = {}): Promise<LendersListResponse> => {
  const queryString = new URLSearchParams(query as Record<string, string>).toString();
  return apiCall({ method: 'get', url: `/admin/lenders?${queryString}` });
};

export const createLender = async (lender: CreateLender): Promise<null> =>
  apiCall({ method: 'post', url: '/admin/lenders', data: lender });

export const updateLender = async (id: string, lender: Partial<CreateLender>): Promise<null> =>
  apiCall({ method: 'put', url: `/admin/lenders/${id}`, data: lender });

export const updateLenderLogo = async (lenderId: string, formData: FormData): Promise<{ url: string }> => {
  const data = await apiCall<{ logo_url: string }>({
    method: 'put',
    url: `/admin/lenders/${lenderId}/logos`,
    data: formData,
    headers: { 'content-type': 'multipart/form-data' },
  });
  return { url: data.logo_url };
};

export const getLenderDetails = async (lenderId: string): Promise<LenderDetails> =>
  apiCall({ method: 'get', url: `/admin/lenders/${lenderId}` });

export const updateLenderSupportedRegions = async (regions: ApplicationRegion[]): Promise<null> =>
  apiCall({ method: 'put', url: '/lender', data: { supported_regions: regions } });
