import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  container: {
    position: 'relative',
  },
  circularProgress: {
    position: 'absolute',
    top: 10,
    left: 'calc(50% - 12.5px)',
  },
}));
