import { Box } from '@mui/material';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import clsx from 'clsx';
import { CommonChip } from 'components/CommonChip/CommonChip';
import CustomFilter from 'components/CustomTable/CustomFilter/CustomFilter';
import CustomTable from 'components/CustomTable/CustomTable';
import FilterModal from 'components/Modal/FilterModal';
import { INCOMPLETED_APP_STATUS, OfferFilterOption, ROWS_PER_TABLE_PAGE } from 'core/constants';
import { IOfferFilterData } from 'core/types';
import { getApplicationStatusAndColorClass, mapAppRegionToSupportedCurrency } from 'core/utils';
import { useDebounce } from 'hooks';
import { getAllApplicationOffer } from 'http/applications';
import moment from 'moment';
import {
  SEARCH_DEBOUNCE_DELAY,
  downloadCSVFile,
  findDataFromObject,
  getParamsValue,
} from 'pages/leads/components/CreateApplicationModal/utils';
import { shallowEqual, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { OfferProductType } from 'store/admin/types';
import { useApplicationsOffer } from 'store/applications/applicationOffer/hooks';
import { IOfferQuery, IOfferStatus, IOfferType, ProductType } from 'store/applications/applicationOffer/types';
import CommonModal from 'components/Modal/CommonModal';
import { useApplications } from 'store/applications/hooks';
import { ApplicationStatus, BasicOffer } from 'store/applications/types';
import { IApplicationOffer } from 'store/integration/types';
import { RootState } from 'store/types';
import { parseMoney } from 'utils';
import OfferModal from '../MakeOfferModal/OfferModal';
import useCommonStyle from '../../../../assets/css/Common.styles';
import useStyles from './ApplicationOffer.styles';
import { ReactComponent as ApplicationOffers } from '../../../../assets/icons/application_offers.svg';

const ApplicationOffer = ({ activeTab }: { activeTab: string }) => {
  const [openOfferModal, setOpenOfferModal] = useState<string>('');
  const [search, setSearch] = useState<string>('');
  const debouncedSearchTerm = useDebounce(search, SEARCH_DEBOUNCE_DELAY);
  const [tabValue, setTabValue] = useState<number | string>(0);
  const [queryData, setQueryData] = useState<{
    per_page: number;
    status: string;
    product_type: string;
    type: string;
  }>({
    per_page: ROWS_PER_TABLE_PAGE,
    status: '',
    product_type: '',
    type: '',
  });
  const [offerFilterData, setOfferFilterData] = useState<IOfferFilterData>({
    type: [],
    status: [],
    product_type: [],
  });
  const [showOfferFilterData, setShowOfferFilterData] = useState<IOfferFilterData>({
    type: [],
    status: [],
    product_type: [],
  });

  const classes = useStyles();
  const commonClasses = useCommonStyle();
  const data = useParams<{ id: string }>();
  const [page, setPage] = useState(1);
  const [openFilterModal, setOpenFilterModal] = useState(false);

  const { getApplicationOffer } = useApplicationsOffer();
  const { applicationDetails } = useApplications();
  const {
    applicationOffer = [],
    loading,
    total,
  } = useSelector((state: RootState) => state?.applicationOffer, shallowEqual);
  const appRegion = applicationDetails?.region;

  const isAppNotCompleted = !!applicationDetails && INCOMPLETED_APP_STATUS.includes(applicationDetails.status);
  const canMakeOffersOrFollowup =
    !!applicationDetails && applicationDetails.status === ApplicationStatus.OFFERS_REQUESTED;

  const toggleFilterModal = () => setOpenFilterModal(!openFilterModal);

  const handleFilterData = (event: ChangeEvent<HTMLInputElement>) => {
    const key: string = Object.keys(offerFilterData)[+tabValue];
    if (event?.target?.checked) {
      setOfferFilterData({
        ...offerFilterData,
        [key]:
          key === 'type'
            ? [event?.target?.name]
            : [...offerFilterData[key as keyof typeof offerFilterData], event?.target?.name],
      });
    } else {
      setOfferFilterData({
        ...offerFilterData,
        [key]: offerFilterData[key as keyof typeof offerFilterData]?.filter((d: string) => d !== event?.target?.name),
      });
    }
  };

  const handleRemoveFilter = (value: string, name: string) => {
    const values: IOfferFilterData = {
      ...offerFilterData,
      [name]: offerFilterData[name as keyof typeof offerFilterData]?.filter((d: string) => d !== value),
    };
    setQueryData({
      ...queryData,
      type: findDataFromObject(IOfferType, values?.type)?.join(','),
      product_type: findDataFromObject(ProductType, values?.product_type)?.join(','),
      status: values?.status?.length !== 10 ? findDataFromObject(IOfferStatus, values?.status)?.join(',') : '',
    });
    setShowOfferFilterData(values);
    setOfferFilterData(values);
  };

  const handleApplyFilter = () => {
    setQueryData({
      ...queryData,
      type: findDataFromObject(IOfferType, offerFilterData?.type)?.join(','),
      product_type: findDataFromObject(ProductType, offerFilterData?.product_type)?.join(','),
      status:
        offerFilterData?.status?.length !== 10
          ? findDataFromObject(IOfferStatus, offerFilterData?.status)?.join(',')
          : '',
    });
    setShowOfferFilterData(offerFilterData);
    toggleFilterModal();
  };

  const columnsData: GridColDef[] = [
    {
      field: 'created_at',
      headerName: 'DATE STARTED',
      valueGetter: (params: GridValueGetterParams) => moment(params?.row?.created_at).format('DD/MM/YYYY hh:mm a'),
      width: 170,
    },
    { field: 'lenderDisplayName', headerName: 'LENDER', width: 170 },
    {
      field: 'type',
      headerName: 'LENDER TYPE',
      width: 170,
    },
    {
      field: 'productType',
      headerName: 'PRODUCT',
      width: 170,
    },
    {
      field: 'status',
      headerName: 'STATUS',
      width: 170,
      renderCell: (params: GridRenderCellParams) => {
        const { color, value } = getApplicationStatusAndColorClass(params?.row?.status);
        return (
          <span
            className={clsx([commonClasses.badge, commonClasses[`${color as 'rejected' | 'accepted' | 'pending'}`]])}
          >
            {value || '-'}
          </span>
        );
      },
    },
    {
      field: 'min_credit_offer',
      headerName: 'MIN CREDIT',
      valueGetter: (params: GridValueGetterParams) => `£${params?.row?.minCreditOffer || 0}`,
      width: 170,
    },
    {
      field: 'max_credit_offer',
      headerName: 'MAX CREDIT',
      valueGetter: (params: GridValueGetterParams) => `£${params?.row?.maxCreditOffer || 0}`,
      width: 170,
    },
    {
      field: 'applicantName',
      headerName: 'BORROWED AMOUNT',
      width: 170,
      renderCell: (params: GridRenderCellParams) => {
        const offer: IApplicationOffer = params?.row;
        if (!offer) return parseMoney(0, mapAppRegionToSupportedCurrency(appRegion));
        let borrowedAmount = 0;
        if (offer?.product_type === OfferProductType.REVENUE_BASED) {
          borrowedAmount = offer?.revenueRepayments?.find((r) => r?.selected)?.total_get ?? 0;
        } else {
          borrowedAmount = offer?.rates?.find((r) => r?.selected)?.principal ?? 0;
        }
        return parseMoney(borrowedAmount, mapAppRegionToSupportedCurrency(appRegion));
      },
    },
    {
      field: 'duration_in_months',
      headerName: 'DURATION',
      valueGetter: (params: GridValueGetterParams) => `${params?.row?.duration_in_months || 0}`,
      width: 150,
    },
  ];

  const downloadCSV = async () => {
    await getAllApplicationOffer(data?.id).then((res) => {
      const AllOfferData = res?.offers?.map((d: BasicOffer) => {
        return {
          'DATE STARTED': moment(d?.created_at).format('DD/MM/YYYY hh:mm a'),
          LENDER: d?.lenderDisplayName,
          'LENDER TYPE': d?.type,
          PRODUCT: d?.productType,
          STATUS: d?.status,
          'MIN CREDIT': d?.min_credit_offer,
          'MAX CREDIT': d?.max_credit_offer,
          'BORROWED AMOUNT': 0,
          DURATION: d?.duration_in_months,
        };
      });
      downloadCSVFile(AllOfferData, 'BScore_application_offer.csv');
    });
  };

  const offerQueryData: IOfferQuery = useMemo(
    () => ({
      page,
      ...getParamsValue({
        ...queryData,
        search: debouncedSearchTerm,
      }),
    }),
    [page, queryData, debouncedSearchTerm],
  );

  const getApplicationDetailsOfferList = useCallback(() => {
    getApplicationOffer({
      id: data?.id,
      query: offerQueryData,
    });
  }, [offerQueryData, data, getApplicationOffer]);

  useEffect(() => {
    if (data?.id || activeTab === '1') {
      getApplicationDetailsOfferList();
    }
    // eslint-disable-next-line
  }, [getApplicationDetailsOfferList]);

  return (
    <>
      <Box className={classes.formControl}>
        <CustomFilter
          downloadCSV={downloadCSV}
          setSearch={setSearch}
          search={search}
          pageSize={ROWS_PER_TABLE_PAGE}
          total={total}
          page={page}
          setPage={setPage}
          toggleFilterModal={toggleFilterModal}
        />
      </Box>
      <CommonChip data={showOfferFilterData} handleRemoveFilter={handleRemoveFilter} />
      <Box className={classes.customTable}>
        <CustomTable
          rows={applicationOffer || []}
          columns={columnsData}
          hideFooterPagination={true}
          loading={loading}
          disableColumnMenu={true}
          NoDataFountMessage="No offers for this application"
          linkTitle={((!isAppNotCompleted || canMakeOffersOrFollowup) && 'Create an offer') || ''}
          NoDataFoundOnclick={() => (!isAppNotCompleted || canMakeOffersOrFollowup) && setOpenOfferModal('1')}
        />
      </Box>
      {openFilterModal && (
        <FilterModal
          setValue={setTabValue}
          value={+tabValue}
          filterTabName={['Lender Type', 'Status', 'Product type']}
          open={openFilterModal}
          onClose={toggleFilterModal}
          filterOption={OfferFilterOption}
          handleApplyFilter={handleApplyFilter}
          filterData={offerFilterData}
          handleFilterData={handleFilterData}
        />
      )}

      {!!openOfferModal && (
        <CommonModal
          open={!!openOfferModal}
          onClose={() => setOpenOfferModal('')}
          title="Offer box"
          icon={<ApplicationOffers />}
        >
          <OfferModal onClose={() => setOpenOfferModal('')} />
        </CommonModal>
      )}
    </>
  );
};

export default ApplicationOffer;
