import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),

    '& .MuiGrid-item': {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
  textInput: {
    minWidth: '100%',

    '& .MuiInputBase-root': {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      fontWeight: 700,
      height: 36,
    },

    '& .Mui-disabled': {
      backgroundColor: theme.palette.grey[200],
      color: theme.palette.common.black,
    },

    '& .Mui-error': {
      color: theme.palette.error.main,
      marginLeft: 0,
      marginRight: 0,
    },

    [theme.breakpoints.down('xs')]: {
      minWidth: 'auto',
    },
  },
  title: {
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(24),
    marginBottom: 12,
  },
  subtitle: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20),
    fontWeight: 700,
  },
  inputBox: {
    width: '100%',
    boxShadow: 'none !important',
    borderRadius: '8px',
    padding: '12px !important',
    fontWeight: '500!important',
    position: 'relative',
    fontFamily: 'jost !important',
    color: '#667085',
    height: '46px',
    display: 'flex',
    alignItems: 'center',
    border: `1px solid #d0d5dd`,
    '&:focus': {
      border: `1px solid #d0d5dd`,
      outline: 'none',
    },
  },
  fontWeight_500: {
    fontWeight: '500!important',
  },
  formInlineLblValue_value: {
    position: 'relative',
  },
  fontJost: {
    fontFamily: 'jost !important',
  },
  gray_500: {
    color: `${theme.palette.Gray[500]} !important`,
  },
  black_500: {
    color: '#000000',
  },
  inputLbl: {
    lineHeight: '1.75rem !important',
    marginBottom: '0.5rem !important',
    display: 'block !important',
  },
}));
