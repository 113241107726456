import { useEffect, useState } from 'react';
import { Avatar, Box, Button, Card, Grid, Stack, Tooltip, Typography } from '@mui/material';
import { Header } from 'components/Header/Header';
import { useTranslation } from 'react-i18next';
import { Cached, ChevronLeft } from '@material-ui/icons';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import routes from 'router/routes';
import { IntegrationConfig } from 'pages/integrations/Integrations';
import { isValidArray } from 'pages/leads/components/CreateApplicationModal/utils';
import { useTooltipStyles } from '../settings/Api/components/ApiSecret/ApiSecret.styles';
import useStyles from './Integration.styles';
import useCommonStyles from '../../assets/css/Common.styles';
import logoIcon from '../../assets/icons/logoicon.svg';
import { ReactComponent as ContentCopyIcon } from '../../assets/icons/copy_icon.svg';

const Integration = () => {
  const [copied, setCopied] = useState(false);
  const { t } = useTranslation();
  const commonClasses = useCommonStyles();
  const classes = useStyles();
  const { state }: { state: { data: IntegrationConfig } } = useLocation();
  const tooltipClasses = useTooltipStyles();

  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 500);
    }
  }, [copied]);
  return (
    <Box>
      <Header name="Integrations" />
      <Box className={commonClasses.mainWrapper}>
        <Box className={clsx([commonClasses.pageBackWrap, commonClasses.mainWrapper])}>
          <Link to={routes?.integrations} className={commonClasses.pageBackLink}>
            <ChevronLeft className={commonClasses.pageBackIcon} />
            Back to all integrations
          </Link>
        </Box>
        <Box sx={{ mt: 6, px: 4 }}>
          <Grid container>
            <Grid item xs={12} lg={12} xl={12}>
              <Box sx={{ px: 8, py: 6, height: '100%' }} className={clsx([commonClasses.cardShadow])}>
                <Grid container spacing={6} direction="row" justifyContent="space-between" alignItems="start">
                  <Grid item xs={12} md={6.4}>
                    <Box className={classes.integrateKeyLeft}>
                      <Box sx={{ mb: 6 }}>
                        <Typography
                          className={clsx([commonClasses.fontJost])}
                          component="h3"
                          variant="text_XL"
                          sx={{ mb: 2 }}
                        >
                          {(state?.data && state?.data?.integration) || '-'}
                        </Typography>
                        <Typography
                          className={clsx([commonClasses.fontJost])}
                          component="p"
                          variant="text_SM"
                          sx={{ mb: 4 }}
                        >
                          {state?.data?.description}
                        </Typography>

                        {false && (
                          <>
                            <Typography
                              className={clsx([commonClasses.fontJost])}
                              component="p"
                              variant="text_MD"
                              sx={{ mb: 2 }}
                            >
                              consectetur adipiscing elit duis tristique sollicitudin nibh sit amet commodo nulla
                              facilisi nullam vehicula ipsum a arcu cursus vitae congue
                            </Typography>
                            <Typography
                              className={clsx([commonClasses.fontJost])}
                              component="p"
                              variant="text_MD"
                              sx={{ mb: 2 }}
                            >
                              consectetur adipiscing elit duis tristique sollicitudin nibh sit amet commodo nulla
                              facilisi nullam vehicula ipsum a arcu cursus vitae congue
                            </Typography>
                            <Typography
                              className={clsx([commonClasses.fontJost, commonClasses.fontWeight_500])}
                              component="p"
                              variant="text_MD"
                              sx={{ mb: 2, mt: 6 }}
                            >
                              Required steps
                            </Typography>
                            <Typography
                              className={clsx([commonClasses.fontJost])}
                              component="p"
                              variant="text_MD"
                              sx={{ mb: 2 }}
                            >
                              consectetur adipiscing elit duis tristique sollicitudin nibh sit amet commodo nulla
                              facilisi nullam vehicula ipsum a arcu cursus vitae congue
                            </Typography>
                            <Typography
                              className={clsx([commonClasses.fontJost])}
                              component="p"
                              variant="text_MD"
                              sx={{ mb: 2 }}
                            >
                              consectetur adipiscing elit duis tristique sollicitudin nibh sit amet commodo nulla
                              facilisi nullam vehicula ipsum a arcu cursus vitae congue
                            </Typography>
                          </>
                        )}
                      </Box>
                      {false && (
                        <Box>
                          <Button sx={{ width: '100%' }} variant="contained" startIcon={<Cached />} className="">
                            {/* Regenerate key */} Connect with Business Score
                          </Button>
                        </Box>
                      )}
                      {/* Admin lender Close Corporation end */}
                      <Box sx={{ mt: 6 }}>
                        {false && (
                          <Typography
                            className={clsx([commonClasses.fontJost, commonClasses.fontWeight_500])}
                            component="p"
                            variant="text_SM"
                            sx={{ mb: 2 }}
                          >
                            Lorem ipsum amet
                          </Typography>
                        )}
                        {isValidArray(state?.data?.secrets || []) &&
                          state?.data?.secrets?.map((d) => {
                            const keyValue = '*'.repeat(d.value.length);
                            return (
                              <Box className={clsx([commonClasses.marginBottom10px])}>
                                <Typography className={clsx([commonClasses.marginBottom10px])} variant="caption">
                                  {d.displayName}
                                </Typography>
                                <Box className={clsx([classes.boxInputKey])}>
                                  <Typography
                                    className={clsx([
                                      commonClasses.fontJost,
                                      commonClasses.boxInputText,
                                      classes.boxInputText_140,
                                    ])}
                                    component="p"
                                    variant="text_SM"
                                  >
                                    {keyValue}
                                  </Typography>
                                  <Tooltip
                                    classes={tooltipClasses}
                                    title={(copied ? t('global.copied') : t('global.copySecret')) as string}
                                    placement="top-start"
                                    arrow
                                  >
                                    <Button
                                      variant="text"
                                      disableRipple={true}
                                      onClick={() => {
                                        navigator.clipboard.writeText(d?.value);
                                        setCopied(true);
                                      }}
                                      className={clsx([classes.btnBlueText, classes.boxInputKeyBtn])}
                                      startIcon={<ContentCopyIcon />}
                                    >
                                      Copy key
                                    </Button>
                                  </Tooltip>
                                </Box>
                              </Box>
                            );
                          })}
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={5.6}>
                    <Box sx={{ p: 8 }} className={classes.integrateKeyBox}>
                      <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Box>
                          <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={4}>
                            <Box sx={{ width: 105, height: 105 }} className={classes.KeyBoxSmall}>
                              <Card
                                className={clsx([classes.integrateToolBox])}
                                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                              >
                                <Avatar
                                  variant="square"
                                  alt="Business score"
                                  src={logoIcon}
                                  sx={{ width: 64, height: 64 }}
                                />
                              </Card>
                            </Box>
                          </Stack>
                        </Box>
                        <Box className={clsx([classes.integrateLine])} />
                        <Box>
                          <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={4}>
                            <Box sx={{ width: 105, height: 105 }} className={classes.KeyBoxSmall}>
                              <Card
                                className={clsx([classes.integrateToolBox])}
                                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                              >
                                <Avatar
                                  variant="square"
                                  alt="Remy Sharp"
                                  src={`https://placehold.co/800?text=${state?.data?.integration}&font=roboto`}
                                  sx={{ width: 64, height: 64 }}
                                />
                              </Card>
                            </Box>
                          </Stack>
                        </Box>
                      </Stack>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default Integration;
