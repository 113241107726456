import { apiCall } from 'http/index';
import { CreateReminder, RemindersListResponse, RemindersQuery } from 'store/reminders/types';

export const getReminders = async (query: RemindersQuery = {}): Promise<RemindersListResponse> => {
  let apiPath = '/admin/reminders';
  const queryString = new URLSearchParams(query as Record<string, string>).toString();
  if (queryString) {
    apiPath += `?${queryString}`;
  }
  return apiCall({ method: 'get', url: apiPath });
};

export const checkReminder = async (id: string, done: boolean): Promise<null> =>
  apiCall({ method: 'put', url: `/admin/reminders/${id}`, data: { done } });

export const createReminder = async (payload: CreateReminder): Promise<null> =>
  apiCall({ method: 'post', url: '/admin/reminders', data: payload });
