import { FC, useState, useEffect, memo } from 'react';
import { Box, Button, Tooltip as MuiTooltip } from '@material-ui/core';
import { FileCopyOutlined } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import Prism from 'prismjs';
import isEqual from 'lodash.isequal';

import copy from 'copy-to-clipboard';
import { WIDGET_BUNDLE_URL } from 'core/constants';
import { WidgetFormConfig, WidthType } from 'core/types';

enum LanguageType {
  html = 'language-html',
}

interface EmbedCodeProps {
  publicKey: string;
  widgetConfig: WidgetFormConfig;
  savedConfigMode: boolean;
}

const EmbedCode: FC<EmbedCodeProps> = ({ publicKey, widgetConfig, savedConfigMode }) => {
  const [copied, setCopied] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    Prism.highlightAll();
  }, [widgetConfig]);

  const {
    widthType,
    width,
    height,
    partnerName,
    logoUrl,
    kycEnabled,
    exitEnabled,
    captchaEnabled,
    popupEnabled,
    letterLogoUrl,
    fontName,
    primaryColor,
    secondaryColor,
  } = widgetConfig;

  const optionalConfig = `kycEnabled: ${kycEnabled},\n          exitEnabled: ${exitEnabled},\n          captchaEnabled: ${captchaEnabled},${
    partnerName ? `\n          partnerName: "${partnerName}",` : ''
  }${logoUrl ? `\n          logoUrl: "${logoUrl}",` : ''}${
    letterLogoUrl ? `\n          letterLogoUrl: "${letterLogoUrl}",` : ''
  }${fontName ? `\n          fontName: "${fontName}",` : ''}${
    primaryColor ? `\n          primaryColor: "${primaryColor}",` : ''
  }${secondaryColor ? `\n          secondaryColor: "${secondaryColor}",` : ''}`;

  const embedCode = `
  <!DOCTYPE html>
  <html lang="en">
  <title>Business Score widget</title>
  <meta charset="UTF-8" />
  <meta name="viewport" content="width=device-width, initial-scale=1" />
  <script src="${WIDGET_BUNDLE_URL}"></script>
  <body>
    <script>
      window.onload = function() {
        BusinessScoreWidget({
          elementId: "bs-widget",
          publicKey: "${publicKey}",
          width: "${width + widthType}",
          height: "${`${height}${WidthType.px}`}",${popupEnabled ? `\n          popup: ${popupEnabled},` : ''}${
    savedConfigMode ? '' : optionalConfig
  }
        }).show()
      }
    </script>

    <div id="bs-widget"></div>
  </body>
  </html>
  `;

  const copyToClipboard = async () => {
    copy(embedCode);
    setCopied(true);
  };

  return (
    <>
      <Box display="flex" flexDirection="column" alignItems="flex-end">
        <MuiTooltip title={(copied ? t('global.copied') : t('global.copySecret')) as string} arrow>
          <Button variant="text" color="default" disableRipple onClick={copyToClipboard} endIcon={<FileCopyOutlined />}>
            {(copied ? t('global.copied') : t('global.copySecret')) as string}
          </Button>
        </MuiTooltip>
      </Box>

      <pre>
        <code className={LanguageType.html}>{embedCode}</code>
      </pre>
    </>
  );
};

export default memo(EmbedCode, isEqual);
