import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  switchContainer: {
    display: 'flex',
    position: 'relative',
    width: 120,
  },
  switchLabel: {
    '& > span': {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(18),
    },
  },
  loader: {
    position: 'absolute',
    right: 32,
    top: 8,
  },
}));
