import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  container: {
    '& .MuiFormControl-root': {
      marginTop: 0,
      marginBottom: 0,
      borderRadius: '6px !important',
      border: `1px solid #d0d5dd`,
    },

    '& .MuiInput-underline:before, .MuiInput-underline:after, .MuiInput-underline:hover:before': {
      border: 'none',
    },

    '& .MuiInputBase-input': {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      fontWeight: 400,
      width: '100%',
      height: 46,
      textAlign: 'left',
      color: theme.palette.Gray[700],
      borderRadius: '8px',
      paddingTop: 0,
      paddingBottom: 0,
      cursor: 'pointer',
      paddingLeft: '12px',
    },
  },
  dateContainer: {
    position: 'relative',
    '& .MuiFormControl-marginNormal': {
      marginTop: 0,
      marginBottom: 0,
    },
    '& .MuiInputBase-input': {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      fontWeight: 700,
      borderRadius: 4,
      paddingLeft: 13,
      cursor: 'pointer',
      border: `1px solid ${theme.palette.greyLightBorder.main}`,
      height: 22,
    },
    '& .MuiInput-underline:before, .MuiInput-underline:after, .MuiInput-underline:hover:before': {
      border: 'none',
    },
  },
  icon: {
    width: 14,
    height: 14,
    color: '#687C8D',
    position: 'absolute',
    top: 40,
    right: 10,
  },
  inputError: {
    '& .MuiInputBase-input': {
      borderColor: theme.palette.error.main,
    },
  },
  iconStyle: { marginLeft: '10px' },
  fontWeight_500: {
    fontWeight: '500!important',
  },
  formInlineLblValue: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  formInlineLblValue_lbl: {
    position: 'relative',
  },
  fontJost: {
    fontFamily: 'jost !important',
  },
  gray_500: {
    color: `${theme.palette.Gray[500]} !important`,
  },
  inputLbl: {
    lineHeight: '1.75rem !important',
    marginBottom: '0.5rem !important',
    display: 'block !important',
  },
  formInlineLblValue_value: {
    position: 'relative',
    width: '100%',
  },
  formLblValue: {
    marginBottom: '1.5rem !important',
  },
}));
