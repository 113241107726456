import { CompanySearchResult, CreateApplicant, EcommerceProviders } from 'core/types';
import { CompanyTransactionsList, CompanyTransactionsQuery, CompanyValuation } from 'store/applications/types';
import { apiCall } from '../index';

export const getCompanyValuations = async (
  companyId: string,
  provider: EcommerceProviders,
): Promise<CompanyValuation[]> =>
  apiCall({ method: 'get', url: `/companies/${companyId}/valuations`, params: { provider } });

export const getCompanyTransactions = async (
  companyId: string,
  query: CompanyTransactionsQuery,
): Promise<CompanyTransactionsList> =>
  apiCall({ method: 'get', url: `/companies/${companyId}/transactions`, params: query });

export const searchCompanies = async (term: string): Promise<CompanySearchResult[]> =>
  apiCall({ method: 'get', url: '/search/companies', params: { term } });

export const searchDirectors = async (companyNumber: string): Promise<CreateApplicant[]> =>
  apiCall({ method: 'get', url: '/search/directors', params: { company_number: companyNumber } });
