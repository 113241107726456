import { useCallback } from 'react';
import { Account } from 'store/account/types';
import useScript from './useScript';

type Intercom = typeof window.Intercom;

const onUnload = () => {
  window.Intercom?.('shutdown');
};

export const useShowIntercom = (): ((message: string) => void) => {
  const showIntercomNewMessage = useCallback((message: string) => {
    window.Intercom?.('showNewMessage', message);
  }, []);

  return showIntercomNewMessage;
};

const useIntercom = (appId: string, account: Account): Intercom | undefined => {
  const onLoad = useCallback(() => {
    if (account.id) {
      const { booted } = window.Intercom;
      const command = booted ? 'update' : 'boot';

      const { owner } = account;

      window.Intercom(command, {
        app_id: appId || '',
        user_id: owner?.id || '',
        email: owner?.email || '',
        name: `${owner?.first_name} ${owner?.last_name}` || '',
        created_at: 1312182000,
        company: {
          id: account?.id || '',
          name: account?.name || '',
        },
      });
    }
  }, [appId, account]);

  useScript('bscore-intercom', 'intercom-script', `https://widget.intercom.io/widget/${appId}`, onLoad, onUnload);
  return window.Intercom;
};

export default useIntercom;
