import { Select } from '@material-ui/core';
import clsx from 'clsx';
import { Box, Typography } from '@mui/material';
import { ChangeEvent } from 'react';
import ErrorMeg from 'components/inputs/ErrorMessage/ErrorMessage';
import useStyles from './CustomSelect.styles';

interface Option {
  value: string | number;
  label: string;
}

interface StateOverviewFilterProps {
  onChange?: (event: ChangeEvent<{ value: unknown }>) => void;
  value: string | number | null;
  options: Option[];
  name: string;
  label?: string;
  placeholder?: string;
  formik?: boolean;
  disabled?: boolean;
  mainDivClassShow?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  className?: any;
}

const CustomSelectBox = ({
  formik = true,
  onChange,
  value,
  options,
  name,
  label,
  placeholder,
  disabled = false,
  mainDivClassShow = true,
  className,
}: StateOverviewFilterProps) => {
  const classes = useStyles();

  return (
    <Box className={clsx([mainDivClassShow && classes.formLblValue])}>
      {label && (
        <Typography
          variant="text_MD"
          component="label"
          className={clsx([classes.fontJost, classes.fontWeight_500, classes.gray_500, classes.inputLbl])}
        >
          {label}
        </Typography>
      )}
      <Box className={clsx([classes.formInlineLblValue])}>
        <Box className={clsx([classes.formInlineLblValue_value])}>
          <Select
            native
            variant="outlined"
            name={name}
            disabled={disabled}
            className={clsx([classes.select, classes.selectBoxDropdown], clsx(className))}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
          >
            {options?.map((d: Option) => {
              return (
                <option key={d?.value} value={d?.value}>
                  {d?.label}
                </option>
              );
            })}
          </Select>
        </Box>
      </Box>
      {formik && <ErrorMeg name={name} />}
    </Box>
  );
};
export default CustomSelectBox;
