import { ChevronLeft } from '@material-ui/icons';
import { Box, Grid, Typography } from '@mui/material';
import { Header } from 'components/Header/Header';
import clsx from 'clsx';
import * as yup from 'yup';
import { Link } from 'react-router-dom';
import TextFieldBox from 'components/CustomTextInput/TextInputBox';
import { Formik } from 'formik';
import CustomSelectBox from 'components/CustomSelect/CustomSelect';
import { useTranslation } from 'react-i18next';
import routes from 'router/routes';
import ReactQuill from 'react-quill';
import { formats, modules } from 'pages/settings/Faqs/constants';
import useCommonStyle from '../../assets/css/Common.styles';
import useStyles from './CreateWorkFlow.styles';

const CreateWorkFlow = () => {
  const commonClasses = useCommonStyle();
  const classes = useStyles();
  const { t } = useTranslation();

  const initialValues = {
    name: '',
    type: '',
    recipient: '',
    scenario: '',
    send: '',
    email: '',
  };

  const onSubmit = () => {};

  const formSchema = yup.object({
    email: yup
      .string()
      .email(t('pages.team.inviteUser.inputs.email.error'))
      .required(t('pages.team.inviteUser.inputs.email.required'))
      .nullable(),
    role: yup.string().required(t('pages.team.inviteUser.inputs.role.required')).nullable(),
  });

  return (
    <Box>
      <Header name="Workflows" />
      <Box sx={{ px: 4 }}>
        <Box className={commonClasses.pageBackWrap}>
          <Link to={routes?.workFlow} className={commonClasses.pageBackLink}>
            <ChevronLeft className={commonClasses.pageBackIcon} />
            Back to all workflows
          </Link>
        </Box>
        <Grid container>
          <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={formSchema}>
            {({ values, setFieldValue }) => {
              return (
                <Grid item xs={12} lg={12} xl={12}>
                  <Box sx={{ px: 8, py: 6, height: '100%' }} className={clsx([commonClasses.cardShadow])}>
                    <Box sx={{ mb: 8, mx: 'auto' }} className={clsx([classes.newWorkflowWrap])}>
                      <Box sx={{ mb: 8 }}>
                        <Typography
                          className={clsx([commonClasses.fontJost, commonClasses.fontWeight_500, classes.gray_800])}
                          component="p"
                          variant="text_XL"
                          sx={{ mb: 1 }}
                          align="center"
                        >
                          Create a new workflow
                        </Typography>
                        <Typography
                          className={clsx([commonClasses.fontJost, commonClasses.gray_700])}
                          component="p"
                          variant="text_SM"
                          align="center"
                          sx={{ mb: 1 }}
                        >
                          Automate sending mails and reminders to partners and lenders
                        </Typography>
                      </Box>
                      <Box className={clsx([classes.filedBoxWorkFlow])}>
                        <Typography
                          className={clsx([commonClasses.fontJost, commonClasses.gray_700, classes.textStyle])}
                          component="p"
                          variant="text_SM"
                          sx={{ mb: 1 }}
                        >
                          Workflow name
                        </Typography>
                        <TextFieldBox name="name" value={values?.name} />
                      </Box>
                      <Box className={clsx([classes.integrateLineY_76])} />
                      <Box className={clsx([classes.filedBoxWorkFlow])}>
                        <Typography
                          className={clsx([commonClasses.fontJost, commonClasses.gray_700, classes.textStyle])}
                          component="p"
                          variant="text_SM"
                          sx={{ mb: 1 }}
                        >
                          Workflow type
                        </Typography>
                        <CustomSelectBox
                          name="type"
                          placeholder="Select type"
                          value={values?.type}
                          options={[
                            {
                              value: '',
                              label: '',
                            },
                            { value: 'Email', label: 'Email' },
                          ]}
                        />
                      </Box>
                      <Box className={clsx([classes.integrateLineY_76])} />
                      <Box className={clsx([classes.filedBoxWorkFlow])}>
                        <Typography
                          className={clsx([commonClasses.fontJost, commonClasses.gray_700, classes.textStyle])}
                          component="p"
                          variant="text_SM"
                          sx={{ mb: 1 }}
                        >
                          Recipient
                        </Typography>
                        <CustomSelectBox
                          name="recipient"
                          placeholder="Select recipient"
                          value={values?.recipient}
                          options={[
                            {
                              value: '',
                              label: '',
                            },
                            { value: 'Email', label: 'Email' },
                          ]}
                        />
                      </Box>
                      <Box className={clsx([classes.integrateLineY_76])} />
                      <Box className={clsx([classes.filedBoxWorkFlow])}>
                        <Typography
                          className={clsx([commonClasses.fontJost, commonClasses.gray_700, classes.textStyle])}
                          component="p"
                          variant="text_SM"
                          sx={{ mb: 1 }}
                        >
                          What scenario does this apply?
                        </Typography>
                        <CustomSelectBox
                          name="recipient"
                          placeholder="Select recipient"
                          value={values?.recipient}
                          options={[
                            {
                              value: '',
                              label: '',
                            },
                            {
                              value: 'When an application mail is received',
                              label: 'When an application mail is received',
                            },
                          ]}
                        />
                      </Box>
                      <Box className={clsx([classes.integrateLineY_76])} />
                      <Box className={clsx([classes.filedBoxWorkFlow])}>
                        <Typography
                          className={clsx([commonClasses.fontJost, commonClasses.gray_700, classes.textStyle])}
                          component="p"
                          variant="text_SM"
                          sx={{ mb: 1 }}
                        >
                          When this happens, send?
                        </Typography>
                        <CustomSelectBox
                          name="recipient"
                          placeholder="Select an option"
                          value={values?.recipient}
                          options={[
                            {
                              value: '',
                              label: '',
                            },
                            {
                              value: 'When an application mail is received',
                              label: 'When an application mail is received',
                            },
                          ]}
                        />
                        <Typography
                          className={clsx([commonClasses.fontJost, commonClasses.gray_700, classes.textStyle])}
                          component="p"
                          variant="text_SM"
                          sx={{ mb: 1 }}
                        >
                          When this happens, send?
                        </Typography>
                        <ReactQuill
                          theme="snow"
                          modules={modules}
                          onChange={(value: string) => setFieldValue('email', value)}
                          formats={formats}
                          readOnly={false}
                          value={values?.email}
                          preserveWhitespace
                          className={clsx([classes.editor])}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              );
            }}
          </Formik>
        </Grid>
      </Box>
    </Box>
  );
};

export default CreateWorkFlow;
