import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  container: {
    height: 196,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  status: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20),
    fontWeight: 600,
    wordBreak: 'break-word',
  },
  subtitle: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20),
    marginRight: 10,
  },
  semibold: {
    fontWeight: 600,
  },
  titleContainer: {
    display: 'flex',
    position: 'relative',
  },
  chip: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 700,
    lineHeight: theme.typography.pxToRem(20),
    marginLeft: theme.spacing(1),
    height: 22,
    padding: '1px 4px',
    marginTop: -1,
    backgroundColor: 'grey',
    '& span': {
      padding: 0,
    },
  },
  '& .MuiChip-root': {
    borderRadius: `0px !important`,
  },
  tooltipContainer: {
    border: `1px solid grey`,
    padding: theme.spacing(1),
    '& > p': {
      textTransform: 'capitalize',
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
    },
  },
  labelContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: -20,
    marginBottom: 4,
    padding: '0px 8px',
  },
  label: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(15),
    color: theme.palette.Gray[500],
  },
  iconContainer: {
    width: 80,
    overflow: 'hidden',
    position: 'absolute',
    right: 0,
    top: 0,
    '& img, & svg': {
      maxWidth: '100%',
      height: 'auto',
    },
  },
  chartContainer: {
    width: '100%',
  },
  metricsBoxTab: {
    boxShadow: 'none !important',
    overflow: 'unset !important',
  },
  perChip: {
    fontSize: `${theme.typography.pxToRem(12)} !important`,
    lineHeight: `${theme.typography.pxToRem(21)} !important`,
    backgroundColor: theme.palette.Gray[200],
    color: theme.palette.Gray[600],
    borderRadius: `${theme.typography.pxToRem(2)} !important`,
    padding: `${theme.spacing(1)} !important`,
    fontWeight: '500',
  },
  metricsBoxTabChart: {
    position: 'relative',
    '& img': {
      width: '100%',
    },
  },
  fontJost: {
    fontFamily: 'jost !important',
  },
}));
