import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useTooltipStyles = makeStyles((theme: Theme) => ({
  tooltip: {
    margin: 0,
    fontSize: theme.typography.pxToRem(14),
  },
}));

export default makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  text: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    userSelect: 'none',
    cursor: 'not-allowed',
  },
  secretOuterContainer: {
    flex: 1,
    display: 'flex',
    alignContent: 'flex-end',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    flexDirection: 'column',

    [theme.breakpoints.down('xs')]: {
      width: '100%',
      justifyContent: 'flex-start',
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
    },
  },
  secretInnerContainer: {
    padding: '0px 16px',
    border: `1px solid ${theme.palette.customGrey.main}`,
    width: 450,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    minHeight: 42,

    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  secretButton: {
    padding: 6,
    marginRight: -6,
    minWidth: 25,
    width: 25,

    '&:hover': {
      backgroundColor: 'transparent',
    },

    '& svg': {
      width: 20,
      height: 20,
    },
  },
  loadingContainer: {
    position: 'absolute',
    right: theme.spacing(2),
  },
}));
