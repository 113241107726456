import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import i18n from 'i18next';
import omitBy from 'lodash.omitby';
import isNil from 'lodash.isnil';

import { AccountRoles, User, UserQuery, UserRole } from 'core/types';
import {
  fetchAccount as fetchAccountApi,
  fetchApiKeys as fetchApiKeysApi,
  deleteAccountUser as deleteAccountUserApi,
  updateAccountUserRole as updateAccountUserRoleApi,
} from 'http/account';
import { inviteUser as inviteUserApi, getUsers as getUsersApi } from 'http/users';
import { composeFullName } from 'utils';
import { ROWS_PER_TABLE_PAGE } from 'core/constants';
import { mapUserRoleToAccountType } from 'core/utils';
import { Account, AccountState, ApiKeys } from './types';

export const FETCH_ACCOUNT = 'account/FETCH_ACCOUNT';
export const FETCH_API_KEYS = 'account/FETCH_API_KEYS';
export const INVITE_USER = 'account/INVITE_USER';
export const SET_ERROR = 'account/SET_ERROR';
export const SET_SUCCESS = 'account/SET_SUCCESS';
export const GET_ACCOUNT_USERS = 'account/GET_ACCOUNT_USERS';
export const DELETE_ACCOUNT_USER = 'account/DELETE_ACCOUNT_USER';
export const UPDATE_ACCOUNT_USER_ROLE = 'account/UPDATE_ACCOUNT_USER_ROLE';

export const fetchAccount = createAsyncThunk<Account>(FETCH_ACCOUNT, async () => {
  const account = await fetchAccountApi();
  return account;
});

export const fetchApiKeys = createAsyncThunk<ApiKeys>(FETCH_API_KEYS, async () => {
  const apiKeys = await fetchApiKeysApi();
  return apiKeys;
});

export const setError = createAction<string | boolean>(SET_ERROR);

export const setSuccess = createAction<string | boolean>(SET_SUCCESS);

export const inviteUser = createAsyncThunk<
  void,
  { email: string; role: UserRole; accountId?: string },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  { dispatch: any }
>(INVITE_USER, async ({ email, role, accountId }, { dispatch }) => {
  await inviteUserApi(email, role, accountId);
  const successMessage = i18n.t('pages.team.inviteUser.successMessage');
  dispatch(setSuccess(successMessage));
});

export const getAccountUsers = createAsyncThunk<{ users: User[]; total: number }, UserQuery>(
  GET_ACCOUNT_USERS,
  async (query) => {
    const data = await getUsersApi(omitBy(query, isNil));
    const users = data.users.map((user) => ({ ...user, fullName: composeFullName(user.first_name, user.last_name) }));
    return { users, total: data.total };
  },
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deleteAccountUser = createAsyncThunk<void, string, { dispatch: any; state: { account: AccountState } }>(
  DELETE_ACCOUNT_USER,
  async (id, { dispatch, getState }) => {
    const {
      account: { account },
    } = getState();
    if (
      account?.owner?.role &&
      account.owner.role !== UserRole.SUPER_ADMIN &&
      account.owner.role !== UserRole.ADMIN &&
      account.owner.role !== UserRole.COMPANY_OWNER
    ) {
      const accountType = mapUserRoleToAccountType(account.owner.role);
      await deleteAccountUserApi(accountType, id);
      dispatch(getAccountUsers({ per_page: ROWS_PER_TABLE_PAGE, page: 1 }));
      const successMessage = i18n.t('pages.team.deleteUser.successMessage');
      dispatch(setSuccess(successMessage));
    }
  },
);

export const updateAccountUserRole = createAsyncThunk<
  { index: number; role: AccountRoles } | null,
  { id: string; role: AccountRoles },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  { dispatch: any; state: { account: AccountState } }
>(UPDATE_ACCOUNT_USER_ROLE, async ({ id, role }, { dispatch, getState }) => {
  const {
    account: { account, users },
  } = getState();
  if (
    account?.owner?.role &&
    account.owner.role !== UserRole.SUPER_ADMIN &&
    account.owner.role !== UserRole.ADMIN &&
    account.owner.role !== UserRole.COMPANY_OWNER
  ) {
    const accountType = mapUserRoleToAccountType(account.owner.role);
    await updateAccountUserRoleApi(accountType, id, role);
    const successMessage = i18n.t('components.updateUserRoleModal.successMessage');
    dispatch(setSuccess(successMessage));
    const index = users.findIndex((user) => user.id === id);
    return { index, role };
  }
  return null;
});
