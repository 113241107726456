import { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { useActionCreator, usePrevious } from 'hooks';
import { RootState } from 'store/types';
import { RemindersReturnHook } from './types';
import { setError, setSuccess, getReminders, updateReminderAction } from './actions';

export const useReminders = (onSuccess?: () => void): RemindersReturnHook => {
  const remindersState = useSelector((state: RootState) => state.reminders, shallowEqual);
  const prevLoading = usePrevious(remindersState.loading);

  useEffect(() => {
    if (prevLoading && !remindersState.loading && !remindersState.error && onSuccess) {
      onSuccess();
    }
  }, [remindersState.error, remindersState.loading, onSuccess, prevLoading]);

  return {
    ...remindersState,
    setError: useActionCreator(setError),
    setSuccess: useActionCreator(setSuccess),
    getReminders: useActionCreator(getReminders),
    updateReminder: useActionCreator(updateReminderAction),
  };
};
