import { useSelector } from 'react-redux';
import { useActionCreator } from 'hooks';

import { RootState } from '../types';
import { setGlobalError } from './actions';
import { GlobalStateReturnHook } from './types';

export const useGlobalState = (): GlobalStateReturnHook => {
  const error = useSelector((state: RootState) => state.global.error);

  return { error, setGlobalError: useActionCreator(setGlobalError) };
};
