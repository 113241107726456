import { FC, useRef, useMemo, ChangeEvent } from 'react';
import { Box, FormControlLabel, FormHelperText, FormLabel, Grid, Switch } from '@material-ui/core';
import { Form, Formik, FormikProps } from 'formik';
import pickBy from 'lodash.pickby';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import clsx from 'clsx';
import CustomCheckbox from 'components/CustomCheckBox/CheckBox';
import CustomSelectBox from 'components/CustomSelect/CustomSelect';
import TextFieldBox from 'components/CustomTextInput/TextInputBox';
import { DocumentUpload } from 'components/DocumentUpload';
import FooterButton from 'components/Modal/FooterButton';
import { CustomLabel } from 'components/inputs';
import { accountCountryOptions, accountEmployeesOptions, accountIndustryOptions, websiteRegExp } from 'core/constants';
import { Document, DocumentUploadResponse } from 'core/types';
import { getFlagIcon } from 'core/utils';
import { updateLenderLogo } from 'http/lenders';
import { ApplicationRegion } from 'store/applications/types';
import { useLenders } from 'store/lenders/hooks';
import { CreateLender, LenderDetails } from 'store/lenders/types';
import { Typography } from '@mui/material';
import useStyles from './CreateLenderModal.styles';
import useCommonStyles from '../../../../assets/css/Common.styles';

interface FormValues {
  name: string;
  displayed_name: string;
  contact_first_name: string;
  contact_last_name: string;
  contact_email: string;
  active: boolean;
  address: string;
  logo_url: string;
  website: string;
  supported_regions: string[];
  phone_number: string;
  job_title: string;
  country_code: string;
  industry: string;
  employees: string;
}

interface CreateLenderModalProps {
  open: boolean;
  toggleOpen: () => void;
  lender?: LenderDetails;
}

const CreateLenderModal: FC<CreateLenderModalProps> = ({ open, toggleOpen, lender }) => {
  const formRef = useRef<FormikProps<FormValues> | null>(null);
  const classes = useStyles();
  const commonStyles = useCommonStyles();
  const { t } = useTranslation();
  const { loading, createLender, updateLender, setLenderLogo } = useLenders(() => {
    if (open) {
      formRef.current?.resetForm();
      toggleOpen();
    }
  });

  const lenderSchema = useMemo(
    () =>
      yup.object({
        name: yup.string().required(t('pages.lenders.admin.createLender.inputs.name.required')).nullable(),
        displayed_name: yup
          .string()
          .required(t('pages.lenders.admin.createLender.inputs.displayed_name.required'))
          .nullable(),
        contact_first_name: yup
          .string()
          .required(t('pages.lenders.admin.createLender.inputs.contact_first_name.required'))
          .nullable(),
        contact_last_name: yup
          .string()
          .required(t('pages.lenders.admin.createLender.inputs.contact_last_name.required'))
          .nullable(),
        contact_email: yup
          .string()
          .email(t('pages.lenders.admin.createLender.inputs.contact_email.error'))
          .required(t('pages.lenders.admin.createLender.inputs.contact_email.required'))
          .nullable(),
        active: yup.boolean(),
        logo_url: yup.string().url(t('pages.lenders.admin.createLender.inputs.logo_url.error')),
        website: yup.string().matches(websiteRegExp, t('pages.lenders.admin.createLender.inputs.website.error')),
        address: yup.string(),
        supported_regions: yup
          .array(yup.string())
          .min(1, t('pages.lenders.admin.createLender.inputs.supported_regions.required')),
        phone_number: yup.string(),
        job_title: yup.string(),
        country_code: yup.string().required(t('pages.lenders.admin.createLender.inputs.country_code.required')),
        industry: yup.string(),
        employees: yup.string(),
      }),
    [t],
  );

  const initialValues: FormValues = {
    name: lender?.name ?? '',
    displayed_name: lender?.displayed_name ?? '',
    contact_first_name: lender?.contact_person_name?.split(' ')[0] ?? '',
    contact_last_name: lender?.contact_person_name?.split(' ')[1] ?? '',
    contact_email: lender?.contact_person_email ?? '',
    active: lender?.active ?? false,
    address: lender?.address ?? '',
    logo_url: lender?.logo_url ?? '',
    website: lender?.website ?? '',
    supported_regions: lender?.supported_regions ?? [],
    phone_number: lender?.phone_number ?? '',
    job_title: lender?.job_title ?? '',
    country_code: lender?.country_code ?? '',
    industry: lender?.industry ?? '',
    employees: lender?.employees ?? '',
  };

  const onSubmit = async (values: FormValues) => {
    const lenderData = { ...pickBy(values), active: values?.active } as CreateLender;

    if (lender?.id) {
      updateLender({ id: lender.id, lender: lenderData });
    } else {
      createLender(lenderData);
    }
  };

  const uploadDocument = async (data: FormData): Promise<DocumentUploadResponse | null> => {
    if (lender) {
      return updateLenderLogo(lender.id, data);
    }
    return null;
  };

  const onUploadSuccess = (document: Document) => {
    if (lender && document.url) {
      setLenderLogo({ lenderId: lender.id, logoUrl: document.url });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={lenderSchema}
      innerRef={formRef}
      enableReinitialize
    >
      {({ values, errors, touched, handleSubmit, setFieldValue }) => (
        <Box className={clsx([commonStyles.filter_cardBody])}>
          <Form noValidate>
            <Grid container direction="column">
              <Grid item>
                <Typography
                  variant="text_MD"
                  component="label"
                  className={clsx([
                    commonStyles.fontJost,
                    commonStyles.fontWeight_500,
                    commonStyles.gray_500,
                    commonStyles.inputLbl,
                  ])}
                >
                  {t('pages.lenders.admin.createLender.inputs.supported_regions.label')}
                </Typography>
                <Box className={classes.supportedRegionsContainer}>
                  {Object.values(ApplicationRegion).map((region) => {
                    return (
                      <CustomCheckbox
                        key={region}
                        formik={false}
                        label={region}
                        name={region}
                        icon={getFlagIcon(region)}
                        value={region}
                        checked={values?.supported_regions?.includes(region)}
                        onChange={(e) => {
                          if (e?.target?.checked) {
                            setFieldValue('supported_regions', [
                              ...(values?.supported_regions || []),
                              e?.target?.value,
                            ]);
                          } else {
                            setFieldValue(
                              'supported_regions',
                              (values?.supported_regions || [])?.filter((d) => d !== e?.target?.value),
                            );
                          }
                        }}
                      />
                    );
                  })}
                </Box>

                {touched.supported_regions && errors.supported_regions && (
                  <FormHelperText error>{errors.supported_regions}</FormHelperText>
                )}
              </Grid>

              <Grid item>
                <TextFieldBox
                  name="name"
                  value={values?.name}
                  label={t('pages.lenders.admin.createLender.inputs.name.label')}
                />
              </Grid>

              <Grid item>
                <TextFieldBox
                  name="displayed_name"
                  value={values?.displayed_name}
                  label={t('pages.lenders.admin.createLender.inputs.displayed_name.label')}
                />
              </Grid>

              <Grid item>
                <TextFieldBox
                  name="contact_first_name"
                  value={values?.contact_first_name}
                  label={t('pages.lenders.admin.createLender.inputs.contact_first_name.label')}
                />
              </Grid>

              <Grid item>
                <TextFieldBox
                  name="contact_last_name"
                  value={values?.contact_last_name}
                  label={t('pages.lenders.admin.createLender.inputs.contact_last_name.label')}
                />
              </Grid>

              <Grid item>
                <TextFieldBox
                  name="contact_email"
                  value={values?.contact_email}
                  label={t('pages.lenders.admin.createLender.inputs.contact_email.label')}
                />
              </Grid>

              <Grid item>
                <CustomSelectBox
                  options={accountCountryOptions}
                  name="country_code"
                  value={values?.country_code}
                  onChange={(event: ChangeEvent<{ value: unknown }>) =>
                    setFieldValue('country_code', event.target.value)
                  }
                  label={t('pages.lenders.admin.createLender.inputs.country_code.label')}
                />
              </Grid>

              {!lender && false && (
                <Grid item>
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={(event: ChangeEvent<HTMLInputElement>, checked: boolean) =>
                          setFieldValue('active', checked)
                        }
                        checked={values?.active}
                      />
                    }
                    label={values?.active ? t('global.states.enabled') : t('global.states.disabled')}
                    className={classes.switchLabel}
                  />
                </Grid>
              )}

              <Grid item>
                <TextFieldBox
                  name="logo_url"
                  value={values?.logo_url}
                  label={t('pages.lenders.admin.createLender.inputs.logo_url.label')}
                />
              </Grid>

              <Grid item>
                <TextFieldBox
                  name="website"
                  value={values?.website}
                  label={t('pages.lenders.admin.createLender.inputs.website.label')}
                />
              </Grid>

              {false && (
                <Grid item>
                  <TextFieldBox
                    name="address"
                    value={values?.address}
                    label={t('pages.lenders.admin.createLender.inputs.address.label')}
                  />
                </Grid>
              )}

              {lender && false && (
                <Grid item className={classes.documentUploadContainer}>
                  <FormLabel component={() => <CustomLabel title="Logo" />} />

                  <DocumentUpload uploadDocument={uploadDocument} onUploadSuccess={onUploadSuccess} />
                </Grid>
              )}

              <Grid item>
                <TextFieldBox
                  name="phone_number"
                  value={values?.phone_number}
                  type="number"
                  label={t('pages.lenders.admin.createLender.inputs.phone_number.label')}
                />
              </Grid>

              <Grid item>
                <TextFieldBox
                  name="job_title"
                  value={values?.job_title}
                  label={t('pages.lenders.admin.createLender.inputs.job_title.label')}
                />
              </Grid>

              <Grid item>
                <CustomSelectBox
                  options={accountIndustryOptions}
                  name="industry"
                  value={values?.industry}
                  onChange={(event: ChangeEvent<{ value: unknown }>) => setFieldValue('industry', event.target.value)}
                  label={t('pages.lenders.admin.createLender.inputs.industry.label')}
                />
              </Grid>

              <Grid item>
                <CustomSelectBox
                  options={accountEmployeesOptions}
                  name="employees"
                  value={values?.employees}
                  onChange={(event: ChangeEvent<{ value: unknown }>) => setFieldValue('employees', event.target.value)}
                  label={t('pages.lenders.admin.createLender.inputs.employees.label')}
                />
                <FooterButton
                  handleSubmit={handleSubmit}
                  onClose={toggleOpen}
                  loading={loading}
                  btnName={
                    lender?.id
                      ? t('pages.lenders.admin.createLender.buttons.update')
                      : t('pages.lenders.admin.createLender.buttons.create')
                  }
                />
              </Grid>
            </Grid>
          </Form>
        </Box>
      )}
    </Formik>
  );
};

export default CreateLenderModal;
