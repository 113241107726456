import { FC } from 'react';
import { Box, Select, FormHelperText } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { FieldProps } from 'formik/dist/Field';

import { Typography } from '@mui/material';
import clsx from 'clsx';
import useStyles from './CustomSelect.styles';

interface CustomSelectProps extends FieldProps {
  title: string;
  placeholder?: string;
  options: { label: string; value: string }[];
  tooltipMessage?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: any;
}

const CustomSelect: FC<CustomSelectProps> = ({ form, field, title, options, tooltipMessage, ...rest }) => {
  const classes = useStyles();
  const hasError = form.touched[field.name] && !!form.errors[field.name];

  return (
    <Box className={classes.selectContainer}>
      {title && (
        <Typography
          variant="text_MD"
          component="label"
          className={clsx([classes.fontJost, classes.fontWeight_500, classes.gray_500, classes.inputLbl])}
        >
          {title}
        </Typography>
      )}

      <Select
        native
        IconComponent={() => <ExpandMore className={classes.expandIcon} />}
        variant="outlined"
        className={clsx([classes.select, classes.selectBoxDropdown])}
        error={hasError}
        {...rest}
      >
        {options.map(({ label, value }) => {
          return (
            <option key={`option${value}`} value={value}>
              {label}
            </option>
          );
        })}
      </Select>

      {hasError && <FormHelperText error>{form.errors[field.name]}</FormHelperText>}
    </Box>
  );
};

export default CustomSelect;
