/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-no-undef */
// eslint-disable-next-line import/no-duplicates
import { FC, useEffect, useMemo, useState } from 'react';
import { Card } from '@material-ui/core';
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Checkbox,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Switch,
  SwitchProps,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import { Header } from 'components/Header/Header';

import { LendersListResponse, UserQuery, UserRole } from 'core/types';
import { getOffer, getApplications, getLenders } from 'http/dashboard';
import moment from 'moment';
import { useHistory } from 'react-router';
import { useAccount } from 'store/account/hooks';
import { useAdmin } from 'store/admin/hooks';
import { ApplicationsListResponse, BasicOffer } from 'store/applications/types';
import { useAuth } from 'store/auth/hooks';

import { composeInitials } from 'utils';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import CommonModal from 'components/Modal/CommonModal';
import { CreateLenderModal } from 'pages/lenders/components';
import Charts from './components/charts/Charts';
import useStyles from './Home.styles';
import useCommonStyles from '../../assets/css/Common.styles';
import { ReactComponent as DocumentPen } from '../../assets/icons/document_pen.svg';

const adminQuery: UserQuery = {
  per_page: 1000,
  page: 1,
  role: `${UserRole.ADMIN},${UserRole.SUPER_ADMIN}`,
};

export const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 26,
  height: 18,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 0,
    top: 5,
    left: 5,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(9px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#32D583',
        // backgroundColor: theme.palette.mode === 'dark' ? '#FF867E' : '#65C466',
        opacity: 1,
        border: 0,
      },
    },
    '&.Mui-disabled .MuiSwitch-thumb': {},
    '&.Mui-disabled + .MuiSwitch-track': {},
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 8,
    height: 8,
    top: 2,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.Gray[300],
    opacity: 1,
    transitionDuration: '300ms',
  },
}));

const Home: FC = () => {
  const [dashboardData, setDashboardData] = useState<{
    allApplications: ApplicationsListResponse;
    allOffers: BasicOffer[];
    allLenders: LendersListResponse;
  }>();

  const [createOpen, setCreateOpen] = useState(false);
  const toggleCreateOpen = () => setCreateOpen((prevState) => !prevState);

  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { firstName, isAdmin, lastName, role } = useAuth();

  const { admins } = useAdmin();

  const { users } = useAccount();
  const history = useHistory();
  const { getAdminUsers } = useAdmin();

  const teamMembers: { id: string; initials: string }[] = useMemo(
    () =>
      (isAdmin ? admins : users).map((user) => ({
        id: user.id,
        initials: composeInitials(user.first_name, user.last_name),
      })),
    [admins, isAdmin, users],
  );

  const fetchDashboardListingData = async () => {
    const defaultLenders: LendersListResponse = {
      lenders: [],
      total: 0,
      page: 0,
      per_page: 0,
    };
    const [allApplications, offers, allLenders] = await Promise.all([
      getApplications(),
      getOffer(),
      isAdmin ? getLenders() : Promise.resolve(defaultLenders),
    ]);
    const allOffers = offers.offers.filter((offer) => offer.status === 'OFFERED').slice(0, 10);
    setDashboardData({
      allApplications,
      allLenders,
      allOffers,
    });
  };

  const navigateToApplication = (id: string) => {
    history.push(`/d/lead/${id}`);
  };
  const navigateToOffer = (id: string) => {
    history.push(`/d/lead/${id}`);
  };

  useEffect(() => {
    fetchDashboardListingData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isAdmin) return;

    getAdminUsers(adminQuery);
  }, [getAdminUsers, isAdmin]);

  return (
    <>
      <Header name="Home" />

      <Box className={(classes.mainWrapper, classes.mainWrapperColor)}>
        <Box className={clsx([classes.gridPadding])}>
          <Grid container>
            <Grid container item lg={12} xl={3} spacing={4} sx={{ paddingRight: '1rem' }}>
              <Grid item sm={12} lg={12}>
                <Grid xs={12}>
                  <Card className={clsx([classes.cardInGrid])} style={{ marginBottom: '1rem' }}>
                    {' '}
                    <Typography className={clsx([classes.TitleWrap])}>
                      <span className={clsx([classes.MAinTitle])}> {moment().format('LLLL')}</span>
                      <Box className={clsx([classes.welcomeHelloTitle])}>👋🏼 {firstName || ''}</Box>
                    </Typography>
                    <List sx={{ width: '100%', maxWidth: '100%' }}>
                      <ListItem style={{ padding: '0' }}>
                        <ListItemAvatar>
                          <Avatar style={{ backgroundColor: '#717BBC' }} alt="Remy Sharp" src="/broken-image.jpg">
                            {firstName?.slice(0, 2)}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText>
                          <Typography className={clsx([classes.primeList])}>
                            {firstName || ''} {lastName || ''}
                          </Typography>
                          <Typography className={clsx([classes.prime2List])}>{role?.replace('_', ' ')}</Typography>
                        </ListItemText>
                      </ListItem>
                    </List>
                  </Card>

                  <Card className={clsx([classes.cardInGrid])}>
                    <Typography className={clsx([classes.TitleWrap])}>
                      <span className={clsx([classes.MAinTitle])}> Your Team</span>{' '}
                      <span className={clsx([classes.MAinBadge])}> {teamMembers.length}</span>
                    </Typography>
                    <AvatarGroup max={4} style={{ justifyContent: 'start', marginBottom: '1rem' }}>
                      {teamMembers.map((member) => {
                        return (
                          <Avatar style={{ backgroundColor: '#9E77ED' }} alt="Remy Sharp" src="/broken-image.jpg">
                            {member.initials}
                          </Avatar>
                        );
                      })}
                    </AvatarGroup>
                    <Button
                      variant="outlined"
                      className="blueSolid"
                      fullWidth
                      onClick={() => history.push(`/d/admins`)}
                    >
                      <AddCircleOutlineIcon /> Add team member
                    </Button>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item lg={12} xl={9} spacing={4}>
              <Grid container item lg={12} xl={5} spacing={4}>
                <Grid item sm={12} lg={12}>
                  <Card className={clsx([classes.cardInGrid])}>
                    <Grid container>
                      <Box className={clsx([classes.BtnTitleWrap])}>
                        <Typography className={clsx([classes.TitleWrap])}>
                          <span className={clsx([classes.MAinTitle])}> New applications</span>{' '}
                          <span className={clsx([classes.MAinBadge])}> 25</span>
                        </Typography>{' '}
                        <Link to="/d/leads">
                          <Button variant="outlined" className="">
                            See all
                          </Button>
                        </Link>
                      </Box>
                      <Box className={clsx([classes.heightScroll])}>
                        {dashboardData?.allApplications?.applications.map((application) => {
                          return (
                            <Box
                              onClick={() => navigateToApplication(application.id)}
                              className={clsx([classes.appBox])}
                              key={application.id}
                            >
                              <Typography className={clsx([classes.BtnTitleWrap])}>
                                <span className={clsx([classes.appTitle])}>{application.company.registered_name}</span>
                                <span className={clsx([classes.appStatus])}> &#8226; {application.status}</span>
                              </Typography>
                              <Typography className={clsx([classes.appAmount])}>
                                $ {application.funding_amount ?? '-'}
                              </Typography>
                              <Typography className={clsx([classes.appTime])}>
                                Time stamp: <span>{moment(application.created_at).format('DD/MM/YYYY hh:mm a')}</span>{' '}
                              </Typography>
                            </Box>
                          );
                        })}
                      </Box>
                      <Link to="/d/leads" className={clsx([classes.btnBox])}>
                        <Button variant="outlined" className="blueSolid" fullWidth>
                          Review new applications &nbsp; <ArrowForwardIosIcon />
                        </Button>
                      </Link>
                    </Grid>
                  </Card>
                </Grid>
              </Grid>
              <Grid container item lg={12} xl={7} spacing={4}>
                <Grid item sm={12} lg={6}>
                  <Card className={clsx([classes.cardInGrid])}>
                    <Grid container>
                      <Typography className={clsx([classes.TitleWrap])} sx={{ paddingBottom: '18px' }}>
                        <span className={clsx([classes.MAinTitle])}>New Offers</span>{' '}
                        <span className={clsx([classes.MAinBadge])}> {dashboardData?.allOffers.length}</span>
                      </Typography>
                      <Box className={clsx([classes.heightScroll])}>
                        <List sx={{ width: '100%', maxWidth: '100%' }}>
                          {dashboardData?.allOffers?.map((offer) => {
                            return (
                              <ListItem
                                style={{ padding: '0 0 10px 0' }}
                                onClick={() => navigateToOffer(offer.application_id)}
                              >
                                <ListItemAvatar>
                                  {offer.lender_logo ? (
                                    <Avatar
                                      variant="rounded"
                                      alt={offer?.lender_name}
                                      className={clsx([commonClasses.avtarRound])}
                                      src={offer.lender_logo}
                                      sx={{ width: 52, height: 52 }}
                                    />
                                  ) : (
                                    <InsertDriveFileIcon fontSize="large" />
                                  )}
                                </ListItemAvatar>
                                <ListItemText>
                                  <Typography className={clsx([classes.offerList])}>
                                    {offer.lender_name.toLocaleUpperCase()}
                                  </Typography>
                                  <Typography className={clsx([classes.offer2List])}>
                                    £{offer.loan_amount} &#8226; {offer?.product_type.replace('_', ' ')}{' '}
                                  </Typography>
                                </ListItemText>
                              </ListItem>
                            );
                          })}
                        </List>
                      </Box>
                      <Link to="/d/leads" className={clsx([classes.btnBox])}>
                        <Button variant="outlined" className="blueSolid" fullWidth>
                          Review offers &nbsp; <ArrowForwardIosIcon />
                        </Button>
                      </Link>
                    </Grid>
                  </Card>
                </Grid>
                {isAdmin && (
                  <Grid item sm={12} lg={6}>
                    <Card className={clsx([classes.cardInGrid])}>
                      <Grid container>
                        <Box className={clsx([classes.BtnTitleWrap])}>
                          <Typography className={clsx([classes.TitleWrap])}>
                            <span className={clsx([classes.MAinTitle])}> Lenders</span>{' '}
                            <span className={clsx([classes.MAinBadge])}>
                              {' '}
                              {dashboardData?.allLenders.lenders.length}
                            </span>
                          </Typography>
                          <Link to="/d/lenders">
                            <Button variant="outlined" className="">
                              See all
                            </Button>
                          </Link>
                        </Box>
                        <Box className={clsx([classes.heightScroll])}>
                          <List sx={{ width: '100%', maxWidth: '100%' }}>
                            {dashboardData?.allLenders.lenders?.map((lender) => {
                              return (
                                <ListItem style={{ padding: '0 0 10px 0' }} key={lender.id}>
                                  <ListItemAvatar>
                                    <Avatar
                                      variant="rounded"
                                      alt={lender?.name}
                                      className={clsx([commonClasses.avtarRound])}
                                      src={lender.logo_url}
                                      sx={{ width: 52, height: 52 }}
                                    />
                                  </ListItemAvatar>
                                  <ListItemText>
                                    <Typography className={clsx([classes.primeList])}>{lender.name}</Typography>
                                    <Typography className={clsx([classes.prime2List])}>{lender.active}</Typography>
                                  </ListItemText>
                                </ListItem>
                              );
                            })}
                          </List>
                        </Box>
                        <Button variant="outlined" className="blueSolid" fullWidth onClick={() => setCreateOpen(true)}>
                          <AddCircleOutlineIcon /> Create lender
                        </Button>
                      </Grid>
                    </Card>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
        {/* <Box className={clsx([classes.gridPadding])}>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={6} xl={3}>
              <Card className={clsx([classes.cardInGrid])}>
                <Box className={clsx([classes.BtnTitleWrap])}>
                  <Typography className={clsx([classes.TitleWrap])}>
                    <span className={clsx([classes.MAinTitle])}> Actions</span>{' '}
                    <span className={clsx([classes.MAinBadge])}> {statusOverViewData.admin_actions.total}</span>
                  </Typography>
                  <Link to="/">
                    <Button variant="outlined" className="">
                      See all
                    </Button>
                  </Link>
                </Box>
                <Box className={clsx([classes.heightLEftBottom])}>
                  {statusOverViewData.admin_actions.duetoday.length > 0 && (
                    <List sx={{ width: '100%', maxWidth: '100%', marginBottom: '24px' }}>
                      <Typography className={clsx([classes.dueHeadTitle])}>Due today</Typography>
                      {statusOverViewData.admin_actions.duetoday.map((value) => {
                        return <ActionItem due="today" action={value} />;
                      })}
                    </List>
                  )}
                  {statusOverViewData.admin_actions.duetomorrow.length > 0 && (
                    <List sx={{ width: '100%', maxWidth: '100%', marginBottom: '24px' }}>
                      <Typography className={clsx([classes.dueHeadTitle])}>Due tomorrow</Typography>
                      {statusOverViewData.admin_actions.duetomorrow.map((value) => {
                        return <ActionItem due="tomorrow" action={value} />;
                      })}
                    </List>
                  )}
                  {statusOverViewData.admin_actions.dueweek.length > 0 && (
                    <List sx={{ width: '100%', maxWidth: '100%', marginBottom: '24px' }}>
                      <Typography className={clsx([classes.dueHeadTitle])}>Due this week</Typography>
                      {statusOverViewData.admin_actions.dueweek.map((value) => {
                        return <ActionItem due="this week" action={value} />;
                      })}
                    </List>
                  )}
                </Box>
                <Button variant="outlined" className="blueSolid" fullWidth>
                  <AddCircleOutlineIcon /> Create
                </Button>
              </Card>
            </Grid>
          </Grid>
        </Box> */}
        <Box className={clsx([classes.gridPadding])}>
          <Grid container spacing={4}>
            {isAdmin && (
              <Grid item xs={12} lg={6} xl={3}>
                <Card className={clsx([classes.cardInGrid])}>
                  <Box className={clsx([classes.BtnTitleWrap])}>
                    <Typography className={clsx([classes.TitleWrap])}>
                      <span className={clsx([classes.MAinTitle])}> Actions</span>{' '}
                      <span className={clsx([classes.MAinBadge])}> 6 </span>
                    </Typography>
                    <Link to="/">
                      <Button variant="outlined" className="">
                        See all
                      </Button>
                    </Link>
                  </Box>
                  <Box className={clsx([classes.heightLEftBottom])}>
                    <List sx={{ width: '100%', maxWidth: '100%', marginBottom: '24px' }}>
                      <Typography className={clsx([classes.dueHeadTitle])}>Due today</Typography>
                      {[0, 1].map((value) => {
                        return (
                          <ListItem
                            key={value}
                            className={clsx([classes.checkTop])}
                            secondaryAction={<Checkbox />}
                            disablePadding
                          >
                            <ListItemButton className={clsx([classes.actionBox])}>
                              <ListItemAvatar>
                                <UnarchiveIcon fontSize="large" />
                              </ListItemAvatar>
                              <ListItemText>
                                <Typography className={clsx([classes.actionTitle])}>Call the client before</Typography>
                                <Typography className={clsx([classes.action2Title])}>
                                  Yuliya M. &#8226; Due today
                                </Typography>
                              </ListItemText>
                            </ListItemButton>
                          </ListItem>
                        );
                      })}
                    </List>
                    <List sx={{ width: '100%', maxWidth: '100%', marginBottom: '24px' }}>
                      <Typography className={clsx([classes.dueHeadTitle])}>Due tomorrow</Typography>
                      {[0, 1].map((value) => {
                        return (
                          <ListItem
                            key={value}
                            className={clsx([classes.checkTop])}
                            secondaryAction={<Checkbox />}
                            disablePadding
                          >
                            <ListItemButton className={clsx([classes.actionBox])}>
                              <ListItemAvatar>
                                <UnarchiveIcon fontSize="large" />
                              </ListItemAvatar>
                              <ListItemText>
                                <Typography className={clsx([classes.actionTitle])}>Call the client before</Typography>
                                <Typography className={clsx([classes.action2Title])}>
                                  Yuliya M. &#8226; Due tomorrow
                                </Typography>
                              </ListItemText>
                            </ListItemButton>
                          </ListItem>
                        );
                      })}
                    </List>
                    <List sx={{ width: '100%', maxWidth: '100%', marginBottom: '24px' }}>
                      <Typography className={clsx([classes.dueHeadTitle])}>Due this week</Typography>
                      {[0, 1].map((value) => {
                        return (
                          <ListItem
                            key={value}
                            className={clsx([classes.checkTop])}
                            secondaryAction={<Checkbox />}
                            disablePadding
                          >
                            <ListItemButton className={clsx([classes.actionBox])}>
                              <ListItemAvatar>
                                <UnarchiveIcon fontSize="large" />
                              </ListItemAvatar>
                              <ListItemText>
                                <Typography className={clsx([classes.actionTitle])}>Call the client before</Typography>
                                <Typography className={clsx([classes.action2Title])}>
                                  Yuliya M. &#8226; Due this week
                                </Typography>
                              </ListItemText>
                            </ListItemButton>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Box>
                  <Button variant="outlined" className="blueSolid" fullWidth>
                    <AddCircleOutlineIcon /> Create
                  </Button>
                </Card>
              </Grid>
            )}
            <Charts />
          </Grid>
          {createOpen && (
            <CommonModal open={createOpen} title="Create a lender" onClose={toggleCreateOpen} icon={<DocumentPen />}>
              <CreateLenderModal open={createOpen} toggleOpen={toggleCreateOpen} />
            </CommonModal>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Home;
