import { FC, useCallback, useEffect, useState } from 'react';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import { ArrowLeft, ArrowRight } from '@material-ui/icons';
import { Button } from '@material-ui/core';
import { VIEW_DOCUMENT } from 'core/constants';
import { useAdmin } from 'store/admin/hooks';
import { Loader } from 'components/Loader';
import useStyles from './CSVAndExcelReader.styles';

interface DataItem {
  [key: string]: string;
}

interface CSVAndExcelReaderProps {
  document: { docName: string; data: ArrayBuffer } | null;
}

const CSVAndExcelReader: FC<CSVAndExcelReaderProps> = ({ document }) => {
  const classes = useStyles();
  const [fileData, setFileData] = useState<DataItem[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const { setError } = useAdmin();

  function convertBufferToString(arrayBuffer: ArrayBuffer): Promise<string> {
    return new Promise((resolve, reject) => {
      try {
        const data = new Uint8Array(arrayBuffer);
        const workbook = XLSX.read(data, { type: 'array' });
        const firstSheetName = workbook.SheetNames[0];
        const documentData = XLSX.utils.sheet_to_csv(workbook.Sheets[firstSheetName]);
        resolve(documentData);
      } catch (error) {
        reject(new Error('Failed to read the file.'));
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const parseDocumentData = (documentData: any[]): DataItem[] => {
    const headers = documentData[0];
    return documentData.slice(1).map((row) =>
      headers.reduce((acc: DataItem, header: string, index: number) => {
        acc[header] = row[index];
        return acc;
      }, {}),
    );
  };

  const handleFile = useCallback(
    async (buffer: ArrayBuffer) => {
      setLoading(true);
      try {
        const documentData = await convertBufferToString(buffer);
        const parsedData = parseDocumentData(Papa.parse(documentData).data);
        setFileData(parsedData);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setError((err as Error)?.message || true);
      }
    },
    [setError],
  );

  const handlePreviousPage = useCallback(() => {
    setCurrentPage((prevPage) => Math.max(1, prevPage - 1));
  }, []);

  const handleNextPage = useCallback(() => {
    setCurrentPage((prevPage) => Math.max(1, prevPage + 1));
  }, []);

  useEffect(() => {
    if (document?.data) handleFile(document.data);
    // eslint-disable-next-line
  }, [document?.data, handleFile]);

  const totalPages = Math.ceil(fileData.length / VIEW_DOCUMENT.ROWS_PER_PAGE);
  // Get the current page's data
  const currentPageData = fileData.slice(
    (currentPage - 1) * VIEW_DOCUMENT.ROWS_PER_PAGE,
    currentPage * VIEW_DOCUMENT.ROWS_PER_PAGE,
  );

  return (
    <div>
      <Loader visible={loading} />
      {currentPageData.length > 0 && (
        <div>
          <table className={classes.tableStyle}>
            <thead>
              <tr>
                {Object.keys(currentPageData[0]).map((header) => (
                  <th key={header} className={classes.thStyle}>
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {currentPageData.map((row) => (
                <tr key={row.id}>
                  {Object.values(row).map((cell) => (
                    <td className={classes.tdStyle}>{cell}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Pagination controls */}
      {totalPages > 1 && (
        <div className={classes.paginationContainer}>
          <Button onClick={handlePreviousPage}>
            <ArrowLeft className={classes.icon} />
            Previous Page
          </Button>
          <span>
            Page {currentPage} of {totalPages}
          </span>
          <Button onClick={handleNextPage} disabled={currentPage === totalPages}>
            Next Page
            <ArrowRight className={classes.icon} />
          </Button>
        </div>
      )}
    </div>
  );
};

export default CSVAndExcelReader;
