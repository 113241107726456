import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  integrateToolBox: {
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: `${theme.spacing(2)} !important`,
    backgroundColor: '#FEFEFF !important',
    boxShadow: `${theme.shadows[1]} !important`,
    height: '100%',
  },
  integrateToolBoxInner: {
    padding: `${theme.spacing(0)} !important`,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  '& .MuiInputBase-input': {
    width: '440px !important',
  },
  chipNoBorder: {
    borderRadius: '0 !important',
    border: '0 !important',
    marginRight: '8px',
  },
  btnGray: {
    color: `${theme.palette.Gray[500]} !important`,
  },
  paginationCustom: {
    '& .MuiPaginationItem-root': {
      '&.Mui-selected': {
        background: `${theme.palette.Blue[600]} !important`,
      },
    },
  },
  paginationStyle: {
    margin: '48px',
    display: 'flex',
    justifyContent: 'center',
  },
  flexWithGap20: {
    display: 'flex',
    gap: '20px',
  },
  marginTop10: {
    marginTop: '10px',
  },
  marginRight16: {
    marginRight: '16px',
  },
}));
export default useStyles;
