import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  contentContainer: {
    // paddingBottom: theme.spacing(8),
  },
  title: {
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(24),
    marginBottom: 12,
  },
  subtitle: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20),
    fontWeight: 700,
  },
  navActionsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(5),

    '& .MuiButton-startIcon': {
      marginRight: theme.spacing(2),
    },

    '& .MuiButton-endIcon': {
      marginLeft: theme.spacing(2),
    },
  },
  actionButton: {
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20),
    '& span span': {
      marginRight: 4,
    },
  },
  navIcon: {
    width: 20,
    height: 20,
    color: theme.palette.common.black,
  },
  tableContainer: {
    marginBottom: theme.spacing(5),
    '& .MuiTableRow-root': {
      cursor: 'auto',
    },

    '& th:nth-child(6), td:nth-child(6)': {
      padding: 0,
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      minWidth: 40,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(2),
        paddingBottom: theme.spacing(2),
      },
    },
  },
  partnerTableContainer: {
    marginBottom: theme.spacing(5),
    '& .MuiTableRow-root': {
      cursor: 'auto',
    },
    '& th:nth-child(n+2), td:nth-child(n+2)': {
      minWidth: 135,
    },
    '& th:nth-child(n+5), td:nth-child(n+5)': {
      minWidth: 150,
    },
    '& th:nth-child(n+7), td:nth-child(n+7)': {
      minWidth: 165,
    },
    '& th:nth-child(n+9), td:nth-child(n+9)': {
      minWidth: 130,
    },
  },
  editButton: {
    height: '30px',
    width: '30px',
  },
  editIcon: {
    fontSize: '20px',
    color: theme.palette.primary.main,
  },
  followUpButton: {
    minWidth: 160,
  },
  mainWrapper: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  dashboardPaletteCard: {},
  dashboardPalette: {},
  dashboardPaletteIconText: {
    marginBottom: '20px',
    display: 'flex',
  },
  dashboardPaletteIcon: {
    width: '3.125rem',
    height: '3.125rem',
    backgroundColor: theme.palette.Blue[50],
    borderRadius: '0.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.Blue[600],
  },
  dashboardPaletteTextWrap: {
    color: theme.palette.common.black,
    paddingLeft: theme.spacing(3.2),
    maxWidth: 'calc(100% - 3.125rem)',
    width: '100%',
  },
  dashboardPaletteDesc: {
    marginBottom: '0.25rem !important',
    fontSize: `${theme.typography.pxToRem(14)} !important`,
    lineHeight: `${theme.typography.pxToRem(25.2)} !important`,
    fontWeight: '400',
    color: theme.palette.Gray[900],
    // `${theme.typography.pxToRem(20)} !important`,
  },
  dashboardPaletteDescCount: {
    fontSize: `${theme.typography.pxToRem(18)} !important`,
    lineHeight: `${theme.typography.pxToRem(28)} !important`,
    fontWeight: '500 !important',
    color: `${theme.palette.Gray[900]} !important`,
    display: 'block !important',
  },
  dashboardPaletteLink: {},
  dashboardPaletteLinkItem: {
    display: 'inline-flex !important',
    alignItems: 'center !important',
    fontSize: '14px !important',
    lineHeight: '20px !important',
    color: `${theme.palette.Blue[600]} !important`,
    fontWight: '700 !important',
    textDecoration: 'none !important',
    cursor: 'pointer',
  },
  dashboardPaletteLinkIcon: {
    marginLeft: '0.5rem',
  },
  c_card: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(4),
    },
    // backgroundColor: theme.palette.primary.contrastText,
  },
  c_cardTitle: {
    display: 'flex',
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
  },
  c_cardTitleText: {
    fontSize: `${theme.typography.pxToRem(16)} !important`,
    lineHeight: `${theme.typography.pxToRem(28)} !important`,
    color: `${theme.palette.Gray[600]} !important`,
    fontWeight: '500 !important',
    fontFamily: '"Jost" !important',
  },
  c_cardBody: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
  },
  cardShadow: {
    boxShadow: '0px 2px 24px 0px #9292921A',
    backgroundColor: theme.palette.primary.contrastText,
    borderRadius: theme.spacing(3),
  },
  filter_cardBody: {
    padding: '0px 16px 24px 16px',
  },
  filter_tabingBox: {
    borderBottom: `1px solid ${theme.palette.grey[50]}`,
    marginBottom: '24px',
  },
  filter_checkBoxGrp: {
    display: 'flex',
    flexDirection: 'column',
  },
  filter_cardFooter: {
    padding: '24px 16px',
    borderTop: `1px solid ${theme.palette.grey[50]}`,
  },
  headingStripIcon: {
    width: '3.25rem',
    height: '3.25rem',
    backgroundColor: theme.palette.primary.contrastText,
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.Gray[900],
    boxShadow: theme.shadows[6],
  },
  headingStripTextWrap: {
    position: 'relative',
    color: theme.palette.Gray[700],
    '& h3': {
      fontWeight: '500',
    },
  },
  tabColLine: {
    position: 'relative',
    '&::before': {
      position: 'absolute',
      content: '""',
      backgroundColor: theme.palette.Gray[900],
      opacity: '0.2',
      width: '1px',
      height: '100%',
      right: '-3rem',
      top: '0%',
    },
  },
  tabInfoWrap: {
    padding: '1.5rem 4.25rem',
    display: 'flex',
    flexWrap: 'wrap',
  },
  tabCol: {
    position: 'relative',
  },
  headingSubStripIconText: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    gap: '0.5rem',
  },
  headingSubStripIcon: {
    width: '2rem',
    height: '2rem',
    backgroundColor: theme.palette.Blue[50],
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.Blue[600],
  },
  headingSubStripTextWrap: {
    position: 'relative',
    color: theme.palette.Gray[700],
    '& h4': {
      fontWeight: '500',
    },
  },
  listKeyValueWrap: {
    position: 'relative',
    padding: `${theme.spacing(0)} !important`,
    marginBottom: `${theme.spacing(10)} !important`,
  },
  listKeyValueItem: {
    position: 'relative',
    paddingLeft: `${theme.spacing(0)} !important`,
    paddingRight: `${theme.spacing(0)} !important`,
    '&:first-child': {
      paddingTop: `${theme.spacing(0)} !important`,
    },
  },
  listKeyValueBox: {
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '0.5rem',
  },
  listKeyValueBox_key: {
    position: 'relative',
    width: '8.5rem',
  },
  listKeyValueBox_value: {
    position: 'relative',
    width: '13rem',
  },
  headingStripIconText: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    gap: '1rem',
  },
  py_16: {
    paddingTop: `${theme.spacing(4)} !important`,
    paddingBottom: `${theme.spacing(4)} !important`,
  },
  flex: {
    display: 'flex',
  },
  flexCol: {
    flexDirection: 'column',
  },
  icon18: {
    width: '1.125rem !important',
    height: '1.125rem !important',
  },
  width_100: {
    width: '100% !important',
  },
  tabGap_160: {
    gap: '10rem',
  },
  fontWeight_400: {
    fontWeight: '400!important',
  },
  fontJost: {
    fontFamily: 'jost !important',
  },
  gray_600: {
    color: `${theme.palette.Gray[600]} !important`,
  },
  gray_700: {
    color: `${theme.palette.Gray[700]} !important`,
  },
  gray_500: {
    color: `${theme.palette.Gray[500]} !important`,
  },
  primary_600: {
    color: `${theme.palette.Blue[600]} !important`,
  },
  gap_24: {
    gap: '1.5rem',
  },
  gap_32: {
    gap: '2rem !important',
  },
  gap_16: {
    gap: '1rem !important',
  },
  Gap_24: {
    gap: '1.5rem !important',
  },
  listKeyValueBox_title: {
    width: '100%',
  },
  stepWrap: {
    position: 'relative',
  },
  tabGap_144: {
    gap: '9rem !important',
  },
  border_0: {
    border: `0px solid ${theme.palette.grey[200]} !important`,
  },
  lineHeight_25: {
    lineHeight: `${theme.typography.pxToRem(25)} !important`,
  },
  tabInfoLeft: {
    width: '13.5rem',
  },
  black_500: {
    color: '#000000',
  },
  listKeyValueTitleWrap: {
    paddingTop: `${theme.spacing(4)} !important`,
    paddingBottom: `${theme.spacing(4)} !important`,
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    gap: '0.5rem',
    flexDirection: 'column',
    display: 'flex',
  },
  listKeyValueTitleItem: {
    position: 'relative',
    padding: `${theme.spacing(0)} !important`,
  },
  fontWeight_500: {
    fontWeight: '500!important',
  },
  cmn_btn: {
    fontWeight: `400 !important`,
    textTransform: `capitalize`,
  },
  cmn_btn_icon: {
    marginLeft: '8px',
  },
  stepLine: {
    position: 'relative',
    '&::before': {
      position: 'absolute',
      content: '""',
      backgroundColor: theme.palette.Gray[200],
      width: '1px',
      margin: '0 auto',
      height: 'calc(100% - 2rem)',
      left: '0',
      right: '0',
      top: '2rem',
    },
  },
  tabInfoRight: {
    position: 'relative',
    maxWidth: '32.75rem',
    width: '100%',
  },
  tabInfoRight_box: {
    position: 'relative',
  },
  tabInfoRight_space: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    paddingBottom: '3rem',
    marginBottom: '4rem',
  },
  formInlineLblValue: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  formInlineLblValue_lbl: {
    position: 'relative',
  },
  formInlineLblValue_value: {
    position: 'relative',
    width: '100%',
  },
  formInlineLblValue_box: {
    position: 'relative',
    width: '21.5rem',
  },
  formSelect: {
    borderRadius: '8px',
    border: `1px solid #8C92AC`,
    backgroundColor: theme.palette.primary.contrastText,
    padding: '12px 14px',
    width: '100%',
  },
  inputIconLeft: {
    width: '24px',
    height: '44px',
    display: 'flex',
    alignItems: 'center',
    borderRight: `1px solid #8C92AC`,
  },
  inputFileWrapper: {},
  inputFileLabel: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    width: '300px',
    height: '105px',
    border: '1px dashed #e8e8e8',
    borderRadius: '10px',
    padding: theme.spacing(2.5),
    cursor: 'pointer',
    '& input': {
      display: 'none',
    },
  },
  inputFileContent: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100%',
  },
  inputFileIcon: {
    marginBottom: '12px',
    width: '40px',
    height: '40px',
    borderRadius: '100px',
    display: 'block',
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    margin: 0,
    backgroundColor: '#F2F4F7',
    boxShadow: '0px 0px 0px 6px #F9FAFB',
    transitionDuration: '0.3s',
    '&:active': {
      scale: '0.85',
    },
    '& svg': {
      width: '100%',
      height: '100%',
    },
  },
  inputFileText: {
    fontSize: `${theme.typography.pxToRem(14)}`,
    lineHeight: `${theme.typography.pxToRem(20)}`,
    fontWeight: '600 !important',
    color: `${theme.palette.Blue[600]} !important`,
    '& span': {
      fontSize: `${theme.typography.pxToRem(14)}`,
      lineHeight: `${theme.typography.pxToRem(20)}`,
      fontWeight: '400',
      color: theme.palette.Gray[500],
      display: 'inline-block',
      marginLeft: '4px',
    },
  },
  docIcon: {
    width: '1.5rem',
    height: '1.5rem',
    display: 'flex',
    '& svg': {
      width: '100%',
      height: '100%',
    },
  },
  listItemText: {
    fontSize: `${theme.typography.pxToRem(16)}`,
    fontWeight: '400',
    lineHeight: `${theme.typography.pxToRem(24)}`,
    color: theme.palette.Gray[500],
    width: 'calc(100% - 65px)',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: '1',
    WebkitBoxOrient: 'vertical',
    // padding: '0 8px',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  listItemAction: {
    display: 'flex',
    gap: 8,
  },
  actionIcon: {
    width: '1.25rem',
    height: '1.25rem',
    display: 'flex',
    '& svg': {
      width: '100%',
      height: '100%',
    },
  },
  downloadIcon: {},
  deleteIcon: {},
  tableActionWrap: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  tableActionPaginationCount: {
    fontFamily: 'Jost !important',
    fontSize: `${theme.typography.pxToRem(16)} !important`,
    fontWeight: '400 !important',
    lineHeight: `${theme.typography.pxToRem(29)} !important`,
    letterSpacing: '0em !important',
  },
  tableActionButtonWrap: {
    display: 'inline-flex',
    flexWrap: 'wrap',
    gap: '1rem',
  },
  tableActionSearchWrap: {
    maxWidth: '561px',
    width: '100%',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  tableActionPaginationWrap: {},
  selectedFilter: {},
  selectedFilterList: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(3.1),
    margin: theme.spacing(0),
    padding: theme.spacing(0),
    listStyleType: 'none',
  },
  selectedFilterItem: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.Gray[200],
    paddingLeft: theme.spacing(3.5),
    paddingRight: theme.spacing(3.5),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderRadius: theme.spacing(2),
  },
  selectedFilterItemText: {
    color: `${theme.palette.Gray[700]} !important`,
    marginRight: `${theme.spacing(2)} !important`,
  },
  selectedFilterRemoveIcon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    display: 'inline-block',
    cursor: 'pointer',
    '& svg': {
      width: '100%',
      height: '100%',
    },
  },
  uploadedItemList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    marginTop: '1rem',
  },
  uploadedItem: {
    display: 'flex',
    alignItems: 'center',
  },
  textareaWrap: {
    position: 'relative',
  },
  textareaLblWrap: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '0.5rem',
  },
  textareaLblWrap_lbl: {
    position: 'relative',
  },
  textareaLblWrap_action: {
    position: 'relative',
  },
  textareaCustom: {
    width: '100%',
    padding: '0rem !important',
  },
  textareaWrapInner: {
    marginBottom: '1rem',
  },
  expandIcon: {
    height: 20,
    width: 20,
    color: '#687C8D',
    pointerEvents: 'none',
    position: 'absolute',
    top: 8,
    right: 10,
  },
  select: {
    root: {
      '&:hover:not($disabled):before': {
        borderColor: '#D0D5DD',
      },
      '&.Mui-focused:before': {
        borderColor: '#D0D5DD',
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#D0D5DD',
    },
    '&:focus-within .MuiOutlinedInput-notchedOutline': {
      borderColor: '#D0D5DD',
    },
    '& .MuiInputBase-input': {
      padding: '13.5px 14px !important',
    },
  },
  selectBoxDropdown: {
    color: `${theme.palette.Gray[700]} !important`,
    fontWeight: 400,
    width: '100%',
  },
  pageBackWrap: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(3),
  },
  pageBackLink: {
    display: 'inline-flex !important',
    alignItems: 'center !important',
    fontSize: '1rem !important',
    lineHeight: '1.8rem !important',
    color: `${theme.palette.Blue[600]} !important`,
    fontWight: '600 !important',
    textDecoration: 'none !important',
  },
  pageBackIcon: {
    marginRight: '0.5rem',
  },
  flex_item: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  selectContainer: {
    // marginRight: theme.spacing(2),
    '& .MuiInputBase-root': {
      outline: 'none !important',
    },
    '& .MuiSelect-root': {
      height: 22,
      minWidth: '7.5em',
      paddingTop: 0,
      paddingBottom: 0,
      paddingRight: 16,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      fontWeight: 700,
    },
  },
  datePickerContainer: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down(494)]: {
      marginTop: theme.spacing(2),
    },
  },
  semibold: {
    fontFamily: 'inter semibold',
  },
  datePicker_wrap: {
    width: 'auto !important',
  },
  home_datePicker: {
    '&:before': {
      display: 'none !important',
    },
    '& input': {
      width: '150px !important',
      textAlign: 'left !important',
    },
  },
  filter_cardTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px',

    '& h3': {
      fontWeight: '500',
    },
  },
  closeFilterIcon: {
    fontSize: '24px',
  },
  closeFilterIconEnd: {
    fontSize: '24px',
    width: '20px',
    height: '20px',
    marginLeft: 'auto !important',
    minWidth: '24px !important',
    minHeight: '24px !important',
    color: `${theme.palette.Gray[700]} !important`,
    '& .MuiSvgIcon-root': {
      marginRight: '0 !important',
    },
  },
  modal_main: {
    width: '620px',
  },
  dashboardPaletteIconMargin: {
    margin: '0 auto 30px',
  },
  header_title: {
    textAlign: 'center',
    marginBottom: '20px',
  },
  ul_style: {
    padding: '0px',
    listStyle: 'none',
    columnCount: 2,
  },
  modalTextAreBox: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 0,
    width: '100%',
  },
  spaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '10px',
  },
  editor: {
    marginTop: theme.spacing(1),
    border: `1px solid ${theme.palette.greyLightBorder.main}`,
    borderRadius: 4,
    height: 500,
    width: '100%',
    maxWidth: 960,
    '& .ql-container.ql-snow': {
      border: 'none',
      height: 457,
      '& h2': {
        fontSize: theme.typography.pxToRem(20),
        lineHeight: theme.typography.pxToRem(28),
        fontWeight: 700,
      },
      '& h3': {
        fontSize: theme.typography.pxToRem(18),
        lineHeight: theme.typography.pxToRem(24),
        fontWeight: 700,
      },
      '& h4': {
        fontSize: theme.typography.pxToRem(16),
        lineHeight: theme.typography.pxToRem(22),
        fontWeight: 700,
      },
      '& p': {
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(20),
        fontFamily: theme.typography.fontFamily,
      },
      '& .ql-tooltip': {
        left: '5px !important',
      },
    },
    '& .ql-toolbar.ql-snow': {
      border: 'none',
      borderBottom: `1px solid ${theme.palette.greyLightBorder.main}`,
      '& .ql-formats': {
        marginRight: 5,
      },
    },
  },
  modalIconBorder: {
    width: '3.5rem',
    height: '3.5rem',
    backgroundColor: theme.palette.Blue[100],
    border: `10px solid ${theme.palette.Blue[50]}`,
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.Blue[600],
  },
  modalIconMargin: {
    margin: '0 auto 1rem',
  },
  modalTitle: {
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(27),
    color: `${theme.palette.Gray[700]} !important`,
    fontWeight: 700,
    display: 'block',
    maxWidth: '432px',
    margin: '0 auto 20px !important',
    textAlign: 'center',
  },
  filedTitle: {
    color: `${theme.palette.Gray[500]} !important`,
    fontWeight: '500 !important',
    fontFamily: 'Jost, sans-serif !important',
  },
  modalTextArea: {
    borderRadius: '0.5rem',
    borderColor: `${theme.palette.Gray[300]} !important`,
  },
  modalFooter: {
    marginTop: theme.spacing(8),
  },
  filedSpaceBottom: {
    marginBottom: '0.5rem !important',
  },
  inputLbl: {
    lineHeight: '1.75rem !important',
    marginBottom: '0.5rem !important',
    display: 'block !important',
  },
  formLblValue: {
    marginBottom: '1.5rem !important',
  },
  formWidthFull: {
    width: '100%',
  },
  customInputHight: {
    '& .MuiInputBase-input': {
      padding: '12px 12px !important',
    },
  },
  formLblValueGroup: {
    borderTop: `1px solid ${theme.palette.Gray[300]}`,
  },
  fontWeight_700: {
    fontWeight: '700 !important',
  },
  LblValueSpace: {
    margin: '2rem 0rem !important',
  },
  ul_styleOneLine: {
    padding: '0px',
    listStyle: 'none',
    columnCount: 1,
  },
  modalTitleSub: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(21),
    color: `${theme.palette.Gray[500]} !important`,
    fontWeight: 400,
    display: 'block',
    maxWidth: '432px',
    margin: '0 auto 20px !important',
    textAlign: 'center',
  },
  disabled: {
    cursor: 'not-allowed',
  },
  iconInputBox: {
    width: '100%',
    '& .MuiInputBase-input': {
      boxShadow: 'none !important',
    },
  },
}));
