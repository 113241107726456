import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import i18n from 'i18next';
import omitBy from 'lodash.omitby';
import isNil from 'lodash.isnil';

import { ROWS_PER_TABLE_PAGE } from 'core/constants';
import { LenderQuery } from 'core/types';
import {
  getLenders as getLendersApi,
  createLender as createLenderApi,
  updateLender as updateLenderApi,
  getAllLenders as getAllLendersApi,
  getLenderDetails as getLenderDetailsApi,
} from 'http/lenders';
import { CreateLender, Lender, LenderDetails, SetLenderLogo, ShortLender, UpdateLender } from './types';

export const GET_LENDERS = 'admin/GET_LENDERS';
export const CREATE_LENDER = 'admin/CREATE_LENDER';
export const UPDATE_LENDER = 'admin/UPDATE_LENDER';
export const SET_LENDER_LOGO = 'admin/SET_LENDER_LOGO';
export const GET_ALL_LENDERS = 'lenders/GET_ALL_LENDERS';
export const GET_LENDER_DETAILS = 'lenders/GET_LENDER_DETAILS';
export const SET_LENDER_DETAILS = 'lenders/SET_LENDER_DETAILS';
export const SET_ERROR = 'partners/SET_ERROR';
export const SET_SUCCESS = 'partners/SET_SUCCESS';

export const setError = createAction<boolean | string>(SET_ERROR);

export const setSuccess = createAction<string | boolean>(SET_SUCCESS);

export const getAllLenders = createAsyncThunk<ShortLender[]>(GET_ALL_LENDERS, async () => {
  const lenders = await getAllLendersApi();
  return lenders;
});

export const getLenders = createAsyncThunk<{ lenders: Lender[]; total: number }, LenderQuery>(
  GET_LENDERS,
  async (query) => {
    const data = await getLendersApi(omitBy(query, isNil));
    const lenders = data.lenders.map((lender) => ({
      ...lender,
      // helps to display up to date logos
      logo_url: lender.logo_url ? `${lender.logo_url}?${Date.now()}` : '',
    }));
    return { lenders, total: data.total };
  },
);

export const createLender = createAsyncThunk<void, CreateLender>(CREATE_LENDER, async (lender, { dispatch }) => {
  await createLenderApi(lender);
  dispatch(getLenders({ per_page: ROWS_PER_TABLE_PAGE, page: 1 }));
  const successMessage = i18n.t('pages.lenders.admin.success.create');
  dispatch(setSuccess(successMessage));
});

export const setLenderLogo = createAction<SetLenderLogo>(SET_LENDER_LOGO);

export const getLenderDetails = createAsyncThunk<LenderDetails, string>(GET_LENDER_DETAILS, async (id) => {
  const lender = await getLenderDetailsApi(id);
  return lender;
});

export const updateLender = createAsyncThunk<void, UpdateLender>(
  UPDATE_LENDER,
  async ({ id, lender }, { dispatch }) => {
    await updateLenderApi(id, lender);
    dispatch(getLenderDetails(id));
    const successMessage = i18n.t('pages.lenders.admin.success.update');
    dispatch(setSuccess(successMessage));
  },
);

export const setLenderDetails = createAction<LenderDetails | null>(SET_LENDER_DETAILS);
