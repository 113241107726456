import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    '& .MuiGrid-item': {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
  modalContainer: {
    maxWidth: 800,
    width: '80%',
    borderRadius: 0,
    '& .MuiDialogContent-root': {
      overflowY: 'initial',
    },
  },
  closeFilterIcon: {
    fontSize: '24px',
    width: '20px',
    height: '20px',
    marginLeft: 'auto !important',
    minWidth: '24px !important',
    minHeight: '24px !important',
    color: `${theme.palette.Gray[700]} !important`,
    '& .MuiSvgIcon-root': {
      marginRight: '0 !important',
    },
  },
  selectInput: {
    '& .MuiSelect-root': {
      height: 46,
    },
  },
  textInput: {
    minWidth: '100%',
    '& .MuiInputBase-root': {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      fontWeight: 700,
      height: 46,
      borderRadius: '8px',
    },
    '&$focused $notchedOutline': {
      borderColor: '#D0D5DD',
      borderWidth: '1px !important',
    },
    '&:hover:not($disabled):before': {
      borderColor: '#D0D5DD !important',
      borderWidth: '1px !important',
    },
    '&.Mui-focused:before': {
      borderColor: '#D0D5DD !important',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#D0D5DD !important',
      borderWidth: '1px !important',
    },
    '&:focus-within .MuiOutlinedInput-notchedOutline': {
      borderColor: '#D0D5DD !important',
      borderWidth: '1px !important',
    },
    '&:focus': {
      backgroundColor: 'transparent',
    },
    '& .MuiOutlinedInput-input': {
      padding: '14px 14px !important',
      color: '#667085',
      fontSize: '0.875rem',
    },
    '& .Mui-disabled': {
      backgroundColor: theme.palette.grey[200],
      color: theme.palette.common.black,
    },
    '& .Mui-error': {
      color: theme.palette.error.main,
      marginLeft: 0,
      marginRight: 0,
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: 'auto',
    },
  },
  autoCompleteInput: {
    minWidth: '100%',
    '& .MuiInputBase-root': {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      fontWeight: 700,
      minHeight: '44.13px',
      padding: '4px !important',
    },
    '& .Mui-disabled': {
      backgroundColor: theme.palette.grey[200],
      color: theme.palette.common.black,
    },
    '& .Mui-error': {
      color: theme.palette.error.main,
      marginLeft: 0,
      marginRight: 0,
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: 'auto',
    },
  },
  popupIcon: {
    height: 20,
    width: 20,
    color: '#687C8D',
    pointerEvents: 'none',
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cardShadow: {
    boxShadow: '0px 2px 24px 0px #9292921A',
    backgroundColor: theme.palette.primary.contrastText,
    borderRadius: theme.spacing(3),
    padding: '15px',
  },
  c_card: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(4),
    },
    // backgroundColor: theme.palette.primary.contrastText,
  },
  c_cardTitle: {
    display: 'flex',
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
  },
  c_cardTitleText: {
    fontSize: `${theme.typography.pxToRem(16)} !important`,
    lineHeight: `${theme.typography.pxToRem(28)} !important`,
    color: `${theme.palette.Gray[600]} !important`,
    fontWeight: '500 !important',
    fontFamily: '"Jost" !important',
  },
  c_cardBody: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
  },
  filter_cardTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px',

    '& h3': {
      fontWeight: '500',
    },
  },
  filter_cardBody: {
    padding: '0px 16px 24px 16px',
  },
  filter_tabingBox: {
    borderBottom: `1px solid ${theme.palette.grey[50]}`,
    marginBottom: '24px',
  },
  filter_checkBoxGrp: {
    display: 'flex',
    flexDirection: 'column',
  },
  filter_cardFooter: {
    padding: '24px 16px',
    borderTop: `1px solid ${theme.palette.grey[50]}`,
  },
  selectDisable: {
    pointerEvents: 'none',
    opacity: 0.7,
  },
}));
