import { FC, useState, useCallback } from 'react';
import { Box } from '@material-ui/core';
import { FileRejection } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { allowedFileTypes } from 'core/constants';
import { Document, DocumentUploadResponse } from 'core/types';
import { FileDropCSV } from '../FileDrop';

interface DocumentUploadProps {
  uploadDocument(data: FormData | string): Promise<DocumentUploadResponse | null>;
  validationError?: string;
  onUploadSuccess?: (document: Document) => void;
}

const DocumentUpload: FC<DocumentUploadProps> = ({ uploadDocument, validationError, onUploadSuccess }) => {
  const [rejectedFiles, setRejections] = useState<FileRejection[]>([]);

  const { t } = useTranslation();

  const handleUpload = useCallback(
    (documents: File[]) => {
      const document = documents[0];
      const reader = new FileReader();

      let csv: string;
      reader.onload = () => {
        csv = reader.result as string;
      };
      reader.onloadend = async () => uploadDocument(csv);
      reader.readAsText(document);

      if (onUploadSuccess) onUploadSuccess(document);
    },
    [onUploadSuccess, uploadDocument],
  );

  const handleRejection = useCallback((files: FileRejection[]) => {
    setRejections(files);
  }, []);

  return (
    <Box>
      <FileDropCSV
        allowedFileTypes={allowedFileTypes.filter((type) => ['.csv', '.xls', '.xlsx']?.includes(type.extension))}
        onUpload={handleUpload}
        onReject={handleRejection}
        uploadMessage={t('global.uploadDocument')}
        validationError={rejectedFiles?.length > 0 || validationError}
      />
    </Box>
  );
};

export default DocumentUpload;
