import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  error: {
    borderColor: `${theme.palette.error.main} !important`,
  },
  modalTextAreBox: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 0,
    width: '100%',
  },
  fontJost: {
    fontFamily: 'jost !important',
  },
  fontWeight_500: {
    fontWeight: '500!important',
  },
  gray_500: {
    color: `${theme.palette.Gray[500]} !important`,
  },
  errorText: {
    color: 'red',
  },
  modalTextArea: {
    borderRadius: '0.5rem',
    borderColor: `${theme.palette.Gray[300]} !important`,
    outline: 'none !important',
  },
  inputLbl: {
    lineHeight: '1.75rem !important',
    marginBottom: '0.5rem !important',
    display: 'block !important',
  },
}));
