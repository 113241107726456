import { FC } from 'react';
import { Box, Divider, Drawer, List, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { SidebarLink } from 'components/SidebarLink';
import { SidebarButtons } from 'components/SidebarButtons';
import { SidebarUser } from 'components/SidebarUser';
import routes from 'router/routes';
import { ReactComponent as DashboardIcon } from '../../assets/icons/dashboard.svg';
import { ReactComponent as ApplicationsIcon } from '../../assets/icons/applications.svg';
import { ReactComponent as LendersIcon } from '../../assets/icons/lenders.svg';
import { ReactComponent as PartnersIcon } from '../../assets/icons/partners.svg';
import { ReactComponent as AdminsIcon } from '../../assets/icons/admins.svg';
import { ReactComponent as MetricsIcon } from '../../assets/icons/metrics.svg';
import { ReactComponent as IntegrationsIcon } from '../../assets/icons/integrations.svg';
import { ReactComponent as WorkflowIcon } from '../../assets/icons/workflow.svg';
import { ReactComponent as SettingsIcon } from '../../assets/icons/settings.svg';
import { ReactComponent as HomeIcon } from '../../assets/icons/home.svg';
import { ReactComponent as BellIcon } from '../../assets/icons/bell.svg';
import { useAuth } from '../../store/auth/hooks';
import useStyles from './SidebarV5.styles';

const Sidebar: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isAdmin, isPartner, isCompanyOwner } = useAuth();
  const { pathname } = useLocation();

  const production = (process.env.REACT_APP_STAGE as string) === 'production';

  return (
    <Box component="nav">
      <Drawer className={classes.sidebarContainer} variant="permanent" open anchor="left">
        <Box className={classes.sidebarLinksContainer}>
          <List component="nav" className={classes.listContainer}>
            <SidebarUser />

            <Box className={classes.categoryContainer}>
              <Typography className={classes.categoryText}>{t('global.overview')}</Typography>
            </Box>
            {false && (
              <SidebarLink
                route={routes.home}
                pathname={pathname}
                Icon={<HomeIcon width="20px" height="20px" />}
                title={t('global.home')}
              />
            )}

            <SidebarLink
              route={routes.dashboard}
              pathname={pathname}
              Icon={<DashboardIcon width="20px" height="20px" />}
              title={t('global.dashboard')}
            />

            {!production && (
              <SidebarLink
                route={routes.leads}
                pathname={pathname}
                Icon={<ApplicationsIcon width="20px" height="20px" />}
                title={t('global.applications')}
              />
            )}

            {(isAdmin || isPartner) && (
              <SidebarLink
                route={routes.metrics}
                pathname={pathname}
                Icon={<MetricsIcon width="20px" height="20px" />}
                title={t('global.metrics')}
              />
            )}

            <Box className={classes.categoryTopMargin} />

            <Box className={classes.categoryContainer}>
              <Typography className={classes.categoryText}>{t('global.users')}</Typography>
            </Box>

            {isPartner && (
              <SidebarLink
                route={routes.developers}
                pathname={pathname}
                Icon={<AdminsIcon width="20px" height="20px" />}
                title={t('global.developer')}
              />
            )}

            {isAdmin && (
              <>
                <SidebarLink
                  route={routes.lenders}
                  pathname={pathname}
                  Icon={<LendersIcon width="20px" height="20px" />}
                  title={t('global.lenders')}
                />

                <SidebarLink
                  route={routes.partners}
                  pathname={pathname}
                  Icon={<PartnersIcon width="20px" height="20px" />}
                  title={t('global.partners')}
                />

                <SidebarLink
                  route={routes.admins}
                  pathname={pathname}
                  Icon={<AdminsIcon width="20px" height="20px" color="#D0D5DD" />}
                  title={t('global.teamMembers')}
                />
              </>
            )}

            <Box className={classes.categoryTopMargin} />

            <Box className={classes.categoryContainer}>
              <Typography className={classes.categoryText}>{t('global.developer')}</Typography>
            </Box>

            {!production && (
              <>
                <SidebarLink
                  route={routes.integrations}
                  pathname={pathname}
                  Icon={<IntegrationsIcon width="20px" height="20px" />}
                  title={t('global.integrations')}
                />

                <SidebarLink
                  route={routes.lenderRules}
                  pathname={pathname}
                  Icon={<IntegrationsIcon width="20px" height="20px" />}
                  title={t('global.lenderRules')}
                />

                <SidebarLink
                  route={routes.workFlow}
                  pathname={pathname}
                  Icon={<WorkflowIcon width="20px" height="20px" />}
                  title={t('global.workflow')}
                />
              </>
            )}

            <Box className={classes.autoContainer} />
            <Divider variant="fullWidth" className={classes.divider} />

            <Box className={classes.categoryTopMargin} />

            <Box className={classes.categoryContainer}>
              <Typography className={classes.categoryText}>{t('global.settings')}</Typography>
            </Box>

            <SidebarLink
              route={routes.reminders}
              pathname={pathname}
              Icon={<BellIcon width="20px" height="20px" />}
              title={t('global.notifications')}
              disabled
            />

            {!isCompanyOwner && (
              <SidebarLink
                route={routes.settings}
                pathname={pathname}
                Icon={<SettingsIcon width="20px" height="20px" />}
                title={t('global.settings')}
              />
            )}

            <SidebarButtons />
          </List>
        </Box>
      </Drawer>
    </Box>
  );
};

export default Sidebar;
