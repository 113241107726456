import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Container, Grid, Typography } from '@material-ui/core';
import { Description, FastForward, Mail } from '@material-ui/icons';
import { AlertMessage } from 'components/alerts';
import { useGlobalState } from 'store/global/hooks';

import { AlertMessageStatus } from 'core/types';
import { usePartners } from 'store/partners/hooks';
import { Webhooks } from './components';
import useStyles from './WebhooksConfig.styles';

const WebhooksConfig: FC = () => {
  const production = true;
  const classes = useStyles();

  const { t } = useTranslation();
  const { error: globalError } = useGlobalState();
  const { error, success, setError, setSuccess } = usePartners();

  return (
    <Container disableGutters maxWidth="xl">
      <Grid container spacing={2}>
        <Grid item xs={12} className={classes.headerContainer}>
          {/* <Switch color="primary" checked={production} onChange={toggleEnv} /> */}
          <Typography className={classes.title}>
            {production ? t('pages.api.production.title') : t('pages.api.sandbox.title')}
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <Typography className={classes.subtitle} gutterBottom>
            {t('pages.api.webhooks.title')}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {t('pages.api.webhooks.description')}
          </Typography>
          <Typography className={classes.subtitle} gutterBottom>
            {t('pages.api.webhooks.subtitle')}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {t('pages.api.webhooks.subDescription')}
          </Typography>
          <Webhooks />
        </Grid>

        <Grid item xs={12}>
          <Typography className={classes.subtitle}>{t('pages.api.resources.title')}</Typography>
        </Grid>

        <Grid item xs={12} className={classes.actionsContainer}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<FastForward />}
            onClick={() => window.open('https://docs.scorethebusiness.com/')}
          >
            {t('pages.api.resources.quickstart')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Description />}
            onClick={() => window.open(t('pages.auth.footer.links.docsUrl'))}
          >
            {t('pages.api.resources.documentation')}
          </Button>
          <Button variant="contained" color="primary" startIcon={<Mail />} href="mailto:olu@scorethebusiness.com">
            {t('pages.api.resources.support')}
          </Button>
        </Grid>
      </Grid>

      {!globalError && (
        <>
          <AlertMessage
            open={!!error}
            onClose={() => setError(false)}
            message={typeof error === 'string' ? error : undefined}
            autoHideDuration={5000}
          />

          <AlertMessage
            open={!!success}
            onClose={() => setSuccess(false)}
            message={typeof success === 'string' ? success : undefined}
            autoHideDuration={5000}
            status={AlertMessageStatus.SUCCESS}
          />
        </>
      )}
    </Container>
  );
};

export default WebhooksConfig;
