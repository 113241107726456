import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  sidebarContainer: {
    width: 272,
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      width: 272,
      boxSizing: 'border-box',
      borderRight: 'none',
    },
    '& .MuiPaper-root': {
      padding: 0,
    },
  },
  sidebarLinksContainer: {
    backgroundColor: '#101828',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: `${theme.spacing(4)} 0`,
  },
  sidebarHeader: {
    paddingLeft: theme.spacing(1),
    marginBottom: theme.spacing(6),
  },
  listContainer: {
    '&.MuiList-root': {
      padding: 0,
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },
  },
  divider: {
    border: '0.5px solid #5B5B5B',
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(3),
  },
  categoryContainer: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  categoryText: {
    '&.MuiTypography-body1': {
      fontSize: theme.spacing(3),
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '140%',
      textTransform: 'uppercase',
      color: '#FCFCFD',
      marginBottom: theme.spacing(1.25),
    },
  },
  categoryTopMargin: {
    height: theme.spacing(3),
  },
  autoContainer: {
    flex: 1,
    minHeight: theme.spacing(17),
    height: 'auto',
    flexGrow: 1,
  },
}));

export default useStyles;
