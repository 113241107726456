import { FC } from 'react';
import { FormikTouched, FormikErrors } from 'formik';
import moment from 'moment';
import * as yup from 'yup';
import i18n from 'i18next';

import { ApplicationRegion, LenderOffer, OfferStatus } from 'store/applications/types';
import { MIN_CREDIT_AMOUNT, MAX_CREDIT_AMOUNT } from 'core/constants';
import { MonthlyRepaymentType, OfferProductType } from 'store/admin/types';
import { CustomNumberField } from 'components/inputs';
import { parseMoney } from 'utils';
import { RejectionReasonTag } from 'core/types';
import { mapAppRegionToSupportedCurrency } from 'core/utils';

const numberErrorMessage = i18n.t('pages.lead.admin.makeOffer.numberError');

export interface OfferFormRate {
  eligibleAmount: number | null;
  interestRate: number | null;
  monthlyRepayment: number | null;
  interestType: MonthlyRepaymentType | null;
  repaymentPercentage: number | null;
  repaymentTerms: string | null;
  totalRepayableAmount: number | null;
}

export interface FormValues {
  lenderId: string | null;
  status: OfferStatus | null;
  productType: OfferProductType | null;
  minCredit: number | null;
  maxCredit: number | null;
  rates: OfferFormRate[];
  loanDuration: number | null;
  validUntil: Date | null;
  notes: string | null;
  rejectionReason: string | null;
  rejectionReasonTags: RejectionReasonTag[];
}

export const today = moment().endOf('day').toDate();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PercentageNumberInput: FC = (props: any) => (
  <CustomNumberField {...props} suffix="%" decimalScale={2} fixedDecimalScale />
);

export const initialRate = {
  eligibleAmount: null,
  interestRate: null,
  monthlyRepayment: null,
  interestType: null,
  repaymentPercentage: null,
  repaymentTerms: null,
  totalRepayableAmount: null,
};

export const getInitialValues = (
  lendersOptions: { label: string; value: string }[],
  offer?: LenderOffer | null,
  lenderId?: string,
): FormValues => {
  if (!offer) {
    return {
      lenderId: lenderId ?? '',
      status: '' as OfferStatus,
      productType: '' as OfferProductType,
      minCredit: null,
      maxCredit: null,
      rates: [initialRate],
      loanDuration: null,
      validUntil: null,
      notes: null,
      rejectionReason: null,
      rejectionReasonTags: [],
    };
  }

  let minCredit = null;
  let maxCredit = null;
  let loanDuration = null;
  let rates: OfferFormRate[];

  if (offer.product_type === OfferProductType.REVENUE_BASED) {
    rates =
      offer?.revenueRepayments?.length === 0
        ? [initialRate]
        : offer?.revenueRepayments?.map((repayment) => ({
            eligibleAmount: repayment.total_get ?? null,
            interestRate: null,
            monthlyRepayment: null,
            interestType: null,
            repaymentPercentage: Number(repayment.sweep ?? null) * 100 ?? null,
            repaymentTerms: repayment.sweep_terms ?? null,
            totalRepayableAmount: repayment.total_repayment ?? null,
          }));
  } else {
    minCredit = offer.min_credit_offer;
    maxCredit = offer.max_credit_offer;
    loanDuration = offer.duration_in_months;
    rates =
      offer.rates.length === 0
        ? [initialRate]
        : offer.rates.map((rate) => ({
            eligibleAmount: rate.principal ?? null,
            interestRate: Number(rate.interest_rate ?? null) * 100 ?? null,
            monthlyRepayment: rate.monthly_repayment,
            interestType: rate.monthly_repayment_type as MonthlyRepaymentType,
            repaymentPercentage: null,
            repaymentTerms: null,
            totalRepayableAmount: rate.total_repayment,
          }));
  }

  return {
    lenderId: lenderId ?? lendersOptions.find((lO) => lO.label === offer.lender_name)?.value ?? '',
    status: offer.status,
    productType: offer.product_type,
    minCredit,
    maxCredit,
    rates,
    loanDuration,
    validUntil: offer.valid_until ? new Date(offer.valid_until) : null,
    notes: offer.notes ?? null,
    rejectionReason: offer.rejection_reason ?? null,
    rejectionReasonTags: offer.rejection_reason_tags ?? [],
  };
};

export const getRateFieldError = (
  touched: FormikTouched<FormValues>,
  errors: FormikErrors<FormValues>,
  index: number,
  fieldName: string,
): string | undefined => {
  return (
    touched.rates &&
    touched.rates[index] &&
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    touched.rates[index][fieldName] &&
    errors.rates &&
    typeof errors.rates !== 'string' &&
    (errors.rates[index] as Partial<OfferFormRate>) &&
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (errors.rates[index] as Partial<OfferFormRate>)[fieldName]
  );
};

const getParentValuesFromTestContext = (context: yup.TestContext) => {
  const contextGeneric = context as unknown as { from: { value: Record<string, unknown> }[] };
  return contextGeneric.from[1].value;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getFormSchema = (lenderId?: string, appRegion?: ApplicationRegion): any =>
  yup.object({
    lenderId: lenderId
      ? yup.string().nullable()
      : yup.string().required(i18n.t('pages.lead.admin.makeOffer.inputs.lenderId.required')).nullable(),
    status: yup.string().required(i18n.t('pages.lead.admin.makeOffer.inputs.status.required')).nullable(),
    productType: yup.string().required(i18n.t('pages.lead.admin.makeOffer.inputs.productType.required')).nullable(),
    minCredit: yup
      .number()
      .when('productType', (productType: OfferProductType, schema: yup.NumberSchema) =>
        productType !== OfferProductType.REVENUE_BASED
          ? schema
              .required(i18n.t('pages.lead.admin.makeOffer.inputs.minCredit.required'))
              .min(
                MIN_CREDIT_AMOUNT,
                i18n.t('pages.lead.admin.makeOffer.inputs.minCredit.minError', {
                  min: parseMoney(MIN_CREDIT_AMOUNT, mapAppRegionToSupportedCurrency(appRegion)),
                }),
              )
              .max(
                MAX_CREDIT_AMOUNT,
                i18n.t('pages.lead.admin.makeOffer.inputs.maxCredit.maxError', {
                  max: parseMoney(MAX_CREDIT_AMOUNT, mapAppRegionToSupportedCurrency(appRegion)),
                }),
              )
          : schema.optional(),
      )
      .nullable(),
    maxCredit: yup
      .number()
      .when('productType', (productType: OfferProductType, schema: yup.NumberSchema) =>
        productType !== OfferProductType.REVENUE_BASED
          ? schema
              .required(i18n.t('pages.lead.admin.makeOffer.inputs.maxCredit.required'))
              .max(
                MAX_CREDIT_AMOUNT,
                i18n.t('pages.lead.admin.makeOffer.inputs.maxCredit.maxError', {
                  max: parseMoney(MAX_CREDIT_AMOUNT, mapAppRegionToSupportedCurrency(appRegion)),
                }),
              )
              .when('minCredit', (minCredit: number, schema2: yup.NumberSchema) => {
                return minCredit
                  ? schema2.min(
                      minCredit,
                      i18n.t('pages.lead.admin.makeOffer.inputs.maxCredit.minError', {
                        min: parseMoney(minCredit, mapAppRegionToSupportedCurrency(appRegion)),
                      }),
                    )
                  : schema2.min(
                      MIN_CREDIT_AMOUNT,
                      i18n.t('pages.lead.admin.makeOffer.inputs.maxCredit.minError', {
                        min: parseMoney(MIN_CREDIT_AMOUNT, mapAppRegionToSupportedCurrency(appRegion)),
                      }),
                    );
              })
          : schema.optional(),
      )
      .nullable(),
    rates: yup.array(
      yup.object({
        eligibleAmount: yup
          .number()
          .required(i18n.t('pages.lead.admin.makeOffer.inputs.eligibleAmount.required'))
          .test('within-range', 'within range', (value, context) => {
            const { minCredit, maxCredit, productType } = getParentValuesFromTestContext(context);
            if (productType === OfferProductType.REVENUE_BASED) return true;
            const minCreditNum = Number(minCredit) ?? MIN_CREDIT_AMOUNT;
            const maxCreditNum = Number(maxCredit) ?? MAX_CREDIT_AMOUNT;
            const valueNum = Number(value);
            if (valueNum < minCreditNum) {
              const message = i18n.t('pages.lead.admin.makeOffer.inputs.eligibleAmount.minError', {
                min: parseMoney(minCreditNum, mapAppRegionToSupportedCurrency(appRegion)),
              });
              return context.createError({ message, path: context.path });
            }
            if (valueNum > maxCreditNum) {
              const message = i18n.t('pages.lead.admin.makeOffer.inputs.eligibleAmount.maxError', {
                max: parseMoney(maxCreditNum, mapAppRegionToSupportedCurrency(appRegion)),
              });
              return context.createError({ message, path: context.path });
            }
            return true;
          })
          .nullable(),
        interestRate: yup
          .number()
          .test('is-valid', 'is valid', (value, context) => {
            const { productType } = getParentValuesFromTestContext(context);
            if (productType && productType !== OfferProductType.REVENUE_BASED) {
              if (!value) {
                return context.createError({
                  message: i18n.t('pages.lead.admin.makeOffer.inputs.interestRate.required'),
                  path: context.path,
                });
              }
              if (value < 1 || value > 100) {
                return context.createError({ message: numberErrorMessage, path: context.path });
              }
            }
            return true;
          })
          .nullable(),
        monthlyRepayment: yup
          .number()
          .test('is-valid', 'is valid', (value, context) => {
            const { productType } = getParentValuesFromTestContext(context);
            if (productType && productType !== OfferProductType.REVENUE_BASED && !value) {
              return context.createError({
                message: i18n.t('pages.lead.admin.makeOffer.inputs.interestRate.required'),
                path: context.path,
              });
            }
            return true;
          })
          .nullable(),
        interestType: yup
          .string()
          .test('is-valid', 'is valid', (value, context) => {
            const { productType } = getParentValuesFromTestContext(context);
            if (productType && productType !== OfferProductType.REVENUE_BASED && !value) {
              return context.createError({
                message: i18n.t('pages.lead.admin.makeOffer.inputs.interestType.required'),
                path: context.path,
              });
            }
            return true;
          })
          .nullable(),

        totalRepayableAmount: yup
          .number()
          .required(i18n.t('pages.lead.admin.makeOffer.inputs.totalRepayableAmount.required'))
          .nullable(),
      }),
    ),
    loanDuration: yup
      .number()
      .when('productType', (productType: OfferProductType, schema: yup.NumberSchema) =>
        productType !== OfferProductType.REVENUE_BASED
          ? schema.required(i18n.t('pages.lead.admin.makeOffer.inputs.loanDuration.required'))
          : schema.optional(),
      )
      .nullable(),
    validUntil: yup
      .date()
      .required(i18n.t('pages.lead.admin.makeOffer.inputs.validUntil.required'))
      .min(today, ({ min }) =>
        i18n.t('pages.lead.admin.makeOffer.inputs.validUntil.error', { date: moment(min).format('DD MMMM YYYY') }),
      )
      .nullable(),
    notes: yup.string().nullable(),
    rejectionReason: yup
      .string()
      .when('status', (status: OfferStatus, schema: yup.StringSchema) =>
        status === OfferStatus.DECLINED
          ? schema.required(i18n.t('pages.lead.admin.makeOffer.inputs.rejectionReason.required'))
          : schema.optional(),
      )
      .nullable(),
    rejectionReasonTags: yup
      .array(yup.string())
      .when('status', (status: OfferStatus, schema: yup.ArraySchema<yup.StringSchema>) =>
        status === OfferStatus.DECLINED
          ? schema
              .min(1, i18n.t('pages.lead.admin.makeOffer.inputs.rejectionReasonTags.error'))
              .required(i18n.t('pages.lead.admin.makeOffer.inputs.rejectionReasonTags.required'))
          : schema.optional(),
      )
      .nullable(),
  });
