import { FC } from 'react';
import { Container } from '@material-ui/core';
import { Box } from '@mui/material';

import { Header } from 'components/Header/Header';
import { LeadsTable } from './components';
import useStyles from './Leads.styles';

const Leads: FC = () => {
  const classes = useStyles();

  return (
    <Container disableGutters maxWidth="xl">
      <Header name="Application" />
      <Box sx={{ p: 6 }}>
        <Box sx={{ mt: 4 }} className={classes.tableContainer}>
          <LeadsTable />
        </Box>
      </Box>
    </Container>
  );
};

export default Leads;
