import { FC, useEffect, useState } from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import { Avatar, Box, List, ListItem, Stack } from '@mui/material';
import clsx from 'clsx';
import { AccountUsers } from 'components/AccountUsers';
import { CreateAccountOwnerModal } from 'components/CreateAccountOwnerModal';
import CustomButton from 'components/CustomButton/Button';
import { Header } from 'components/Header/Header';
import CommonModal from 'components/Modal/CommonModal';
import { UserSwitchEnabled } from 'components/UserSwitchEnabled';
import { AlertMessage } from 'components/alerts';
import { countryCodeLabel } from 'core/countries';
import { AlertMessageStatus } from 'core/types';
import { firstLatterCapital } from 'pages/leads/components/CreateApplicationModal/utils';
import routes from 'router/routes';
import { useAccount } from 'store/account/hooks';
import { AccountType } from 'store/applications/types';
import { useGlobalState } from 'store/global/hooks';
import { useLenders } from 'store/lenders/hooks';
import { CreateLenderModal } from '../lenders/components';
import { DeleteLenderModal } from './components';
import useCommonStyles from '../../assets/css/Common.styles';
import { ReactComponent as DocumentPen } from '../../assets/icons/document_pen.svg';

const Lender: FC = () => {
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);
  const [createOwnerOpen, setCreateOwnerOpen] = useState(false);

  const data = useParams<{ id: string }>();
  const commonClasses = useCommonStyles();
  const { t } = useTranslation();
  const {
    lenderDetails,
    error: lendersError,
    success: lendersSucess,
    getLenderDetails,
    setLenderDetails,
    setError: setLendersError,
    setSuccess: setLendersSuccess,
  } = useLenders();
  const {
    error: accountError,
    success: accountSuccess,
    setError: setAccountError,
    setSuccess: setAccountSuccess,
  } = useAccount();
  const { error: globalError } = useGlobalState();
  const { users } = useAccount();

  const toggleDeleteOpen = () => setDeleteOpen((prevState) => !prevState);

  const toggleUpdateOpen = () => setUpdateOpen((prevState) => !prevState);

  const toggleCreateOwnerOpen = () => setCreateOwnerOpen((prevState) => !prevState);

  const clearError = () => {
    if (accountError) setAccountError(false);
    if (lendersError) setLendersError(false);
  };

  const LendersData = [
    { title: t('pages.lender.sections.header.accountName'), value: lenderDetails?.name },
    { title: t('pages.lender.sections.header.displayedName'), value: lenderDetails?.displayed_name },
    { title: t('pages.lender.sections.header.contactName'), value: lenderDetails?.contact_person_email },
    { title: t('pages.lender.sections.header.contactEmail'), value: lenderDetails?.contact_person_email },
    {
      title: t('pages.lender.sections.header.country'),
      value: `${countryCodeLabel[lenderDetails?.country_code || '']} (${lenderDetails?.country_code})`,
    },
    { title: t('pages.lender.sections.header.supportedRegions'), value: lenderDetails?.supported_regions.join(', ') },
    { title: 'Last opened', value: moment().format('DD/MM/YYYY') },
    { title: 'Enable lender', value: '' },
  ];

  const clearSuccess = () => {
    if (accountSuccess) setAccountSuccess(false);
    if (lendersSucess) setLendersSuccess(false);
  };

  const error = accountError || lendersError;

  const success = accountSuccess || lendersSucess;

  useEffect(() => {
    return () => setLenderDetails(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data?.id) getLenderDetails(data?.id);
    // eslint-disable-next-line
  }, [getLenderDetails, data?.id]);

  return (
    <Container disableGutters maxWidth="xl">
      <Header name={firstLatterCapital(lenderDetails?.name || '')} isShowTestMode={false} isShowSendBtn={true} />
      <Box className={clsx([commonClasses.pageBackWrap, commonClasses.mainWrapper])}>
        <Link to={routes?.lenders} className={commonClasses.pageBackLink}>
          <ChevronLeft className={commonClasses.pageBackIcon} />
          Back to lenders
        </Link>
      </Box>
      <Box sx={{ mt: 6, px: 4 }} className={commonClasses.mainWrapper}>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={4} xl={4}>
            <Box sx={{ px: 8, py: 6, height: '100%' }} className={clsx([commonClasses.cardShadow])}>
              <Box>
                <Stack sx={{ mb: 6 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={4}>
                  <Avatar alt={lenderDetails?.name} src={lenderDetails?.logo_url} sx={{ width: 56, height: 56 }} />
                  <Box className={commonClasses.boxCircleText}>
                    <Typography className={clsx([commonClasses.fontJost, commonClasses.boxCircleTitle])} component="p">
                      {lenderDetails?.name}
                    </Typography>
                    <Typography className={clsx([commonClasses.fontJost])} component="p">
                      All information relating to this lender
                    </Typography>
                  </Box>
                </Stack>
                <Box sx={{ mb: 6 }}>
                  <List sx={{ mb: 0, p: 0 }}>
                    {LendersData?.map((d) => {
                      return (
                        <ListItem key={d?.title} sx={{ px: 0 }}>
                          <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                            <Box className={commonClasses.fixedWidth136}>
                              <Typography
                                component="p"
                                className={clsx([
                                  commonClasses.fontJost,
                                  commonClasses.gray_600,
                                  commonClasses.lineHeight_25,
                                ])}
                              >
                                {d?.title}
                              </Typography>
                            </Box>
                            <Box>
                              {d?.title === 'Enable lender' ? (
                                lenderDetails && (
                                  <UserSwitchEnabled
                                    id={lenderDetails?.id}
                                    initialActive={lenderDetails?.active}
                                    accountType={AccountType.LENDER}
                                    setSuccess={setLendersSuccess}
                                    setError={setLendersError}
                                    successMessage={t('pages.lender.messages.updateSuccess') as string}
                                  />
                                )
                              ) : (
                                <Typography
                                  component="p"
                                  className={clsx([
                                    commonClasses.fontJost,
                                    commonClasses.gray_600,
                                    commonClasses.lineHeight_25,
                                  ])}
                                >
                                  {d?.value || '-'}
                                </Typography>
                              )}
                            </Box>
                          </Stack>
                        </ListItem>
                      );
                    })}
                  </List>
                </Box>
                <Box className={commonClasses.flexWithGap10}>
                  <CustomButton onClick={toggleUpdateOpen}>Update lender</CustomButton>
                  {users?.length === 0 && <CustomButton onClick={toggleCreateOwnerOpen}>Create Account</CustomButton>}
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={8} xl={8}>
            <Box sx={{ px: 8, py: 6, height: '100%' }} className={clsx([commonClasses.cardShadow])}>
              <AccountUsers lenderId={data?.id} toggle={createOwnerOpen} />
            </Box>
          </Grid>
        </Grid>
      </Box>

      {deleteOpen && <DeleteLenderModal open={deleteOpen} toggleOpen={toggleDeleteOpen} />}

      {updateOpen && lenderDetails && (
        <CommonModal open={updateOpen} onClose={toggleUpdateOpen} title="Update this lender" icon={<DocumentPen />}>
          <CreateLenderModal open={updateOpen} toggleOpen={toggleUpdateOpen} lender={lenderDetails} />
        </CommonModal>
      )}

      {createOwnerOpen && (
        <CommonModal
          open={createOwnerOpen}
          onClose={toggleCreateOwnerOpen}
          title="Create account owner"
          icon={<DocumentPen />}
        >
          <CreateAccountOwnerModal toggleOpen={toggleCreateOwnerOpen} accountType={AccountType.LENDER} />
        </CommonModal>
      )}

      {!globalError && (
        <>
          <AlertMessage
            open={!!error}
            onClose={clearError}
            message={typeof error === 'string' ? error : undefined}
            autoHideDuration={5000}
          />

          <AlertMessage
            open={!!success}
            onClose={clearSuccess}
            message={typeof success === 'string' ? success : undefined}
            autoHideDuration={5000}
            status={AlertMessageStatus.SUCCESS}
          />
        </>
      )}
    </Container>
  );
};

export default Lender;
