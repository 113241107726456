import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { setGlobalError } from './actions';

import { GlobalState } from './types';

const initialState: GlobalState = {
  error: false,
};

const globalStore = createSlice({
  name: 'global',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<GlobalState>) => {
    builder.addCase(setGlobalError, (state, { payload }) => {
      state.error = payload;
    });
  },
});

export default globalStore.reducer;
