import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    width: '100%',
  },
  inputError: {
    borderColor: `${theme.palette.error.main} !important`,
  },
  submitButton: {
    marginTop: theme.spacing(2),
    maxWidth: 120,
    alignSelf: 'flex-end',
  },
}));
